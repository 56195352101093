import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function PluginExtra({ product }) {
  const { t } = useTranslation()
  let Extra = () => <></>
  switch (product?.id) {
    case 'ChatGPT':
    case 'LLM Models':
    case 'Marketing mix modeling (MMM)':
    default:
      break
  }

  const free = !!product?.prices?.length
  const contactUsLink =
    product?.id === 'Marketing mix modeling (MMM)'
      ? 'https://nextbrain.ai/marketing-mix-modeling#schedule'
      : 'https://nextbrain.ai/schedule-your-free-demo'

  const contactUsDetails = ''

  return (
    <>
      <Row className="header-plugin-extra py-3">
        <Col xs={2}>
          <Image
            src={product?.image}
            alt={product?.name ?? 'No-plugin'}
            className="mx-1 px-1 py-1 d-block image-plugin-extra"
          />
        </Col>
        <Col xs={4}>
          <Row className="h-100">
            <Col xs={12}>
              <strong className="h3">{product?.name ?? 'No-plugin'}</strong>
            </Col>
            <Col className="mt-2" xs={12}>
              <a
                type="button"
                className={`original no-radius ${
                  free ? 'btn-primary' : 'btn-secondary'
                } py-2 px-3 `}
                href={contactUsLink}
              >
                {free ? t('Book a demo') : t('Free')}
              </a>
              {contactUsDetails ? (
                <>
                  <br />
                  <span>{t(contactUsDetails)}</span>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
        <Col className="d-flex justify-content-end" xs={6}>
          <Row
            style={{ maxWidth: '300px' }}
            className="h-100 align-items-center justify-content-end insights-card mx-3 p-1"
          >
            <Col className="d-flex justify-content-center" xs={12}>
              <strong className="h5 color-white" style={{ opacity: 0.8 }}>
                Developer
              </strong>
            </Col>
            <Col className="mt-2 d-flex justify-content-center" xs={12}>
              <Row>
                <Col
                  className="position-relative d-flex justify-content-end"
                  xs={6}
                >
                  <Image
                    src={'/LogoBlue.svg'}
                    alt={'Nextbrain-ai'}
                    style={{ objectFit: 'scaleDown', width: '80px' }}
                  />
                </Col>
                <Col className="d-flex align-items-center" xs={6}>
                  <Row>
                    <Col xs={12}>
                      <strong className="color-white">Nextbrain</strong>
                    </Col>
                    <Col>
                      <a className="small" href="https://nextbrain.ai">
                        nextbrain.ai
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="content-plugin-extra">
        <Col className="content-plugin-extra-side-col" xs={2}></Col>
        <Col xs={10}>
          <Extra />
        </Col>
      </Row>
    </>
  )
}
