import React, { useMemo, useState } from 'react'
import { Col, Row, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { ResponsiveLine } from '@nivo/line'
import { BasicTooltip } from '@nivo/tooltip'

import {
  getCompanyMonthlyActivity,
  getCompanyDailyActivity,
} from '../../services/user'
import './Account.css'

function Limit({ data, xScale, yScale, ...props }) {
  const limit = data?.[0]?.data?.[0]?.limit
  const limitMessage = data?.[0]?.data?.[0]?.limitMessage
  if (Number.isNaN(limit)) return <></>
  const xend = xScale(data?.[0]?.data?.[data?.[0]?.data?.length - 1]?.x)
  const yend = yScale(limit)
  return (
    <>
      <path
        d={`M 0,${yend} L${xend},${yend}`}
        className="simple-ant-trail"
        stroke={'var(--nextbrain-tables-negative-graph-bar-color)'}
        strokeWidth="1"
        fill="transparent"
        strokeDasharray="3 6"
      />
      <text
        x={0}
        y={yend - 10}
        fill="var(--nextbrain-tables-negative-graph-bar-color)"
        fontSize={13}
        fontWeight={800}
      >
        {limitMessage}
      </text>
    </>
  )
}

export default function AccountActivity({ token, user, ...props }) {
  const { t } = useTranslation()
  const monthNames = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ]
  const monthShortNames = [
    t('Jan'),
    t('Feb'),
    t('Mar'),
    t('Apr'),
    t('May'),
    t('Jun'),
    t('Jul'),
    t('Aug'),
    t('Sep'),
    t('Oct'),
    t('Nov'),
    t('Dec'),
  ]

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const monthEndDate = new Date(selectedYear, selectedMonth, 0)

  const currentSelected =
    selectedYear === new Date().getFullYear() &&
    selectedMonth === new Date().getMonth() + 1

  const monthRes = useQuery(
    ['user-month-activity'],
    async () => {
      const response = await getCompanyMonthlyActivity(
        token,
        user.selected_company,
      )
      return response
    },
    { staleTime: Infinity },
  )

  const earliestMonth =
    monthRes.isSuccess && monthRes.data && monthRes.data[0]
      ? monthRes.data[0]
      : { month: selectedMonth, year: selectedYear }

  const earliestSelected =
    selectedYear === earliestMonth.year && selectedMonth === earliestMonth.month

  const setPreviousMonth = () => {
    if (selectedMonth === 1) {
      setSelectedMonth(12)
      setSelectedYear(selectedYear - 1)
    } else {
      setSelectedMonth(selectedMonth - 1)
    }
  }

  const setNextMonth = () => {
    if (selectedMonth === 12) {
      setSelectedMonth(1)
      setSelectedYear(selectedYear + 1)
    } else {
      setSelectedMonth(selectedMonth + 1)
    }
  }

  const dayRes = useQuery(
    ['user-day-activity', selectedMonth, selectedYear],
    async () => {
      const response = await getCompanyDailyActivity(
        token,
        user.selected_company,
        selectedMonth,
        selectedYear,
      )

      return response
    },
    { staleTime: Infinity },
  )

  const [
    trainings,
    retraining,
    trainData,
    maxYTrain,
    predictData,
    maxYPredict,
  ] = useMemo(() => {
    const currentEndDate = currentSelected ? new Date() : monthEndDate
    const trainData = [...Array(currentEndDate.getDate()).keys()].map(
      (idx) => ({
        x: idx + 1,
        y: 0,
      }),
    )
    const predictData = [...Array(currentEndDate.getDate()).keys()].map(
      (idx) => ({ x: (idx + 1).toString(), y: 0 }),
    )
    let trainings = 0,
      retraining = 0
    if (dayRes.isSuccess && Array.isArray(dayRes.data)) {
      dayRes.data.forEach((element) => {
        if (element._id.action === 'train') {
          trainData[element._id.day - 1].y = element.sum
        } else {
          predictData[element._id.day - 1].y = element.sum
        }
      })

      trainData.forEach((_, idx) => {
        if (!idx) return
        trainData[idx].y += trainData[idx - 1].y
        predictData[idx].y = predictData[idx - 1].y + predictData[idx].y
      })

      const trainsByModel = dayRes.data
        .filter(({ _id }) => _id.action === 'train')
        .reduce((ac, i) => {
          ac[i._id.modelId] = ac[i._id.modelId] ?? 0
          ac[i._id.modelId]++
          return ac
        }, {})
      trainings = Object.values(trainsByModel).reduce((ac, i) => ac + i, 0)
      retraining = trainings - Object.keys(trainsByModel).length
    }

    const subscription_conf = user.companies?.find(
      (c) => c.id === user.selected_company,
    )?.subscription?.config

    let trainLimit = subscription_conf?.training_rows ?? 50000
    let maxYTrain = 'auto'
    const trainLimitNum = Number.parseInt(trainLimit)
    const lastTrainY = trainData[trainData.length - 1]?.y ?? 1
    if (!Number.isNaN(trainLimitNum) && lastTrainY < trainLimitNum)
      maxYTrain = trainLimitNum

    if (trainData?.length) {
      trainData[0].limit = trainLimitNum
      trainData[0].limitMessage = t('limit')
    }

    const predictLimit = subscription_conf?.predictions ?? 1000
    let maxYPredict = 'auto'
    const predictLimitNum = Number.parseInt(predictLimit)
    const lastPredictY = predictData[predictData.length - 1]?.y ?? 1
    if (
      !Number.isNaN(predictLimitNum) &&
      lastPredictY < predictLimitNum &&
      lastPredictY > predictLimit * 0.1
    )
      maxYPredict = predictLimitNum

    if (predictData?.length) {
      predictData[0].limit = predictLimitNum
      predictData[0].limitMessage = t('limit')
    }
    return [
      trainings,
      retraining,
      trainData,
      maxYTrain,
      predictData,
      maxYPredict,
    ]
    // eslint-disable-next-line
  }, [dayRes?.data])

  return (
    <Row
      className="justify-content-center align-items-center mx-3"
      style={{ marginBottom: '100px' }}
    >
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Account activity')}</strong>
      </Col>
      <Col id="activity" className="text-center text-md-start" xs={'auto'}>
        {t('Select month to show activity:')}
      </Col>
      <Col style={{ minWidth: '280px', maxWidth: '280px' }}>
        <Dropdown
          className="d-flex align-items-center w-100"
          id="change-month-container"
        >
          {!earliestSelected && (
            <span
              id="prev-month-btn"
              className={`change-month-side`}
              onClick={() => {
                setPreviousMonth()
              }}
            />
          )}
          <Dropdown.Toggle
            id="change-month-btn"
            className=""
            style={{
              width: '100%',
              ...(currentSelected && {
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
              }),
            }}
          >
            {monthNames[selectedMonth - 1]} {selectedYear}
          </Dropdown.Toggle>
          {!currentSelected && (
            <span
              id="next-month-btn"
              className={`change-month-side`}
              onClick={() => {
                setNextMonth()
              }}
            />
          )}

          <Dropdown.Menu
            id="dropdown-menu-account"
            className={!earliestSelected && 'shift-left'}
            style={{ width: '100%' }}
          >
            {
              // cycle through each year between earliestMonth and new date
              [
                ...Array(
                  new Date().getFullYear() - earliestMonth.year + 1,
                ).keys(),
              ]
                .reverse()
                .map((yearIdx) => {
                  const year = earliestMonth.year + yearIdx
                  const startMonth =
                    year === earliestMonth.year ? earliestMonth.month : 1
                  const endMonth =
                    year === new Date().getFullYear()
                      ? new Date().getMonth() + 1
                      : 12
                  return (
                    <div key={year}>
                      <Dropdown.ItemText key={year} className="text-center">
                        {year}
                      </Dropdown.ItemText>
                      <Row>
                        {[...Array(endMonth - startMonth + 1).keys()].map(
                          (monthIdx) => {
                            const month = startMonth + monthIdx
                            return (
                              <Col
                                key={`${month} ${year}`}
                                xs={4}
                                className="text-center"
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedMonth(month)
                                    setSelectedYear(year)
                                  }}
                                >
                                  {monthShortNames[month - 1]}
                                </Dropdown.Item>
                              </Col>
                            )
                          },
                        )}
                      </Row>
                    </div>
                  )
                })
            }
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col className="h5 py-2 text-center" xs={12}>
        {t('You trained a total of')} <strong>{trainings}</strong> {t('models')}
        , <strong>{retraining}</strong> {t('of which were retrainings.')}
      </Col>
      <Col xs={12} className="mt-2 position-relative" />
      {dayRes.isSuccess && dayRes.data && (
        <Row style={{ minHeight: '500px' }}>
          <Col xs={6}>
            <ResponsiveLine
              data={[
                {
                  id: 'Trainings',
                  data: trainData,
                },
              ]}
              margin={{ top: 30, right: 20, bottom: 90, left: 90 }}
              xScale={{ type: 'linear', min: 1 }}
              yScale={{
                type: 'linear',
                min: 0,
                max: maxYTrain,
                stacked: false,
                reverse: false,
              }}
              tooltip={({ point }) => {
                return (
                  <BasicTooltip
                    id={`Day ${point.data.x}`}
                    value={`Training rows: ${point.data.y}`}
                    color={props.color}
                    enableChip
                  />
                )
              }}
              enableArea={true}
              areaOpacity={0.2}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                legendPosition: 'middle',
                tickRotation: -22,
                legend: monthNames[selectedMonth - 1] + ' ' + selectedYear,
                legendOffset: 50,
              }}
              axisLeft={{
                orient: 'left',
                legendOffset: -80,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legend: t('Training rows'),
              }}
              enableGridX={false}
              enableGridY={false}
              enablePoints={false}
              theme={{
                textColor: 'var(--nextbrain-white-font)',
                axis: {
                  ticks: {
                    text: {
                      fill: '#F0EFFF88',
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 22,
                      outlineWidth: 0,
                      outlineColor: 'transparent',
                    },
                  },
                },
              }}
              useMesh={true}
              colors={'var(--nextbrain-main-color)'}
              layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'points',
                Limit,
                'slices',
                'mesh',
                'legends',
              ]}
            />
          </Col>
          <Col xs={6}>
            <ResponsiveLine
              data={[
                {
                  id: 'Predictions',
                  data: predictData,
                },
              ]}
              margin={{ top: 30, right: 20, bottom: 90, left: 90 }}
              xScale={{ type: 'linear', min: 1 }}
              yScale={{
                type: 'linear',
                min: 0,
                max: maxYPredict,
                stacked: false,
                reverse: false,
              }}
              tooltip={({ point }) => {
                return (
                  <BasicTooltip
                    id={`Day ${point.data.x}`}
                    value={`Predictions: ${point.data.y}`}
                    color={props.color}
                    enableChip
                  />
                )
              }}
              enableArea={true}
              areaOpacity={0.2}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                legendPosition: 'middle',
                tickRotation: -22,
                legend: monthNames[selectedMonth - 1] + ' ' + selectedYear,
                legendOffset: 50,
              }}
              axisLeft={{
                orient: 'left',
                legendOffset: -80,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legend: t('Predictions'),
              }}
              enableGridX={false}
              enableGridY={false}
              enablePoints={false}
              theme={{
                textColor: 'var(--nextbrain-white-font)',
                axis: {
                  ticks: {
                    text: {
                      fill: '#F0EFFF88',
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 22,
                      outlineWidth: 0,
                      outlineColor: 'transparent',
                    },
                  },
                },
              }}
              useMesh={true}
              colors={'var(--nextbrain-main-color)'}
              layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'points',
                Limit,
                'slices',
                'mesh',
                'legends',
              ]}
            />
          </Col>
        </Row>
      )}
    </Row>
  )
}
