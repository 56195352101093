import React, { useState, useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { resetIndustries } from '../../services/user'
import { EDIT_TYPES } from './Account'

export default function AccountLevelInd({ auth, setEditingWindow, ...props }) {
  const { t } = useTranslation()

  const [restoringTemplates, setRestoringTemplates] = useState(false)
  const queryClient = useQueryClient()

  const mlevelMap = {
    beginner: ['Beginner', '/iconsRegister/Brain2.svg'],
    intermediate: ['Intermediate', '/iconsRegister/Brain1.svg'],
    advanced: ['Advanced', '/iconsRegister/Brain3.svg'],
  }
  const mlLevel = mlevelMap[auth.user.ml_level]
    ? auth.user.ml_level
    : 'beginner'
  const userIndustry = (auth.user?.industries ?? []).reduce((dict, k) => {
    dict[k] = true
    return dict
  }, {})
  const selectedIndustriesLC = Object.keys(userIndustry)
    .filter((k) => userIndustry[k])
    .join(', ')
  const selectedIndustries =
    selectedIndustriesLC.charAt(0).toUpperCase() + selectedIndustriesLC.slice(1)

  useEffect(
    () => {
      if (restoringTemplates) {
        const override = restoringTemplates.override
        NotificationManager.info(t('Your templates are being reset'))
        resetIndustries({
          override: restoringTemplates.override,
          token: auth.token,
          signout: false,
        })
          .then((e) => {
            if (e.ok) {
              NotificationManager.info(
                t(
                  'Your workspaces are being reseted, they will be ready in a while',
                ),
              )
            }
          })
          .catch((e) => {
            console.log(
              `error reseting templates, overriden: ${override}`,
              e,
              restoringTemplates,
            )
            NotificationManager.error(t('Failed to reset templates'))
          })
          .finally(() => {
            queryClient.invalidateQueries(`workspace-${auth.token}-automl`)
            queryClient.invalidateQueries(`workspace-${auth.token}-mmm`)
            setRestoringTemplates(null)
          })
      }
    },
    // eslint-disable-next-line
    [restoringTemplates],
  )

  return (
    <Row className="justify-content-end mx-3" style={{ marginBottom: '100px' }}>
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Level, Industries and templates')}</strong>
      </Col>
      <Col xs={12} lg={4} className="mb-5 mb-lg-2">
        <Row className="flex-column justify-content-between h-100">
          <Col xs={12} className="mb-2">
            <Row className="">
              <Col className="h4 mb-3 text-center text-md-start" xs={12}>
                {t('Level')}
              </Col>
              <Col xs={12} className="mb-2 d-flex align-items-center">
                <img
                  className="float-start me-2"
                  style={{ width: '3vw', minWidth: '35px' }}
                  alt="ml level"
                  src={mlevelMap[mlLevel][1]}
                />
                <span className="mt-1">{mlevelMap[mlLevel][0]}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Button
              className="w-100 py-2 mt-5"
              onClick={() => {
                setEditingWindow(EDIT_TYPES.EDIT_LEVEL)
              }}
            >
              {t('Update level')}
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs={12} lg={4} className="mb-5 mb-lg-2  mt-md-0">
        <Row className="flex-column justify-content-between h-100 mx-3">
          <Col xs={12} className="mb-2">
            <Row>
              <Col className="h4 mb-3 text-center text-md-start" xs={12}>
                {t('Industries')}
              </Col>
              <Col
                xs={12}
                className="mb-2 d-flex d-md-block justify-content-center"
              >
                <img
                  className="float-start me-2"
                  style={{ width: '3vw', minWidth: '35px' }}
                  alt={'industry'}
                  src={'/iconsRegister/retail.svg'}
                />
                <span>
                  <small>{selectedIndustries}</small>
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Button
              className="w-100 py-2 mt-5"
              onClick={() => {
                setEditingWindow(EDIT_TYPES.EDIT_INDUSTRY)
              }}
            >
              {t('Update industries')}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={4} className="mt-3 mt-md-0">
        <Row className="flex-column justify-content-between h-100 mx-3">
          <Col xs={12} className="mb-2">
            <Row>
              <Col className="h4 mb-3 text-center text-md-start" xs={12}>
                {t('Templates')}
              </Col>
              <Col
                xs={12}
                className="mb-2 d-flex d-md-block justify-content-center"
              >
                <img
                  className="float-start me-2"
                  style={{ width: '3vw', minWidth: '35px' }}
                  alt={'industry'}
                  src={'/icons/akar-icons_file.svg'}
                />
                <span>
                  <small>
                    {t(
                      'In case you delete the examples provided for your industries you can restore them by clicking this button',
                    )}
                  </small>
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="mb-2">
            <Button
              disabled={restoringTemplates}
              className="w-100 py-2 mt-5"
              onClick={() => {
                setRestoringTemplates({ override: false })
              }}
            >
              {restoringTemplates ? (
                <div className="bouncing-loader alternative my-1">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>{t('Recover templates')}</>
              )}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={4} className="mt-3 mt-md-0">
        <Row className="justify-content-lg-end mx-3">
          <Col xs={12} className="">
            <Button
              disabled={restoringTemplates}
              className="w-100 original"
              variant="danger"
              onClick={() => {
                NotificationManager.warning(
                  <>
                    <Row>
                      <Col xs={12}>
                        {t(
                          'You will lose your changes to your current template projects, confirm before hard reseting them',
                        )}
                      </Col>
                      <Col>
                        <Button
                          className="original w-100 my-3"
                          variant="danger"
                          onClick={() => {
                            setRestoringTemplates({
                              override: true,
                            })
                          }}
                        >
                          {t('Confirm')}
                        </Button>
                      </Col>
                    </Row>
                  </>,
                )
              }}
            >
              {restoringTemplates ? (
                <div className="bouncing-loader alternative my-1">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>{t('Reset templates')}</>
              )}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
