import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Row, Col, Button, Form, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import NextbrainSelect, { Option } from '../../NextbrainSelect'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import MergeTable from './MergeTable'
import { AddRowButton, DeleteRowButton } from '../../../utils/ui'
import { useDebouncedCallback } from 'use-debounce'
import { IoLink } from 'react-icons/io5'

function ColumnPair({
  left,
  right,
  constantValue,
  deletable = true,
  optionsLeft,
  optionsRight,
  onDelete,
  onChange,
}) {
  const onChangeDebounce = useDebouncedCallback(
    (value) => onChange(value),
    1000,
  )
  const { t } = useTranslation()
  const leftRef = useRef()
  const rightRef = useRef()

  return (
    <Row className="align-items-center mt-2">
      <Col xs={11}>
        <Row>
          <Col xs={6} style={{ maxWidth: 'calc(50% - 15px)' }}>
            <Row>
              <Col className="py-1 overflow-hidden" xs={12}>
                <Form.Check
                  type="switch"
                  label={
                    <span
                      title={t('Use constant value')}
                      className="smallp d-inline-block text-truncate"
                    >
                      {t('Use constant value')}
                    </span>
                  }
                  className="form-switch-share d-flex"
                  checked={constantValue === 'left'}
                  onChange={async (e) =>
                    onChange([null, right, e?.target?.checked ? 'left' : null])
                  }
                />
              </Col>
              <Col ref={leftRef} xs={12}>
                {constantValue === 'left' ? (
                  <Form.Control
                    type="text"
                    className="nb-input match-select-height"
                    placeholder={`Constant value`}
                    defaultValue={left ?? ''}
                    onChange={async (e) =>
                      onChangeDebounce([
                        e.currentTarget.value,
                        right,
                        constantValue,
                      ])
                    }
                  />
                ) : (
                  <NextbrainSelect
                    type={'dark'}
                    value={left ? { label: left, value: left } : null}
                    onChange={(value) => {
                      onChange([value.value, right, constantValue])
                      if (!right)
                        rightRef.current?.querySelector('input')?.focus()
                    }}
                    options={optionsLeft}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    className={`basic-single`}
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="Data display"
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col
            xs={2}
            className="px-0 d-flex justify-content-center align-items-end"
            style={{ maxWidth: '30px' }}
          >
            <IoLink size={30} style={{ marginBottom: '10px' }} />
          </Col>
          <Col xs={6} style={{ maxWidth: 'calc(50% - 15px)' }}>
            <Row>
              <Col className="py-1 overflow-hidden" xs={12}>
                <Form.Check
                  type="switch"
                  label={
                    <span
                      title={t('Use constant value')}
                      className="smallp d-inline-block text-truncate"
                    >
                      {t('Use constant value')}
                    </span>
                  }
                  className="form-switch-share  d-flex"
                  checked={constantValue === 'right'}
                  onChange={async (e) => {
                    onChange([left, null, e?.target?.checked ? 'right' : null])
                  }}
                />
              </Col>
              <Col ref={rightRef} xs={12}>
                {constantValue === 'right' ? (
                  <Form.Control
                    type="text"
                    className="nb-input match-select-height"
                    placeholder={`Constant value`}
                    defaultValue={right ?? ''}
                    onChange={async (e) =>
                      onChangeDebounce([
                        left,
                        e.currentTarget.value,
                        constantValue,
                      ])
                    }
                  />
                ) : (
                  <NextbrainSelect
                    type={'dark'}
                    value={right ? { label: right, value: right } : null}
                    onChange={(value) => {
                      onChange([left, value.value, constantValue])
                      if (!left)
                        leftRef.current?.querySelector('input')?.focus()
                    }}
                    options={optionsRight}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    className={`basic-single`}
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="Data display"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={1} className="d-flex align-items-end align-self-end mb-1">
        {deletable && <DeleteRowButton onClick={onDelete} />}
      </Col>
    </Row>
  )
}

function ColumnControl({
  columnsPaired,
  setColumnsPaired,
  leftColumns,
  rightColumns,
}) {
  const { t } = useTranslation()
  const leftOptions = useMemo(() => {
    return leftColumns.map((c) => ({ value: c, label: c }))
  }, [leftColumns])

  const rightOptions = useMemo(() => {
    return rightColumns.map((c) => ({ value: c, label: c }))
  }, [rightColumns])

  return (
    <Row className="justify-content-center datasource-merge-column-pairs  my-2 w-100">
      <Col xs={12}>
        <Row className="justify-content-between align-items-center">
          <Col xs={12}>
            <span>
              <strong>Join Columns</strong>
            </span>
          </Col>
        </Row>
      </Col>
      <Col
        className="datasource-merge-column-pairs-content mt-2"
        xl={10}
        sm={12}
      >
        {(columnsPaired ?? []).map((c, i) => (
          <ColumnPair
            key={i}
            left={c[0]}
            deletable={columnsPaired.length > 1}
            right={c[1]}
            constantValue={c[2]}
            optionsLeft={leftOptions}
            optionsRight={rightOptions}
            onDelete={() => {
              columnsPaired.splice(i, 1)
              setColumnsPaired([...columnsPaired])
            }}
            onChange={(value) => {
              columnsPaired[i] = value
              setColumnsPaired([...columnsPaired])
            }}
          />
        ))}
      </Col>
      <Col
        className="mt-2 mb-3 d-flex justify-content-center align-items-center d-none"
        xs={11}
      >
        <AddRowButton
          className="px-5"
          onClick={() => setColumnsPaired((p) => [...p, [null, null, null]])}
          tooltip={t('Add column pair')}
        />
      </Col>
    </Row>
  )
}

const joinOptions = [
  { value: 'left', label: 'Left join (Based on top node)' },
  { value: 'right', label: 'Right join  (Based on bottom node)' },
  { value: 'outer', label: 'Outer join' },
  { value: 'inner', label: 'Inner join' },
]

const joinExplanation = {
  left: 'Entries from the left table are included in the result, even if there is no match in the right table.',
  right:
    'Entries from the right table are included in the result, even if there is no match in the left table.',
  outer:
    'Entries from both tables are included in the result, even if there is no match in the other table.',
  inner:
    'Only entries that have a match in both tables are included in the result.',
}

export default function ConfigureMerge({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [leftNode, setLeftNode] = useState(null)
  const [rightNode, setRightNode] = useState(null)
  const [sample, setSample] = useState(null)
  const [avoidDuplicatesLeft, setAvoidDuplicatesLeft] = useState(
    configuration?.avoidDuplicatesLeft ?? true,
  )
  const [avoidDuplicatesRight, setAvoidDuplicatesRight] = useState(
    configuration?.avoidDuplicatesRight ?? true,
  )
  const [joinType, setJoinType] = useState(() => {
    const option = joinOptions.find((o) => o.value === configuration?.joinType)
    if (option) return option
    return joinOptions[0]
  })
  const [visibleColumns, setVisibleColumns] = useState(() => {
    if (configuration?.visibleColumns)
      return configuration?.visibleColumns.map((c) => ({ value: c, label: c }))

    return []
  })
  const [columnsPaired, setColumnsPaired] = useState(
    configuration?.columnsPaired ?? [[null, null, null]],
  )
  const visibleColumnsOptions = useRef(null)

  useEffect(() => {
    if (columnsPaired.every(([left, right]) => left && right)) {
      setColumnsPaired([...columnsPaired, [null, null, null]])
    }
  }, [columnsPaired])

  useEffect(() => {
    const leftEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_left_target`,
    )
    const rightEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_right_target`,
    )
    if (!leftEdge) {
      NotificationManager.error('Missing top connection')
      close()
    } else if (!rightEdge) {
      NotificationManager.error('Missing bottom connection')
      close()
    } else {
      const leftNode = getNode(leftEdge.source)
      const rightNode = getNode(rightEdge.source)
      if (!leftNode?.data?.valid || !rightNode?.data?.valid) {
        NotificationManager.error(
          'Connected nodes are invalid, configure them first',
        )
        close()
      } else {
        if (!leftNode?.data?.sample || !rightNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          visibleColumnsOptions.current = [
            ...rightNode.data.sample.columns,
            ...leftNode.data.sample.columns,
          ].map((v) => ({ value: v, label: v }))

          setRightNode(rightNode)
          setLeftNode(leftNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!leftNode || !rightNode) return <Loading />

  const validJoins =
    columnsPaired.some(([left, right]) => left && right) && sample

  return (
    <Row>
      <Col className="my-2" xs={12}>
        <Row>
          <Col xs={12}>
            <Row className="w-100">
              <Col xs={12}>
                <strong>{t('Join type')}</strong>
              </Col>
              <Col xs={12}>
                <span className="smallp">
                  {t(
                    'Select how the rows from the table on the left are matched with the table on the right.',
                  )}
                </span>
              </Col>
              <Col xs={12}>
                <Row className="mt-1 justify-content-center">
                  <Col
                    className="position-relative overflow-hidden"
                    xl={2}
                    md={3}
                    xs={6}
                  >
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        setJoinType(joinOptions[0])
                      }}
                    >
                      <Col
                        xs={12}
                        className="text-center smallp mt-3"
                        style={{ marginBottom: '-12px' }}
                      >
                        {t('Left join')}
                      </Col>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          className="merge-image-ind"
                          src={'/merge_left.png'}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center" xs={12}>
                        <Form.Check
                          className="mt-2 pe-none rounded-circle-check"
                          checked={joinType.value === 'left'}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="position-relative overflow-hidden"
                    xl={2}
                    md={3}
                    xs={6}
                  >
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        setJoinType(joinOptions[1])
                      }}
                    >
                      <Col
                        xs={12}
                        className="text-center smallp mt-3"
                        style={{ marginBottom: '-12px' }}
                      >
                        {t('Right join')}
                      </Col>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          className="merge-image-ind"
                          src={'/merge_right.png'}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center" xs={12}>
                        <Form.Check
                          className="mt-2 pe-none rounded-circle-check"
                          checked={joinType.value === 'right'}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="position-relative overflow-hidden"
                    xl={2}
                    md={3}
                    xs={6}
                  >
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        setJoinType(joinOptions[3])
                      }}
                    >
                      <Col
                        xs={12}
                        className="text-center smallp mt-3"
                        style={{ marginBottom: '-12px' }}
                      >
                        {t('Inner join')}
                      </Col>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          className="merge-image-ind"
                          src={'/merge_inner.png'}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center" xs={12}>
                        <Form.Check
                          className="mt-2 pe-none rounded-circle-check"
                          checked={joinType.value === 'inner'}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="position-relative overflow-hidden"
                    xl={2}
                    md={3}
                    xs={6}
                  >
                    <Row
                      className="cursor-pointer"
                      onClick={() => {
                        setJoinType(joinOptions[2])
                      }}
                    >
                      <Col
                        xs={12}
                        className="text-center smallp mt-3"
                        style={{ marginBottom: '-12px' }}
                      >
                        {t('Outer join')}
                      </Col>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          className="merge-image-ind"
                          src={'/merge_outer.png'}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center" xs={12}>
                        <Form.Check
                          className="mt-2 pe-none rounded-circle-check"
                          checked={joinType.value === 'outer'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-3">
              {joinExplanation[joinType?.value] && (
                <Col className="d-flex justify-content-center" xs={12}>
                  <span className="explanation-merge-join p-2">
                    {t(joinExplanation[joinType?.value] ?? '')}
                  </span>
                </Col>
              )}
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <Row>
              <Col className="d-block mt-3" xs={12}>
                <strong>{t('Visible columns')}</strong>
                <span className="ms-1">
                  <small>({t('Leave empty for all')})</small>
                </span>
              </Col>
              <Col md={12}>
                <NextbrainSelect
                  value={visibleColumns}
                  onChange={(value) => setVisibleColumns(value)}
                  className="mt-2"
                  options={visibleColumnsOptions.current}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  allowSelectAll={true}
                />
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex align-items-end justify-content-center"
            xs={12}
            md={6}
          >
            <Row className="mt-2 flex-column justify-content-end">
              <Col className="d-inline-block pe-0" xs={12}>
                <Form.Check
                  type="switch"
                  checked={avoidDuplicatesLeft}
                  className="form-switch-flow label-no-margin"
                  label={<span>{t('Avoid left duplicate indexes')}</span>}
                  onChange={(e) => setAvoidDuplicatesLeft(e.target.checked)}
                />
              </Col>
              <Col className="mt-2 pe-0" xs={12}>
                <Form.Check
                  type="switch"
                  checked={avoidDuplicatesRight}
                  className="form-switch-flow label-no-margin"
                  label={<span>{t('Avoid right duplicate indexes')}</span>}
                  onChange={(e) => setAvoidDuplicatesRight(e.target.checked)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <hr className="my-2"></hr>
      <Col className="mt-2" xs={12}>
        <ColumnControl
          columnsPaired={columnsPaired}
          setColumnsPaired={setColumnsPaired}
          leftColumns={leftNode.data.sample.columns}
          rightColumns={rightNode.data.sample.columns}
        />
      </Col>
      <Col className="mt-2" xs={12}>
        <span>
          <strong>{t('Preview')}</strong>
        </span>
      </Col>
      <Col
        className="my-2 merge-table-flow position-relative"
        style={{
          overflow: 'auto',
          position: 'relative',
          zIndex: 0,
          maxHeight: '250px',
          minHeight: '250px',
        }}
      >
        {leftNode && rightNode && (
          <MergeTable
            sampleLeft={leftNode.data.sample}
            sampleRight={rightNode.data.sample}
            joinType={joinType.value}
            joinColumns={columnsPaired.filter((v) => v[0] && v[1])}
            visibleColumns={visibleColumns.map((v) => v.value)}
            avoidDuplicatesLeft={avoidDuplicatesLeft}
            avoidDuplicatesRight={avoidDuplicatesRight}
            onDataLoad={(data) => setSample(data)}
          />
        )}
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!validJoins || !sample}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              left: leftNode.id,
              right: rightNode.id,
              joinType: joinType.value,
              visibleColumns: visibleColumns.map((v) => v.value),
              columnsPaired: columnsPaired.filter((v) => v[0] && v[1]),
              avoidDuplicatesLeft,
              avoidDuplicatesRight,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
