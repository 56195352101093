import React, { useState, useRef } from 'react'
import {
  Col,
  Row,
  OverlayTrigger,
  Form,
  Tooltip,
  Button,
  Modal,
} from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import { FcCancel } from 'react-icons/fc'
import { BsPencil } from 'react-icons/bs'
import { BsCalendarPlus, BsCalendarX } from 'react-icons/bs'
import { FaUser, FaUserTie, FaUsersCog, FaPencilAlt } from 'react-icons/fa'

import { InlineEdit } from '../inline-edit/InlineEdit'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery, useQueryClient } from 'react-query'

import NextbrainSelect from '../model-content/NextbrainSelect'
import {
  editRole,
  getCompanyUsers,
  inviteUser,
  removeUser,
  renameCompany,
} from '../../services/user'
import { t } from 'i18next'
import Loading from '../loading/LoadingSmall'
import { enforceValidation } from '../../util/validation'
import { NotificationManager } from 'react-notifications'
import BouncyButton from '../bouncy-button/BouncyButton'
import './Account.css'
import ConfirmButton from '../confirm-button/ConfirmButton'

function UserLogo({ role, ...props }) {
  switch (role) {
    case 'owner':
      return <FaUserTie {...props} />
    case 'admin':
      return <FaUsersCog {...props} />
    default:
      return <FaUser {...props} />
  }
}

function CompanyUsers({ users, owner, company }) {
  const [selectedRole, setSelectedRole] = useState('user')
  const [showNewUser, setShowNewUser] = useState(false)
  const [inviting, setInviting] = useState()
  const userRef = useRef(null)
  const { token, signout, user } = useAuth()
  const baseUser = user
  const queryClient = useQueryClient()
  const limit = company?.subscription?.config?.user_seats ?? 1
  const canAdd = limit > users.length

  const roles = ['owner', 'admin', 'user'].map((v) => ({
    label: (
      <Row>
        <Col className="d-flex align-items-end" xs={12}>
          <UserLogo size={20} className="mx-2" role={v} />
          {t(v)}
        </Col>
      </Row>
    ),
    value: v,
  }))

  const createUser = async () => {
    const email = userRef.current.value
    if (!email) {
      NotificationManager.error(t('Invalid email address'))
      return
    }
    if (!selectedRole) {
      NotificationManager.error(t('Invalid role'))
      return
    }
    if (users.find((u) => u.email === email)) {
      NotificationManager.error(t(`${email} already in company`))
      return
    }
    setInviting(true)
    inviteUser({
      companyId: company.id,
      invite: email,
      role: selectedRole,
      token,
      signout,
    })
      .then((c) => {
        if (c?.ok) {
          userRef.current.value = ''
          queryClient.invalidateQueries(['users-company', company?.id])
          NotificationManager.success(t('User has been invited to company'))
          setShowNewUser(false)
        } else NotificationManager.error(t(c?.detail ?? 'Error inviting user'))
      })
      .catch((e) => {
        console.error(e)
        NotificationManager.error(t('Error inviting user'))
      })
      .finally(() => setInviting(false))
  }
  return (
    <Row className="align-items-start">
      <Row className="justify-content-end">
        <Col xs="auto" className="pe-0">
          <Button
            className="p-2 px-4"
            disabled={!canAdd}
            onClick={() => setShowNewUser(true)}
          >
            {t('New user')}
          </Button>
        </Col>
      </Row>
      <Row
        xs={12}
        className="p-2 mt-2 d-flex align-items-center"
        style={{
          border: '1px solid var(--nextbrain-secondary-border-color)',
          borderRadius: '17px',
        }}
      >
        <Col xs={12} key={user.email}>
          <Row className="pt-2 pb-4 h4">
            <Col className="d-flex-center text-center" xs={2}>
              {t('Name')}
            </Col>
            <Col className="d-flex-center text-center" xs={5}>
              {t('Email')}
            </Col>
            <Col className="d-flex-center text-center" xs={3}>
              {t('Role')}
            </Col>
            <Col xs={2} className="d-flex-center text-center">
              {t('Actions')}
            </Col>
          </Row>
        </Col>

        {users.map(
          (user) =>
            user.id !== baseUser.id && (
              <Col xs={12} key={user.email}>
                <Row
                  className="py-2"
                  style={{
                    borderTop:
                      '1px solid var(--nextbrain-secondary-border-color)',
                  }}
                >
                  <Col className="d-flex-center text-center" xs={2}>
                    {user.name}
                  </Col>
                  <Col className="d-flex-center text-center" xs={5}>
                    <div
                      className="d-inline-block text-truncate w-100"
                      title={user.email}
                    >
                      {user.email}
                    </div>
                  </Col>
                  <Col className="d-flex-center text-center" xs={3}>
                    <div className="w-auto d-inline-block">
                      <NextbrainSelect
                        value={roles.find((v) => v.value === user.role)}
                        isDisabled={user.role === 'owner' && !owner}
                        onChange={async (c) => {
                          if (c?.value) {
                            editRole({
                              userId: user.id,
                              companyId: company.id,
                              role: c.value,
                              token,
                              signout,
                            })
                              .then((r) => {
                                if (r?.ok)
                                  queryClient.invalidateQueries([
                                    'users-company',
                                    company?.id,
                                  ])
                                else
                                  NotificationManager.error(
                                    t(r?.detail ?? 'Error editing role'),
                                  )
                              })
                              .catch((e) => {
                                console.error(e)
                                NotificationManager.error(
                                  t('Error editing role'),
                                )
                              })
                          }
                        }}
                        options={
                          owner
                            ? roles
                            : roles.filter((r) => r.value !== 'owner')
                        }
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        className={`basic-single`}
                        classNamePrefix="select"
                        type="empty"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex-center text-center" xs={2}>
                    <ConfirmButton
                      asOutside={Col}
                      className="original cursor-pointer half-opacity-on-hover"
                      style={{ marginLeft: 12 }}
                      variant="danger"
                      disabled={user.role === 'owner' && !owner}
                      title={t('Remove user')}
                      onClick={() => {
                        removeUser({
                          userId: user.id,
                          companyId: company.id,
                          token,
                          signout,
                        }).then((r) => {
                          if (r?.ok) {
                            queryClient.invalidateQueries([
                              'users-company',
                              company?.id,
                            ])
                          } else {
                            NotificationManager.error(
                              t(r?.detail ?? 'Error removing user'),
                            )
                          }
                        })
                      }}
                    >
                      <MdDelete size={24} className="mt-2" />
                    </ConfirmButton>
                  </Col>
                </Row>
              </Col>
            ),
        )}
      </Row>
      <Modal show={showNewUser} onHide={() => setShowNewUser(false)}>
        <Modal.Header closeButton>
          <h5>{t('Add user to company')}</h5>
        </Modal.Header>
        <Modal.Body>
          <Col className="pb-2" xs={12}>
            <div className="enforced-validation-container position-relative">
              {canAdd ? (
                <FaPencilAlt
                  className="form-input-icon-left-icon"
                  size={20}
                  style={{ left: '10px', top: 'calc(50% - 10px)' }}
                />
              ) : (
                <FcCancel
                  className="form-input-icon-left-icon"
                  size={25}
                  style={{ left: '8px', top: 'calc(50% - 12px)' }}
                />
              )}
              <Form.Control
                className="nb-input-soft py-2 form-input-icon-left"
                ref={userRef}
                onBlur={enforceValidation({ email: true })}
                placeholder={
                  canAdd ? t('Invite email') + '...' : 'No seats remaining'
                }
                disabled={!canAdd}
              />
            </div>
          </Col>
          <Col className="py-2" xs={12}>
            <NextbrainSelect
              value={roles.find((r) => r.value === selectedRole)}
              onChange={(c) => (c?.value ? setSelectedRole(c.value) : null)}
              options={owner ? roles : roles.filter((r) => r.value !== 'owner')}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single`}
              classNamePrefix="select"
            />
          </Col>
          <Col xs={12} className="d-flex align-items-center mt-4">
            <BouncyButton
              disabled={inviting || !canAdd}
              onClick={createUser}
              className="w-100"
              title={t(canAdd ? 'Invite user' : 'Max users reached')}
            >
              {t('Confirm')}
            </BouncyButton>
          </Col>
        </Modal.Body>
      </Modal>
    </Row>
  )
}

function CompanyManager({ company, ...props }) {
  const { token, signout } = useAuth()

  const { data, isLoading } = useQuery(
    ['users-company', company?.id],
    async () => {
      return company
        ? await getCompanyUsers({ companyId: company.id, token, signout })
        : []
    },
    { enabled: !!company?.id, staleTime: 60000 },
  )

  if (!company) return <></>

  if (company?.role === 'user') {
    const { from_date, to_date } = company?.subscription ?? {}
    return (
      <>
        <Col style={{ maxWidth: '200px' }} xs={4}>
          <Row>
            {!company?.subscription && (
              <Col xs={12} className="mt-2">
                {t('No plan')} / {t('Trial')}
              </Col>
            )}
            {(from_date || to_date) && (
              <Col xs={12} className="h4">
                {t(`Subscription`)}
              </Col>
            )}
            {from_date && (
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>{t(`Plan start`)}.</Tooltip>
                )}
              >
                <Col
                  xs={'auto'}
                  className=" d-flex align-items-center badge-plan-dates p-3"
                >
                  <BsCalendarPlus className="me-3" size={30} />{' '}
                  {new Date(from_date).toLocaleDateString()}
                </Col>
              </OverlayTrigger>
            )}
            <Col xs={12}></Col>
            {to_date && (
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>{t(`Plan end`)}.</Tooltip>
                )}
              >
                <Col
                  xs={'auto'}
                  className="mt-3 d-flex align-items-center badge-plan-dates p-3"
                >
                  <BsCalendarX className="me-3" size={30} />{' '}
                  {new Date(to_date).toLocaleDateString()}
                </Col>
              </OverlayTrigger>
            )}
          </Row>
        </Col>
        <Col style={{ maxWidth: 'calc(100% - 200px)' }}>
          <Row>
            <Col xs={12} className="h4">
              {t(`Owners`)}
            </Col>
            {(Array.isArray(data) ? data : [])
              .filter((u) => u.role === 'owner')
              .map((u) => (
                <Col
                  className="mb-3 d-inline-block text-truncate"
                  key={u.id}
                  md={12}
                  xl={6}
                >
                  <a
                    className="h5 d-flex align-items-center  p-1"
                    href={`mailto:${u.email}`}
                  >
                    <FaUserTie className="me-2" size={20} />
                    {u.email}
                  </a>
                </Col>
              ))}
          </Row>
        </Col>
      </>
    )
  }

  return (
    <Col xs={12}>
      {isLoading ? (
        <Loading />
      ) : (
        <Row>
          <Col xs={12}>
            <CompanyUsers
              key={company.id}
              owner={company.role === 'owner'}
              users={Array.isArray(data) ? data : []}
              company={company}
            />
          </Col>
        </Row>
      )}
    </Col>
  )
}

export default function Company({ ...props }) {
  const { user, token, signout, reloadUser } = useAuth()
  const company = user?.company ?? user?.companies?.[0] ?? {}
  const editRef = useRef()
  return (
    <Row>
      <Col
        className="mt-2 d-inline-block text-nowrap overflow-auto pt-1 overflow-hidden"
        xs={12}
      >
        <h3 className="d-inline-block">{t('Company')}:</h3>
        <h4 className="d-inline-block ms-1">
          <i>
            {company?.name ? (
              company.role === 'owner' ? (
                <>
                  <InlineEdit
                    ref={editRef}
                    text={company?.name}
                    onEdit={(text) => {
                      renameCompany({
                        newName: text,
                        token,
                        signout,
                      })
                        .then((r) => {
                          if (r?.ok) {
                            reloadUser()
                            NotificationManager.success('Company updated')
                          } else {
                            NotificationManager.error(
                              'Failed to update company',
                            )
                          }
                        })
                        .catch((r) => {
                          NotificationManager.error('Failed to update company')
                        })
                    }}
                  />
                  <BsPencil
                    size={25}
                    style={{
                      marginLeft: '5px',
                      marginTop: '-5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      editRef?.current?.click()
                    }}
                  />
                </>
              ) : (
                company?.name
              )
            ) : (
              t('No company')
            )}
          </i>
        </h4>
      </Col>
      <Col
        className=" profile-section-separator d-inline-block text-nowrap overflow-auto pt-1 overflow-hidden"
        xs={12}
      >
        <h3 className="d-inline-block">{t('Role')}:</h3>
        <h3 className="d-inline-block ms-1">
          <strong>
            <i>{company?.role ?? t('No role')}</i>
          </strong>
        </h3>
      </Col>
      <Col xs={12} className="mb-3">
        <Row>
          <CompanyManager company={company} />
        </Row>
      </Col>
    </Row>
  )
}
