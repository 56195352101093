import React, { useState, useMemo } from 'react'
import {
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
  Form,
  Button,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import { AiOutlineReload } from 'react-icons/ai'
import { ImMagicWand } from 'react-icons/im'
import { BsPlusSquareDotted } from 'react-icons/bs'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

export function AddCard({ creatingNew, ...props }) {
  const { t } = useTranslation()
  return (
    <Row
      {...props}
      className={`
      ${props?.className ?? ''} ${
        creatingNew ? 'pe-none' : ''
      } lida-visualization-card lida-visualization-card-add 
      p-2 mx-2 flex-column justify-content-center h-100 cursor-pointer`}
    >
      <Col className="color-white bold dflex-center " xs={12}>
        {creatingNew ? (
          <AiOutlineReload
            className="edit-lida-visualization-working"
            size={130}
          />
        ) : (
          <BsPlusSquareDotted size={130} />
        )}
      </Col>
      {creatingNew && (
        <Col className="text-center mt-2" xs={12}>
          <div className="loading-tooltip">{t('Generating chart')}</div>
        </Col>
      )}
    </Row>
  )
}

export default function Visualization({
  visualization,
  active,
  onEdit,
  onWand,
  onCheck,
  onDelete,
  checked,
  showRationale = true,
  showTitle = true,
  children,
  ...props
}) {
  const { t } = useTranslation()
  const [showDelete, setShowDelete] = useState(false)
  const [question, rationale, image] = useMemo(() => {
    return [
      visualization?.goal?.question,
      visualization?.goal?.rationale,
      `data:image/png;base64,${visualization.visualizations?.[0]?.raster}`,
    ]
  }, [visualization])

  const [activeImage, setActiveImage] = useState(false)

  return (
    <Row
      {...props}
      className={`lida-visualization-card  ${
        props?.className ?? ''
      } flex-column justify-content-between h-100 position-relative`}
    >
      {onDelete && (
        <>
          <div
            className="delete-lida-visualization-card"
            onClick={() => setShowDelete(true)}
          >
            <FaTrash size={25} />
          </div>
          <div
            className={`delete-overlay-confirm ${
              showDelete ? 'active-overlay' : ''
            }`}
          >
            <FaTrash className="bg-delete" size={225} />
            <Row
              className="text-center position-relative"
              style={{ zIndex: 2 }}
            >
              <Col xs={12} className="color-white">
                <strong>{t('Delete the current visualization?')}</strong>
              </Col>
              <Col className="mt-2" xs={12}>
                <Button
                  className="me-1 original"
                  variant="success"
                  onClick={onDelete}
                >
                  {t('Confirm')}
                </Button>
                <Button
                  className="ms-1 original"
                  variant={'warning'}
                  onClick={() => setShowDelete(false)}
                >
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
      <Col className="color-white bold" xs={12}>
        <Row>
          {showTitle && (
            <Col xs={12} style={{ maxWidth: 'calc(100% - 70px' }}>
              {question}
            </Col>
          )}
          <Col
            className="d-inline-flex flex-nowrap justify-content-between ps-0"
            xs={2}
            style={{ maxWidth: '70px' }}
          >
            {onCheck && (
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement="auto"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`${props?.className ?? ''} pe-none`}
                  >
                    {checked
                      ? t('Ignore this suggestion')
                      : t('Add this suggestion to the report')}
                  </Tooltip>
                )}
              >
                <Form.Check
                  checked={checked}
                  className="form-switch-share w-100"
                  type="checkbox"
                  onChange={(e) => onCheck(e.target.checked)}
                />
              </OverlayTrigger>
            )}
            {active ? (
              <span className="d-inline-flex justify-content-end w-100">
                <AiOutlineReload
                  className="edit-lida-visualization-working"
                  size={20}
                />
              </span>
            ) : (
              <>
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="auto"
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`${props?.className ?? ''} pe-none`}
                    >
                      {t('Generate similar chart')}
                    </Tooltip>
                  )}
                >
                  <span>
                    <ImMagicWand
                      className={`edit-lida-visualization ${
                        onWand ? '' : 'disabled-control'
                      }`}
                      size={20}
                      onClick={onWand}
                    />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="auto"
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`${props?.className ?? ''} pe-none`}
                    >
                      {t('Refine this chart')}
                    </Tooltip>
                  )}
                >
                  <span>
                    <FaPencilAlt
                      onClick={onEdit}
                      className={`edit-lida-visualization ${
                        onEdit ? '' : 'disabled-control'
                      }`}
                      size={20}
                    />
                  </span>
                </OverlayTrigger>
              </>
            )}
          </Col>
          <Col className="position-relative dflex-center pt-4 pb-1" xs={12}>
            {activeImage && (
              <Lightbox
                mainSrc={image}
                onCloseRequest={() => setActiveImage(false)}
              />
            )}
            <Image
              className="image-preview-lida"
              onClick={() => setActiveImage(true)}
              src={image}
            />
          </Col>
        </Row>
      </Col>
      {showRationale && (
        <Col className="smallp text-center px-4 pt-3 mb-2" xs={12}>
          {rationale}
        </Col>
      )}
      {children}
    </Row>
  )
}
