import { useEffect, useRef } from 'react'
import { forwardRef } from 'react'
import { useState } from 'react'
import './InlineEdit.css'

export const InlineEdit = forwardRef(
  ({ onEdit = () => {}, text, area = false, ...props }, ref) => {
    const [savedText, setSavedText] = useState(text)
    const [editing, setEditing] = useState(false)
    //Seems redundant but fixes an issue with scroll margin when switching from input->span
    const [newText, setNewText] = useState(false)
    const editRef = useRef()

    const afterEdit = () => {
      const newName = editRef.current.value
      editRef.current.value = ''
      if (newName !== savedText) {
        onEdit(newName)
        setNewText(newName)
      } else {
        setNewText(savedText)
      }
      setSavedText('')
      setEditing(false)
    }

    useEffect(() => {
      if (editing && editRef.current) editRef.current.select()
    }, [editing, editRef])

    useEffect(() => {
      if (newText) {
        setNewText(null)
        setSavedText(newText)
      }
      // eslint-disable-next-line
    }, [newText])

    const { className } = { ...props }

    return editing ? (
      area ? (
        <textarea
          ref={editRef}
          autoFocus
          onBlur={afterEdit}
          defaultValue={savedText}
          onKeyDown={(event) => {
            event.stopPropagation()
            if (event.key === 'Enter' || event.key === 'Escape') {
              afterEdit()
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
            setEditing(true)
          }}
          rows="2"
          {...props}
          className={`invisibleInput ${props.className}`}
        />
      ) : (
        <input
          ref={editRef}
          autoFocus
          onBlur={afterEdit}
          defaultValue={savedText}
          onKeyDown={(event) => {
            event.stopPropagation()
            if (event.key === 'Enter' || event.key === 'Escape') {
              afterEdit()
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
            setEditing(true)
          }}
          {...props}
          className={`invisibleInput ${props.className}`}
        />
      )
    ) : (
      <span
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setEditing(true)
        }}
        {...props}
        className={`editableText ${className}`}
      >
        {savedText}
      </span>
    )
  },
)
