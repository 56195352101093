import React, { useState, useMemo, useRef } from 'react'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Popover,
  Modal,
} from 'react-bootstrap'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
  FaFileDownload,
  FaFileUpload,
  FaTrash,
  FaSpinner,
  FaEdit,
  FaTag,
} from 'react-icons/fa'
import { FcHighPriority, FcMindMap } from 'react-icons/fc'

import { useAuth } from '../../providers/AuthProvider'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import 'bootstrap/dist/css/bootstrap.css'
import { downloadDocument } from '../../services/document'
import EditDocument from './EditDocument'
import { Tag } from './TagManager'
import Csv from './file-icons/csv'
import Doc from './file-icons/doc'
import Pdf from './file-icons/pdf'
import Py from './file-icons/py'
import Xls from './file-icons/xls'
import Txt from './file-icons/txt'
import Html from './file-icons/html'
import Ppt from './file-icons/ppt'
import MindMap from './MindMap'

export default function Document({
  doc,
  mode = 'automl',
  onDelete,
  onUpdate,
  ...props
}) {
  const document = doc
  const menuRef = useRef()
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)
  const [edit, setEdit] = useState(null)
  const [showMindMap, setShowMindMap] = useState(false)

  let updated = document.updated_at
  if (isNaN(updated)) updated = new Date()
  const dateStr = `${updated.getDate()} ${updated.toLocaleString('default', {
    month: 'short',
  })} ${updated.getFullYear()}`

  const name = useMemo(() => {
    return (document?.name ?? 'Untitled')
      .split(' ')
      .map((word) => {
        if (word.length > 22) {
          return word.slice(0, 20) + '.. '
        }
        return word
      })
      .join(' ')
  }, [document])

  // All icons are extracted from: https://www.svgrepo.com/collection/files-types/
  let documentIcon = <Txt className="custom-svg" />
  switch (document?.name.split('.').pop()) {
    case 'pdf':
      documentIcon = <Pdf className="custom-svg" />
      break
    case 'doc':
    case 'docx':
      documentIcon = <Doc className="custom-svg" />
      break
    case 'csv':
      documentIcon = <Csv className="custom-svg" />
      break
    case 'xlsx':
    case 'xls':
      documentIcon = <Xls className="custom-svg" />
      break
    case 'ppt':
    case 'pptx':
      documentIcon = <Ppt className="custom-svg" />
      break
    case 'py':
      documentIcon = <Py className="custom-svg" />
      break
    case 'html':
      documentIcon = <Html className="custom-svg" />
      break
    default:
      documentIcon = <Txt className="custom-svg" />
  }
  const documentLoading = document?.status === 'loading'
  const documentError = document?.status === 'error'

  if (documentLoading) {
    documentIcon = (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'auto'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <span className="loading-tooltip pe-3">{t('Importing')}</span>
          </Tooltip>
        )}
      >
        <span>
          <FaSpinner size={50} className="rotating-2" />
        </span>
      </OverlayTrigger>
    )
  } else if (documentError) {
    documentIcon = (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'auto'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <span className="">Failed import</span>
          </Tooltip>
        )}
      >
        <span>
          <FcHighPriority size={50} className="" />
        </span>
      </OverlayTrigger>
    )
  }

  return (
    <>
      {showMindMap && (
        <Modal
          show={true}
          onHide={() => setShowMindMap(false)}
          size={'xl'}
          className="modal-xxl"
        >
          <Modal.Header className="d-flex align-items-center" closeButton>
            <FcMindMap size={25} />
            <span className="h5 ms-2 d-flex align-items-center mb-0">
              {t('Mindmap of')}
              {` ${document.name}`}
            </span>
          </Modal.Header>
          <Modal.Body>
            <MindMap markup={document?.markmap} />
          </Modal.Body>
        </Modal>
      )}
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="auto"
        delay={{ show: 200, hide: 0 }}
        overlay={(props) =>
          doc?.summary && !menuVisible ? (
            <Popover
              {...props}
              style={{
                ...props.style,
                pointerEvents: 'none',
                minWidth: 'max(300px, 50vw)',
              }}
            >
              <div
                className="p-4 text-center smallp"
                style={{
                  width: 'max(300px, 50vw)',
                  backgroundColor: 'var(--nextbrain-body-75)',
                  border: '2px solid var(--nextbrain-background)',
                  borderRadius: '5px',
                }}
              >
                <p>{doc?.summary}</p>
              </div>
            </Popover>
          ) : (
            <></>
          )
        }
      >
        <Row
          {...props}
          className={`${
            props?.className ?? ''
          } model-card-type-automl color-white contains-show-on-hover cursor-pointer position-relative`}
          key={'content'}
          onClick={() => menuRef.current.click()}
        >
          {doc?.tags?.length > 0 && (
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'top'}
              onToggle={(v) => setMenuVisible(v)}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) => (
                <Popover
                  {...props}
                  style={{
                    ...props.style,
                    transform: `${props?.style?.transform ?? ''} scale(0.75)`,
                  }}
                >
                  <Row
                    className="p-1 text-center smallp mx-0 justify-content-center"
                    style={{
                      backgroundColor: 'var(--nextbrain-body-75)',
                      border: '2px solid var(--nextbrain-background)',
                      borderRadius: '5px',
                    }}
                  >
                    {doc?.tags?.map((tag) => (
                      <Tag tag={tag} key={tag} />
                    ))}
                  </Row>
                </Popover>
              )}
            >
              <Col
                xs={'auto'}
                className="d-flex position-absolute justify-content-end smallp"
                style={{ right: '30px', marginTop: '-10px' }}
              >
                <FaTag /> <strong className="ms-1">{doc?.tags?.length}</strong>
              </Col>
            </OverlayTrigger>
          )}
          <Row className="justify-content-between w-100 px-0 mx-0 mt-2 ">
            <Col className="d-flex justify-content-center" xs={12}>
              {documentIcon}
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="right"
                delay={{ show: 250, hide: 100 }}
                onToggle={(v) => setMenuVisible(v)}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`context-menu-document   ${
                      props?.className ?? ''
                    }`}
                  >
                    <Row className="p-2">
                      {!documentLoading && document?.markmap && (
                        <Col
                          className="d-flex justify-content-start align-items-center cursor-pointer icon-btn mb-3"
                          xs={12}
                          onClick={() => setShowMindMap(true)}
                        >
                          <FcMindMap size={20} className="me-2" />{' '}
                          {t('Show Mindmap')}
                        </Col>
                      )}
                      {!documentLoading && (
                        <Col
                          className="d-flex justify-content-start align-items-center cursor-pointer icon-btn mb-3"
                          xs={12}
                          onClick={onUpdate}
                        >
                          <FaFileUpload size={20} className="me-2" />{' '}
                          {t(
                            document?.type === 'web'
                              ? 'Update Website'
                              : 'Update Document',
                          )}
                        </Col>
                      )}
                      {doc.type === 'file' && (
                        <Col
                          className="d-flex justify-content-start align-items-center cursor-pointer icon-btn mb-3"
                          xs={12}
                          onClick={(e) => {
                            downloadDocument({
                              documentId: document.id,
                              token,
                              signout,
                            })
                              .then((response) => response.blob())
                              .then((blob) => {
                                // Create blob link to download
                                const url = window.URL.createObjectURL(
                                  new Blob([blob]),
                                )
                                const link = window.document.createElement('a')
                                link.href = url
                                link.setAttribute('download', document.name)
                                window.document.body.appendChild(link)
                                link.click()
                                link.parentNode.removeChild(link)
                              })
                            document?.querySelector('body')?.click()
                          }}
                        >
                          <FaFileDownload size={20} className="me-2" />{' '}
                          {t('Download Document')}
                        </Col>
                      )}
                      {!documentLoading && (
                        <Col
                          className="d-flex justify-content-start align-items-center cursor-pointer icon-btn mb-3"
                          xs={12}
                          onClick={() => {
                            setEdit(
                              <EditDocument
                                document={document}
                                onHide={() => setEdit(null)}
                              />,
                            )
                          }}
                        >
                          <FaEdit size={20} className="me-2" /> {t('Edit')}
                        </Col>
                      )}
                      {!documentLoading && (
                        <Col
                          className="d-flex justify-content-start align-items-center cursor-pointer icon-btn"
                          xs={12}
                          onClick={() => {
                            onDelete()
                            document.querySelector('body')?.click()
                          }}
                        >
                          <FaTrash size={20} className="me-2" /> {t('Delete')}
                        </Col>
                      )}
                    </Row>
                  </Tooltip>
                )}
              >
                <Col
                  className="px-0 mx-0 float-right"
                  style={{ visibility: 'hidden', maxWidth: '0px' }}
                  xs={1}
                  ref={menuRef}
                >
                  <BiDotsVerticalRounded
                    onClick={() => $('body').click()}
                    onMouseUp={(e) => {
                      e.stopPropagation()
                    }}
                    className="hover-link"
                    style={{ width: '30px', height: '30px' }}
                  />
                </Col>
              </OverlayTrigger>
            </Col>
            <Col
              title={document?.name}
              xs={12}
              className="align-self-center d-flex justify-content-center"
            >
              <span
                className="text-center smallp"
                style={{ maxWidth: '140px' }}
              >
                {name}
              </span>
            </Col>
          </Row>
          <Row className="foot-card justify-content-center px-0 w-100 hidden-over-hover">
            <Col xs="auto" style={{ marginTop: 5, opacity: 0.6 }}>
              {dateStr}
            </Col>
            <Col
              xs={'auto'}
              className="d-flex align-items-end pe-0"
              style={{ marginRight: '-10px' }}
            >
              {document.size_bytes > 1024 * 1024
                ? `${Math.round(document.size_bytes / (1024 * 1024), 1)} mb`
                : `${Math.round(document.size_bytes / 1024, 0)} kb`}
            </Col>
          </Row>
        </Row>
      </OverlayTrigger>
      {edit}
    </>
  )
}
