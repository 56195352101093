import React, { useState, useMemo, useEffect } from 'react'
import { Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { useQuery, useQueryClient } from 'react-query'
import {
  getSyntheticData,
  getSyntheticSummary,
  requestSyntheticData,
} from '../../../services/model'
import { useAuth } from '../../../providers/AuthProvider'
import { BsInfoCircle } from 'react-icons/bs'
import { GrTest } from 'react-icons/gr'
import { GiConvergenceTarget } from 'react-icons/gi'
import { FaCircle, FaBalanceScaleRight, FaFileDownload } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { BouncyLoader, getTextWidth } from '../../utils/ui'
import { t } from 'i18next'
import NextbrainSelect, { Option } from '../NextbrainSelect'
import { ResponsiveBar } from '@nivo/bar'
import { useTranslation } from 'react-i18next'
import { defaultFormat, round } from '../../utils/formating'
import { ResponsiveLineCanvas } from '@nivo/line'
import { BasicTooltip } from '@nivo/tooltip'
import { useModels } from '../../../providers/ModelProvider'
import { awaitTask } from '../../../services/base'
import RoleDisable from '../../utils/RoleDisable'

const matchFloat = /^[+-]?\d+(\.\d+)?$/
function normalizeKeys(obj) {
  const res = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (!matchFloat.test(k) || Number.isNaN(Number.parseFloat(k))) res[k] = v
    else res[Number.parseFloat(k)] = v
  })
  return res
}

function Semaphore({ value }) {
  const pValue = value > 0.05
  const warning = value > 0.025 && value < 0.05

  return (
    <div className="d-flex flex-column p-1  semaphore-synthetic-data">
      <FaCircle
        color={'#f56057'}
        size={10}
        style={{
          opacity: !warning && !pValue ? 1 : 0.15,
        }}
      />
      <FaCircle
        style={{
          marginTop: '2px',
          marginBottom: '2px',
          opacity: warning ? 1 : 0.15,
        }}
        color={'#f9bc2f'}
        size={10}
      />
      <FaCircle
        color={'#3ec73e'}
        size={10}
        style={{
          opacity: !warning && pValue ? 1 : 0.15,
        }}
      />
    </div>
  )
}

function Histogram({ model, name, histogram, statistics, ...props }) {
  const { t } = useTranslation()
  const rangeCoverage = useMemo(() => {
    if (
      statistics &&
      statistics?.min !== null &&
      model?.dataset?.statistics?.[name]?.max !== null
    ) {
      const minSynthetic = Number.parseFloat(statistics.min)
      const maxSynthetic = Number.parseFloat(statistics.max)
      const minOriginal = Number.parseFloat(
        model?.dataset?.statistics?.[name]?.min,
      )
      const maxOriginal = Number.parseFloat(
        model?.dataset?.statistics?.[name]?.max,
      )
      let p1 = Math.max(
        (minSynthetic - minOriginal) / (maxOriginal - minOriginal),
        0,
      )
      let p2 = Math.max(
        (maxOriginal - maxSynthetic) / (maxOriginal - minOriginal),
        0,
      )
      if (Number.isNaN(p1)) p1 = 0
      if (Number.isNaN(p2)) p2 = 0
      const coverage = 1 - Math.max(p1 + p2, 0)

      return Number.isNaN(coverage)
        ? null
        : defaultFormat({ num: coverage, wrap: true })
    }
    // eslint-disable-next-line
  }, [])

  if (!histogram || typeof histogram !== 'object') return null

  if (histogram?.category) {
    const original = normalizeKeys(histogram?.original)
    const synthetic = normalizeKeys(histogram?.synthetic)
    const keySet = new Set(Object.keys(original).concat(Object.keys(synthetic)))
    const data = Array.from(keySet).map((k) => ({
      category: k,
      original: original[k] ?? 0,
      synthetic: synthetic[k] ?? 0,
    }))
    const totalOriginal = Object.values(original).reduce((a, b) => a + b, 0)
    const totalSynthetic = Object.values(synthetic).reduce((a, b) => a + b, 0)
    const keys = Math.max(
      Object.keys(original)?.length,
      Object.keys(synthetic)?.length,
    )
    const fontSize = Math.max(16 - keys * 0.5, 9)

    return (
      <Row className="mt-2 d-flex justify-content-center">
        <Col className="dflex-center py-1" xs={12}>
          <Row className="w-100">
            <Col
              className="d-flex align-items-center justify-content-start"
              xs={4}
            >
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'auto'}
                delay={{ show: 100, hide: 200 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <div className="p-2 text-left">
                      {t(
                        'The chi-square test tests the null hypothesis that the categorical data has the given frequencies.',
                      )}
                    </div>
                    <div className="p-2 h5">
                      {`p-value: `}
                      <span className="bold">
                        {Number.parseFloat(histogram.p).toExponential(3)}
                      </span>
                    </div>
                  </Tooltip>
                )}
              >
                <div
                  className="d-flex flex-nowrap align-items-center"
                  style={{ cursor: 'help' }}
                >
                  <span className="me-1">{t('Evaluation')}</span>
                  <Semaphore value={histogram?.p} />
                  <BsInfoCircle
                    className="ms-2"
                    style={{ alignSelf: 'flex-start' }}
                    size={15}
                  />
                </div>
              </OverlayTrigger>
            </Col>
            <Col
              className="d-flex justify-content-start align-items-center"
              xs={8}
            >
              {`${t('Distribution of')} ${name}`}
            </Col>
          </Row>
        </Col>
        {rangeCoverage && (
          <Col xs={12}>
            <span className="smallp ms-3">
              {t('Range coverage')}:{rangeCoverage}
            </span>
          </Col>
        )}
        <Col style={{ minHeight: '300px', maxHeight: '300px' }} xs={12}>
          <ResponsiveBar
            data={data}
            keys={['original', 'synthetic']}
            indexBy="category"
            margin={{ top: 40, right: 10, bottom: 50, left: 60 }}
            padding={0.1}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true, max: 'auto' }}
            defs={[]}
            fill={[]}
            enableLabel={false}
            enableGridY={false}
            colors={(d) => (d.id === 'synthetic' ? '#ff7f0e' : '#1f77b4')}
            axisTop={null}
            axisRight={null}
            tooltip={(props) => (
              <BasicTooltip
                id={`${props?.data?.category}`}
                value={`${props.value}, ${round(
                  (100 * props.value) /
                    (data?.id === 'original' ? totalOriginal : totalSynthetic),
                  1,
                )} % of total`}
                color={props.color}
                enableChip
              />
            )}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            theme={{
              fontSize: '13px',
              textColor: '#ADBAC7',
            }}
            legends={[]}
            layers={[
              'grid',
              'axes',
              'bars',
              'markers',
              'legends',
              'annotations',
              ({ bars, labelSkipWidth }) => {
                if (keys > 13) return <></>
                return (
                  <g>
                    {bars.map((props, index) => {
                      const { width, x, y, data } = props
                      const base =
                        data?.id === 'original' ? totalOriginal : totalSynthetic
                      const color =
                        data?.id === 'original' ? '#1f77b488' : '#ff7f0e88'
                      const text = `${
                        round((100 * data.formattedValue) / base, 1) ?? ''
                      }%`
                      const twidth = getTextWidth(
                        text,
                        `Open Sans ${fontSize}px`,
                      )
                      return (
                        <text
                          key={`${data.value}_${index}`}
                          transform={`translate(${
                            x + width / 2 - twidth / 2
                          }, ${y - 10})`}
                          fontSize={fontSize}
                          textAnchor="left"
                          dominantBaseline="central"
                          fontWeight="bold"
                          stroke={color}
                          strokeWidth={1}
                          fill="var(--nextbrain-white-font)"
                        >
                          {text}
                        </text>
                      )
                    })}
                  </g>
                )
              },
            ]}
          />
        </Col>
      </Row>
    )
  } else {
    const data = [
      {
        id: 'original',
        color: '#1f77b4',
        data: histogram?.original.x.map((x, i) => ({
          x: Number.parseFloat(x),
          y: Number.parseFloat(histogram?.original.y[i]),
        })),
      },
      {
        id: 'synthetic',
        color: '#ff7f0e',
        data: histogram?.synthetic.x.map((x, i) => ({
          x: Number.parseFloat(x),
          y: Number.parseFloat(histogram?.synthetic.y[i]),
        })),
      },
    ]
    return (
      <Row className="mt-2 d-flex justify-content-center">
        <Col className="dflex-center py-1" xs={12}>
          <Row className="w-100">
            <Col
              className="d-flex align-items-center justify-content-start"
              xs={4}
            >
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'auto'}
                delay={{ show: 100, hide: 200 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <div className="p-2 text-left">
                      {t(
                        'The Wilcoxon rank-sum test tests the null hypothesis that two sets of measurements are drawn from the same distribution.',
                      )}
                    </div>
                    <div className="p-2 h5">
                      {`p-value: `}
                      <span className="bold">
                        {Number.parseFloat(histogram.p).toExponential(3)}
                      </span>
                    </div>
                  </Tooltip>
                )}
              >
                <div
                  className="d-flex flex-nowrap align-items-center"
                  style={{ cursor: 'help' }}
                >
                  <span className="me-1">{t('Evaluation')}</span>
                  <Semaphore value={histogram?.p} />
                  <BsInfoCircle
                    className="ms-2"
                    style={{ alignSelf: 'flex-start' }}
                    size={15}
                  />
                </div>
              </OverlayTrigger>
            </Col>
            <Col
              className="d-flex justify-content-start align-items-center"
              xs={8}
            >
              {`${t('Distribution of')} ${name}`}
            </Col>
          </Row>
        </Col>
        {rangeCoverage && (
          <Col xs={12}>
            <span className="smallp ms-3">
              {t('Range coverage')}:{rangeCoverage}
            </span>
          </Col>
        )}
        <Col style={{ minHeight: '300px', maxHeight: '300px' }} xs={12}>
          <ResponsiveLineCanvas
            data={data}
            margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
            xScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            xFormat=">-.2f"
            yFormat=">-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 3,
              tickPadding: 5,
              legendOffset: 60,
              legendPosition: 'middle',
              tickRotation: -45,
            }}
            lineWidth={2}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
            }}
            enableGridX={false}
            colors={(d) => d.color}
            enableArea={false}
            enablePoints={false}
            enableSlices="x"
            pointSize={4}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            gridXValues={[0, 20, 40, 60, 80, 100, 120]}
            gridYValues={[0, 500, 1000, 1500, 2000, 2500]}
            legends={[]}
            theme={{
              fontSize: '13px',
              textColor: '#ADBAC7',
            }}
          />
        </Col>
      </Row>
    )
  }
}

function HistogramSummary({ model, histograms, statistics, ...props }) {
  const [activeKeys, setActiveKeys] = useState([])

  const options = useMemo(() => {
    if (histograms && typeof histograms === 'object')
      return Object.keys(histograms).map((k) => ({ label: k, value: k }))
    return []
  }, [histograms])

  useEffect(() => {
    if (histograms && typeof histograms === 'object')
      setActiveKeys(
        Object.keys(histograms)
          .slice(0, 5)
          .map((k) => ({ label: k, value: k })),
      )
  }, [histograms])

  return (
    <Row {...props}>
      <Col className="mb-2" xs={12}>
        <NextbrainSelect
          className="w-100"
          isSearchable={true}
          placeholder={'Select columns to show'}
          isDisabled={false}
          isClearable={true}
          options={options}
          value={activeKeys}
          onChange={(v) => setActiveKeys(v)}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
        />
      </Col>
      <Col className="py-2 dflex-center bold" xs={12}>
        <span className="pe-5 original-decorated" style={{ color: '#1f77b4' }}>
          {t('Original data')}
        </span>
        <span className=" synthetic-decorated" style={{ color: '#ff7f0e' }}>
          {t('Synthetic data')}
        </span>
      </Col>
      {activeKeys.map(({ label }) => (
        <Col className="py-1" key={label} xl={6} md={12} xs={12}>
          <Histogram
            model={model}
            name={label}
            histogram={histograms[label]}
            statistics={statistics?.[label]}
          />
        </Col>
      ))}
    </Row>
  )
}

export default function SyntheticDataSummary({ model, generate, ...props }) {
  const { token, signout } = useAuth()
  const [show, setShow] = useState(false)
  const [working, setWorking] = useState(false)
  const queryClient = useQueryClient()
  const { updateModel } = useModels()

  useEffect(() => {
    if (working) {
      getSyntheticData({ modelId: model.id, token, signout })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            (model?.dataset?.name ?? 'dataset') + '_synthetic_data.csv',
          )
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
        .catch((e) => {
          NotificationManager.error('No synthetic data found')
        })
        .finally(() => setWorking(false))
    }
    // eslint-disable-next-line
  }, [working])

  const { data, isLoading } = useQuery(
    ['synthetic-summary', model?.id],
    async () => {
      if (!model?.id) return null
      return await getSyntheticSummary({
        modelId: model?.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )
  const validSummary =
    model?.id && !isLoading && data && typeof data === 'object'

  const generatingSynthetic = model?.generating_synthetic_data
  useEffect(() => {
    if (generatingSynthetic) {
      const iv = setInterval(() => {
        getSyntheticSummary({ modelId: model?.id, token, signout }).then(
          (r) => {
            if (r) {
              model.generating_synthetic_data = false
              updateModel(model, model)
              queryClient.invalidateQueries(['synthetic-summary', model?.id])
            }
          },
        )
      }, 2500)
      return () => clearInterval(iv)
    }
    // eslint-disable-next-line
  }, [generatingSynthetic])

  if (generate && !validSummary && model?.id)
    return (
      <RoleDisable className="w-100 d-inline-block">
        <span
          className={` ${
            model.dataset.rows > 5000 ? 'opacity-50 pe-none' : ''
          }`}
          onClick={(e) => {
            if (model?.generating_synthetic_data) return
            document.querySelector('body')?.click()
            requestSyntheticData({ modelId: model?.id, token, signout })
              .then(async (r) => {
                if (r?.task_id) {
                  model.generating_synthetic_data = true
                  updateModel(model, model)
                  const response = await awaitTask({
                    taskUuid: r.task_id,
                  }).catch((e) => {})
                  model.generating_synthetic_data = false
                  updateModel(model, model)
                  if (response?.status) {
                    NotificationManager.success(
                      t('Synthetic data generated successfully'),
                    )
                    queryClient.invalidateQueries([
                      'synthetic-summary',
                      model?.id,
                    ])
                  } else
                    NotificationManager.error(
                      t(
                        response?.message ??
                          'Unable to generate synthetic data',
                      ),
                    )
                } else {
                  const TOO_BIG_REASON =
                    'Synthetic data cannot be generated for datasets with more than 5000 rows'
                  if (r.detail === TOO_BIG_REASON) {
                    NotificationManager.error(t(TOO_BIG_REASON))
                  } else {
                    NotificationManager.error(
                      t('Unable to generate synthetic data'),
                    )
                  }
                }
              })
              .catch((e) => {
                model.generating_synthetic_data = false
                updateModel(model, model)
                NotificationManager.error(
                  t('Unable to generate synthetic data'),
                )
              })
          }}
        >
          {model?.generating_synthetic_data ? (
            <span style={{ float: 'left' }} className="loading-tooltip px-3">
              {t('Generating')}
            </span>
          ) : (
            <span style={{ float: 'left' }}>
              {t('Generate synthetic data')}
            </span>
          )}
          <GrTest
            size={20}
            color="var(--nextbrain-white-font)"
            style={{
              float: 'right',
            }}
          />
        </span>
      </RoleDisable>
    )

  if (!isLoading && (!data || typeof data !== 'object')) return <></>

  return (
    <>
      <Row
        {...props}
        className={`${props?.className ?? ''} ${
          model.dataset.rows > 5000 ? 'opacity-50 pe-none' : ''
        }`}
      >
        <Col
          className="px-0 d-flex align-items-center synthetic-data-summary"
          xs={'auto'}
        >
          <GrTest
            className="synth-icon-summary"
            size={30}
            style={{ filter: 'invert(1)' }}
          />
        </Col>
        <Col
          className={`trigger-synthetic-summary d-flex align-items-end ${
            validSummary || generate ? 'active-trigger' : ''
          }`}
          xs="auto"
          onClick={() => validSummary && setShow(true)}
        >
          {t('Synthetic data evaluation')}
          {validSummary && (
            <span style={{ fontSize: '20px' }}>
              {' '}
              <HiOutlineExternalLink className="ps-1" />
            </span>
          )}
        </Col>
        {isLoading && (
          <Col className="d-flex align-items-end px-0" xs="auto">
            <BouncyLoader />
          </Col>
        )}
        {!isLoading && !generate && (!data || typeof data !== 'object') && (
          <Col className="d-flex align-items-end px-0" xs="auto">
            <ImCross size={20} />
          </Col>
        )}
      </Row>
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header className="h4" closeButton>
          {t('Synthetic data evaluation')}
        </Modal.Header>
        <Modal.Body>
          {validSummary && (
            <Row className="h4">
              <Col md={12} xl={6} className="mb-3">
                <Row>
                  <Col
                    className="d-flex align-items-center justify-content-xl-center justify-content-md-start"
                    xs={12}
                  >
                    <FaBalanceScaleRight className="me-2" size={30} />
                    {t('Similarity score')}
                    <strong>
                      {`: ${round(data?.data_similarity ?? 0, 2)}%`}
                    </strong>
                  </Col>
                  <Col
                    xs={12}
                    className="smallp d-flex justify-content-center mt-1 italic"
                  >
                    {t(
                      'Degree to which synthetic data closely resembles the original data',
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                md={12}
                xl={6}
                className={`mb-3 ${
                  data?.topological_spaces_congruency < 60 ? 'd-none' : ''
                }`}
              >
                <Row>
                  <Col
                    className="d-flex align-items-center justify-content-xl-center justify-content-md-start"
                    xs={12}
                  >
                    <GiConvergenceTarget className="me-2" size={30} />
                    {t('Congruency score')}
                    <strong>
                      {`: ${round(
                        data?.topological_spaces_congruency ?? 0,
                        2,
                      )}%`}
                    </strong>
                  </Col>
                  <Col
                    xs={12}
                    className="smallp d-flex justify-content-center mt-1 italic"
                  >
                    {t(
                      'Exact alignment between synthetic data and the original data',
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            {!isLoading && data && typeof data === 'object' && (
              <Col
                className="cursor-pointer d-flex align-items-center py-2"
                style={{
                  pointerEvents: working ? 'none' : 'all',
                  opacity: working ? '0.5' : '1',
                }}
                xs={'auto'}
                onClick={() => setWorking(true)}
              >
                {working ? (
                  <BouncyLoader />
                ) : (
                  <>
                    <FaFileDownload size={25} className="me-1" />{' '}
                    {t('Download synthetic dataset')}
                    {data?.samples_generated ? (
                      <div className="small ms-2">
                        (
                        {t('{{samples}} samples generated', {
                          samples: data.samples_generated,
                        })}
                        )
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Col>
            )}
          </Row>

          {validSummary && (
            <HistogramSummary
              className="my-3"
              model={model}
              histograms={data?.histograms}
              statistics={data?.statistics}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
