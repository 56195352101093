import { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'

import { useAuth } from '../../providers/AuthProvider'
import { originalForecast } from '../../services/model'
import { default as d3_timeseries } from './d3_timeseries'

export default function FeedbackForecast({ model, height = 400, ...props }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [data, setData] = useState([])

  useEffect(() => {
    originalForecast({
      modelId: model.id,
      token,
      signout,
    }).then((response) => {
      if (response) {
        setData(
          response.forecast.map((f) => ({
            ...f,
            ds: new Date(f.ds),
            forecast: f.y,
            'lower error margin': f.y_lower,
            'upper error margin': f.y_upper,
          })),
        )
      } else {
        NotificationManager.error(t('Failed to retrieve original forecast'))
      }
    })
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const drawGraph = () => {
    if (data.length) {
      let minY = data?.[0]?.y,
        maxY = minY
      data.forEach((d) => {
        minY = Math.min(minY, d.y_lower)
        maxY = Math.max(maxY, d.y_upper)
        minY = Math.min(minY, d.original)
        maxY = Math.max(maxY, d.original)
      })
      const diff = (maxY - minY) * 0.1

      $(`#timeseries-original-forecast-model-${model?.id}`).html('')
      var chart = d3_timeseries()
        .addSerie(
          data,
          { x: 'ds', y: `forecast`, ci_down: 'y_lower', ci_up: 'y_upper' },
          { interpolate: 'monotone', color: '#df997f' },
        )
        .addSerie(
          data,
          { x: 'ds', y: 'original' },
          {
            interpolate: 'monotone',
            color: 'var(--nextbrain-tables-blue-graph-bar-color)',
            points: true,
            pointSize: 3,
            scaleR: (x) => {
              if (x < 20) return 4
              else if (x < 200) return 2
              return 0.5
            },
          },
        )
        .addSerie(
          data,
          { x: 'ds', y: `upper error margin` },
          { interpolate: 'monotone', color: '#750a03c8' },
        )
        .addSerie(
          data,
          { x: 'ds', y: `lower error margin` },
          { interpolate: 'monotone', color: '#e66a6088' },
        )
        .margin({ left: 50, right: 50, top: 0, bottom: 20 })
        .width($(`#timeseries-original-forecast-model-${model?.id}`).width())
        .height(height)
        .yscale.domain([minY - diff, maxY + diff])

      chart(`#timeseries-original-forecast-model-${model?.id}`)
    }
  }

  useEffect(() => {
    drawGraph()
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    window.addEventListener('resize', drawGraph)
    return () => window.removeEventListener('resize', drawGraph)
    // eslint-disable-next-line
  }, [drawGraph])

  return (
    <div
      id={`timeseries-original-forecast-model-${model?.id}`}
      className="time-series-container-original"
    ></div>
  )
}
