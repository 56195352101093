import React, { useState, useRef, useMemo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Popover } from 'react-bootstrap'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getCompanyUsers } from '../../services/user'
import './homepage.css'
import { useAuth } from '../../providers/AuthProvider'
import { useModels } from '../../providers/ModelProvider'
import { FaCheck, FaChevronDown } from 'react-icons/fa'
import { OverlayTrigger } from 'react-bootstrap'

export default function NewProject({
  project,
  allUsers,
  onCancel = () => {},
  onSave = () => {},
  justShare = false,
  ...props
}) {
  const { t } = useTranslation()
  const { user, token, signout } = useAuth()
  const { project: providerModel } = useModels()
  const shareRef = useRef()
  project = project ?? providerModel
  const editing = project.id
  const [chosen, setChosen] = useState(editing ? project.color : '#4240B5')
  const [colors, setColors] = useState(() => {
    const arr = [
      '#22f0B5',
      '#D14444',
      '#D144B2',
      '#7144D1',
      '#446BD1',
      '#e6ab02',
      '#a6761d',
      '#1199dd',
      '#ffffff',
      '#000000',
    ]
    return arr.filter((e, i) => arr.indexOf(e) === i)
  })
  const [users, setUsers] = useState(
    editing
      ? project.invited_users.map((u) => ({ name: u.email, role: u.role }))
      : [],
  )
  const currentUserRef = useRef()
  const nameRef = useRef()
  const generateShareBadge = (key) => {
    const sp = key.split(new RegExp('\\._'))
    const tag =
      sp.length > 1 && sp[0].length && sp[1].length
        ? sp[0][0] + sp[1][0]
        : key.substr(0, 2)
    const extraStyles = {}
    if (project.owner.email === key) {
      extraStyles['borderColor'] = project.color ?? '#4240B5'
    }
    return (
      <Col
        className={`type-badge align-items-center rounded-circle py-1 px-2`}
        title={key}
        md={'auto'}
        style={{ marginLeft: '-10px', display: 'flex', ...extraStyles }}
        key={key}
      >
        <span>{tag.toUpperCase()}</span>
      </Col>
    )
  }

  const { data, isLoading } = useQuery(
    ['users-all-companies', user.id],
    async () => {
      try {
        const companies = user?.companies ?? []
        const companyResults = companies.map((c) =>
          getCompanyUsers({ companyId: c.id, token, signout }),
        )
        const allResults = await Promise.all(companyResults)
        return [
          ...new Set(
            allResults
              .flat()
              .map((u) => u.email)
              .filter((e) => e),
          ),
        ]
      } catch (e) {}
      return []
    },
    { staleTime: 300000 },
  )

  const availableUsers = useMemo(() => {
    return new Set([...Object.keys(allUsers), ...(isLoading ? [] : data)])
  }, [data, isLoading, allUsers])

  return (
    <>
      {project ? (
        <>
          <Row
            {...props}
            className={`project-home-modal ${justShare ? 'share-only' : ''} ${
              props.className ?? ''
            }`}
          >
            <Row>
              <Col>
                <Form.Control
                  required
                  ref={nameRef}
                  className="with-icon nb-input"
                  style={{ backgroundColor: '#EBEBEB' }}
                  placeholder={project?.name ?? t('Project name')}
                  defaultValue={project?.name ?? ''}
                />
                <i className="far fa-folder-open icon"></i>
              </Col>
            </Row>
            <Row>
              <Col className="h4 my-4">
                <strong>{t('Color pick')}</strong>
              </Col>
            </Row>
            <Row className="mx-2">
              {colors.map((c) => (
                <Col
                  xs={'auto'}
                  key={c}
                  className={`color-ball rounded-circle mx-1 ${
                    c === chosen ? 'color-ball-chosen' : ''
                  }`}
                  style={{ backgroundColor: c }}
                  onClick={() => {
                    setChosen(c)
                  }}
                />
              ))}

              <Col
                md={'auto'}
                xs={'auto'}
                className={`color-ball rounded-circle mx-1 d-none`}
              >
                <label>
                  <img
                    src="/icons/AddColor.png"
                    alt={t('Add new Color')}
                    title={t('Add new Color')}
                    className="color-ball"
                  />
                  <Form.Control
                    type="color"
                    className="d-none"
                    onChange={(e) => {
                      if (!colors.find((c) => c === e.target.value)) {
                        setColors([...colors, e.target.value])
                      }
                    }}
                  />
                </label>
              </Col>
            </Row>
            <Row className="share-only-view">
              <Col xs={12}>
                <Row className="position-relative">
                  <input
                    type="text"
                    className="invisible-input-share"
                    onFocus={(e) => {
                      shareRef.current?.querySelector('input')?.focus()
                    }}
                    onChange={(e) => {
                      if (shareRef?.current?.querySelector('input'))
                        shareRef.current.querySelector('input').value =
                          e.target.value
                    }}
                  />
                  <Col ref={shareRef}>
                    <NextbrainSelect
                      value={null}
                      type={'dark'}
                      className="basic-single mt-2"
                      classNamePrefix="select"
                      isClearable={false}
                      creatable={true}
                      placeholder={t('Add users to your workspace...')}
                      onBlur={(e) => {
                        if (e.currentTarget.value)
                          setUsers((users) => [
                            { name: e.currentTarget.value, role: 'editor' },
                            ...users,
                          ])
                      }}
                      onChange={(v) => {
                        if (v.value) {
                          setUsers([
                            { name: v.value, role: 'editor' },
                            ...users,
                          ])
                          currentUserRef.current.scrollTop = 0
                        }
                      }}
                      options={[...availableUsers]
                        .filter(
                          (u) =>
                            !users.map((u) => u.name).includes(u) &&
                            u !== project.owner.email,
                        )
                        .map((u) => ({ value: u, label: u }))}
                    />
                  </Col>
                </Row>
              </Col>
              <Row>
                <Col className="h5 my-2 mx-2">{t('People with access')}</Col>
              </Row>
              <Row ref={currentUserRef} className="selectedUsers">
                <Row className="justify-content-between my-2 mx-3">
                  <Col md={'auto'} xs={'auto'}>
                    <Row>
                      <Col>{generateShareBadge(project.owner.email)}</Col>
                      <Col md={'auto'} className="align-self-center">
                        {project.owner.email}
                        <strong className="ms-1">({t('You')})</strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={'auto'} className=" align-self-center ">
                    <strong>{t('Owner')}</strong>
                    <FaChevronDown className="ms-2 pe-none opacity-0" />
                  </Col>
                </Row>
                {users.map((u, index) => (
                  <Row
                    key={u.name}
                    className="justify-content-between my-2 mx-3"
                  >
                    <Col md={'auto'} xs={'auto'}>
                      <Row>
                        <Col>{generateShareBadge(u.name)}</Col>
                        <Col className="align-self-center">{u.name}</Col>
                      </Row>
                    </Col>
                    <OverlayTrigger
                      rootClose={true}
                      trigger={'click'}
                      placement="bottom"
                      delay={{ show: 200, hide: 0 }}
                      overlay={(props) => (
                        <Popover
                          {...props}
                          style={{
                            ...props.style,
                            zIndex: 9999,
                            fontSize: '15px',
                            overflow: 'hidden',
                          }}
                        >
                          <Row className="py-2">
                            <Col
                              className="cursor-pointer mt-2 icon-btn"
                              xs={12}
                              onClick={() => {
                                u.role = 'editor'
                                setUsers([...users])
                                shareRef?.current?.click()
                              }}
                            >
                              <FaCheck
                                className={`mx-3 ${
                                  (u?.role ?? 'editor') === 'editor'
                                    ? ''
                                    : 'opacity-0'
                                }`}
                              />
                              {t('editor')}
                            </Col>
                            <Col
                              className="cursor-pointer mt-2 icon-btn"
                              xs={12}
                              onClick={() => {
                                u.role = 'viewer'
                                setUsers([...users])
                                shareRef?.current?.click()
                              }}
                            >
                              <FaCheck
                                className={`mx-3 ${
                                  u?.role === 'viewer' ? '' : 'opacity-0'
                                }`}
                              />
                              {t('viewer')}
                            </Col>
                            <Col
                              xs={12}
                              className="mt-2 mb-0"
                              style={{
                                borderBottom:
                                  '1px solid var(--nextbrain-secondary-border-color)',
                              }}
                            ></Col>
                            <Col
                              className="cursor-pointer mt-2 icon-btn"
                              xs={12}
                              onClick={() => {
                                setUsers(users.filter((_, i) => i !== index))
                                shareRef?.current?.click()
                              }}
                            >
                              <span className="d-inline-flex flex-nowrap">
                                <FaCheck className={`mx-3 opacity-0`} />
                                {t('remove user from workspace')}
                              </span>
                            </Col>
                          </Row>
                        </Popover>
                      )}
                    >
                      <Col
                        xs={'auto'}
                        style={{ cursor: 'pointer' }}
                        className="d-inline-flex align-items-center"
                      >
                        <strong>
                          {t(u?.role ?? 'editor')}{' '}
                          <FaChevronDown className="ms-2" />
                        </strong>
                      </Col>
                    </OverlayTrigger>
                  </Row>
                ))}
              </Row>
            </Row>
            <Row className="mt-md-5 share-only-view justify-content-end">
              <Col xs={3}>
                <Button
                  className="original empty-secondary w-100 py-2"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xs={3}>
                <Button
                  className="add-button-newpr py-2"
                  onClick={(e) => {
                    e.currentTarget.disabled = true
                    if (nameRef.current.value.trim() !== '')
                      onSave({
                        ...project,
                        name: nameRef.current.value,
                        invited_emails: users,
                        color: chosen,
                      })
                  }}
                >
                  {editing ? t('Save changes') : t('Create workspace')}
                </Button>
              </Col>
            </Row>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
