import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Popover, OverlayTrigger } from 'react-bootstrap'
import { GiConfirmed } from 'react-icons/gi'
import { BsInfoCircle } from 'react-icons/bs'

export function OptimizeCard({ title, value, errorMargin, help, ...props }) {
  const { t } = useTranslation()
  const Help = help
  return (
    <Row
      {...props}
      className={`d-flex align-items-center justify-content-center position-relative
           text-center optimize-card mx-xl-5 mx-md-2 mb-5 mt-2 mx-1 py-3  ${
             props?.className ?? ''
           }`}
    >
      {Help && (
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement={'auto'}
          delay={{ show: 100, hide: 200 }}
          overlay={(props) => (
            <Popover
              {...props}
              style={{
                ...(props?.style ?? {}),
                minWidth: `450px`,
              }}
            >
              <Help />
            </Popover>
          )}
        >
          <div className="optimize-card-info-circle p-0">
            <span>
              <BsInfoCircle size={20} />
            </span>
          </div>
        </OverlayTrigger>
      )}
      <Col className="h4" xs={12}>
        <strong>{t(title)}</strong>
      </Col>
      <Col
        className="d-inline-block text-truncate text-center h3 mb-0"
        xs={'auto'}
      >
        {value}
      </Col>
      <Col className="px-0 gx-0" xs={'auto'}>
        <Row>
          <Col style={{ maxWidth: '30px' }} xs={2}>
            <Row>
              <Col className="px-0 gx-0" style={{ maxHeight: '6px' }} xs={12}>
                <span
                  style={{
                    color: 'var(--nextbrain-tables-graph-bar-color)',
                  }}
                >
                  +
                </span>
              </Col>
              <Col className="px-0 gx-0" style={{ maxHeight: '6px' }} xs={12}>
                <span
                  style={{
                    color: 'var(--nextbrain-tables-negative-graph-bar-color)',
                  }}
                >
                  -
                </span>
              </Col>
            </Row>
          </Col>
          <Col
            className="ps-0"
            style={{ maxWidth: 'calc(100% - 30px)' }}
            xs={10}
          >
            <strong>{errorMargin}</strong>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <span className="small-mmm-optimize">
          {t('With 95% confidence')}
          <GiConfirmed className="ms-2" />
        </span>
      </Col>
    </Row>
  )
}
