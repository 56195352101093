import { useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { Row, Modal } from 'react-bootstrap'
import { BsPencil } from 'react-icons/bs'
import { datasourceConfig } from './config'
import { useTranslation } from 'react-i18next'
import { InlineEdit } from '../../inline-edit/InlineEdit'
import { useAuth } from '../../../providers/AuthProvider'
import { validateStructure } from './validation'
import { importFlow } from '../../../services/csv'
import { awaitTask } from '../../../services/base'
import { useModels } from '../../../providers/ModelProvider'
import { assistantQuestion, getModelById } from '../../../services/model'
import { useQueryClient } from 'react-query'
import { Status } from './Status'
import { DESCRIPTION_PROMPT } from '../model-assistant/assistantConfig'

const nameMap = {
  existingData: 'Stored data',
  localFile: 'Upload local file',
  google: 'Google Sheets',
  database: 'Database',
  dataslayer: 'Dataslayer AI',
  api: 'General API',
  powerbi: 'Power BI',
  Mongo: 'Mongo',
  'Public Datasets': 'Public Datasets',
}

export default function SingleImport({
  type,
  onError,
  config = {},
  actionLabel = 'Create model',
  modelId = null,
  projectId = null,
  onCheckModel,
  name,
  onFinish,
}) {
  const userData = useAuth()
  const [importing, setImporting] = useState(false)
  const [importingStatus, setImportingStatus] = useState({})
  const [sampleSize, setSampleSize] = useState(200000)
  const queryClient = useQueryClient()
  const nodeRef = useRef({
    id: 'single',
    data: { config: config },
    type: nameMap[type] ?? type,
  })
  const node = nodeRef.current
  const editRef = useRef()
  const componentRef = useRef(datasourceConfig[nameMap[type] ?? type].component)
  const [modelName, setModelName] = useState(
    name ??
      `new model ${new Date().toLocaleDateString('default', {
        dateStyle: 'medium',
      })}`,
  )
  const [userEditedName, setUserEditedName] = useState(!!name)
  const [useAssistant, setUseAssistant] = useState(false)
  const ComponentConfig = componentRef.current
  const { t } = useTranslation()
  const { updateModel, addPlaceholder, addModel } = useModels()

  if (!onFinish)
    onFinish = async (sample, ...props) => {
      setImporting(true)
      setImportingStatus({})
      const newData = datasourceConfig[nameMap[type] ?? type].prepareData(
        node.data,
        ...props,
      )
      if (!newData.valid) {
        NotificationManager.error(t('Invalid configuration'))
        onError()
        return
      }
      const { error, structure, errors, target } = await validateStructure({
        nodes: [
          node,
          {
            id: 'output',
          },
        ],
        edges: [
          {
            source: 'single',
            target: 'output',
          },
        ],
        target: 'output',
        userData,
        onProgress: (percent, name) => {
          setImportingStatus((prev) => ({ ...prev, [name]: percent }))
        },
        options: {
          tryTask: true,
        },
      })

      if (error) {
        NotificationManager.error(
          `${t('Invalid configuration')} ${errors?.[0] ?? ''}`,
        )
        onError()
        setImportingStatus({})
      } else {
        let localAssistant = useAssistant
        const cols = sample?.columns?.length
        if (cols && cols > 500) localAssistant = false

        importFlow({
          outputIndex: target,
          objects: structure,
          name: modelName,
          modelId: modelId,
          projectId: projectId,
          llmFeatures: !!onFinish.llmFeatures,
          check: localAssistant,
          token: userData.token,
          signout: userData.signout,
          ...(sampleSize ? { sample: sampleSize } : {}),
        })
          .then(async (r) => {
            const { task_id, model_id } = r
            onError()
            if (modelId) {
              awaitTask({ taskUuid: task_id, sleep: 1000 })
                .then(async ({ model_id }) => {
                  const model = await getModelById(
                    model_id,
                    userData.token,
                    userData.signout,
                  )
                  if (!model) NotificationManager.error('Error updating model')
                  else {
                    updateModel(modelId, model)
                    queryClient.invalidateQueries(`model-flow-${modelId}`)
                  }
                })
                .catch((e) => {
                  NotificationManager.error(`Error updating model ${e}`)
                })
            } else {
              if (model_id) {
                if (localAssistant) {
                  onCheckModel(model_id)
                  return
                }
                const model = await getModelById(
                  model_id,
                  userData.token,
                  userData.signout,
                )
                addModel(model)
                new Promise(async (resolve) => {
                  while (true) {
                    const model = await getModelById(
                      model_id,
                      userData.token,
                      userData.signout,
                    )
                    switch (model?.dataset?.status) {
                      case 'imported':
                        resolve(model)
                        return
                      case 'readyToImport':
                      case 'importing':
                        await new Promise((r) => setTimeout(r, 5000))
                        break
                      default:
                        resolve(null)
                        return
                    }
                  }
                })
                  .then((model) => {
                    if (model) {
                      assistantQuestion({
                        modelId: model.id,
                        prompt: DESCRIPTION_PROMPT,
                        key: 'initialSuggestions',
                        token: userData.token,
                        signout: userData.signout,
                      })
                    } else {
                      console.error(
                        'Failed to import model, skipping context generation',
                      )
                    }
                  })
                  .catch(() => {})
                return
              }
              addPlaceholder(modelName, async () => {
                return await awaitTask({ taskUuid: task_id, sleep: 500 }).then(
                  async ({ model_id }) => {
                    const model = await getModelById(
                      model_id,
                      userData.token,
                      userData.signout,
                    )
                    if (!model) {
                      NotificationManager.error('Error importing model')
                      return null
                    } else {
                      return model
                    }
                  },
                )
              })
            }
          })
          .catch(() => {
            NotificationManager.error('Error importing model')
            onError()
          })
          .finally(() => {
            setImportingStatus({})
          })
      }
    }

  const extraComponentProps =
    type === 'localFile' ? { enableEager: true, setSampleSize, sampleSize } : {}

  return (
    <Row>
      <Modal
        dialogClassName={`modal-nb modal-datasource-${type?.replace(' ', '-')}`}
        size={'xl'}
        show={true}
        keyboard={false}
        onHide={onError}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ pointerEvents: importing ? 'none' : 'all' }}>
            <InlineEdit
              key={modelName}
              ref={editRef}
              text={modelName}
              onEdit={(text) => {
                setUserEditedName(true)
                setModelName(text)
              }}
            />
            <span
              className={`active-icon cursor-pointer ${
                importing ? 'd-none' : ''
              }`}
            >
              <BsPencil
                onClick={() => editRef.current?.click()}
                size={20}
                style={{ marginLeft: '15px', marginTop: '-5px' }}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {importing ? (
            <Status
              status={importingStatus}
              message={modelId ? t('Updating model') : t('Creating model')}
            />
          ) : (
            <ComponentConfig
              id={type}
              actionLabel={actionLabel}
              configuration={node.data.config}
              onNameChange={(name) => {
                if (!userEditedName) {
                  setModelName(name)
                }
              }}
              close={onError}
              sampleData={null}
              onFinish={onFinish}
              single={true}
              onAskAi={onCheckModel ? setUseAssistant : false}
              {...extraComponentProps}
            />
          )}
        </Modal.Body>
      </Modal>
    </Row>
  )
}
