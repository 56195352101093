import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { FaArrowRight, FaEdit, FaTrash, FaCircle } from 'react-icons/fa'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { NotificationManager } from 'react-notifications'
import $ from 'jquery'
import {
  createSubscription,
  deleteSubscription,
  updateSubscription,
} from '../../services/user'
import { useAuth } from '../../providers/AuthProvider'
import ConfirmButton from '../confirm-button/ConfirmButton'
import { BsFillPlusSquareFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../util/other'
import { t } from 'i18next'

const paymentTypes = [
  { label: 'Stripe', value: 'stripe' },
  { label: 'Trial', value: 'trial' },
  { label: 'Transfer', value: 'transfer' },
  { label: 'Free', value: 'free' },
]

const paymentStatuses = [
  { label: 'Paid', value: 'paid' },
  { label: 'Pending', value: 'pending' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Refunded', value: 'refunded' },
]

const productTypes = [
  { label: 'Plan', value: 'plan' },
  { label: 'Plugin', value: 'plugin' },
]

const planNames = [
  { label: 'Free', value: 'free' },
  { label: 'Freelance', value: 'freelance' },
  { label: 'Small business', value: 'small_business' },
  { label: 'Enterprise', value: 'Enterprise' },
]

const planParameters = {
  training_rows: 'text',
  predictions: 'text',
  clustering_models: 'bool',
  data_scientist: 'bool',
  dockerfile: 'bool',
  external_api: 'bool',
  sql_db: 'bool',
  user_seats: 'text',
  max_rows_per_model: 'text',
  onboarding: 'text',
  forecasting_models: 'bool',
  max_models: 'text',
  dashboard: 'bool',
  generative_ai: 'bool',
  synthetic_data: 'bool',
}

const plugins = [
  {
    name: 'mmm',
    parameters: {
      max_channels: 'text',
    },
  },
  {
    name: 'anomaly',
    parameters: {},
  },
  {
    name: 'llm',
    parameters: {},
  },
  {
    name: 'clustering',
    parameters: {},
  },
]

const pluginOptions = plugins.map(({ name }) => ({ label: name, value: name }))

const regex = /-?[0-9]+/

const templates = {
  plan: {
    free: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 15),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plan',
      config: {
        name: 'Free',
        training_rows: 100000,
        predictions: 500,
        clustering_models: 'False',
        data_scientist: 'False',
        dockerfile: 'False',
        external_api: 'False',
        sql_db: 'False',
        user_seats: 1,
        max_rows_per_model: 1000,
        onboarding: '0 hours',
        forecasting_models: 'False',
        max_models: 15,
        dashboard: 'False',
        generative_ai: 'True',
        synthetic_data: 'False',
      },
    },
    freelance: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plan',
      config: {
        name: 'Freelance',
        training_rows: 100000,
        predictions: 2000,
        max_models: 30,
        max_rows_per_model: 5000,
        user_seats: 1,
        clustering_models: 'False',
        data_scientist: 'False',
        dockerfile: 'False',
        external_api: 'True',
        sql_db: 'True',
        onboarding: '30 min',
        forecasting_models: 'False',
        dashboard: 'True',
        generative_ai: 'True',
        synthetic_data: 'False',
      },
    },
    small_business: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plan',
      config: {
        name: 'Small business',
        training_rows: 100000,
        predictions: 20000,
        max_models: 100,
        max_rows_per_model: 90000,
        user_seats: 3,
        clustering_models: 'True',
        data_scientist: 'False',
        dockerfile: 'False',
        external_api: 'True',
        sql_db: 'True',
        onboarding: '1 hour',
        forecasting_models: 'True',
        dashboard: 'True',
        generative_ai: 'True',
        synthetic_data: 'False',
      },
    },
    Enterprise: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plan',
      config: {
        name: 'Enterprise',
        training_rows: 1000000,
        predictions: 100000,
        max_models: 500,
        max_rows_per_model: 1000000,
        user_seats: 10,
        clustering_models: 'True',
        data_scientist: 'True',
        dockerfile: 'True',
        external_api: 'True',
        sql_db: 'True',
        onboarding: '1 hour',
        forecasting_models: 'True',
        dashboard: 'True',
        generative_ai: 'True',
        synthetic_data: 'True',
      },
    },
  },
  plugin: {
    mmm: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plugin',
      config: {
        plugin_type: 'mmm',
        product_type: 'plugin',
        max_channels: 'unlimited',
      },
    },
    anomaly: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plugin',
      config: { plugin_type: 'anomaly', product_type: 'plugin' },
    },
    clustering: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plugin',
      config: { plugin_type: 'clustering', product_type: 'plugin' },
    },
    llm: {
      from_date: new Date(),
      to_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      payment_type: 'free',
      payment_status: 'paid',
      product_type: 'plugin',
      config: { plugin_type: 'llm', product_type: 'plugin' },
    },
  },
}

function gatherConfig(mode, paymentConfig, form) {
  switch (mode) {
    case 'plan': {
      const result = { ...paymentConfig, config: {} }
      const name = $(form).find('.plan-type-input').val()
      if (name) result.config.name = name

      $(form)
        .find('.plan-input-form')
        .each((i, el) => {
          const key = $(el).data('plan-config')
          const value = $(el).val()
          if (value)
            result.config[key] = regex.test(value)
              ? Number.parseInt(value)
              : value
        })
      $(form)
        .find('.plan-input-check > input')
        .each((i, el) => {
          const key = $(el).data('plan-config')
          const value = $(el).is(':checked')
          result.config[key] = value ? 'True' : 'False'
        })
      return result
    }
    case 'plugin': {
      const result = { ...paymentConfig, config: { product_type: 'plugin' } }
      const type = $(form).find('.plugin-type-input').val()
      if (type) result.config.plugin_type = type

      $(form)
        .find('.plugin-input-form')
        .each((i, el) => {
          const key = $(el).data('plan-config')
          const value = $(el).val()
          if (value)
            result.config[key] = regex.test(value)
              ? Number.parseInt(value)
              : value
        })
      $(form)
        .find('.plugin-input-check > input')
        .each((i, el) => {
          const key = $(el).data('plan-config')
          const value = $(el).is(':checked')
          result.config[key] = value ? 'True' : 'False'
        })
      return result
    }

    default:
      return null
  }
}

function PlanConfig({ subscription }) {
  const planTypeRef = useRef()

  return (
    <Row>
      <Col xs={6}>
        <Row>
          <Col xs={12}>Plan name</Col>
          <Col className="mt-2 mb-3" xs={12}>
            <Form.Control
              ref={planTypeRef}
              className="plan-type-input d-none"
            />
            <NextbrainSelect
              defaultValue={planNames.find(
                ({ label }) => label === subscription?.config?.name,
              )}
              options={planNames}
              placeholder={'Select a plan type'}
              type={'train'}
              onChange={(v) => {
                planTypeRef.current.value = v?.value
              }}
            />
          </Col>
        </Row>
        {Object.entries(planParameters)
          .filter(([, type]) => type !== 'bool')
          .map(([key, type]) => (
            <Row key={key} style={{ minHeight: '80px' }}>
              <Col xs={12}>{key}</Col>
              <Col className="mt-1" xs={12}>
                <Form.Control
                  type={type}
                  className="nb-input-soft plan-input-form"
                  data-plan-config={key}
                  defaultValue={subscription?.config?.[key] ?? ''}
                />
              </Col>
            </Row>
          ))}
      </Col>
      <Col xs={6}>
        <Row style={{ minHeight: '80px' }}>
          {Object.entries(planParameters)
            .filter(([, type]) => type === 'bool')
            .map(([key, type]) => (
              <Col key={key} className="d-flex align-items-center mt-2" xs={6}>
                <Form.Check
                  className="plan-input-check"
                  type="switch"
                  data-plan-config={key}
                  label={key}
                  defaultChecked={subscription?.config?.[key] === 'True'}
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  )
}

function PluginConfig({ subscription }) {
  const [plugin, setPlugin] = useState(() => {
    return pluginOptions.find(
      ({ value }) => value === subscription?.config?.plugin_type,
    )
  })
  const pluginParams =
    (plugin && plugins.find(({ name }) => name === plugin.value)?.parameters) ??
    {}
  const pluginTypeRef = useRef()
  useEffect(() => {
    pluginTypeRef.current.value = plugin?.value
  }, [plugin])

  return (
    <Row>
      <Col xs={6}>
        <Row>
          <Col xs={12}>Plugin type</Col>
          <Col className="mt-2 mb-3" xs={12}>
            <Form.Control
              ref={pluginTypeRef}
              className="plugin-type-input d-none"
            />
            <NextbrainSelect
              value={plugin}
              onChange={setPlugin}
              options={pluginOptions}
              placeholder={'Select a plugin type'}
              type={'train'}
            />
          </Col>
        </Row>
        {Object.entries(pluginParams)
          .filter(([, type]) => type !== 'bool')
          .map(([key, type]) => (
            <Row key={key} style={{ minHeight: '80px' }}>
              <Col xs={12}>{key}</Col>
              <Col className="mt-1" xs={12}>
                <Form.Control
                  type={type}
                  className="nb-input-soft plugin-input-form"
                  data-plan-config={key}
                  defaultValue={subscription?.config?.[key] ?? ''}
                />
              </Col>
            </Row>
          ))}
      </Col>
      <Col xs={6}>
        <Row style={{ minHeight: '80px' }}>
          {Object.entries(pluginParams)
            .filter(([, type]) => type === 'bool')
            .map(([key, type]) => (
              <Col key={key} className="d-flex align-items-center mt-2" xs={6}>
                <Form.Check
                  type="switch"
                  label={key}
                  className="plugin-input-check"
                  data-plan-config={key}
                  defaultChecked={subscription?.config?.[key] === 'True'}
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  )
}

function convertToDatetimeLocalFormat(datetimeString) {
  if (!datetimeString) return null
  const dateTime = new Date(datetimeString)
  const year = dateTime.getUTCFullYear()
  const month = (dateTime.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = dateTime.getUTCDate().toString().padStart(2, '0')
  const hours = dateTime.getUTCHours().toString().padStart(2, '0')
  const minutes = dateTime.getUTCMinutes().toString().padStart(2, '0')
  const datetimeLocalString = `${year}-${month}-${day}T${hours}:${minutes}`
  return datetimeLocalString
}

function Subscription({
  company,
  subscription,
  onUpdate,
  onCancel,
  avoidDataCollision,
}) {
  const { token, signout } = useAuth()

  const [mode] = useState(() => {
    return productTypes.find(({ value }) => value === subscription.product_type)
  })
  const [working, setWorking] = useState(false)
  const paymentConfig = useRef({})
  const containerRef = useRef()

  return (
    <Tabs>
      <Row className="mt-2 tabsdiv">
        <Col className="ps-0" xs={12}>
          <TabList className="ps-0 mb-0">
            <Row className="d-flex justify-content-between">
              <Col xs="auto" className="d-flex align-items-end">
                <Tab className="d-none">Payment</Tab>
                <Tab className="d-none">Configuration</Tab>
              </Col>
              <Col xs="auto">
                <Tab
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Button
                    className="py-2 "
                    disabled={working}
                    onClick={() => {
                      setWorking(true)
                      const params = gatherConfig(
                        mode?.value,
                        paymentConfig.current,
                        containerRef.current,
                      )
                      params.config = {
                        ...(subscription?.config ?? {}),
                        ...params.config,
                      }
                      params.company_id = company.id
                      const plugin_type = params?.config?.plugin_type
                      params.config = JSON.stringify(params.config)
                      let collides = false
                      let baseSubscription = subscription

                      if (avoidDataCollision) {
                        const subscription = { ...baseSubscription, ...params }
                        let matchingSubs = company?.subscription?.filter(
                          (s) => s.product_type === subscription.product_type,
                        )

                        if (subscription.product_type === 'plugin')
                          matchingSubs = matchingSubs.filter(
                            (s) => s.config.plugin_type === plugin_type,
                          )

                        const start = new Date(subscription.from_date).getTime()
                        const end = new Date(subscription.to_date).getTime()
                        const intersectDates = (from, to) =>
                          (start <= from && from <= end) ||
                          (start <= to && to <= end) ||
                          (from <= start && start <= to) ||
                          (from <= end && end <= to)

                        matchingSubs = matchingSubs.filter((s) =>
                          intersectDates(
                            new Date(s.from_date).getTime(),
                            new Date(s.to_date).getTime(),
                          ),
                        )
                        if (matchingSubs.length) {
                          collides = (
                            <Row>
                              <Col xs={12}>
                                {t(
                                  'Subscription dates overlap with existing subscription',
                                )}
                              </Col>
                              {matchingSubs.map((s, i) => (
                                <Col key={i} xs={12} className="my-2">
                                  {t('Active subscription for the period:')}
                                  <br />
                                  {formatDate(new Date(s.from_date))} -{' '}
                                  {formatDate(new Date(s.to_date))}
                                </Col>
                              ))}
                            </Row>
                          )
                        }

                        if (
                          !collides &&
                          subscription.product_type === 'plugin'
                        ) {
                          const max = Math.max(
                            ...(company?.subscription
                              ?.filter((s) => s.product_type === 'plan')
                              ?.map((s) => new Date(s.to_date).getTime()) ??
                              []),
                          )

                          if (max < end)
                            NotificationManager.warning(
                              t(
                                'Plugin subscription ends after the plan subscription, it will stop working once the plan expires.',
                              ),
                            )
                        }
                      }

                      if (!subscription.id) {
                        if (collides) {
                          NotificationManager.error(collides, null, 10000)
                          setWorking(false)
                          return
                        }
                        createSubscription({
                          subscription: { ...subscription, ...params },
                          token,
                          signout,
                        })
                          .then((res) => {
                            if (res?.id) {
                              NotificationManager.info('Created subscription')
                              onUpdate()
                            } else
                              NotificationManager.error(
                                'Error creating subscription',
                              )
                          })
                          .finally(() => setWorking(false))
                      } else
                        updateSubscription({
                          subscriptionId: subscription.id,
                          subscription: params,
                          token,
                          signout,
                        })
                          .then((res) => {
                            if (res.ok) {
                              NotificationManager.info('Updated subscription')
                              onUpdate()
                            } else {
                              NotificationManager.error(
                                'Error updating subscription',
                              )
                            }
                          })
                          .finally(() => {
                            setWorking(false)
                          })
                    }}
                  >
                    {subscription?.id ? 'Update' : 'Create'}
                  </Button>
                </Tab>
                <Tab
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Button
                    className="py-2 original"
                    variant="danger"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Tab>
              </Col>
            </Row>
          </TabList>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: 'var(--nextbrain-secondary-color)',
          borderRadius: '5px',
        }}
        ref={containerRef}
      >
        <Col className="mt-2 py-2" xs={12}>
          <TabPanel
            selectedClassName="active-panel-company-manager"
            className="panel-company-manager"
            forceRender={true}
          >
            <Row>
              <Col lg={6} xs={12}>
                <Row>
                  <Col xs={12}>Stripe subscription ID</Col>
                  <Col className="mb-3 ">
                    <Form.Control
                      className="nb-input-soft py-2"
                      defaultValue={subscription?.stripe_subscription_id}
                      onChange={(e) =>
                        (paymentConfig.current.stripe_subscription_id =
                          e.target.value)
                      }
                      style={{ minHeight: '41px' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} xs={12}>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>Payment type</Col>
                      <Col className="mt-2 mb-3">
                        <NextbrainSelect
                          defaultValue={paymentTypes.find(
                            (v) => v.value === subscription?.payment_type,
                          )}
                          options={paymentTypes}
                          placeholder={'Select a payment type'}
                          type={'train'}
                          onChange={(e) =>
                            (paymentConfig.current.payment_type = e?.value)
                          }
                        />
                      </Col>
                      <Col xs={12}>Payment status</Col>
                      <Col className="mt-2 mb-3">
                        <NextbrainSelect
                          defaultValue={paymentStatuses.find(
                            (v) => v.value === subscription?.payment_status,
                          )}
                          options={paymentStatuses}
                          placeholder={'Select a payment status'}
                          type={'train'}
                          onChange={(e) =>
                            (paymentConfig.current.payment_status = e?.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} xs={12}>
                <Row>
                  <Col xs={12}>From Date</Col>
                  <Col className="mt-2 mb-3">
                    <Form.Control
                      type="datetime-local"
                      className="nb-input-soft"
                      defaultValue={convertToDatetimeLocalFormat(
                        subscription?.from_date,
                      )}
                      onChange={(e) =>
                        (paymentConfig.current.from_date = e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} xs={12}>
                <Row>
                  <Col xs={12}>To Date</Col>
                  <Col className="mt-2 mb-3">
                    <Form.Control
                      type="datetime-local"
                      className="nb-input-soft"
                      defaultValue={convertToDatetimeLocalFormat(
                        subscription?.to_date,
                      )}
                      onChange={(e) =>
                        (paymentConfig.current.to_date = e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4>{t('Configuration')}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {mode?.value === 'plan' && (
                  <PlanConfig subscription={subscription} />
                )}
                {mode?.value === 'plugin' && (
                  <PluginConfig subscription={subscription} />
                )}
              </Col>
            </Row>
          </TabPanel>
          <TabPanel
            selectedClassName="active-panel-company-manager"
            className="panel-company-manager"
            forceRender={true}
          ></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
        </Col>
      </Row>
    </Tabs>
  )
}

function PlanButton({
  companyData,
  plan,
  activePlan,
  onUpdate,
  onFinish,
  onEdit,
  ...props
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [working, setWorking] = useState(false)

  const [future, active, date] = useMemo(() => {
    try {
      const now = Date.now()
      const from = new Date(plan?.from_date)
      const to = new Date(plan?.to_date)
      const active = from < now && to > now
      const future = !active && (from > now || to > now)

      return [
        future,
        active,
        <Col
          xs={7}
          className={`d-inline-flex align-items-center text-truncate subscription-manager-company`}
        >
          {formatDate(from)}
          <FaArrowRight className="mx-1" />
          {formatDate(to)}
        </Col>,
      ]
    } catch (e) {
      return [false, false, <Col xs={7}>{t('Invalid date range')}</Col>]
    }
    // eslint-disable-next-line
  }, [plan])

  const name =
    plan?.product_type === 'plugin'
      ? 'Plugin ' + (plan?.config?.plugin_type ?? t('unknown'))
      : plan?.config?.name ?? t('Nameless')

  return (
    <Row
      {...props}
      className={`admin-subscription-config py-2 mb-2 ${
        props?.className ?? ''
      } ${activePlan ? 'active-subscription' : ''} ${
        working ? 'pe-none opacity-50' : ''
      }`}
    >
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="left"
        delay={{ show: 100, hide: 300 }}
        overlay={(props) => (
          <Tooltip
            {...props}
            className={`wide-tooltip-400 ${props?.className ?? ''}`}
          >
            {t(active ? 'Active subscription' : 'Expired subscription')}
          </Tooltip>
        )}
      >
        <Col
          className="d-inline-flex align-items-center text-truncate"
          xs={3}
          title={name}
        >
          <FaCircle
            size={30}
            className="me-1"
            color={
              future
                ? '#888888'
                : active
                ? 'var(--nextbrain-success-color)'
                : 'var(--nextbrain-error-color)'
            }
          />
          {name}
          <span className="ms-1 smallp">(id {plan?.id ?? ''})</span>
        </Col>
      </OverlayTrigger>
      {date}
      <Col className="d-flex justify-content-end" xs={2}>
        {!activePlan && (
          <>
            <Button
              className="start-confirm-button visible py-2 me-2"
              onClick={onEdit}
            >
              <FaEdit size={19} />
            </Button>
            <ConfirmButton
              className="original py-2 ms-2"
              variant="danger"
              disabled={working || !plan?.id}
              title={'Delete Subscription'}
              onClick={() => {
                setWorking(true)
                deleteSubscription({
                  subscriptionId: plan.id,
                  token,
                  signout,
                })
                  .then((res) => {
                    if (res?.ok) {
                      NotificationManager.info('Deleted subscription')
                      onUpdate()
                    } else
                      NotificationManager.error('Error deleting subscription')
                  })
                  .finally(() => {
                    setWorking(false)
                  })
              }}
            >
              <FaTrash size={17} />
            </ConfirmButton>
          </>
        )}
      </Col>
      {activePlan && (
        <Subscription
          company={companyData}
          subscription={plan}
          onUpdate={() => {
            onFinish()
            onUpdate()
          }}
          onCancel={() => {
            onFinish()
          }}
        />
      )}
    </Row>
  )
}

export default function CompanyManager({ companyId, companies, onUpdate }) {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [activeSubscription, setActiveSubscription] = useState(null)
  const [newSubscription, setNewSubscription] = useState(null)
  const company = companies?.[companyId]

  const plans = useMemo(() => {
    return (
      company?.subscription?.filter((s) => s.product_type === 'plan') ?? []
    ).sort(
      (a, b) => new Date(b.to_date).getTime() - new Date(a.to_date).getTime(),
    )
  }, [company])

  const plugins = useMemo(() => {
    return (
      company?.subscription?.filter((s) => s.product_type === 'plugin') ?? []
    ).sort(
      (a, b) => new Date(b.to_date).getTime() - new Date(a.to_date).getTime(),
    )
  }, [company])

  if (!company)
    return (
      <Row>
        <Col xs={12}>{t('No company found')}</Col>
      </Row>
    )

  return (
    <Row className="px-2 pb-3">
      <Col xs={12}>
        {newSubscription ? (
          <Subscription
            company={company}
            subscription={newSubscription}
            onUpdate={() => {
              setNewSubscription(null)
              onUpdate()
            }}
            onCancel={() => {
              setNewSubscription(null)
            }}
            avoidDataCollision={true}
          />
        ) : (
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
          >
            <Row className="mt-2 tabsdiv">
              <Col className="ps-0" xs={12}>
                <TabList className="ps-0 mb-0">
                  <Tab
                    onClick={() => {
                      setActiveSubscription(null)
                      setNewSubscription(null)
                    }}
                  >
                    {t('Plans')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      setActiveSubscription(null)
                      setNewSubscription(null)
                    }}
                  >
                    {t('Plugins')}
                  </Tab>
                </TabList>
              </Col>
            </Row>
            <Row
              className={`mt-2 subscriptions-container ${
                activeSubscription ? 'contains-active' : ''
              }`}
            >
              <Col xs="12">
                <TabPanel
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Row>
                    {plans?.length ? (
                      plans.map((plan) => (
                        <Col id={plan.id} xs={12}>
                          <PlanButton
                            companyData={company}
                            plan={plan}
                            onUpdate={onUpdate}
                            onEdit={() => setActiveSubscription(plan)}
                            onFinish={() => setActiveSubscription(null)}
                            activePlan={plan?.id === activeSubscription?.id}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col xs={12} className="h4 py-3 dflex-center">
                        <strong>{t('No plans in this company')}</strong>
                      </Col>
                    )}
                    <Col className="dflex-center" xs={12}>
                      <OverlayTrigger
                        rootClose={true}
                        trigger={'click'}
                        placement="auto"
                        delay={{ show: 100, hide: 300 }}
                        overlay={(props) => (
                          <Tooltip
                            {...props}
                            className={`wide-tooltip-400 ${
                              props?.className ?? ''
                            }`}
                          >
                            <Row style={{ minWidth: '300px' }}>
                              <Col xs={12}>
                                <Row>
                                  {Object.entries(templates.plan).map(
                                    ([k, v]) => (
                                      <Col key={k} xs={12}>
                                        <Button
                                          onClick={() => {
                                            setNewSubscription(
                                              JSON.parse(JSON.stringify(v)),
                                            )
                                          }}
                                          className="w-100 my-2"
                                        >
                                          {k}
                                        </Button>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </Tooltip>
                        )}
                      >
                        <div>
                          <BsFillPlusSquareFill
                            className="icon-btn"
                            size={60}
                          />
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Row>
                    {plugins?.length ? (
                      plugins.map((plan) => (
                        <Col id={plan.id} xs={12}>
                          <PlanButton
                            companyData={company}
                            plan={plan}
                            onUpdate={onUpdate}
                            onEdit={() => setActiveSubscription(plan)}
                            onFinish={() => setActiveSubscription(null)}
                            activePlan={plan?.id === activeSubscription?.id}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col xs={12} className="h4 py-3 dflex-center">
                        <strong>{t('No plugins in this company')}</strong>
                      </Col>
                    )}
                    <Col className="dflex-center" xs={12}>
                      <OverlayTrigger
                        rootClose={true}
                        trigger={'click'}
                        placement="auto"
                        delay={{ show: 100, hide: 300 }}
                        overlay={(props) => (
                          <Tooltip
                            {...props}
                            className={`wide-tooltip-400 ${
                              props?.className ?? ''
                            }`}
                          >
                            <Row style={{ minWidth: '300px' }}>
                              <Col xs={12}>
                                <Row>
                                  <Col xs={12}>Plugin</Col>
                                  {Object.entries(templates.plugin).map(
                                    ([k, v]) => (
                                      <Col key={k} xs={12}>
                                        <Button
                                          className="w-100 my-2"
                                          onClick={() => {
                                            setNewSubscription(
                                              JSON.parse(JSON.stringify(v)),
                                            )
                                          }}
                                        >
                                          {k}
                                        </Button>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </Tooltip>
                        )}
                      >
                        <div>
                          <BsFillPlusSquareFill
                            className="icon-btn"
                            size={60}
                          />
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </TabPanel>
              </Col>
            </Row>
          </Tabs>
        )}
      </Col>
    </Row>
  )
}
