import { Row, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'

import { ModelProvider } from '../../../providers/ModelProvider'
import DatasourceFlowDisplay from './DatasourceFlowDisplay'

export default function DatasourceFlowView({ model, setTitle }) {
  const { workspaceId } = useParams()

  return (
    <ModelProvider setTitle={setTitle} workspaceId={workspaceId}>
      <Row className="w-100">
        <Col
          className="d-flex align-items-center"
          style={{ minHeight: '60px', maxHeight: '60px' }}
          xs={12}
        >
          <Link to={-1}>
            <div className="width-auto col-auto ms-2">
              <span
                className="breadcrumbs-nav-report p-2 looks-like-a-2"
                style={{
                  fontWeight: 'bold',
                  border: '1px solid var(--nextbrain-main-color)',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                <BsArrowLeft size={25} />
              </span>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="position-relative pe-4 ms-3" xs={12}>
          <DatasourceFlowDisplay
            style={{ minHeight: 'calc(100vh - 70px)' }}
            model={model}
          />
        </Col>
      </Row>
    </ModelProvider>
  )
}
