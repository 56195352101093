import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { FaAd, FaLanguage, FaServer } from 'react-icons/fa'

import { useAuth } from '../../providers/AuthProvider'
import { getPodsAndQueues } from '../../services/server'
import Loading from '../loading/LoadingSmall'
import { JSONEditor } from '../editor/editor'

export default function Server({ ...props }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()

  const { data: podsAndQueues, isLoading } = useQuery(
    ['serverPodsAndQueues'],
    () => {
      return getPodsAndQueues({ token, signout })
    },
    { staleTime: 3000, refetchInterval: 3000 },
  )

  const parseDate = (dateTimeStr) => {
    const offset = new Date().getTimezoneOffset() * -1 // in minutes
    const [dateStr, timeStr] = dateTimeStr.split('T')
    const [year, month, day] = dateStr.split('-')
    const [hour, minute, second] = timeStr.split(':')

    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

    date.setHours(parseInt(hour))
    date.setMinutes(parseInt(minute))
    date.setSeconds(parseInt(second))

    return new Date(date.getTime() + offset * 60 * 1000)
  }

  const getDiffToNow = (date) => {
    const now = new Date()
    const diff = now.getTime() - date.getTime()
    const diffInDays = Math.floor(diff / 1000 / 60 / 60 / 24)
    const diffInHours = Math.floor(diff / 1000 / 60 / 60)
    const diffInMinutes = Math.floor(diff / 1000 / 60)
    const diffInSeconds = Math.floor(diff / 1000)

    if (diffInDays > 0) {
      if (diffInHours % 24 > 0) {
        return `${diffInDays} days, ${diffInHours % 24} hours and ${
          diffInMinutes % 60
        } minutes ago`
      }
      return `${diffInDays} days ago`
    }
    if (diffInHours > 0) {
      if (diffInMinutes % 60 > 0) {
        return `${diffInHours} hours, ${diffInMinutes % 60} minutes ago`
      }
      return `${diffInHours} hours ago`
    }
    if (diffInMinutes > 0) {
      if (diffInSeconds % 60 > 0) {
        return `${diffInMinutes} minutes, ${diffInSeconds % 60} seconds ago`
      }
      return `${diffInMinutes} minutes ago`
    }

    return `${diffInSeconds} seconds ago`
  }

  const podTypeToIcon = (podType) => {
    switch (podType) {
      case 'worker':
        return <FaServer className="mx-1" />
      case 'langchain':
        return <FaLanguage className="mx-1" />
      case 'mmm':
        return <FaAd className="mx-1" />
      default:
        return <></>
    }
  }

  const podTypeToStyles = (podType) => {
    const styles = {}
    switch (podType) {
      case 'langchain':
        styles.backgroundColor = '#2B3855'
        break
      case 'mmm':
        styles.backgroundColor = '#2E2E40'
        break
      default:
        break
    }
    return styles
  }

  const queueToStyle = (queue) => {
    queue = queue.toLowerCase()
    if (queue.startsWith('langchain')) {
      return podTypeToStyles('langchain')
    }
    if (queue.startsWith('mmm')) {
      return podTypeToStyles('mmm')
    }
    return {}
  }

  return (
    <>
      {!isLoading ? (
        <Row className="mt-2 mx-4" style={{ marginBottom: -30 }}>
          <Col md={12} style={{ textAlign: 'right' }}>
            <small>{new Date().toLocaleString()}</small>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="admin-panel" {...props}>
        <Col md={12} className="title mb-4">
          <div className="h2">Pods</div> <span className="line" />
        </Col>
        {isLoading ? (
          <Loading />
        ) : (
          podsAndQueues?.pods
            ?.sort((a, b) =>
              parseDate(a.last_update) > parseDate(b.last_update) ? -1 : 1,
            )
            .map((pod) => {
              return (
                <Col key={pod._id} md={3}>
                  <div
                    className="backfilters-activity mx-2 mb-2"
                    style={podTypeToStyles(pod.pod_type)}
                  >
                    <span>
                      <strong>{t('Last update')}:</strong>{' '}
                      {getDiffToNow(parseDate(pod.last_update))}
                    </span>
                    <br />
                    <span>
                      <strong>{t('Type')}:</strong>{' '}
                      {podTypeToIcon(pod.pod_type)}
                      {pod.pod_type}
                    </span>
                    <br />
                    <span>
                      <strong>{t('Pod name')}:</strong> {pod._id}
                    </span>
                    <br />
                    {pod?.task ? (
                      <span>
                        <strong>Task:</strong>
                        <br />
                        <JSONEditor
                          height={'200px'}
                          value={JSON.stringify(pod.task ?? {}, null, 2)}
                          setValue={() => {}}
                        />
                      </span>
                    ) : (
                      <span>
                        <strong>{t('Task')}:</strong> None
                      </span>
                    )}
                  </div>
                </Col>
              )
            })
        )}
      </Row>
      <Row className="mx-4" {...props}>
        <Col md={12} className="title mb-5">
          <div className="h2">{t('Queues')}</div> <span className="line" />
        </Col>
        {isLoading ? (
          <Loading />
        ) : (
          Object.keys(podsAndQueues?.queues ?? [])
            .sort()
            .map((queue) => {
              return (
                <Col key={queue} md={3}>
                  <div
                    className="backfilters-activity mx-2 mb-2"
                    style={queueToStyle(queue)}
                  >
                    <span>
                      <strong>{t('Queue')}:</strong>{' '}
                      {queue.replace('_Queue', '')}
                    </span>
                    <ul>
                      <li>
                        <strong>{t('Consumers')}: </strong>
                        <span>{podsAndQueues.queues[queue].consumers}</span>
                      </li>
                      <li>
                        <strong>{t('Messages')}: </strong>
                        <span>{podsAndQueues.queues[queue].messages}</span>
                      </li>
                      <li>
                        <strong>{t('Messages ready')}: </strong>
                        <span>
                          {podsAndQueues.queues[queue].messages_ready}
                        </span>
                      </li>
                      <li>
                        <strong>{t('Messages unacknowledge')}: </strong>
                        <span>
                          {podsAndQueues.queues[queue].messages_unacknowledged}
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              )
            })
        )}
      </Row>
    </>
  )
}
