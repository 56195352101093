import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
  ColumnChooser,
  WidgetVariableCorrelation,
} from '../widget-report/Widgets/VariableCorrelation'
import { getDistribution } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { NotificationManager } from 'react-notifications'
import HelpTooltip from './HelpTooltip'
import { SwitchButtons } from '../utils/ui'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'

import { useQuery } from 'react-query'

export function CustomVarCorrealtion({ model, id, ...props }) {
  const SAMPLES = 200
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [config, setConfig] = useState({})
  const [showLine, setShowLine] = useState(false)

  const columns = Object.keys(model.columns_active)
  const selectedColumns = {
    col1: config.col1 ?? columns[0],
    col2: config.col2 ?? columns[1] ?? columns[0],
  }
  const showGraphToggle =
    model.dataset.final_column_status[selectedColumns.col1] === 'Datetime' &&
    model.dataset.final_column_status[selectedColumns.col2] !== 'Categorical'

  useEffect(() => {
    setShowLine(false)
  }, [showGraphToggle])

  const { data, isLoading } = useQuery(
    ['distribution', config.col1, config.col2, model.id],
    async () => {
      return await getDistribution({
        modelId: model.id,
        first_column: config.col1,
        second_column: config.col2,
        samples: SAMPLES,
        token,
        signout,
      })
    },
    {
      staleTime: 20 * 60 * 1000,
      enabled: !!config.col1 && !!config.col2,
      onError: (e) => {
        NotificationManager.error(t('Failed to retrieve correlation data'))
      },
    },
  )

  if (!model || !model.columns_active) return <></>

  return (
    <>
      <Row id={id}>
        <Col
          className="d-inline-block text-truncate widget-title col-12"
          xs={12}
        >
          {t('Variable Correlation')}{' '}
          <HelpTooltip
            className="help-select-icon"
            message={t('correlation view data')}
            helpLink={'variable-correlation-1ecbd24'}
          />
        </Col>
        <Col xs={12}>
          {t(
            'You can select two columns and visualize a plot with their correlation',
          )}
        </Col>
        <Col xs={12}>
          <ColumnChooser
            config={config}
            onChange={(config) => setConfig(config)}
            colSize={6}
            selectProps={{
              className: 'nb-input-soft',
            }}
            model={model}
          />
        </Col>
        {showGraphToggle && (
          <div className="position-relative">
            <div
              className="position-absolute d-flex align-items-center"
              style={{ minHeight: '70px', zIndex: 1 }}
            >
              <SwitchButtons
                options={[t('Points'), t('Lines')]}
                selected={showLine ? 1 : 0}
                setSelected={(index) => setShowLine(index === 1)}
              />
            </div>
          </div>
        )}
        <Col xs={12} style={{ minHeight: '400px' }}>
          <DownloadGraphOverlay>
            <WidgetVariableCorrelation
              config={{
                graphFontSize: 15,
                samples: SAMPLES,
                title: '',
                showLine: showLine,
                ...config,
              }}
              model={model}
              requestedData={{
                [`correlation$$$${config.col1}$$$${config.col2}$$$${SAMPLES}`]:
                  isLoading ? 'loading' : data,
              }}
            />
          </DownloadGraphOverlay>
        </Col>
      </Row>
    </>
  )
}
