import React, { useMemo } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { Row, Col } from 'react-bootstrap'
import { defaultFormat, round } from '../utils/formating'
import { BasicTooltip } from '@nivo/tooltip'
import { useTranslation } from 'react-i18next'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'
import { animated } from '@react-spring/web'
import { useAnimatedPath } from '@nivo/core'
import * as d3 from 'd3'

import {
  abbrNum,
  getTextWidth,
  getRotatedHeight,
  nivoProps,
  nivoLineProps,
} from '../utils/ui'

function rangeToMean(range) {
  const singleNumberMatch = /^[0-9.]+$/g
  const singleNumber = range.match(singleNumberMatch)
  if (singleNumber && singleNumber.length) {
    const r = round(Number.parseFloat(singleNumber))
    return { x: r, xl: r, xr: r }
  }

  const fromToMatch = /from -?[0-9.]+ to -?[0-9.]+/g
  const mt = range.match(fromToMatch)
  if (mt && mt.length) {
    range = `(${mt[0].replace('from ', '').replace('to', ',')}]`
  }

  const [left, right] = range.substring(1, range.length - 1).split(',')
  const [lnum, rnum] = [Number.parseFloat(left), Number.parseFloat(right)]
  return { x: round(lnum + rnum) / 2, xl: round(lnum), xr: round(rnum) }
}

function NoData({ ...props }) {
  const { t } = useTranslation()
  return (
    <Row className="justify-content-center p-4" {...props}>
      <Col xs={'auto'}>
        <strong>{t('No data available')}</strong>
      </Col>
    </Row>
  )
}

const CustomAreaLayer = ({ series, innerWidth, fill, ...props }) => {
  const areaGenerator = d3
    .area()
    .x((d) => d.position.x)
    .y0((d) => d.position.y)
    .y1((d) => props.yScale(0))
    .curve(d3.curveMonotoneX)

  const animatedPath = useAnimatedPath(areaGenerator(series[0].data))
  return (
    <animated.path
      key="custom-area"
      fillRule="even-odd"
      filter="url(#signcolor)"
      d={animatedPath}
      fillOpacity={'0.5'}
    />
  )
}

const formatTick = ['Double', 'Integer']
function getDefaultFormat(model, column) {
  const type = model?.dataset?.statistics?.[column]?.logical_type
  if (formatTick.includes(type)) return (tick) => defaultFormat({ num: tick })
  return (x) => x
}

export function TopFieldsGraphLine({
  effects,
  category,
  model,
  variable,
  useNegative = false,
  maximumY,
  minimumY,
  isWidget = false,
  ...props
}) {
  const maxY = round(maximumY, 2)
  const minY = round(minimumY, 2)
  const positiveLine = []
  const negativeLine = []
  const xTicks = getDefaultFormat(model, category)

  const lineData = useMemo(() => {
    const line = []
    let segmentKey = 0
    let effectKey = 0
    for (let effect of effects) {
      const individual_importance =
        effect.individual_importance === 'nan'
          ? 0
          : effect.individual_importance
      const { x, xl, xr } = rangeToMean(effect.range)
      const currentX =
        effectKey === 0 ? xl : effectKey === effects.length - 1 ? xr : x

      line.push({
        x: currentX,
        xl,
        xr,
        y: individual_importance,
      })
      effectKey++
    }
    line.sort((a, b) => a.x - b.x)
    return [
      {
        color: 'var(--nextbrain-white-font)',
        id: `${category} segment-${segmentKey} influence`,
        data: line,
        filter: 'url(#signcolor)',
      },
    ]
  }, [category, effects])

  const allEmpty = positiveLine.every((d) => d.y === 0)
  let base = negativeLine.length ? 'auto' : 0

  const csvData = lineData?.[0]?.data
    ? [
        [category, 'influence'],
        ...lineData.reduce(
          (acc, line) => [...acc, ...line.data.map((d) => [d.x, d.y ?? 0])],
          [],
        ),
      ]
    : []

  const xTicksHeight =
    10 +
    (csvData.length > 1
      ? getRotatedHeight(
          Math.max(
            ...[csvData[1], csvData[csvData.length - 1]].map((d) =>
              getTextWidth(abbrNum(d[0]), 'normal 11px sans-serif', false),
            ),
          ),
        )
      : 0)
  const yTicksWidth = getTextWidth('-9.99', 'normal 11px sans-serif')

  return (
    <Row className="h-100" {...props}>
      {lineData.length > 0 || allEmpty ? (
        <DownloadGraphOverlay
          buttonsStyle={{ top: '10px', right: '20px' }}
          disabled={isWidget}
        >
          <Col
            className="data-holder h-100"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`influence__${category}__${variable}__${model.id}`}
            xs={12}
          >
            <ResponsiveLine
              {...nivoLineProps}
              data={lineData}
              margin={{
                top: 100,
                right: 10,
                bottom: 100 + xTicksHeight,
                left: 50 + yTicksWidth,
              }}
              yScale={{
                type: 'linear',
                min: minY ?? base,
                max: maxY ?? 'auto',
                stacked: false,
                reverse: false,
              }}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                if (slice.points.length === 1)
                  return (
                    <BasicTooltip
                      id={`${category} ${slice.points[0].data.xFormatted}`}
                      value={`${slice.points[0].data.yFormatted}`}
                      color={slice.points[0].color}
                      enableChip
                    />
                  )
                const positive =
                  slice.points[1].data.y + slice.points[0].data.y > 0
                return (
                  <BasicTooltip
                    id={`${category} ${slice.points[0].data.xFormatted}`}
                    value={`+${slice.points[1].data.yFormatted} to ${slice.points[0].data.yFormatted}`}
                    color={slice.points[positive ? 1 : 0].color}
                    enableChip
                  />
                )
              }}
              axisBottom={{
                ...nivoProps.axisBottom,
                legend: category,
                legendOffset: xTicksHeight + 20,
                format: xTicks,
              }}
              enableArea={false}
              areaBaselineValue={0}
              pointLabelYOffset={-12}
              axisLeft={{
                ...nivoProps.axisLeft,
                legend: `Influence of ${category} in ${model.target}`,
                legendOffset: -(yTicksWidth + 30),
              }}
              pointSize={5}
              enableGridX={true}
              enableGridY={true}
              useMesh={true}
              layers={[
                (props) => (
                  <defs>
                    <filter id="signcolor">
                      <feFlood
                        floodColor="var(--nextbrain-decision-tree-green)"
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        result="flood1"
                      />
                      <feFlood
                        floodColor="var(--nextbrain-decision-tree-red)"
                        x="0"
                        y={props.yScale(0)}
                        width="100%"
                        height="100%"
                        result="flood2"
                      />
                      <feMerge result="merged">
                        <feMergeNode in="flood1" />
                        <feMergeNode in="flood2" />
                      </feMerge>
                      <feComposite
                        operator="in"
                        in2="SourceGraphic"
                        in="merged"
                        result="compos"
                      />
                    </filter>
                  </defs>
                ),
                'grid',
                'markers',
                'axes',
                'areas',
                CustomAreaLayer,
                'crosshair',
                'lines',
                'points',
                'slices',
                'mesh',
                'legends',
              ]}
            />
          </Col>
        </DownloadGraphOverlay>
      ) : (
        <NoData />
      )}
    </Row>
  )
}

export function TopFieldsGraphColumns({
  effects,
  category,
  model,
  variable,
  isWidget = false,
  ...props
}) {
  const xTicks = getDefaultFormat(model, category)
  let data = effects.map((e) =>
    e.individual_importance === 'nan'
      ? {
          value: e.range,
          effect: 0,
        }
      : {
          value: e.range,
          effect: e.individual_importance,
        },
  )
  if (data.length > 20) data = data.filter((d) => d.effect !== 0).slice(0, 20)

  data.forEach((d) => {
    d.color =
      d.effect < 0
        ? 'var(--nextbrain-tables-negative-graph-bar-color)'
        : 'var(--nextbrain-tables-graph-bar-color)'
  })

  const csvData = data
    ? [[category, 'influence'], ...data.map((d) => [d.value, d.effect])]
    : []

  const xTicksHeight =
    10 +
    (data?.length > 0
      ? getRotatedHeight(
          Math.max(
            ...data.map((d) =>
              getTextWidth(d.value, 'normal 11px sans-serif', false),
            ),
          ),
        )
      : 0)
  const yTicksWidth = getTextWidth('-9.99', 'normal 11px sans-serif', false)

  return (
    <Row className="h-100" {...props}>
      {data.length ? (
        <DownloadGraphOverlay
          buttonsStyle={{ top: '10px', right: '20px' }}
          disabled={isWidget}
        >
          <Col
            className="data-holder h-100"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`influence__${category}__${variable}__${model.id}`}
            xs={12}
          >
            <ResponsiveBar
              {...nivoProps}
              data={data}
              keys={['effect']}
              indexBy="value"
              margin={{
                top: 100,
                right: 10,
                bottom: 50 + xTicksHeight,
                left: 50 + yTicksWidth,
              }}
              groupMode="grouped"
              layout={'vertical'}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={(d) => d.data.color}
              enableGridY={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                ...nivoProps.axisBottom,
                legend: category,
                legendOffset: xTicksHeight + 20,
                format: xTicks,
              }}
              axisLeft={{
                ...nivoProps.axisLeft,
                legend: `Effects of ${category} in ${model.target}`,
                legendOffset: -(yTicksWidth + 30),
              }}
              labelSkipHeight={0}
              labelTextColor="#000"
              legends={[]}
              ariaLabel="Bar chart"
              barAriaLabel={function (e) {
                return (
                  e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
                )
              }}
              label={(d) => `${d.value}`}
              labelSkipWidth={50}
            />
          </Col>
        </DownloadGraphOverlay>
      ) : (
        <NoData />
      )}
    </Row>
  )
}
