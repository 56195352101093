import { useRef, useState } from 'react'
import { Handle, Position, useReactFlow } from 'reactflow'
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { FaPlus, FaTrash, FaFlagCheckered } from 'react-icons/fa'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import { ActionTemplate } from './DatasourceActions'
import { actionConfig } from './config'
import { useTranslation } from 'react-i18next'
import { useProps } from '../../../providers/PropsProvider'

export function SingleHandle({ id, isConnectable }) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id={`${id}_uniq_target`}
        style={{
          background: '#555',
          left: '-35px',
          minWidth: '25px',
          minHeight: '25px',
        }}
        isConnectable={isConnectable}
        className="input-action-edge"
      />
    </>
  )
}

export function DoubleHandle({ id, isConnectable }) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id={`${id}_left_target`}
        style={{
          left: '0px ',
          top: '0px',
          background: '#555',
          minWidth: '25px',
          minHeight: '25px',
        }}
        isConnectable={isConnectable}
        className="input-action-edge"
      />
      <Handle
        type="target"
        position={Position.Left}
        id={`${id}_right_target`}
        style={{
          left: '0%',
          top: '100%',
          background: '#555',
          minWidth: '25px',
          minHeight: '25px',
        }}
        isConnectable={isConnectable}
        className="input-action-edge"
      />
    </>
  )
}

const doubleInput = new Set(['Merge', 'Concatenate'])

export default function DatasourceActionNode({ data, isConnectable, id }) {
  const [visibleActions, setVisibleActions] = useState(false)
  const componentRef = useRef(actionConfig[data.type].component)
  const componentConfigRef = useRef(actionConfig[data.type].prepareData)
  const { getNode } = useReactFlow()
  const { createNewNode, selectOutputNode, outputNode } = useProps()

  const ComponentConfig = componentRef.current
  const close = () => data.controls.onUpdate({ ...data, active: false })
  const { t } = useTranslation()
  const node = getNode(id)
  const isOutput = outputNode === node?.id

  return (
    <>
      <ActionTemplate
        id={node.id}
        position={node.position}
        node={node?.data ?? {}}
        type={data.type}
        className={`no-hover node-type ${data.valid ? 'valid' : 'invalid'}`}
      />
      {doubleInput.has(data.type) ? (
        <DoubleHandle id={id} isConnectable={isConnectable} />
      ) : (
        <SingleHandle id={id} isConnectable={isConnectable} />
      )}
      <Handle
        type="source"
        position={Position.Right}
        id={`${id}_source`}
        style={{
          right: '-25px',
          background: '#555',
          minWidth: '15px',
          minHeight: '15px',
        }}
        isConnectable={isConnectable}
      />
      <ContextMenuTrigger
        id={`datasource-flow-transform-context-${id}`}
        mouseButton={0}
        disableIfShiftIsPressed={true}
      >
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement={'top'}
          delay={{ show: 100, hide: 200 }}
          overlay={(props) => (
            <Tooltip {...props}>
              <span className="">
                {t('Apply a transformation for your datasource')}
              </span>
            </Tooltip>
          )}
        >
          <div
            className={`transform-datasource-trigger ${
              visibleActions ? 'force-visible-trigger' : ''
            } ${node?.data?.valid ? '' : 'd-none'}`}
          >
            <FaPlus size={30} />
          </div>
        </OverlayTrigger>
      </ContextMenuTrigger>
      <ContextMenu
        id={`datasource-flow-transform-context-${id}`}
        className="menu-context-dataflow config-forced-actions-context-menu"
        onShow={() => setVisibleActions(true)}
        onHide={() => setVisibleActions(false)}
      >
        {Object.keys(actionConfig).map((key) => (
          <MenuItem key={key}>
            <div
              className="d-inline-block text-nowrap h5 mb-0"
              onClick={() => {
                createNewNode({
                  type: key,
                  position: node.position,
                  forceActive: (actionConfig[key]?.inputs ?? 1) === 1,
                  input: node,
                })
              }}
            >
              {actionConfig[key].iconSmall}
              {t(key)}
            </div>
          </MenuItem>
        ))}
      </ContextMenu>
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'top'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip
            {...props}
            className={`context-menu-column-type pe-none ${
              props?.className ?? ''
            }`}
          >
            <span className="">
              {isOutput
                ? t('Node selected as output for the model')
                : t('Select as output node')}
            </span>
          </Tooltip>
        )}
      >
        <div
          className={`output-datasource-trigger icon-btn  ${
            node?.data?.valid ? '' : 'd-none'
          } ${isOutput ? 'selected-output-node' : ''}`}
          onClick={() => !isOutput && selectOutputNode({ node })}
        >
          {/*<Image
            className="d-inline-block ms-1"
            src={'/goal.svg'}
            height={30}
          />*/}
          <FaFlagCheckered color="white" size={28} />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'top'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip
            {...props}
            className={`context-menu-column-type pe-none ${
              props?.className ?? ''
            }`}
          >
            <span className="">{t('Delete node')}</span>
          </Tooltip>
        )}
      >
        <div
          className={`delete-datasource-trigger icon-btn `}
          onClick={() => node?.data?.controls?.onDelete()}
        >
          <FaTrash color="white" size={20} />
        </div>
      </OverlayTrigger>

      <Modal
        dialogClassName={`modal-nb modal-datasource-${data.type?.replace(
          ' ',
          '-',
        )}`}
        show={data.active}
        keyboard={false}
        onHide={close}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="ms-5">{t(data.type)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.active && (
            <ComponentConfig
              id={id}
              actionLabel="Save"
              configuration={data.config}
              close={close}
              onFinish={(sample, ...props) => {
                data.sample = sample
                const newData = componentConfigRef.current(data, ...props)
                data.controls.onUpdate({ ...newData, active: false })
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
