import { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import Loading from '../../../loading/LoadingSmall'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import TableError from './TableError'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'

export default function ConcatTable({ sampleLeft, sampleRight, onDataLoad }) {
  const { t } = useTranslation()

  const showData = useMemo(() => {
    if (!sampleLeft?.columns || !sampleRight?.columns) return null
    if (sampleLeft.columns.length !== sampleRight.columns.length) return null
    if (!sampleLeft.columns.every((c) => sampleRight.columns.includes(c)))
      return null
    onDataLoad(sampleLeft)
    return {
      columns: [sampleLeft.columns],
      data: sampleLeft.sample,
    }
    // eslint-disable-next-line
  }, [sampleLeft, sampleRight])

  const isLoading = !sampleLeft || !sampleRight
  const error = !isLoading && !showData

  return error ? (
    <TableError
      error="Failed to generate concat inputs"
      subtext={'The columns of both datasets dont match'}
    >
      <Row>
        <Col xs={12}>
          <ColumnPaginatedGridTable
            key="data"
            stableHeight={true}
            header={[sampleLeft?.columns ?? []]}
            rows={sampleLeft?.sample ?? []}
            rowsPerPage={5}
            cellElement={(c) => <span className="text-truncate">{c}</span>}
            index={(i) => (
              <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
                {i ? i : ''}
              </div>
            )}
            className="w-100 table-view-data"
          />
        </Col>
        <Col xs={12}>
          <ColumnPaginatedGridTable
            key="data"
            stableHeight={true}
            header={[sampleRight?.columns ?? []]}
            rows={sampleRight?.sample ?? []}
            rowsPerPage={5}
            cellElement={(c) => <span className="text-truncate">{c}</span>}
            index={(i) => (
              <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
                {i ? i : ''}
              </div>
            )}
            className="w-100 table-view-data"
          />
        </Col>
      </Row>
    </TableError>
  ) : isLoading ? (
    <Loading />
  ) : (
    <ColumnPaginatedGridTable
      key="data"
      stableHeight={true}
      header={showData.columns}
      rows={showData.data}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data"
      Pager={({ maxPage, onChange, page }) => (
        <ReactPaginate
          className="paginate-view-data"
          breakLabel="..."
          nextLabel={t('next')}
          onPageChange={({ selected }) => onChange(selected)}
          pageRangeDisplayed={3}
          pageCount={maxPage}
          previousLabel={t('previous')}
          renderOnZeroPageCount={null}
        />
      )}
      pagerLast={true}
    />
  )
}
