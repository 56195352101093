import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import CodeMirror from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { python } from '@codemirror/lang-python'
import { dracula } from '@uiw/codemirror-theme-dracula'
import { useTranslation } from 'react-i18next'
import { AiFillGithub } from 'react-icons/ai'
import { SiJupyter } from 'react-icons/si'
import { URL } from '../../services/base'
import { NotificationManager } from 'react-notifications'

import { useAuth } from '../../providers/AuthProvider'

import './PredictForDevelopers.css'
import HelpTooltip from '../model-content/HelpTooltip'
import { downloadJupyter } from '../../services/model'

function NotebookDownload({ model, languageBindings }) {
  const { token, signout } = useAuth()
  if (languageBindings && languageBindings.notebook) {
    return (
      <Button
        onClick={() => {
          downloadJupyter({
            name: `${model.dataset.name}_${model.id}_notebook`,
            description: model.html_description
              ? `${model.html_description}`
              : `Example of usage for ${model.dataset.name}`,
            cells: [
              languageBindings.dependencies
                ? {
                    cell_type: 'install',
                    data: languageBindings.dependencies,
                  }
                : null,
              {
                cell_type: 'code',
                data: languageBindings.value,
              },
            ].filter((e) => e),
            token,
            signout,
          })
            .then((response) => {
              if (response.status !== 200) {
                NotificationManager.error(
                  'Error downloading notebook',
                  'Error',
                  5000,
                )
                return
              }
              try {
                return response.blob()
              } catch (e) {
                NotificationManager.error(
                  'Error downloading notebook',
                  'Error',
                  5000,
                )
              }
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                `${model.dataset.name}_${model.id}_notebook.ipynb`,
              )

              // Append to html link element page
              document.body.appendChild(link)

              // Start download
              link.click()

              // Clean up and remove the link
              link.parentNode.removeChild(link)
            })
        }}
        className="download-notebook py-1"
      >
        <Row className="justify-content-center">
          <Col className="d-flex align-items-center pe-0 " xs={'auto'}>
            <strong>Jupyter notebook</strong>
          </Col>
          <Col className="ps-0" xs={'auto'}>
            <SiJupyter className="ms-1" color="#f37626" size={19} />
          </Col>
        </Row>
      </Button>
    )
  }

  return <></>
}

export default function PredictForDevelopers({ model, ...props }) {
  const { t } = useTranslation()
  let { user } = useAuth()
  const columnsActive = Object.keys(model?.columns_active ?? {})
    .filter((c) => c !== model?.target)
    .map((c) => `"${c}"`)
    .join(', ')

  const availableLanguages = {
    JavaScript: {
      lang: javascript,
      value: `import { NextBrain } from 'nextbrain'

const model_id = '${model?.id}'
const nb = new NextBrain({
  access_token: '${user.access_token}',
})
const predictTable = await nb.loadCsv('<PATH-TO-YOUR-PREDICTING-CSV>')
const response = await nb.predictModel(modelId, predictTable)
console.log(response)`,
      github: 'https://github.com/NextBrain-ai/nextbrain-javascript',
    },
    Python: {
      lang: python,
      value: `from nextbrain import NextBrain
from typing import Any, List

model_id = '${model?.id}'
nb = NextBrain('${user.access_token}')

predict_table: List[List[Any]] = nb.load_csv('<PATH-TO-YOUR-PREDICTING-CSV>')
response = nb.predict_model(model_id, predict_table[0], predict_table[1:])
print(response)`,
      github: 'https://github.com/NextBrain-ai/nextbrain-python',
      notebook: true,
      dependencies: `!pip install nextbrain
!pip install asyncio aiohttp
`,
    },
    CURL: {
      lang: () => null,
      value: `curl -X 'POST' \\
  '${URL}/model/predict_token/${model?.id ?? 'model_id'}' \\
    -H 'accept: application/json' \\
    -H 'Content-Type: application/json' \\
    -d '{
      "header": [${columnsActive}],
      "rows": [
        [ "${t('list of values for each column in the header')}" ]
      ],
      "access_token": "${user.access_token}"
    }'`,
    },
  }

  const extraCodeMirrors = {
    JavaScript: {
      lang: javascript,
      value: `fetch('${URL}/model/predict_token/${model?.id ?? 'model_id'}', {
  method: 'POST',
  headers: {
    'accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "header": [${columnsActive}],
    "rows": [
      [ "${t('list of values for each column in the header')}" ]
    ],
    "access_token": "${user.access_token}"
  })
});`,
      github: 'https://github.com/NextBrain-ai/nextbrain-javascript',
    },
    Python: {
      lang: python,
      value: `import requests

response = requests.post(
  '${URL}/model/predict_token/${model?.id ?? 'model_id'}',
  json={
    "header": [${columnsActive}],
    "rows": [
      [ "${t('list of values for each column in the header')}" ]
    ],
    "access_token": "${user.access_token}"
  }
)`,
      dependencies: `!pip install requests`,
      notebook: true,
    },
  }

  const [languageBindings, setLanguageBindings] = useState(
    availableLanguages['Python'],
  )
  const [language, setLanguage] = useState('Python')

  return (
    <Row {...props}>
      <Row className="mb-2">
        <Col xs={'auto'}>
          <span>
            {t('Language bindings')}{' '}
            <HelpTooltip
              className="help-select-icon"
              message={'developers'}
              helpLink={'nextbrain-for-developers-x3g0df'}
            />
          </span>
          <Form.Select
            className=""
            defaultValue={'Python'}
            onChange={(e) => {
              setLanguageBindings(availableLanguages[e.target.value])
              setLanguage(e.target.value)
            }}
          >
            {Object.keys(availableLanguages).map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {languageBindings.github ? (
        <Row className="justify-content-between align-items-end">
          <Col xs={'auto'}>
            <NotebookDownload
              model={model}
              languageBindings={languageBindings}
            />
          </Col>
          <Col style={{ marginTop: -20 }} xs={'auto'}>
            <h5 className="d-inline-block text-nowrap pe-4">
              <small>{t('Opensource on')} </small>
              <a
                href={languageBindings.github}
                target="_blank"
                className={`link `}
                rel="noopener noreferrer"
                onClick={() => window.open(languageBindings.github, '_blank')}
              >
                <AiFillGithub size="1.2em" style={{ marginTop: -5 }} /> GitHub
              </a>
            </h5>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className={`position-relative`}>
        <CodeMirror
          value={languageBindings.value}
          editable={false}
          extensions={[languageBindings.lang({})].filter((e) => e)}
          onChange={() => {}}
          theme={dracula}
          indentWithTab
        />
      </Row>
      {extraCodeMirrors[language] ? (
        <>
          <Row className="mt-4 mb-2">
            <Col>
              <strong>{t('Or without our SDK')}</strong>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-end">
            <Col xs={'auto'}>
              <NotebookDownload
                model={model}
                languageBindings={extraCodeMirrors[language]}
              />
            </Col>
            <Col xs={'auto'}></Col>
          </Row>
          <Row>
            <CodeMirror
              value={extraCodeMirrors[language].value}
              editable={false}
              extensions={[extraCodeMirrors[language].lang({})].filter(
                (e) => e,
              )}
              onChange={() => {}}
              theme={dracula}
              indentWithTab
            />
          </Row>
        </>
      ) : (
        <></>
      )}
    </Row>
  )
}
