import React, { useEffect, useState } from 'react'
import { Container } from 'react-grid-system'
import { useParams, useSearchParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import ModelContent from '../model-content/model-content'
import NewModelContent from '../model-content/NewModelContent'
import { editProjectById } from '../../services/project'
import { useAuth } from '../../providers/AuthProvider'
import NewProject from '../homepage/NewProject'
import Loading from '../loading/LoadingSmall'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { ModelProvider } from '../../providers/ModelProvider'
import { useModels } from '../../providers/ModelProvider'
import { SocketProvider } from '../../providers/SocketProvider'
import ModelNav from './ModelNav'

import './model-landing.css'
import BreadcrumbNav from '../header/BreadcrumbNav'
import { useQueryClient } from 'react-query'

function ModelContentWrapper({ searchParams, id, dataflow, ...props }) {
  const {
    loading,
    loadingCompleteModel,
    lightModels,
    activeModel,
    setActiveModel,
    project,
  } = useModels()

  useEffect(() => {
    try {
      const modelId = searchParams.get('model-id')
      if (modelId && modelId !== activeModel?.id) {
        const model = lightModels.find(
          (m) => m.id === searchParams.get('model-id'),
        )
        if (model) setActiveModel(model)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [searchParams])

  if (dataflow) return <NewModelContent />

  if (loading || loadingCompleteModel)
    return (
      <Container fluid className={`p-0 mt-2`}>
        <div
          className={`model-content`}
          style={{ marginTop: -18, scrollY: 'hidden' }}
        >
          <Loading style={{ minHeight: 'calc(100vh - 292px)' }} />
        </div>
      </Container>
    )
  if (activeModel && project) {
    const fullW = searchParams.get('modelContentWidth') === 'full'
    return (
      <Container fluid className={`p-0 mt-2`}>
        {activeModel?.id !== 'new' ? (
          <div
            className={fullW ? '' : `model-content mx-1 pt-0`}
            style={
              fullW ? {} : { maxHeight: 'calc(100vh - 60px)', overflow: 'auto' }
            }
          >
            <ModelContent key={activeModel.id} />
          </div>
        ) : (
          <div className={`model-content mx-1`}>
            <NewModelContent />
          </div>
        )}
      </Container>
    )
  }

  return (
    <Container fluid className={`p-0 mt-2`}>
      <ModelNav
        projectId={id}
        setActiveModel={setActiveModel}
        models={lightModels}
        className="mx-1"
      />
    </Container>
  )
}

function ModelLanding({ setTitle }) {
  const { t } = useTranslation()
  const [editProject, setEditProject] = useState(false)
  const [searchParams] = useSearchParams()
  const param = useParams()
  const { signout, token } = useAuth()
  const queryClient = useQueryClient()
  const dataflow = searchParams.get('connector') === 'dataflow'

  return (
    <ModelProvider setTitle={setTitle} key={param.id} workspaceId={param.id}>
      <BreadcrumbNav />
      <SocketProvider>
        <div className="model-landing-container">
          <ModelContentWrapper
            searchParams={searchParams}
            id={param.id}
            dataflow={dataflow}
          />
          <Modal size={'lg'} show={editProject}>
            <Modal.Body>
              <NewProject
                allUsers={{}}
                onCancel={() => {
                  setEditProject(false)
                }}
                onSave={async (data) => {
                  const result = await editProjectById(
                    data.id,
                    {
                      project_name: data.name,
                      invited_emails: data.invited_emails,
                      color: data.color,
                    },
                    token,
                    signout,
                  )
                  if (result && result.id) {
                    setEditProject(false)
                    queryClient.invalidateQueries(['project', param.id])
                  } else {
                    NotificationManager.error(
                      `${t('Failed to edit workspace')} ${data.name ?? ''}.`,
                    )
                    setEditProject(false)
                  }
                }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </SocketProvider>
    </ModelProvider>
  )
}

export default ModelLanding
