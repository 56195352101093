import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import { getVideos } from '../../services/videos'
import VideoCard from './VideoCard'
import { useInfiniteQuery } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import './Guides.css'

export default function Guides({ setTitle = () => {}, ...props }) {
  const { t } = useTranslation()
  const { token } = useAuth()

  const [type, setType] = useState('everything')
  const [industryFilter, setIndustryFilter] = useState([])
  const [problemFilter, setProblemFilter] = useState([])
  const [levelSelected, setLevelSelected] = useState('all')

  const industries = [
    { value: 'energy', label: t('energy') },
    { value: 'financial', label: t('financial') },
    { value: 'engineering', label: t('engineering') },
    { value: 'government', label: t('government') },
    { value: 'healthcare', label: t('healthcare') },
    { value: 'manufacturing', label: t('manufacturing') },
    { value: 'media', label: t('media') },
    { value: 'retail', label: t('retail') },
    { value: 'unknown', label: t('unknown') },
  ]
  const problem_types = [
    { value: 'classification', label: t('classification') },
    { value: 'regression', label: t('regression') },
    { value: 'forecast', label: t('forecast') },
  ]
  const levels = [
    { value: 'all', label: t('all') },
    { value: 'easy', label: t('easy') },
    { value: 'medium', label: t('medium') },
    { value: 'hard', label: t('hard') },
  ]
  const types = [
    { value: 'everything', label: t('all') },
    { value: 'business_case', label: t('Business case') },
    { value: 'example', label: t('Examples') },
    { value: 'basic', label: t('Basics') },
  ]

  const handleIndustryCheckboxChange = (e) => {
    const value = e.target.value
    if (industryFilter.includes(value)) {
      setIndustryFilter(industryFilter.filter((v) => v !== value))
    } else {
      setIndustryFilter([...industryFilter, value])
    }
  }
  const handleProblemCheckboxChange = (e) => {
    const value = e.target.value
    if (problemFilter.includes(value)) {
      setProblemFilter(problemFilter.filter((v) => v !== value))
    } else {
      setProblemFilter([...problemFilter, value])
    }
  }

  const fetchVideos = useCallback(
    async ({ pageParam = 0 }) => {
      const response = await getVideos(
        token,
        type,
        industryFilter,
        problemFilter,
        levelSelected,
        pageParam,
      )
      return {
        data: response,
        nextCursor: pageParam + 1,
      }
    },
    [token, type, industryFilter, problemFilter, levelSelected],
  )

  const { data, isLoading, isError, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['videos', token, type, industryFilter, problemFilter, levelSelected],
      fetchVideos,
      {
        getNextPageParam: (lastPage) =>
          lastPage.data.length > 0 ? lastPage.nextCursor : undefined,
        refetchOnWindowFocus: false,
      },
    )

  const intObserver = useRef(null)
  const scrollEndRef = useCallback(
    (scrollEnd) => {
      if (isLoading) return

      if (intObserver.current) intObserver.current.disconnect()

      intObserver.current = new IntersectionObserver((scrollEnd) => {
        if (scrollEnd[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })

      if (scrollEnd) intObserver.current.observe(scrollEnd)
    },
    [isLoading, fetchNextPage, hasNextPage],
  )

  useEffect(
    () => {
      setTitle(`${t('Nextbrain')} | ${t('Academy')}`)
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <Row className="newContainer mx-0">
      <Alert show={error}>{error ?? ''}</Alert>
      <Col lg={2} xs={12} className="video-filter-container">
        <Row className="mx-auto mt-4">
          <Col lg={12} xs={3}>
            <Row className="filter-label">{t('Content')}</Row>
            <div className=" difficulty-selector mt-2 industries-checkbox mx-2">
              {types.map((select) => (
                <div
                  className="form-check form-check-inline"
                  key={select.value}
                >
                  <input
                    className="form-check-input radio-type"
                    type="radio"
                    name="type"
                    id={`type-${select.value}`}
                    value={select.value}
                    checked={type === select.value}
                    onChange={(e) => setType(e.target.value)}
                    style={{ transform: 'scale(0.7)' }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`type-${select.value}`}
                  >
                    {select.label}
                  </label>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={12} xs={3}>
            <Row className="filter-label">{t('Industries')}</Row>
            <div className="mt-2 selector industries-checkbox mx-1">
              {industries.map(({ value, label }) => (
                <label key={value} className="checkbox-label ">
                  <input
                    type="checkbox"
                    value={value}
                    checked={industryFilter.includes(value)}
                    onChange={handleIndustryCheckboxChange}
                    className="form-check-input-guides"
                  />
                  <span className="check-label">{label}</span>
                </label>
              ))}
            </div>
          </Col>
          <Col lg={12} xs={3}>
            <Row className="filter-label">{t('Problem types')}</Row>
            <div className="mt-2 selector industries-checkbox mx-1">
              {problem_types.map(({ value, label }) => (
                <label key={value} className="checkbox-label ">
                  <input
                    type="checkbox"
                    value={value}
                    checked={problemFilter.includes(value)}
                    onChange={handleProblemCheckboxChange}
                    className="form-check-input-guides"
                  />
                  <span className="check-label">{label}</span>
                </label>
              ))}
            </div>
          </Col>
          <Col lg={12} xs={3}>
            <Row className="filter-label">{t('Difficulty')}</Row>
            <div className=" difficulty-selector mt-2 industries-checkbox mx-2">
              {levels.map((option) => (
                <div
                  className="form-check form-check-inline"
                  key={option.value}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="difficulty"
                    id={`difficulty-${option.value}`}
                    value={option.value}
                    checked={levelSelected === option.value}
                    onChange={(e) => setLevelSelected(e.target.value)}
                    style={{ transform: 'scale(0.7)' }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`difficulty-${option.value}`}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={2} xs={12} />
      <Col className="ms-2 ms-lg-0">
        <Row className="me-1">
          <Col className="mt-3 mx-2 ">
            <Row className="videos-list">
              {isError ? (
                <div>{t('Not able to load videos')}</div>
              ) : isLoading ? (
                <div>{t('Loading...')}</div>
              ) : Array.isArray(data?.pages) && data.pages.length > 0 ? (
                <>
                  {data.pages.map((page, pageIdx) => {
                    if (!Array.isArray(page.data))
                      return <React.Fragment key={pageIdx}></React.Fragment>
                    return page.data.map((video, videoIdx) => {
                      const { id, type, ...props } = video
                      return <VideoCard key={id} {...props} />
                    })
                  })}
                </>
              ) : (
                <div>{t('No videos')}</div>
              )}
              <div ref={scrollEndRef}></div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
