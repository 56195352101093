import React, { useEffect, useState, useMemo } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { FaUpload } from 'react-icons/fa'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import {
  downloadLookLikePrediction,
  getForecast,
  getModelById,
  originalForecast,
  predictModel,
  uploadBatchPredict,
} from '../../services/model'
import { stringToCsv } from '../upload-csv/upload-csv'
import useUploadCsv from '../upload-csv/useUploadCSV'
import ViewDataTable from '../view-data/view-data'
import PredictResultPie from './PredictResultPie'
import { defaultFormat, round, zeroPad } from '../utils/formating'
import { useAuth } from '../../providers/AuthProvider'
import TimeseriesForecast from '../forecast/TimeseriesForecast'

import { NotificationManager } from 'react-notifications'
import { useNav } from '../../providers/NavProvider'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'
import { customReport } from '../../services/base'
import { ErrorLoadingContent } from '../loading/LoadingErrorContent'
import 'bootstrap/dist/css/bootstrap.min.css'
import './predict-form.css'
import './spreadsheet.css'
import TimeSeriesDensityForecast from '../forecast/TimeSeriesDensityForecast'
import { adjustLNSQ, zip } from '../../util/other'
import ShareModelContextMenu from './ShareModelContextMenu'
import { GridTable } from '../grid-table/GridTable'
import { useSearchParams } from 'react-router-dom'

export function WebAppPredict({
  setTitle,
  defaultModel = null,
  hideNav = true,
}) {
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const { t } = useTranslation()
  let { signout, token, user } = useAuth()
  const [model, setModel] = useState(defaultModel)
  const [isPrivatePredict, setIsPrivatePredict] = useState(true)
  const param = useParams()
  const { setShowNav } = useNav()
  useEffect(() => {
    if (hideNav && window.self !== window.top) {
      setShowNav(false)
      return () => setShowNav(true)
    }
    // eslint-disable-next-line
  }, [])

  const { isLoading, data } = useQuery(
    `model-${param.id}`,
    async () => defaultModel ?? (await getModelById(param.id, token, signout)),
  )

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      setTitle(
        searchParams.get('title') || `${t('Predict')} | ${t('NextBrain')}`,
      )
      return
    }
    setTitle(
      searchParams.get('title') ||
        `${t('Predict')} ${model.dataset.name} | ${t('NextBrain')}`,
    )
    setIsPrivatePredict(model.is_private_predict)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading)
    return <LoadingModel shortMsg={t('Loading web app prediction')} />

  if (!model || (isPrivatePredict && !model.user_has_access_to_model))
    return <ModelNotFound />

  return (
    <>
      <Row className={`mb-4 header-app px-0`}>
        <Col md={12} className="d-flex align-items-center px-4">
          {model && model.status === 'trained'
            ? model.dataset.name + ' | '
            : ''}{' '}
          {t('Prediction')}
        </Col>
      </Row>
      <Container>
        {title && (
          <Row className="mt-3">
            <Col xs={12} className="h4">
              {title}
              {model?.user_has_access_to_model && (
                <ShareModelContextMenu model={model} />
              )}
            </Col>
          </Row>
        )}
        <Row>
          {!title && user ? (
            <Col align="right" md={12}>
              <ShareModelContextMenu model={model} />
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <GenericPredictForm model={model} />
        <Row className="my-3"></Row>
        <GenericPredictForm model={model} batchPredict={true} />
      </Container>
    </>
  )
}

export function PredictForm({ model, batchPredict = false }) {
  const { t } = useTranslation()
  let { signout, token, user, updateuserData, unlimitedPredictions } = useAuth()
  const blocked =
    user && !unlimitedPredictions && user.monthly_limits.predictions < 1
  const [UploadCsv, setFileLoadProgress] = useUploadCsv()

  const getDefaultValue = (column) => {
    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return model.dataset.statistics[column].mode
      case 'Double':
      case 'Float':
        return round(model.dataset.statistics[column].mode, 4)
      case 'Categorical':
        return model.dataset.categorical_to_unique[column]?.[0]
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        return new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
      default:
        return 0
    }
  }

  const importances = model?.details?.feature_importance?.reduce(
    (dict, feature) => {
      const [col] = adjustLNSQ(feature.feature, feature.importance)
      dict[col] = feature.importance
      return dict
    },
    {},
  )

  const validColumns = (
    model &&
    model.status === 'trained' &&
    model.dataset &&
    model.dataset.columns_order
      ? model.dataset.columns_order.filter(
          (column) =>
            column in model.columns_active &&
            column in model.dataset.statistics &&
            column !== model.target,
        )
      : []
  )
    .map((k) => ({
      k,
      importance: importances?.[k] ?? 0,
    }))
    .sort((a, b) => b.importance - a.importance)
    .reduce(
      (acc, item) => {
        if (acc.acum < 0.9 || acc.elements.length < 6) {
          acc.acum += item.importance
          acc.elements.push(item.k)
        }
        return acc
      },
      { acum: 0, elements: [] },
    )
    .elements.slice(0, 8)

  const getDefaultFormState = () => {
    let initialFormState = {}
    for (let column of validColumns)
      initialFormState[column] = {
        value: getDefaultValue(column),
        isDefault: true,
      }
    return initialFormState
  }

  const [formState, setFormState] = useState(getDefaultFormState())
  const [isPredicting, setIsPredicting] = useState(false)
  const [isDiffToLastPredict, setIsDiffToLastPredict] = useState(true)
  const [predictResponse, setPredictResponse] = useState(null)
  const [listPredictJsx, setListPredictJsx] = useState([])

  const renderPredictField = (column) => {
    const handleChange = (event) => {
      setFormState((prevState) => {
        if (event.target.value.length === 0)
          prevState[event.target.name] = {
            value: parseFloat(getDefaultValue(column)),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: parseFloat(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    const handleCategoricalChange = (event) => {
      setFormState((prevState) => {
        prevState[column] = {
          value: event.value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    const handleDateChange = (event) => {
      setFormState((prevState) => {
        if (isNaN(new Date(event.target.value)))
          prevState[event.target.name] = {
            value: getDefaultValue(column),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: new Date(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return (
          <Form.Control
            name={column}
            type="number"
            placeholder={defaultFormat({
              num: getDefaultValue(column),
              digits: column < 1000 ? 0 : 2,
            })}
            aria-label="Number"
            onKeyPress={(event) => {
              // Allow minus only at first position
              if (event.key === '-' && event.target.value.length === 0) {
                return
              }
              // Allow only integers
              if (isNaN(event.key)) {
                event.preventDefault()
                event.stopPropagation()
              }
            }}
            className="no-arrows big-form nb-input-soft force"
            onChange={handleChange}
          />
        )
      case 'Double':
      case 'Float':
        return (
          <Form.Control
            name={column}
            type="number"
            placeholder={defaultFormat({
              num: getDefaultValue(column),
              digits: 2,
            })}
            aria-label="Number"
            className="no-arrows big-form nb-input-soft force"
            onChange={handleChange}
          />
        )
      case 'Categorical':
        return (
          <NextbrainSelect
            className="basic-single mt-2"
            classNamePrefix="select"
            options={(model.dataset.categorical_to_unique?.[column] ?? []).map(
              (option) => ({
                value: option,
                label: option,
              }),
            )}
            defaultValue={{
              value: getDefaultValue(column),
              label: getDefaultValue(column),
            }}
            onChange={handleCategoricalChange}
            name={column}
          />
        )
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        now = new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
        return (
          <Form.Control
            name={column}
            type="datetime-local"
            placeholder="Datetime"
            aria-label="Number"
            className="no-arrows nb-input-soft force big-form"
            onChange={handleDateChange}
            defaultValue={now}
          />
        )
      default:
        return <p className="my-2">WIP: Invalid type for now</p>
    }
  }

  const ResponseWrapper = ({ executionTime, children }) => {
    return (
      <>
        <Row className="justify-content-center">
          <Col md={7} xs={12}>
            <hr className="mb-0" />
          </Col>
        </Row>
        <Row className="mt-1 justify-content-center">
          <Col align="right" md={7} xs={12}>
            <span className="text-secondary card-title">
              {t('Execution at')} {executionTime}
            </span>
          </Col>
        </Row>
        <Row>{children}</Row>
      </>
    )
  }

  const handlePredictModelClick = () => {
    if (!isDiffToLastPredict) return
    let data = {
      header: validColumns,
      rows: [validColumns.map((column) => formState[column].value)],
    }
    setPredictResponse(null)
    setIsPredicting(true)
    setIsDiffToLastPredict(false)
    predictModel(model.id, data, token, signout).then((response) => {
      setPredictResponse(response)
      setIsPredicting(false)
      if (!unlimitedPredictions && user)
        updateuserData((session) => {
          session.data.user.monthly_limits.predictions--
          session.data.user = { ...session.data.user }
          return { ...session }
        })
    })
  }

  const handleFileCallback = (file) => {
    let reader = new FileReader()
    reader.addEventListener('progress', (event) => {
      setFileLoadProgress((event.loaded / event.total) * 100)
    })
    reader.addEventListener('load', () => setFileLoadProgress(null), false)
    reader.addEventListener('error', () => setFileLoadProgress(null), false)
    reader.addEventListener('abort', () => setFileLoadProgress(null), false)
    reader.onload = function () {
      const getExecutionTime = () => {
        const now = new Date()
        const hour = now.getHours()
        const minutes = now.getMinutes()
        const seconds = now.getSeconds()
        return `${zeroPad(hour, 2)}:${zeroPad(minutes, 2)}:${zeroPad(
          seconds,
          2,
        )}`
      }
      const executionTime = getExecutionTime()
      let response = stringToCsv(reader.result)

      if (response) {
        const foundColumnsSet = new Set(response.columns_order)
        const notPresentActiveCols = validColumns.filter(
          (column) => !foundColumnsSet.has(column),
        )

        const now = new Date()
        const hour = now.getHours()
        const minutes = now.getMinutes()
        const seconds = now.getSeconds()
        const executionTime = `${zeroPad(hour, 2)}:${zeroPad(
          minutes,
          2,
        )}:${zeroPad(seconds, 2)}`

        // Not valid column found
        if (notPresentActiveCols.length === validColumns.length) {
          setListPredictJsx((oldValues) => {
            const render = (
              <ResponseWrapper
                key={executionTime}
                executionTime={executionTime}
              >
                <Row className="mt-2">
                  <Col md={12} align="center">
                    <h5 className="text-secondary">
                      {t('Invalid dataset provided. Skipped.')}
                    </h5>
                  </Col>
                </Row>
              </ResponseWrapper>
            )
            return [render, ...oldValues]
          })
          return // This is an invalid state, therefore, we don't need to do anything more
        } else {
          response.head[model.target] = Array(
            response.head[response.columns_order[0]].length,
          ).fill(t('Predicting...'))
          if (response.columns_order.indexOf(model.target) === -1)
            response.columns_order.push(model.target)

          setListPredictJsx((oldValues) => {
            const render = (
              <ResponseWrapper
                key={executionTime}
                executionTime={executionTime}
              >
                <Row justify="center" className="mt-3 response-predict-file">
                  <Col md={12}>
                    <ViewDataTable
                      getHead={() => {
                        return new Promise((resolve) => {
                          resolve(response)
                        })
                      }}
                      customOrder={[model.target]}
                      titlePrefix={t('Predicting sample')}
                    />
                  </Col>
                </Row>
              </ResponseWrapper>
            )
            return [render, ...oldValues]
          })
        }
      } else {
        setListPredictJsx((oldValues) => {
          const render = (
            <ResponseWrapper key={executionTime} executionTime={executionTime}>
              <Row className="mt-2">
                <Col md={12} align="center">
                  <h5 className="text-secondary">
                    {t('Preview cannot be displayed, predicting...')}
                  </h5>
                </Col>
              </Row>
            </ResponseWrapper>
          )
          return [render, ...oldValues]
        })
      }

      // Upload as batch predict
      uploadBatchPredict(model.id, file, token, signout).then(
        async (response) => {
          if (!response || response?.status > 400) {
            NotificationManager.error(t('Error prediction'))
            customReport({
              userId: user.id,
              message: 'Failed batch predict',
              report: {
                files: [file],
                modelId: model.id,
              },
              token,
              signout,
            })
            return
          }

          if (response?.status === 400) {
            NotificationManager.error(t('Plan limit reached'))
            return
          }
          const data = await response.json().catch((e) => {
            console.error(e)
            NotificationManager.error(t('Error prediction'))
          })

          if (Array.isArray(data?.head?.Confidence)) {
            if (data.head.Confidence.every((c) => c === 'nan')) {
              delete data.head.Confidence
              data.columns_order =
                data?.columns_order?.filter((c) => c !== 'Confidence') ??
                data.columns_order
            } else
              data.head.Confidence = data.head.Confidence.map((c) =>
                c === 'nan' ? 0 : c,
              )
          }

          if (data?.head)
            setListPredictJsx((oldValues) => {
              const order = data.columns_order
              const idx = order.indexOf(model.target)
              order.splice(idx, 1)
              order.unshift(model.target)
              data.columns_order = order.filter(
                (c) => c !== `Confidence ${model.target}`,
              )
              const newExecutionTime = getExecutionTime()
              const render = (
                <ResponseWrapper
                  key={newExecutionTime}
                  executionTime={newExecutionTime}
                >
                  <Row justify="center" className="mt-3 response-predict-file">
                    <Col md={12}>
                      <GridTable
                        className="w-100 table-view-data mb-3"
                        header={[data.columns_order]}
                        rows={zip(
                          data.columns_order.map((column) => data.head[column]),
                          10,
                        )}
                      />
                    </Col>
                  </Row>
                </ResponseWrapper>
              )
              // When it is finished, remove the temporary table and add the new one
              oldValues.shift()
              return [render, ...oldValues]
            })
          else NotificationManager.error(t('Error generating prediction'))
        },
      )
    }
    reader.readAsBinaryString(file)
  }

  useEffect(() => {
    setIsDiffToLastPredict(true)
  }, [model, formState])

  useEffect(() => {
    if (!predictResponse) return
    setListPredictJsx((oldValues) => {
      const dt = `${Date.now()}`
      const render = (
        <PredictResultPie
          key={dt}
          predictResponse={predictResponse}
          onDelete={() =>
            setListPredictJsx((e) => e.filter((d) => d.key !== dt))
          }
          model={model}
          columns={validColumns
            .map((k) =>
              formState[k].isDefault ? null : [k, formState[k].value],
            )
            .filter((k) => k)}
        />
      )
      if (!render) return oldValues
      return [render, ...oldValues]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictResponse])

  return (
    <>
      {batchPredict ? (
        <Container className="">
          <Row className="mt-2 justify-content-center">
            <Col md={6} sm={8} xs={12}>
              <UploadCsv
                onHandleFile={handleFileCallback}
                limit={blocked}
                concern={false}
                header={
                  <>
                    {t('Drag and drop your predicting data file here')}
                    <br />
                    <FaUpload size={60} />
                  </>
                }
                style={{ height: '15rem' }}
              />
            </Col>
          </Row>
          <Row
            className="mt-2 text-center"
            onClick={() => {
              if (!model) return
              const download = (filename, text) => {
                var pom = document.createElement('a')
                pom.setAttribute(
                  'href',
                  'data:text/csv;charset=utf-8,' + encodeURIComponent(text),
                )
                pom.setAttribute('download', filename)

                if (document.createEvent) {
                  var event = document.createEvent('MouseEvents')
                  event.initEvent('click', true, true)
                  pom.dispatchEvent(event)
                } else {
                  pom.click()
                }
              }
              downloadLookLikePrediction({
                modelId: model.id,
                token,
                signout,
              }).then((prediction) => {
                download(
                  `${t('Prediction structure')} ${model.dataset.name}.csv`,
                  prediction,
                )
              })
            }}
          >
            {' '}
            <span className="looks-like-white-a cursor-pointer">
              <Image
                className="download-csv"
                alt={t('Download csv icon')}
                src="/icons/Download-csv.svg"
                height={25}
                width={25}
              />{' '}
              {t('Download prediction template.')}
            </span>
          </Row>
          <Row className="mx-2 mt-2 justify-content-center">
            {listPredictJsx}
          </Row>
        </Container>
      ) : (
        <Container className="px-0">
          <Row className="px-0">
            <Col className="predict-form-inputs" md={8} xs={12}>
              <Row>
                {validColumns.length ? (
                  validColumns.map((column) => (
                    <Col key={column} md={6} sm={12}>
                      <Row className=" mb-3">
                        <Col md={12} className="mb-2">
                          <span className="predict-input-head">{column}</span>
                        </Col>
                        <Col className="predict-input" md={12}>
                          {renderPredictField(column)}
                        </Col>
                      </Row>
                    </Col>
                  ))
                ) : (
                  <></>
                )}
              </Row>
            </Col>
            <Col className="predict-form-results" md={4} xs={12}>
              <Row justify="center">
                <Col md={12}>
                  <div className="mb-2 col-md-12">
                    <span className="predict-input-head">&nbsp;</span>
                  </div>
                  {blocked ? (
                    <OverlayTrigger
                      rootClose={true}
                      trigger={['hover', 'focus']}
                      placement="bottom"
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {t(
                            'Not enough rows left to predict this model, upgrade your plan to increase the monthly limit',
                          )}
                        </Tooltip>
                      )}
                    >
                      <div>
                        <Button
                          disabled
                          variant={'warning'}
                          className={`w-100 pointer-events-none original`}
                        >
                          {t('Plan limit reached')}
                        </Button>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      variant="primary"
                      className="action-button w-100"
                      style={{ fontWeight: 'bolder' }}
                      onClick={handlePredictModelClick}
                      disabled={isPredicting}
                    >
                      {isPredicting ? (
                        <div className="bouncing-loader my-1">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        t('Predict')
                      )}
                    </Button>
                  )}
                </Col>
              </Row>

              <Row className="ms-2 mt-2">{listPredictJsx}</Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export function ForecastPredict({ model, widget }) {
  let { signout, token } = useAuth()
  const [isError, setIsError] = useState(false)

  const { data: forecast } = useQuery(
    ['forecast-model', model?.id],
    async () => {
      return await getForecast(model.id, token, signout)
    },
    { staleTime: Infinity },
  )

  const { data: baseForecast } = useQuery(
    ['forecast-model-original', model?.id],
    async () => {
      return await originalForecast({
        modelId: model.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const adaptedForecast = useMemo(() => {
    if (forecast) {
      if (forecast?.forecast?.time_series_forecast) {
        return forecast.forecast
      } else {
        //Legacy
        if (!Array.isArray(forecast?.forecast)) {
          NotificationManager.error('Error generating forecast')
          setIsError(true)
          return null
        }
        return forecast.forecast.map((d) => ({
          ...d,
          [model.target]: d.y,
          [`${model.target} prediction`]: d.y,
          ds: new Date(d.ds),
        }))
      }
    }
    return null
    // eslint-disable-next-line
  }, [forecast, baseForecast])
  const item =
    adaptedForecast?.time_series_forecast && baseForecast ? (
      <TimeSeriesDensityForecast
        forecast={adaptedForecast ?? []}
        baseForecast={baseForecast}
        model={model}
      />
    ) : (
      <TimeseriesForecast forecast={adaptedForecast ?? []} model={model} />
    )

  if (widget) return item

  return (
    <Row>
      <Col
        className="position-relative"
        style={{ minHeight: 'max(60vh, 600px)', maxHeight: 'max(60vh, 600px)' }}
        xs={12}
      >
        <ErrorLoadingContent
          isLoading={
            !adaptedForecast ||
            (adaptedForecast?.time_series_forecast && !baseForecast)
          }
          isError={isError}
          errorProps={{ style: { maxHeight: '100px' } }}
        >
          <DownloadGraphOverlay>{item}</DownloadGraphOverlay>
        </ErrorLoadingContent>
      </Col>
    </Row>
  )
}

export default function GenericPredictForm({
  model,
  batchPredict = false,
  widget = false,
}) {
  const validColumns =
    model &&
    model.status === 'trained' &&
    model.dataset &&
    model.dataset.columns_order
      ? model.dataset.columns_order.filter(
          (column) =>
            column in model.columns_active &&
            column in model.dataset.statistics &&
            column !== model.target,
        )
      : []
  return validColumns.length <= 2 &&
    model.problem_type === 'time_series_regression' ? (
    !batchPredict && <ForecastPredict model={model} widget={widget} />
  ) : (
    <PredictForm model={model} batchPredict={batchPredict} />
  )
}
