import React, { useRef, useState, useMemo, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { checkMongo, getMongoDatabases } from '../../../services/csv'
import { awaitTask, awaitTaskCall } from '../../../services/base'
import { useAuth } from '../../../providers/AuthProvider'
import { JSONEditor } from '../../editor/editor'
import { VscJson } from 'react-icons/vsc'
import BouncyButton from '../../bouncy-button/BouncyButton'
import { NotificationManager } from 'react-notifications'
import { GridTable } from '../../grid-table/GridTable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { FaSpinner } from 'react-icons/fa'
import NextbrainSelect from '../NextbrainSelect'
import TreeSelect from '../../tree-select/TreeSelect'
import './MongoDB.css'
import { enforceValidation } from '../../../util/validation'
import InsightHeading from '../InsightHeading'
import { useQuery } from 'react-query'
import { AssistantCheck } from '../AssistantCheck'
import RoleDisable from '../../utils/RoleDisable'

function HeaderSelectFeature({ onSelectFeature, children }) {
  const { t } = useTranslation()
  return onSelectFeature ? (
    <>
      <div className="d-flex justify-content-between px-2 align-items-center ">
        {children}
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
            <Tooltip {...props}>{t('Extract JSON features')}</Tooltip>
          )}
        >
          <Button
            className="py-1 ms-2 original"
            variant={'warning'}
            onClick={onSelectFeature}
          >
            <VscJson size={20} />
          </Button>
        </OverlayTrigger>
      </div>
    </>
  ) : (
    <>{children}</>
  )
}

function Preview({ preview }) {
  return (
    <Row>
      {Object.keys(preview).map((k, i) =>
        preview[k].length ? (
          <Col className="mongo-preview-head" key={k} xs={12}>
            <Row>
              <Col xs={'auto'}>
                <span className="h5">
                  <strong>{k}</strong>
                </span>
              </Col>
            </Row>
            <Row className="ms-2">
              {preview[k].map((v, j) => (
                <React.Fragment key={v}>
                  <Col
                    className={`mongo-preview-entry position-relative ${
                      v.length > 1 ? 'multi' : 'single'
                    }`}
                    xs={'auto'}
                  >
                    {v[v.length - 1]}
                    {v.length > 1 && (
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        placement="auto"
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <div
                              className="p-2"
                              style={{ fontSize: 12, textAlign: 'left' }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <strong>Nested value</strong>
                                </Col>
                                {[k, ...v].map((k, i) => (
                                  <Col
                                    className={`${
                                      i ? 'nested-value-tooltip' : ''
                                    } position-relative`}
                                    xs={12}
                                    key={i}
                                    style={{ marginLeft: `${(i + 1) * 5}px` }}
                                  >
                                    {k}
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </Tooltip>
                        )}
                      >
                        <div className="mongo-preview-entry-nested">
                          <VscJson className="py-0" />
                        </div>
                      </OverlayTrigger>
                    )}
                  </Col>
                  <Col xs={12}></Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>
        ) : (
          <React.Fragment key={k}></React.Fragment>
        ),
      )}
    </Row>
  )
}

export default function MongoDB({
  actionLabel = 'Create new model',
  configuration,
  onFinish = null,
  sampleData = null,
  tableControlWidth = 0,
  onNameChange = () => {},
  onAskAi = false,
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [query, setQuery] = useState(() => {
    try {
      return JSON.stringify(configuration?.query ?? {})
    } catch (e) {
      return '{}'
    }
  })
  const [projection, setProjection] = useState(() => {
    try {
      return JSON.stringify(configuration?.projection ?? {})
    } catch (e) {
      return '{}'
    }
  })
  const [uri, setUri] = useState(configuration?.uri ?? '')
  const [database, setDatabase] = useState(configuration?.database ?? '')
  const [collection, setCollection] = useState(configuration?.collection ?? '')
  const [limit, setLimit] = useState(configuration?.limit ?? '')

  const [isChecking, setIsChecking] = useState(false)
  const [sample, setSample] = useState(sampleData)
  const [schema, setSchema] = useState(sampleData?.schema ?? null)
  const schemaKeys = useMemo(() => {
    if (schema)
      return new Set(
        Object.keys(schema).filter(
          (k) => schema[k].type === 'object' || schema[k].type === 'array',
        ),
      )
    return new Set()
  }, [schema])

  const [selectedFeature, setSelectedFeature] = useState(null)

  const features = useMemo(() => {
    if (!selectedFeature || !sample) return {}

    const parseProperties = (p) => {
      return Object.keys(p).reduce((ac, k) => {
        if (p[k].type === 'object' || p[k].type === 'array')
          ac[k] = parseProperties(p[k].properties)
        else ac[k] = p[k].type
        return ac
      }, {})
    }
    const properties = schema[selectedFeature]?.properties
    try {
      if (properties) return parseProperties(properties)
    } catch (e) {
      NotificationManager.error(t('Error parseing column features'))
    }
    return {}
    // eslint-disable-next-line
  }, [selectedFeature, sample])

  const featureStates = useRef(configuration?.features?.states ?? {})
  const featureSelections = useRef(configuration?.features?.selections ?? {})
  const featureView = useRef(configuration?.features?.views ?? {})
  const [preview, setPreview] = useState(() => {
    const sel = featureSelections?.current ?? {}

    return Object.keys(sel).reduce((ac, k) => {
      ac[k] = ac[k] ?? []
      Object.entries(sel[k]).forEach(([key, { path, status }]) => {
        if (status) ac[k].push(path)
      })
      return ac
    }, {})
  })

  const [tabIndex, setTabIndex] = useState(0)

  const linkRef = useRef()
  const databaseRef = useRef()
  const collectionRef = useRef()
  const limitRef = useRef()

  const [focusDBInput, setFocusDBInput] = useState(false)

  const { data: databaseInfo, isLoading } = useQuery(
    [uri, focusDBInput],
    async () => {
      if (!uri || focusDBInput) return null
      try {
        const db = await awaitTaskCall(getMongoDatabases, 500, 120000, {
          uri,
          token,
          signout,
        })
        Object.keys(db.databases).forEach((k) => {
          db.databases[k] = db.databases[k].map((d) => ({ label: d, value: d }))
        })
        return db
      } catch (e) {
        NotificationManager.error(t(e?.error ?? 'Failed to connect to MongoDB'))
        return { databases: {} }
      }
    },
    { staleTime: Infinity },
  )

  const [databaseOptions, setDatabaseOptions] = useState([])

  useEffect(() => {
    if (databaseInfo?.databases && typeof databaseInfo.databases === 'object') {
      setDatabaseOptions(
        Object.keys(databaseInfo.databases).map((k) => ({
          label: k,
          value: k,
        })),
      )
    }
  }, [databaseInfo])

  if (!onFinish)
    onFinish = () =>
      NotificationManager.error('Failed to create model from MongoDB')

  return (
    <Row className="mx-1">
      <Col className="ms-0 ps-0" xs={12}>
        <p>{t('Create new model from a MongoDB instance')}</p>
      </Col>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
        style={{ maxWidth: '100%' }}
      >
        <Col className="alt-tabsdiv mongo-tabs ps-0 gs-0" xs={12}>
          <TabList className="ps-0 w-100">
            <Tab>
              <span>{t('Connect')}</span>
            </Tab>
            <Tab disabled={!sample}>
              <span>{t('Visualize')}</span>
            </Tab>
            <Tab disabled={!sample || !schemaKeys.size}>
              <span>{t('Select features')}</span>
            </Tab>
          </TabList>
        </Col>
        <Col className={`mongo-db-ui`} style={{ marginTop: '-20px' }} xs={12}>
          <TabPanel>
            <Form>
              <Row className="p-2 w-100">
                <Col xs={12}>
                  <Form.Group className="mb-3" controlId="formMongoURI">
                    <Form.Label>{t('Database URL')}</Form.Label>
                    <Form.Control
                      ref={linkRef}
                      value={uri}
                      onChange={(e) => {
                        setUri(e.target.value)
                      }}
                      className="nb-input-soft"
                      type="text"
                      placeholder={t(
                        'mongodb://username:password@host:port/?authMechanism=DEFAULT',
                      )}
                      onBlur={() => setFocusDBInput(false)}
                      onFocus={() => setFocusDBInput(true)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={5}>
                  <Form.Group
                    className="mb-3"
                    style={{ maxHeight: '300px' }}
                    controlId="formMongoDatabase"
                  >
                    <Form.Label>
                      {t('Database')}
                      {isLoading && (
                        <FaSpinner
                          size={12}
                          className={`spinner-mongodb ms-1`}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      ref={databaseRef}
                      className="nb-input-soft d-none"
                      value={database}
                      onChange={(e) => {
                        setDatabase(e.target.value)
                      }}
                      type="text"
                      placeholder={t('Database')}
                    />
                    <NextbrainSelect
                      value={
                        database ? { label: database, value: database } : null
                      }
                      onChange={(v) => {
                        databaseRef.current.value = v.value
                        setDatabase(v.value)
                        collectionRef.current.value = ''
                        setCollection('')
                      }}
                      options={databaseOptions}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      className={`basic-single `}
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={true}
                      creatable={true}
                      isMulti={false}
                    />
                  </Form.Group>
                </Col>
                <Col xs={5}>
                  <Form.Group className="mb-3" controlId="formMongoCollection">
                    <Form.Label className="d-flex align-items-center">
                      {t('Collection')}
                    </Form.Label>
                    <Form.Control
                      ref={collectionRef}
                      className="nb-input-soft d-none"
                      value={collection}
                      onChange={(e) => {
                        setCollection(e.target.value)
                      }}
                      placeholder={t('Collection')}
                    />
                    <NextbrainSelect
                      value={
                        collection
                          ? { label: collection, value: collection }
                          : null
                      }
                      onChange={(v) => {
                        collectionRef.current.value = v.value
                        setCollection(v.value)
                      }}
                      options={databaseInfo?.databases?.[database] ?? []}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      className={`basic-single `}
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={true}
                      creatable={true}
                      isMulti={false}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Form.Group
                    className="mb-3 enforced-validation-container"
                    controlId="formMongoLimit"
                  >
                    <Form.Label className="d-flex align-items-center">
                      {t('Limit')}
                    </Form.Label>
                    <Form.Control
                      ref={limitRef}
                      className="nb-input-soft py-2"
                      onKeyPress={enforceValidation({
                        numericInteger: true,
                        clamp: { min: 0 },
                      })}
                      value={limit}
                      onChange={(e) => {
                        setLimit(e.target.value)
                      }}
                      placeholder={t('Limit')}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col className="mt-3 mb-1" xs={12}>
                      {t('Query')}
                    </Col>
                    <Col xs={12}>
                      <JSONEditor
                        height={'150px'}
                        value={query}
                        setValue={setQuery}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col className="mt-3 mb-1" xs={12}>
                      {t('Projection')}
                    </Col>
                    <Col xs={12}>
                      <JSONEditor
                        height={'150px'}
                        value={projection}
                        setValue={setProjection}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="mt-2 d-flex justify-content-start" xs={12}>
                  <RoleDisable className="w-100">
                    <BouncyButton
                      loading={isChecking}
                      alternative={true}
                      onClick={() => {
                        let jquery = {}
                        let jprojection = {}
                        try {
                          if (query) jquery = JSON.parse(query)
                        } catch (e) {
                          NotificationManager.error(
                            'Invalid query, not a valid mongo query',
                          )
                          return
                        }
                        try {
                          if (projection) jprojection = JSON.parse(projection)
                        } catch (e) {
                          NotificationManager.error(
                            'Invalid projection, not a valid mongo projection',
                          )
                          return
                        }
                        const dbData = {
                          uri: linkRef.current.value,
                          database: databaseRef.current.value,
                          collection: collectionRef.current.value,
                          query: jquery,
                          projection: jprojection,
                        }
                        setSample(null)
                        setSchema(null)
                        setSelectedFeature(null)
                        setIsChecking(true)
                        checkMongo(dbData, token, signout)
                          .then(async (response) => {
                            if (response.task_id) {
                              awaitTask({ taskUuid: response.task_id })
                                .then((r) => {
                                  const shouldReset = !!sample
                                  setSample({
                                    schema: r.schema,
                                    columns: r.table.columns,
                                    sample: r.table.data.map((d) =>
                                      d.map((dd) => dd?.toString() ?? ''),
                                    ),
                                  })
                                  setSchema(r.schema)
                                  setIsChecking(false)
                                  setTabIndex(1)
                                  if (shouldReset) {
                                    featureStates.current = {}
                                    featureSelections.current = {}
                                    featureView.current = {}
                                    setPreview({})
                                  }
                                })
                                .catch((e) => {
                                  NotificationManager.error(
                                    e?.error ??
                                      'Failed to retrieve data from mongo',
                                  )
                                  setIsChecking(false)
                                })
                            } else {
                              NotificationManager.error('Failed to connect')
                              setIsChecking(false)
                            }
                          })
                          .catch((e) => setIsChecking(false))
                      }}
                      className="config-button w-auto"
                      style={{
                        backgroundColor: isChecking ? '#DCDCDC' : '#4240B5',
                      }}
                    >
                      {t('Test Connection')}
                    </BouncyButton>
                  </RoleDisable>
                </Col>
              </Row>
            </Form>
          </TabPanel>
          <TabPanel>
            <div className="p-1">
              {sample && (
                <GridTable
                  rows={sample.sample}
                  style={{ maxHeight: '300px' }}
                  rowsPerPage={30}
                  headerElement={(h) => (
                    <HeaderSelectFeature
                      onSelectFeature={
                        schemaKeys.has(h)
                          ? () => {
                              setSelectedFeature(h)
                              setTabIndex(2)
                            }
                          : null
                      }
                    >
                      {h}
                    </HeaderSelectFeature>
                  )}
                  cellElement={(e) => (
                    <span className="d-inline-block text-truncate">{e}</span>
                  )}
                  header={[sample.columns]}
                  index={(i) => (
                    <div
                      className={`grid-table-cell index-cell ${
                        i ? '' : 'first'
                      }`}
                    >
                      {i ? i : ''}
                    </div>
                  )}
                  className="table-view-data w-100"
                  defaultColumnWidth={(str) => Math.max(200, str.length * 12)}
                  pagerLast={true}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <Row className="w-100 mt-2">
              <Col
                className="pb-2"
                style={{
                  maxHeight: '490px',
                  minHeight: '490px',
                  overflow: 'auto',
                }}
                xs={8}
              >
                <Row
                  className="mongo-tree-select ms-2 p-2 pt-0"
                  style={{ maxWidth: 'calc(100% - 10px)' }}
                >
                  <Col className="h3 mt-2 d-flex align-items-center" xs={12}>
                    {t('Select a column')}
                    <Button
                      className="py-1 ms-2 original original-disabled"
                      variant={'warning'}
                      disabled
                    >
                      <VscJson className="" size={30} />
                    </Button>
                  </Col>
                  <Col className="mongo-tree-element" xs={12}>
                    <div style={{ maxWidth: '300px' }}>
                      <NextbrainSelect
                        type={'dark'}
                        className="basic-single mt-2"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={false}
                        value={
                          selectedFeature
                            ? { label: selectedFeature, value: selectedFeature }
                            : null
                        }
                        onChange={(e) => {
                          setSelectedFeature(e.value)
                        }}
                        options={[...schemaKeys].map((k) => ({
                          label: k,
                          value: k,
                        }))}
                      />
                    </div>
                  </Col>
                  <Col
                    className="mongo-tree-element"
                    style={{ minHeight: '350px' }}
                    xs={12}
                  >
                    {selectedFeature && (
                      <div className="w-100">
                        <TreeSelect
                          state={featureStates.current?.[selectedFeature] ?? {}}
                          expand={featureView.current?.[selectedFeature] ?? {}}
                          onChange={(state, change) => {
                            featureStates.current[selectedFeature] = state
                            featureSelections.current[selectedFeature] =
                              featureSelections.current[selectedFeature] ?? {}
                            featureSelections.current[selectedFeature][
                              change.path.join('---')
                            ] = change
                            setPreview(
                              Object.keys(featureSelections.current).reduce(
                                (ac, k) => {
                                  ac[k] = ac[k] ?? []
                                  Object.entries(
                                    featureSelections.current[k],
                                  ).forEach(([key, { path, status }]) => {
                                    if (status) ac[k].push(path)
                                  })
                                  return ac
                                },
                                {},
                              ),
                            )
                          }}
                          onExpand={(path) => {
                            featureView.current[selectedFeature] =
                              featureView.current[selectedFeature] ?? {}
                            featureView.current[selectedFeature] = {
                              ...featureView.current[selectedFeature],
                              ...path,
                            }
                          }}
                          frame={features}
                          key={selectedFeature}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <InsightHeading
                        title={'New columns to create'}
                        className="mb-2"
                      />
                    </Row>
                  </Col>
                  <Col style={{ maxHeight: '470px', overflow: 'auto' }} xs={12}>
                    <Preview preview={preview} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPanel>
        </Col>
      </Tabs>
      <Col
        xs={12}
        className={`mt-2 d-flex justify-content-end ${onAskAi ? 'mb-3' : ''}`}
      >
        {onAskAi && (
          <AssistantCheck
            className="me-3"
            onChange={onAskAi}
            enable={() => {
              const cols = sample?.columns?.length
              if (cols && cols > 500) return false
              return true
            }}
          />
        )}
        <RoleDisable className="w-100">
          <Button
            onClick={() => {
              let jquery = {}
              let jprojection = {}
              try {
                if (query) jquery = JSON.parse(query)
              } catch (e) {
                NotificationManager.error(
                  'Invalid query, not a valid mongo query',
                )
                return
              }
              try {
                if (projection) jprojection = JSON.parse(projection)
              } catch (e) {
                NotificationManager.error(
                  'Invalid projection, not a valid mongo projection',
                )
                return
              }
              const dbData = {
                uri,
                database,
                collection,
                query: jquery,
                projection: jprojection,
                limit: limit,
                features: {
                  states: featureStates.current,
                  selections: featureSelections.current,
                  views: featureView.current,
                },
              }
              onFinish(sample, dbData)
            }}
            className="config-button w-auto"
            disabled={!sample}
          >
            <span className="d-inline-block">{t(actionLabel)}</span>
          </Button>
        </RoleDisable>
      </Col>
    </Row>
  )
}
