import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { MdOutlineMenuBook } from 'react-icons/md'

export default function BreadcrumbNavKnowledgeBase({
  repositoryName,
  repositoryId,
  chat = false,
  ...props
}) {
  const { t } = useTranslation()
  const path = [['Repositories', '/knowledge-base']]
  if (repositoryName && repositoryId) {
    path.push([repositoryName, `/knowledge-base/${repositoryId}`])
    if (chat) path.push([t('Chat'), `/knowledge-base/chat/${repositoryId}`])
  }

  let lengths = path.map(([k]) => k.length)
  const totalChars =
    (window.innerWidth - 430) /
    Math.max(1, 30 * path.reduce((a, [n]) => a + n.length, 0))

  lengths = lengths.map((l) => (100 * l) / totalChars)

  return (
    <Row className={`breadcrumbs-nav mx-0`}>
      <Col
        className={`position-relative px-0 pe-none disabled-drop-nav d-flex align-items-center justify-content-center`}
        xs={12}
        style={{ maxWidth: '280px', minWidth: '280px' }}
      >
        <strong>
          <span>
            <MdOutlineMenuBook color="white" size={35} />
          </span>
          <div className="d-inline-flex align-items-end h-100 mt-1 ms-1">
            {t('Knowledge Base')}
          </div>
        </strong>
      </Col>
      <Col
        xs={12}
        style={{
          maxWidth: 'calc(100% - 480px)',
          minWidth: 'calc(100% - 480px)',
        }}
      >
        <Row className="h-100 ms-0 ms-md-4 d-inline-flex flex-nowrap">
          {path.map(([k, link], i, all) => (
            <Col
              key={i}
              className="d-inline-flex px-0 mb-1"
              xs={'auto'}
              style={{
                fontSize: '18px',
                maxWidth: `${lengths[i]}%`,
              }}
            >
              <span className="d-flex align-items-center">
                <Link to={link}>
                  <i>
                    {' '}
                    <span className="color-white">{k}</span>
                  </i>
                </Link>
                {all.length - 1 !== i && <span className="px-2">/</span>}
              </span>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}
