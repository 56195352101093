import { request, upload } from './base'

export async function getAllCSV(token, signout) {
  return request({
    endpoint: '/csv/csvs',
    token,
    signout,
  })
}

export async function getCsvHead(
  csvId,
  headSize = 2000,
  token,
  signout,
  sizeInRows = false,
) {
  try {
    while (true) {
      const jsonResponse = request({
        endpoint: `/csv/get_head/${csvId}?head_size=${headSize}&size_in_rows=${sizeInRows}`,
        token,
        signout,
      })

      if (!('retry' in jsonResponse) && !jsonResponse.detail) {
        return jsonResponse
      }
      if (jsonResponse.detail) return jsonResponse.detail
      if (!jsonResponse?.retry) return []
    }
  } catch (error) {
    return []
  }
}

export function getCsvSeriesHead(csvId, series = [], token, signout) {
  return request({
    endpoint: `/csv/get_head_series/${csvId}`,
    body: series,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function uploadCSV(
  userId,
  file,
  token,
  signout,
  createModel = true,
  onProgress = null,
  uploadProps = {},
  taskId = null,
  modelName = null,
) {
  if (onProgress && !taskId) {
    return upload({
      token,
      endpoint: `/csv/upload/${userId}?create_model=${createModel}${
        modelName ? `&model_name=${modelName}` : ''
      }`,
      headers: {
        Accept: 'application/json',
      },
      filename: 'csv_file',
      file,
      onProgress,
      ...uploadProps,
    })
  }

  let formdata = new FormData()
  if (!taskId) formdata.append('csv_file', file)

  return request({
    endpoint: `/csv/upload/${userId}?create_model=${createModel}${
      taskId ? `&task_id=${taskId}` : ''
    }${modelName ? `&model_name=${modelName}` : ''}`,
    token,
    signout,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    raw_body: true,
    body: taskId ? null : formdata,
    redirect: 'follow',
  })
}

export async function deleteCsv(csvId, token, signout) {
  return request({
    endpoint: `/csv/delete_csv/${csvId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function checkDb(dbData, token, signout) {
  return request({
    endpoint: `/csv/check-db`,
    method: 'POST',
    contentType: 'json',
    body: dbData,
    token,
    signout,
  })
}

export async function checkMongo(dbData, token, signout) {
  return request({
    endpoint: `/csv/check-mongo`,
    method: 'POST',
    contentType: 'json',
    body: dbData,
    token,
    signout,
  })
}

export async function uploadDb(completeDbData, token, signout) {
  return request({
    endpoint: `/csv/upload_db`,
    method: 'POST',
    contentType: 'json',
    body: completeDbData,
    token,
    signout,
  })
}

export async function uploadApi(apiData, token, signout) {
  return request({
    endpoint: `/csv/upload_api`,
    method: 'POST',
    contentType: 'json',
    body: apiData,
    token,
    signout,
  })
}

export async function import_gsheet(
  spreadsheet_id,
  workbook_name,
  google_access_token,
  token,
  signout,
) {
  return request({
    endpoint: `/csv/import_gsheet?spreadsheet_id=${spreadsheet_id}&workbook_name=${workbook_name}&google_token=${encodeURIComponent(
      google_access_token,
    )}`,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
    rawResponse: true,
  })
}

export async function checkGsheet({
  spreadsheet_id,
  workbook_name,
  google_token,
  token,
  signout,
}) {
  return request({
    endpoint: `/csv/check-gsheet`,
    body: {
      spreadsheet_id,
      workbook_name,
      google_token,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkApi({
  link,
  data_type,
  headers,
  username_auth,
  password_auth,
  token,
  signout,
}) {
  return request({
    endpoint: `/csv/check-api`,
    body: {
      link,
      data_type,
      headers,
      username_auth,
      password_auth,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function importFlow({
  outputIndex,
  objects,
  name,
  projectId = null,
  modelId = null,
  check = false,
  token,
  signout,
  ...props
}) {
  const params = {}
  if (projectId && modelId) {
    params.project_id = projectId
    params.model_id = modelId
  }
  if (props?.sample) {
    params.sample = props.sample
  }
  let search = ''
  if (Object.keys(params).length)
    search = '?' + new URLSearchParams(params).toString()

  return request({
    endpoint: `/csv/${check ? 'check' : 'import'}-flow${search}`,
    method: 'POST',
    contentType: 'json',
    body: {
      output_index: outputIndex,
      objects,
      flow_name: name,
    },
    token,
    signout,
  })
}

export async function checkMerge({
  left,
  right,
  join_type,
  left_columns,
  right_columns,
  visible_columns,
  avoid_duplicated_left_indexes = true,
  avoid_duplicated_right_indexes = true,
  constant_columns = null,
  token,
  signout,
}) {
  return request({
    endpoint: `/csv/check-merge`,
    body: {
      left,
      right,
      join_type,
      left_columns,
      right_columns,
      visible_columns,
      avoid_duplicated_left_indexes,
      avoid_duplicated_right_indexes,
      constant_columns,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkFilter({ input, filter, token, signout }) {
  return request({
    endpoint: `/csv/check-filter`,
    body: {
      input,
      filter,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkEnrich({ config, token, signout }) {
  return request({
    endpoint: `/csv/check-enrichment`,
    body: config,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkAITransform({ config, token, signout }) {
  return request({
    endpoint: `/csv/check-ai-transform`,
    body: config,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkTransform({ input, transform, token, signout }) {
  return request({
    endpoint: `/csv/check-transform`,
    body: {
      input,
      transform,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkAggregate({
  input,
  aggregateBy,
  aggregations,
  defaultBehavior,
  token,
  signout,
}) {
  return request({
    endpoint: `/csv/check-aggregate`,
    body: {
      input,
      groupby_columns: aggregateBy,
      column_to_function: aggregations,
      general_function: defaultBehavior,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkChangeType({ input, typeMapping, token, signout }) {
  return request({
    endpoint: `/csv/check-change-type`,
    body: {
      input,
      type_mapping: typeMapping,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function getMongoDatabases({ uri, token, signout }) {
  return request({
    endpoint: `/csv/get-mongo-databases`,
    body: { uri },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function getRelationalDatabases({
  link,
  username,
  password,
  database_type,
  token,
  signout,
}) {
  return request({
    endpoint: `/csv/get-relational-databases`,
    body: {
      link,
      username,
      password,
      database_type,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function getCsvStatus({ csvId, token, signout }) {
  return request({
    endpoint: `/csv/status/${csvId}`,
    token,
    signout,
  })
}

export async function getEnrichmentOption({ option, token, signout }) {
  return request({
    endpoint: `/csv/enrichment_options/${option}`,
    token,
    signout,
  })
}

export async function checkFile({ csv_file, head = null, token, signout }) {
  let formdata = new FormData()
  formdata.append('csv_file', csv_file)
  return request({
    endpoint: `/csv/check-file${
      head !== null ? '?head=' + encodeURIComponent(head) : ''
    }`,
    method: 'POST',
    body: formdata,
    headers: {
      Accept: 'application/json',
    },
    raw_body: true,
    token,
    signout,
  })
}

export async function mergeSuggestion({ df_1, df_2, token, signout }) {
  return request({
    endpoint: `/csv/merge-suggestion`,
    method: 'POST',
    body: {
      df_1,
      df_2,
    },
    headers: {
      Accept: 'application/json',
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function checkPublicCSV({ url, head = 30, token, signout }) {
  return request({
    endpoint: `/csv/check-public-csv`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: {
      url,
      head,
    },
    contentType: 'json',
    token,
    signout,
  })
}
