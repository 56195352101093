export default function Ppt({
  width = 50,
  height = 50,
  className = '',
  ...props
}) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 303.188 303.188"
      className={className}
      height={height}
      width={width}
      {...props}
    >
      <g>
        <polygon
          style={{ fill: '#E8E8E8' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <g>
          <rect
            x="90.902"
            y="61.704"
            style={{ fill: '#FF671B' }}
            width="119.89"
            height="119.89"
          />
          <rect
            x="101.303"
            y="72.105"
            style={{ fill: '#FFFFFF' }}
            width="99.089"
            height="99.087"
          />
          <g>
            <rect
              x="115.616"
              y="92.182"
              style={{ fill: '#FF671B' }}
              width="70.463"
              height="58.933"
            />
            <rect
              x="120.918"
              y="98.303"
              style={{ fill: '#FFFFFF' }}
              width="59.861"
              height="7.613"
            />
            <path
              style={{ fill: '#FFFFFF' }}
              d="M135.872,112.472c-8.259,0-14.955,6.696-14.955,14.954c0,8.259,6.695,14.955,14.955,14.955
				c8.258,0,14.954-6.696,14.954-14.955h-14.954V112.472z"
            />
            <rect
              x="157.902"
              y="113.564"
              style={{ fill: '#FFFFFF' }}
              width="22.876"
              height="4.434"
            />
            <rect
              x="157.902"
              y="122.993"
              style={{ fill: '#FFFFFF' }}
              width="22.876"
              height="4.433"
            />
            <rect
              x="157.902"
              y="132.422"
              style={{ fill: '#FFFFFF' }}
              width="22.876"
              height="4.432"
            />
          </g>
        </g>
        <polygon
          style={{ fill: '#FF671B' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M128.532,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917H93.712v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C127.011,232.51,128.532,236.273,128.532,241.152z
			 M106.675,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.148-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
			h-3.348C106.675,236.36,106.675,247.387,106.675,247.387z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M170.998,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917h-12.963v-47.979h16.606c6.06,0,10.61,1.324,13.652,3.971C169.477,232.51,170.998,236.273,170.998,241.152z
			 M149.142,247.387h2.362c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.724-2.604,1.724-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.347V247.387z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M199.812,273.871h-12.963v-37.379h-11.716v-10.6h36.361v10.6h-11.683v37.379H199.812z"
          />
        </g>
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  )
}
