import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { round } from '../utils/formating'

export default function AccuracyGauge({
  testAcc,
  trainAcc,
  height = 150,
  units = 'px',
  tooltip = 'Testing accuracy',
  marginLeft = 50,
  colorTrain = '#0E4429',
  colorTest = '#59a354',
  colorError = 'var(--nextbrain-pie-background)',
  ...props
}) {
  const { t } = useTranslation()
  const data = [
    trainAcc !== null && trainAcc !== undefined
      ? {
          id: t('Training dataset'),
          data: [
            {
              x: t('Training Accuracy'),
              y: trainAcc,
            },
            {
              x: t('Error'),
              y: 100 - trainAcc,
            },
          ],
          color: colorTrain,
        }
      : null,
    testAcc !== null && testAcc !== undefined
      ? {
          id: t(tooltip),
          data: [
            {
              x: t(tooltip),
              y: testAcc,
            },
            {
              x: t('Error'),
              y: 100 - testAcc,
            },
          ],
          color: colorTest,
        }
      : null,
  ].filter((n) => n)
  return (
    <>
      <Row style={{ height: `${height}${units}` }}>
        <ResponsiveRadialBar
          data={data}
          maxValue={100}
          startAngle={-90}
          endAngle={90}
          innerRadius={0.25}
          padding={0.1}
          margin={{ top: 30, right: 0, bottom: -150, left: marginLeft }}
          colors={(datum) => {
            if (datum.category === 'Error') return colorError
            if (datum.groupId === t('Training')) return colorTrain
            return datum.category === t('Training Accuracy')
              ? colorTrain
              : colorTest
          }}
          enableLabels={true}
          enableTracks={false}
          enableRadialGrid={false}
          enableCircularGrid={false}
          radialAxisStart={null}
          circularAxisOuter={null}
          transitionMode="startAngle"
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: -marginLeft + 10,
              translateY: -30,
              itemsSpacing: 6,
              itemDirection: 'left-to-right',
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              symbolSize: 18,
              symbolShape: 'square',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#fff',
                  },
                },
              ],
            },
          ]}
          valueFormat={(d) => `${round(d)}%`}
          labelsTextColor={'#dddddd'}
          {...props}
        />
      </Row>
    </>
  )
}
