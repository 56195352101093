import { request } from './base'

export async function createSubscription({ items, token, signout }) {
  return request({
    endpoint: '/payment/create-subscription',
    method: 'POST',
    contentType: 'json',
    body: {
      items,
    },
    token,
    signout,
  })
}

export async function getCustomerBillingInfo({ token, signout }) {
  return request({
    endpoint: '/payment/customer-billing-info',
    method: 'GET',
    token,
    signout,
  })
}

export async function updateCustomerBillingInfo({
  token,
  signout,
  billingInfo,
}) {
  return request({
    endpoint: '/payment/customer-billing-info',
    method: 'POST',
    contentType: 'json',
    token,
    signout,
    body: billingInfo,
  })
}

export async function getTaxIdTypesByCountry() {
  return request({
    endpoint: `/payment/tax-id-types-by-country`,
    method: 'GET',
  })
}

export async function getNextBillingInfo({ token, signout, subscriptionId }) {
  return request({
    endpoint: `/payment/next-billing-info/${subscriptionId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function changePlan({
  token,
  signout,
  subscriptionId,
  newProductId,
  priceId,
  cancel = false,
  reactivate = false,
}) {
  const queryParams = {
    cancel: 'false',
    reactivate: 'false',
  }
  if (newProductId) {
    queryParams.new_product_id = newProductId
  }
  if (priceId) {
    queryParams.price_id = priceId
  }
  if (cancel) {
    queryParams.cancel = 'true'
  }
  if (reactivate) {
    queryParams.reactivate = 'true'
  }
  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&')
  return request({
    endpoint: `/payment/change-plan/${subscriptionId}?${queryString}`,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}
