import { getCountriesForTimezone } from 'countries-and-timezones'

export function getCountry() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return getCountriesForTimezone(timezone)?.[0]?.name ?? ''
}
export function formatDate(date) {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`
}

export function zip(arrays, limit) {
  if (!arrays.length) return []
  return (limit ? arrays[0].slice(0, limit) : arrays[0]).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i]
    })
  })
}

export function isValidDate(item) {
  return (
    Object.prototype.toString.call(item) === '[object Date]' && !isNaN(item)
  )
}

export function splitByCodeSections(string, newLines = false) {
  const sections = []
  if (!string || typeof string !== 'string') return sections
  const parts = string.split('```')

  for (let i = 0; i < parts.length; i++) {
    const text = parts[i].trim()

    if (i % 2 === 0) {
      // Text section
      if (text !== '') {
        if (newLines) {
          text
            .split('\n')
            .filter((e) => e)
            .forEach((e) => {
              sections.push({ type: 'text', text: e })
            })
        } else sections.push({ type: 'text', text })
      }
    } else {
      // Code section
      const firstNewLine = text.indexOf('\n')
      if (firstNewLine !== -1) {
        const language = text.substring(0, firstNewLine)
        const code = text.substring(firstNewLine + 1)
        sections.push({
          type: 'code',
          text: `#${language}\n` + code,
          language,
        })
      } else sections.push({ type: 'code', text: text, language: text })
    }
  }

  return sections
}

export function adjustLNSQ(column, value) {
  if (column.startsWith('ln_')) {
    column = column.substring(3)
    value = Math.E ** value
  }
  if (column.startsWith('sq_')) {
    column = column.substring(3)
    value = value ** 2
  }
  return [column, value]
}

export function safeExecute(fn, errorValue, ...props) {
  try {
    return fn(...props)
  } catch (err) {
    return errorValue
  }
}
