import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import './QuestionnaireForm.css'
import { userDataProto } from './QuestionnaireParts'
import { industryDataProto } from './QuestionnaireIndustry'

//TODO Create request file to call the API (
//TODO Gather data and send it
const stages = ['1', '2', '3', '4', '5']
const LAST_STAGE = stages.length - 1
const QUESTIONNAIRE2_STAGE = 1
export default function QuestionnaireForm(props) {
  const { t } = useTranslation()
  const [userData, setUserData] = useState(userDataProto)

  const [industryData] = useState(industryDataProto)

  const [locationData] = useState([
    {
      id: null,
      name: 'Floor',
      type: 'NUMBER',
      deletable: false,
      disabled: true,
      options: [],
    },
  ])

  const [maxVisited, setMaxVisited] = useState(0)
  const [stage, setStage] = useState(0)
  const [isStageValid, setisStageValid] = useState([false, false, true])
  const [alert, setAlert] = useState(null)

  const navigate = useNavigate()

  const alertMessages = [
    t('Invalid inputs or empty fields'),
    t('Invalid inputs or empty fields'),
    t('Repeated or empty fields or options'),
    t('Invalid or empty inputs'),
  ]

  useEffect(() => {
    if (alert && isStageValid[stage]) setAlert(null)
    // eslint-disable-next-line
  }, [isStageValid])

  const storeData = (newLocationData = locationData) => {
    const inputsValidity = []
    switch (stage) {
      case 0:
        Object.keys(userData).forEach((key) => {
          const el = document.getElementById(key)
          if (el) {
            inputsValidity.push(el.checkValidity())
            userData[key] = document.getElementById(key).value
          } else console.log(`Non existant attribute id ${key} for user`)
        })
        setUserData({ ...userData })
        break
      case 1:
        Object.keys(industryData).forEach((key) => {
          const el = document.getElementById(key)
          if (el) {
            inputsValidity.push(el.checkValidity())
            industryData[key] = document.getElementById(key).value
          } else console.log(`Non existant attribute id ${key} for industry`)
        })
        //setIndustryData({ ...industryData });
        break
      case 2:
        break
      default:
        break
    }
    isStageValid[stage] = inputsValidity.reduce((a, b) => a && b, true)
    setisStageValid([...isStageValid])
  }

  const advance = (target = stage + 1) => {
    storeData()
    if (target > maxVisited) setMaxVisited(target)
    setStage(target)
    setAlert(null)
  }

  const stageStyle = (index) => {
    if (index === stage) return 'stageCurrent'
    else if (index <= maxVisited) {
      if (isStageValid[index]) return 'stageOk'
      else return 'stageFail'
    }
    return ''
  }

  const advButton = () => {
    return stage !== LAST_STAGE ? (
      <Row>
        <Col md={12} className="d-flex justify-content-center">
          <Button
            className="btnregister p-2"
            style={{
              height: 48,
            }}
            onClick={() => {
              if (isStageValid[stage]) advance()
              else setAlert(alertMessages[stage])
            }}
            variant={'primary'}
          >
            {t('Continue')}
          </Button>
        </Col>

        <Col align="center" md={12} className="mt-2">
          <a className="mt-3 mb-5" href="/">
            {t('Go back')}
          </a>
        </Col>
      </Row>
    ) : (
      <Button
        type="submit"
        className="mb-5 px-5 py-4 float-md-end"
        variant="success"
      >
        {t('Finish')}
      </Button>
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Navbar expand="lg">
              <Container fluid>
                <h4>{t('Questionnaire')}</h4>
                <Nav variant="pills">
                  <Nav.Link
                    className="mx-md-2"
                    onClick={() => {
                      navigate(`/login`)
                    }}
                  >
                    <Image src="/LogoBlue.svg" width={85}></Image>
                  </Nav.Link>
                </Nav>
              </Container>
            </Navbar>
            <Container>
              <Row md={12} className="mb-3" id="differentsteps">
                {stages.map((stage, index) => {
                  const style = {}
                  if (index === 0) {
                    style.marginLeft = 55
                  }
                  return (
                    <Col xs={2} className="p-0" style={style}>
                      <Col
                        key={index}
                        className={`stageSelect ${stageStyle(index)}`}
                        onClick={() => {
                          if (index <= maxVisited) advance(index)
                        }}
                      >
                        {stageStyle(index) === 'stageOk' ? (
                          <img alt="check" width={20} src={'./check.png'} />
                        ) : (
                          stage
                        )}
                      </Col>
                      <Col
                        className={`stageSelect2 ${stageStyle(index)}`}
                      ></Col>
                    </Col>
                  )
                })}
              </Row>
              <Row md={12}>
                <Row>
                  <Col className="title_register text-center">
                    {t(
                      'You have a spreadsheet with seven columns and ten rows. How many variables are there?',
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-8 offset-md-2">
                    <div className="questionnairecheckbox">
                      <Form.Check
                        type="radio"
                        key={'industry_1'}
                        id="industry_1"
                        {...props}
                      />
                      <p>10</p>
                    </div>
                  </Col>
                  <Col className="col-md-8 offset-md-2">
                    <div className="questionnairecheckbox">
                      <Form.Check
                        type="radio"
                        key={'industry_2'}
                        id="industry_2"
                        {...props}
                      />
                      <p>7</p>
                    </div>
                  </Col>
                  <Col className="col-md-8 offset-md-2">
                    <div className="questionnairecheckbox">
                      <Form.Check
                        type="radio"
                        key={'industry_3'}
                        id="industry_3"
                        {...props}
                      />
                      <p>7 x 10 = 70</p>
                    </div>
                  </Col>
                </Row>
              </Row>
              {
                stage !== QUESTIONNAIRE2_STAGE ? advButton() : <></> //creates his own for map styling
              }
            </Container>
          </Col>
        </Row>
        <Row>
          <Col id="alertContainer">
            <Alert
              variant="danger"
              show={alert !== null}
              onClose={() => {
                setAlert('')
              }}
              transition={true}
            >
              {alert}
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  )
}
