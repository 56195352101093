import { request } from './base'

export function getGoal({ modelId, token, signout }) {
  return request({
    endpoint: `/lida/goal/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export function patchGoal({ modelId, n = 3, token, signout }) {
  return request({
    endpoint: `/lida/goal/${modelId}?n=${n}`,
    method: 'PATCH',
    token,
    signout,
  })
}

export function getVisualize({ modelId, token, signout }) {
  return request({
    endpoint: `/lida/visualize/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export function postVisualize({
  modelId,
  question,
  visualization,
  rationale,
  id,
  token,
  signout,
}) {
  return request({
    endpoint: `/lida/visualize/${modelId}`,
    method: 'POST',
    body: {
      id,
      question,
      visualization,
      rationale: rationale ?? ' ',
    },
    contentType: 'json',
    token,
    signout,
  })
}

export function getGoalVisualizations({
  modelId,
  idsToIgnore = null,
  basedOn = null,
  token,
  signout,
}) {
  return request({
    endpoint: `/lida/goal-visualizations/${modelId}${
      basedOn ? `?based_on=${basedOn}` : ''
    }`,
    method: 'POST',
    body: idsToIgnore ?? [],
    ignoreOddStatus: false,
    contentType: 'json',
    token,
    signout,
  })
}

export function visualizaEdit({
  modelId,
  goalId,
  instructions,
  token,
  signout,
}) {
  return request({
    endpoint: `/lida/visualize/edit/${modelId}?goal_id=${goalId}&instructions=${instructions}`,
    method: 'POST',
    token: token,
    signout: signout,
  })
}

export function generateAllVisualizations({
  modelId,
  token,
  signout,
  ...props
}) {
  return request({
    endpoint: `/lida/generate_all/${modelId}`,
    method: 'POST',
    token: token,
    signout: signout,
    ...props,
  })
}

export function goalRecommend({ modelId, goalId, token, signout }) {
  return request({
    endpoint: `/lida/visualize/recommend/${modelId}?goal_id=${goalId}`,
    method: 'POST',
    rawResponse: true,
    token: token,
    signout: signout,
  })
}

export function confirmGoalIds({
  modelId,
  goalIds,
  uncomfirmIds,
  token,
  signout,
}) {
  return request({
    endpoint: `/lida/goal/confirm/${modelId}`,
    method: 'POST',
    body: {
      goal_ids: goalIds,
      uncomfirm_goal_ids: uncomfirmIds,
    },
    contentType: 'json',
    rawResponse: true,
    token,
    signout,
  })
}

export function getGoalsNotVisualized({ modelId, token, signout }) {
  return request({
    endpoint: `/lida/goals-not-visualized/${modelId}`,
    token: token,
    signout: signout,
  })
}

export function deleteGoals({ modelId, goalIds = null, token, signout }) {
  return request({
    endpoint: `/lida/goal/${modelId}`,
    method: 'DELETE',
    body: goalIds,
    contentType: 'json',
    token,
    signout,
  })
}
