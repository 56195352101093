import { Row, Col } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

const problemTypeDesc = {
  Classification: 'Predict a given category',
  Regression: 'Predict a continuous column',
  Forecast: 'Predict a time dependent column',
  'Marketing Mix Modeling': 'Quantify your Marketing impact',
  'Anomaly Detection': 'Detect anomalies in your data',
}

export default function ProblemButton({
  problemType,
  active = false,
  onClick = () => {},
  onDeselect = () => {},
  ...props
}) {
  const { t } = useTranslation()
  if (active) {
    return (
      <Row {...props}>
        <Col
          className={`problem-select-subrow pe-none user-select-none ${problemType.replace(
            /\s/g,
            '',
          )}-target-select-button`}
          xs={'auto'}
        >
          <div>{t(problemType)}</div>
        </Col>
      </Row>
    )
  }

  return (
    <Row
      onClick={() => onClick(problemType)}
      {...props}
      className={`${problemType.replace(
        /\s/g,
        '',
      )}-target-select-button target-select-button ${props.className ?? ''}`}
    >
      <Row className="justify-content-center" style={{ marginLeft: '30px' }}>
        <Col className="h5 d-inline-block text-truncate" xs={'auto'}>
          <div>{t(problemType)}</div>
        </Col>
        <Col xs={12}></Col>
        <Col xs={'auto'} align="center">
          {t(problemTypeDesc[problemType]) ?? ''}
        </Col>
      </Row>
    </Row>
  )
}
