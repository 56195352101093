import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import $ from 'jquery'
import MediaContributionBars from '../../mmm/MediaContributionBars'

export function ConfigMediaContributionBars({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.mcb-title').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="mcb-title"
            defaultValue={`${config.title ?? 'Media contribution'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetMediaContributionBars({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  return (
    <Row {...props} id={id} className={`w-100 h-100 ${props.className ?? ''}`}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col
        xs={12}
        style={{ height: 'calc(100% - 50px)', position: 'relative' }}
      >
        <MediaContributionBars
          style={{ minHeight: '100%' }}
          model={model}
          {...props}
        />
      </Col>
    </Row>
  )
}
