import React, { useRef, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import * as markmap from 'markmap-view'
import { Transformer } from 'markmap-lib'
import Loading from '../loading/LoadingSmall'
import { FaPlus, FaMinus } from 'react-icons/fa'
import { RiCollapseDiagonal2Fill } from 'react-icons/ri'

export default function MindMap({ markup, ...props }) {
  const ref = useRef()
  const [map, setMap] = useState(null)

  // useEffect(() => {
  //   if (map) {
  //     try {
  //       const getChildren = (n) => {
  //         if (Array.isArray(n.c) && n.c.length) {
  //           return n.c.map(getChildren).flat()
  //         }
  //         return n
  //       }
  //       const children = getChildren(map.state.data)
  //         .flat()
  //         .filter((v) => v)
  //         .sort((a, b) => a.p.x0 - b.p.x0)
  //       if (children?.[0]) map.ensureView(children[0], { right: 400, top: 100 })
  //     } catch (e) { }
  //   }
  // }, [map])

  useEffect(() => {
    if (!markup) return null
    const pieces = markup
      .split('\n')
      .map((v) => {
        let transform = []
        const tail = v.split(' ').reduce((ac, v, i, arr) => {
          ac = ac + v + ' '
          if (ac.length > 100) {
            transform.push(ac)
            ac = ''
          }
          return ac
        }, '')
        if (tail) transform.push(tail)
        return transform.join('\n')
      })
      .join('\n')
    const transformer = new Transformer()
    const { root } = transformer.transform(pieces)
    const { Markmap } = markmap
    const map = Markmap.create(
      ref.current,
      {
        nodeFont: 'Open Sans',
        spacingVertical: 20,
      },
      root,
    )
    setMap(map)
    window.currentMap = map
    return () => {
      setMap(null)
      map.destroy()
    }
  }, [markup])

  return (
    <Row className="position-relative">
      {markup ? (
        <>
          <div className="controls-mind-map">
            <div className="d-flex flex-column">
              <FaPlus
                className="icon-btn mb-3 p-1"
                size={30}
                title={'Zoom in'}
                onClick={() => map?.rescale(1.5)}
              />
              <FaMinus
                size={30}
                className="icon-btn mb-3 p-1"
                title={'Zoom out'}
                onClick={() => map?.rescale(0.66)}
              />
              <RiCollapseDiagonal2Fill
                className="icon-btn mb-3 p-1"
                size={30}
                title={'Fit in view'}
                onClick={() => {
                  map?.fit()
                }}
              />
            </div>
          </div>
          <Col xs={12} className={`mindmap-container position-relative`}>
            <svg ref={ref} className={`h-100 w-100`}></svg>
          </Col>
        </>
      ) : (
        <Loading />
      )}
    </Row>
  )
}
