import { request } from './base'
import { FaShareSquare } from 'react-icons/fa'
import { HELP_URL } from '../Constants'

export async function queryNlp(data) {
  const response = await fetch(
    'https://api-inference.huggingface.co/models/deepset/roberta-base-squad2',
    {
      headers: { Authorization: 'Bearer ' },
      method: 'POST',
      body: JSON.stringify(data),
    },
  )
  const result = await response.json()
  return result
}

export async function secureQueryNlp(data, token, signout) {
  return request({
    endpoint: `/model/nlp`,
    method: 'POST',
    body: data,
    contentType: 'json',
    token: token,
    signout: signout,
  })
}

export function andJoin(data) {
  if (data.length === 0) return ''
  if (data.length === 1) return data[0]
  return data.slice(0, -1).join(', ') + ` and ${data[data.length - 1]}`
}

function getMostImportantMetric(problem_type) {
  switch (problem_type) {
    case 'regression':
      return 'ExpVariance'
    case 'time_series_regression':
      return '100 - Weighted MAPE'
    case 'binary':
    case 'time_series_binary':
      return 'Accuracy Binary'
    case 'multiclass':
    case 'time_series_multiclass':
      return 'Accuracy Multiclass'
    default:
      return 'Precision'
  }
}

function getAcc({ model, train = false }) {
  const metric = getMostImportantMetric(model.problem_type)
  if (train) return parseInt(parseFloat(model.acc_train[metric]) * 100)
  let acc = parseInt(parseFloat(model.acc[metric]) * 100)
  if (acc > 999) acc = parseInt(acc / 100)
  return acc
}

function getModelAccPhrases(model) {
  const accTrain = getAcc({ model, train: true })
  const accTest = getAcc({ model })

  let trainingAccs = `The model accuracy during training phase is ${accTrain}%.\n`
  for (const [k, v] of Object.entries(model.acc_train)) {
    if (k === 'Precision') continue
    trainingAccs += `The model "${k}" during training phase is ${v}.\n`
  }

  let testingAccs = `The model testing accuracy or model accuracy is ${accTest}%.\n`
  for (const [k, v] of Object.entries(model.acc)) {
    if (k === 'Precision') continue
    testingAccs += `The model "${k}" during testing phase is ${v}.\n`
  }

  testingAccs += `The baseline is ${model.baseline}.`

  let modelSummary = ''

  // Overfitting
  if (accTest > accTrain + 20) {
    modelSummary =
      'It is not a production ready model, it is not good because the system detects overfitting.\n'
  } else {
    if (accTest > 80) {
      // Good model
      modelSummary =
        'It is a really good model with high accuracy and no overfitting detected, it is doing much better than baseline.\n'
    } else if (accTest > model.baseline) {
      // Medium model
      modelSummary =
        'It is a good model but the system can do better with some data adjustments, it is doing better than baseline..\n'
    } else if (accTest <= model.baseline) {
      // Bad model
      modelSummary =
        'It is not a good model, it is doing worst than baseline.. Try providing more data.\n'
    }
  }

  return modelSummary + trainingAccs + testingAccs
}

export function generateModelContext(model) {
  if (!model || !model.dataset || model.status !== 'trained') return ''
  let featureImportanceStr = ''
  if (model.details.feature_importance)
    featureImportanceStr = `The most important features, columns or labels are: ${andJoin(
      model.details.feature_importance
        .sort((a, b) => a.importance - b.importance)
        .map((a) => a.feature),
    )}.`
  return `The model and data set name is ${
    model.dataset.name
  } and it's target feature is ${model.target}.
The problem type of the model is ${model.problem_type}, created on ${
    model.created
  } and it was trained on ${model.training_date}.
The model was trained using ${
    model.train_percentage * 100
  }% of the data set, therefore the ${
    100 - model.train_percentage * 100
  }% of the data set was used for testing it.
The model was tested using ${
    100 - model.train_percentage * 100
  }% of the data set, therefore it was trained using ${
    model.train_percentage * 100
  }% of the data set.

${getModelAccPhrases(model)}
The alogithms used to create the model are ${
    model.algorithms.length === 1 && model.algorithms[0] === 'Auto'
      ? 'All'
      : andJoin(model.algorithms)
  }.
The active columns, features or labels of the model are: ${andJoin(
    Object.keys(model.columns_active).filter((k) => model.columns_active[k]),
  )}.
The relevant columns, features or labels are: ${andJoin(
    Object.keys(model.relevant_columns),
  )}.
${featureImportanceStr}
The data set columns, features or labels are: ${andJoin(
    model.dataset.columns_order,
  )}.

The data set has ${model.dataset.rows} rows and ${
    model.dataset.cols
  } columns, features or labels.
The data preparation or data preprocessing of the data set are ${model.dataset.logs.join(
    '\n',
  )}

To predict the model, you need to go to "Train model" tab.
The probability of ${andJoin(
    [model.target].concat(model.dataset.columns_order),
  )} could be obtained going to "Train model" tab.
  `.replace(/^\s+|\s+$/g, '')
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function buildHref({ href, text = 'Get more information here' }) {
  return (
    <button
      href={href}
      target="_blank"
      className={`linkshare link`}
      rel="noopener noreferrer"
      onClick={() => window.open(href, '_blank')}
      style={{ float: 'left' }}
    >
      <span>{text}</span>
      <FaShareSquare />
    </button>
  )
}

export function enrichBotAnswer(question, answer, model) {
  const lowAnswer = answer.toLocaleLowerCase()
  const initialAnswer = (
    <>
      <span>{capitalizeFirstLetter(answer)}</span>
      <br />
    </>
  )
  if (lowAnswer.includes('train model')) {
    return (
      <>
        {initialAnswer}
        <span>or</span>
        <br />
        {buildHref({ href: `/predict/${model.id}`, text: 'Predict online' })}
      </>
    )
  } else if (
    lowAnswer.includes('binary') ||
    lowAnswer.includes('time_series_binary') ||
    lowAnswer.includes('multiclass') ||
    lowAnswer.includes('time_series_multiclass') ||
    lowAnswer.includes('regression') ||
    lowAnswer.includes('time_series_regression')
  ) {
    return (
      <>
        {initialAnswer}
        {buildHref({ href: `${HELP_URL}/en/` })}
      </>
    )
  }
  return capitalizeFirstLetter(answer)
}
