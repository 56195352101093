import * as d3 from 'd3'
import { useState, useEffect } from 'react'

export function useSampleFlow() {
  const [nodes, setNodes] = useState([])
  const [branch, setBranch] = useState(null)
  const [simulation, setSimulation] = useState(null)
  const [idGen] = useState(() => {
    let i = 0
    return () => i++
  })

  const addNodes = ({
    newNodes,
    createElement = (n) =>
      d3
        .create('svg:circle')
        .attr('r', 3)
        .attr('cx', n.x)
        .attr('cy', n.y)
        .style('fill', n.node.color),
    onCreate = () => {},
    category = (n) => n.category,
    initalPosition = () => ({ x: 0, y: 0 }),
  }) => {
    const createdNodes = [
      ...newNodes.map((n) => {
        const newNode = {
          node: n,
          category: category(n),
          id: n.id ?? idGen(),
          ...initalPosition(n),
        }
        newNode.element = createElement(newNode)
        onCreate(newNode)
        return newNode
      }),
    ]
    setNodes(createdNodes)
    return createdNodes
  }

  const getNodesByCategory = () =>
    nodes.reduce((dict, n) => {
      dict[n.category] = dict[n.category] ?? []
      dict[n.category].push(n)
      return dict
    }, {})

  const activateBranch = (branches, request = (b) => b.requests()) => {
    setBranch({
      branches: branches,
      request: request,
    })
  }
  // eslint-disable-next-line
  const previous = () => {
    const branches = branch.branches
    const request = branch.request
    branches.forEach(
      (b) => (b.assignedNodes = b.children ? b.assignedNodes ?? [] : []),
    )
    const freeNodesByCategory = getNodesByCategory()
    let count = Object.entries(freeNodesByCategory).reduce(
      (ac, [e, v]) => ac + v.length,
      0,
    )
    const nodeSet = []
    branches.forEach((b) => {
      if (!count || b.children) return
      const requests = request(b)
      requests.forEach((r) => {
        if (freeNodesByCategory?.[r.category]?.length) {
          const node = freeNodesByCategory?.[r.category].pop()
          node.assignedParent = branch
          node.targetX = r.x
          node.targetY = r.y
          node.element.attr('fill-opacity', 1)
          nodeSet.push(node)
          if (!--count) return
        }
      })
    })

    //In case we leave nodes hanging for rounding errors with ratios we hide them :(
    Object.keys(freeNodesByCategory).forEach((k) => {
      freeNodesByCategory[k].forEach((n) => n.element.attr('fill-opacity', 0))
    })

    setSimulation((s) => {
      if (s) s.stop()
      return d3
        .forceSimulation()
        .alphaDecay(0)
        .force('collide', d3.forceCollide())
        .force('x', d3.forceX((d) => d.targetX).strength(0.07))
        .force('y', d3.forceY((d) => d.targetY).strength(0.07))
        .nodes(nodeSet)
        .on('tick', () => {
          nodeSet.forEach((n) => {
            n.element.attr('cx', n.x).attr('cy', n.y)
          })
        })
    })
  }

  useEffect(() => {
    if (simulation) simulation.stop()
    if (nodes.length && branch) {
      //previous();
      const branches = branch.branches
      const request = branch.request
      const nodeSet = []
      nodes.forEach((n) => n.element.attr('fill-opacity', 0))
      branches
        .filter((b) => !b.children)
        .forEach((b) => {
          const freeNodesByCategory = b.dynamicNodes.reduce((dict, n) => {
            dict[n.category] = dict[n.category] ?? []
            dict[n.category].push(n)
            return dict
          }, {})

          request(b).forEach((r) => {
            if (freeNodesByCategory?.[r.category]?.length) {
              const node = freeNodesByCategory?.[r.category]?.pop()
              if (!node) {
                console.log('Failed to get dynamic node')
                return
              }
              node.targetX = r.x
              node.targetY = r.y
              node.element.attr('fill-opacity', 1)
              nodeSet.push(node)
            }
          })
        })
      setSimulation((s) => {
        if (s) s.stop()
        return d3
          .forceSimulation()
          .alphaDecay(0)
          .force('collide', d3.forceCollide())
          .force('x', d3.forceX((d) => d.targetX).strength(0.03))
          .force('y', d3.forceY((d) => d.targetY).strength(0.03))
          .nodes(nodeSet)
          .on('tick', () => {
            nodeSet.forEach((n) => {
              n.element.attr('cx', n.x).attr('cy', n.y)
            })
          })
      })
    }

    // eslint-disable-next-line
  }, [nodes, branch])

  useEffect(() => {
    return () => {
      if (simulation) simulation.stop()
    }
    // eslint-disable-next-line
  }, [])

  return [nodes, addNodes, activateBranch]
}
