import React from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import PredictionChart from '../../mmm/PredictionChart'

import {
  AccInsightV2,
  HowToImprovePerformance,
  TextAccuracy,
} from '../../insights/insights-v2'

import { ModelSummaryEngagement } from '../ModelSummaryEngagement'
import { useModels } from '../../../providers/ModelProvider'
import { DownloadGraphOverlay } from '../../utils/DownloadGraphOverlay'
import IntersectionWrapper from '../../intersection-wrapper/IntersectionWrapper'
import MMMInsightHeading from './MMMInsightHeading'
import { isAnomalyModel } from '../../../util/models'
import AnomalySumary from './AnomalySummary'
import HowToImproveMMMPerformance from '../../mmm/HowToImproveMMMPerformance'
import MediaContribution from '../../mmm/MediaContribution'
import MediaContributionBars from '../../mmm/MediaContributionBars'
import BestSpend from '../../mmm/BestSpend'
import LagAndCarryover from '../../mmm/LagAndCarryover'
import OriginalSpend from '../../mmm/OriginalSpend'
import Influence from '../../mmm/Influence'
import DynamicSpend from '../../mmm/DynamicSpend'

import FunnelEffect from '../../mmm/FunnelEffect'
import SyntheticDataSummary from './SyntheticDataSummary'
import ErrorNB from '../../loading/ErrorNB'
import CrashFallback from '../../crash-fallback/CrashFallback'
import TotalFunnelEffect from '../../mmm/TotalFunnelEffect'

export default function ModelSummary({
  currentTrainingConfig,
  expand,
  switchSynthetic,
  switchBalanceData,
  syntheticEnabled,
  balanceDataEnabled,
  switchRemoveOutliers,
  removeOutliersEnabled,
  dashboardFlair,
  switchTabs = () => {},
  children,
  ...props
}) {
  const { activeModel } = useModels()
  const { t } = useTranslation()

  const showImprovePerformance =
    activeModel.dataset.cols > 2 && activeModel.special_model_type !== 'mmm'
  const showMMMTable = activeModel.special_model_type === 'mmm'
  const isNotSpecialModelType =
    activeModel.special_model_type === null ||
    activeModel.special_model_type === undefined

  const targetColumn = currentTrainingConfig?.targetConfig?.target

  if (activeModel.status !== 'trained') return <></>

  const improvePerformance = showImprovePerformance && !showMMMTable && (
    <HowToImprovePerformance
      Wrapper={Col}
      wrapperProps={{
        className: 'ps-0',
        xs: 12,
      }}
      model={activeModel}
      viewData={() => {
        switchTabs(3)
      }}
      viewBot={() => {
        switchTabs(3)
        setTimeout(() => {
          const anchorElement = document.querySelector('#accordion-chat-bot')
          if (anchorElement)
            anchorElement.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }}
      trainingConfiguration={currentTrainingConfig}
      style={{
        minHeight: '100%',
        marginLeft: 0,
        maxHeight: '100%',
        overflow: 'auto',
      }}
      switchSynthetic={switchSynthetic}
      switchBalanceData={switchBalanceData}
      syntheticEnabled={syntheticEnabled}
      balanceDataEnabled={balanceDataEnabled}
      switchRemoveOutliers={switchRemoveOutliers}
      removeOutliersEnabled={removeOutliersEnabled}
    />
  )

  return (
    <Col {...props}>
      <Row className="w-100 justify-content-end ms-3">
        <SyntheticDataSummary
          model={activeModel}
          className="py-2 justify-content-end w-100"
        />
      </Row>
      {isAnomalyModel(activeModel) ? (
        <Col className="mb-3" xs={12}>
          <AnomalySumary model={activeModel} />
        </Col>
      ) : (
        <>
          <Row
            style={{ marginTop: '6px' }}
            className="container-insights-training"
          >
            {!showMMMTable && (
              <Col className="mb-3" md={4}>
                <IntersectionWrapper
                  component={AccInsightV2}
                  visibleProps={{
                    isInView: true,
                    model: activeModel,
                    style: { minHeight: '100%' },
                  }}
                  hiddenProps={{
                    isInView: false,
                    model: activeModel,
                    style: { minHeight: '100%' },
                  }}
                  viewProps={{ amount: 0.1, once: true }}
                />
              </Col>
            )}
            {showMMMTable && (
              <>
                <Col className="mb-4" xs={12}>
                  <TextAccuracy model={activeModel} />
                </Col>
                <Col xs={12}>
                  <HowToImproveMMMPerformance
                    model={activeModel}
                    trainingConfiguration={currentTrainingConfig}
                  />
                </Col>
                <Col className="ps-0 mb-5" xl={4} md={12}>
                  <Row
                    className="h-100 ms-0 insight-model-content position-relative"
                    style={{ zIndex: 0 }}
                  >
                    <DownloadGraphOverlay
                      buttonsStyle={{ top: '3px', right: '13px' }}
                    >
                      <MMMInsightHeading
                        className="ms-4 py-4"
                        title="Current media mix"
                      >
                        <Row>
                          <Col xs={12}>
                            {t(
                              `A description of the input data used to build the model. Input data is divided into three categories: media spend, KPIs, and extra features. The columns where we include the spend for each media channel are referred to as media spend.`,
                            )}
                          </Col>
                          <Col className="mt-1" xs={12}>
                            {t(
                              `KPI is the target column and can be any metric we use to assess our company's success. Finally, extra features are other variables that are not spent on media but have an impact on our KPI.`,
                            )}
                          </Col>
                        </Row>
                      </MMMInsightHeading>
                      <Col xs={12}>
                        <CrashFallback message={<ErrorNB />} t={t}>
                          <IntersectionWrapper
                            component={OriginalSpend}
                            visibleProps={{
                              isInView: true,
                              legend: true,
                              height: 250,
                            }}
                            hiddenProps={{
                              isInView: false,
                              margin: {
                                top: 20,
                                right: 250,
                                bottom: 30,
                                left: 20,
                              },
                            }}
                            viewProps={{ amount: 'some', once: true }}
                          />
                        </CrashFallback>
                      </Col>
                    </DownloadGraphOverlay>
                  </Row>
                </Col>
                <Col xl={8} md={12} className="mb-5 ps-0">
                  <DownloadGraphOverlay>
                    <Row className="ms-xl-4 ms-md-0 ms-0">
                      <Col
                        className={'insight-model-content overflow-hidden'}
                        xs={12}
                      >
                        <Row className="px-3">
                          <Col xs={12}>
                            <Row className="py-1">
                              <MMMInsightHeading
                                title={'Media effects - average'}
                                description={`Media channels average influence on outcome. The sum of this effects is equal to 100% because baseline effect is considered separately.`}
                                className="px-2 py-4"
                              />
                            </Row>
                            <CrashFallback
                              style={{ minHeight: '0px' }}
                              message={<ErrorNB />}
                              t={t}
                            >
                              <MediaContributionBars
                                isInView={true}
                                model={activeModel}
                                style={{ minHeight: '250px' }}
                              />
                            </CrashFallback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="mb-5" xs={12}>
                  <DownloadGraphOverlay>
                    <Row className="insight-model-content row">
                      <Col className="" xs={12}>
                        <MMMInsightHeading
                          className="ms-2 py-4"
                          title={'Response Model accuracy'}
                          description={`Accuracy of the response model in predicting target outcomes given current data on consumer and market responses to the marketing mix.`}
                        />
                      </Col>
                      <Col
                        style={{
                          minHeight: '450px',
                          maxHeight: '450px',
                        }}
                        className="pt-3"
                        xs={12}
                      >
                        <CrashFallback
                          style={{ minHeight: '0px' }}
                          message={<ErrorNB />}
                          t={t}
                        >
                          <PredictionChart
                            isInView={true}
                            model={activeModel}
                            target={targetColumn}
                          />
                        </CrashFallback>
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="mb-5 insight-model-content" xs={12}>
                  <DownloadGraphOverlay>
                    <Row>
                      <MMMInsightHeading
                        title="Current Effect"
                        className="ms-4 py-4"
                        description={
                          <Row>
                            <Col xs={12}>
                              {t('The Media Effects per Week plot displays')}{' '}
                              <strong>
                                {t(
                                  'the weekly impact of each marketing channel on your business outcomes.',
                                )}
                              </strong>{' '}
                              {t(
                                'It visually represents how the effectiveness of each channel fluctuates over time, allowing for pinpoint identification of high-performing channels and optimal timing for campaigns.',
                              )}
                            </Col>
                            <Col xs={12}>
                              {t(
                                'It serves as a key tool for informed marketing strategy development, budget allocation, and campaign scheduling.',
                              )}
                            </Col>
                          </Row>
                        }
                      />
                    </Row>
                    <Row className="my-1">
                      <Col xs={12}>
                        <IntersectionWrapper
                          component={MediaContribution}
                          visibleProps={{
                            isInView: true,
                            model: activeModel,
                            target: targetColumn,
                          }}
                          hiddenProps={{
                            isInView: false,
                            model: activeModel,
                            target: targetColumn,
                          }}
                          viewProps={{ amount: 0.8, once: true }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col
                  className={'insight-model-content overflow-hidden px-0 mb-5'}
                  xs={12}
                >
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <Col xs={12}>
                        <Row>
                          <MMMInsightHeading
                            title={'Funnel effect'}
                            description={`The funnel effect shows how investment on the left channels of the graph affect the channels on the bottom of the graph.`}
                            className="pe-2 ms-4 pt-4"
                          />
                        </Row>
                        <Row className="pb-4">
                          <Col className="smallp" xs={12}>
                            {t(
                              'The collaborative effects of investing in different channels.',
                            )}
                          </Col>
                        </Row>
                        <IntersectionWrapper
                          component={FunnelEffect}
                          visibleProps={{
                            isInView: true,
                            model: activeModel,
                            style: { minHeight: '450px', maxHeight: '450px' },
                          }}
                          hiddenProps={{
                            isInView: false,
                            model: activeModel,
                            style: { minHeight: '450px', maxHeight: '450px' },
                          }}
                          viewProps={{ amount: 0.8, once: true }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col
                  className={'insight-model-content overflow-hidden px-0 mb-5'}
                  xs={12}
                >
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <Col xs={12}>
                        <Row>
                          <MMMInsightHeading
                            title={'KPI summary breakdown'}
                            description={`Individual effects on the KPI per channel and date, negative values shown in red suggest negative effects on performance.`}
                            className="pe-2 ms-4 pt-4"
                          />
                        </Row>
                        <Row className="pb-4">
                          <Col className="smallp" xs={12}>
                            {t(
                              'Individual effects per channels broken down by dates.',
                            )}
                          </Col>
                        </Row>
                        <TotalFunnelEffect
                          model={activeModel}
                          style={{ minHeight: '450px', maxHeight: '450px' }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="insight-model-content px-0 mb-5" xs={12}>
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <MMMInsightHeading
                        title={'Lag and Carryover'}
                        className="pe-2 ms-4 py-4"
                      >
                        <Row>
                          <Col xs={12}>
                            {t(
                              'Dynamic effects are those effects of advertising that change with time. The carryover effect of advertising is that portion of its effect that occurs in time periods following the pulse of advertising. The lag effect is the time delay existing between advertising response and launch.',
                            )}
                          </Col>
                        </Row>
                      </MMMInsightHeading>
                      <Col style={{ minHeight: '500px' }} xs={12}>
                        <IntersectionWrapper
                          component={LagAndCarryover}
                          visibleProps={{
                            isInView: true,
                          }}
                          hiddenProps={{
                            isInView: false,
                          }}
                          viewProps={{ amount: 0.8, once: true }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="mb-5 insight-model-content px-0" xs={12}>
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <MMMInsightHeading
                        title={'Ad to consumer response'}
                        className="pe-2 mx-4 py-4"
                      >
                        <Row>
                          <Col xs={12}>
                            {t(
                              'Ad spend and consumer response can differ due to effects: the response has a delay in time with the moment where we launch the campaign (lagged effect), and the magnitude of this consumer response is not proportional to the media spend effort (diminishing returns).',
                            )}
                          </Col>
                        </Row>
                      </MMMInsightHeading>
                      <Col style={{ minHeight: '400px' }} xs={12}>
                        <IntersectionWrapper
                          component={DynamicSpend}
                          visibleProps={{
                            isInView: true,
                            model: activeModel,
                          }}
                          hiddenProps={{
                            isInView: false,
                            model: activeModel,
                          }}
                          viewProps={{ amount: 0.8, once: true }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="mb-5 insight-model-content px-0" xs={12}>
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <MMMInsightHeading
                        title={'Underlying effects'}
                        className="pe-2 ms-4 py-4"
                      >
                        <Row>
                          <Col xs={12}>
                            {t(
                              'Marketing contribution includes media effects, it is, the differences in advertising response due to various media.',
                            )}
                          </Col>
                          <Col xs={12}>
                            {t(
                              'Non media contribution are those influences in KPI that are not directly related to a spend in advertising.',
                            )}
                          </Col>
                        </Row>
                      </MMMInsightHeading>
                      <Col style={{ minHeight: '450px' }} xs={12}>
                        <Influence model={activeModel} inView={true} />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
                <Col className="mb-5 insight-model-content px-0" xs={12}>
                  <DownloadGraphOverlay
                    buttonsStyle={{ top: '3px', right: '5px' }}
                  >
                    <Row className="px-3">
                      <MMMInsightHeading
                        title={'Shape effect (diminishing returns)'}
                        description={`The shape effect refers to the change in sales in response to increasing intensity of advertising in the same time period`}
                        className="pe-2 ms-4  py-4"
                      />
                      <Col style={{ minHeight: '600px' }} xs={12}>
                        <IntersectionWrapper
                          component={BestSpend}
                          visibleProps={{
                            isInView: true,
                            target: targetColumn,
                          }}
                          hiddenProps={{
                            isInView: false,
                            target: targetColumn,
                          }}
                          viewProps={{ amount: 0.8, once: true }}
                        />
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>

                {/* */}
              </>
            )}
            {isNotSpecialModelType && (
              <>
                <ModelSummaryEngagement
                  model={activeModel}
                  improvePerformance={improvePerformance}
                />
              </>
            )}
          </Row>
        </>
      )}
      {showMMMTable && children}
    </Col>
  )
}
