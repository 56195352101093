import React, { useRef, useState, useCallback, useEffect } from 'react'
import {
  Row,
  Col,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap'
import ReactFlow, {
  ReactFlowProvider,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  getBezierPath,
} from 'reactflow'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { BsPencil } from 'react-icons/bs'
import { FaPlus, FaTable } from 'react-icons/fa'
import { FiPlay } from 'react-icons/fi'
import { MdOutlineHelpOutline } from 'react-icons/md'
import { useQueryClient } from 'react-query'
import { ImTree } from 'react-icons/im'
import { useTour } from '@reactour/tour'
import Logo from '../../../assets/icons/1.svg'
import IntroScreen from '../../homepage/IntroScreen'

import {
  typeToComponent,
  datasourceConfig,
  actionConfig,
  unbindToType,
  bindToType,
} from './config'
import { treeArrange } from './tools'
import { recoverFlow, validateStructure, createMerge } from './validation'
import { useAuth } from '../../../providers/AuthProvider'
import { InlineEdit } from '../../inline-edit/InlineEdit'
import { useModels } from '../../../providers/ModelProvider'
import { importFlow } from '../../../services/csv'
import { assistantQuestion, getModelById } from '../../../services/model'
import { awaitTask } from '../../../services/base'
import { Status } from './Status'
import PropsProvider from '../../../providers/PropsProvider'
import 'reactflow/dist/style.css'
import './DatasourceFlow.css'
import useStoreState from '../../../hooks/UseStoreState'
import { DESCRIPTION_PROMPT } from '../model-assistant/assistantConfig'
import RoleDisable from '../../utils/RoleDisable'

function Path({
  id,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
  ...props
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  if (target === 'output') return <></>

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path flow-trail"
      d={edgePath}
      markerEnd={markerEnd}
    />
  )
}

const edgeTypes = {
  default: Path,
}

const editProps = {
  panOnScroll: false,
  zoomOnScroll: true,
  zoomOnDoubleClick: false,
  panOnDrag: true,
  minZoom: 0.4,
  maxZoom: 2,
}

const readOnlyProps = {
  panOnScroll: true,
  zoomOnScroll: false,
  zoomOnDoubleClick: false,
  panOnDrag: false,
  minZoom: 0.4,
  maxZoom: 2,
}

export default function DatasourceFlow({
  defaultNodes = null,
  name = true,
  modelId, // Model id when updating
  projectId,
  onFinish,
  readOnly = false,
  onCheckModel = false,
  styleFlow = {},
  preName,
  ...props
}) {
  const [userHelp, setUserHelp] = useStoreState({ key: 'userHelp' })
  const flowRef = useRef()
  const contextRef = useRef()
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])

  const { isOpen, setIsOpen, setCurrentStep } = useTour()
  const [tour, setTour] = useStoreState({
    key: 'shownTour-dataflow',
    shownTour: false,
  })
  const [prevState, setPrevState] = useState(null)
  useEffect(() => {
    if (!isOpen && prevState) {
      setNodes(prevState.nodes)
      setEdges(prevState.edges)
      setPrevState(null)
    }
  }, [prevState, isOpen])

  const [readyForCreation, setReadyForCreation] = useState(false)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [defaultConnection, setDefaultConnection] = useState(null)
  const [lastEnd, setLastEnd] = useState({})
  const queryClient = useQueryClient()
  const { activeModel, updateModel, addPlaceholder, addModel } = useModels()
  const editRef = useRef()
  const arrangeRef = useRef()
  const [modelName, setModelName] = useState(
    modelId
      ? activeModel.dataset.name
      : `New model ${new Date().toLocaleDateString('default', {
          dateStyle: 'medium',
        })}`,
  )
  const { t } = useTranslation()
  const [importing, setImporting] = useState(false)
  const { user, token, signout } = useAuth()
  const [importingStatus, setImportingStatus] = useState({})
  const lastAction = useRef('create')
  const idGenenerator = useRef(
    (() => {
      let id = 1
      return (d) => {
        if (d) id = d + 1
        return `node_${id++}`
      }
    })(),
  )

  const onNodesChange = useCallback(
    (changes) =>
      setNodes((nds) => {
        return applyNodeChanges(changes, nds)
      }),
    [],
  )

  const [temporaryOption, setTemporaryOption] = useState(null)
  useEffect(() => {
    if (lastEnd?.start && lastEnd?.end) {
      const sourceNode = lastEnd.start.target.dataset['nodeid']
      const sourceHandle = lastEnd.start.target.dataset['handleid']
      const node = nodes.find((n) => n.id === sourceNode)

      const position = reactFlowInstance.project({
        x: lastEnd.end.layerX - 60,
        y: lastEnd.end.layerY - 60,
      })
      const minY = window.innerHeight
      if (sourceHandle.includes('target')) {
        setTemporaryOption(
          <>
            <div className="dataflow-backdrop"></div>
            <div
              className="temporary-option-container"
              style={{
                position: 'absolute',
                top: Math.min(lastEnd.end.layerY, minY - 280),
                left: lastEnd.end.layerX,
                backgroundColor: 'var(--nextbrain-body)',
                zIndex: 1000,
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div className="config-placeholder dflex-center">
                <FaPlus
                  className="icon-btn"
                  style={{ color: 'var(--nextbrain-white)', fontSize: 65 }}
                />
              </div>
              <ContextMenu
                id={'temporary-menu'}
                className="menu-context-dataflow force-interactive"
                style={{
                  transform: 'translate(30px, -60px)',
                }}
              >
                {Object.keys(datasourceConfig)
                  .filter((k) => k !== 'Config')
                  .map((key) => (
                    <MenuItem key={key}>
                      <div
                        className="d-inline-block text-nowrap py-0 mb-0 h5 mx-3"
                        onMouseDown={() => {
                          setTemporaryOption(null)
                          setNodes((p) =>
                            p.filter((n) => n?.data?.id !== node.id),
                          )
                          createNewNode({
                            type: key,
                            position: position,
                            forceActive: true,
                            sourceHandle: sourceHandle,
                            sourceNode: node,
                          })
                        }}
                      >
                        {datasourceConfig[key].iconSmall}
                        {t(key)}
                      </div>
                    </MenuItem>
                  ))}
              </ContextMenu>
            </div>
          </>,
        )
      } else {
        if (node?.data?.valid)
          setTemporaryOption(
            <>
              <div className="dataflow-backdrop"></div>
              <div
                className="temporary-option-container"
                style={{
                  position: 'absolute',
                  top: Math.min(lastEnd.end.layerY, minY - 250),
                  left: lastEnd.end.layerX,
                  backgroundColor: 'var(--nextbrain-body)',
                  zIndex: 1000,
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <div className="config-placeholder dflex-center">
                  <FaPlus
                    className="icon-btn"
                    style={{ color: 'var(--nextbrain-white)', fontSize: 65 }}
                  />
                </div>
                <ContextMenu
                  id={'temporary-menu'}
                  className="menu-context-dataflow force-interactive"
                  style={{
                    transform: 'translate(30px, -60px)',
                  }}
                >
                  {Object.keys(actionConfig).map((key) => (
                    <MenuItem key={key}>
                      <div
                        className="d-inline-block text-nowrap h5 mb-0"
                        onMouseDown={() => {
                          createNewNode({
                            type: key,
                            position: position,
                            forceActive: (actionConfig[key]?.inputs ?? 1) === 1,
                            input: node,
                            adjust: false,
                          })
                        }}
                      >
                        {actionConfig[key].iconSmall}
                        {t(key)}
                      </div>
                    </MenuItem>
                  ))}
                </ContextMenu>
              </div>
            </>,
          )
        else {
          NotificationManager.error(
            t(
              'Configure the node by clicking on it before connecting the node to other nodes',
            ),
            null,
            5000,
          )
        }
      }
      setLastEnd(null)
    }
    // eslint-disable-next-line
  }, [lastEnd, nodes])

  const onEdgesChange = useCallback(
    (changes) =>
      setEdges((eds) => {
        return applyEdgeChanges(changes, eds)
      }),
    [],
  )
  const onConnect = useCallback(
    (params) => {
      setLastEnd({})
      const visitedNodes = new Set()
      const nodesToVisit = [params.target]
      while (nodesToVisit.length) {
        const currentNode = nodesToVisit.shift()
        if (currentNode === params.source) {
          NotificationManager.error('Circular dependency detected')
          return
        }
        if (visitedNodes.has(currentNode)) continue
        visitedNodes.add(currentNode)
        nodesToVisit.push(
          ...edges.filter((e) => e.source === currentNode).map((e) => e.target),
        )
      }

      setEdges((eds) => {
        const source = nodes.find((n) => n.id === params.source)
        if (!source.data.valid) {
          NotificationManager.error(
            t('Configure the node before connecting it'),
          )
          return eds
        }

        const [removedEdges, remainingEdges] = eds.reduce(
          (p, e) => {
            if (
              e.targetHandle !== params.targetHandle &&
              (e.target !== params.target || e.source !== params.source)
            )
              p[1].push(e)
            else p[0].push(e)
            return p
          },
          [[], []],
        )

        const newEdges = addEdge(params, remainingEdges)
        removedEdges.forEach((e) => {
          const sourceIndex = nodes.findIndex((n) => n.id === e.source)
          const targetIndex = nodes.findIndex((n) => n.id === e.target)
          const source = nodes[sourceIndex]
          const target = nodes[targetIndex]
          if (source && source.data.onUnbind) {
            nodes[sourceIndex] = { ...source }
            source.data.onUnbind(source, nodes, newEdges)
          }
          if (target && target.data.onUnbind) {
            nodes[targetIndex] = { ...target }
            target.data.onUnbind(target, nodes, newEdges)
          }
        })

        const targetNodeIndex = nodes.findIndex((n) => n.id === params.target)
        const targetNode = nodes[targetNodeIndex]

        if (targetNode?.data?.onBind) {
          targetNode.data.onBind(targetNode, nodes, newEdges)
          nodes[targetNodeIndex] = { ...targetNode }
        }
        return newEdges
      })
      setNodes((nds) => [...nds])
    },
    // eslint-disable-next-line
    [nodes],
  )

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  function selectOutputNode({ node }) {
    setEdges((eds) => {
      const newEds = eds.filter((e) => e.target !== 'output')
      return newEds.concat({
        id: `reactflow__edge-${node.id}${node.id}_source-outputoutput _target`,
        source: node.id,
        sourceHandle: `${node.id}_source`,
        target: 'output',
        targetHandle: 'output_target',
      })
    })
  }

  function createNewNode({
    type,
    position,
    additionalConfig = {},
    valid = false,
    dataProps = {},
    forceActive = null,
    replacedNode = null,
    input,
    sourceHandle,
    sourceNode,
    adjust = true,
  }) {
    lastAction.current = 'create'
    if (!('x' in position)) {
      const { clientWidth, clientHeight } = flowRef.current
      position = reactFlowInstance.project({
        x: clientWidth / 6,
        y: clientHeight / 4,
      })
    } else position = { ...position }

    if (input && adjust) position = { ...position, x: position.x + 250 }

    let newNode = {
      id: idGenenerator.current(),
      type,
      position,
      data: {
        label: type,
        type: type,
        active:
          forceActive ??
          (!valid &&
            !datasourceConfig[type]?.skipConfigByDefault &&
            Object.keys(datasourceConfig).includes(type)),
        config: {
          ...additionalConfig,
        },
        valid,
        onBind: bindToType[type],
        onUnbind: unbindToType[type],
        ...dataProps,
      },
    }

    newNode.data.controls = {
      onDelete: () => {
        lastAction.current = 'delete'
        setNodes((nds) => {
          setEdges((eds) => {
            const toInvalidateIds = new Set(
              eds.filter((n) => n.source === newNode.id).map((n) => n.target),
            )
            const toInvalidate = nds.filter((n) => toInvalidateIds.has(n.id))
            toInvalidate.forEach((n) => n?.data?.onUnbind?.(n))
            return eds.filter(
              (e) => e.source !== newNode.id && e.target !== newNode.id,
            )
          })
          const idx = nds.findIndex((n) => n.id === newNode.id)
          if (idx !== -1) {
            const node = nds[idx]
            nds.splice(idx, 1)
            if (nds?.length === 1) {
              createNewNode({
                type: 'Config',
                position: node.position,
              })
            }
          }
          return [...nds]
        })
      },
      onUpdate: (data) => {
        setNodes((nds) => {
          const original = nds.find((n) => n.id === newNode.id)
          return nds
            .filter((n) => n.id !== newNode.id)
            .concat({ ...original, data: data })
        })
      },
    }

    if (input) {
      const singleConnection = (actionConfig?.[type]?.inputs ?? 1) > 1
      const target = singleConnection
        ? `${newNode.id}_left_target`
        : `${newNode.id}_uniq_target`
      newNode.position.y += singleConnection && adjust ? 120 : 0
      setNodes((nds) => nds.concat(newNode))
      const edge = {
        id: `reactflow__edge-${input.id}${input.id}-${newNode.id}${target}`,
        source: input.id,
        sourceHandle: `${input.id}_source`,
        target: newNode.id,
        targetHandle: target,
      }
      setEdges((e) => [...e, edge])
    } else setNodes((nds) => nds.concat(newNode))

    if (sourceNode && sourceHandle) {
      setEdges((e) => {
        return e
          .filter((ed) => ed.targetHandle !== sourceHandle)
          .concat({
            id: `reactflow__edge-${sourceNode.id}${sourceNode.id}-${newNode.id}${sourceHandle}`,
            source: newNode.id,
            sourceHandle: `${newNode.id}_source`,
            target: sourceNode.id,
            targetHandle: sourceHandle,
          })
      })
    }
    return newNode
  }

  const triedMerges = useRef({})
  //Try to automerge
  useEffect(() => {
    if (lastAction.current === 'delete') return
    const used = edges.filter((e) => e.target !== 'output').map((e) => e.source)
    const availableFiles = nodes.filter(
      (n) => !used.includes(n.id) && n.id !== 'output',
    )
    if (
      availableFiles.length === 2 &&
      availableFiles.every((n) => n.data.valid)
    ) {
      const [file1, file2] = availableFiles.sort(
        (a, b) => a.position.y - b.position.y,
      )
      if (triedMerges.current[`merge-${file1.id}-${file2.id}`]) return
      triedMerges.current[`merge-${file1.id}-${file2.id}`] = true
      triedMerges.current[`merge-${file2.id}-${file1.id}`] = true
      NotificationManager.info('Trying to merge files')
      const reactFlowBounds = flowRef.current.getBoundingClientRect()
      createMerge({
        existingNodes: availableFiles,
        event: {
          clientX: file1?.position?.x,
          clientY: file1?.position?.y,
        },
        reactFlowInstance,
        reactFlowBounds,
        xoffset: 100,
        yoffset: 100,
        createNewNode,
        edges,
        setEdges,
        setNodes,
        token,
        signout,
        triedMerges,
      }).then((r) => {
        if (r) NotificationManager.info('Suggested merge available')
        else NotificationManager.info('No automatic merge available')
      })
    }
    // eslint-disable-next-line
  }, [nodes, edges])

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault()
      let additionalConfig = {}
      const reactFlowBounds = flowRef.current.getBoundingClientRect()
      let type = event.dataTransfer.getData('application/reactflow')
      const xoffset = event.dataTransfer.getData('dragXOffset')
      const yoffset = event.dataTransfer.getData('dragYOffset')
      if (typeof type === 'undefined' || !type) {
        if (event.dataTransfer.files.length) {
          switch (event.dataTransfer.files.length) {
            case 2: {
              NotificationManager.info('Trying to merge files')
              const successMerge = await createMerge({
                files: [...event.dataTransfer.files],
                event,
                reactFlowInstance,
                reactFlowBounds,
                xoffset,
                yoffset,
                createNewNode,
                edges,
                setEdges,
                setNodes,
                token,
                signout,
                triedMerges,
              })
              if (successMerge) {
                NotificationManager.info('Suggested merge available')
                setNodes((n) => n.filter((n) => n?.type !== 'Config'))
              } else {
                NotificationManager.info('No automatic merge available')
              }
              break
            }

            default:
              Promise.all(
                [...event.dataTransfer.files].map(
                  (file, i) =>
                    new Promise((resolve) => {
                      const position = reactFlowInstance.project({
                        x:
                          event.clientX -
                          reactFlowBounds.left -
                          (xoffset || 0) +
                          i * 125,
                        y: event.clientY - reactFlowBounds.top - (yoffset || 0),
                      })
                      createNewNode({
                        type: 'Upload local file',
                        position,
                        additionalConfig: {
                          fileToUpload: file,
                        },
                        valid: false,
                        forceActive: true,
                        dataProps: {
                          subtitle: file.name,
                          sample: null,
                        },
                      })
                      resolve()
                    }),
                ),
              )
              break
          }
        }
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - (xoffset || 0),
        y: event.clientY - reactFlowBounds.top - (yoffset || 0),
      })
      createNewNode({ type, position, additionalConfig })
    },
    // eslint-disable-next-line
    [reactFlowInstance],
  )

  const contextPoint = useRef({ x: 0, y: 0 })
  const addSourceFromContext = () => {
    const values = {
      dragXOffset: 55,
      dragYOffset: 55,
      'application/reactflow': 'Config',
    }
    const simuEvent = {
      preventDefault: () => {},
      dataTransfer: {
        files: [],
        getData: (key) => values[key],
      },
      clientX: contextPoint.current.x,
      clientY: contextPoint.current.y,
    }
    onDrop(simuEvent)
  }

  if (!onFinish)
    onFinish = ({ target, structure }, setImporting) => {
      const executeImport = (useAssistant = false) => {
        importFlow({
          outputIndex: target,
          objects: structure,
          name: modelName,
          modelId: modelId,
          projectId: projectId,
          token,
          signout,
        })
          .then(async (r) => {
            const { task_id, model_id } = r
            setImporting(false)
            if (modelId) {
              const { model_id } = await awaitTask({
                taskUuid: task_id,
                sleep: 1000,
              })
              if (onCheckModel && useAssistant) {
                setImporting(false)
                onCheckModel(model_id)
                return
              }
              const model = await getModelById(model_id, token, signout)
              updateModel(modelId, model)
              setTimeout(
                () => queryClient.invalidateQueries(`model-flow-${modelId}`),
                5000,
              )
            } else {
              if (model_id) {
                if (onCheckModel && useAssistant) {
                  setImporting(false)
                  onCheckModel(model_id)
                  return
                } else {
                  const model = await getModelById(model_id, token, signout)
                  if (model) {
                    addModel(model)
                    new Promise(async (resolve) => {
                      while (true) {
                        const model = await getModelById(
                          model_id,
                          token,
                          signout,
                        )
                        switch (model?.dataset?.status) {
                          case 'imported':
                            resolve(model)
                            return
                          case 'readyToImport':
                          case 'importing':
                            await new Promise((r) => setTimeout(r, 5000))
                            break
                          default:
                            resolve(null)
                            return
                        }
                      }
                    })
                      .then((model) => {
                        if (model) {
                          assistantQuestion({
                            modelId: model.id,
                            prompt: DESCRIPTION_PROMPT,
                            key: 'initialSuggestions',
                            token: token,
                            signout: signout,
                          })
                        } else {
                          console.error(
                            'Failed to import model, skipping context generation',
                          )
                        }
                      })
                      .catch(() => {})
                    return
                  }
                }
              }
              if (onCheckModel && useAssistant) {
                setImporting(false)
                onCheckModel(model_id)
                return
              }
              addPlaceholder(modelName, async () => {
                const { model_id } = await awaitTask({
                  taskUuid: task_id,
                  sleep: 1000,
                })
                const model = await getModelById(model_id, token, signout)
                if (!model) {
                  NotificationManager.error('Error importing model')
                  return null
                }
                return model
              })
            }
          })
          .catch(() => {
            NotificationManager.error('Error importing model')
            setImporting(false)
          })
      }

      if (
        true || //Forced to not use assistant
        modelId ||
        structure.some((s) => {
          const cols = s?.metadata?.data?.sample?.columns?.length
          if (cols && cols > 500) return true
          return false
        })
      )
        executeImport(false)
      else {
        setTemporaryOption(
          <Modal
            centered
            size={'lg'}
            show={true}
            onHide={() => {
              setTemporaryOption(null)
            }}
          >
            <Modal.Header closeButton>
              <h5>{t('Create model')}</h5>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-5">
                <Col xs={6}>
                  <Button
                    className="h-100 w-100 py-3"
                    onClick={() => {
                      executeImport(true)
                    }}
                  >
                    <Row>
                      <Col
                        xs={12}
                        style={{ maxWidth: 'calc(100% - 100px)' }}
                        className="smallp text-center"
                      >
                        {t(
                          'Use the AI assistant to discover solvable problems, gain further insights into your data and aid you in the creation of the model',
                        )}
                      </Col>
                      <Col
                        xs={12}
                        className="px-0 dflex-center"
                        style={{ maxWidth: '100px' }}
                      >
                        <Image
                          style={{ filter: 'invert(1)' }}
                          src={Logo}
                          width={70}
                        />
                      </Col>
                    </Row>
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="h-100 w-100 py-3"
                    onClick={() => {
                      executeImport(false)
                    }}
                  >
                    <Row>
                      <Col
                        xs={12}
                        className="px-0 dflex-center"
                        style={{ maxWidth: '100px' }}
                      >
                        <FaTable size={50} />
                      </Col>
                      <Col
                        xs={12}
                        style={{ maxWidth: 'calc(100% - 100px)' }}
                        className="smallp text-center dflex-center"
                      >
                        {t('Create model without assistant')}
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>,
        )
      }
    }

  useEffect(() => {
    if (reactFlowInstance && !nodes.length) {
      const { clientWidth } = flowRef.current
      const ystart = window.innerHeight / 2 - 100

      const output = {
        id: 'output',
        type: 'Output',
        position: { x: clientWidth - 200, y: ystart },
        data: {
          label: 'output',
          finishMessage: 'Create model',
          import: () => setImporting(true),
        },
      }
      if (defaultNodes) {
        const { nodes, edges } = recoverFlow(defaultNodes)
        idGenenerator.current(
          nodes
            .map((n) => Number.parseInt(n.id.replace('node_', '')))
            .sort((a, b) => b - a)[0] ?? 0,
        )
        const sourceNode = edges.find((e) => e.target === 'output')
        const toOutput = nodes.find((n) => n.id === sourceNode.source)
        if (toOutput) output.position.y = toOutput.position.y + 200
        output.data.finishMessage = 'Update model'
        nodes.push(output)
        nodes.forEach((newNode) => {
          newNode.data.onBind = bindToType[newNode.type]
          newNode.data.onUnbind = unbindToType[newNode.type]
          newNode.data.controls = {
            onDelete: () => {
              setNodes((nds) => {
                setEdges((eds) => {
                  const toInvalidateIds = new Set(
                    eds
                      .filter((n) => n.source === newNode.id)
                      .map((n) => n.target),
                  )
                  const toInvalidate = nds.filter((n) =>
                    toInvalidateIds.has(n.id),
                  )
                  toInvalidate.forEach((n) => n?.data?.onUnbind?.(n))
                  return eds.filter(
                    (e) => e.source !== newNode.id && e.target !== newNode.id,
                  )
                })
                const idx = nds.findIndex((n) => n.id === newNode.id)
                if (idx !== -1) {
                  const node = nds[idx]
                  nds.splice(idx, 1)
                  if (nds?.length === 1) {
                    createNewNode({
                      type: 'Config',
                      position: node.position,
                    })
                  }
                }
                return [...nds]
              })
            },
            onUpdate: (data) => {
              setNodes((nds) => {
                const original = nds.find((n) => n.id === newNode.id)
                return nds
                  .filter((n) => n.id !== newNode.id)
                  .concat({ ...original, data: data })
              })
            },
          }
        })
        setNodes(nodes)
        setEdges(edges)
        if (readOnly) {
          setTimeout(() => {
            arrangeRef.current?.click()
          }, 1000)
        }
      } else {
        setNodes((nds) => {
          return nds.concat(output)
        })

        createNewNode({
          type: 'Config',
          position: { x: 150, y: ystart },
        })
        if (!tour?.shownTour) {
          setTimeout(() => setIsOpen(true), 500)
          setTour({ shownTour: true })
        }
      }
    }

    // eslint-disable-next-line
  }, [reactFlowInstance])
  useEffect(() => {
    const targetIndex = nodes.findIndex((n) => n.id === 'output')
    if (targetIndex !== -1) {
      const edge = edges.find(
        (e) => e.target === 'output' && nodes.find((n) => n.id === e.source),
      )
      setReadyForCreation(!!edge)
    } else {
      setReadyForCreation(false)
    }
  }, [nodes, edges])

  useEffect(
    () => {
      if (!defaultConnection && nodes.length === 2) {
        const output = nodes.find((n) => n.type === 'Output')
        const other = nodes.find((n) => n.type !== 'Output')

        if (other.data.valid) {
          const edge = {
            source: other.id,
            sourceHandle: `${other.id} _source`,
            target: output.id,
            targetHandle: `${output.id} _target`,
          }
          setEdges((eds) => {
            return addEdge(
              {
                source: other.id,
                sourceHandle: `${other.id} _source`,
                target: output.id,
                targetHandle: `${output.id} _target`,
              },
              eds,
            )
          })
          setDefaultConnection(edge)
        }
      } else if (defaultConnection && nodes.length > 2) {
        setEdges((eds) => {
          return eds.filter((e) => e.target !== 'output')
        })
        setDefaultConnection(null)
      } else if (defaultConnection && nodes.length === 1) {
        setDefaultConnection(null)
      }
    },
    // eslint-disable-next-line
    [nodes],
  )
  useEffect(() => {
    if (importing) {
      setImportingStatus({})
      const any = nodes.find((n) => n.type !== 'Config' && n.id !== 'output')
      if (any) any.data.advancedFlow = true
      validateStructure({
        nodes,
        edges,
        target: 'output',
        userData: { token, user, signout },
        onProgress: (percent, name) => {
          setImportingStatus((prev) => ({ ...prev, [name]: percent }))
        },
      })
        .then((result) => {
          if (result.error) {
            NotificationManager.error(result.errors[0])
            setImporting(false)
          } else {
            onFinish(result, setImporting)
          }
        })
        .catch((error) => {
          console.error(error)
          NotificationManager.error(`${error} `)
          setImporting(false)
        })
        .finally(() => setImportingStatus({}))
    }
    // eslint-disable-next-line
  }, [importing])

  return (
    <Row
      className={`position-relative flex-column  datasource-flow-component ${
        readOnly ? 'datasource-flow-readonly' : ''
      } ${props.className ?? ''} ${isOpen ? 'dataflow-open-tour' : ''}
      datasource-flow-nodenumber-${nodes?.length ?? 0}
      `}
    >
      {name && !temporaryOption && (
        <Col
          className="p-2"
          xs={'auto'}
          style={{
            position: 'absolute',
            top: 0,
            left: 5,
            zIndex: 2,
          }}
        >
          {preName}
          <Row
            className="justify-content-between"
            style={{ marginTop: -42, marginLeft: 50 }}
          >
            <Col className="h5" xs={'auto'}>
              <InlineEdit
                ref={editRef}
                text={modelName}
                onEdit={(text) => setModelName(text)}
              />
              <span className="active-icon cursor-pointer">
                <BsPencil
                  onClick={() => editRef.current?.click()}
                  size={20}
                  style={{ marginLeft: '15px', marginTop: '-5px' }}
                />
              </span>
            </Col>
          </Row>
        </Col>
      )}
      {!temporaryOption && (
        <Col xs={12}>
          <Row
            className="flex-column"
            style={{
              position: 'absolute',
              width: 'fit-content',
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Col xs={'auto'} className="d-flex flex-nowrap">
              <RoleDisable>
                <div
                  className={`create-model-flow ${
                    readyForCreation ? 'cursor-pointer' : 'cursor-normal'
                  } ${readyForCreation ? '' : 'disabled'}`}
                  onClick={() => {
                    if (!readyForCreation) {
                      setCurrentStep(1)
                      document
                        .querySelector('.help-button-datasource-flow')
                        ?.click()
                    }
                    setImporting(true)
                  }}
                  disabled={!readyForCreation}
                >
                  <Button
                    style={{ width: 54, height: 54, display: 'inline-block' }}
                    className="create-model-button original"
                    variant="success"
                    disabled={!readyForCreation}
                  >
                    <FiPlay size={30} />
                  </Button>
                  <span className="ms-2 h5">
                    {t(defaultNodes ? 'Update model' : 'Create model')}
                  </span>
                </div>
              </RoleDisable>
            </Col>
            <Col xs={'auto'} className="mt-2 mb-2 d-flex flex-nowrap">
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'top'}
                delay={{ show: 50, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`context-menu-column-type pe-none ${
                      props?.className ?? ''
                    }`}
                  >
                    <span className="">{t('Add new datasource')}</span>
                  </Tooltip>
                )}
              >
                <Button
                  className="add-new-datasource-button tour-part me-2"
                  onClick={() =>
                    createNewNode({ type: 'Config', position: {} })
                  }
                  style={{ marginBottom: 15 }}
                >
                  <FaPlus className="my-1" size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'top'}
                delay={{ show: 50, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`context-menu-column-type pe-none ${
                      props?.className ?? ''
                    }`}
                  >
                    <span className="">{t('Arrange nodes')}</span>
                  </Tooltip>
                )}
              >
                <Button
                  className="original me-2 not-important-flow-button"
                  onClick={() => {
                    const width = flowRef.current.offsetWidth
                    const height = flowRef.current.offsetHeight
                    treeArrange(nodes, edges, width, height)
                    setNodes((n) => [...n])
                    reactFlowInstance.fitBounds({
                      x: 0,
                      y: 150,
                      width,
                      height,
                    })
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <ImTree
                    color="var(--nextbrain-white-font)"
                    className="my-1"
                    size={24}
                  />
                </Button>
              </OverlayTrigger>

              <Button
                className="add-new-datasource-button me-2"
                style={{
                  display: 'none',
                  pointerEvents: 'none',
                  marginBottom: 15,
                }}
                onClick={() => createNewNode({ type: 'Config', position: {} })}
              >
                <FaPlus className="my-1" size={24} />
              </Button>

              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'top'}
                delay={{ show: 50, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`context-menu-column-type pe-none ${
                      props?.className ?? ''
                    }`}
                  >
                    <span className="">{t('Show help')}</span>
                  </Tooltip>
                )}
              >
                <Button
                  className="original not-important-flow-button help-button-datasource-flow"
                  onClick={() => {
                    const n = nodes
                    const e = edges
                    setIsOpen(true)
                    setPrevState({ nodes: n, edges: e })

                    const { clientWidth } = flowRef.current
                    const ystart = window.innerHeight / 2 - 100

                    const output = {
                      id: 'output',
                      type: 'Output',
                      position: { x: clientWidth - 200, y: ystart },
                      data: {
                        label: 'output',
                        finishMessage: 'Create model',
                        import: () => setImporting(true),
                      },
                    }
                    setEdges([])
                    setNodes([output])

                    createNewNode({
                      type: 'Config',
                      position: reactFlowInstance.project({
                        x: 200,
                        y: window.innerHeight / 2 - 100,
                      }),
                    })
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <MdOutlineHelpOutline
                    color="var(--nextbrain-white-font)"
                    className="my-1"
                    size={24}
                  />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      )}
      <Col xs={12}>
        <Row {...props}>
          <PropsProvider
            setNodes={setNodes}
            setEdges={setEdges}
            createNewNode={createNewNode}
            selectOutputNode={selectOutputNode}
            outputNode={edges.find((n) => n.target === 'output')?.source}
          >
            <ReactFlowProvider>
              <Col
                ref={flowRef}
                className="datasource-content position-relative px-0"
                onClick={() => contextRef.current?.hideMenu({ keyCode: 27 })}
              >
                <ContextMenuTrigger
                  id={`datasourceflow-context-menu`}
                  disableIfShiftIsPressed={true}
                >
                  <ReactFlow
                    ref={flowRef}
                    className="position-relative"
                    onContextMenu={(e) => {
                      contextPoint.current = { x: e.clientX, y: e.clientY }
                    }}
                    panOnScroll={true}
                    elementsSelectable={true}
                    onDrop={onDrop}
                    onClick={() => setTemporaryOption(null)}
                    onDragOver={onDragOver}
                    nodes={nodes}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    onEdgesChange={onEdgesChange}
                    onConnectStart={(e) => setLastEnd({ start: e })}
                    onConnectEnd={(e) =>
                      setTimeout(
                        () => setLastEnd((p) => ({ ...p, end: e })),
                        100,
                      )
                    }
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    nodeTypes={typeToComponent}
                    edgeTypes={edgeTypes}
                    {...(readOnly ? readOnlyProps : editProps)}
                  >
                    {temporaryOption}
                  </ReactFlow>
                </ContextMenuTrigger>
                <ContextMenu
                  id="datasourceflow-context-menu"
                  className="menu-context-dataflow"
                  ref={contextRef}
                >
                  <MenuItem onClick={addSourceFromContext}>
                    {t('Add new datasource')}
                  </MenuItem>
                </ContextMenu>
              </Col>
              <Col className="datasource-panel" xs={12}>
                <Row></Row>
              </Col>
            </ReactFlowProvider>
          </PropsProvider>
        </Row>
      </Col>
      <Modal size={'lg'} show={!userHelp.datasource}>
        <IntroScreen
          message={'Datasource import guide'}
          video={'https://www.youtube.com/embed/-0zgold9zIE'}
          onFinish={() => {
            setUserHelp({ ...userHelp, datasource: true })
          }}
        />
      </Modal>
      <Modal size={'lg'} show={importing}>
        <Row className="p-3">
          <Col className="py-3" xs={12}>
            <Status
              status={importingStatus}
              message={modelId ? t('Updating model') : t('Creating model')}
            />
          </Col>
        </Row>
      </Modal>
      <div
        style={{ zIndex: -1, top: '-10000px' }}
        className="position-absolute pe-none"
      >
        <svg
          className="circle-mask"
          viewBox="0 0 120 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <mask id="circleMask">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="-42" cy="80" r="65" fill="black" />
            </mask>
            <mask id="circleMask2">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="-44" cy="-17" r="65" fill="black" />
            </mask>
            <mask id="circleMask3">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="86" cy="-20" r="65" fill="black" />
            </mask>
          </defs>
        </svg>
      </div>
    </Row>
  )
}
