import React, { useState } from 'react'
import { Alert, Col, Row, Button, Form } from 'react-bootstrap'
import { FaKey } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'
import Image from 'react-bootstrap/Image'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'

import './RecoverForm.css'

export default function Recover(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { applyRecovery } = useAuth()
  const [alert, setAlert] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const token = searchParams.get('token')
  const handleSendPassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (e.target.password.value !== e.target.password2.value) {
      setAlert(t('Passwords do not match'))
      return
    } else {
      setAlert(null)
    }
    applyRecovery(e.target.password.value, token).then(() => {
      setIsLoading(false)
      navigate(`/`)
      NotificationManager.info(t('Password changed successfully'))
    })
  }
  return (
    <Row id="boxlogin" style={{ minHeight: '800px' }}>
      <Col id="LoginContainer" md={6}>
        <Row className="h-100 justify-content-center flex-column">
          <Row>
            <Col className="h4 mb-2" md={12}>
              {t('Set your new password')}
            </Col>
          </Row>
          <Form onSubmit={handleSendPassword}>
            <Row className="labellogin">{t('Password')}</Row>
            <Row className="posrelative">
              <FaKey className="iconinput iconinputlogin keyicon" />
              <Form.Control
                id="userPassword"
                type="password"
                bsPrefix="inputnextbrain"
                name="password"
                required
                onChange={(e) => {
                  setIsLoading(false)
                  setAlert(null)
                }}
              />
            </Row>
            <Row className="labellogin">{t('Confirm password')}</Row>
            <Row className="posrelative">
              <FaKey className="iconinput iconinputlogin keyicon" />
              <Form.Control
                id="userPassword"
                type="password"
                bsPrefix="inputnextbrain"
                name="password2"
                required
                onChange={(e) => {
                  setIsLoading(false)
                  setAlert(null)
                }}
              />
            </Row>
            <Row className="mb-5" align="center">
              <Button
                id="loginButton"
                className="w-100 p-2"
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                {t('Continue')}
              </Button>
              <Link
                className="mt-3 mb-5 backlink"
                style={{ fontSize: '20px', color: 'black' }}
                to={'/'}
              >
                {t('Go back')}
              </Link>
            </Row>
            <Alert
              variant="danger"
              show={alert !== null}
              onClose={() => {
                setAlert('')
              }}
              transition={true}
            >
              {alert}
            </Alert>
          </Form>
        </Row>
      </Col>
      <Col md={6} className="p-0 imglogin">
        <div className="logologin">
          <Image
            alt="NextBrain Logo"
            height={80}
            width={80}
            src="/LogoLogin.svg"
          />
        </div>
      </Col>
    </Row>
  )
}
