import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import $ from 'jquery'
import { round } from '../../utils/formating'

export function ConfigAdvancedStatistics({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.advanced-statistics-title').val(),
    fontSize: Number.parseInt($('.advanced-statistics-font-size').val()) ?? 12,
    precission:
      Number.parseInt($('.advanced-statistics-precission').val()) ?? 4,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="advanced-statistics-title"
            defaultValue={`${config.title ?? 'Advanced Statistics'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="advanced-statistics-font-size"
            defaultValue={`${config.fontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Precission:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="advanced-statistics-precission"
            defaultValue={`${config.precission ?? 4}`}
            placeholder="Precission..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function format(val, config) {
  const flt = Number.parseFloat(val)
  if (flt !== null) {
    return round(flt, config.precission ?? 4)
  }
  return val
}

export function WidgetAdvancedStatistics({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const acc = model?.acc ?? {}
  const acc_train = model?.acc_train ?? {}
  const isMMM = model.special_model_type === 'mmm'

  return (
    <Row className="w-100 h-100 overflow-scroll px-0 mx-0" id={id}>
      <Col
        className="header-data-distribution d-inline-block text-truncate widget-title"
        xs={12}
      >
        {config.title}
      </Col>
      <Row className="mx-0">
        <Col xs={isMMM ? 12 : 6}>
          {!isMMM && <strong>Testing</strong>}
          <ul style={{ fontSize: config.fontSize }}>
            {Object.keys(acc).map((k, i) => (
              <li key={i}>
                <strong>{k}</strong>: {`${format(acc[k], config)}`}
              </li>
            ))}
          </ul>
        </Col>
        {!isMMM && (
          <Col xs={6}>
            <strong>Training</strong>
            <ul style={{ fontSize: config.fontSize }}>
              {Object.keys(acc_train).map((k, i) => (
                <li key={i}>
                  <strong>{k}</strong>: {`${format(acc_train[k], config)}`}
                </li>
              ))}
            </ul>
          </Col>
        )}
      </Row>
    </Row>
  )
}
