import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import CheckoutForm from './CheckoutForm'
import ProductDetails from './ProductDetails'
import { createSubscription } from '../../services/pricing'
import { useAuth } from '../../providers/AuthProvider'
import { config } from '../../Constants'

export default function Payment({ setTitle }) {
  const { t } = useTranslation()
  const { signout, token, cookies } = useAuth()
  const param = useParams()
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState('')
  const [products, setProducts] = useState([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (cookies.payment) {
      setStripePromise(loadStripe(config.STRIPE_PK))
    } else setStripePromise(null)
  }, [cookies])

  useEffect(() => {
    setTitle(`Payment | ${t('NextBrain')}`)

    createSubscription({
      items: [{ id: param.id, price: searchParams.get('price') }],
      token,
      signout,
    })
      .then((data) => {
        setClientSecret(data.clientSecret)
        setProducts(data.products)
      })
      .catch((e) => console.log('Cannot get client secret', e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#4240B5',
    },
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Row className="mt-3">
      {clientSecret && stripePromise ? (
        <Elements options={options} stripe={stripePromise}>
          <Col md={6}>
            {products?.map((product) => (
              <ProductDetails key={product.id} product={product} />
            ))}
          </Col>
          <Col md={6}>
            {cookies?.payment && <CheckoutForm className="mt-3" />}
          </Col>
        </Elements>
      ) : (
        <>
          <Col md={6}>
            {products?.map((product) => (
              <ProductDetails key={product.id} product={product} />
            ))}
          </Col>
          <Col md={6}>
            {cookies?.payment && <CheckoutForm className="mt-3" />}
          </Col>
        </>
      )}
    </Row>
  )
}
