import React, { useState, useEffect } from 'react'
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FcServices } from 'react-icons/fc'
import { FaAngleDoubleRight, FaInfoCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'

import { getConfigurations } from '../../services/adminManager'
import Loading from '../loading/LoadingSmall'
import NextbrainSelect from '../model-content/NextbrainSelect'
import ConfirmButton from '../confirm-button/ConfirmButton'
import SingleAccordion from '../single-accordion/SingleAccordion'

import { help } from './configurationHelp'

function Aid({ service, namespace, envar }) {
  return help?.[service]?.[namespace]?.[envar] ? (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement={'auto'}
      delay={{ show: 100, hide: 200 }}
      overlay={(props) => (
        <Tooltip
          className={`wide-tooltip-400 ${props?.className ?? ''}`}
          {...props}
        >
          <span className="">{help?.[service]?.[namespace]?.[envar].help}</span>
        </Tooltip>
      )}
    >
      <div className="d-flex align-items-center">
        <FaInfoCircle />
      </div>
    </OverlayTrigger>
  ) : (
    <FaInfoCircle style={{ opacity: 0.3 }} />
  )
}

function ServiceConfiguration({
  service,
  name,
  values,
  overrideConfiguration,
  onChange,
  defaultOpen = false,
  ...props
}) {
  const [advancedOpen, setAdvancedOpen] = useState(defaultOpen)

  return (
    <Row {...props}>
      <Col
        className="h5 cursor-pointer d-flex align-items-center p-2 pt-0"
        onClick={() => setAdvancedOpen((v) => !v)}
        xs={12}
      >
        <u>
          <span className="pb-1">
            {name}{' '}
            <FaAngleDoubleRight
              className={`accordion-control ${
                advancedOpen ? 'open-accordion' : ''
              }`}
            />
          </span>
        </u>
      </Col>
      <Col>
        <SingleAccordion
          className="mt-0 advance-options accordion-admin-manager-configure nb-accordion"
          headerProps={{ className: 'd-none' }}
          forceStatus={advancedOpen}
        >
          <Row>
            {Object.entries(values).map(([key, value]) => (
              <Col className="mb-2" xs={12} key={key}>
                <Row>
                  <Col className="d-flex align-items-center" xl={6} lg={12}>
                    <Row className="w-100">
                      <Col
                        className="px-0 d-flex align-items-center"
                        style={{ maxWidth: '20px' }}
                      >
                        <Aid service={service} namespace={name} envar={key} />
                      </Col>
                      <Col
                        className="d-flex align-items-center ps-0"
                        style={{ maxWidth: 'calc(100% - 20px)' }}
                      >
                        {help?.[service]?.[name]?.[key]?.alias ?? key}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="d-flex align-items-center" xl={6} lg={12}>
                    {help?.[service]?.[name]?.[key]?.boolean ? (
                      <Form.Check
                        type="switch"
                        label={''}
                        className="form-switch-share ps-0"
                        defaultChecked={
                          overrideConfiguration?.[key] ??
                          !!overrideConfiguration?.[key]
                        }
                        onChange={async (e) =>
                          onChange(key, e.target.checked ? true : false)
                        }
                      />
                    ) : (
                      <Form.Control
                        onChange={(e) => onChange(key, e.target.value)}
                        className="nb-input-soft"
                        defaultValue={overrideConfiguration?.[key] ?? value}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </SingleAccordion>
      </Col>
    </Row>
  )
}

export default function AdminSystemConfiguration({ credentials, exit }) {
  const { data: configurations } = useQuery(
    'admin-manager-configurations',
    async () => {
      return await getConfigurations(
        credentials?.username,
        credentials?.password,
      ).then((r) => r.json())
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const [selectedService, setSelectedService] = useState(null)

  const [overrideConfiguration] = useState({})

  useEffect(() => {
    if (!selectedService && configurations) {
      const k = Object.keys(configurations)?.[0]
      if (k) setSelectedService({ label: k, value: k })
    }
  }, [configurations, selectedService])

  return !configurations || !selectedService ? (
    <Loading />
  ) : (
    <Row className="px-2 admin-manager-header-system-configuration mt-3">
      <Col>
        <Row>
          <Col className="pb-2 h5" xs={12}>
            <Row>
              <Col className="d-flex align-items-center pe-0" xs="auto">
                <FcServices size={30} />
              </Col>
              <Col>
                <strong>Services to configure</strong>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <div style={{ maxWidth: '300px' }}>
              <NextbrainSelect
                value={selectedService}
                onChange={(value) => setSelectedService(value)}
                options={Object.keys(configurations ?? {}).map((key) => ({
                  value: key,
                  label: key,
                }))}
                hideSelectedOptions={false}
                isClearable={false}
                placeholder={'Select a service'}
                type={'dark'}
              />
            </div>
          </Col>
          <Col xs={12}>
            <ConfirmButton
              className="original w-100 mt-3 py-2"
              variant="danger"
              title={'Cancel'}
              onClick={() => {}}
            >
              Revert current changes
            </ConfirmButton>
          </Col>
          <Col xs={12}>
            <ConfirmButton
              className="w-100 mt-3 py-2"
              title={'Cancel'}
              onClick={() => {}}
            >
              Save
            </ConfirmButton>
          </Col>
        </Row>
      </Col>
      <Col>
        {Object.entries(configurations[selectedService.value]).map(
          ([name, values], i) =>
            Object.keys(values).length > 0 ? (
              <ServiceConfiguration
                key={name}
                service={selectedService.value}
                name={name}
                values={values}
                override={overrideConfiguration?.[name]}
                defaultOpen={!i}
                onChange={(k, v) => {
                  overrideConfiguration[name] =
                    overrideConfiguration[name] ?? {}
                  overrideConfiguration[name][k] = v
                }}
                className="mb-4"
              />
            ) : (
              <span key={name}></span>
            ),
        )}
      </Col>
    </Row>
  )
}
