/** Gets the value at row and column of matrix. */
function get(point, matrix) {
  var columns = matrix[point.row]
  if (columns === undefined) {
    return undefined
  }
  return columns[point.column]
}

/** Creates a slice of matrix from startPoint up to, but not including, endPoint. */
function slice(startPoint, endPoint, matrix) {
  var sliced = []
  var columns = endPoint.column - startPoint.column
  for (var row = startPoint.row; row <= endPoint.row; row++) {
    var slicedRow = row - startPoint.row
    sliced[slicedRow] = sliced[slicedRow] || Array(columns)
    for (var column = startPoint.column; column <= endPoint.column; column++) {
      sliced[slicedRow][column - startPoint.column] = get(
        { row: row, column: column },
        matrix,
      )
    }
  }
  return sliced
}

/**
 * Flattens a matrix values to an array
 * @param matrix - the matrix to flatten values from
 * @param transform - optional transform function to apply to each value in the
 * matrix
 * @returns an array of the values from matrix, transformed if a transform
 * function is passed
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function toArray(matrix, transform) {
  var array = []
  for (var row = 0; row < matrix.length; row++) {
    for (var column = 0; column < matrix[row].length; column++) {
      var value = matrix[row][column]
      array.push(
        transform ? transform(value, { row: row, column: column }) : value,
      )
    }
  }
  return array
}

var FORMULA_VALUE_PREFIX = '='

/** Returns whether given value is a formula */
function isFormulaValue(value) {
  return typeof value === 'string' && value.startsWith(FORMULA_VALUE_PREFIX)
}

/** Get the computed value of a cell. */
function getComputedValue(_a) {
  var cell = _a.cell,
    formulaParser = _a.formulaParser
  if (cell === undefined) {
    return null
  }
  if (isFormulaCell(cell)) {
    return getFormulaComputedValue({ cell: cell, formulaParser: formulaParser })
  }
  return cell.value
}

/** Extracts formula from value  */
function extractFormula(value) {
  return value.slice(1)
}

/** Get the computed value of a formula cell */
function getFormulaComputedValue(_a) {
  var cell = _a.cell,
    formulaParser = _a.formulaParser
  var formula = extractFormula(cell.value)
  var _b = formulaParser.parse(formula),
    result = _b.result,
    error = _b.error
  return error || result
}

/** Returns whether given cell contains a formula value */
function isFormulaCell(cell) {
  return isFormulaValue(cell.value)
}

/** Tranform given hot-formula-parser coord to Point.Point */
export function transformCoordToPoint(coord) {
  return { row: coord.row.index, column: coord.column.index }
}

/**
 * Get cell value for given point from given spreadsheet data with evaluated
 * cells using given formulaParser
 */
export function getCellValue(formulaParser, data, point) {
  return getComputedValue({
    cell: get(point, data),
    formulaParser: formulaParser,
  })
}

/**
 * Get cell range value for given start and end points from given spreadsheet
 * data with evaluated cells using given formulaParser
 */
export function getCellRangeValue(formulaParser, data, start, end) {
  return toArray(slice(start, end, data), function (cell) {
    return getComputedValue({
      cell: cell,
      formulaParser: formulaParser,
    })
  })
}
