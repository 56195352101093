import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { IoSearchSharp } from 'react-icons/io5'

import { GridTable } from './GridTable'
import NextbrainSelect from '../model-content/NextbrainSelect'
function NavigatePage({
  page,
  pageOptions,
  columnOptions,
  onChangePage,
  columnsPerPage,
  hide,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Row {...props}>
      <Col className="mb-1" xs={12}>
        <Row>
          <Col xs={6} className="mt-2">
            {t('Select page')}:
          </Col>
          <Col xs={6}>
            <NextbrainSelect
              value={{ label: page + 1, value: page }}
              onChange={(value) => onChangePage(value?.value) && hide()}
              options={pageOptions}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder={'Select a page'}
              type={'dark'}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-3 mb-1" xs={12}>
        {t('Go to page containing column')}:
      </Col>
      <Col xs={12}>
        <NextbrainSelect
          value={null}
          onChange={(value) => {
            onChangePage(value?.index) && hide()
          }}
          options={columnOptions}
          hideSelectedOptions={false}
          isClearable={false}
          placeholder={'Select a column'}
          type={'dark'}
        />
      </Col>
    </Row>
  )
}

function ColNavigation({
  currentPage,
  setCurrentPage,
  columnSegmentation,
  header,
  ...props
}) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <span {...props} className={`${props?.className ?? ''}`}>
      <Row
        className="justify-content-end"
        style={{ marginTop: '-20px', maxWidth: '150px' }}
      >
        <Col
          xs={12}
          className="flex-row d-flex smallp justify-content-center mb-1"
        >
          <div className="flex-nowrap d-flex flex-row">
            {t('Showing columns')}
            <strong className="mx-1">
              {1 + currentPage * columnSegmentation.columnsPerPage}
            </strong>
            —
            <strong className="mx-1">
              {currentPage * columnSegmentation.columnsPerPage +
                header?.[0]?.length}
            </strong>
          </div>
        </Col>
        <Col
          xs={12}
          className="justify-content-center d-flex flex-nowrap position-relative"
        >
          <span
            className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
            disabled={currentPage === 0}
            onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
          >
            <FaChevronLeft size={24} />
          </span>
          <OverlayTrigger
            rootClose={true}
            trigger={'click'}
            placement="auto"
            onToggle={(show) => setShow(show)}
            delay={{ show: 200, hide: 0 }}
            overlay={(props) => <Popover {...props}></Popover>}
          >
            <span className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap">
              <IoSearchSharp size={18} />
            </span>
          </OverlayTrigger>
          <span
            className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
            disabled={currentPage === columnSegmentation.pages - 1}
            onClick={() =>
              setCurrentPage(
                Math.min(currentPage + 1, columnSegmentation.pages - 1),
              )
            }
          >
            <FaChevronRight size={24} />
          </span>
          {show && (
            <div
              className="popover-column-paginated-table"
              onClick={(e) => e.stopPropagation()}
            >
              <NavigatePage
                page={currentPage}
                pageOptions={columnSegmentation.pageOptions}
                columnOptions={columnSegmentation.columnOptions}
                onChangePage={(p) => setCurrentPage(p)}
                columnsPerPage={columnSegmentation.columnsPerPage}
                className="p-3"
                hide={() => setShow(false)}
              />
            </div>
          )}
        </Col>
      </Row>
    </span>
  )
}

export default function ColumnPaginatedGridTable({
  pageSize = 100,
  page = null,
  header,
  rows,
  navProps = {
    className: 'd-inline-block',
  },
  children,
  ...props
}) {
  const [currentPage, setCurrentPage] = useState(page || 0)

  const columnSegmentation = useMemo(() => {
    try {
      const columnsPerPage = pageSize
      const columns = header[0]
      const pages = Math.ceil(columns.length / columnsPerPage)
      const pageOptions = new Array(pages).fill(0).map((_, i) => ({
        label: i + 1,
        value: i,
      }))
      const columnOptions = columns.map((c, i) => ({
        label: c,
        value: c,
        index: Math.floor(i / columnsPerPage),
      }))
      return { columns, pages, columnsPerPage, pageOptions, columnOptions }
    } catch (e) {}
    return {
      columns: [],
      pages: 0,
      columnsPerPage: 0,
      pageOptions: [],
      columnOptions: [],
    }
    // eslint-disable-next-line
  }, [header, rows])

  const [_header, _rows] = useMemo(() => {
    try {
      const start = currentPage * pageSize
      const end = start + pageSize
      const _header = [header[0].slice(start, end)]
      const _rows = rows.map((row) => row.slice(start, end))
      return [_header, _rows]
    } catch (e) {}
    return [[], []]
    // eslint-disable-next-line
  }, [currentPage, pageSize, header, rows])

  const showNav = (header?.[0]?.length ?? 0) > pageSize

  return (
    <>
      {showNav && (
        <Row className="justify-content-end w-100">
          <Col xs="auto" className="pe-0">
            <ColNavigation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              columnSegmentation={columnSegmentation}
              header={_header}
              {...navProps}
            />
          </Col>
        </Row>
      )}
      <GridTable
        header={_header}
        rows={_rows}
        {...props}
        key={_header?.[0]?.length}
      >
        {children}
      </GridTable>
    </>
  )
}
