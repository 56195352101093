import { TiSortNumerically } from 'react-icons/ti'
import { FaListOl, FaRegCalendarAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import './DataTypeIcon.css'

export default function DataTypeIcon({ type, color, size, ...props }) {
  const { t } = useTranslation()
  const dcolor = color ?? 'auto'
  const dsize = size ?? 24

  switch (type?.toLowerCase()) {
    case 'integer':
      return (
        <TiSortNumerically
          color={color}
          size={size}
          title={'integer'}
          {...props}
          className={`icon-data-type icon-integer ${props.className ?? ''}`}
        />
      )
    case 'double':
    case 'decimal':
      return (
        <div
          title={'Decimal'}
          {...props}
          className={`icon-data-type icon-decimal ${props.className ?? ''}`}
          style={{
            ...(props.style ?? {}),
            borderColor: dcolor,
            width: `${dsize}px`,
            height: `${dsize}px`,
          }}
        >
          <div style={{ backgroundColor: dcolor }}></div>
        </div>
      )
    case 'datetime':
      return (
        <FaRegCalendarAlt
          title={type}
          size={size + 1}
          color={color}
          {...props}
          className={`icon-data-type icon-datetime ${props.className ?? ''}`}
        />
      )
    case 'categorical':
      return (
        <FaListOl
          title={type}
          size={size + 1}
          color={color}
          {...props}
          className={`icon-data-type icon-categorical ${props.className ?? ''}`}
        />
      )
    default:
      return <>{t('Invalid type')}</>
  }
}
