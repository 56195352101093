import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import NextbrainSelect from '../../NextbrainSelect'
import '../TrainFlow.css'
import HelpTooltip from '../../HelpTooltip'

export default function TimeColumn({
  onChange,
  mmmTimeColumn,
  mmmTimeColumnOptions,
  selectProps = {},
  ...props
}) {
  const { t } = useTranslation()
  return (
    <Row
      xs={12}
      onClick={() => {}}
      className={`columns-to-ignore-container ${props?.className ?? ''}`}
    >
      <Col xs={12}>
        {t('Time column')}{' '}
        <HelpTooltip
          className="help-select-icon"
          message={'time column'}
          helpLink={'time-column-17tcbw1'}
        />
      </Col>
      <Col xs={12}>
        <NextbrainSelect
          value={mmmTimeColumn}
          onChange={onChange}
          className="basic-single mt-2"
          classNamePrefix="select"
          name={t('Train quality')}
          options={mmmTimeColumnOptions}
          defaultValue={{
            value: mmmTimeColumnOptions[0].value,
            label: mmmTimeColumnOptions[0].label,
          }}
          {...selectProps}
        />
      </Col>
    </Row>
  )
}
