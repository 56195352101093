import { categories } from '../../util/aethetics'

export const colors = categories.mmm

export const integerParams = Object.freeze({
  xScale: { type: 'linear', min: 'auto', max: 'auto' },
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    legendOffset: 46,
    legendPosition: 'middle',
    tickValues: 20,
    tickRotation: -22,
  },
})

export const dateParams = Object.freeze({
  xScale: { format: '%Y-%m-%d', type: 'time' },
  axisBottom: {
    format: '%d/%m/%y',
    legendOffset: 46,
    legendPosition: 'middle',
    tickValues: 15,
    tickRotation: -22,
  },
})
