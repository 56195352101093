import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function LoadingModel({ shortMsg = 'Loading insights' }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container>
      <Row className="mt-5">
        <Col align="center" md={12}>
          <Image
            alt={t('NextBrain')}
            id="logo"
            style={{ width: '79px', height: '79px', marginTop: '-14px' }}
            className="cursor-pointer"
            onClick={() => navigate('/')}
            src={'/Logo/1.svg'}
          />
          <p>{t('NextBrain')}</p>
        </Col>
        <Col align="center" md={12} className="mt-2">
          <h5>{shortMsg}</h5>
          <p>{t('Please wait a few seconds.')}</p>
        </Col>
      </Row>
    </Container>
  )
}
