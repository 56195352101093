import { request } from './base'
import { config } from '../Constants'

export async function getModelById(modelId, token, signout) {
  return request({
    endpoint: `/model/get_model/${modelId}`,
    token,
    signout,
  })
}

export async function editPrivacy(modelId, options = {}, token, signout) {
  let params = []
  if ('private_report' in options)
    params.push(`private_report=${options.private_report}`)
  if ('private_predict' in options)
    params.push(`private_predict=${options.private_predict}`)
  if (params.length === 0) return
  return request({
    endpoint: `/model/edit_privacy/${modelId}?${params.join('&')}`,
    token,
    signout,
  })
}

export async function getModels(token, signout) {
  return request({
    endpoint: '/model/get_models',
    token,
    signout,
  })
}

export async function getModelsFromList(model_ids, token, signout) {
  return request({
    endpoint: '/model/get_models_from_list',
    body: {
      model_ids,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function modelsFromList(model_ids, token, signout) {
  return request({
    endpoint: '/model/models-from-list',
    body: {
      model_ids,
    },
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function getModelsUpdate(workspaceId, token, signout) {
  return request({
    endpoint: `/model/models?project_id=${workspaceId}`,
    contentType: 'json',
    token,
    signout,
  })
}

export async function deleteModel(modelId, token, signout) {
  return request({
    endpoint: `/model/delete_model/${modelId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function createModelFromCSV(csvId, token, signout) {
  return request({
    endpoint: `/model/create_by_csv/${csvId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function trainModel(modelId, data, token, signout) {
  return request({
    endpoint: `/model/train/${modelId}?from_gsheet=false`,
    method: 'POST',
    contentType: 'json',
    body: { ...data },
    ignoreOddStatus: false,
    token,
    signout,
  })
}

export async function predictModel(modelId, data, token, signout) {
  return request({
    endpoint: `/model/predict/${modelId}?from_gsheet=false`,
    method: 'POST',
    contentType: 'json',
    body: { ...data },
    token,
    signout,
    ignoreOddStatus: false,
  })
}

export async function getForecast(modelId, token, signout) {
  return request({
    endpoint: `/model/future_forecast/${modelId}`,
    token,
    signout,
  })
}

export async function uploadBatchPredict(modelId, file, token, signout) {
  let formdata = new FormData()
  formdata.append('dataset', file)
  return request({
    endpoint: `/model/upload_batch_predict/${modelId}?as_table=false`,
    method: 'POST',
    body: formdata,
    headers: {
      Accept: 'application/json',
    },
    token,
    signout,
    rawResponse: true,
    raw_body: true,
    redirect: 'follow',
  })
}

export async function getHead({
  modelId,
  pageSize,
  pageNumber,
  rules_matrix = false,
  token,
  signout,
}) {
  while (true) {
    const jsonResponse = request({
      endpoint: `/model/get_head/${modelId}?${
        pageSize || pageSize === 0 ? `&page_size=${pageSize}` : ''
      }${pageNumber || pageNumber === 0 ? `&page_number=${pageNumber}` : ''}${
        rules_matrix ? `&rules_matrix=${rules_matrix}` : ''
      }`,
      token,
      signout,
    })

    if (!jsonResponse) return null
    if (!('retry' in jsonResponse) && !jsonResponse.detail) {
      return jsonResponse
    }
    if (jsonResponse.detail) return jsonResponse.detail
    if (!jsonResponse?.retry) return []
  }
}

export async function editModelHTMLdescription({
  modelId,
  html_description,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/edit_html_description/${modelId}`,
    method: 'POST',
    body: { html_description },
    contentType: 'json',
    token,
    signout,
  })
}

export async function reportIssue({ modelId, msg, token, signout, ...props }) {
  return request({
    endpoint: `/model/report_issue/${modelId}`,
    method: 'POST',
    body: { msg: msg },
    contentType: 'json',
    token,
    signout,
    ...props,
  })
}

export async function editModelWidgetReport({
  modelId,
  widgets_report,
  token,
  signout,
  ...props
}) {
  return request({
    endpoint: `/model/edit_report/${modelId}`,
    method: 'POST',
    body: { widgets_report },
    contentType: 'json',
    token,
    signout,
    ...props,
  })
}

export async function generateSynthetic({ modelId, sample, token, signout }) {
  return request({
    endpoint: `/model/generate_synthetic/${modelId}?sample=${sample}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getDistribution({
  modelId,
  first_column,
  second_column,
  samples = 100,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/get_distribution/${modelId}?first_column=${first_column}&second_column=${second_column}&samples=${samples}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function setColumnType(
  modelId,
  field,
  desiredType,
  dateFormat,
  token,
  signout,
) {
  return request({
    endpoint: `/model/set_column_type/${modelId}`,
    method: 'POST',
    body: {
      field: field,
      type: desiredType,
      format: dateFormat,
    },
    contentType: 'json',
    token: token,
    signout: signout,
  })
}

export async function getTree({ modelId, token, signout }) {
  return request({
    endpoint: `/model/tree/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function originalForecast({ modelId, token, signout }) {
  return request({
    endpoint: `/model/original_forecast/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function optimizeMMM({
  modelId,
  token,
  signout,
  channelToMinSpend,
  channelToMaxSpend,
  outcomeTarget,
  budgetTarget,
  weeks,
  time,
}) {
  return request({
    endpoint: `/model/mmm-optimize/${modelId}`,
    method: 'POST',
    body: {
      channel_to_min_spend: channelToMinSpend,
      channel_to_max_spend: channelToMaxSpend,
      outcome_target: outcomeTarget,
      budget_target: budgetTarget,
      weeks: weeks,
      time,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function stopOptimizeMMM({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-stop-optimize/${modelId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function optimizeMMMTime({
  modelId,
  token,
  signout,
  channel,
  weeks,
  investment_amount,
}) {
  return request({
    endpoint: `/model/mmm-time-optimize/${modelId}`,
    method: 'POST',
    body: {
      channel: channel,
      weeks: weeks,
      investment_amount: investment_amount,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function getMMMOptimizedTable({
  modelId,
  is_outcome_optimization = true,
  weekly = false,
  original = false,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/mmm-model-optimized-table/${modelId}?is_outcome_optimization=${is_outcome_optimization}&weekly=${weekly}&original=${original}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMStatistics({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-model-statistics/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMModelStackedPlot({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-model-stacked-plot/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMModelTimeOptimization({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-model-optimized-time/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMSeasonalData({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-seasonal-data/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMSaturationCurves({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-saturation-curves/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMLagCarryover({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-carryover-and-lag/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMOutcomeBudgetOptimization({
  modelId,
  original = false,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/mmm-outcome-budget-optimization/${modelId}?original=${original}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMInfluence({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-influence/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMDynamicSpend({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-dynamic-spend/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMFunnelEffect({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-model-funnel-effect/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMTotalFunnelEffect({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-model-total-funnel-effect/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMMMBounds({ modelId, token, signout }) {
  return request({
    endpoint: `/model/mmm-bounds/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function downloadLookLikePrediction({ modelId, token, signout }) {
  return request({
    endpoint: `/model/download_look_like_prediction/${modelId}`,
    method: 'GET',
    token,
    signout,
    responseAsJson: false,
  })
}

export async function stopTraining({ modelId, token, signout }) {
  return request({
    endpoint: `/model/stop_training/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function stopTrainingClustering({
  modelId,
  clusteringModelId,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/stop-training-clustering/${modelId}/${clusteringModelId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getRecentModels({
  limit,
  page = 0,
  name_like = '',
  problem_type = null,
  model_status = null,
  project_type = null,
  project_name = null,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/get_recent_models?limit=${limit}&page=${page}&name_like=${name_like}${
      problem_type ? `&problem_type=${problem_type}` : ''
    }${model_status ? `&model_status=${model_status}` : ''}${
      project_type ? `&project_type=${project_type}` : ''
    }${
      project_name ? `&project_name=${encodeURIComponent(project_name)}` : ''
    }`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getForecastPerformance({ modelId, token, signout }) {
  return request({
    endpoint: `/model/forecast_performance/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}
export async function getForecastDataTrend({ modelId, token, signout }) {
  return request({
    endpoint: `/model/forecast/${modelId}/data-trend`,
    method: 'GET',
    token,
    signout,
  })
}
export async function getForecastWeeklyTrend({ modelId, token, signout }) {
  return request({
    endpoint: `/model/forecast/${modelId}/weekly-trend`,
    method: 'GET',
    token,
    signout,
  })
}
export async function getForecastYearlyTrend({ modelId, token, signout }) {
  return request({
    endpoint: `/model/forecast/${modelId}/yearly-trend`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getSpreadsheet({ modelId, token, signout }) {
  return request({
    endpoint: `/model/spreadsheet/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function saveSpreadsheet({
  modelId,
  spreadsheet,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/spreadsheet/${modelId}`,
    method: 'POST',
    body: spreadsheet,
    contentType: 'json',
    token,
    signout,
  })
}

export async function downloadDataset({ modelId, token, signout }) {
  return request({
    endpoint: `/model/csv/${modelId}`,
    rawResponse: true,
    token,
    signout,
  })
}

export async function downloadModifiedDataset({ modelId, token, signout }) {
  return request({
    endpoint: `/model/csv_transformed/${modelId}`,
    rawResponse: true,
    token,
    signout,
  })
}

export async function getModelFlow({ modelId, token, signout }) {
  return request({
    endpoint: `/model/flow/${modelId}`,
    token,
    signout,
  })
}

export async function getPercentageTrain({ modelId, token, signout }) {
  return request({
    endpoint: `/model/percent-train/${modelId}`,
    token,
    signout,
  })
}

export async function getPercentageOptimize({ modelId, token, signout }) {
  return request({
    endpoint: `/model/percent-optimized/${modelId}`,
    token,
    signout,
  })
}

export async function regenerateSuggestions({
  modelId,
  explore = false,
  transform = false,
  description = false,
  token,
  signout,
}) {
  const query = Object.entries({ explore, transform, description })
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&')
  return request({
    endpoint: `/model/re-generate-ai/${modelId}?${query}`,
    token,
    signout,
    method: 'POST',
  })
}

export async function downloadJupyter({
  name,
  description,
  cells,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/jupyter`,
    body: {
      name,
      description,
      cells,
    },
    rawResponse: true,
    contentType: 'json',
    token,
    signout,
    method: 'POST',
  })
}

export async function getLastThought({
  modelId,
  action_type = 'explore',
  token,
  signout,
}) {
  return request({
    endpoint: `/model/chat/${modelId}?action_type=${action_type}`,
    token,
    signout,
  })
}

export async function duplicateModelSecret({ modelId, name, password }) {
  return fetch(`${config.API_URL}/model/secret_duplicate_model/${modelId}`, {
    headers: { Authorization: 'Basic ' + btoa(`${name}:${password}`) },
  })
}

export async function downloadOutliersCsv({ modelId, token, signout }) {
  return request({
    endpoint: `/model/outliers-csv/${modelId}`,
    responseAsJson: false,
    token,
    signout,
  })
}
export async function getModelClusters({ modelId, token, signout }) {
  return request({
    endpoint: `/model/cluster/${modelId}`,
    token,
    signout,
  })
}

export async function trainCluster({
  modelId,
  clusterId = null,
  neighbours,
  minSize,
  columnsIgnored,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/train-cluster/${modelId}?from_gsheet=false${
      clusterId ? `&clustering_model_id=${clusterId}` : ''
    }`,
    method: 'POST',
    contentType: 'json',
    body: {
      n_neighbors: neighbours,
      min_cluster_size: minSize,
      columns_to_ignore: columnsIgnored,
    },
    token,
    signout,
  })
}

export async function transformCluster({ modelId, clusterId, token, signout }) {
  return request({
    endpoint: `/model/cluster/transform/${modelId}/${clusterId}`,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function clusterCSV({ modelId, clusterId, token, signout }) {
  return request({
    endpoint: `/model/cluster/csv/${modelId}/${clusterId}`,
    token,
    signout,
    rawResponse: true,
  })
}

export async function clusterPredictPoints({
  modelId,
  prediction = { header: [], rows: [] },
  clusterId,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/cluster/predict/${modelId}/${clusterId}?as_df=True&from_gsheet=false`,
    method: 'POST',
    body: prediction,
    contentType: 'json',
    token,
    signout,
  })
}

export async function kmeansTrain({
  modelId,
  columnsToIgnore,
  nClusters,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/kmeans/${modelId}?from_gsheet=false`,
    method: 'POST',
    contentType: 'json',
    body: {
      columns_to_ignore: columnsToIgnore,
      n_clusters: nClusters,
    },
    token,
    signout,
  })
}

export async function getKmeans({ modelId, token, signout }) {
  return request({
    endpoint: `/model/kmeans/${modelId}`,
    token,
    signout,
  })
}

export async function transformKmeans({ modelId, token, signout }) {
  return request({
    endpoint: `/model/kmeans/transform/${modelId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function updateModelDataset({
  modelId,
  columnsToDelete,
  columnsTypesToUpdate,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/update_dataset/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: {
      delete_columns: columnsToDelete,
      type_columns: columnsTypesToUpdate,
    },
    token,
    signout,
  })
}

export async function generateMMMPresentation({ modelId, token, signout }) {
  return request({
    endpoint: `/model/generate-mmm-presentation/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function regenerateDescription({
  modelId,
  initialPrompt = null,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/ai-html-description/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: {
      initial_prompt: initialPrompt,
    },
    token,
    signout,
  })
}

export async function gptSpecificTask({
  modelId,
  taskType,
  method = 'GET',
  token,
  signout,
  ...props
}) {
  return request({
    endpoint: `/model/gpt-specific-task/${modelId}?task_type=${taskType}`,
    method,
    token,
    signout,
    ...props,
  })
}

export async function generateClusteringSuggestions({
  modelId,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/generate-clustering-suggestions/${modelId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function gptGenerateProblems({ modelId, prompt, token, signout }) {
  const query = prompt ? new URLSearchParams({ prompt }).toString() : ''
  return request({
    endpoint: `/model/gpt-problems/${modelId}${query ? `?${query}` : ''}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function gptSetColumnsDescription({
  modelId,
  columns,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/gpt-columns-description/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: columns,
    token,
    signout,
  })
}

export async function gptSetGPTSuggestedColumns({
  modelId,
  columns,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/gpt-suggested-columns/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: columns,
    token,
    signout,
  })
}

export async function confirmModel({
  modelId,
  dataCleaningtasks,
  columnToDelete,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/confirm-import/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: {
      data_cleaning_tasks: dataCleaningtasks,
      columns_to_delete: columnToDelete,
    },
    token,
    signout,
  })
}

export async function selectGptProblem({ modelId, problems, token, signout }) {
  return request({
    endpoint: `/model/select-gpt-problem/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: { problems },
    token,
    signout,
  })
}

export async function statisticalSignificance({ modelId, token, signout }) {
  return request({
    endpoint: `/model/statistical-significance/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getSyntheticSummary({ modelId, token, signout }) {
  return request({
    endpoint: `/model/synthetic-data-summary/${modelId}`,
    token,
    signout,
  })
}

export async function getSyntheticData({ modelId, token, signout }) {
  return request({
    endpoint: `/model/get-synthetic-data/${modelId}`,
    rawResponse: true,
    token,
    signout,
  })
}

export function requestSyntheticData({ modelId, token, signout }) {
  return request({
    endpoint: `/model/generate-synthetic-data/${modelId}`,
    token,
    signout,
  })
}

export function getPearson({ modelId, token, signout }) {
  return request({
    endpoint: `/model/pearson/${modelId}`,
    token,
    signout,
  })
}

export function getForecastTrend({ modelId, token, signout }) {
  return request({
    endpoint: `/model/forecast_trend/${modelId}`,
    token,
    signout,
  })
}

export async function getModelSummary({ modelId, token, signout }) {
  return request({
    endpoint: `/model/column_summary/${modelId}`,
    token,
    signout,
  })
}

export async function getModelDeletedColumns({ modelId, token, signout }) {
  return request({
    endpoint: `/model/columns_deleted/${modelId}`,
    token,
    signout,
  })
}

export async function getModelHighCorrelation({
  modelId,
  target,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/detect-high-correlation/${modelId}?target=${encodeURIComponent(
      target,
    )}`,
    token,
    signout,
  })
}

export async function generateCounterfactuals({
  modelId,
  prediction,
  notChangeableColumns,
  predictionRange,
  desiredClass,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/counterfactuals_no_bg/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: {
      prediction,
      not_changable_columns: notChangeableColumns,
      prediction_range: predictionRange,
      desired_class: desiredClass,
    },
    token,
    signout,
  })
}

export async function trainMultivariableForecast({
  modelId,
  data,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/train-arima/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: data,
    token,
    signout,
  })
}

export async function previewMultivariableForecast({
  modelId,
  data,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/preview-arima/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: data,
    token,
    signout,
  })
}

export async function fillEmptyValues({
  modelId,
  column,
  useSynthetic,
  confirm = false,
  useMode = false,
  useMean = false,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/fill-empty-values/${modelId}?column_to_fill=${column}&confirm=${confirm}&use_synthetic=${useSynthetic}&use_mode=${useMode}&use_mean=${useMean}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function assistantQuestion({
  modelId,
  prompt,
  responseFormat = 'json',
  key = null,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/assistant-question/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: {
      prompt,
      response_format: responseFormat,
      key,
    },
    token,
    signout,
  })
}

export async function getAssistantResponse({ modelId, key, token, signout }) {
  return request({
    endpoint: `/model/user-assistant-response/${modelId}/${encodeURIComponent(
      key,
    )}`,
    token,
    signout,
  })
}

export async function editAssistantResponse({
  modelId,
  key,
  data,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/user-assistant-response/${modelId}/${encodeURIComponent(
      key,
    )}`,
    method: 'POST',
    contentType: 'json',
    body: data,
    token,
    signout,
  })
}

export async function getModelRepository({ modelId, token, signout }) {
  return request({
    endpoint: `/model/get_repo/${modelId}`,
    token,
    signout,
  })
}

export async function setModelRepository({
  modelId,
  repositoryId,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/set_repo/${modelId}${
      repositoryId ? `?repository_id=${repositoryId}` : ''
    }`,
    method: 'POST',
    token,
    signout,
  })
}

export async function launchCausalInference({
  modelId,
  target,
  treatment,
  confounders,
  specificGraph,
  refuteAlgorithms,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/causal-inference/${modelId}?target=${target}&treatment=${treatment}`,
    method: 'POST',
    contentType: 'json',
    body: {
      specific_graph: specificGraph,
      confounders,
      refute_algorithms: refuteAlgorithms,
    },
    token,
    signout,
  })
}

export async function sixSigmaGenerateReport(modelId, data, token, signout) {
  return request({
    endpoint: `/modules/sixsigma/generate-report/${modelId}`,
    method: 'POST',
    contentType: 'json',
    body: { ...data },
    ignoreOddStatus: false,
    token,
    signout,
  })
}

export async function downloadReport({ modelId, token, signout }) {
  return request({
    endpoint: `/modules/sixsigma/report/${modelId}`,
    rawResponse: true,
    method: 'GET',
    token,
    signout,
  })
}

export async function downloadSummaryReport({ modelId, token, signout }) {
  return request({
    endpoint: `/modules/sixsigma/summary-report/${modelId}`,
    rawResponse: true,
    method: 'GET',
    token,
    signout,
  })
}

export async function getCausalFinalGraph({
  modelId,
  target,
  treatment,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/final-graph/${modelId}?target=${target}&treatment=${treatment}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function detectConfounders({ modelId, target, token, signout }) {
  return request({
    endpoint: `/model/detect-confounders/${modelId}?target=${target}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getROCAUCCurve({ modelId, token, signout }) {
  return request({
    endpoint: `/model/roc-auc/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getModelDataQualityRules({ modelId, token, signout }) {
  return request({
    endpoint: `/model/data-quality-rules/${modelId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function updateModelDataQualityRules({
  modelId,
  rules,
  token,
  signout,
}) {
  return request({
    endpoint: `/model/data-quality-rules/${modelId}`,
    method: 'POST',
    body: rules,
    contentType: 'json',
    token,
    signout,
  })
}
