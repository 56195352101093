import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RiErrorWarningFill } from 'react-icons/ri'
import { actionConfig } from './config'

export default function InvalidWarning({ node }) {
  const { t } = useTranslation()

  if (node?.valid || node?.type === 'Config') return <></>
  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement={'top'}
      delay={{ show: 100, hide: 200 }}
      overlay={(props) => (
        <Tooltip
          {...props}
          className={`context-menu-column-type ${props?.className ?? ''}`}
        >
          {actionConfig[node?.type] ? (
            <span className="">
              {t(
                'Your node is not configured, add missing connections and click on it to configure',
              )}
            </span>
          ) : (
            <span className="">
              {t('Your node is not configured, click on it to configure')}
            </span>
          )}
        </Tooltip>
      )}
    >
      <div className="datasource-flow-warning-node-invalid px-0">
        <RiErrorWarningFill color="#dd2222" size={45} />
      </div>
    </OverlayTrigger>
  )
}
