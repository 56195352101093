import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useSearchParams } from 'react-router-dom'

import AccountPlan from '../profile/AccountPlan'
import Marketplace from './Marketplace'

import '../profile/Account.css'

export default function Plan({ setTitle = () => {}, ...props }) {
  const { t } = useTranslation()
  const auth = useAuth()
  const { token, user } = auth
  const [searchParams, setSearchParams] = useSearchParams()
  const initialTab = parseInt(searchParams.get('tab') ?? 0)
  const [index, setIndex] = useState(initialTab ?? 0)

  useEffect(() => {
    if (searchParams.get('tab')) {
      setIndex(parseInt(searchParams.get('tab')) || 0)
    }
  }, [searchParams])

  return (
    <Tabs
      selectedIndex={index}
      onSelect={(newIndex) => {
        setIndex(newIndex)
        searchParams.tab = newIndex
        setSearchParams({ ...searchParams })
      }}
    >
      <Row
        {...props}
        className={`g-0 ${props.className ?? ''} justify-content-center`}
      >
        <Col className="d-none" xs={12} md={10} xl={9}>
          <TabList className="tabs-profile ps-0">
            <Tab>{t('Plan')}</Tab>
            <Tab>{t('Plugins')}</Tab>
          </TabList>
        </Col>
        <Col
          className="account-content-column px-5 my-3"
          xs={12}
          md={10}
          xl={9}
        >
          <TabPanel>
            <AccountPlan setTitle={setTitle} token={token} user={user} />
          </TabPanel>
          <TabPanel>
            <Marketplace setTitle={setTitle} />
          </TabPanel>
        </Col>
      </Row>
    </Tabs>
  )
}
