import { OverlayTrigger, Popover, Row, Col, Tooltip } from 'react-bootstrap'
import { BsInfoCircle, BsExclamationCircle } from 'react-icons/bs'
import { RiShareBoxFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { toggleArticle } from '../../util/crisp'

const DISABLED_CRISP = process.env?.['REACT_APP_DISABLECRISP'] === '1'

export default function HelpTooltip({
  message,
  iconProps = { size: 18 },
  warning = false,
  helpLink,
  tooltip = false,
  ...props
}) {
  const { t } = useTranslation()
  const gotoHelp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (DISABLED_CRISP) return
    toggleArticle(helpLink)
  }
  const HelpComponent = tooltip ? Tooltip : Popover

  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="auto"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <HelpComponent
          {...props}
          className={`popover-help-tooltip ${props.className ?? ''}`}
          style={{ ...(props.style ?? {}), zIndex: 99999 }}
        >
          <Row className="py-4 pe-4 ps-3 ms-1">
            <Col xs={12}>
              {message === 'generate synthetic' ? (
                <Row>
                  <Col xs={12}>
                    {t('Tooltip generate synthetic') ?? message}
                  </Col>
                </Row>
              ) : typeof message === 'object' ? (
                message
              ) : (
                t(`Tooltip ${message}`, message) ?? message
              )}
            </Col>
          </Row>
        </HelpComponent>
      )}
    >
      {helpLink ? (
        <span
          onClick={gotoHelp}
          {...props}
          className={`${
            props.className ?? ''
          } original help-tooltip p-2 link help-tooltip-with-link`}
        >
          <BsInfoCircle {...iconProps} />
          <RiShareBoxFill {...iconProps} />
        </span>
      ) : (
        <span
          {...props}
          className={`${props.className ?? ''} help-tooltip p-2}`}
        >
          {warning ? (
            <BsExclamationCircle
              color={'var(--nextbrain-tables-typography-red)'}
              {...iconProps}
            />
          ) : (
            <BsInfoCircle {...iconProps} />
          )}
        </span>
      )}
    </OverlayTrigger>
  )
}
