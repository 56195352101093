import React, {
  useRef,
  forwardRef,
  useState,
  useMemo,
  useEffect,
  Fragment,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Modal,
  Image,
  Popover,
} from 'react-bootstrap'
import { config } from '../../Constants'
import { NotificationManager } from 'react-notifications'
import ReactPaginate from 'react-paginate'
import MarkdownView from 'react-showdown'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { solarizedDark } from '@uiw/codemirror-theme-solarized'
import { useQuery, useQueryClient } from 'react-query'
import Lightbox from 'react-image-lightbox'
import TextareaAutosize from 'react-textarea-autosize'
import $ from 'jquery'

import { BsTerminal, BsFillEyeFill } from 'react-icons/bs'
import { CiMenuBurger } from 'react-icons/ci'
import { RiRefreshFill } from 'react-icons/ri'
import './bot.css'
import flowGraph from '../../assets/images/state_machine.png'
import flowGraphRag from '../../assets/images/state_machine_rag.png'
import { useDebouncedCallback } from 'use-debounce'

import {
  FaUser,
  FaHistory,
  FaPlus,
  FaRocketchat,
  FaCopy,
  FaArrowDown,
  FaLightbulb,
  FaFilePdf,
  FaFileCode,
  FaFileDownload,
  FaRegFileCode,
  FaTrash,
  FaTools,
  FaChevronRight,
  FaShareAlt,
  FaFileCsv,
  FaRegFilePowerpoint,
  FaExpandArrowsAlt,
  FaCompress,
  FaPencilAlt,
  FaChevronLeft,
  FaDotCircle,
  FaRegArrowAltCircleDown,
  FaAddressCard,
  FaCheck,
  FaFile,
  FaExclamation,
  FaTag,
  FaHashtag,
  FaRegFileWord,
  FaPython,
  FaSpinner,
} from 'react-icons/fa'
import { GoTriangleRight } from 'react-icons/go'
import { TiDelete } from 'react-icons/ti'
import { VscDebugDisconnect } from 'react-icons/vsc'
import { SiMicrosoftexcel } from 'react-icons/si'
import { MdSend, MdDelete, MdEmail } from 'react-icons/md'
import { GrStatusUnknown } from 'react-icons/gr'
import { RiShareBoxFill } from 'react-icons/ri'

import useAutogen, { iconMap, matchCommunication } from './autogen'
import { BouncyLoader } from '../utils/ui'
import { useAuth } from '../../providers/AuthProvider'
import { useNav } from '../../providers/NavProvider'
import Loading from '../loading/LoadingSmall'
import { regenerateSuggestions } from '../../services/model'
import { useModels } from '../../providers/ModelProvider'
import { splitByCodeSections } from '../../util/other'
import CrashFallback from '../crash-fallback/CrashFallback'
import ErrorNB from '../loading/ErrorNB'
import ColumnPaginatedGridTable from '../grid-table/ColumnPaginatedGridTable'
import { EditContext } from '../model-content/model-assistant/ModelAssistant'
import {
  downloadDocument,
  getCompletion,
  getDocumentChunks,
  getSingleDocument,
} from '../../services/document'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { awaitTask } from '../../services/base'
import RoleDisable from '../utils/RoleDisable'

const IS_DEV =
  (process.env.REACT_APP_MODE || process.env.NODE_ENV) === `development`

const EXPLAIN_MSG =
  'Explain each step to obtain the result and its rationale, tailored for a non-programmer.'

function SuccessExit({ ...props }) {
  return (
    <span style={{ color: 'var(--nextbrain-success-color)' }} {...props}>
      Successful execution <FaCheck />
    </span>
  )
}

function ErrorExit({ retry, ...props }) {
  return (
    <span style={{ color: 'var(--nextbrain-link-color-2)' }} {...props}>
      Code execution, retrying
      {retry || retry === 0 ? ` attempt ${retry}/10` : ''}
      <FaPython className="ms-2" size={20} />
    </span>
  )
}

function CitationIcon({ file, size = 22, DefaultItem = FaFile, ...props }) {
  const extension = file?.split('.')?.pop()

  switch (extension) {
    case 'pdf':
      return <FaFilePdf size={size} {...props} />
    case 'csv':
      return <FaFileCsv size={size} {...props} />
    case 'xlsx':
      return <SiMicrosoftexcel size={size} {...props} />
    case 'pptx':
      return <FaRegFilePowerpoint size={size} {...props} />
    case 'docx':
    case 'doc':
      return <FaRegFileWord size={size} {...props} />
    default:
      return <DefaultItem size={size} {...props} />
  }
}

function CitationChunkDisplay({ file, documentId, chunks, onClose, ...props }) {
  const contentRef = useRef()
  useEffect(() => {
    const target = contentRef.current
    setTimeout(() => {
      target?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 200)
  }, [])
  const { token, signout } = useAuth()
  const { data: document, isLoading: isLoadingDocument } = useQuery(
    ['citation-document', documentId],
    async () => {
      return await getSingleDocument({
        documentId: documentId,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const { data: dataChunks, isLoading: isLoadingChunks } = useQuery(
    ['citation-document-chunks', document, chunks],
    async () => {
      if (!document?.id) return []
      return await getDocumentChunks({
        repositoryId: document.repository_id,
        map: {
          [`${document?.id}`]: chunks,
        },
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const items = Object.values(Object.values(dataChunks ?? {})?.[0] ?? {}).flat()

  const [activeItems, setActiveItems] = useState({})

  return (
    <div className="w-100 position-relative overflow-hidden" ref={contentRef}>
      <Row className="mx-5 w-auto pt-2 pb-4 m-3 container-chunks-citation-autogen w-100">
        <Col xs={12} className="mb-2 d-inline-flex justify-content-between">
          <span style={{ fontSize: '13px' }}>
            References to {file}
            <FaFileDownload
              className="icon-btn ms-1"
              size={15}
              onClick={async () => {
                const document = await getSingleDocument({
                  documentId: documentId,
                  token,
                  signout,
                })
                if (document.type === 'web') {
                  if (document?.metadata?.url)
                    window.open(document.metadata.url, '_blank')
                  else
                    NotificationManager.error('No URL found for this document')

                  return
                }

                if (documentId)
                  downloadDocument({
                    documentId: documentId,
                    token,
                    signout,
                  })
                    .then((response) => {
                      if (response.status !== 200)
                        throw new Error('Error downloading file')
                      return response.blob()
                    })
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]))
                      const link = window.document.createElement('a')
                      link.href = url
                      link.setAttribute('download', file)
                      window.document.body.appendChild(link)
                      link.click()
                      link.parentNode.removeChild(link)
                    })
                    .catch((e) => {
                      NotificationManager.error('Error downloading file')
                    })
              }}
            />
          </span>
          <IoCloseCircleSharp
            size={25}
            className="icon-btn"
            onClick={onClose}
          />
        </Col>
        <Col xs={12}>
          {isLoadingChunks || isLoadingDocument ? (
            <Loading />
          ) : (
            <Row>
              {items.map((chunk, i) => (
                <Col
                  key={i}
                  xs={12}
                  className="chunk-citation my-2 cursor-pointer user-select-none p-2 py-1 position-relative overflow-auto"
                  style={{
                    maxWidth: 'calc(100% - 40px)',
                    marginLeft: '20px',
                  }}
                  onClick={(e) => {
                    const target = e.currentTarget
                    if (!activeItems[i]) {
                      setTimeout(() => {
                        target?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }, 200)
                    }
                    setActiveItems((a) => ({ ...a, [i]: !a[i] }))
                  }}
                >
                  {activeItems[i] ? (
                    <MarkdownView
                      markdown={chunk}
                      options={{ tables: true, emoji: true }}
                      className="markdown-content-inline-citation p-3"
                    />
                  ) : (
                    <span
                      className="d-inline-block w-100 text-truncate color-white"
                      style={{ fontSize: '13px' }}
                    >
                      {chunk}
                    </span>
                  )}
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>
    </div>
  )
}

function Citation({ file, pages, chunks, id }) {
  file = file || ''
  pages = (pages || '')
    .split(',')
    .map((x) => parseInt(x))
    .sort((a, b) => a - b)
    .join(', ')
  chunks = chunks?.split(',') ?? []
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <label
        className="d-flex align-items-center py-1 citation-file-autogen h3 mb-0"
        title={`Pages: ${pages}`}
      >
        <span className="icon-btn" onClick={async () => setIsOpen((o) => !o)}>
          <CitationIcon file={file} className="me-1 ms-4" /> {file}
          <GoTriangleRight
            size={20}
            style={{
              transitionDuration: '0.25s',
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          />
        </span>
      </label>
      {isOpen && (
        <CitationChunkDisplay
          file={file}
          documentId={id}
          chunks={chunks}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}

function Communication({ message, date = null, ...props }) {
  const result = matchCommunication.exec(message)
  if (!result?.[1] || !result?.[1]) return message

  result[1] = result[1].trim()
  result[2] = result[2].trim()
  const colorMap = window?.currentChatState?.flowConfig?.agents ?? {}

  return (
    <>
      <Row
        {...props}
        className={` ${
          props?.className ?? ''
        } d-inline-flex align-items-center justify-content-between w-100 communication-message-autogen  px-2`}
      >
        <Col xs="auto" className="d-inline-flex ">
          <span style={{ color: colorMap?.[result[1]]?.color ?? 'auto' }}>
            {result[1]}
          </span>
          <Row style={{ color: colorMap?.[result[1]]?.color ?? 'auto' }}>
            <Col className="dflex-center" xs={12}>
              <FaChevronRight size={10} className="mx-2" color="white" />
            </Col>
          </Row>
          <span style={{ color: colorMap?.[result[2]]?.color ?? 'auto' }}>
            {result[2]}
          </span>
        </Col>
        {date && (
          <Col
            xs="auto"
            className="d-inline-flex show-on-hover align-items-center"
          >
            {date}
          </Col>
        )}
      </Row>
    </>
  )
}

const MarkdownComponents = {
  SuccessExit,
  ErrorExit,
  Communication,
  Citation,
}

function AccordionAutogen({ elements = [], ...props }) {
  const containerRef = useRef()
  const [position, setPosition] = useState(0)

  const update = (i) => {
    if (i >= 0 && i < elements.length) {
      setPosition(i)
    }
  }

  return (
    <Row className={`accordion-autogen ${props?.className ?? ''}`}>
      <Col xs={12}>
        <Row
          className="flex-row flex-nowrap position-relative overflow-hidden"
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
          ref={containerRef}
        >
          <FaChevronLeft
            size={40}
            className={`accordion-autogen-left icon-btn ${
              position === 0 ? 'disabled-aal' : ''
            }`}
            onClick={() => update(position - 1)}
          />
          <FaChevronRight
            size={40}
            className={`accordion-autogen-right icon-btn ${
              position === elements.length - 1 ? 'disabled-aar' : ''
            }`}
            onClick={() => update(position + 1)}
          />
          {elements.map((e, i) => (
            <Col
              xs={12}
              className={`autogen-accordion-item autogen-accordion-item-${i} px-0`}
              key={i}
              style={{
                marginLeft: `-${i === 0 ? position * 100 : 0}%`,
                maxHeight: i === position ? '100%' : '100%',
                pointerEvents: i === position ? 'auto' : 'none',
                opacity: i === position ? 1 : 0,
              }}
            >
              {e}
            </Col>
          ))}
        </Row>
      </Col>
      <Col xs={12}>
        <Row className="justify-content-center">
          {elements.map((_, i) => (
            <span className="px-0" style={{ width: '33px' }} key={i}>
              <FaDotCircle
                className={`d-inline-block pe-2 ${
                  i === position ? 'pe-none' : 'icon-btn'
                } `}
                onClick={() => setPosition(i)}
                size={30}
                color={
                  i === position
                    ? 'var(--nextbrain-main-color)'
                    : 'var(--nextbrain-secondary)'
                }
              />
            </span>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

function SessionButton({ session, active, onDelete, ...props }) {
  return (
    <Row
      className={`autogen-bot-session flex-nowrap  justify-content-center mx-1 rounded ${
        active ? 'active-session-autogen' : ''
      }`}
      {...props}
    >
      <Col xs={active ? 12 : 10} className="d-inline-block text-truncate">
        <FaChevronRight className="indicator-selected-session" />
        {session?.short_content ||
          new Date(session.timestamp).toLocaleTimeString('defailt', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour12: false,
          })}
      </Col>
      {!active && (
        <Col xs={2}>
          <RoleDisable
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <FaTrash
              className="icon-btn button-delete-session-autogen"
              size={20}
              onClick={(e) => {
                e.stopPropagation()
                onDelete()
              }}
            />
          </RoleDisable>
        </Col>
      )}
    </Row>
  )
}

function matchDate(date, markers, t) {
  if (markers.length && markers[0][0] > date) {
    const separator = (
      <span className={`session-timeline-separator  pt-2`}>
        {t(markers[0][1])}
      </span>
    )
    markers.shift()
    return matchDate(date, markers, t) || separator
  }
}

function SessionManager({
  sessions,
  currentSession,
  changeSession,
  deleteSession,
}) {
  const { t } = useTranslation()

  const dateObj = new Date()
  const month = dateObj.getUTCMonth()
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()
  const base = new Date(year, month, day).getTime()
  const markers = [
    [dateObj.getTime(), 'Today'],
    [base, 'Yesterday'],
    [base - 1000 * 60 * 60 * 24, 'Last 30 days'],
  ]

  return (
    <Row
      className="position-relative flex-column flex-nowrap"
      style={{ maxWidth: '320px', maxHeight: '300px' }}
    >
      <Col
        xs={12}
        className="d-flex flex-column flex-no-wrap"
        style={{
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {sessions?.map((s) => {
          const active = s.session_id === currentSession?.session_id
          if (!active && !s?.short_content)
            return <React.Fragment key={s.session_id}></React.Fragment>

          const date = new Date(s.timestamp).getTime()
          const separator =
            s.session_id === currentSession?.session_id
              ? null
              : matchDate(date, markers, t)
          return (
            <Fragment key={s.session_id}>
              {separator}
              <SessionButton
                session={s}
                active={active}
                onDelete={() => deleteSession(s.session_id)}
                onClick={() => changeSession(s)}
              />
            </Fragment>
          )
        })}
      </Col>
    </Row>
  )
}

function PopupMenu({
  model,
  botIsThinking,
  botIsRegeneratingSuggestions,
  defaultSuggestions,
  onShowSuggestions,
  showSkills,
  onClear,
  onRegenerate,
  onRegenerateTransform,
  sessions,
  currentSession,
  changeSession,
  deleteSession,
  newSession,
  setShowContext,
  onEditTags,
  ...props
}) {
  const { t } = useTranslation()
  const [showSessions, setShowSessions] = useState(false)
  return (
    <Row
      className={`px-2 pb-2 justify-content-start popup-controls-autogen position-relative ${
        props?.className ?? ''
      }`}
    >
      {showSessions && (
        <>
          <div className="autogen-chat-session-manager">
            <SessionManager
              sessions={sessions}
              currentSession={currentSession}
              changeSession={changeSession}
              deleteSession={deleteSession}
            />
          </div>
          <div className="autogen-chat-session-manager-arrow" />
        </>
      )}
      <Col className="px-0 mt-1 mb-4 public-autogen-chat-hidden" xs={12}>
        <Button
          className="w-100 dflex-center"
          disabled={!model}
          onClick={() => {
            newSession()
            document.body.click()
          }}
        >
          <FaPlus className="me-2" size={20} /> {t('New chat')}
        </Button>
      </Col>
      <Col
        className="mb-2 pe-0 public-autogen-chat-hidden"
        xs={12}
        onMouseEnter={() => setShowSessions(true)}
      >
        <div
          className={`d-flex justify-content-start align-items-center cursor-pointer position-relative ${
            !model ? 'text-secondary' : 'text-white focusable-element'
          }`}
          onClick={() => setShowSessions((s) => !s)}
        >
          <Button
            className={`rounded-circle original p-1 d-inline-block autogen-btn ${
              !model ? 'text-secondary' : 'text-white'
            }`}
            variant={'none'}
            disabled={!model}
          >
            <FaRocketchat style={{ marginLeft: '2px' }} size={22} />
          </Button>
          <span className="ms-2 text-start">{t('Manage chats')}</span>
          <FaChevronRight />
        </div>
      </Col>
      <RoleDisable>
        <Col className="mb-2 public-autogen-chat-hidden" xs={12}>
          <div
            className={`d-flex justify-content-start align-items-center cursor-pointer ${
              botIsThinking || !model
                ? 'text-secondary'
                : 'text-white focusable-element'
            }`}
            onClick={() => {
              if (botIsThinking || !model) return
              onClear()
              document.querySelector('body').click()
            }}
          >
            <Button
              className={`rounded-circle original p-1 d-inline-block autogen-btn ${
                botIsThinking || !model ? 'text-secondary' : 'text-white'
              }`}
              variant={'none'}
              disabled={botIsThinking || !model}
            >
              <MdDelete size={22} />
            </Button>
            <span className="ms-2 text-start">{t('Clear chat')}</span>
          </div>
        </Col>
      </RoleDisable>
      <Col className="mb-2 hide-rag" xs={12}>
        <div
          className={`d-flex justify-content-start align-items-center cursor-pointer ${
            botIsThinking || !model || botIsRegeneratingSuggestions
              ? 'text-secondary'
              : 'text-white focusable-element'
          }`}
          onClick={() => {
            if (botIsThinking || !model || botIsRegeneratingSuggestions) return
            if (!Object.keys(defaultSuggestions)?.length) {
              NotificationManager.info(
                <span className="loading-tooltip">
                  {t('Suggestions are being generated')}
                </span>,
              )
              return
            }
            onShowSuggestions()
            document.querySelector('body').click()
          }}
        >
          <Button
            className={`rounded-circle original p-1 d-inline-block hide-rag autogen-btn ${
              botIsThinking || !model || botIsRegeneratingSuggestions
                ? 'text-secondary'
                : 'text-white'
            }`}
            variant={'none'}
            disabled={botIsThinking || !model || botIsRegeneratingSuggestions}
          >
            <FaLightbulb size={22} />
          </Button>
          <span className="ms-2 text-start">{t('Show suggestions')}</span>
        </div>
      </Col>
      <Col className="mb-2 d-none" xs={12}>
        <div
          className={`d-flex justify-content-start align-items-center cursor-pointer autogen-btn ${
            botIsThinking || !model || botIsRegeneratingSuggestions
              ? 'text-secondary'
              : 'text-white focusable-element'
          }`}
          onClick={() => {
            if (botIsThinking || !model || botIsRegeneratingSuggestions) return
            showSkills()
            document.querySelector('body').click()
          }}
        >
          <Button
            className={`rounded-circle original p-1 d-inline-block autogen-btn ${
              botIsThinking || !model || botIsRegeneratingSuggestions
                ? 'text-secondary'
                : 'text-white'
            }`}
            disabled={botIsThinking || !model || botIsRegeneratingSuggestions}
            variant={'none'}
          >
            <FaTools size={22} />
          </Button>
          <span className="ms-2 text-start">{t('Show my skills')}</span>
        </div>
      </Col>
      <Col className="mb-2 hide-rag d-none" xs={12}>
        <div
          className={`d-flex justify-content-start align-items-center cursor-pointer autogen-btn ${
            botIsThinking || !model || botIsRegeneratingSuggestions
              ? 'text-secondary'
              : 'text-white focusable-element'
          }`}
          onClick={() => setShowContext(true)}
        >
          <Button
            className={`rounded-circle original p-1 d-inline-block autogen-btn text-white`}
            variant={'none'}
          >
            <FaAddressCard size={22} />
          </Button>
          <span className="ms-2 text-start">{t('Model context')}</span>
        </div>
      </Col>
      <RoleDisable>
        <Col className="hide-rag" xs={12}>
          <div
            className={`d-flex justify-content-start align-items-center cursor-pointer ${
              botIsRegeneratingSuggestions || botIsThinking || !model
                ? 'text-secondary'
                : 'text-white focusable-element'
            }`}
            onClick={() => {
              if (botIsRegeneratingSuggestions || botIsThinking || !model)
                return
              onRegenerate()
              onRegenerateTransform()
              document.querySelector('body').click()
            }}
          >
            <Button
              className={`rounded-circle original p-1 d-inline-block autogen-btn ${
                botIsRegeneratingSuggestions ? 'regenerating-suggestions' : ''
              } ${
                botIsRegeneratingSuggestions || botIsThinking || !model
                  ? 'text-secondary'
                  : 'text-white'
              }`}
              variant={'none'}
              disabled={botIsRegeneratingSuggestions || botIsThinking || !model}
            >
              <RiRefreshFill size={22} />
            </Button>
            <span className="ms-2 text-start">
              {t('Regenerate suggestions')}
            </span>
          </div>
        </Col>
      </RoleDisable>
      {onEditTags && (
        <Col xs={12}>
          <div
            className={`d-flex justify-content-start align-items-center cursor-pointer`}
            onClick={() => {
              onEditTags()
              document.querySelector('body').click()
              document.querySelector('.input-chat')?.focus()
            }}
          >
            <Button
              className={`rounded-circle original p-1 d-inline-block autogen-btn`}
              variant={'none'}
            >
              <FaTag size={22} color="white" />
            </Button>
            <span className="ms-2 text-start">{t('Edit tags')}</span>
          </div>
        </Col>
      )}
    </Row>
  )
}

const TagContextMenu = forwardRef(
  (
    { tags, content, setContent, selectedTags, setSelectedTags, ...props },
    ref,
  ) => {
    const doSearch = content.startsWith('#')
    const { t } = useTranslation()
    const [selected, setSelected] = useState(0)
    useEffect(() => setSelected(0), [content])

    useEffect(() => {
      if (doSearch) {
        const onClick = (e) => {
          setContent('')
        }
        document.querySelector('body').addEventListener('click', onClick)
        return () =>
          document.querySelector('body').removeEventListener('click', onClick)
      }
      // eslint-disable-next-line
    }, [doSearch])

    if (!doSearch) return null

    const search = content.slice(1).toLowerCase()
    const filteredTags = tags.filter(
      ({ name: tag }) =>
        tag.toLowerCase().includes(search) &&
        !selectedTags.some((v) => v.name === tag),
    )

    return (
      <Row className={`ms-0 w-100 ${props?.className ?? ''}`}>
        {filteredTags?.length ? (
          <Col className="position-relative px-0" xs={12}>
            <fieldset className="px-0 w-100 context-menu-tags-autogen-bot border-theme">
              {filteredTags.map((tag, i) => (
                <Fragment key={`${tag?.name}-${i}`}>
                  <div
                    className="input-tag-radio"
                    onMouseEnter={() => {
                      setSelected(i)
                    }}
                  >
                    <input
                      type="radio"
                      id={tag?.name}
                      name={'select-current-tag'}
                      value={tag?.name}
                      onChange={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setSelected(i)
                        document
                          ?.querySelector(
                            `.input-tag-radio:nth-child(${i + 1})`,
                          )
                          ?.scrollIntoView()
                      }}
                    />
                    <label
                      className={`${
                        i === selected ? 'active-tag-select' : ''
                      } py-1 ps-2 h-100 cursor-pointer user-select-none`}
                      htmlFor={tag?.name}
                      onClick={() => {
                        setSelectedTags((t) => [...t, tag])
                      }}
                    >
                      <strong className="me-1">#</strong>
                      <i style={{ fontSize: '13px' }} className="color-white">
                        {tag?.name}
                      </i>
                      <div
                        className="d-flex align-items-start"
                        style={{ fontSize: '11px' }}
                      >
                        <strong
                          className="me-1 invisible d-inline-block"
                          style={{ maxHeight: '0px' }}
                        >
                          #
                        </strong>
                        {tag?.type}
                      </div>
                    </label>
                  </div>
                </Fragment>
              ))}
            </fieldset>
          </Col>
        ) : (
          <fieldset className="px-0 w-100 context-menu-tags-autogen-bot border-theme">
            <Col
              className="w-100 d-flex align-items-center justify-content-center h5"
              xs={12}
              style={{
                minHeight: '15vh',
              }}
            >
              {t('No matching tags or files')}
            </Col>
          </fieldset>
        )}
      </Row>
    )
  },
)

function SelectedTag({ tag, onDelete }) {
  return (
    <Col
      xs="auto"
      className="m-1 me-3 selected-tag-autogen-chat position-relative"
      title={tag?.name}
    >
      <TiDelete
        className="icon-btn"
        size={35}
        style={{
          position: 'absolute',
          right: '-15px',
          top: '-10px',
          zIndex: 2,
        }}
        onClick={onDelete}
      />
      <Row className="h-100 d-inline-flex flex-nowrap w-100">
        <Col
          className="dflex-center hash-separator"
          style={{ minWidth: '70px', maxWidth: '70px' }}
        >
          <strong style={{ fontSize: '28px' }} className="pe-1">
            <CitationIcon
              file={tag.name}
              size={40}
              DefaultItem={FaHashtag}
              color="white"
            />
          </strong>
        </Col>
        <Col
          className="d-flex flex-column align-items-center position-relative justify-content-center"
          style={{
            minWidth: 'calc(100% - 70px)',
            maxWidth: 'calc(100% - 70px)',
          }}
        >
          <div
            style={{ minHeight: 'calc(100% - 30px)' }}
            className="w-100 color-white text-center py-2 d-flex align-items-center justify-content-center overflow-hidden"
          >
            <strong style={{ fontSize: '13px' }}>{tag.name}</strong>
          </div>
          <div
            className="text-start py-1"
            style={{ fontSize: '11px', minHeight: '30px' }}
          >
            {tag?.type}
          </div>
        </Col>
      </Row>
    </Col>
  )
}

function CompleteSuggestions({ suggestions, onTrigger, ...props }) {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Row {...props}>
      {suggestions.map((s, i) => (
        <Col
          title={s}
          onMouseEnter={() => setActiveIndex(i)}
          onClick={() => onTrigger(s)}
          xs={12}
          key={i}
          className={`suggestion-complete ${
            i === activeIndex ? 'focus-completion' : ''
          } py-1 cursor-pointer user-select-none d-flex align-items-center`}
        >
          <span
            className="d-inline-block text-truncate"
            style={{ paddingLeft: '8px' }}
          >
            {s}
          </span>
        </Col>
      ))}
    </Row>
  )
}

const Input = forwardRef(
  (
    {
      model,
      shareSession,
      botIsThinking,
      onMessage,
      onClear,
      defaultSuggestions,
      onShowSuggestions,
      botIsRegeneratingSuggestions,
      onRegenerate,
      onRegenerateTransform,
      sessions,
      currentSession,
      newSession,
      changeSession,
      deleteSession,
      showSkills,
      setShowContext,
      workflow,
      tags,
      state,
    },
    questionRef,
  ) => {
    const { token, signout } = useAuth()
    const { t } = useTranslation()
    const [contentReady, setContentReady] = useState(false)

    const [selectedTags, setSelectedTags] = useState([])
    const [content, setContent] = useState('')
    const [_autocomplete, setAutocomplete] = useState({})
    const autocomplete = _autocomplete?.[questionRef.current?.value?.trim()]
    const [showAutocomplete, setShowAutocomplete] = useState(false)
    const [showAutocompleteLoading, setShowAutocompleteLoading] =
      useState(false)

    const context = useMemo(() => {
      let context = ''
      if (model?.dataset?.final_column_status)
        context += `It could be related to a dataset with the following columns:: 
        ${Object.entries(model?.dataset?.final_column_status)
          .map(([c, t]) => `${c} (${t})`)
          .join(', ')}`

      return context
      // eslint-disable-next-line
    }, [])

    const previousSuggestion = useRef([])
    const [queryRequest, setQueryRequest] = useState('')

    useEffect(() => {
      setShowAutocompleteLoading(false)
      setShowAutocomplete(false)
      setQueryRequest('')
      questionRef.current.value = ''
      // eslint-disable-next-line
    }, [botIsThinking])

    useEffect(() => {
      const close = () => setShowAutocomplete(false)
      document.querySelector('body').addEventListener('click', close)
      return () =>
        document.querySelector('body').removeEventListener('click', close)
    }, [])

    const { isLoading: isGeneratingCompletion } = useQuery(
      ['completion', queryRequest],
      async () => {
        if (
          !queryRequest ||
          queryRequest?.startsWith('#') ||
          queryRequest.split(' ')?.length < 4
        )
          return

        const completion = await getCompletion({
          query: queryRequest,
          context,
          token,
          signout,
        })
        if (Array.isArray(completion) && completion.length) {
          setAutocomplete((r) => ({ ...r, [queryRequest]: completion }))
          setShowAutocomplete(true)
        }
      },
      { staleTime: Infinity },
    )

    const requestSuggestion = useDebouncedCallback((text) => {
      if (workflow === 'rag' || text.split(' ')?.length < 4) return
      if (previousSuggestion.current.some((s) => s.startsWith(text))) return
      previousSuggestion.current.push(text)
      setQueryRequest(text)
      setShowAutocompleteLoading(true)
    }, 2000)

    const parseTags = !!tags?.length
    useEffect(() => {
      setContent('')
      state.selectedTags = selectedTags
    }, [selectedTags, state])
    useEffect(() => {
      setSelectedTags([])
    }, [state?.currentSession?.session_id])

    const showingSuggestions =
      Array.isArray(autocomplete) && showAutocomplete && !botIsThinking

    return (
      <Row
        className="pb-2 w-100  autogen-input-row"
        style={{
          borderRadius: '20px',
        }}
      >
        <Col xs={12} className="my-1 position-relative pe-0">
          <Row>
            <Col className="position-relative px-0" xs={12}>
              {parseTags && (
                <Row>
                  <Col xs={12}>
                    <TagContextMenu
                      className="px-4"
                      tags={tags}
                      content={content}
                      setContent={setContent}
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                    />
                  </Col>
                </Row>
              )}
              <Form
                className={`input-chat-form ${
                  showingSuggestions ? 'showing-suggestions' : ''
                }`}
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                {showingSuggestions && (
                  <CompleteSuggestions
                    className="complete-suggestions-chat"
                    suggestions={autocomplete}
                    onTrigger={(s) => {
                      questionRef.current.value = s
                      setShowAutocomplete(false)
                      questionRef.current?.focus()
                    }}
                  />
                )}
                {parseTags && selectedTags?.length > 0 && (
                  <Row className="mx-5 py-2">
                    {selectedTags.map((tag, i) => (
                      <SelectedTag
                        key={`${tag?.name}-${i}`}
                        tag={tag}
                        onDelete={() => {
                          setSelectedTags((t) => t.filter((t) => t !== tag))
                        }}
                      />
                    ))}
                  </Row>
                )}
                <Row className="position-relative mx-2">
                  <div
                    className="px-0 h-100 d-flex align-items-center justify-content-center"
                    style={{
                      position: 'absolute',
                      left: '5px',
                      bottom: '0px',
                      zIndex: 2,
                      width: '30px',
                    }}
                  >
                    <OverlayTrigger
                      rootClose={true}
                      trigger={'click'}
                      placement="top"
                      delay={{ show: 200, hide: 0 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <div>
                            <PopupMenu
                              model={model}
                              botIsThinking={botIsThinking}
                              botIsRegeneratingSuggestions={
                                botIsRegeneratingSuggestions
                              }
                              defaultSuggestions={defaultSuggestions}
                              onShowSuggestions={onShowSuggestions}
                              showSkills={showSkills}
                              onClear={onClear}
                              onRegenerate={onRegenerate}
                              onRegenerateTransform={onRegenerateTransform}
                              sessions={sessions}
                              currentSession={currentSession}
                              changeSession={changeSession}
                              deleteSession={deleteSession}
                              newSession={newSession}
                              className={
                                (shareSession ? 'public-autogen-chat' : '') +
                                ` popup-controls-workflow-${workflow}`
                              }
                              setShowContext={setShowContext}
                              onEditTags={
                                parseTags ? () => setContent('#') : null
                              }
                            />
                          </div>
                        </Tooltip>
                      )}
                    >
                      <Button
                        className="rounded-circle original ms-2 p-1 btn-send-chat btn-main-color dflex-center w-100"
                        variant={'secondary'}
                        onClick={() => {}}
                      >
                        <CiMenuBurger
                          size={26}
                          color="white"
                          className="icon-btn"
                        />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      className="rounded-circle original p-1 btn-send-chat hide-rag justify-content-center align-items-center w-100 public-autogen-chat-visible"
                      variant={'secondary'}
                      disabled={botIsThinking || !model}
                      onClick={() => {
                        if (!Object.keys(defaultSuggestions)?.length) {
                          NotificationManager.info(
                            <span className="loading-tooltip">
                              {t('Suggestions are being generated')}
                            </span>,
                          )
                          return
                        }
                        onShowSuggestions()
                        document.querySelector('body').click()
                      }}
                      title={t('Show suggestions')}
                    >
                      <FaLightbulb size={25} />
                    </Button>
                  </div>
                  <Col
                    className=" d-inline-flex flex-nowrap position-relative overflow-hidden"
                    xs={12}
                  >
                    {isGeneratingCompletion &&
                      showAutocompleteLoading &&
                      !botIsThinking && (
                        <span //Hack to add a loader spinner at the end of the text area content
                          className="generating-completion-spin position-absolute mt-1 pe-none"
                          style={{
                            paddingLeft: '40px',
                            paddingRight: '35px',
                            resize: 'none',
                            minWidth: '100%',
                            paddingTop: '15px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {queryRequest}
                          <FaSpinner
                            className="rotating-2 ms-2"
                            color="white"
                          />
                        </span>
                      )}
                    <TextareaAutosize
                      ref={questionRef}
                      placeholder={t(
                        workflow === 'rag'
                          ? 'Ask the assistant questions related with the knowledge base'
                          : 'Ask the assistant ...',
                      )}
                      className="no-border input-chat w-100 mt-1"
                      rows={1}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (showAutocomplete) {
                            const complete =
                              document?.querySelector('.focus-completion')
                            if (complete) {
                              complete?.click()
                              e.preventDefault()
                              e.stopPropagation()
                              return
                            }
                          }
                          const tag =
                            document?.querySelector('.active-tag-select')
                          if (tag) {
                            e.preventDefault()
                            e.stopPropagation()
                            setContent('')
                            tag?.click()
                            questionRef.current.value = ''
                            return
                          }
                          if (!e.ctrlKey && !e.shiftKey) {
                            e.preventDefault()
                            e.stopPropagation()
                            if (!questionRef.current.value) return
                            onMessage(questionRef.current.value)
                            questionRef.current.value = ''
                            questionRef.current.rows = 1
                          }
                        } else if (e.key === 'ArrowUp') {
                          if (showingSuggestions) {
                            e.preventDefault()
                            e.stopPropagation()
                            const prev =
                              document?.querySelector('.focus-completion')
                                ?.previousSibling ??
                              document.querySelector(
                                '.suggestion-complete:last-child',
                              )
                            prev?.dispatchEvent(
                              new MouseEvent('mouseover', { bubbles: true }),
                            )
                          }
                          if (content?.startsWith('#')) {
                            e.preventDefault()
                            e.stopPropagation()
                            const prev = document
                              ?.querySelector('.active-tag-select')
                              ?.parentNode?.previousSibling?.querySelector(
                                'input',
                              )

                            if (prev) prev?.click()
                            else
                              document
                                ?.querySelector(
                                  '.input-tag-radio:last-child input',
                                )
                                ?.click()
                          }
                        } else if (e.key === 'ArrowDown') {
                          if (showingSuggestions) {
                            e.preventDefault()
                            e.stopPropagation()
                            const prev =
                              document?.querySelector('.focus-completion')
                                ?.nextElementSibling ??
                              document.querySelector(
                                '.suggestion-complete:first-child',
                              )
                            prev?.dispatchEvent(
                              new MouseEvent('mouseover', { bubbles: true }),
                            )
                          }
                          if (content?.startsWith('#')) {
                            e.preventDefault()
                            e.stopPropagation()
                            const next = document
                              ?.querySelector('.active-tag-select')
                              ?.parentNode?.nextElementSibling?.querySelector(
                                'input',
                              )
                            if (next) next?.click()
                            else
                              document
                                ?.querySelector(
                                  '.input-tag-radio:first-child input',
                                )
                                ?.click()
                          }
                        } else if (e.key === 'Escape') {
                          setContent('')
                          setShowAutocomplete(false)
                        }
                      }}
                      disabled={botIsThinking || (!model && !shareSession)}
                      onChange={(e) => {
                        if (parseTags) setContent(e.target.value)
                        setShowAutocomplete(false)
                        setShowAutocompleteLoading(false)
                        setQueryRequest('')
                        requestSuggestion(e.target.value.trim())
                        setContentReady(e.target.value?.length > 0)
                      }}
                      style={{
                        paddingLeft: '40px',
                        paddingRight: '35px',
                        resize: 'none',
                        minWidth: '100%',
                      }}
                    />
                  </Col>
                  <div
                    style={{
                      position: 'absolute',
                      left: 'calc(100% - 30px)',
                      bottom: '0px',
                      width: '30px',
                    }}
                    className={`${
                      !contentReady || botIsThinking || !model
                        ? 'nbbot-disabled-button'
                        : ''
                    } px-0 d-flex align-items-center h-100`}
                  >
                    <Button
                      className={`rounded-circle p-1 original btn-send-chat dflex-center w-100 btn-main-color`}
                      variant={'secondary'}
                      disabled={botIsThinking || !model || !contentReady}
                      onClick={() => {
                        if (!questionRef.current.value) return
                        onMessage(questionRef.current.value)
                        questionRef.current.value = ''
                      }}
                    >
                      <MdSend size={25} />
                    </Button>
                  </div>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  },
)

function removeInitialDefaultMessage(message) {
  if (typeof message !== 'string') return message
  const initialDefaultMsg =
    "I have share this dataset in './data.csv'. Before anything, start by loading and exploring the dataset.\n"
  if (message.startsWith(initialDefaultMsg)) {
    message = message.replace(initialDefaultMsg, '')
  }
  return message
}

function ProgressMessageFragment({ text, raw, scrollNewContent }) {
  if (typeof text !== 'string') text = ''

  const pieces = useMemo(() => text?.split(' ') ?? [], [text])
  const [counter, setCounter] = useState(1)

  useEffect(() => {
    if (!raw) {
      const steps = Math.max(1, Math.ceil(pieces.length / 110))
      const interval = setInterval(() => {
        if (counter === pieces.length) {
          clearInterval(interval)
          return
        }
        setCounter((c) => c + steps)
        scrollNewContent()
      }, 50)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [scrollNewContent])

  if (raw) return text

  return (
    <MarkdownView
      markdown={pieces.slice(0, counter).join(' ')}
      options={{ tables: true, emoji: true }}
      className="markdown-content"
      components={MarkdownComponents}
    />
  )
}

function MessageFragment({ fragment, raw }) {
  if (fragment.type === 'text')
    fragment.text = removeInitialDefaultMessage(fragment.text)
  if (raw) return fragment

  switch (fragment.type) {
    case 'code':
      return (
        <Accordion
          style={{ maxWidth: 'calc(100% - 20px)', display: 'none' }}
          className={`mt-2 animated-arrow  accordion-message-code`}
        >
          <Accordion.Item className="p-0" eventKey="0">
            <Accordion.Header className="py-0 d-flex align-items-start accordion-header-code">
              <ul className="ps-2">
                <li className="thought-bot-header-code">
                  <span>Show code</span>
                </li>
              </ul>
            </Accordion.Header>
            <Accordion.Body className="p-0 code-body">
              <CodeMirror
                value={fragment.text}
                readOnly
                editable={false}
                extensions={[python({})].filter((e) => e)}
                onChange={() => {}}
                theme={solarizedDark}
                indentWithTab
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    default:
      return (
        <MarkdownView
          markdown={fragment.text}
          options={{ tables: true, emoji: true }}
          className="markdown-content"
          components={MarkdownComponents}
        />
      )
  }
}

function GetIcon({ fileType }) {
  switch (fileType) {
    case 'code':
      return <FaFileCode size={45} />
    case 'image':
      return <></>
    case 'pdf':
      return <FaFilePdf size={45} />
    case 'excel':
      return <SiMicrosoftexcel size={45} />
    case 'csv':
      return <FaFileCsv size={45} />
    case 'ppt':
      return <FaRegFilePowerpoint size={45} />
    case 'preview':
      return <></>
    default:
      return <GrStatusUnknown size={25} />
  }
}

function download(file, data) {
  const link = document.createElement('a')
  if (typeof data === 'string')
    link.setAttribute(
      'href',
      `data:application/octet-stream;base64,${btoa(
        unescape(encodeURIComponent(data)),
      )}`,
    )
  else {
    const url = URL.createObjectURL(data)
    link.setAttribute('href', url)
    setTimeout(() => {
      URL.revokeObjectURL(url)
    }, 5000)
  }
  link.setAttribute('download', `${file.name}`)
  document.body.appendChild(link)
  link.click()
}

function DisplayFileFragment({ file, onHide, isContent, shareSession }) {
  const { token } = useAuth()
  const { data, isLoading } = useQuery(
    file.path ?? file.text,
    async () => {
      if (isContent) return file.text
      const fileData = await fetch(file.path, {
        headers: {
          Authorization: shareSession
            ? `Session ${shareSession}`
            : `Bearer ${token}`,
        },
      })
      switch (file.type) {
        case 'code':
          return await fileData.text()
        default:
          return await fileData.blob()
      }
    },
    { staleTyle: Infinity },
  )

  switch (file.type) {
    case 'code':
      return (
        <Modal show={true} onHide={onHide} size={'xl'}>
          <Modal.Header closeButton>
            <span className="h3">{file.name}</span>
            {!isLoading && (
              <Col className="ms-2" xs={12}>
                <FaFileDownload
                  className="icon-btn"
                  size={25}
                  onClick={(e) => {
                    e.target.classList.add('pe-none')
                    download(file, data)
                    e.target.classList.remove('pe-none')
                  }}
                />
              </Col>
            )}
          </Modal.Header>
          <Modal.Body>
            <Row className="p- mb-5">
              <Col xs={12}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <CodeMirror
                      value={data}
                      editable={false}
                      extensions={[python({})].filter((e) => e)}
                      onChange={() => {}}
                      theme={solarizedDark}
                      indentWithTab
                      height="50vh"
                    />
                  </>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )
    default:
      if (data) {
        download(file, data)
        onHide()
      }
      return <></>
  }
}

function ImagePreview({ file, shareSession }) {
  const [show, setShow] = useState(false)
  const { token } = useAuth()
  const { data, isLoading } = useQuery(
    file.path,
    async () => {
      const fileData = await fetch(file.path, {
        headers: {
          Authorization: shareSession
            ? `Session ${shareSession}`
            : `Bearer ${token}`,
        },
      })
      return await fileData.blob()
    },
    { staleTime: Infinity },
  )
  const url = useMemo(() => {
    if (data) return URL.createObjectURL(data)
  }, [data])

  useEffect(() => {
    if (url) return () => URL.revokeObjectURL(url)
  }, [url])

  return isLoading ? (
    <Loading imageProps={{ className: 'botimg' }} />
  ) : (
    <>
      {show && <Lightbox mainSrc={url} onCloseRequest={() => setShow(false)} />}
      <Image
        className="botimg d-block"
        style={{ maxHeight: '90px', objectFit: 'contain' }}
        src={url}
        onClick={(e) => {
          e.stopPropagation()
          setShow(true)
        }}
      />
      <div
        className="icon-btn position-absolute"
        style={{
          bottom: 0,
          left: 'calc(100% - 40px)',
          zIndex: 1,
        }}
        onClick={(e) => {
          e.stopPropagation()
          download(file, data)
        }}
      >
        <Button className="original p-1">
          <FaFileDownload size={20} />
        </Button>
      </div>
    </>
  )
}

function TransformPreview({ file, currentSession }) {
  const { t } = useTranslation()
  const { token } = useAuth()

  const { data, isLoading } = useQuery(
    ['preview-session', file.path],
    async () => {
      try {
        const response = await fetch(
          `${
            config.AUTOGEN2_URL
          }/api/preview-transform?session_id=${encodeURIComponent(
            currentSession?.session_id,
          )}&head_size=500`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ).catch((e) => {})
        const res = await response.json()
        return res
      } catch (e) {}
      return null
    },
    { staleTime: 20000 },
  )

  return isLoading ? (
    <Loading />
  ) : data?.status ? (
    <div
      className="mt-2"
      style={{
        maxWidth: '100%',
        overflow: 'auto',
      }}
    >
      <ColumnPaginatedGridTable
        className="table-view-data"
        pageSize={20}
        header={[data.data.columns]}
        rows={data.data.data /*:D*/}
        cellElement={(str) => <span className="single-line">{str}</span>}
        Pager={({ maxPage, onChange, page }) => (
          <ReactPaginate
            className="paginate-view-data"
            breakLabel="..."
            nextLabel={t('next')}
            onPageChange={({ selected }) => onChange(selected)}
            pageRangeDisplayed={3}
            pageCount={maxPage}
            previousLabel={t('previous')}
            renderOnZeroPageCount={null}
          />
        )}
        rowsPerPage={10}
        pagerLast={true}
      />
    </div>
  ) : (
    <ErrorNB />
  )
}

function TransformedConfirmation({
  applyTransform,
  currentSession,
  file,
  shareSession,
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [message, setMessage] = useState('Applying transformation')
  const { role } = useModels()

  if (!show || role !== 'editor') return <></>
  return (
    <Row className="my-3 message-transform-autogen">
      {loading ? (
        <Col xs={12} style={{ maxWidth: '450px' }}>
          <Loading message={t(message)} imageProps={{ className: 'botimg' }} />
        </Col>
      ) : (
        <>
          <Col xs={11}>
            <div className="ms-3 mt-1 mb-2">
              {t(
                'It seems like your last inquiry has created some changes in your CSV file. Do you want to keep it or ignore it?',
              )}{' '}
              <FaExclamation className="mb-1" />
            </div>
          </Col>
          <Col className="d-inline-flex" xs={12}>
            <div className="ms-2" style={{ maxWidth: '250px' }}>
              <FileFragment
                name={
                  <>
                    <BsFillEyeFill size={25} /> {t('Preview')}
                  </>
                }
                file={file}
                shareSession={shareSession}
                type={'preview'}
                onClick={() => setShowPreview(true)}
              />
            </div>
            <Button
              className="me-2 ms-5"
              disabled={loading}
              onClick={async () => {
                setLoading(true)
                await applyTransform()
                setShow(false)
                setLoading(false)
              }}
            >
              {t('Apply')}
            </Button>
            <Button
              className="original ms-1 button-cancel-tool btn btn-secondary"
              disabled={loading}
              onClick={async () => {
                setMessage('')
                setLoading(true)
                //const error =
                await applyTransform(true)
                /*if (error) {
                  NotificationManager.error(error)
                  setLoading(false)
                  return
                }*/
                setShow(false)
                setLoading(false)
              }}
            >
              {t('Ignore')}
            </Button>
          </Col>
        </>
      )}
      {showPreview && (
        <Modal
          show={showPreview}
          onHide={() => setShowPreview(false)}
          size={'xl'}
        >
          <Modal.Header closeButton>
            <div className="d-flex align-items-center">
              <BsFillEyeFill size={35} className="me-2" />
              <span className="m-0 h3">{t('Changes preview')}</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <TransformPreview file={file} currentSession={currentSession} />
          </Modal.Body>
        </Modal>
      )}
    </Row>
  )
}

function FileFragment({
  name = null,
  type = null,
  file,
  shareSession,
  onClick = null,
}) {
  const [active, setActive] = useState(false)

  return (
    <>
      <Row
        className="file-fragment-autogen flex-column mx-1 position-relative overflow-hidden"
        title={name ?? file.name}
        onClick={onClick ?? ((e) => file.type !== 'image' && setActive(true))}
      >
        {file.type === 'image' ? (
          <ImagePreview file={file} shareSession={shareSession} />
        ) : (
          <>
            <Col className="text-center smallp" xs={12}>
              <div
                className="d-inline-block text-truncate w-100 color-white"
                style={{ marginTop: 4 }}
              >
                {name ?? file.name}
              </div>
            </Col>
            {(type ?? file.type) !== 'preview' && (
              <Col className="dflex-center mt-2" xs={12}>
                <GetIcon fileType={type ?? file.type} />
              </Col>
            )}
          </>
        )}
      </Row>
      {active && (
        <DisplayFileFragment
          file={file}
          shareSession={shareSession}
          onHide={() => {
            setActive(false)
          }}
        />
      )}
    </>
  )
}

function CodeFragment({ file, isContent, shareSession }) {
  const [active, setActive] = useState(false)

  return (
    <>
      <span title={file.name} onClick={(_) => setActive(true)}>
        <BsTerminal className="me-2 terminal-icon" />
      </span>
      {active && (
        <DisplayFileFragment
          file={file}
          isContent={isContent}
          shareSession={shareSession}
          onHide={() => {
            setActive(false)
          }}
        />
      )}
    </>
  )
}

function LogsAccordion({ line, section }) {
  return (
    <Accordion
      style={{ maxWidth: 'calc(100% - 20px)' }}
      className={`mt-2 animated-arrow  accordion-message-code`}
    >
      <Accordion.Item className="p-0" eventKey="0">
        <Accordion.Header className="py-0 d-flex align-items-start accordion-header-code">
          <ul className="ps-2 mb-0">
            <li className="thought-bot-header-code">
              <FaRegFileCode className="me-1" size={25} />{' '}
              <span>Show code</span>
            </li>
          </ul>
        </Accordion.Header>
        <Accordion.Body className="p-0 code-body">
          <CodeMirror
            value={section.text}
            editable={false}
            extensions={[python({})].filter((e) => e)}
            onChange={() => {}}
            theme={solarizedDark}
            indentWithTab
            maxHeight="50vh"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

function LogsRow({ row, isCommunication, date }) {
  try {
    if (
      row.includes('exitcode: 1 (execution failed)') ||
      row.includes('<ErrorExit />')
    )
      return <ErrorExit />
    if (
      row.includes('exitcode: 0 (execution succeeded)') ||
      row.includes('<SuccessExit />')
    )
      return <SuccessExit />

    if (isCommunication)
      return (
        <>
          <Communication
            message={row}
            date={
              date && (
                <div className="communication-date-message">
                  <small className="text-secondary ">{date} </small>
                </div>
              )
            }
          />
        </>
      )
  } catch (e) {}

  return (
    <div className={`ps-4 d-inline-block`}>
      {row
        .replace('primary_assistant', 'Planner')
        .replace('userproxy', 'Developer')}
    </div>
  )
}

function LogsBody({ data, workflow }) {
  const [showAutoScroll, setShowAutoScroll] = useState(false)
  const containerRef = useRef(null)
  const bottomRef = useRef(null)

  const split = useMemo(() => data.map((d) => splitByCodeSections(d)), [data])
  const matches = useMemo(
    () => data.map((d) => matchCommunication.test(d)),
    [data],
  )

  const messageToComponent = (index, i_parent) => {
    return split[index].map((section, i) => {
      if (section.type === 'code') {
        return (
          <Row>
            <Col
              key={i}
              xs={12}
              className="ps-2 pb-2"
              style={{ maxWidth: 'calc(100% - 35px)', marginLeft: '35px' }}
            >
              <LogsAccordion section={section} />
            </Col>
          </Row>
        )
      }
      return (
        <React.Fragment key={i}>
          {section.text.split('\n').map((row, j) => {
            const index = row.indexOf(']')
            let date = null
            if (index !== -1) {
              date = row.slice(0, index + 1)
              row = row.slice(index + 2)
            }
            const isCommunication = matchCommunication.test(row)
            const isSeparator = /^-+$/.test(row)
            return (
              <React.Fragment key={j}>
                <div
                  className={`${
                    isSeparator
                      ? isCommunication
                        ? 'd-inline-flex'
                        : ''
                      : 'd-inline-block'
                  } ${j > 0 ? 'extra-terminal-line' : ''} `}
                  style={
                    isCommunication
                      ? {
                          minWidth: 'calc(100% - 20px)',
                          maxWidth: 'calc(100% - 20px)',
                        }
                      : {}
                  }
                >
                  {isSeparator ? (
                    <hr
                      style={{
                        marginLeft: 0,
                        marginRight: 15,
                        marginBottom: '-10px',
                      }}
                    />
                  ) : isCommunication ? (
                    <LogsRow
                      row={row}
                      isCommunication={isCommunication}
                      date={date}
                    />
                  ) : (
                    <span>
                      <LogsRow row={row} date={date} />
                    </span>
                  )}
                </div>
                <br />
              </React.Fragment>
            )
          })}
        </React.Fragment>
      )
    })
  }

  const isAtBottom = () => {
    const MARGIN = 10
    const container = containerRef.current
    return (
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + MARGIN
    )
  }

  const handleScroll = () => {
    setShowAutoScroll(!isAtBottom())
  }

  useEffect(handleScroll, [data])

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    if (!isAtBottom()) {
      containerRef.current.scrollBy({
        top: 100000,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const borderMap = useMemo(() => {
    const colorMap = window?.currentChatState?.flowConfig?.agents ?? {}
    const mapMessages = (messages) => {
      const colors = messages.map((m) => {
        if (m.match(matchCommunication)) {
          const name = matchCommunication
            .exec(m)[1]
            ?.replace(/^\[[^\]]+]\s+/, '')
          return colorMap?.[name]?.color ?? 'white'
        }
        return null
      })
      colors.reduce((color, currentColor, i) => {
        if (currentColor) return currentColor
        colors[i] = color
        return color
      }, null)
      return colors
    }
    try {
      return mapMessages(data)
    } catch (e) {
      return []
    }
  }, [data])
  let substract = 0
  return (
    <Row
      ref={containerRef}
      className="mb-5 mx-4 terminal-content py-0"
      onScroll={handleScroll}
    >
      {data.map((_, i) => (
        <Fragment key={i}>
          <Col
            xs={12}
            className=""
            style={{
              borderLeft: `8px solid ${
                borderMap?.[i] ? borderMap?.[i] + '88' : '#00000000'
              }`,
            }}
          >
            <Row>
              {!i && (
                <Col className="dflex-center py-3" xs={12}>
                  <Image
                    src={workflow === 'rag' ? flowGraphRag : flowGraph}
                    width={'70%'}
                  />
                </Col>
              )}
            </Row>
            <Row className="py-1">
              <Col
                style={{ maxWidth: '40px', minWidth: '40px' }}
                className="pe-0"
              >
                <span
                  className={`terminal-line-counter ${
                    matches[i] && (substract-- || true) ? 'hidden' : ''
                  }`}
                >
                  {i + 1 + substract}
                </span>
              </Col>
              <Col
                className="ps-0"
                style={{
                  maxWidth: 'calc(100% - 40px)',
                  minWidth: 'calc(100% - 40px)',
                }}
              >
                {messageToComponent(i, i + 1)}
              </Col>
            </Row>
          </Col>
        </Fragment>
      ))}
      <button
        className={'terminal-auto-scroll-btn'}
        style={{
          display: !showAutoScroll ? 'none' : 'block',
        }}
        onClick={scrollToBottom}
      >
        <FaArrowDown style={{ marginLeft: -5 }} />
      </button>
      <div ref={bottomRef}></div>
    </Row>
  )
}

function LogsFragment({ data, workflow, header = '' }) {
  const [active, setActive] = useState(false)
  const TITLE = `Agents' conversation`

  return (
    <>
      <span
        className="terminal-icon me-4"
        title={TITLE}
        onClick={(_) => (window.start = Date.now()) && setActive(true)}
      >
        <FaRegFileCode className="me-2" />
        {header}
      </span>
      {active && (
        <Modal show={true} onHide={() => setActive(false)} size={'xl'}>
          <Modal.Header closeButton>
            <span className="h3">{TITLE}</span>
          </Modal.Header>
          <Modal.Body className="relative logs-body-container">
            <LogsBody data={data} workflow={workflow} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

function AutogenMessage({
  message,
  workflow,
  index,
  logs,
  onRegenerate,
  onExplain,
  shareSession,
  children,
  loading,
  createSession,
  clearMessages,
  showWarning,
  editable,
  sendMessage,
  finishedNewContent,
  scrollNewContent,
  applyTransform,
  isLastMessage = false,
  currentSession,
  updatedContext,
  model,
  poolForUpdate,
  ...props
}) {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const editRef = useRef()
  const messageRef = useRef()
  const extraCode = useMemo(() => {
    if (!Array.isArray(message?.content)) return []
    const existingFiles = new Set(
      message?.metadata?.files?.map((f) => f?.name) ?? [],
    )
    return (
      message?.content
        ?.filter((c) => c.type === 'code')
        ?.map((c) => {
          const name = c?.text?.match(/filename: ([^\n]+)/)?.[1] ?? 'code.py'
          if (existingFiles.has(name)) return null
          return {
            name,
            type: 'code',
            text: c.text,
          }
        })
        ?.filter((v) => v) ?? []
    )
  }, [message])

  if (index === 1 && message.agent_name === 'initializer' && message.content) {
    const prompt = '**Question:**'
    const initialQuestionIndex = message.content.indexOf(prompt)
    if (initialQuestionIndex !== -1) {
      message.content = message.content
        .slice(initialQuestionIndex + prompt.length)
        .trim()
      const lastIndex = message.content.lastIndexOf(']')
      if (lastIndex !== -1)
        message.content = message.content.slice(0, lastIndex).trim()
    }
  }

  return (
    <Row
      className={`chat-msg msg-id-${message.msg_id} ${props?.className ?? ''} ${
        message?.className ?? ''
      } ${message.role === 'assistant' ? '' : 'autogen-user-msg'} ${
        finishedNewContent ? 'finished-new-content' : ''
      }`}
      style={{ maxWidth: '100%' }}
      ref={messageRef}
    >
      <Col className="position-relative px-0 autogen-icon-col">
        <div className="flex-grow-0 dflex-center flex-column icon-autogen-actor position-relative">
          {message.role === 'assistant' ? (
            <>
              <img
                src="/icons/logo.png"
                alt={t('NextBrain')}
                title={t('NextBrain')}
              />
              {message?.metadata?.time && (
                <span className="duration-message-autogen mt-1 d-inline-block no-wrap">
                  {Math.floor(message.metadata.time)}s
                </span>
              )}
            </>
          ) : (
            <FaUser className="user-autogen-icon" />
          )}
        </div>
      </Col>
      <Col className="autogen-content-col position-relative" xs={12}>
        {editing ? (
          <Row>
            <Col xs={12}>
              <p className="remark-color sender-msg">
                {message.role === 'assistant' ? 'NextBrain AI' : t('You')}
              </p>
              <TextareaAutosize
                ref={editRef}
                className="w-100 nb-input"
                defaultValue={message.content}
                autoFocus
              />
            </Col>
            <Col className="mt-2" xs={'auto'}>
              <Button
                className="me-2 original"
                variant="success"
                onClick={() => {
                  const content = editRef.current.value
                  setEditing(false)
                  sendMessage(content, false, message.msg_id, updatedContext)
                }}
              >
                {t('Save and send')}
              </Button>
              <Button
                className="original"
                variant="danger"
                onClick={() => setEditing(false)}
              >
                {t('Cancel')}
              </Button>
            </Col>
          </Row>
        ) : (
          <>
            <p className="remark-color sender-msg">
              {message.role === 'assistant' ? 'NextBrain AI' : t('You')}
            </p>
            {finishedNewContent ? (
              Array.isArray(message.content) ? (
                message.content
                  .filter((c) => c.type !== 'code')
                  .map((c, i) => (
                    <ProgressMessageFragment
                      text={c?.text ?? c}
                      key={i}
                      raw={message?.raw}
                      scrollNewContent={scrollNewContent}
                    />
                  ))
              ) : (
                <ProgressMessageFragment
                  text={message.content}
                  raw={message?.raw}
                  scrollNewContent={scrollNewContent}
                />
              )
            ) : Array.isArray(message.content) ? (
              message.content.map((c, i) => (
                <MessageFragment fragment={c} key={i} raw={message?.raw} />
              ))
            ) : (
              <MarkdownView
                markdown={removeInitialDefaultMessage(message.content)}
                options={{ tables: true, emoji: true }}
                className="markdown-content"
                components={MarkdownComponents}
              />
            )}
            {message.extraContent ? message.extraContent : <></>}
            {children}
            {Array.isArray(message?.metadata?.files) &&
              message.metadata.files.filter((file) => file.type === 'code')
                .length > 0 &&
              message.metadata.files
                .filter((file) => file.type === 'code')
                .map((m, i) => (
                  <CodeFragment key={i} file={m} shareSession={shareSession} />
                ))}
            {extraCode.map((m, i) => (
              <CodeFragment
                key={i}
                file={m}
                isContent={true}
                shareSession={shareSession}
              />
            ))}
          </>
        )}
      </Col>
      <Col
        className="actions-autogen-message"
        xs={12}
        style={{ marginLeft: 30 }}
      >
        {logs && (
          <LogsFragment
            data={logs}
            workflow={workflow}
            header={`${t('Message logs')}`}
          />
        )}
        {message.role === 'assistant' && !loading && (
          <>
            <span
              title="Copy to clipboard"
              className="me-4 terminal-icon"
              onClick={async () => {
                const range = document.createRange()
                ;[
                  ...messageRef.current.querySelectorAll('.markdown-content'),
                ].forEach((e) => range.selectNode(e))
                const selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(range)
                document.execCommand('copy')
                selection.removeAllRanges()
                NotificationManager.info(
                  <span>{t('Copied to clipboard')}</span>,
                )
              }}
            >
              <FaCopy className="me-2" />
              {t('Copy')}
            </span>
          </>
        )}
        {onRegenerate && message.role === 'assistant' && (
          <span
            title="Ask the same question again"
            className="terminal-icon me-4 "
            onClick={onRegenerate}
          >
            <FaHistory className="me-2" />
            {t('Try again')}
          </span>
        )}
        {editable && !editing && message.role !== 'assistant' && (
          <span
            title="Edit my message"
            className="terminal-icon"
            onClick={() => setEditing(true)}
          >
            <FaPencilAlt className="me-2" /> {t('Edit')}
          </span>
        )}
      </Col>
      {Array.isArray(message?.metadata?.files) &&
        message.metadata.files.filter((file) => file.type !== 'code').length >
          0 && (
          <Col className="my-3" xs={12}>
            <Row>
              {message.metadata.files
                .filter((file) => file.type !== 'code')
                .map((m, i) =>
                  isLastMessage && m.name === 'transformed_data.csv' ? (
                    <TransformedConfirmation
                      file={m}
                      currentSession={currentSession}
                      shareSession={shareSession}
                      applyTransform={applyTransform}
                      key={i}
                    />
                  ) : (
                    <Col className="h-100 mb-3" xl={2} md={4} xs={6} key={i}>
                      <FileFragment file={m} shareSession={shareSession} />
                    </Col>
                  ),
                )}
            </Row>
          </Col>
        )}
      {showWarning && (
        <Col className="mt-2" xs={12}>
          <strong className="color-warning" style={{ fontSize: '14px' }}>
            {t('Large message history in the current session')}.{' '}
          </strong>
          <div style={{ fontSize: '14px' }}>
            {t('Consider starting a new session')}{' '}
            <Button
              className="linkshare ms-1"
              style={{ float: 'none', fontSize: '14px' }}
              onClick={() => createSession()}
            >
              {t('Start new session')} <FaPlus className="ms-1" />
            </Button>
            {!shareSession && (
              <>
                <span className="mx-2" style={{ fontSize: '14px' }}>
                  {t('or')}
                </span>
                <Button
                  className="linkshare ms-1"
                  style={{ float: 'none', fontSize: '14px' }}
                  onClick={() => clearMessages()}
                >
                  {t('Clear the chat')} <FaTrash className="ms-1" />
                </Button>
              </>
            )}
          </div>
        </Col>
      )}
    </Row>
  )
}

const logsToLines = (logs) => {
  return logs.reduce((acc, log) => {
    // Get number of lines
    const lines = log.split('\n').filter((w) => w.trim() !== '').length
    return acc + lines
  }, 0)
}

function ShareStatus({
  model,
  updateModel,
  currentSession,
  setModelVisibility,
  ...props
}) {
  const { t } = useTranslation()

  const isPrivateReport = model.is_private_report || !currentSession?.is_public

  return (
    <Row className={`autogen-share-chat ${props?.className ?? ''} p-3`}>
      <Col className="h5 color-white mb-3" xs={12}>
        <FaShareAlt className="me-1 " /> {t('Share chat')}
      </Col>
      <RoleDisable>
        <Col
          className="cursor-pointer autogen-share-chat-button pe-0"
          xs={7}
          onClick={(e) => {
            e.currentTarget.querySelector('input')?.click()
          }}
        >
          <Form.Check
            type="checkbox"
            label={t('Make this chat public')}
            className="pe-none"
            checked={!isPrivateReport}
            onChange={(e) => {
              e.stopPropagation()
              model.is_private_report = !e.target.checked
              updateModel(model, model)
              setModelVisibility(e.target.checked)
            }}
          />
        </Col>
      </RoleDisable>
      {isPrivateReport ? (
        <></>
      ) : (
        <>
          <Col
            xs={5}
            className={`d-flex align-items-center justify-content-end cursor-pointer`}
          >
            <span
              className="linkshare me-3"
              style={{ paddingTop: '5px' }}
              onClick={() => {
                window.open(
                  `${window.location.origin}/chat/${currentSession?.session_id}`,
                )
              }}
            >
              {t('Open link')}
              <RiShareBoxFill className="ms-1" size={20} />
            </span>
          </Col>
          <Col xs={12} className="mt-2">
            <Row>
              <Col xs={10}>
                <Form.Control
                  className="nb-input"
                  readOnly
                  value={`${window.location.origin}/chat/${currentSession?.session_id}`}
                />
              </Col>
              <Col
                className="d-flex align-items-end justify-content-start ps-0"
                xs={2}
              >
                <FaCopy
                  className="icon-btn mb-2"
                  title={t('Copy to clipboard')}
                  size={25}
                  onClick={() => {
                    if (navigator?.clipboard?.writeText) {
                      NotificationManager.info(t('URL copied to clipboard'))
                      navigator.clipboard.writeText(
                        `${window.location.origin}/chat/${currentSession?.session_id}`,
                      )
                    } else NotificationManager.error('Clipboard not supported')
                  }}
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
      <Col className="mt-3" xs={12}>
        <div className="h5">{t('Once the chat is shared anyone can')}</div>
        <ul className="mb-0 ps-2">
          <li className="ms-2 mt-2 color-white">
            {t(
              'Read and download messages and files generated in the current conversation',
            )}
          </li>
          <li className="ms-2 color-white">
            {t('Ask new questions and generate new graphs and files')}
          </li>
          <li className="ms-2 color-white">
            {t('Start new chats within the current model context')}
          </li>
          <li className="ms-2 color-white">
            {t('Other conversations within the same space will not be shared')}
          </li>
        </ul>
      </Col>
    </Row>
  )
}

const ChatExternalControl = forwardRef(
  (
    {
      defaultPrompt,
      updatePrompt,
      model,
      updateModel,
      currentSession,
      setModelVisibility,
      expand,
      setExpand,
      online,
      extraControls,
      onShareContext,
      setShowContext,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const ExpandIcon = expand ? FaCompress : FaExpandArrowsAlt
    return (
      <>
        <span ref={ref}></span>
        <div
          className={`d-flex justify-content-end align-items-center ${
            props?.className ?? ''
          }`}
          style={{
            position: 'absolute',
            top: '-40px',
            right: '0',
            width: '280px',
          }}
        >
          {IS_DEV && false && (
            <OverlayTrigger
              rootClose={true}
              trigger={'click'}
              placement="auto"
              delay={{ show: 200, hide: 0 }}
              overlay={(props) => (
                <Popover
                  {...props}
                  style={{ ...props.style, minWidth: '400px' }}
                >
                  <div style={{ width: '400px' }}>
                    <Form.Control
                      className="nb-input"
                      as="textarea"
                      defaultValue={defaultPrompt}
                      onKeyDown={(e) => {
                        e.stopPropagation()
                      }}
                      onBlur={(e) => {
                        updatePrompt(e.target.value)
                      }}
                      rows={10}
                    />
                  </div>
                </Popover>
              )}
            >
              <Button>
                <span className="smallp">Change prompt</span>
              </Button>
            </OverlayTrigger>
          )}
          {extraControls}
          <span></span>
          <Button
            className="linkshare link original me-2 hide-rag"
            title={t('Show context')}
            onClick={() => setShowContext(true)}
          >
            <FaAddressCard className="ms-2 icon-btn" size={26} />
          </Button>
          <Button
            className="linkshare link original me-2 hide-rag"
            title={t('Generate report')}
            onClick={async () => {
              NotificationManager.info(
                t(
                  'Report generated. You will receive an email with the report shortly.',
                ),
              )
              await window.currentChatState.sendReport()
            }}
          >
            <MdEmail className="ms-2 icon-btn" size={26} />
          </Button>
          <OverlayTrigger
            rootClose={true}
            trigger={'click'}
            placement="bottom-start"
            delay={{ show: 200, hide: 0 }}
            overlay={(props) => (
              <Popover {...props} style={{ ...props.style, minWidth: '400px' }}>
                <div
                  style={{
                    width: '400px',
                    backgroundColor: 'var(--nextbrain-body-75)',
                    border: '2px solid var(--nextbrain-background)',
                    borderRadius: '5px',
                  }}
                >
                  <ShareStatus
                    model={model}
                    updateModel={updateModel}
                    currentSession={currentSession}
                    setModelVisibility={setModelVisibility}
                  />
                </div>
              </Popover>
            )}
          >
            <Button
              className="linkshare link original me-2"
              title={t('Share chat')}
            >
              <FaShareAlt className="ms-2 icon-btn" size={24} />
            </Button>
          </OverlayTrigger>
          {false &&
            (process.env.REACT_APP_MODE || process.env.NODE_ENV) ===
              `development` && (
              <OverlayTrigger
                rootClose={true}
                trigger={'click'}
                placement="bottom-start"
                delay={{ show: 200, hide: 0 }}
                overlay={(props) => (
                  <Popover
                    {...props}
                    style={{ ...props.style, minWidth: '400px' }}
                  >
                    <div
                      style={{
                        width: '400px',
                        backgroundColor: 'var(--nextbrain-body-75)',
                        border: '2px solid var(--nextbrain-background)',
                        borderRadius: '5px',
                      }}
                    >
                      Edit Context
                    </div>
                  </Popover>
                )}
              >
                <Button
                  className="linkshare  original me-2"
                  title={t('Edit context')}
                  onClick={onShareContext}
                >
                  <FaAddressCard size={27} />
                </Button>
              </OverlayTrigger>
            )}
          <div
            className="control-expand-autogen-chat btn-alt-main-color public-autogen-chat-hidden cursor-pointer"
            style={{
              position: 'absolute',
              top: '50px',
              right: '10px',
              width: '30px',
              maxHeight: '0px',
              zIndex: 10,
              display: 'none',
            }}
            onClick={() => setExpand((s) => !s)}
          >
            <ExpandIcon size={30} style={{ float: 'right' }} />
          </div>
          {!online && (
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement="left"
              delay={{ show: 200, hide: 0 }}
              overlay={(props) => (
                <Tooltip
                  {...props}
                  className={`context-menu-column-type big-menu pe-none ${
                    props?.className ?? ''
                  }`}
                >
                  {t('Connection lost to the server, reconnecting...')}
                </Tooltip>
              )}
            >
              <div
                className="connection-loss-autogen-chat"
                style={{
                  position: 'absolute',
                  top: '90px',
                  right: '10px',
                  width: '30px',
                  height: '30px',
                  zIndex: 12,
                }}
              >
                <VscDebugDisconnect
                  size={30}
                  color={'var(--nextbrain-tables-negative-graph-bar-color)'}
                />
              </div>
            </OverlayTrigger>
          )}
        </div>
      </>
    )
  },
)

function ProgressStep({ item, active, onFinish, retry }) {
  const ref = useRef()
  const [statusCode, setStatusCode] = useState(null)
  const [source, setSource] = useState(null)
  const [open, setOpen] = useState(() => {
    return item.includes('planner (to chat_manager)')
  })
  const data = useMemo(
    () => {
      let res = splitByCodeSections(item)
        .map((section, i) =>
          section.type === 'text' ? section.text : '[CODE...]',
        )
        .join('\n')

      if (res.includes('<SuccessExit />')) {
        setStatusCode(<SuccessExit />)
        res = res.replace('<SuccessExit />', '')
      }
      if (res.includes('<ErrorExit />')) {
        setStatusCode(<ErrorExit retry={retry} />)
        res = res.replace('<ErrorExit />', '')
      }
      if (matchCommunication.test(res)) {
        const matches = matchCommunication.exec(res)
        let rebuild = `${matches[1]} (to ${matches[2]}):`
        const dateRegx = /^(\[[^\]]+])\s+/
        if (dateRegx.test(rebuild)) {
          rebuild = rebuild.replace(dateRegx, '')
        }
        res = res.replace(matchCommunication, '')
        if (rebuild.includes('planner')) setOpen(true)
        setSource(
          <div
            className="w-auto py-2 position-relative cursor-pointer"
            onClick={() => setOpen((o) => !o)}
          >
            <Communication
              className="communication-shift-left cursor-pointer user-select-none"
              message={rebuild}
            />
          </div>,
        )
      }
      return res.length > 1500 ? res.slice(0, 1500) + '...' : res
    },
    // eslint-disable-next-line
    [item],
  )

  const dataRef = useRef(active ? '' : data)
  useEffect(() => {
    if (active) {
      let i = 0
      let step = Math.round(Math.max(1, data.length / 100))
      const interval = setInterval(() => {
        if (!ref.current) return
        i += step
        dataRef.current = data.slice(0, i)
        ref.current.textContent = dataRef.current
        if (i > data.length) {
          clearInterval(interval)
          onFinish()
        }
      }, 50)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [active])

  return (
    <Row
      className={`${open ? '' : 'collapsed'} ${
        statusCode ? `status-message-output` : ''
      }`}
    >
      <div className="position-relative d-inline-flex flex-nowrap align-items-center">
        {source}
        {source && (
          <FaChevronRight
            size={25}
            style={{
              transform: `rotate(${open ? 90 : 0}deg)`,
              transitionDuration: '0.3s',
              cursor: 'pointer',
            }}
            onClick={() => setOpen((o) => !o)}
          />
        )}
      </div>
      {statusCode}
      <Col ref={ref} xs={12} className={`collapse-content-chat`}>
        {active ? (
          dataRef.current
        ) : (
          <MarkdownView
            markdown={dataRef.current}
            options={{ tables: true, emoji: true }}
            className="markdown-content"
            components={MarkdownComponents}
          />
        )}
      </Col>
    </Row>
  )
}

function LogsProgress({ logs, onUpdate, ...props }) {
  const { t } = useTranslation()
  const filterLogs = useMemo(() => {
    return (
      logs?.filter(
        (l) =>
          !l.endsWith('-------------') && !l.includes('EXECUTING CODE BLOCK'),
      ) ?? []
    )
  }, [logs])
  const [currentStep, setCurrentStep] = useState(
    Math.max(filterLogs?.length ? filterLogs.length - 1 : 0, 0),
  )
  useEffect(() => {
    onUpdate()
    // eslint-disable-next-line
  }, [currentStep])
  const executingCode = logs?.[logs?.length - 1]?.includes(
    'EXECUTING CODE BLOCK',
  )
  const codeBlock = executingCode
    ? logs?.findLast((c) => c.includes('```'))?.split('```')?.[1]
    : null

  const codeVisible = useRef(false)

  const indexStatus = useMemo(() => {
    const indexes = filterLogs
      .map((l, i) =>
        l.includes('<SuccessExit />') || l.includes('<ErrorExit />') ? i : -1,
      )
      .filter((i) => i !== -1)
    indexes.pop()
    return indexes.map((i) => [i - 4, i - 3, i - 2, i - 1, i]).flat()
  }, [filterLogs])
  const retries = 1 + (indexStatus?.length ?? 0) / 5

  return (
    <>
      <div {...props}>
        {filterLogs.map((l, i) =>
          indexStatus.includes(i) ? (
            <></>
          ) : (
            <ProgressStep
              key={i}
              item={l}
              active={currentStep === i}
              onFinish={() => setCurrentStep((i) => i + 1)}
              retry={retries}
            />
          ),
        )}
      </div>
      {executingCode && (
        <div className="theme-color w-100 pb-5 pt-0 dflex-center">
          <OverlayTrigger
            rootClose={true}
            trigger={'click'}
            placement="auto"
            onToggle={(s) => (codeVisible.current = s)}
            delay={{ show: 200, hide: 100 }}
            overlay={(props) =>
              codeBlock ? (
                <Popover
                  {...props}
                  className={`code-preview-loading  ${props?.className ?? ''}`}
                >
                  <div className="position-relative overflow-auto secondary-code-editor">
                    <CodeMirror
                      value={codeBlock}
                      readOnly
                      editable={false}
                      extensions={[python({})].filter((e) => e)}
                      onChange={() => {}}
                      theme={solarizedDark}
                      indentWithTab
                      maxHeight="60vh"
                    />
                  </div>
                </Popover>
              ) : null
            }
          >
            <span
              className="d-inline-flex flex-nowrap align-items-center cursor-pointer user-select-none"
              onMouseEnter={(e) => {
                if (!codeVisible.current) e.currentTarget?.click()
              }}
            >
              <FaPython size={50} /> {t('Executing code block')}
              <BouncyLoader />
            </span>
          </OverlayTrigger>
        </div>
      )}
    </>
  )
}

export default function AutogenBot({
  model,
  shareSession,
  extraControls,
  chatWindow = 'max(60vh, 700px)',
  resizeRelative = false,
  refreshModel = () => {},
  workflow,
  tags = [],
  ...props
}) {
  const queryClient = useQueryClient()
  const containerRef = useRef()
  const inputRef = useRef()
  const headerRef = useRef()
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const {
    onParameterUpdate,
    offParameterUpdate,
    updateModel,
    requestUpdate,
    poolForUpdate,
  } = useModels()
  const [requestSuggestions, setRequestSuggestions] = useState(false)
  const [showAutoScroll, setShowAutoScroll] = useState(false)
  const bottomRef = useRef(null)
  const [regenerateTransform, setRegenerateTransform] = useState(false)
  const [regenerate, setRegenerate] = useState(false)
  const [expand, setExpand] = useState(false)
  const [userScrolled, setUserScrolled] = useState(false)
  const [updatedContext, setUpdatedContext] = useState({})
  const [showUpdateContext, setShowUpdateContext] = useState(false)
  const { mode } = useNav()

  const [calculatedHeight, setCalculatedHeight] = useState(chatWindow)
  useEffect(() => {
    if (!resizeRelative) return
    const widthCallback = () => {
      if (headerRef.current) {
        const position = $(headerRef.current)?.offset()?.top
        const height = $(headerRef.current)?.outerHeight(true)
        if (Number.isNaN(position) || Number.isNaN(height)) return
        setCalculatedHeight(`calc(100vh - ${position + height + 5}px)`)
      }
    }
    widthCallback()
    window.addEventListener('resize', widthCallback)
    return () => window.removeEventListener('resize', widthCallback)
    // eslint-disable-next-line
  }, [])

  const isAtBottom = () => {
    const MARGIN = 10
    const container = containerRef.current
    if (!container || !messages?.length) return true
    return (
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + MARGIN
    )
  }

  const handleScroll = () => {
    setShowAutoScroll(!isAtBottom())
  }

  const scrollToBottom = () => {
    if (!isAtBottom())
      containerRef.current.scrollTop = containerRef.current.scrollHeight
  }

  const {
    online,
    autogenState,
    messages,
    state,
    error,
    sendMessage,
    clearMessages,
    clearError,
    forceUpdate,
    currentLogs,
    defaultPrompt,
    updatePrompt,
    sessions,
    currentSession,
    changeSession,
    newSession,
    deleteSession,
    setModelVisibility,
    createNewPublicSession,
    showSkills,
    finishedNewContent,
    createWelcome,
    setCustomWelcome,
    applyTransform,
  } = useAutogen({
    model,
    refreshModel,
    onNewContent: () => {
      setTimeout(() => {
        inputRef.current?.focus()
        if (containerRef.current)
          containerRef.current.scrollTop = containerRef.current.scrollHeight
      }, 200)
    },
    shareSession,
    mode: mode,
    workflow,
  })
  useEffect(() => {
    if (autogenState) autogenState.rewrite = updatedContext
  }, [updatedContext, autogenState])
  useEffect(() => {
    if (finishedNewContent) setUserScrolled(true)
  }, [finishedNewContent])

  useEffect(() => {
    if (error) {
      NotificationManager.error(`${error}`)
      clearError()
    }
    // eslint-disable-next-line
  }, [error])

  const transform_suggestions = model?.initial_transform_questions
  const explore_suggestions = model?.initial_questions

  const { data: suggestions } = useQuery(
    [
      'suggestions-autogen',
      model?.id,
      transform_suggestions,
      explore_suggestions,
    ],
    async () => {
      const items = [explore_suggestions, transform_suggestions]
      const suggestions = {}
      items.forEach((s) => {
        if (s && s.constructor === Object) {
          Object.keys(s).forEach((k) => {
            if (Array.isArray(suggestions[k])) suggestions[k].push(...s[k])
            else suggestions[k] = [...s[k]]
          })
        }
      })

      return suggestions
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (
      model &&
      (explore_suggestions?.constructor !== Object ||
        transform_suggestions?.constructor !== Object)
    ) {
      const timeout = setTimeout(() => requestUpdate(model?.id), 30000)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [model, transform_suggestions, explore_suggestions])

  useEffect(
    () => {
      if (shareSession) return
      const cb = () => {
        setRequestSuggestions(true)
        forceUpdate()
      }
      onParameterUpdate(model.id, 'initial_questions', cb)
      setTimeout(scrollToBottom, 200)
      return () => offParameterUpdate(model.id, 'initial_questions', cb)
    },
    // eslint-disable-next-line
    [],
  )

  const suggestionMessage = useMemo(() => {
    if (
      (!explore_suggestions && !transform_suggestions) ||
      !suggestions ||
      mode === 'mmm'
    )
      return null
    if (explore_suggestions) setRegenerate(false)
    if (transform_suggestions) setRegenerateTransform(false)

    let localSuggestions = suggestions
    if (Array.isArray(localSuggestions))
      localSuggestions = {
        Suggestions: localSuggestions,
      }

    return {
      role: 'assistant',
      id: 'suggestions',
      className: 'suggestions-autogen-message',
      raw: true,
      content: [
        <AccordionAutogen
          key="Accordion-autogen"
          elements={[
            createWelcome(),
            ...Object.keys(localSuggestions)
              .map((name) =>
                localSuggestions[name].map((suggestion, i) => (
                  <Col
                    md={3}
                    xs={12}
                    key={`${name}-${i}`}
                    className="mx-3 px-2 py-3 my-3 cursor-pointer bot-suggestions text-white"
                    onClick={() => {
                      sendMessage(suggestion, false, null, updatedContext)
                    }}
                  >
                    <span className="px-3 bot-suggestions-title remark-color h5">
                      {iconMap[name] ?? iconMap['Other']}
                      {name}
                    </span>
                    <Row className="p-3">
                      <Col md={10}>
                        <i>{suggestion}</i>
                      </Col>
                      <Col md={2}>
                        <FaRegArrowAltCircleDown className="arrow" size={35} />
                      </Col>
                    </Row>
                  </Col>
                )),
              )
              .flat()
              .reduce(
                (acc, el) => {
                  let i = acc.length - 1
                  if (acc[i].length === 6) {
                    acc.push([])
                    i++
                  }
                  acc[i].push(el)
                  return acc
                },
                [[]],
              )
              .map((r, j) => (
                <Row className="justify-content-center" key={j}>
                  {r}
                </Row>
              )),
          ]}
        />,
      ],
    }
    // eslint-disable-next-line
  }, [suggestions, autogenState])

  if (suggestionMessage) setCustomWelcome(suggestionMessage)

  const updateSuggestions = () => {
    const index = messages.findIndex((m) => m.id === 'suggestions')
    if (index !== -1) messages.splice(index, 1)
    messages.push(suggestionMessage)
    forceUpdate()
    setTimeout(scrollToBottom, 200)
  }
  //Add the newly generated suggestions
  useEffect(() => {
    if (requestSuggestions) {
      updateSuggestions()
      setRequestSuggestions(false)
    }
    // eslint-disable-next-line
  }, [requestSuggestions])

  const botIsThinking = state !== 'on'

  const messageColSpan = {
    xs: 12,
  }

  const showWarning = messages.length > 20

  useEffect(() => {
    if (expand) {
      inputRef.current?.focus()
      window?.$crisp?.push(['do', 'chat:hide'])
      return () => window?.$crisp?.push(['do', 'chat:show'])
    }
  }, [expand])

  useEffect(() => {
    const cb = (e) => e.key === 'Escape' && setExpand(false)
    document.addEventListener('keydown', cb)
    return () => document.removeEventListener('keydown', cb)
  }, [])

  const scrollNewContent = userScrolled
    ? () => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight
      }
    : () => {}

  const [showContext, setShowContext] = useState(false)

  const firstMessageAndEmptyLogs =
    state !== 'off' &&
    state !== 'starting' &&
    botIsThinking &&
    !currentLogs?.length &&
    messages.length < 3
  return (
    <>
      <ChatExternalControl
        className={
          shareSession || expand
            ? 'd-none'
            : `chat-external-controls-workflow-${workflow}`
        }
        defaultPrompt={defaultPrompt}
        updatePrompt={updatePrompt}
        model={model}
        updateModel={updateModel}
        currentSession={currentSession}
        setModelVisibility={setModelVisibility}
        expand={expand}
        setExpand={setExpand}
        online={online}
        extraControls={extraControls}
        ref={headerRef}
        onShareContext={() => setShowUpdateContext(true)}
        setShowContext={() => setShowContext(true)}
      />
      <div className="custom-gradient"></div>
      <Row
        className={`chat autogen-chat ${
          expand ? 'fullscreen-chat' : ''
        } flex-column align-items-center flex-nowrap ${
          props?.className ?? ''
        } ${
          shareSession ? 'public-autogen-chat' : ''
        } chat-workflow-${workflow}`}
        style={{
          minHeight: calculatedHeight,
          maxHeight: calculatedHeight,
          overflow: 'auto',
          ...(props?.style ?? {}),
        }}
        ref={containerRef}
        onScroll={handleScroll}
        onWheel={() => setUserScrolled(false)}
      >
        {expand && (
          <FaCompress
            style={{
              width: '30px',
              position: 'fixed',
              top: '10px',
              right: '10px',
            }}
            size={30}
            className="btn-alt-main-color cursor-pointer p-0"
            onClick={() => setExpand(false)}
          />
        )}
        <Col
          className="public-autogen-chat-visible"
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 10,
            maxHeight: '50px',
          }}
          xs={12}
        >
          <Button
            className="dflex-center mt-1 py-1"
            onClick={async (e) => {
              e.target.disabled = true
              if (shareSession) {
                let newSession = null
                const test = (s) => (newSession = s?.data?.session_id)
                for (let i = 0; i < 5 && !newSession; i++)
                  try {
                    await createNewPublicSession()
                      .then(test)
                      .catch(() => {})
                    if (!newSession)
                      await new Promise((r) =>
                        setTimeout(r, i * 50 + Math.round(Math.random() * 50)),
                      )
                    else break
                  } catch (e) {
                    console.error('Failed to create a new public session', e)
                  }

                if (newSession)
                  window.open(
                    `${window.location.origin}/chat/${newSession}`,
                    '_blank',
                  )
                else NotificationManager.error('Error creating new chat')
                e.target.disabled = false
              } else newSession().finally(() => (e.target.disabled = false))
            }}
          >
            <FaPlus className="me-1" size={20} /> {t('New chat')}
          </Button>
        </Col>
        <Col
          style={
            shareSession
              ? {
                  position: 'sticky',
                  bottom: '100%',
                  marginTop: '55px',
                }
              : {}
          }
          {...messageColSpan}
        >
          {messages.map((m, i) => (
            <AutogenMessage
              key={`${m.msg_id}`}
              editable={!botIsThinking}
              index={i}
              message={m}
              logs={messages?.[i - 1]?.logs}
              shareSession={shareSession}
              currentSession={currentSession}
              onRegenerate={
                i === messages.length - 1
                  ? () => sendMessage('', true, null, updatedContext)
                  : false
              }
              onExplain={
                i === messages.length - 1
                  ? () => sendMessage(EXPLAIN_MSG)
                  : false
              }
              showWarning={
                showWarning && !botIsThinking && i === messages.length - 1
              }
              createSession={newSession}
              clearMessages={clearMessages}
              sendMessage={sendMessage}
              finishedNewContent={
                finishedNewContent && i === messages.length - 1
              }
              workflow={workflow}
              scrollNewContent={scrollNewContent}
              isLastMessage={i === messages.length - 1}
              applyTransform={async (...props) => {
                const response = await applyTransform(...props)
                if (response?.task_id) {
                  awaitTask({
                    taskUuid: response.task_id,
                    sleep: 10000,
                  }).then((r) => {
                    if (r?.transformed)
                      NotificationManager.success(
                        t('Transform applied successfully'),
                      )
                    else
                      NotificationManager.error(t('Error applying transform'))

                    requestUpdate(model)
                    queryClient.invalidateQueries([
                      'model-summary',
                      model?.id,
                      'imported',
                    ])
                    queryClient.invalidateQueries([
                      'viewData-infinite',
                      model?.id,
                      'imported',
                    ])
                  })
                } else {
                  // Do not send notification if it is a cancel
                  if (!props.length || !props[0]) {
                    NotificationManager.error('Error applying transform')
                  }
                }
              }}
              updatedContext={updatedContext}
              model={model}
              poolForUpdate={poolForUpdate}
            />
          ))}
          {botIsThinking && (
            <AutogenMessage
              workflow={workflow}
              loading={true}
              message={{
                role: 'assistant',
                content: '',
              }}
            >
              {firstMessageAndEmptyLogs && (
                <Row className="mb-5 mb-3 download-autogen-message">
                  <Col
                    xs={12}
                    className="dflex-center download-autogen-message-icon"
                  >
                    <OverlayTrigger
                      rootClose={true}
                      trigger={['hover', 'focus']}
                      placement="left"
                      delay={{ show: 200, hide: 0 }}
                      overlay={(props) => (
                        <Tooltip
                          {...props}
                          className={`context-menu-column-type big-menu pe-none ${
                            props?.className ?? ''
                          }`}
                        >
                          {t(
                            workflow === 'rag'
                              ? 'Preparing chat'
                              : 'The agent is downloading the dataset to generate a context and analize your model for a better experience',
                          )}
                        </Tooltip>
                      )}
                    >
                      <div className="d-inline-block position-relative">
                        <FaFile size={130} />
                        <div className="animated-download-context-autogen" />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={12}>
                    <div className="dflex-center  position-relative mt-3">
                      <span
                        className="loading-tooltip"
                        style={{ fontSize: '1.5rem' }}
                      >
                        {t(
                          workflow === 'rag'
                            ? 'Preparing chat'
                            : 'The chat agent is downloading the dataset to access the context',
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
              <CrashFallback message={<></>} t={t}>
                {Array.isArray(currentLogs) && (
                  <LogsProgress
                    onUpdate={() => {
                      containerRef.current.scrollTop =
                        containerRef.current.scrollHeight
                    }}
                    logs={currentLogs}
                    className="p-xl-4 p-md-2 p-1 mb-2 autogen-logs-progress"
                    style={{ opacity: 0.85, fontStyle: 'italic' }}
                  />
                )}
              </CrashFallback>
              <Row>
                <Col className="my-3" xs={12}>
                  {state !== 'starting' && (
                    <LogsFragment
                      data={currentLogs ?? []}
                      header={`${t(
                        'View agents talking (Lines written',
                      )}: ${logsToLines(currentLogs ?? [])})`}
                    />
                  )}
                  <BouncyLoader className="my-2 d-inline-flex justify-content-start me-1" />
                </Col>
              </Row>
            </AutogenMessage>
          )}
        </Col>
        <div ref={bottomRef}></div>
        <Col className="autogen-chat chat-send-widget" {...messageColSpan}>
          <button
            className="button-scroll-main"
            style={{
              display: !showAutoScroll ? 'none' : 'flex',
            }}
            onClick={() => {
              scrollToBottom()
              inputRef.current?.focus()
            }}
          >
            <FaArrowDown />
          </button>
          <Input
            ref={inputRef}
            model={model}
            shareSession={shareSession}
            botIsThinking={botIsThinking}
            onMessage={sendMessage}
            onClear={() => clearMessages()}
            defaultSuggestions={suggestions || []}
            onShowSuggestions={updateSuggestions}
            showSkills={showSkills}
            botIsRegeneratingSuggestions={regenerate || regenerateTransform}
            onRegenerate={() => {
              setRegenerate(true)
              model.initial_questions = null
              regenerateSuggestions({
                explore: true,
                modelId: model.id,
                token,
                signout,
              })
              NotificationManager.info(
                t('New suggestions will be available soon'),
              )
            }}
            onRegenerateTransform={() => {
              setRegenerateTransform(true)
              model.initial_transform_questions = null
              regenerateSuggestions({
                explore: false,
                modelId: model.id,
                transform: true,
                token,
                signout,
              })
            }}
            sessions={sessions}
            currentSession={currentSession}
            newSession={newSession}
            changeSession={changeSession}
            deleteSession={deleteSession}
            setShowContext={setShowContext}
            workflow={workflow}
            tags={tags}
            state={autogenState}
          />
        </Col>
      </Row>
      <Modal show={showContext} onHide={() => setShowContext(false)} size="xl">
        <Modal.Header closeButton>
          <h4>{t('Edit context')}</h4>
        </Modal.Header>
        <Modal.Body>
          <EditContext model={model} onSave={() => setShowContext(false)} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showUpdateContext}
        onHide={() => setShowUpdateContext(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <h4>{t('Edit prompt context')}</h4>
        </Modal.Header>
        <Modal.Body>
          {showUpdateContext && (
            <Row>
              <Col className="my-5" xs={12}>
                <TextareaAutosize
                  placeholder={t('Update the responder context')}
                  className="no-border input-chat w-100"
                  rows={5}
                  onChange={(e) => {
                    setUpdatedContext({
                      responder: e.target.value,
                    })
                  }}
                  defaultValue={
                    updatedContext?.responder ??
                    autogenState?.flowConfig?.agents?.responder?.config
                      ?.system_message ??
                    ''
                  }
                  style={{
                    backgroundColor: 'var(--nextbrain-body)',
                    paddingLeft: '40px',
                    paddingRight: '35px',
                    resize: 'none',
                  }}
                />
              </Col>
              <Col xs={12}>
                <Button onClick={() => setShowUpdateContext(false)}>
                  <div className="h2 mb-0">Save</div>
                </Button>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
