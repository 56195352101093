import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
} from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import { FaColumns, FaLongArrowAltRight } from 'react-icons/fa'
import { BiUndo } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import './view-data.css'
import Loading from '../loading/LoadingSmall'
import { useQuery, useQueryClient } from 'react-query'
import ViewDataResume from './ViewDataResume'
import $ from 'jquery'
import { categoryToColorDataTypes as categoryToColor } from '../../util/aethetics'
import {
  getModelById,
  getModelSummary,
  updateModelDataset,
} from '../../services/model'
import { NotificationManager } from 'react-notifications'
import { useModels } from '../../providers/ModelProvider'
import DataTypeIcon from '../data-types/DataTypeIcon'
import ModalChangeType from './ModalChangeType'
import { readableNumberMMM } from '../utils/formating'

const categoryTranslate = {
  Double: 'Decimal',
}

const originalCategoryTranslate = Object.entries(categoryTranslate).reduce(
  (acc, [k, v]) => ({ ...acc, [v]: k }),
  {},
)

const TRANSLATE_STATS = {
  logical_type: 'Data type',
  min: 'Minimum',
  max: 'Maximum',
  nan_count: 'Empty values',
  skew: 'Skewness',
  mode: 'Mode',
  nunique: 'Number of unique values',
  percent_consecutive_values: 'Percent consecutive values',
}

const NUMBER_FORMAT_CATEGORIAS = new Set(['Integer', 'Double'])

const NUMBER_FORMAT_KEYS = new Set([
  'min',
  'max',
  'nunique',
  'mode',
  'nan_count',
])

function Column({
  name,
  model,
  summary,
  head,
  columnToType,
  columnDescription,
  onDelete,
  onChangeType,
  ...props
}) {
  const { t } = useTranslation()
  const colRef = useRef()
  const colHRef = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [newType, setNewType] = useState(null)
  const [toDelete, setToDelete] = useState(false)

  useEffect(() => {
    onDelete(name, toDelete)
    // eslint-disable-next-line
  }, [toDelete])

  useEffect(() => {
    setWidth($(colRef.current).width())
  }, [colRef])
  useEffect(() => {
    setHeight(Math.max($(colHRef.current).height(), 150))
  }, [colHRef])

  const newCategoryColor =
    categoryToColor[categoryTranslate[newType]] ?? categoryToColor[newType]

  return (
    <Row
      className={`${props?.className ?? ''} ${
        toDelete ? 'column-view-data-edit-disabled-column' : ''
      } pb-2`}
    >
      <Col className="header-column-view-data-edit py-2" xs={12}>
        <Row className="position-relative align-items-center justify-content-between">
          <Col
            className="hide-on-error"
            style={{ maxWidth: 'calc(100% - 120px)' }}
            xs={12}
          >
            <Row className="align-items-center">
              <Col
                className=" mb-0 color-white d-flex align-items-center"
                xs={'auto'}
              >
                <FaColumns className="me-1" size={20} />

                {columnDescription ? (
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement={'auto'}
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <span>{columnDescription}</span>
                      </Tooltip>
                    )}
                  >
                    <span className="d-inline-block text-truncate">{name}</span>
                  </OverlayTrigger>
                ) : (
                  <span className="d-inline-block text-truncate">{name}</span>
                )}
              </Col>
              <Col className="d-flex align-items-center" xs={'auto'}>
                <ModalChangeType
                  column={name}
                  model={model}
                  multipleDates={
                    (model?.dataset?.datetime_options?.[name] ?? [])?.length > 1
                  }
                  columnType={
                    categoryTranslate[columnToType[name]] ?? columnToType[name]
                  }
                  onColumnTypeChanged={(name, newType, format) => {
                    setNewType(newType)
                    onChangeType(name, newType, format)
                  }}
                />
                {newType && (
                  <>
                    <FaLongArrowAltRight className="ms-2 me-0" />
                    <Button className="no-focus m-0 p-0 no-bg btn">
                      <div className="columnd-and-type pb-0 d-inline-block">
                        <span
                          align="left"
                          className="ms-2 badge-datatype"
                          style={{
                            color: newCategoryColor,
                            backgroundColor: `${newCategoryColor}22`,
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span>{categoryTranslate[newType] ?? newType}</span>
                            <DataTypeIcon
                              className="ms-2 mt-1"
                              size={19}
                              color={newCategoryColor}
                              type={newType}
                            />
                          </div>
                        </span>
                      </div>
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={'auto'}>
            <div className="view-data-edit-button-delete-column">
              {!toDelete ? (
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={'auto'}
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <span>{t('Delete column')}</span>
                    </Tooltip>
                  )}
                >
                  <span>
                    <MdDelete size={30} onClick={() => setToDelete(true)} />
                  </span>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={'auto'}
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <span>{t('Cancel delete')}</span>
                    </Tooltip>
                  )}
                >
                  <span>
                    <BiUndo size={30} onClick={() => setToDelete(false)} />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="hide-on-error" md={3} xs={12}>
        <Row>
          <Col style={{ height: `${height}px` }} ref={colRef} xs={12}>
            <ViewDataResume
              summary={summary}
              width={width}
              height={height}
              model={model}
              column={name}
              className="border-bottom-none"
            />
          </Col>
        </Row>
      </Col>
      <Col className="hide-on-error" ref={colHRef} md={9} xs={12}>
        <Row className="mt-3" style={{ minHeight: 'calc(100% - 50px)' }}>
          {Object.entries(model?.dataset?.statistics?.[name] ?? {})
            .filter(
              ([k]) =>
                k !== 'skew' ||
                model?.dataset?.final_column_status?.[name] !== 'Categorical',
            )
            .map(([k, v]) => (
              <Col key={k} lg={6} md={12}>
                <Row className="mt-2">
                  <Col
                    className="d-inline-block text-truncate"
                    xs={'auto'}
                    style={{ maxWidth: '50%' }}
                  >
                    <strong title={TRANSLATE_STATS[k]}>
                      {TRANSLATE_STATS[k]}
                    </strong>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={6}>
                    <span title={categoryTranslate[v] ?? v ?? 'None'}>
                      {NUMBER_FORMAT_CATEGORIAS.has(
                        model?.dataset?.statistics?.[name]?.logical_type,
                      ) && NUMBER_FORMAT_KEYS.has(k)
                        ? readableNumberMMM(v)
                        : categoryTranslate[v] ?? v ?? 'None'}
                    </span>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  )
}

export default function ViewDataEdit({
  model,
  getHead,
  columnToType,
  ...props
}) {
  const { t } = useTranslation()
  const { activeModel, updateModel } = useModels()
  if (!model) model = activeModel
  const { token, signout } = useAuth()
  const deleteColumns = useRef({})
  const changeTypes = useRef({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [updating, setUpdating] = useState(false)
  const queryClient = useQueryClient()
  const [iteration, setIteration] = useState(0)

  const { data: summary } = useQuery(
    ['model-summary', model?.id, 'imported'],
    async () => await getModelSummary({ modelId: model?.id, token, signout }),
    { staleTime: Infinity },
  )

  const deleteCB = useCallback(
    (name, del) => (deleteColumns.current[name] = del),
    [],
  )
  const changeTypeCB = useCallback(
    (name, type) => (changeTypes.current[name] = type),
    [],
  )

  const { data: sampleData, isLoading } = useQuery(
    ['viewData', model?.id, model?.dataset?.status],
    async () => {
      if (model?.dataset?.status !== 'imported') return null
      let response = await getHead({
        modelId: model?.id,
        pageSize: 100,
        pageNumner: 1,
        token,
        signout,
      })
      if (!response || response.status === 'error' || response.detail) {
        response = {
          status: 'error',
          message: t('Failed to retrieve dataset data'),
        }
      } else if (!response) {
        response = {
          status: 'error',
          message: t('Error retrieving data'),
        }
      } else if (response.head)
        Object.keys(response.head).forEach(
          (k) =>
            (response.head[k.replace(/^(ln_)||(sq_)/, '')] = response.head[k]),
        )
      return response
    },
    { staleTime: Infinity },
  )

  const columnsToDelete = Object.entries(deleteColumns.current).filter(
    ([, v]) => v,
  )
  const columnsToChangeType = Object.entries(changeTypes.current).filter(
    ([k, v]) => v && !deleteColumns.current[k],
  )
  const empty = columnsToDelete.length === 0 && columnsToChangeType.length === 0

  return (
    <Row
      {...props}
      className={`${props?.className ?? ''} edit-column-data-container`}
    >
      {isLoading || !model || !sampleData.columns_order ? (
        <Loading />
      ) : (
        sampleData.columns_order.map((col, i) => (
          <Col
            className="column-view-data-edit"
            key={`${iteration}-${col}`}
            xs={12}
          >
            <Column
              model={model}
              columnToType={columnToType}
              columnDescription={sampleData.columns_description?.[col]}
              summary={summary?.[col]}
              name={col}
              head={sampleData.head[col]}
              onDelete={deleteCB}
              onChangeType={changeTypeCB}
            />
          </Col>
        ))
      )}

      <Col
        xs={12}
        className="edit-column-commit-changes d-flex align-items-center justify-content-center py-3 px-0 w-100"
      >
        <Button
          className="py-2 px-4"
          type="submit"
          variant={'secondary'}
          onClick={() => setShowConfirm(true)}
        >
          <span>{t('Apply changes')}</span>
        </Button>
      </Col>
      <Modal size="xl" show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <strong className="h4">{t('Confirm changes')}</strong>
        </Modal.Header>
        <Modal.Body>
          {columnsToDelete.length > 0 && (
            <Row className="mb-3">
              <Col className="d-flex align-items-center" xs={12}>
                <span className="color-white me-2">
                  {t('Columns to delete:')}{' '}
                </span>
                {columnsToDelete.map(([k], i) => (
                  <span className="view-data-edit-column-deleted me-1" key={k}>
                    {k}
                  </span>
                ))}
              </Col>
            </Row>
          )}
          {columnsToChangeType.length > 0 && (
            <Row>
              <Col xs={12}>
                <span className="color-white me-2">
                  {t('Columns types changed:')}{' '}
                </span>
              </Col>
              <Col xs={12}></Col>
              {columnsToChangeType.map(([k, v], i) => (
                <React.Fragment key={k}>
                  <Col xs={12}>
                    <Row>
                      <Col
                        className="d-inline-block text-truncate py-2"
                        title={k}
                        xs={3}
                      >
                        <span
                          className="view-data-edit-column-deleted me-1"
                          key={k}
                        >
                          {k}
                        </span>
                      </Col>
                      <Col xs={'auto'}>
                        <Row>
                          <Col
                            className="d-inline-block text-truncate py-2"
                            xs={'auto'}
                          >
                            <i
                              className="small p-1 px-2"
                              style={{
                                backgroundColor: `${
                                  categoryToColor[
                                    categoryTranslate[columnToType[k]] ??
                                      columnToType[k]
                                  ]
                                }11`,
                                borderRadius: '6px',
                              }}
                            >
                              <strong
                                style={{
                                  color:
                                    categoryToColor[
                                      categoryTranslate[columnToType[k]] ??
                                        columnToType[k]
                                    ],
                                }}
                              >
                                {categoryTranslate[columnToType[k]] ??
                                  columnToType[k]}
                              </strong>
                            </i>
                          </Col>
                          <Col
                            className="d-inline-block text-truncate py-2"
                            xs={'auto'}
                          >
                            <FaLongArrowAltRight />
                          </Col>
                          <Col
                            className="d-inline-block text-truncate py-2"
                            xs={'auto'}
                          >
                            <i
                              className="small p-1 px-2"
                              style={{
                                backgroundColor: `${categoryToColor[v]}11`,
                                borderRadius: '6px',
                              }}
                            >
                              <strong style={{ color: categoryToColor[v] }}>
                                {categoryTranslate[v] ?? v}
                              </strong>
                            </i>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}></Col>
                </React.Fragment>
              ))}
            </Row>
          )}
          {empty && (
            <Row>
              <Col className="h5" xs={12}>
                {t('No changes to apply')}
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="p-3"
            disabled={updating}
            onClick={() => {
              if (empty) {
                setShowConfirm(false)
                return
              }
              setUpdating(true)
              updateModelDataset({
                modelId: model.id,
                columnsToDelete: Object.entries(deleteColumns.current)
                  .filter(([, v]) => v)
                  .map(([k]) => k),
                columnsTypesToUpdate: Object.entries(changeTypes.current)
                  .filter(([, v]) => v)
                  .reduce(
                    (acc, [k, v]) => ({
                      ...acc,
                      [k]: originalCategoryTranslate[v] ?? v,
                    }),
                    {},
                  ),
                token,
                signout,
              })
                .then(async (response) => {
                  if (response?.ok) {
                    const updatedModel = await getModelById(
                      model.id,
                      token,
                      signout,
                    )
                    updateModel(null, updatedModel)
                    queryClient.invalidateQueries([
                      'viewData-infinite',
                      model?.id,
                      'imported',
                    ])
                    queryClient.invalidateQueries([
                      'model-summary',
                      model.id,
                      'imported',
                    ])
                    queryClient.invalidateQueries([
                      'model-kmean-clusters',
                      model?.id,
                    ])
                    queryClient.invalidateQueries(['columnsDeleted', model?.id])
                    NotificationManager.info(t('Updated dataset'))
                    deleteColumns.current = {}
                    changeTypes.current = {}
                    setIteration((i) => i + 1)
                    setShowConfirm(false)
                  } else NotificationManager.error(t('Error updating dataset'))
                })
                .catch((e) => {
                  NotificationManager.error(t('Error updating dataset'))
                })
                .finally(() => {
                  setUpdating(false)
                })
            }}
          >
            {t(empty ? 'Go back' : 'Confirm changes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}
