import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { SiZapier } from 'react-icons/si'
import { useTranslation } from 'react-i18next'

import Connector from '../model-content/Connector'

export default function ZapierPrediction() {
  const { t } = useTranslation()

  return (
    <Container>
      <Row>
        <Col md={12} className="mt-2">
          <p>
            {t(
              'Zapier is a web service that allows you to connect your apps and automate workflows. You can connect your apps to Zapier and then create a Zap that will automate a workflow for you. For example, you can create a Zap that will add a prediction to a Database every time you make a prediction in the app.',
            )}
          </p>
        </Col>
        <Col md={12} align="center" className="my-4">
          <Connector
            name={t('Connect with zapier')}
            description={t('Create a connection with zapier')}
            icon=<SiZapier style={{ color: '#FF4A00', fontSize: 35 }} />
            onClick={() =>
              window.open(
                'https://zapier.com/developer/public-invite/176834/5ed3079d1526bda8c5bf029191c11244/',
                '_blank',
              )
            }
          />
        </Col>
        <Col md={12}>
          <p>
            <strong>{t('How to use Zapier with the app')}</strong>
          </p>
          <p>
            <strong>{t('Step')} 1:</strong>{' '}
            {t('Create a zap with a trigger event.')}
            <br />
            <strong>{t('Step')} 2:</strong>{' '}
            {`${t('Select your')} ${t('NextBrain')} ${t('model')}.`}
            <br />
            <strong>{t('Step')} 3:</strong>{' '}
            {t(
              'Pass in the relevant fields and connect it to any other action.',
            )}
          </p>
        </Col>
      </Row>
    </Container>
  )
}
