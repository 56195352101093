import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import {
  MdRadioButtonUnchecked,
  MdRadioButtonChecked,
  MdDelete,
  MdEdit,
} from 'react-icons/md'
import {
  HiOutlineClipboardCopy,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import { v4 as uuidv4 } from 'uuid'

import { useAuth } from '../../providers/AuthProvider'
import { formatDate } from '../../util/other'

import HelpTooltip from '../model-content/HelpTooltip'
import NextbrainSelect from '../model-content/NextbrainSelect'

const AI_SOURCES = [
  { label: 'OpenAI', value: 'openai' },
  { label: 'Microsoft Azure', value: 'azure' },
  { label: 'Custom LLM', value: 'custom' },
]

const BOT_MODELS = [
  { label: 'GPT 4.0', value: 'gpt-4' },
  { label: 'GPT 3.5', value: 'gpt-3.5-turbo' },
]

const AZURE_NB_DEPLOYMENTS = [
  {
    label: 'Nextbrain 4',
    value: 'NextBrain-4',
  },
]

function CustomDeployment({ llm, config, ...props }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(!config?.openai_api_key)

  return (
    <Row {...props}>
      <Col lg={6} md={4} xs={12} className="mb-3 ">
        {t(
          'If you have a custom deployment with an API similar to OpenAI you can connect it here',
        )}
        <br />
        <br />
        {t(
          'This feature enables you to override the default {{NextBrain}} configuration for all your requests, ensuring that interactions with the chatbot do not contribute towards your plan limits.',
          { NextBrain: t('NextBrain') },
        )}
      </Col>
      <Col className="token-box p-3" lg={6} md={8} xs={12}>
        <Row className="input-container-nb">
          <Col xs={12}>
            <Row>
              <Col xs={12} className="mb-1">
                <strong> {t('Model name')}</strong>
              </Col>
              <Col xs={12} className="mb-1">
                <Form.Control
                  className="nb-input"
                  placeholder="Your model name"
                  pattern={'.+'}
                  defaultValue={config.deployment_name}
                  onChange={(e) => {
                    config.deployment_name = e.target.value
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-1">
                <strong> {t('URL')}</strong>
              </Col>
              <Col xs={12} className="mb-1">
                <Form.Control
                  className="nb-input"
                  placeholder="Endpoint URL"
                  pattern={'.+'}
                  defaultValue={config?.openai_api_base ?? ''}
                  onChange={(e) => (config.openai_api_base = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-1">
                <strong> {t('API type')}</strong>
              </Col>
              <Col xs={12} className="mb-1">
                <Form.Control
                  className="nb-input"
                  placeholder="Endpoint URL"
                  defaultValue={config?.openai_api_type ?? ''}
                  onChange={(e) => (config.openai_api_type = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-1">
                <strong> {t('ApI Version')}</strong>
              </Col>
              <Col xs={12} className="mb-1">
                <Form.Control
                  className="nb-input"
                  placeholder="Api version"
                  defaultValue={config?.openai_api_version ?? ''}
                  onChange={(e) => (config.openai_api_version = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-1">
                <strong> {t('API Key')}</strong>
              </Col>
              <Col
                style={{ minWidth: '100%' }}
                className="position-relative mb-3"
                xs={12}
              >
                {show ? (
                  <input
                    className="w-100 token-input"
                    onChange={(e) => {
                      config.openai_api_key = e.target.value
                    }}
                    defaultValue={config?.openai_api_key ?? ''}
                  />
                ) : (
                  <input
                    className="w-100 token-input"
                    type="password"
                    onChange={() => {}}
                    value={config?.openai_api_key ?? ''}
                  />
                )}
                <Button
                  onClick={() => {
                    setShow((s) => !s)
                  }}
                  className="inside-input-button"
                  style={{ right: '39px' }}
                >
                  {show ? (
                    <HiOutlineEye size={20} />
                  ) : (
                    <HiOutlineEyeOff size={20} />
                  )}
                </Button>
                <Button
                  onClick={() => {
                    if (navigator?.clipboard?.writeText) {
                      NotificationManager.info(t('Copied to clipboard'))
                      navigator.clipboard.writeText(config?.openai_api_key)
                    } else NotificationManager.error('Clipboard not supported')
                  }}
                  className="inside-input-button"
                  style={{ right: '12px' }}
                >
                  <HiOutlineClipboardCopy size={20} />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function AzureConfig({ llm, config, ...props }) {
  const { t } = useTranslation()

  const [customKey, setCustomKey] = useState(config?.openai_api_key ?? '')
  const [apiVersion, setApiVersion] = useState(config?.openai_api_version ?? '')
  const [apiBase, setApiBase] = useState(config?.openai_api_base ?? '')
  const [deployName, setDeployName] = useState(config?.deployment_name ?? '')
  const [show, setShow] = useState(!config?.openai_api_key)

  const [customDeploySelect, setCustomDeploySelect] = useState(() => {
    return (
      AZURE_NB_DEPLOYMENTS.find((v) => v.value === config?.deployment_name) ??
      AZURE_NB_DEPLOYMENTS[0]
    )
  })
  const [customDeploy, setCustomDeploy] = useState(
    config?.customDeploy ?? !!apiBase,
  )

  useEffect(() => {
    if (customDeploy) {
      config.customDeploy = true
      config.openai_api_key = customKey
      config.openai_api_version = apiVersion
      config.openai_api_base = apiBase
      config.deployment_name = deployName
    }
  }, [config, customKey, apiVersion, apiBase, deployName, customDeploy])

  useEffect(() => {
    if (!customDeploy) {
      config.customDeploy = false
      config.deployment_name = customDeploySelect?.value
    }
  }, [config, customDeploySelect, customDeploy])

  return (
    <Row {...props}>
      <Col lg={6} md={4} xs={12} className="mb-3 ">
        {t('If you have a custom deployment in')}
        <a
          href="https://learn.microsoft.com/en-us/azure/machine-learning/how-to-deploy-online-endpoints?view=azureml-api-2&tabs=azure-cli"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' Microsoft Azure '}
        </a>
        {t(
          `you can configure it here instead of using the resources associated with your plan.`,
        )}
        <br />
        <br />
        {t(
          'This feature enables you to override the default {{NextBrain}} configuration for all your requests, ensuring that interactions with the chatbot do not contribute towards your plan limits.',
          { NextBrain: t('NextBrain') },
        )}
      </Col>
      <Col className="token-box p-3" lg={6} md={8} xs={12}>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Check
              value={customDeploy}
              onChange={(e) => setCustomDeploy(e.target.checked)}
              className="nb-wide-switch"
              type="switch"
              label={t('Use personal Azure deployment')}
            />
          </Col>
        </Row>
        {customDeploy ? (
          <Row className="input-container-nb">
            <Col xs={12}>
              <Row>
                <Col xs={12} className="mb-1">
                  <strong> {t('API Revision')}</strong>
                </Col>
                <Col xs={12} className="mb-1">
                  <Form.Control
                    className="nb-input"
                    placeholder="yyyy-mm-dd or yyyy-mm-dd-preview"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}(-preview)?"
                    value={apiVersion}
                    onChange={(e) => setApiVersion(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  <strong> {t('Deploy name')}</strong>
                </Col>
                <Col xs={12} className="mb-1">
                  <Form.Control
                    className="nb-input"
                    placeholder="Your deploy name"
                    pattern={'.+'}
                    value={deployName}
                    onChange={(e) => setDeployName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  <strong> {t('URL')}</strong>
                </Col>
                <Col xs={12} className="mb-1">
                  <Form.Control
                    className="nb-input"
                    placeholder="Endpoint URL"
                    pattern={'.+'}
                    value={apiBase}
                    onChange={(e) => setApiBase(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  <strong> {t('API Key')}</strong>
                </Col>
                <Col
                  style={{ minWidth: '100%' }}
                  className="position-relative mb-3"
                  xs={12}
                >
                  {show ? (
                    <input
                      className="w-100 token-input"
                      onChange={(e) => {
                        setCustomKey(e.target.value)
                      }}
                      value={customKey}
                    />
                  ) : (
                    <input
                      className="w-100 token-input"
                      type="password"
                      onChange={() => {}}
                      value={customKey}
                    />
                  )}
                  <Button
                    onClick={() => {
                      setShow((s) => !s)
                    }}
                    className="inside-input-button"
                    style={{ right: '39px' }}
                  >
                    {show ? (
                      <HiOutlineEye size={20} />
                    ) : (
                      <HiOutlineEyeOff size={20} />
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      if (navigator?.clipboard?.writeText) {
                        NotificationManager.info(t('Copied to clipboard'))
                        navigator.clipboard.writeText(customKey)
                      } else
                        NotificationManager.error('Clipboard not supported')
                    }}
                    className="inside-input-button"
                    style={{ right: '12px' }}
                  >
                    <HiOutlineClipboardCopy size={20} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} className="mb-1">
                  <strong> {t('Deploy name')}</strong>
                </Col>
                <Col xs={12} className="mb-3">
                  <NextbrainSelect
                    value={customDeploySelect}
                    placeholder={t('Select Deployment') + '...'}
                    onChange={(value) => setCustomDeploySelect(value)}
                    options={AZURE_NB_DEPLOYMENTS}
                    hideSelectedOptions={false}
                    type={'dark'}
                    autocomplete="off"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

function OpenAIConfig({ llm, config, ...props }) {
  const { t } = useTranslation()

  const [botModel, setBotModel] = useState(
    () =>
      BOT_MODELS.find((m) => m.value === config?.openai_model_name) ??
      BOT_MODELS[0],
  )

  useEffect(() => {
    config.openai_model_name = botModel.value
  }, [botModel, config])

  const [show, setShow] = useState(config.openai_api_key === '')

  return (
    <Row {...props}>
      <Col lg={6} md={12} xs={12} className="mb-3 ">
        {t('If you have a custom ')}
        <a
          href="https://platform.openai.com/overview"
          target="_blank"
          rel="noopener noreferrer"
        >
          OpenAI
        </a>
        {t(' key, you can set it here.')}
        <br />
        <br />
        {t(
          'This feature enables you to override the default {{NextBrain}} key for all your requests, ensuring that interactions with the chatbot do not contribute towards your plan limits.',
          { NextBrain: t('NextBrain') },
        )}
      </Col>
      <Col className="token-box p-3" lg={6} md={12} xs={12}>
        <Col xs={12} className="mb-1">
          <strong> {t('Model')}</strong>
        </Col>
        <NextbrainSelect
          value={botModel}
          placeholder={t('Select bot model') + '...'}
          onChange={(value) => {
            setBotModel(value)
            config.openai_model_name = value?.value
          }}
          options={BOT_MODELS}
          hideSelectedOptions={false}
          type={'dark'}
          autocomplete="off"
        />
        <Col xs={12}>
          {/* Stops browsers from thinking this set of inputs behaves as a login/register form, dont remove! */}
          <input className="d-none" />
          <input className="d-none" />
        </Col>
        <Col xs={12} className="mt-3 mb-1">
          <strong> {t('API Key')}</strong>
          <HelpTooltip
            className="help-select-icon mx-2"
            message={
              <Row>
                <Col xs={12}>
                  <span className="small">
                    {t(
                      'Use your own API key to access the OpenAI API to avoid congestion and add privacy to your data.',
                    )}
                  </span>
                </Col>
                <Col className="mt-3" xs={12}>
                  <span className="small">
                    {t(
                      'You can start using Nextbrain API again by simply clearing this input.',
                    )}
                  </span>
                </Col>
              </Row>
            }
          />
          <span className="small">{t('(optional)')}</span>
        </Col>
        <Col xs={12}>
          <Row>
            <Col
              style={{ minWidth: '100%' }}
              className="position-relative mb-3"
            >
              {show ? (
                <input
                  className="w-100 token-input"
                  onChange={(e) => {
                    config.openai_api_key = e.target.value
                  }}
                  defaultValue={config?.openai_api_key ?? ''}
                />
              ) : (
                <input
                  className="w-100 token-input"
                  type="password"
                  onChange={() => {}}
                  value={config.openai_api_key}
                />
              )}
              <Button
                onClick={() => {
                  setShow((s) => !s)
                }}
                className="inside-input-button"
                style={{ right: '39px' }}
              >
                {show ? (
                  <HiOutlineEye size={20} />
                ) : (
                  <HiOutlineEyeOff size={20} />
                )}
              </Button>
              <Button
                onClick={() => {
                  if (navigator?.clipboard?.writeText) {
                    NotificationManager.info(t('Copied to clipboard'))
                    navigator.clipboard.writeText(config.openai_api_key)
                  } else NotificationManager.error('Clipboard not supported')
                }}
                className="inside-input-button"
                style={{ right: '12px' }}
              >
                <HiOutlineClipboardCopy size={20} />
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  )
}

const CONFIG_COMPONENT = {
  openai: OpenAIConfig,
  azure: AzureConfig,
  custom: CustomDeployment,
}

function getDefaultConfiguration(llm, source) {
  switch (source) {
    case 'azure':
      return {
        name: llm?.name ?? '',
        description: llm?.description ?? '',
        openai_api_key: llm?.openai_api_key ?? '',
        openai_api_version: llm?.openai_api_version ?? '',
        openai_api_base: llm?.openai_api_base ?? '',
        deployment_name: llm?.deployment_name ?? '',
        customDeploy: llm?.customDeploy ?? !!llm?.openai_api_base,
      }
    case 'custom':
      return {
        name: llm?.name ?? '',
        description: llm?.description ?? '',
        openai_api_key: llm?.openai_api_key,
        openai_api_version: llm?.openai_api_version,
        openai_api_base: llm?.openai_api_base,
        openai_api_type: llm?.openai_api_type,
        deployment_name: llm?.deployment_name,
      }
    case 'openai':
    default:
      return {
        name: llm?.name ?? '',
        description: llm?.description ?? '',
        openai_model_name: llm?.openai_model_name ?? 'gpt-3.5-turbo',
        openai_api_key: llm?.openai_api_key ?? '',
      }
  }
}

function LLModal({ llm, onFinish, onCancel, ...props }) {
  const { t } = useTranslation()
  const [selectedSource, setSelectedSource] = useState(() => {
    if (llm) return AI_SOURCES.find((s) => s.value === llm.source)

    return null
  })
  const [[Config, values], setCurrentConfig] = useState([() => null, {}])

  useEffect(() => {
    if (!selectedSource) return
    const Config = CONFIG_COMPONENT[selectedSource?.value] ?? (() => null)
    setCurrentConfig([
      Config,
      getDefaultConfiguration(llm, selectedSource?.value),
    ])
    // eslint-disable-next-line
  }, [selectedSource])

  return (
    <Modal show={true} onHide={onCancel} size={'xl'}>
      <Modal.Header closeButton>
        {llm ? (
          <Modal.Title>{t('Edit LLM')}</Modal.Title>
        ) : (
          <Modal.Title>{t('New LLM')}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Row className="w-100 px-4">
          <Col style={{ minWidth: '400px' }} xs="auto">
            <NextbrainSelect
              value={selectedSource}
              placeholder={t('Select source') + '...'}
              onChange={(value) => setSelectedSource(value)}
              options={AI_SOURCES}
              hideSelectedOptions={false}
              type={'train'}
              autocomplete="off"
            />
          </Col>
          {selectedSource && (
            <Col xs={12}>
              <Row>
                <Col xl={6} md={12}>
                  <Row>
                    <Col xs={12} className="mt-2">
                      {t('Name')}
                    </Col>
                    <Col className="mt-2" xs={12}>
                      <Form.Control
                        className="nb-input"
                        type="text"
                        placeholder={t('Name')}
                        defaultValue={values?.name ?? ''}
                        onChange={(e) => (values.name = e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} md={12}>
                  <Row>
                    <Col xs={12} className="mt-2">
                      {t('Description')}
                    </Col>
                    <Col className="mt-2" xs={12}>
                      <Form.Control
                        className="nb-input"
                        type="text"
                        placeholder={t('Description')}
                        defaultValue={values?.description ?? ''}
                        onChange={(e) => (values.description = e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          <Col className="mt-4" xs={12}>
            <Config config={values} llm={llm} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="justify-content-center w-100">
          <Col className="me-2" xs="auto">
            <Button
              className="py-2 px-4 largep"
              disabled={!selectedSource || !selectedSource}
              onClick={() =>
                onFinish({ ...values, source: selectedSource?.value })
              }
            >
              {t('Save')}
            </Button>
          </Col>
          <Col className="ms-2" xs="auto">
            <Button
              className="py-2 px-4 largep original"
              variant="danger"
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

function LLM({ llm, onActivate, onEdit, onDelete, ...props }) {
  return (
    <Row
      className={`llm-configuration-item me-1 flex-column justify-content-between ${
        props?.className ?? ''
      } ${llm?.editable ? '' : 'disabled-llm-item'} h-100`}
    >
      <Col xs={12}>
        <Row>
          <Col
            xs={11}
            title={llm.name}
            className="d-inline-block text-truncate bold"
          >
            {llm.name}
          </Col>
          <Col
            className="d-flex justify-content-end align-items-center px-0"
            xs={1}
          >
            {llm?.active ? (
              <MdRadioButtonChecked
                className="cursor-pointer icon-btn"
                color="var(--nextbrain-tables-graph-bar-color)"
                size={20}
              />
            ) : (
              <MdRadioButtonUnchecked
                className="cursor-pointer icon-btn"
                onClick={onActivate}
                size={20}
              />
            )}
          </Col>
          <Col className="smallp py-3 overflow-hidden" xs={12}>
            {llm?.description ?? ''}
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row className="justify-content-between">
          <Col className="smallp" xs={'auto'}>
            {llm?.date ?? ''}
          </Col>
          <Col
            xs="auto"
            className="px-0 d-flex align-items-start control-llm-item"
          >
            <MdEdit className="icon-btn" onClick={onEdit} size={22} />
            <MdDelete className="ms-2 icon-btn" onClick={onDelete} size={22} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function LLMConfig({ ...props }) {
  const { t } = useTranslation()
  const { user, updateuser } = useAuth()
  const [modal, setModal] = useState(null)

  const llms = user?.llm_config?.saved_models ?? [
    {
      id: uuidv4(),
      name: 'Azure/Nextbrain 4',
      deployment_name: 'NextBrain-4',
      customDeploy: false,
      source: 'azure',
      editable: false,
      active: true,
      description: 'Nextbrain custom deployment in Microsoft Azure',
      date: formatDate(new Date()),
    },
  ]

  const activate = (llm) => {
    const config = { ...user?.llm_config, saved_models: llms }
    switch (llm.source) {
      case 'azure':
        config.model_kwargs = {}
        config.model_name = 'azure'
        if (llm.customDeploy) {
          config.model_kwargs.openai_api_key = llm.openai_api_key
          config.model_kwargs.openai_api_version = llm.openai_api_version
          config.model_kwargs.openai_api_base = llm.openai_api_base
          config.model_kwargs.deployment_name = llm.deployment_name
        } else {
          config.model_kwargs.deployment_name = llm.deployment_name
        }
        break
      case 'custom':
        config.model_kwargs = {}
        config.model_name = 'custom'
        config.model_kwargs.openai_api_key = llm.openai_api_key
        config.model_kwargs.openai_api_version = llm.openai_api_version
        config.model_kwargs.openai_api_base = llm.openai_api_base
        config.model_kwargs.openai_api_type = llm.openai_api_type
        config.model_kwargs.deployment_name = llm.deployment_name
        break
      case 'openai':
      default:
        config.model_kwargs = {}
        config.model_kwargs.openai_model_name =
          llm?.openai_model_name ?? 'gpt-3.5-turbo'
        config.model_name = 'openai'
        if (llm?.openai_api_key)
          config.model_kwargs.openai_api_key = llm?.openai_api_key
        break
    }
    return config
  }

  return (
    <Row>
      <Col className="d-flex justify-content-end" xs={12}>
        <Button
          onClick={() =>
            setModal(
              <LLModal
                onFinish={(data) => {
                  llms.push({
                    ...data,
                    id: uuidv4(),
                    editable: true,
                    date: formatDate(new Date()),
                  })
                  updateuser({
                    llm_config: { ...user?.llm_config, saved_models: llms },
                  })
                  setModal(null)
                }}
                onCancel={() => setModal(null)}
              />,
            )
          }
        >
          {t('New LLM')} <FaPlus className="pb-1" size={20} />
        </Button>
      </Col>
      <Col className="mt-3" xs={12}>
        <Row>
          {llms.map((llm, i) => (
            <Col xl={3} lg={6} md={6} xs={12} key={llm.id}>
              <LLM
                llm={llm}
                className="mb-2"
                onActivate={() => {
                  llms.forEach((l) => (l.active = false))
                  llm.active = true
                  const config = activate(llm)
                  updateuser({ llm_config: config })
                  NotificationManager.success(
                    t('Using model:') + ' ' + (llm.name ?? ''),
                  )
                }}
                onDelete={() =>
                  NotificationManager.warning(
                    <Row>
                      <Col xs={12}>
                        {t('Are you sure you want to delete this LLM?')}
                      </Col>
                      <Col xs={12}>
                        <Row className="flex-nowrap mt-3">
                          <Col className="" xs={6}>
                            <Button
                              className="py-2 w-100 smallp"
                              onClick={async () => {
                                llms.splice(i, 1)
                                const oneActive = llms.some((l) => l.active)
                                let config = {
                                  ...user?.llm_config,
                                  saved_models: llms,
                                }
                                if (!oneActive && llms.length) {
                                  llms[0].active = true
                                  config = activate(llms[0])
                                }
                                updateuser({ llm_config: config })
                              }}
                            >
                              {t('Confirm')}
                            </Button>
                          </Col>
                          <Col className="" xs={6}>
                            <Button
                              className="py-2 w-100 original smallp"
                              variant="danger"
                            >
                              {t('Cancel')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>,
                    null,
                    50000,
                  )
                }
                onEdit={() =>
                  setModal(
                    <LLModal
                      llm={llm}
                      onFinish={(data) => {
                        const newData = { ...llm, ...data }
                        llms[i] = newData
                        updateuser({
                          llm_config: {
                            ...user?.llm_config,
                            saved_models: llms,
                          },
                        })
                        setModal(null)
                      }}
                      onCancel={() => setModal(null)}
                    />,
                  )
                }
              />
            </Col>
          ))}
        </Row>
      </Col>
      {modal}
    </Row>
  )
}
