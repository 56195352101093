import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { useQueryClient, useQuery } from 'react-query'
import { Row, Col, Form, Button, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { GiReturnArrow } from 'react-icons/gi'
import { NotificationManager } from 'react-notifications'
import { useAuth } from '../../providers/AuthProvider'
import NextbrainSelect from '../model-content/NextbrainSelect'
import StatusMessage from '../model-content/StatusMessage'
import { ImCross } from 'react-icons/im'
import { FaCalendarAlt, FaPlus, FaHandHoldingMedical } from 'react-icons/fa'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import {
  getMMMOptimizedTable,
  getMMMSaturationCurves,
} from '../../services/model'
import { enforceValidation } from '../../util/validation'
import { useModels } from '../../providers/ModelProvider'
import { useSockets } from '../../providers/SocketProvider'
import { formatDate, zip } from '../../util/other'
import { svgDefaultProps as barDefaultProps, ResponsiveBar } from '@nivo/bar'
import { defaultProps as pieDefaultProps, ResponsivePie } from '@nivo/pie'
import { nFormatter, readableNumberMMM, round } from '../utils/formating'
import {
  generateMMMColorMap,
  getMMMMediaAverageContribution,
  getMMMNonFeatureColumns,
  testAccuracy,
} from '../../util/models'
import { colors } from './config'
import './Optimize.css'
import { getMMMOutcomeBudgetOptimization } from '../../services/model'
import BestSpend from './BestSpend'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'
import { nivoProps, useBarLegend, usePieLegend } from '../utils/ui'
import MMMInsightHeading from '../model-content/model-summary/MMMInsightHeading'
import YearlyLayer from './YearlyLayer'
import Loading from '../loading/LoadingSmall'
import WarningMMM from '../model-content/train-flow/mmm/WarningMMM'
import { OptimizeCard } from './OptimizeCard'
import {
  HelpOptimizedMediaOutcome,
  HelpOptimizedMediaOutcomeRoi,
  HelpOptimizedMediaOutcomeRoiTotal,
  HelpOptimizedMediaOutcomeTotal,
  HelpOptimizedOutcome,
  HelpOptimizedOutcomeRoi,
} from './MMMOptimizeHelp'
import NBInput from '../form/NBInput'
import InvalidOptimization from './InvalidOptimization'

function calculateGraph(id, data, colorScheme, weeks, t, Legend) {
  const base = data.graphData.map((d) => d.week - 1)
  let start = base[0]
  let end = base[base.length - 1]

  const legendData = Object.keys(data?.graphData?.[0] ?? {})
    .filter((k) => k !== 'id' && k !== 'week')
    .map((k) => ({
      id: k,
      color: colorScheme[k] ?? 'var(--nextbrain-main-color)',
    }))
  const csvKeyset = [
    'week',
    ...Object.keys(data?.graphData?.[0] ?? {}).filter(
      (d) => d !== 'week' && d !== 'id',
    ),
  ]
  return (
    <ResponsiveBar
      {...nivoProps}
      data={data.graphData}
      keys={data.itemKeys}
      indexBy="week"
      margin={{ top: 50, right: 350, bottom: 120, left: 110 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => colorScheme[d.id]}
      defs={[]}
      fill={[]}
      axisTop={null}
      axisRight={null}
      valueFormat={(d) => `${round(d, 2)}`}
      enableLabel={false}
      axisBottom={{
        tickSize: 15,
        tickPadding: 5,
        legend: t('Week'),
        legendPosition: 'middle',
        legendOffset: 100,
        tickRotation: -30,
      }}
      axisLeft={{
        tickSize: 5,
        translateX: -100,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Spend'),
        legendPosition: 'middle',
        legendOffset: -100,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[]}
      layers={[
        ...barDefaultProps.layers,
        () => (
          <div
            className="data-holder display-none"
            data-csv={encodeURIComponent(
              JSON.stringify([
                csvKeyset,
                ...data.graphData.map((d) => csvKeyset.map((k) => d[k])),
              ]),
            )}
            data-filename={`time_allocation__${id}`}
          ></div>
        ),
        (props) => (
          <YearlyLayer
            offset={70}
            start={start}
            end={end}
            ignoreLessThanYear={true}
            {...props}
          />
        ),
        (props) => <Legend data={legendData} {...props} />,
      ]}
    />
  )
}

function Constraint({
  model,
  constraint = {},
  i,
  optimizing,
  error = false,
  onDelete,
  channel,
  min,
  max,
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const minRef = useRef()
  const { data } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      const res = await getMMMSaturationCurves({
        modelId: model.id,
        token,
        signout,
      })
      return res
    },
    { staleTime: Infinity },
  )
  const [limit, setLimit] = useState('')

  useEffect(() => {
    if (data && data?.[channel]?.maximum && !limit)
      setLimit(` (${nFormatter(data?.[channel]?.maximum)})`)
    // eslint-disable-next-line
  }, [data])

  return (
    <>
      <Col
        className="d-flex align-items-center justify-content-end"
        style={{ minWidth: '50px', maxWidth: '50px' }}
      >
        <ImCross
          className={`mt-1 original ${
            onDelete ? 'cursor-pointer' : 'pe-none opacity-50'
          }`}
          onClick={onDelete}
          size={25}
        />
      </Col>
      <Col
        style={{ minWidth: 'calc(100% - 50px)', maxWidth: 'calc(100% - 50px)' }}
      >
        <NextbrainSelect
          className="basic-single mt-2"
          classNamePrefix="select"
          isSearchable={true}
          placeholder={t('Select media') + '...'}
          name={`media-to-spend-${i}`}
          isDisabled={optimizing}
          isClearable={true}
          onChange={(e) => {
            constraint.channel = e?.value
            if (e?.value && data?.[e.value]?.maximum)
              setLimit(` (${nFormatter(data[e.value].maximum)})`)
            else setLimit('')
          }}
          options={(model?.dataset?.columns_order ?? [])
            .map((element) => ({
              value: element,
              label: element,
            }))
            .filter((e) => {
              if (
                e.value === model.target ||
                e.value === model?.mmm?.datetime_col
              ) {
                return false
              }
              return e
            })}
          defaultValue={
            channel !== null ? { label: channel, value: channel } : undefined
          }
        />
      </Col>
      <Col
        className={`min-spend-container ${error ? 'error-input' : ''}`}
        xs={6}
        style={{ maxWidth: 'calc(50% - 25px)', marginLeft: '50px' }}
      >
        <NBInput
          validation={true}
          type="text"
          name={`min-spend-${i}`}
          disabled={optimizing}
          aria-label="Number"
          onKeyPress={enforceValidation({ numeric: true, clamp: { min: 0 } })}
          soft={true}
          className={`min-spend-input no-arrows force mt-2`}
          style={{ minHeight: 45 }}
          placeholder={t('Spend floor')}
          defaultValue={min ?? ''}
          onChange={(e) => {
            constraint.min = e.target.value
          }}
          ref={minRef}
        />
      </Col>
      <Col
        className={`min-spend-container ${error ? 'error-input' : ''}`}
        xs={6}
        style={{ maxWidth: 'calc(50% - 25px)' }}
      >
        <NBInput
          validation={true}
          type="text"
          name={`max-spend-${i}`}
          disabled={optimizing}
          aria-label="Number"
          onKeyPress={enforceValidation({ numeric: true, clamp: { min: 0 } })}
          className={`max-spend-input no-arrows force mt-2`}
          soft={true}
          style={{ minHeight: 45 }}
          placeholder={t('Ceil') + limit}
          defaultValue={max ?? ''}
          onChange={(e) => (constraint.max = e.target.value)}
          onBlur={() => {
            if (constraint.max && !constraint.min && constraint.min !== 0) {
              constraint.min = Math.round(constraint.max / 10)
              minRef.current.value = constraint.min
            }
          }}
        />
      </Col>
    </>
  )
}

function PlanningResults({
  model,
  budget,
  outcome,
  budgetMode = true,
  children,
  mediaImpact,
  ...props
}) {
  const { t } = useTranslation()
  if (
    Number.isNaN(budget) ||
    Number.isNaN(outcome) ||
    Number.isNaN(budget / outcome)
  )
    return <Loading />

  const acc = testAccuracy(model)
  let accuracy = 0.95
  if (!isNaN(acc)) accuracy = acc / 100

  const infoboxParms = budgetMode
    ? {
        titleTop: t('Media outcome'),
        titleTopRight: t('Media ROI'),
        titleBot: t('Total outcome'),
        titleBotRight: t('Total ROI'),
        helpTop: () => <HelpOptimizedMediaOutcome model={model} />,
        helpTopRight: () => <HelpOptimizedMediaOutcomeRoi model={model} />,
        helpBottom: () => <HelpOptimizedMediaOutcomeTotal model={model} />,
        helpBottomRight: () => (
          <HelpOptimizedMediaOutcomeRoiTotal model={model} />
        ),
        value: outcome,
        error: outcome * (1 - accuracy),
        mediaImpact: mediaImpact,
      }
    : {
        titleTop: t('Media spend'),
        titleTopRight: t('Media ROI'),
        helpTop: () => <HelpOptimizedOutcome model={model} />,
        helpTopRight: () => <HelpOptimizedOutcomeRoi model={model} />,
        value: budget / mediaImpact,
        error: budget * (1 - accuracy),
        mediaImpact: mediaImpact,
      }

  return (
    <Row
      {...props}
      style={{ maxWidth: 'calc(100% - 50px)', marginLeft: '50px' }}
      className={`optimize-card py-2 ${props.className ?? ''}`}
    >
      <MMMInsightHeading
        popupWidth={300}
        title="Planning results"
        help={false}
      />
      <Col xs={12}>
        <Row>
          {infoboxParms.titleTop && (
            <Col xl={6} md={12}>
              <OptimizeCard
                className="border-main-1 shadow-main-4-50"
                title={infoboxParms.titleTop}
                value={readableNumberMMM(
                  infoboxParms.value * infoboxParms.mediaImpact,
                  2,
                  4,
                )}
                errorMargin={readableNumberMMM(
                  infoboxParms.error * infoboxParms.mediaImpact,
                  2,
                  4,
                )}
                style={{ minHeight: '90%' }}
                help={infoboxParms.helpTop}
              />
            </Col>
          )}
          {infoboxParms.titleTopRight && (
            <Col xl={6} md={12}>
              <OptimizeCard
                className="border-success-1 shadow-success-4-50"
                title={infoboxParms.titleTopRight}
                value={readableNumberMMM(
                  (outcome * infoboxParms.mediaImpact) / budget,
                  2,
                  4,
                )}
                errorMargin={readableNumberMMM(
                  (1 - accuracy) /
                    (budget / (outcome * infoboxParms.mediaImpact)),
                  2,
                  4,
                )}
                style={{ minHeight: '90%' }}
                help={infoboxParms.helpTopRight}
              />
            </Col>
          )}
          {infoboxParms.titleBot && (
            <Col xl={6} md={12}>
              <OptimizeCard
                className="border-main-1 shadow-main-4-50"
                title={infoboxParms.titleBot}
                value={readableNumberMMM(infoboxParms.value, 2, 4)}
                errorMargin={readableNumberMMM(infoboxParms.error, 2, 4)}
                style={{ minHeight: '90%' }}
                help={infoboxParms.helpBottom}
              />
            </Col>
          )}
          {infoboxParms.titleBotRight && (
            <Col xl={6} md={12}>
              <OptimizeCard
                className="border-success-1 shadow-success-4-50"
                title={infoboxParms.titleBotRight}
                value={readableNumberMMM(outcome / budget, 2, 4)}
                errorMargin={readableNumberMMM(
                  (1 - accuracy) / (budget / outcome),
                  4,
                )}
                style={{ minHeight: '90%' }}
                help={infoboxParms.helpBottomRight}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

function deserializeConstraintSet(constraints, items, key) {
  Object.entries(items ?? {}).forEach(([channel, value]) => {
    constraints[channel] = constraints[channel] ?? {
      channel,
      min: null,
      max: null,
    }
    constraints[channel][key] = value
  })
}

function deserializeConstraints(budgetParams) {
  const constraints = {}
  deserializeConstraintSet(
    constraints,
    budgetParams?.channel_to_min_spend,
    'min',
  )
  deserializeConstraintSet(
    constraints,
    budgetParams?.channel_to_max_spend,
    'max',
  )
  const result = Object.values(constraints)
  if (result.length) return result

  return [{ channel: null, min: null, max: null }]
}

export default function CustomOptimize({ model, ...props }) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [budgetMode, setBudgetMode] = useState(true)
  const [constraints, setConstraints] = useState(() => {
    const constraints = [{ channel: null, min: null, max: null }]
    try {
      const params = model?.mmm?.budget_allocation_params
      return deserializeConstraints(params)
    } catch (e) {}
    return constraints
  })
  const [budgetConstraints, setBudgetConstraints] = useState(() => {
    const constraints = [{ channel: null, min: null, max: null }]
    try {
      const params = model?.mmm?.budget_allocation_budget_params
      return deserializeConstraints(params)
    } catch (e) {}
    return constraints
  })
  const [selectedFeatureBudget, setSelectedFeatureBudget] = useState()
  const [selectedFeatureOutcome, setSelectedFeatureOutcome] = useState()
  const pieLegend = usePieLegend({ height: 290, paddingX: 50, trim: 35 })

  const [selectedPerformance, setSelectedPerformance] = useState(null)
  const optionsPerformance = useMemo(() => {
    return [
      { label: t('Fast (max ~3min)'), value: 120 },
      { label: t('Accurate (max ~10min)'), value: 600 },
      { label: t('Complete (no time limit)'), value: 7200 },
    ]
  }, [t])

  const targetRef = useRef()
  const weekRef = useRef()
  const formRef = useRef()
  const { activeModel, isOptimizing, optimize, stopOptimize } = useModels()
  const { lastOptimizingMessage, onOptimizingMessage, offOptimizingMessage } =
    useSockets()
  const [statusMessage, setStatusMessage] = useState(lastOptimizingMessage)

  if (!model) model = activeModel
  const [colorScheme] = useState(() => {
    return generateMMMColorMap(model, colors)
  })

  const [budgetGraph, setBudgetGraph] = useState(<></>)
  const [outcomeGraph, setOutcomeGraph] = useState(<></>)

  const { data: outcomeResult, isLoading: outcomeLoading } = useQuery(
    ['mmm-model-optimization-outcome', model?.id],
    async () => {
      return await getMMMOutcomeBudgetOptimization({
        modelId: model?.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const { data: optimizedTable } = useQuery(
    ['CustomOptimizedTable', model?.id],
    async () => {
      return await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: true,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const optimizeTableBars = useMemo(() => {
    const graphData = []
    const itemKeys = []
    const pieDistribution = []
    const spendPerChannel = {}
    let weeks = 0
    try {
      const [id, total, start] = [
        'index',
        'Total Proposed spend',
        'Week_0',
      ].map((k) => optimizedTable.columns.indexOf(k))
      let acum = 0
      const spend = optimizedTable.data.reduce((acc, data) => {
        acc[data[id]] = data[total]
        acum += data[total]
        spendPerChannel[data[id]] = data
          .slice(start, data.length - 4 + start)
          .reduce((acc, cur) => acc + cur, 0)
        return acc
      }, {})

      Object.keys(spend).forEach((k) => (spend[k] /= acum))
      pieDistribution.push(
        ...Object.entries(spend)
          .filter(([k, v]) => v > 0.01)
          .map(([k, v]) => ({
            id: k,
            value: v * 100,
            color: colorScheme[k],
            label: k,
          })),
      )

      const validKeyRegs = /Week_[0-9]+/
      const validIndexes = optimizedTable.columns.reduce((acc, key, i) => {
        if (key.match(validKeyRegs)) acc[i] = true
        return acc
      }, {})
      const data = optimizedTable.data
        .map(([k, ...v]) =>
          spend[k] < 0.01
            ? null
            : {
                id: k,
                data: v.filter((_, i) => validIndexes[i + 1]),
              },
        )
        .filter((v) => v)
      const keys = data.map((d) => d.id)
      const newData = zip(data.map((d) => d.data)).map((v, i) => ({
        id: `week ${i + 1}`,
        week: i + 1,
        ...v.reduce((acc, cur, i) => {
          acc[keys[i]] = cur
          return acc
        }, {}),
      }))
      weeks = newData.length
      Object.keys(spendPerChannel).forEach((k) => {
        spendPerChannel[k] /= weeks
      })
      graphData.push(...newData)
      itemKeys.push(...keys)
    } catch (e) {}
    return { graphData, itemKeys, pieDistribution, spendPerChannel, weeks }
    // eslint-disable-next-line
  }, [optimizedTable])

  const { data: optimizedTableOutcome } = useQuery(
    ['CustomOptimizedTableOutcome', model?.id],
    async () => {
      return await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: true,
        weekly: true,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const optimizeTableOutcomeBars = useMemo(() => {
    const graphData = []
    const itemKeys = []
    const pieDistribution = []
    let totalSpend = 0
    const spendPerChannel = {}
    let weeks = 0
    try {
      const [id, total, start] = [
        'index',
        'Total Proposed spend',
        'Week_0',
      ].map((k) => optimizedTableOutcome.columns.indexOf(k))
      const spend = optimizedTableOutcome.data.reduce((acc, data) => {
        acc[data[id]] = data[total]
        totalSpend += data[total]
        spendPerChannel[data[id]] = data
          .slice(start, data.length - 4 + start)
          .reduce((acc, cur) => acc + cur, 0)
        return acc
      }, {})
      Object.keys(spend).forEach((k) => (spend[k] /= totalSpend))
      pieDistribution.push(
        ...Object.entries(spend)
          .filter(([k, v]) => v > 0.01)
          .map(([k, v]) => ({
            id: k,
            value: v * 100,
            color: colorScheme[k],
            label: k,
          })),
      )

      const validKeyRegs = /Week_[0-9]+/
      const validIndexes = optimizedTableOutcome.columns.reduce(
        (acc, key, i) => {
          if (key.match(validKeyRegs)) acc[i] = true
          return acc
        },
        {},
      )
      const data = optimizedTableOutcome.data.map(([k, ...v]) => ({
        id: k,
        data: v.filter((_, i) => validIndexes[i + 1]),
      }))
      const keys = data.map((d) => d.id)
      const newData = zip(data.map((d) => d.data)).map((v, i) => ({
        id: `week ${i + 1}`,
        week: i + 1,
        ...v.reduce((acc, cur, i) => {
          acc[keys[i]] = cur
          return acc
        }, {}),
      }))
      weeks = newData.length
      Object.keys(spendPerChannel).forEach((k) => {
        spendPerChannel[k] /= weeks
      })
      graphData.push(...newData)
      itemKeys.push(...keys)
    } catch (e) {}
    return {
      graphData,
      itemKeys,
      pieDistribution,
      totalSpend,
      spendPerChannel,
      weeks,
    }
    // eslint-disable-next-line
  }, [optimizedTableOutcome])

  const doOptimize = useCallback(() => {
    const target = targetRef.current.value
    const weeks = weekRef.current.value
    if (target === '' || weeks === '') {
      NotificationManager.error(
        t('Please fill in all fields, budget/outcome and weeks are required'),
      )
      return
    }
    if (Number.parseInt(weeks) < 4) {
      NotificationManager.error(t('Minimum period for optmization is 4 weeks'))
      return
    }

    const channelMin = {}
    const channelMax = {}
    const currentConstraints = budgetMode ? budgetConstraints : constraints
    const errors = []
    currentConstraints.forEach(({ channel, min, max }) => {
      if (channel) {
        min = Number.parseFloat(min)
        max = Number.parseFloat(max)
        if (!Number.isNaN(min)) channelMin[channel] = min
        if (!Number.isNaN(max)) channelMax[channel] = max
        if (!Number.isNaN(max) && !Number.isNaN(min) && min > max) {
          errors.push(`${channel} ${t('min is greater than max')}`)
        }
      }
    })
    if (errors.length > 0) {
      NotificationManager.error(
        <Row>
          {errors.map((error, index) => (
            <Col key={index} className="mb-2" xs={12}>
              {error}
            </Col>
          ))}
        </Row>,
      )
      return
    }
    optimize({
      modelId: model.id,
      channelToMinSpend: channelMin,
      channelToMaxSpend: channelMax,
      outcomeTarget: budgetMode ? null : target,
      budgetTarget: budgetMode ? target : null,
      weeks,
      time: selectedPerformance?.value ?? 120,
    })
    // eslint-disable-next-line
  }, [constraints, budgetConstraints, budgetMode, selectedPerformance])

  useEffect(
    () => {
      if (model) {
        const id = model.id
        const onNewMessage = (model, message) => {
          setStatusMessage(message)
        }
        onOptimizingMessage(onNewMessage, id)
        return () => offOptimizingMessage(onNewMessage, id)
      }
    },
    // eslint-disable-next-line
    [model],
  )

  const Legend = useBarLegend({ trim: 21 })

  useEffect(() => {
    setBudgetGraph(
      calculateGraph(
        model.id,
        optimizeTableBars,
        colorScheme,
        optimizeTableBars.weeks,
        t,
        Legend,
      ),
    )
    // eslint-disable-next-line
  }, [optimizeTableBars])
  useEffect(() => {
    setOutcomeGraph(
      calculateGraph(
        model.id,
        optimizeTableOutcomeBars,
        colorScheme,
        optimizeTableOutcomeBars.weeks,
        t,
        Legend,
      ),
    )
    // eslint-disable-next-line
  }, [optimizeTableOutcomeBars])

  const parseProgress = parseInt(model.mmm?.percent_optimize)
  const progress = Number.isNaN(parseProgress) ? 0 : parseProgress

  const spend = budgetMode
    ? model?.mmm?.budget_allocation_budget_params?.budget_target
    : optimizeTableOutcomeBars?.totalSpend

  const outcome = budgetMode
    ? outcomeLoading
      ? null
      : outcomeResult?.outcome
    : model?.mmm?.budget_allocation_params?.outcome_target

  const features =
    (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)?.graphData
      ?.length > 0 &&
    getMMMNonFeatureColumns(model).filter(
      (v) =>
        (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)
          .spendPerChannel[v] > 1,
    )

  const currentFeatureSelected = budgetMode
    ? selectedFeatureBudget
    : selectedFeatureOutcome

  useEffect(() => {
    if (!currentFeatureSelected?.value && features?.[0]) {
      if (budgetMode) {
        setSelectedFeatureBudget({ label: features[0], value: features[0] })
      } else {
        setSelectedFeatureOutcome({ label: features[0], value: features[0] })
      }
    }
  }, [
    currentFeatureSelected,
    selectedFeatureBudget,
    selectedFeatureOutcome,
    budgetMode,
    features,
  ])

  useEffect(() => {
    queryClient.invalidateQueries(['CustomOptimizedTableOutcome', model?.id])
    queryClient.invalidateQueries(['CustomOptimizedTable', model?.id])
    // eslint-disable-next-line
  }, [])
  const invalidBudgetOptimization =
    budgetMode && outcomeResult?.outcome && outcomeResult?.outcome < 0

  const formatPeriod = (val, setTooltipDate) => {
    const dateCol = model?.mmm?.datetime_col
    let res = null
    if (model.dataset.final_column_status[dateCol] === 'Integer') {
      const max = model.dataset.statistics[dateCol].max + 1
      res = t('Optimize for weeks') + `: ${max}-${max + val}`
    } else {
      const date = new Date(model.dataset.statistics[dateCol].max)
      const end = new Date(date.getTime() + val * 7 * 24 * 60 * 60 * 1000)
      res =
        t('Optimize from') +
        ` ${formatDate(date)} ${t('to')} ${formatDate(end)}`
    }
    if (setTooltipDate) setTooltipDate(res)
    return res
  }
  const [tooltipData, setTooltipDate] = useState(() => {
    const val = budgetMode
      ? model?.mmm?.budget_allocation_budget_params?.weeks
      : model?.mmm?.budget_allocation_params?.weeks
    if (val) return formatPeriod(val)
    return null
  })

  return (
    <Row
      {...props}
      className={`${
        props.className ?? ''
      } justify-content-center custom-optimize-control`}
    >
      <Col className="d-flex justify-content-center" xs={12}>
        <Row className="custom-optimize-mode-selector mb-4">
          <Col
            onClick={() => setBudgetMode(true)}
            className={` py-2 ${budgetMode ? 'active' : ''}`}
            xs={6}
          >
            <span className="d-inline-block text-trucate">
              {t('Based on my budget')}
            </span>
          </Col>
          <Col
            onClick={() => setBudgetMode(false)}
            className={` py-2 ${!budgetMode ? 'active' : ''}`}
            xs={6}
          >
            <span className="d-inline-block text-trucate">
              {t('Based on my outcome')}
            </span>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginLeft: '50px' }} xs={12}>
        <WarningMMM
          className="insight-model-content my-2 mx-3 py-2"
          model={model}
          timeColumn={model?.mmm?.datetime_col}
        />
      </Col>
      <Col xs={12}>
        <Row className="mt-3">
          <Col className="h5" style={{ marginLeft: '50px' }} xs={12}>
            <strong>
              {t(budgetMode ? 'Budget' : `Outcome`)}
              {budgetMode ? '' : ` = ${model?.target ?? 'target'}`}
            </strong>
          </Col>
          <Col xl={4} md={12}>
            <Row style={{ marginLeft: '50px' }}>
              <Col xs={12}>
                <Row>
                  <Col
                    className="position-relative enforced-validation-container ps-0"
                    xs={12}
                  >
                    {budgetMode ? (
                      <FaHandHoldingMedical
                        size={20}
                        className="form-input-icon-left-icon mt-2"
                        style={{ marginLeft: '-5px' }}
                      />
                    ) : (
                      <GiReturnArrow
                        size={20}
                        className="form-input-icon-left-icon mt-2"
                        style={{ marginLeft: '-5px' }}
                      />
                    )}
                    <NBInput
                      validation={true}
                      className="py-2 form-input-icon-left w-100"
                      soft={true}
                      style={{ minWidth: '200px' }}
                      ref={targetRef}
                      key={budgetMode}
                      defaultValue={
                        (budgetMode
                          ? model?.mmm?.budget_allocation_budget_params
                              ?.budget_target
                          : model?.mmm?.budget_allocation_params
                              ?.outcome_target) ?? ''
                      }
                      onKeyPress={enforceValidation({
                        numeric: true,
                        clamp: { min: 1 },
                      })}
                      placeholder={t(budgetMode ? 'Budget' : 'Outcome') + '...'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col className="h5 mt-3 ps-0" xs={12}>
                    <strong>{t('Period (weeks)')}</strong>
                    {tooltipData && (
                      <div className="mt-1 smallp">{tooltipData}</div>
                    )}
                  </Col>
                  <Col
                    className="position-relative enforced-validation-container ps-0"
                    xs={12}
                  >
                    <FaCalendarAlt
                      size={20}
                      className="form-input-icon-left-icon mt-2"
                      style={{ marginLeft: '-5px' }}
                    />
                    <NBInput
                      validation={true}
                      className={`py-2 form-input-icon-left w-100 `}
                      soft={true}
                      style={{ minWidth: '200px' }}
                      ref={weekRef}
                      key={budgetMode}
                      onChange={(e) => {
                        const num = e.target.value
                        if (!num) {
                          setTooltipDate(null)
                        } else {
                          try {
                            const val = Number.parseInt(num)
                            formatPeriod(val, setTooltipDate)
                          } catch (e) {}
                        }
                      }}
                      defaultValue={
                        (budgetMode
                          ? model?.mmm?.budget_allocation_budget_params?.weeks
                          : model?.mmm?.budget_allocation_params?.weeks) ?? ''
                      }
                      onKeyPress={enforceValidation({
                        numeric: true,
                        clamp: { min: 4, max: 54 },
                      })}
                      placeholder={t('Weeks') + '...'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className="mt-4 row-add-constrait"
              style={{ marginLeft: '50px' }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center position-relative"
              >
                <Button
                  className="py-2 px-2"
                  onClick={() =>
                    budgetMode
                      ? setBudgetConstraints((c) => [
                          ...c,
                          { channel: null, min: null, max: null },
                        ])
                      : setConstraints((c) => [
                          ...c,
                          { channel: null, min: null, max: null },
                        ])
                  }
                >
                  <div className="d-inline-flex align-items-center">
                    {t('Add Constraint')} <FaPlus className="ms-2" />
                  </div>
                </Button>
                <MMMInsightHeading
                  title=""
                  style={{
                    maxWidth: '50px',
                    position: 'absolute',
                    left: '-40px',
                    top: 'calc(50% - 20px)',
                  }}
                  className="d-flex align-items-center h-auto"
                  description={
                    <Row>
                      <Col xs={6}>
                        <Row className="d-flex align-items-center h-100 text-center">
                          <Col xs={12}>
                            {t(
                              `Constraints allow you to to set minimum and maximum spend. You might have a minimum or maximum allocated budgets for certain channels`,
                            )}
                            <strong>
                              {' ' +
                                t(
                                  'for the whole period, not individual weeks.',
                                )}
                            </strong>
                          </Col>
                          <Col className="mt-1" xs={12}>
                            <Image
                              style={{ borderRadius: '5px' }}
                              width={300}
                              src={'/min-max-constraints.png'}
                            />
                          </Col>
                          <Col className="mt-2" xs={12}>
                            {t(
                              'For example the previous constraint will set a minimum spend of 12,000 and a maximum of 20,000 for the channel TV.',
                            )}
                          </Col>
                          <Col xs={12}>
                            {t(
                              'The monetary units are dependant of the data fed into the MMM model.',
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="art-add-constraint" xs={6}></Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Form ref={formRef}>
                {(budgetMode ? budgetConstraints : constraints).map(
                  (constraint, i) => (
                    <Col
                      className="my-2"
                      key={`${budgetMode}-${i}-${constraint?.channel}`}
                      xs={12}
                    >
                      <Row>
                        <Constraint
                          model={model}
                          i={i}
                          onDelete={
                            (budgetMode ? budgetConstraints : constraints)
                              .length > 1
                              ? () => {
                                  ;(budgetMode
                                    ? setBudgetConstraints
                                    : setConstraints)((c) =>
                                    c.filter((_, index) => index !== i),
                                  )
                                }
                              : null
                          }
                          constraint={constraint}
                          {...constraint}
                        />
                      </Row>
                    </Col>
                  ),
                )}
              </Form>
            </Row>
            <Row style={{ marginLeft: '50px' }}>
              <Col className="h5 mt-3 ps-0" xs={12}>
                <strong> {t('Planning accuracy')}</strong>
                <span className="small">({t('optional')})</span>
              </Col>
              <Col className="ps-0" xs={12}>
                <NextbrainSelect
                  className="basic-single w-100"
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder={t('Accuracy') + '...'}
                  isDisabled={false}
                  isClearable={true}
                  options={optionsPerformance}
                  value={selectedPerformance}
                  onChange={(v) => setSelectedPerformance(v)}
                />
              </Col>
            </Row>
            <Row>
              <Col
                className="mt-3"
                style={{ marginLeft: '50px', minWidth: 'calc(100% - 50px)' }}
              >
                {isOptimizing ? (
                  <Button
                    onClick={() => stopOptimize({ modelId: model?.id })}
                    className="py-2 original w-100"
                    variant="danger"
                    disabled={
                      !!model?.mmm?.last_optimize_socket?.stop_optimization ||
                      model?.mmm?.stopping_optimize
                    }
                  >
                    {!!model?.mmm?.last_optimize_socket?.stop_optimization ||
                    model?.mmm?.stopping_optimize ? (
                      <span className="loading-tooltip">{t('Stopping')}</span>
                    ) : (
                      t('Stop')
                    )}
                  </Button>
                ) : (
                  <Button onClick={doOptimize} className="py-2  w-100">
                    {t('Calculate plan')}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center position-relative mt-xl-0 "
            xl={8}
            md={12}
          >
            {isOptimizing ? (
              <Row className="justify-content-center align-self-start mt-3">
                <Col className="h-100 d-flex align-items-center justify-content-center">
                  <div style={{ width: '200px' }}>
                    <CircularProgressbar
                      value={Math.min(progress, 96)}
                      text={`${Math.min(progress, 99)}%`}
                      strokeWidth={10}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: 'rounded',
                        pathTransitionDuration: 0.5,
                        pathColor: `#4240B5`,
                        trailColor: 'var(--nextbrain-background)',
                        textColor: 'var(--nextbrain-white-font)',
                        text: {
                          fontSize: '20px',
                        },
                      })}
                    />
                  </div>
                </Col>
                <Col xs={12}></Col>
                <Col className="mt-2" xs={10}>
                  <StatusMessage
                    className="darker"
                    history={statusMessage ? [statusMessage] : []}
                  />
                </Col>
              </Row>
            ) : (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)
                .pieDistribution.length ? (
              invalidBudgetOptimization ? (
                <InvalidOptimization className="h-100" />
              ) : (
                <>
                  <Row className="w-100 h-100">
                    <Col className="pb-2 mt-xl-0 mt-md-2" xs={12}>
                      <PlanningResults
                        model={model}
                        budget={spend}
                        budgetMode={budgetMode}
                        outcome={outcome}
                        mediaImpact={getMMMMediaAverageContribution(model)}
                      >
                        <DownloadGraphOverlay
                          buttonsStyle={{ top: '-15px', right: '20px' }}
                        ></DownloadGraphOverlay>
                      </PlanningResults>
                    </Col>
                  </Row>
                </>
              )
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>
      {!isOptimizing &&
        (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)
          .pieDistribution.length > 0 &&
        !invalidBudgetOptimization && (
          <Col
            style={{ maxWidth: 'calc(100% - 50px)', marginLeft: '50px' }}
            xs={12}
          >
            <Row className="mt-3">
              <Col className="optimize-card mt-2 pb-3" xs={12}>
                <Row className="position-relative">
                  <MMMInsightHeading
                    title="Plan Strategy"
                    description={'Budget distribution accordion the new plan'}
                    popupWidth={200}
                  />
                  <Col style={{ minHeight: '400px' }} xs={12} lg={6}>
                    <DownloadGraphOverlay>
                      <ResponsivePie
                        {...nivoProps}
                        data={
                          (budgetMode
                            ? optimizeTableBars
                            : optimizeTableOutcomeBars
                          ).pieDistribution
                        }
                        margin={{
                          left: 0,
                          bottom: 60,
                          right: 250,
                          top: 60,
                        }}
                        innerRadius={0.5}
                        colors={(d) => d.data.color}
                        padAngle={0.7}
                        cornerRadius={0}
                        axisLeft={null}
                        axisRight={null}
                        axisTop={null}
                        axisBottom={null}
                        activeOuterRadiusOffset={8}
                        valueFormat={(n) => `${round(n, 1)}%`}
                        borderWidth={1}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]],
                        }}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsDiagonalLength={15}
                        arcLinkLabelsStraightLength={20}
                        defs={[]}
                        fill={[]}
                        legends={[]}
                        layers={[
                          ...pieDefaultProps.layers,
                          () => (
                            <div
                              className="data-holder display-none"
                              data-csv={encodeURIComponent(
                                JSON.stringify([
                                  ['channel', 'media mix'],
                                  ...(budgetMode
                                    ? optimizeTableBars
                                    : optimizeTableOutcomeBars
                                  ).pieDistribution.map((d) => [d.id, d.value]),
                                ]),
                              )}
                              data-filename={`scenario_mediamix__${model.id}`}
                            ></div>
                          ),
                          pieLegend,
                        ]}
                      />
                    </DownloadGraphOverlay>
                  </Col>
                  <Col xs={12} lg={6}>
                    <DownloadGraphOverlay>
                      <Row>
                        <Col style={{ minWidth: '350px' }} xs={'auto'}>
                          <NextbrainSelect
                            className="basic-single mt-2"
                            type={'dark'}
                            classNamePrefix="select"
                            isSearchable={true}
                            placeholder={t('Select media') + '...'}
                            name={`features-select`}
                            isDisabled={false}
                            isClearable={true}
                            options={features.map((f) => ({
                              label: f,
                              value: f,
                            }))}
                            value={
                              budgetMode
                                ? selectedFeatureBudget
                                : selectedFeatureOutcome
                            }
                            onChange={(v) =>
                              budgetMode
                                ? setSelectedFeatureBudget(v)
                                : setSelectedFeatureOutcome(v)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ minHeight: '350px' }} xs={12} lg={11}>
                          {currentFeatureSelected?.value && (
                            <BestSpend
                              key={currentFeatureSelected.value}
                              channel={currentFeatureSelected.value}
                              targetSpend={
                                (budgetMode
                                  ? optimizeTableBars
                                  : optimizeTableOutcomeBars
                                )?.spendPerChannel?.[
                                  currentFeatureSelected.value
                                ]
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </DownloadGraphOverlay>
                  </Col>
                </Row>
              </Col>
              <Col className="optimize-card mt-3 px-0" xs={12}>
                <DownloadGraphOverlay
                  buttonsStyle={{ top: '3px', right: '5px' }}
                >
                  <Row className="px-3">
                    <MMMInsightHeading title="Time allocation">
                      <Row>
                        <Col>
                          {t(
                            'Distribution of the budget in the time axis over the requested period',
                          )}
                        </Col>
                      </Row>
                    </MMMInsightHeading>
                    <Col
                      className="px-0"
                      style={{ maxHeight: '800px', minHeight: '800px' }}
                      xs={12}
                    >
                      {budgetMode ? budgetGraph : outcomeGraph}
                    </Col>
                  </Row>
                </DownloadGraphOverlay>
              </Col>
            </Row>
          </Col>
        )}
    </Row>
  )
}
