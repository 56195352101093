import * as d3 from 'd3'

export function treeArrange(
  nodes,
  edges,
  width,
  height,
  target = 'output',
  margin = 200,
) {
  const tree = d3.tree().size([height - margin, width])

  const nodeMap = nodes.reduce((map, node) => {
    map[node.id] = node
    return map
  }, {})

  const nodeObjs = nodes.map((node) => ({
    id: node.id,
    children: [],
    parent: null,
  }))
  const nodeObjMap = nodeObjs.reduce((map, node) => {
    map[node.id] = node
    return map
  }, {})
  edges
    .sort((a) => (a.targetHandle.includes('left') ? -1 : 1))
    .forEach((edge) => {
      if (nodeObjMap[edge.target] && nodeObjMap[edge.source]) {
        nodeObjMap[edge.target].children.push(nodeObjMap[edge.source])
        nodeObjMap[edge.source].parent = nodeObjMap[edge.target]
      }
    })

  const parentNode = {
    children: [nodeObjMap[target]],
    parent: null,
  }

  const hierarchy = d3.hierarchy(parentNode)
  const layout = tree(hierarchy).descendants()
  layout.forEach((node) => {
    if (node.data.id) {
      const _node = nodeMap[node.data.id]
      _node.position.x = width - node.y
      _node.position.y = node.x
    }
  })
  const depthCount = layout.reduce((st, nd) => {
    st[nd.depth] = st[nd.depth] || 0
    st[nd.depth]++
    return st
  }, {})

  return {
    width: Object.keys(depthCount).length - 1,
    height: Math.max(...Object.values(depthCount)),
  }
}
