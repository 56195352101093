import { useMemo } from 'react'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery } from 'react-query'
import { getForecastTrend } from '../../services/model'
import { ResponsiveLine } from '@nivo/line'
import Loading from '../loading/LoadingSmall'

const COLORS = {
  seasonality: '#FFEE96',
  trend: '#FFA85A',
}

function dateColLim(model) {
  const items = Object.entries(
    model?.dataset?.final_column_status ?? {},
  ).filter(([k, v]) => v === 'Datetime')
  if (items.length === 1) {
    try {
      const date = new Date(
        model.dataset.statistics[items[0][0]].max.replace(',', ''),
      )
      if (!isNaN(date)) return date
    } catch (e) {}
  }

  return 'auto'
}

export default function SeasonalTrend({ model }) {
  const { token, signout } = useAuth()

  const { data, isLoading } = useQuery(
    ['forecast_trend', model?.id],
    async () => {
      return await getForecastTrend({ modelId: model?.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const [lines, csvData] = useMemo(() => {
    if (data?.data) {
      return [
        data?.columns?.map((c, i) => {
          return {
            id: c,
            color: COLORS[c] ?? 'white',
            data: data?.index?.map((x, j) => ({
              x: new Date(x),
              y: data?.data[j][i],
            })),
          }
        }) ?? [],
        [
          ['Date', ...data?.columns],
          ...data?.data.map((c, i) => [data?.index[i], ...c]),
        ],
      ]
    }
    return [[], []]
  }, [data])
  if (isLoading) return <Loading />

  return (
    <>
      <ResponsiveLine
        data={lines}
        margin={{ top: 30, right: 50, bottom: 80, left: 70 }}
        yScale={{
          type: 'linear',
          stacked: false,
          min: 'auto',
          max: 'auto',
        }}
        yFormat=" >-.2f"
        enableGridX={false}
        axisTop={null}
        axisRight={null}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
          max: dateColLim(model),
        }}
        xFormat="time:%Y-%m-%d"
        axisBottom={{
          orient: 'bottom',
          tickSize: 3,
          tickPadding: 5,
          legendOffset: 60,
          legendPosition: 'middle',
          tickRotation: -45,
          format: '%Y-%m-%d',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: model?.target,
          legendOffset: -60,
          legendPosition: 'middle',
        }}
        lineWidth={2}
        colors={(d) => d.color}
        enablePoints={false}
        pointBorderWidth={0}
        pointSize={4}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableSlices="x"
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: -35,
            translateY: -35,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 120,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'square',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
        theme={{
          textColor: 'white',
          fontSize: 12,
          axis: {
            ticks: {
              text: {
                fill: 'var(--nextbrain-widget-axis-legend)',
              },
            },
            legend: {
              text: {
                fill: 'var(--nextbrain-widget-axis-legend)',
              },
            },
          },
        }}
      />
      <div
        className="d-none data-holder"
        data-csv={encodeURIComponent(JSON.stringify(csvData))}
        data-filename={`forecasting_trend_${model?.dataset?.name}`}
      ></div>
    </>
  )
}
