import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

export default function CustomModal({
  btn = <span>Show modal</span>,
  as = Button,
  title,
  children,
  close = true,
  confirmText = null,
  confirmProps = {},
  confirmCallback = () => {},
  closeCallback = () => {},
  showVariant = 'link',
  className = '',
  useButton = true,
  centered = false,
}) {
  const { t } = useTranslation()
  confirmText = confirmText ?? t('Confirm')

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    closeCallback()
  }
  const handleShow = () => {
    setShow(true)
  }

  const CButton = as

  return (
    <>
      {useButton ? (
        <CButton
          variant={showVariant}
          className={`no-focus m-0 p-0 ${className}`}
          onClick={handleShow}
        >
          {btn}
        </CButton>
      ) : (
        <>
          <div
            className={`cursor-pointer w-100 ${className}`}
            onClick={handleShow}
          >
            {btn}
          </div>
        </>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        centered
      >
        {title ? (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        ) : (
          <></>
        )}
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              confirmCallback()
              handleClose()
            }}
            {...confirmProps}
          >
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
