export const categories = Object.freeze({
  accent: [
    '#7fc97f',
    '#beaed4',
    '#fdc086',
    '#ffff99',
    '#386cb0',
    '#f0027f',
    '#bf5b17',
    '#1199dd',
    '#EC812F',
  ],
  dark: [
    '#1b9e77',
    '#d95f02',
    '#7570b3',
    '#e7298a',
    '#66a61e',
    '#e6ab02',
    '#a6761d',
    '#1199dd',
  ],
  greenScale: [
    '#cef1dd',
    '#9ee3bb',
    '#6dd499',
    '#3cc677',
    '#33a865',
    '#2a8b53',
    '#216d41',
    '#077026',
  ],
  highContrast: [
    '#b8ebf0',
    '#ded0eb',
    '#f2bdbe',
    '#f2bdbe',
    '#f2bdbe',
    '#ffffff',
    '#b8ebf0',
    '#ded0eb',
    '#f2bdbe',
    '#f2bdbe',
    '#f2bdbe',
    '#ffffff',
  ],
  mmm: [
    'rgb(27, 158, 119)',
    'rgb(217, 95, 2)',
    'rgb(117, 112, 179)',
    'rgb(231, 41, 138)',
    'rgb(102, 166, 30)',
    'rgb(230, 171, 2)',
    'rgb(166, 118, 29)',
    'rgb(102, 102, 102)',
    'rgb(152, 192, 102)',
    'rgb(55, 180, 76)',
    'rgb(175, 37, 37)',
    'rgb(71, 3, 71)',
    'rgb(100, 37, 0)',
    'rgb(153, 174, 201)',
    'rgb(131, 201, 20)',
    'rgb(58, 50, 105)',
    'rgb(156, 85, 109)',
    'rgb(24, 66, 7)',
    'rgb(185, 236, 0)',
    'rgb(0, 69, 82)',
    'rgb(226, 198, 145)',
  ],
  color10: [
    'rgb(31, 119, 180)',
    'rgb(255, 127, 14)',
    'rgb(44, 160, 44)',
    'rgb(214, 39, 40)',
    'rgb(148, 103, 189)',
    'rgb(140, 86, 75)',
    'rgb(227, 119, 194)',
    'rgb(127, 127, 127)',
    'rgb(188, 189, 34)',
    'rgb(23, 190, 207)',
  ],
  colorpnn: [
    'rgb(245, 60, 50)', // negative
    'rgb(245, 120, 66)', // neutral
    'rgb(20, 140, 60)', // positive
  ],
  spectral: [
    'rgb(158, 1, 66)',
    'rgb(213, 62, 79)',
    'rgb(244, 109, 67)',
    'rgb(253, 174, 97)',
    'rgb(254, 224, 139)',
    'rgb(255, 255, 191)',
    'rgb(230, 245, 152)',
    'rgb(171, 221, 164)',
    'rgb(102, 194, 165)',
    'rgb(50, 136, 189)',
    'rgb(94, 79, 162)',
  ],
})

export const dataTypeCategories = Object.freeze({
  integer: categories.accent[0], //Change these in App.css variables for consistency
  key: categories.accent[1],
  float: categories.accent[2],
  categorical: categories.accent[8],
  date: categories.accent[5],
  unknown: categories.accent[7],
})

export const categoryToColorDataTypes = Object.freeze({
  Categorical: dataTypeCategories.categorical,
  Decimal: dataTypeCategories.float,
  Integer: dataTypeCategories.integer,
  Datetime: dataTypeCategories.date,
})
