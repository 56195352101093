import React, { useState, useEffect } from 'react'
import { Col, Image, Row, Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { CgComponents } from 'react-icons/cg'
import { useTranslation } from 'react-i18next'

import { getProducts } from '../profile/AccountPlan'
import { useAuth } from '../../providers/AuthProvider'
import Loading from '../loading/LoadingSmall'
import LLMPlugin from './LLMPlugin'
import './Marketplace.css'
import PluginExtra from './plugin-extra/PluginExtra'

const idToDescription = {
  LLM: <LLMPlugin />,
  'LLM Models': <LLMPlugin />,
}

function Plugin({ product, active = false, children, ...props }) {
  const { t } = useTranslation()
  return (
    <Col xl={4} lg={6} md={12} xs={12} {...props}>
      <Row>
        <Col xs={12}>
          <div className="insights-card h-100 hoverable my-1 py-4 px-2 cursor-pointer mx-1">
            {children && (
              <Row className="mx-1 mb-3">
                <Col xs={12}>{children}</Col>
              </Row>
            )}
            <div className="mb-3 d-flex justify-content-between">
              <div className="d-flex align-items-start">
                <span
                  className="float-right light-outline-button p-2 h-auto"
                  style={{
                    borderRadius: '15px',
                    backgroundColor: 'var(--nextbrain-background)',
                    border:
                      ' 1px solid var(--nextbrain-secondary-border-color)',
                  }}
                >
                  <strong className="d-inline-block text-truncate mt-1">
                    {t('Details')}
                  </strong>
                </span>
              </div>
              <Image
                src={product.image}
                className="mx-1 px-1 py-1 d-block light-outline-button image-plugin"
              />
            </div>
            <div style={{ marginTop: -25, marginBottom: 15 }}>
              Status:
              {product.id === 'LLM Models' ? (
                <strong
                  style={{ color: 'var(--nextbrain-tables-graph-bar-color)' }}
                >
                  {' '}
                  Active{' '}
                </strong>
              ) : (
                <strong> Inactive </strong>
              )}
            </div>
            <div className="mt-auto mb-auto mx-1">
              <strong>{product.name}</strong>
              <p className="small w-100" style={{ color: 'white' }}>
                {product?.description || idToDescription?.[product?.id]}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default function Marketplace({ setTitle }) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [selectedProduct, setSelectedProduct] = useState(null)
  const products = useQuery(
    ['products-info'],
    async () => {
      return await getProducts(token)
    },
    { staleTime: Infinity },
  )

  useEffect(
    () => {
      setTitle('Marketplace')
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <Row className="marketplace-container">
      <Col xs={12}>
        <Row className="my-3">
          <Col xs={12}>
            <strong className="h3">
              <CgComponents size={29} /> {t('Plugins')}
            </strong>
          </Col>
        </Row>
        <Row
          className={`not-selected-product-container`}
          style={{ minHeight: '302px' }}
        >
          {products.isLoading ? (
            <Col xs={12}>
              <Loading className="py-4 justify-content-center" />
            </Col>
          ) : (
            <>
              {products.data
                ?.filter((p) => p?.details?.product_type === 'plugin')
                ?.map((product) => (
                  <Plugin
                    className={`flex-column plugin-marketplace mb-4 mt-sm-0`}
                    key={product.id}
                    product={product}
                    onClick={() => setSelectedProduct(product)}
                  />
                ))}
            </>
          )}
        </Row>
      </Col>
      <Modal
        className="invisible-bg-modal"
        size={'xl'}
        show={!!selectedProduct}
        onHide={() => {
          setSelectedProduct(null)
        }}
      >
        <Modal.Body>
          <PluginExtra product={selectedProduct} />
        </Modal.Body>
      </Modal>
    </Row>
  )
}
