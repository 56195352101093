import { request } from './base'

export async function getAITools({ token, signout }) {
  return request({
    endpoint: `/api/tools`,
    token,
    signout,
    isAITools: true,
  })
}

export async function getAITool({ filename, token, signout }) {
  return request({
    endpoint: `/api/tool?filename=${encodeURIComponent(filename)}`,
    token,
    signout,
    isAITools: true,
  })
}

export async function deleteAITool({ filename, token, signout }) {
  return request({
    endpoint: `/api/tool?filename=${encodeURIComponent(filename)}`,
    method: 'DELETE',
    token,
    signout,
    isAITools: true,
  })
}

export async function createAITool({
  name,
  description,
  disabled,
  code,
  overwrite = true,
  token,
  signout,
}) {
  return request({
    endpoint: `/api/tools?overwrite=${overwrite}`,
    method: 'POST',
    body: {
      name,
      description,
      disabled,
      code,
    },
    contentType: 'json',
    token,
    signout,
    isAITools: true,
  })
}
