import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

import './RegisterForm.css'

export const industryDataProto = {
  industry: {},
}
export function IndustryInfoBody({
  industryData = { industry: {} },
  header = <></>,
  advButton = () => <></>,
  singles = false,
  ...props
}) {
  const { t } = useTranslation()
  const sizeProps = singles ? { xs: 12 } : { lg: 3, md: 6 }
  return (
    <>
      <Container {...props}>
        {header ? (
          <Row>
            <Col xs={12}>{header}</Col>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mx-xl-2 industry-selection">
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_8').click()}
            >
              <Image src="/iconsRegister/retail.svg" />
              <h4>{t('Marketing and Sales')}</h4>
              <p>
                {t(
                  'Personalize customer experiences and optimize your product availability',
                )}
              </p>
              <Form.Check
                autoComplete="off"
                type="switch"
                data-industry="retail"
                name="industry_8"
                key={'industry_8'}
                id="industry_8"
                checked={!!industryData.industry.retail}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_1').click()}
            >
              <Image src="/iconsRegister/energy.svg" />
              <h4>{t('Energy and environment')}</h4>
              <p>{t('Improve efficiency and reduce maintenance cost')}</p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="energy"
                name="industry_checkbox"
                key={'industry_1'}
                id="industry_1"
                checked={!!industryData.industry.energy} //Give this undefined and it cries
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_2').click()}
            >
              <Image src="/iconsRegister/financial-services.svg" />
              <h4>{t('Financial services')}</h4>
              <p>{t('Improve customer relationship and risk management')}</p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="financial"
                name="industry_2"
                key={'industry_2'}
                id="industry_2"
                checked={!!industryData.industry.financial}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_3').click()}
            >
              <Image src="/iconsRegister/configuration-icon.svg" />
              <h4>{t('Engineering')}</h4>
              <p>{t('Launch and scale your projects across platforms')}</p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="engineering"
                name="industry_3"
                key={'industry_3'}
                id="industry_3"
                checked={!!industryData.industry.engineering}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_4').click()}
            >
              <Image src="/iconsRegister/government.svg" />
              <h4>{t('Government and public interest')}</h4>
              <p>
                {t(
                  'Implement new sustainable citizen services more efficiently',
                )}
              </p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="government"
                name="industry_4"
                key={'industry_4'}
                id="industry_4"
                checked={!!industryData.industry.government}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_5').click()}
            >
              <Image src="/iconsRegister/healthcare.svg" />
              <h4>{'Healthcare'}</h4>
              <p>
                {t(
                  'Boost patient engagement, empower provider collaboration, and improve operations',
                )}
              </p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="healthcare"
                name="industry_5"
                key={'industry_5'}
                id="industry_5"
                checked={!!industryData.industry.healthcare}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_6').click()}
            >
              <Image src="/iconsRegister/manufacturing.svg" />
              <h4>{t('Manufacturing')}</h4>
              <p>
                {t(
                  'Improve operational efficiencies, reduce costs, and reduce time to introduce new capabilities',
                )}
              </p>
              <Form.Check
                type="switch"
                autoComplete="off"
                data-industry="manufacturing"
                name="industry_6"
                key={'industry_6'}
                id="industry_6"
                checked={!!industryData.industry.manufacturing}
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" {...sizeProps}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_7').click()}
            >
              <Image src="/iconsRegister/media-entertainment.svg" />
              <h4>{t('Media & entertaiment')}</h4>
              <p>
                {t(
                  'Controls your audience engagement, improve customer experience',
                )}
              </p>
              <Form.Check
                autoComplete="off"
                type="switch"
                data-industry="media"
                name="industry_7"
                key={'industry_7'}
                id="industry_7"
                checked={!!industryData.industry.media}
                {...props}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col md={12}>
          <Row>
            <Col className="mt-3 mb-4">{advButton()}</Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
