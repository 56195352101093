import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'

import { ResponsiveBar, canvasDefaultProps } from '@nivo/bar'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { colorPalette1, calculateMargin } from './common'
import { getTopFeatureImportance } from '../../../util/models'
import { round } from '../../utils/formating'

export function ConfigColumnImportance({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.col-importance-title').val(),
    graphFontSize:
      Number.parseInt($('.col-importance-graph-font-size').val()) ?? 12,
    graphType: $('.col-importance-graph-type').val(),
    limit: $('.col-importance-limit').val(),
    requests: [],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="col-importance-title"
            defaultValue={`${config.title ?? 'Column importance'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="col-importance-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Limit columns:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="col-importance-limit"
            defaultValue={`${config.limit ?? 4}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="col-importance-graph-type raw"
            defaultValue={`${config.graphType ?? 'col'}`}
          >
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function CICol({ model, config }) {
  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  }

  const keys = []
  const data = getTopFeatureImportance(model)
    .sort((a, b) => b.importance - a.importance)
    .slice(0, config.limit ?? 6)
    .map((i) => {
      if (i.column.startsWith('ln_') || i.column.startsWith('ln_'))
        i.column = i.column.substr(3)
      i.importance = round(i.importance)
      return i
    })
    .map((i, dex) => {
      keys.push(i.column)
      return {
        id: i.column,
        label: i.column,
        [i.column]: i.importance,
        color: colorPalette1[dex % colorPalette1.length],
      }
    })

  const csvData = data
    ? [['column', 'importance'], ...data.map((d) => [d.id, d[d.id]])]
    : []

  return data && data.length ? (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="id"
      enableGridY={false}
      margin={{
        top: 10,
        right: 10,
        bottom: 50,
        left: horizontal
          ? calculateMargin(
              data.map((d) => d.label),
              config.graphFontSize,
            ) * 0.8
          : 10,
      }}
      groupMode="stacked"
      layout={horizontal ? 'horizontal' : 'vertical'}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      tooltip={(props) => (
        <BasicTooltip
          id={`${props.id}`}
          value={`Provides ${props.value}% of the weight to predict ${model.target}`}
          color={props.color}
          enableChip
        />
      )}
      axisBottom={legend}
      labelSkipHeight={0}
      legends={[]}
      role="application"
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      label={(d) => `${d.value}%`}
      labelTextColor={'#333'}
      labelSkipWidth={50}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
      layers={[
        ...canvasDefaultProps.layers,
        () => (
          <div
            className="data-holder display-none"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`column_importance__${model.id}`}
          ></div>
        ),
      ]}
    />
  ) : (
    <>No data</>
  )
}

export function WidgetColumnImportance({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  return (
    <Row className="w-100 h-100" id={id}>
      <Col className="d-inline-block text-truncate widget-title" xs={12}>
        {config.title}
      </Col>

      <Col
        xs={12}
        style={{ height: 'calc(100% - 30px)' }}
        className={`header-data-distribution-num-pie`}
      >
        <CICol model={model} config={config} />
      </Col>
    </Row>
  )
}
