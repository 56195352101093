import React, { useMemo, useState, useEffect } from 'react'
import { colors } from './config'
import { Row, Col } from 'react-bootstrap'
import { useModels } from '../../providers/ModelProvider'
import {
  getMMMOptimizedTable,
  getMMMOutcomeBudgetOptimization,
} from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery, useQueryClient } from 'react-query'
import { generateMMMColorMap, testAccuracy } from '../../util/models'
import { readableNumber, readableNumberMMM, round } from '../utils/formating'
import { useTranslation } from 'react-i18next'
import Loading from '../loading/LoadingSmall'
import { GiConfirmed } from 'react-icons/gi'

function calculateMediaContributionTotal(table) {
  try {
    const indexMediaContribution = table.columns.indexOf('Media contribution')
    const contributionPerc =
      table.data
        .map((r) => r[indexMediaContribution])
        .reduce((a, b) => a + b, 0) / 100
    if (Number.isNaN(contributionPerc)) return 1
    return contributionPerc
  } catch (e) {
    return 1
  }
}

export default function MMMPerformance({ model, ...props }) {
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()
  const { activeModel } = useModels()
  const { t } = useTranslation()
  const [usePercent] = useState(true)
  if (!model) model = activeModel
  const budgetOptimization = useQuery(
    ['model-budget-optimization-original', model.id],
    async () => {
      return await getMMMOutcomeBudgetOptimization({
        modelId: model.id,
        original: true,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const { data, isLoading, isSuccess } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      return await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: false,
        original: true,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const contributionPerc = calculateMediaContributionTotal(data)

  const outcome =
    budgetOptimization.isLoading || isLoading
      ? 0
      : budgetOptimization.data?.outcome * contributionPerc

  const acc = testAccuracy(model)
  let accuracy = 0.95
  if (!isNaN(acc)) accuracy = acc / 100

  const { increaseOutcome, newCpa } = useMemo(() => {
    const table = model?.mmm?.table
    if (table) {
      const header = table.columns
      const mediaSpendIndex = header.indexOf('Media spend')
      const mediaOutcomeIndex = header.indexOf('Media outcome')
      let totalMediaSpend = 0
      let totalMediaOutcome = 0
      table.data.forEach((row) =>
        row.forEach((cell, index) => {
          const value = isNaN(cell) ? cell : round(cell, 2)
          switch (index) {
            case mediaSpendIndex:
              totalMediaSpend += value
              break
            case mediaOutcomeIndex:
              totalMediaOutcome += value
              break
            default:
              break
          }
        }),
      )
      const increaseOutcome = round(100 * (outcome / totalMediaOutcome - 1), 2)
      const prevCpa = totalMediaSpend / totalMediaOutcome
      const newCpa = totalMediaSpend / outcome

      return {
        totalMediaOutcome,
        increaseOutcome,
        prevCpa,
        newCpa,
        improveCpa: 100 * (1 - newCpa / prevCpa),
      }
    }
    return {}
    // eslint-disable-next-line
  }, [outcome])

  const datasets = useMemo(() => {
    if (data) {
      const colorMap = generateMMMColorMap(model, colors)
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const datasets = data.data.reduce((ac, d) => {
        ac[d[keymap['index']]] = {
          spend: d[keymap['Media spend']],
          recommended: d[keymap['Recommended spend']],
          color: colorMap[d[keymap['index']]],
        }
        return ac
      }, {})

      const { total, totalRecommended } = data.data.reduce(
        (acc, d) => {
          acc.total += d[keymap['Media spend']]
          acc.totalRecommended += d[keymap['Recommended spend']]
          return acc
        },
        { total: 0, totalRecommended: 0 },
      )
      Object.entries(datasets).forEach(([k, v]) => {
        v.spendPerc = (100 * v.spend) / total
        v.recommendedPerc = (100 * v.recommended) / totalRecommended
      })
      return datasets
    }

    return null
    // eslint-disable-next-line
  }, [data])

  const pieData = datasets
    ? Object.entries(datasets).map(([k, v]) => {
        return [
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.spendPerc : v.spend,
          },
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.recommendedPerc : v.recommended,
          },
        ]
      })
    : null
  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess) {
      setShownData(
        pieData?.map((d) => [
          { ...d[0], value: 0 },
          { ...d[1], value: 0 },
        ]),
      )
      setTimeout(() => setShownData(pieData), 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, datasets])

  useEffect(() => {
    if (
      isSuccess &&
      !budgetOptimization.isLoading &&
      !budgetOptimization?.data
    ) {
      const timeout = setTimeout(() => {
        queryClient.invalidateQueries([
          'model-budget-optimization-original',
          model.id,
        ])
      }, 3000)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [isSuccess, budgetOptimization])

  const table = model?.mmm?.table
  const [mediaOutcome, ROI] = useMemo(() => {
    if (table) {
      const mes = table.columns.indexOf('Media spend'),
        moi = table.columns.indexOf('Media outcome'),
        cpai = table.columns.indexOf('CPA')

      const valid = [mes, moi, cpai].reduce((ac, i) => ac && i !== -1, true)
      if (valid) {
        const totalSpend = table.data.reduce((ac, row) => ac + row[mes], 0)
        const totalOutcome = table.data.reduce((ac, row) => ac + row[moi], 0)
        const totalCPA = totalSpend / totalOutcome
        return [totalOutcome, 1 / totalCPA]
      }
    }
    return [0, 0]
  }, [table])

  if (isLoading || !shownData || !budgetOptimization?.data)
    return (
      <Loading
        className="mt-3"
        message="Generating optimization, this might take a few minutes"
      />
    )

  let optimizeOutcome = outcome
  let optimizedROI = 1 / newCpa
  let optimizeIncreaseOutcome = increaseOutcome

  if (optimizeOutcome < mediaOutcome) {
    optimizeOutcome = mediaOutcome
    optimizedROI = ROI
    optimizeIncreaseOutcome = 0
  }

  let mediaSpendContributionTotal = 0
  try {
    const ci = table.columns.indexOf('Media contribution')
    const contribution = table.data.map((r) => r[ci]).reduce((a, b) => a + b, 0)
    mediaSpendContributionTotal = round(contribution, 0)
  } catch (e) {}

  return (
    <Row className="w-100 justify-content-md-start justify-content-sm-center">
      <Col xl={6} md={12} className="container-performance-mmm">
        <Row className="position-relative h-100">
          <Col xs={6} className=" mb-1 pe-0">
            <Row className="flex-column p-1 position-relative h-100 w-100 performance-card-mmm">
              <Col className="text-center h5" xs={12}>
                <strong>{t('Media outcome')}</strong>
              </Col>
              <Col
                xs={12}
                className="text-center d-inline-block text-truncate h3 mt-1"
              >
                <strong>
                  <u>{readableNumber(round(mediaOutcome, 2))}</u>
                </strong>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="mb-1 px-0 ">
            <Row className="justify-content-center p-1 h-100 w-100 performance-card-mmm ms-sm-0 ms-md-1">
              <Col className="text-center h5" xs={12}>
                <strong>{t('Optimized media outcome')}</strong>
              </Col>
              <Col
                xs={'auto'}
                className="text-center d-inline-block text-truncate h3 mt-1"
              >
                <strong>
                  <u>{readableNumber(round(optimizeOutcome, 2))}</u>
                </strong>
              </Col>
              <Col
                className="px-0 ms-1 gx-0 d-flex align-items-center"
                xs={'auto'}
              >
                <Row>
                  <Col style={{ maxWidth: '12px' }} xs={2}>
                    <Row>
                      <Col
                        className="px-0 gx-0"
                        style={{ maxHeight: '6px' }}
                        xs={12}
                      >
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            color: 'var(--nextbrain-tables-graph-bar-color)',
                          }}
                        >
                          +
                        </span>
                      </Col>
                      <Col
                        className="px-0 gx-0"
                        style={{ maxHeight: '6px' }}
                        xs={12}
                      >
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            color:
                              'var(--nextbrain-tables-negative-graph-bar-color)',
                          }}
                        >
                          -
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="ps-0"
                    style={{ maxWidth: 'calc(100% - 30px)' }}
                    xs={10}
                  >
                    <strong>
                      {round((1 - accuracy) * optimizeOutcome, 2)}
                    </strong>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}></Col>
              <Col className="outcome-increase-container mt-2" xs={'auto'}>
                {optimizeIncreaseOutcome}%
              </Col>
              <Col className="d-flex justify-content-center mt-3" xs={12}>
                <span className="small-mmm-optimize">
                  {t('With 95% confidence')}
                  <GiConfirmed className="ms-2" />
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="mb-1 pe-0">
            <Row className="flex-column p-1 position-relative h-100 w-100 performance-card-mmm">
              <Col className="text-center h5" xs={12}>
                <strong>{t('Media ROI')}</strong>
              </Col>
              <Col className="mt-1 h3 text-center" xs={12}>
                <strong>
                  <u>{readableNumberMMM(ROI)}</u>
                </strong>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="mb-1 px-0">
            <Row className="justify-content-center p-1 position-relative h-100 w-100 performance-card-mmm ms-sm-0 ms-md-1">
              <Col className="text-center h5" xs={12}>
                <strong>{t('Optimized media ROI')}</strong>
              </Col>
              <Col className="mt-1 h3" xs={'auto'}>
                <strong>
                  <u>{readableNumberMMM(optimizedROI)}</u>
                </strong>
              </Col>
              <Col
                className="px-0 ms-1 gx-0 d-flex align-items-center"
                xs={'auto'}
              >
                <Row>
                  <Col style={{ maxWidth: '12px' }} xs={2}>
                    <Row>
                      <Col
                        className="px-0 gx-0"
                        style={{ maxHeight: '6px' }}
                        xs={12}
                      >
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            color: 'var(--nextbrain-tables-graph-bar-color)',
                          }}
                        >
                          +
                        </span>
                      </Col>
                      <Col
                        className="px-0 gx-0"
                        style={{ maxHeight: '6px' }}
                        xs={12}
                      >
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            color:
                              'var(--nextbrain-tables-negative-graph-bar-color)',
                          }}
                        >
                          -
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="ps-0"
                    style={{ maxWidth: 'calc(100% - 30px)' }}
                    xs={10}
                  >
                    <strong>{round(((1 - accuracy) * 1) / newCpa, 4)}</strong>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}></Col>
              <Col className="cpa-optimize-container mt-2" xs={'auto'}>
                {optimizeIncreaseOutcome}%
              </Col>
              <Col className="d-flex justify-content-center mt-3" xs={12}>
                <span className="small-mmm-optimize">
                  {t('With 95% confidence')}
                  <GiConfirmed className="ms-2" />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        className="d-flex flex-column align-items-center justify-content-center performance-card-mmm mx-2 mb-1"
        style={{ maxWidth: '300px', maxHeight: '400px' }}
        xs={12}
      >
        <h1 style={{ color: 'var(--nextbrain-mmm-color)' }}>
          {mediaSpendContributionTotal}%
        </h1>
        <span className="px-3 smallp text-center">
          <strong>{t('Percentage of contribution from media')}</strong>
        </span>
      </Col>
    </Row>
  )
}
