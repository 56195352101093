import { Button, Row } from 'react-bootstrap'
import $ from 'jquery'
import useStoreState from '../../hooks/UseStoreState'
import { AssistantTooltips } from './AssistantTooltips'

export const modelLandingTourSteps = [
  {
    selector: '.top-controls > div:nth-child(1)',
    content: 'Name of the current workspace.',
  },
  {
    selector: '.top-controls > div:nth-child(2)',
    content:
      'You can mark a workspace as a favorite, change its name and color label, and share it with other people.',
  },
  {
    selector: '.addModel',
    content: 'Add a new model to your workspace.',
  },
  {
    selector: '.react-tabs__tab:nth-child(1)',
    content: 'Add a new model using data already stored in other models.',
  },
  {
    selector: '.react-tabs__tab:nth-child(3)',
    content: 'Add a new model uploading data from a local file.',
  },
  {
    selector: '.react-tabs__tab:nth-child(2)',
    content: <AssistantTooltips modalid={2} bottom={false} />,
    action: () => {
      $('.items-models li:nth-child(2)').click()
    },
  },
  {
    selector: '.react-tabs__tab:nth-child(3)',
    content: <AssistantTooltips modalid={3} bottom={false} />,
    action: () => {
      $('.items-models li:nth-child(2)').click()
    },
  },
  {
    selector: '.react-tabs__tab:nth-child(4)',
    content: <AssistantTooltips modalid={4} bottom={false} />,
    action: () => {
      $('.items-models li:nth-child(2)').click()
    },
  },
]

export function ModelLandingBadge({ content, setIsOpen }) {
  const [userHelp, setUserHelp] = useStoreState({ key: 'userHelp' })
  return (
    <Row className="py-2 mt-4">
      {content}
      <Button
        style={{
          position: 'absolute',
          backgroundColor: '',
          border: 'none',
          left: 0,
          bottom: '-40px',
        }}
        onClick={() => {
          setIsOpen(false)
          setUserHelp({ ...userHelp, modelTour: true })
        }}
      >
        Don't show this anymore
      </Button>
    </Row>
  )
}
