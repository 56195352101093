import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Navbar,
  Container,
  Image,
  Form,
} from 'react-bootstrap'
import {
  FaEnvelope,
  FaUser,
  FaGlobeAmericas,
  FaPeopleArrows,
} from 'react-icons/fa'
import { RiPsychotherapyFill } from 'react-icons/ri'
import { CountryDropdown } from 'react-country-region-selector'
import { getCountry } from '../../util/other'
import { useAuth } from '../../providers/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function SetInfoAccount() {
  const { t } = useTranslation()
  const { user, updateuser } = useAuth()

  const defaultStyle = { className: 'my-3' }
  const [newUser, setNewUser] = useState(user)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setNewUser((prevUser) => ({
      ...prevUser,
      country: getCountry(),
    }))
  }, [])

  return (
    <Container>
      <Row>
        <Navbar expand="lg">
          <Container fluid>
            <h4>{t('Please finish registration')}</h4>
            <Image src="/LogoLogin.svg" width={85}></Image>
          </Container>
        </Navbar>
      </Row>
      <Row className="missing-user-info">
        <Col xs={6} {...defaultStyle} style={{ display: 'none' }}>
          <Form.Label>
            Name <span className="text-danger">*</span>
          </Form.Label>
          <Col className="posrelative">
            <FaUser className="iconinput" />
            <Form.Control
              key="admin_name"
              id="admin_name"
              className="inputnextbrain nb-input show-shadow"
              defaultValue={user.name}
              onChange={(e) =>
                setNewUser((prevUser) => ({
                  ...prevUser,
                  name: e.target.value,
                }))
              }
              autoComplete="off"
              required
            />
          </Col>
        </Col>
        <Col xs={6} {...defaultStyle} style={{ display: 'none' }}>
          <Form.Label>
            {t('Email')} <span className="text-danger">*</span>
          </Form.Label>
          <Col className="posrelative">
            <FaEnvelope className="iconinput" />
            <Form.Control
              key="email"
              id="email"
              className="inputnextbrain nb-input show-shadow"
              placeholder="example@email.com"
              defaultValue={user['email']}
              type="email"
              autoComplete="off"
              readOnly={true}
              required
            />
          </Col>
        </Col>
        <Col className="my-3" xs={12}>
          <Row style={{ minWidth: '100%' }}>
            <Col xs={12}>
              <Form.Label>{t('Where are you from?')} </Form.Label>
            </Col>
            <Col className="position-relative" xs={12}>
              <FaGlobeAmericas className="iconinput-2" />
              <CountryDropdown
                id="country"
                className="raw nb-input inputnextbrain gx-0 "
                defaultValue={getCountry()}
                value={null}
                key="country"
                onChange={(e) =>
                  setNewUser((prevUser) => ({
                    ...prevUser,
                    country: e,
                  }))
                }
                style={{ maxWidth: '100%', minWidth: '100%' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row style={{ minWidth: '100%' }}>
            <Col xs={12}>
              <Form.Label>
                {t('How did you hear about us?')}{' '}
                <span className="text-danger">*</span>{' '}
              </Form.Label>
            </Col>
            <Col xs={12} className="posrelative">
              <FaPeopleArrows className="iconinput-2" />
              <Form.Select
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  paddingTop: '12px',
                  paddingBottom: '12px',
                }}
                key="introduction"
                id="introduction"
                className="nb-input inputnextbrain raw"
                defaultValue={'Default'}
                required
                onChange={(e) =>
                  setNewUser((prevUser) => ({
                    ...prevUser,
                    introduction: e.target.value,
                  }))
                }
              >
                <option value="Default" selected disabled></option>
                <option value="Twitter">{t('Twitter')}</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Reddit">Reddit</option>
                <option value="Friend/colleague">
                  {t('Friend/colleague')}
                </option>
              </Form.Select>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row style={{ minWidth: '100%' }}>
            <Col xs={12}>
              <Form.Label>
                {t(
                  'What other autoML tools or platforms have you used? (Optional)',
                )}
              </Form.Label>
            </Col>
            <Col className="position-relative" xs={12}>
              <RiPsychotherapyFill className="iconinput-2" />
              <Form.Control
                key="previous_platforms"
                id="previous_platforms"
                className="inputnextbrain nb-input show-shadow"
                type="text"
                defaultValue={user['previous_platforms']}
                onChange={(e) =>
                  setNewUser((prevUser) => ({
                    ...prevUser,
                    previous_platforms: e.target.value,
                  }))
                }
                autoComplete="off"
              />
            </Col>
          </Row>
        </Col>
        <Row className="justify-content-between">
          <Col xs={'auto'}>
            <Form.Check
              key="student"
              id="student"
              type="checkbox"
              label={t('I´m a student')}
              autoComplete="off"
              value={''}
              defaultChecked={user['student']}
              onChange={(e) =>
                setNewUser((prevUser) => ({
                  ...prevUser,
                  student: e.target.value,
                }))
              }
            />
          </Col>
          <Col xs={'auto'}>
            <Form.Label>
              <span className="text-danger">*</span> {t('Required')}
            </Form.Label>
          </Col>
        </Row>
        <Row className="update-info-button-row">
          <Button
            id="register-button-1"
            className="mb-5 px-5 py-4 float-md-end update-info-button"
            variant="success"
            disabled={!newUser.introduction || !newUser.country || loading}
            onClick={() => {
              setLoading(true)
              updateuser(newUser).finally(() => {
                setLoading(false)
              })
            }}
          >
            {t('Finish registration')}
          </Button>
        </Row>
      </Row>
    </Container>
  )
}
