import React, { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import $ from 'jquery'
import { ShapleyValues } from '../../model-content/TopFieldsExploration'

export function ColumnChooser({
  config,
  model,
  selectProps = {},
  colSize = 12,
  ...props
}) {
  const individual_feature_importance =
    model.details.individual_feature_importance

  const defaultTargetCategory = Object.keys(individual_feature_importance)[0]
  const sumImportance = defaultTargetCategory
    ? Object.keys(individual_feature_importance[defaultTargetCategory] ?? {})
        .map(
          (k) =>
            individual_feature_importance[defaultTargetCategory][k].importance,
        )
        .reduce((ac, i) => ac + i, 0)
    : 100

  const columns = defaultTargetCategory
    ? Object.keys(individual_feature_importance[defaultTargetCategory])
        .sort(
          (a, b) =>
            individual_feature_importance[defaultTargetCategory][b].importance -
            individual_feature_importance[defaultTargetCategory][a].importance,
        )
        .filter(
          (e) =>
            individual_feature_importance[defaultTargetCategory][e].importance /
              sumImportance >
            0.01,
        )
        .slice(0, 7)
    : []
  const [columnToExplore, setColumnToExplore] = useState(
    config.columnToExplore ?? columns[0],
  )
  const [targetValue, setTargetValue] = useState(
    config.targetValue ?? Object.keys(individual_feature_importance),
  )

  return (
    <Row>
      <Col xs={colSize}>
        <Row className="mt-2">
          <Col xs={12}>Column to analyze:</Col>
          <Col xs={12}>
            <Form.Select
              onChange={(e) => {
                setColumnToExplore(e.target.value)
              }}
              defaultValue={columnToExplore}
              {...selectProps}
              className={`shapley-col raw ${selectProps.className ?? ''}`}
            >
              {columns.map((c) => (
                <option key={c} value={c}>
                  {individual_feature_importance[defaultTargetCategory][
                    c
                  ].column
                    .replace('ln_', '')
                    .replace('sq_', '')}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>Target value:</Col>
          <Col xs={12}>
            <Form.Select
              onChange={(e) => {
                setTargetValue(e.target.value)
              }}
              defaultValue={targetValue}
              {...selectProps}
              className={`shapley-targ raw ${selectProps.className ?? ''}`}
            >
              {Object.keys(individual_feature_importance).map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export function ConfigShapleyValues({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.shapley-title').val(),
    columnToExplore: $('.shapley-col').val(),
    targetValue: $('.shapley-targ').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="shapley-title"
            defaultValue={`${config.title ?? 'Analysis of influence'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <ColumnChooser config={config} model={model} />
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetShapleyValues({ model, config, id, ...props }) {
  if (!model || !config) return <>Loading...</>

  const individual_feature_importance =
    model.details.individual_feature_importance

  const res = {}
  const resNeg = {}
  Object.entries(individual_feature_importance).forEach(([k, v]) => {
    v.forEach((d) => {
      res[d.column] = res[d.column] ?? {}
      resNeg[d.column] = resNeg[d.column] ?? {}
      d.effects.forEach((e) => {
        if (e.individual_importance === 'nan') return
        res[d.column][e.range] = res[d.column][e.range] ?? 0
        resNeg[d.column][e.range] = resNeg[d.column][e.range] ?? 0

        if (e.individual_importance < 0)
          resNeg[d.column][e.range] += e.individual_importance
        else res[d.column][e.range] += e.individual_importance
      })
    })
  })

  const allSet = [
    ...new Set([...Object.keys(res), ...Object.keys(resNeg)]),
  ].reduce((d, v) => {
    d[v] = Object.keys(res[v]).map((k) => ({
      range: k,
      individual_importance: res[v]?.[k] ?? 0,
      negative_individual_importance: resNeg[v]?.[k] ?? 0,
    }))
    return d
  }, {})

  let maxIndividualImportance = -Infinity
  let minNegativeIndividualImportance = Infinity

  for (let key in allSet) {
    let arr = allSet[key]
    for (let i = 0; i < arr.length; i++) {
      let importance = arr[i]['individual_importance']
      let negativeImportance = arr[i]['negative_individual_importance']
      if (importance > maxIndividualImportance) {
        maxIndividualImportance = importance
      }
      if (negativeImportance < minNegativeIndividualImportance) {
        minNegativeIndividualImportance = negativeImportance
      }
    }
  }

  const isCategoricalVariable =
    config.targetValue &&
    config.columnToExplore &&
    model?.dataset?.final_column_status?.[
      individual_feature_importance[config.targetValue][config.columnToExplore]
        ?.column
    ] === 'Categorical'

  function remove_prefix(str) {
    if (!str) return ''
    return str.replace(/^(ln_)||(sq_)/, '')
  }

  const columnToExploreName =
    config.targetValue && config.columnToExplore
      ? remove_prefix(
          individual_feature_importance[config.targetValue][
            config.columnToExplore
          ].column,
        )
      : ''

  const effects =
    individual_feature_importance[config.targetValue][config.columnToExplore]
      .effects

  return (
    <Row {...props} id={id} className={`w-100 h-100 ${props.className ?? ''}`}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col
        xs={12}
        style={{ height: 'calc(100% - 50px)', position: 'relative' }}
      >
        <ShapleyValues
          model={model}
          effects={effects}
          columnToExplore={columnToExploreName}
          targetValue={config.targetValue}
          isCategorical={isCategoricalVariable}
          maxY={maxIndividualImportance}
          minY={minNegativeIndividualImportance}
          isWidget={true}
        />
      </Col>
    </Row>
  )
}
