import React from 'react'
import { Row, Col, Dropdown, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../providers/NavProvider'
import { useModels } from '../../providers/ModelProvider'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'

const items = Object.freeze({
  inventory_management: '/icons/inventory-management.svg',
  mmm: '/icons/MMM-35.svg',
  automl: '/icons/robot-automl.svg',
  sixsigma: '/icons/sixsigma.webp',
})

export default function BreadcrumbNav({ ...props }) {
  const { t } = useTranslation()
  const { mode, setMode } = useNav()
  const { workspaceId, workspacename, activeModel } = useModels()
  const { MMMEnabled } = useAuth()
  const navigate = useNavigate()
  const path = [[t('Workspaces'), '/']]
  if (workspacename && workspaceId) {
    path.push([workspacename, `/model/${workspaceId}?wp-name=${workspacename}`])
    if (activeModel?.dataset?.name)
      path.push([
        activeModel.dataset.name,
        `/model/${workspaceId}?wp-name=${workspacename}&model-id=${activeModel.id}`,
      ])
  }

  let lengths = path.map(([k]) => k.length)
  const totalChars =
    (window.innerWidth - 430) /
    Math.max(1, 30 * path.reduce((a, [n]) => a + n.length, 0))

  lengths = lengths.map((l) => (100 * l) / totalChars)

  const automl = true
  const mmm = MMMEnabled()
  const inventory = false
  const navClass = `${automl ? 'automl-enabled-nav' : ''} ${
    mmm ? 'mmm-enabled-nav' : ''
  } ${inventory ? 'inventory-enabled-nav' : ''}`
  const sum = [automl, mmm, inventory].reduce((a, i) => a + (i ? 1 : 0), 0)

  return (
    <Row className={`breadcrumbs-nav mx-0`}>
      <Col
        className={`position-relative px-0 ${
          sum > 1 ? '' : 'pe-none disabled-drop-nav'
        }`}
        xs={12}
        style={{ maxWidth: '280px', minWidth: '280px' }}
      >
        <Dropdown className="breadcrumbs-mode-dropdown">
          <Dropdown.Toggle className="py-2 w-100">
            <strong>
              <span>
                <Image
                  src={items[mode]}
                  height="40px"
                  alt={`Mode icon for ${mode}`}
                />
              </span>
              <div className="d-inline-flex align-items-end h-100 mt-1 ms-1">
                {t(mode)}
              </div>
            </strong>
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">
            {Object.entries(items)
              .filter(([key]) => key !== mode)
              .map(([key, value]) => (
                <Dropdown.Item
                  className={`text-center w-100 align-items-center ${key}-nav ${navClass}`}
                  key={key}
                  onClick={() => {
                    setMode(key)
                    navigate('/')
                  }}
                >
                  <strong className={`w-100`}>
                    <span>
                      <Image
                        src={items[key]}
                        height="40px"
                        alt={`Mode icon for ${key}`}
                      />
                    </span>
                    <div className="color-white ms-1">{t(key)}</div>
                  </strong>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col
        xs={12}
        style={{
          maxWidth: 'calc(100% - 480px)',
          minWidth: 'calc(100% - 480px)',
        }}
      >
        <Row className="h-100 ms-0 ms-md-4 d-inline-flex flex-nowrap">
          {path.map(([k, link], i, all) => (
            <Col
              key={i}
              className="d-inline-flex px-0 mb-1"
              xs={'auto'}
              style={{
                fontSize: '18px',
                maxWidth: `${lengths[i]}%`,
              }}
            >
              <span className="d-flex align-items-center">
                <Link to={link}>
                  <i>
                    {' '}
                    <span className="color-white">{k}</span>
                  </i>
                </Link>
                {all.length - 1 !== i && <span className="px-2">/</span>}
              </span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        className="align-items-center d-md-flex dflex-center d-none"
        xs={12}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      >
        <div
          style={{ display: 'none' }}
          className="breadcrumbs-nav-report p-1 looks-like-a-2 "
          onClick={() => {
            document.querySelector('.report-anchor-nav-column')?.click()
          }}
        >
          Report Issue
        </div>
      </Col>
    </Row>
  )
}
