import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation, Link } from 'react-router-dom'

import './header.css'
import '../../styles/navbar.css'
import 'bootstrap/dist/css/bootstrap.css'

function BlankNavbarNextbrain({ ...props }) {
  const location = useLocation()
  const path = location.pathname.replace(/^\//, '').replace(/\/.+/, '')

  return (
    <>
      <Row
        {...props}
        className={`${path ? path : 'home'}-path d-inline-flex ${
          props.className ?? ''
        }`}
      >
        {process.env.REACT_APP_MODE === 'dev' ? (
          <div className="dev-not">
            <span>dev</span>
          </div>
        ) : (
          <></>
        )}

        <Col
          className="ms-5 gx-0 order-md-1 order-2 next-brain-col-nav"
          style={{ minWidth: 'calc(100% - 1500px)' }}
          xs={'auto'}
        >
          <Link to="/">
            <div className="next-brain-logo icon-nav"></div>
          </Link>
        </Col>
      </Row>
    </>
  )
}

export default function BlankHeader({ ...props }) {
  return (
    <>
      <Row
        {...props}
        className={`top-navbar gx-0 header ${props.className ?? ''} no-print`}
      >
        <BlankNavbarNextbrain className="align-items-center justify-content-between" />
      </Row>
    </>
  )
}
