import React, { Component } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'

export default class CrashFallback extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    this.setState({ hasError: true })
    if (this.props.onError) this.props.onError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Row
          style={{ minHeight: '100vh', ...(this?.props?.style ?? {}) }}
          className="w-100 justify-content-center align-items-center"
        >
          <Col xs={'auto'}>
            {this?.props?.message ? (
              <Row>
                <Col className="h3" xs={'auto'}>
                  {typeof this.props.message === 'string'
                    ? this.props.t(this.props.message)
                    : this.props.message}
                </Col>
              </Row>
            ) : (
              <Row className="d-flex justify-content-center">
                <Col className="h3 d-flex justify-content-center" xs={'auto'}>
                  <Image src={'/Logo/3.svg'} style={{ height: '200px' }} />
                </Col>
                <Col xs={12}></Col>
                <Col className="h3" xs={'auto'}>
                  {this.props.t('Something went wrong.')}
                </Col>
                <Col xs={12}></Col>
                <Col className="mt-3" xs={'auto'}>
                  {this.props.t(
                    'A report has been dispatched to the engineering team.',
                  )}
                </Col>
                <Col xs={12}></Col>
                <Col xs={'auto'}>
                  {this.props.t('Thank you for your patience.')}
                </Col>
                <Col xs={12}></Col>
                <Col
                  className="crash-nav-control looks-like-a mt-4"
                  xs={'auto'}
                  onClick={
                    this?.props?.onCrash ??
                    (() => {
                      document.location.href = '/'
                      this.setState({ hasError: false })
                    })
                  }
                >
                  <FaArrowLeft />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )
    }
    return this.props.children
  }
}
