import React, { useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import useCountdown from '../../hooks/useCountdown'
import './ConfirmButton.css'

export default function ConfirmButton({
  as = Button,
  asOutside = null,
  onClick = () => {},
  waitForInput = 4000,
  children,
  confirmText,
  inProps = null,
  onEvent = () => {}, //Pass the event for the click on the buttons for stopping propagation or else
  ...props
}) {
  const [confirm, setConfirm] = useState(false)
  const [time, start, stop] = useCountdown()
  const resetRef = useRef(null)

  inProps = inProps || {}
  const Component = as
  const ComponentOutside = asOutside || as
  return (
    <>
      <ComponentOutside
        onClick={(e) => {
          onEvent(e)
          start({
            duration: 2000,
            step: 1000,
            onFinish: () =>
              (resetRef.current = setTimeout(
                () => setConfirm(false),
                waitForInput,
              )),
          })
          setConfirm(true)
        }}
        {...props}
        className={`start-confirm-button ${confirm ? '' : 'visible'} ${
          props.className || ''
        }`}
      >
        {children}
      </ComponentOutside>

      <Component
        variant="success"
        disabled={time !== 0}
        onClick={(e) => {
          onEvent(e)
          setConfirm(false)
          stop()
          onClick()
        }}
        style={{ minWidth: '4ch' }}
        className={`original me-2 confirm-button ${confirm ? 'visible' : ''} ${
          inProps.className || ''
        }`}
      >
        {time ? `${Math.round(time / 1000)}s` : confirmText || <FaCheck />}
      </Component>

      <Component
        variant="danger"
        className={`original confirm-button ${confirm ? 'visible' : ''} ${
          inProps.className || ''
        }`}
        onClick={(e) => {
          onEvent(e)
          stop()
          clearTimeout(resetRef.current)
          setConfirm(false)
        }}
      >
        <ImCross />
      </Component>
    </>
  )
}
