import { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import NextbrainSelect from '../NextbrainSelect'
import Slider from '../../slider/Slider'
import { useTranslation } from 'react-i18next'

export const OPTIONS = [
  { label: 'Auto', value: 'Auto' },
  { label: 'Days', value: 'Days', dayVal: 1 },
  { label: 'Weeks', value: 'Weeks', dayVal: 7 },
  { label: 'Months', value: 'Months', dayVal: 30 },
  { label: 'Years', value: 'Years', dayVal: 365 },
]

export default function ForecastHorizon({
  model,
  forecastingDate,
  magnitude,
  units,
  onChange = () => {},
  ...props
}) {
  const { t } = useTranslation()

  const options = useMemo(
    () =>
      OPTIONS.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  )

  const trainDataDayLength = useMemo(() => {
    if (model && forecastingDate) {
      try {
        const columnStats = model.dataset.statistics[forecastingDate]
        const max = columnStats.max.replace(',', '')
        const min = columnStats.min.replace(',', '')
        const diff = new Date(max).getTime() - new Date(min).getTime()
        return diff / (1000 * 60 * 60 * 24)
      } catch (e) {
        return null
      }
    }
  }, [model, forecastingDate])

  const limitSteps = useMemo(() => {
    if (trainDataDayLength && units && units !== 'Auto') {
      const limitDays = trainDataDayLength / 3
      const factor = OPTIONS.find((o) => o.value === units)?.dayVal
      return Math.max(1, Math.round(limitDays / factor))
    }
  }, [trainDataDayLength, units])
  return (
    <Row {...props} className={`align-items-center ${props?.className ?? ''}`}>
      <Col xs={12}>
        <NextbrainSelect
          value={options.find((o) => o.value === units)}
          onChange={(value) => {
            onChange(1, value?.value)
          }}
          options={options}
          hideSelectedOptions={false}
          isClearable={false}
          placeholder={'Units'}
        />
      </Col>
      {units && units !== 'Auto' && limitSteps && (
        <Col xs={12}>
          <Slider
            key={units}
            min={-Math.round(limitSteps / 5)}
            max={Math.ceil(limitSteps)}
            value={magnitude}
            leftText={``}
            rightText={`${limitSteps}`}
            updateRight={false}
            onChange={(v) =>
              onChange(Math.max(Math.min(v, limitSteps), 1), units)
            }
            percUpdate={false}
          />
        </Col>
      )}
    </Row>
  )
}
