import { NotificationManager } from 'react-notifications'

export const validationErrors = {
  nonNumeric: 1,
  outOfRange: 2,
  custom: 3,
  invalidType: 4,
  invalidEmail: 5,
}

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

function addAid(element, message) {
  const parentNode = element.parentNode
  if (parentNode.classList.contains('enforced-validation-container')) {
    if (!element.getAttribute('data-error-tooltip-cleanup')) {
      element.setAttribute('data-error-tooltip-cleanup', true)
      element.addEventListener('focusout', removeAid)
    }
    parentNode.setAttribute('data-error_tooltip', message)
    parentNode.classList.add('active-error')
  }
}

function removeAid(event) {
  const element = event.target
  const parentNode = element.parentNode
  if (parentNode.classList.contains('enforced-validation-container')) {
    parentNode.classList.remove('active-error')
  }
}

//To be used on free inputs on the keypress event
export function enforceValidation({
  warn = false,
  format = null, // (validationErrorCode, value, event):str =>{}
  numeric = false,
  numericInteger = false,
  clamp = null, //{min, max}
  customValidation = null, //For anything else (value, eventKey, event):bool =>{}
  decorate = true, //Display a popup message with the error on the parent as a pseudoelement if parent has class enforced-validation-container
  email = false,
}) {
  if (numericInteger) numeric = true

  if (clamp && !numeric)
    console.warn('Using clamp validation with non numeric validation')

  return (event) => {
    let error = false
    let errorMessage = ''
    let stopInput = true
    const value = event.target.value
    const eventKey = event.key
    const ge0 = clamp?.min === 0

    if (customValidation) {
      const { errorCode, message } = customValidation(value, eventKey, event)
      if (errorCode) {
        error = true
        errorMessage = format ? format(errorCode, value, event) : message
      }
    } else if (numeric) {
      if (eventKey === '.' || eventKey === ',') {
        if (value.includes('.') || value.includes(',')) {
          error = true
          errorMessage = format
            ? format(validationErrors.nonNumeric, value, event)
            : 'Only one decimal point is allowed'
        } else if (numericInteger) {
          error = true
          errorMessage = format
            ? format(validationErrors.invalidType, value, event)
            : 'Integers only'
        }
      } else if (eventKey === '-') {
        if (event.target.selectionStart !== 0 || value.includes('-')) {
          error = true
          errorMessage = format
            ? format(validationErrors.nonNumeric, value, event)
            : 'Only numeric inputs are valid'
        } else if (ge0) {
          error = true
          errorMessage = format
            ? format(validationErrors.nonNumeric, value, event)
            : 'Only positive numbers are allowed'
        }
      } else {
        if (isNaN(eventKey)) {
          error = true
          errorMessage = format
            ? format(validationErrors.nonNumeric, value, event)
            : 'Only numeric inputs are valid'
        } else if (clamp) {
          let numValue = 0
          if (event.target.selectionStart !== event.target.selectionEnd) {
            const strData = value.split('')
            strData.splice(
              event.target.selectionStart,
              event.target.selectionEnd - event.target.selectionStart,
              eventKey,
            )
            numValue = parseFloat(strData.join('').replace(',', '.'))
          } else {
            const strData = value.split('')
            strData.splice(event.target.selectionStart, 0, eventKey)
            numValue = parseFloat(strData.join('').replace(',', '.'))
          }
          if ('min' in clamp)
            if (numValue < clamp.min) {
              error = true
              stopInput = false
              errorMessage = format
                ? format(validationErrors.outOfRange, value, event)
                : `Value out of range, minimum is ${clamp.min}`
            }
          if ('max' in clamp)
            if (numValue > clamp.max) {
              error = true
              errorMessage = format
                ? format(validationErrors.outOfRange, value, event)
                : `Value out of range, maximum is ${clamp.max}`
            }
        }
        if (eventKey === '0' && value.match(/^[0]+$/)) {
          error = true
          errorMessage = format
            ? format(validationErrors.outOfRange, value, event)
            : `More than 1 leading zeros`
        }
      }
    } else if (email) {
      if (value && !value.match(emailRegex)) {
        error = true
        errorMessage = format
          ? format(validationErrors.invalidEmail, value, event)
          : `Invalid email`
      }
    }
    if (error) {
      if (stopInput) {
        event.preventDefault()
        event.stopPropagation()
      }
      if (warn) NotificationManager.warning(errorMessage)
      if (decorate) addAid(event.target, errorMessage)
    } else {
      if (decorate) removeAid(event)
    }
  }
}
