import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import './QuestionnaireForm.css'
import { FaEnvelope, FaKey, FaUser } from 'react-icons/fa'

export const userDataProto = {
  admin_name: '',
  admin_last_name: '',
  email: '',
  password: '',
}
//If you're gonna add fields to User info add them in the prototype too
//Input attr id must match key in prototype
export function userInfoBody(userInfo, props) {
  const defaultStyle = { className: 'my-3' }
  return (
    <>
      <Col md={6} {...defaultStyle}>
        <Form.Label>Name</Form.Label>
        <Col className="posrelative">
          <FaUser className="iconinput" />
          <Form.Control
            key="admin_name"
            id="admin_name"
            className="inputnextbrain"
            defaultValue={userInfo['admin_name']}
            required
            {...props}
          />
        </Col>
      </Col>
      <Col md={6} {...defaultStyle}>
        <Form.Label>Email</Form.Label>
        <Col className="posrelative">
          <FaEnvelope className="iconinput" />
          <Form.Control
            key="email"
            id="email"
            className="inputnextbrain"
            defaultValue={userInfo['email']}
            placeholder="example@email.com"
            type="email"
            required
            {...props}
          />
        </Col>
      </Col>
      <Col md={6} {...defaultStyle}>
        <Form.Label>Password</Form.Label>
        <Col className="posrelative">
          <FaKey className="iconinput" />
          <Form.Control
            key="password"
            id="password"
            className="inputnextbrain"
            type="password"
            defaultValue={userInfo['password']}
            required
            {...props}
          />
        </Col>
      </Col>
      <Col md={6} {...defaultStyle}>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Label>Password</Form.Label>
        <Col className="posrelative">
          <FaKey className="iconinput" />
          <Form.Control
            key="password"
            id="password"
            className="inputnextbrain"
            type="password"
            defaultValue={userInfo['password']}
            required
            {...props}
          />
        </Col>
      </Col>
      <Col md={12}>
        <Form.Check type="checkbox" label="I´m a student" />
      </Col>
    </>
  )
}
