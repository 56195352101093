import { config } from '../Constants'
import { NotificationManager } from 'react-notifications'

export const URL = config.API_URL
export const ADMIN_URL = config.ADMIN_API_URL
export const AI_TOOLS_URL = config.AUTOGEN2_URL

export function generateContentType(contentType) {
  if (!contentType) return null
  switch (contentType.toLowerCase()) {
    case 'json':
      return 'application/json'
    case 'form':
      return "application/x-www-form-urlencoded'"
    default:
      return null
  }
}

export function generateOauthHeader(token, contentType = null) {
  const res = {
    Authorization: `Bearer ${token}`,
  }
  contentType = generateContentType(contentType)
  if (contentType) res['Content-Type'] = contentType
  return res
}

export async function upload({
  endpoint,
  token,
  headers = {},
  method = 'POST',
  filename = 'file',
  file,
  onProgress,
  json = true,
  defaultTail = null,
}) {
  const data = new FormData()
  data.append(filename, file)
  const request = new XMLHttpRequest()
  request.open(method, URL + endpoint, true)
  request.setRequestHeader('Authorization', `Bearer ${token}`)
  Object.entries(headers).forEach(([key, value]) =>
    request.setRequestHeader(key, value),
  )

  return new Promise((resolve, reject) => {
    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        resolve({
          success: true,
          status: request.status,
          response: json
            ? JSON.parse(request.responseText)
            : request.responseText,
          request: request,
        })
        return
      } else {
        reject({
          success: false,
          status: request.status,
          response: request.responseText,
          request: request,
        })
        return
      }
    }
    request.onerror = function () {
      reject({
        success: false,
        status: request.status,
        response: request.responseText,
        request: request,
        error: true,
      })
      return
    }
    request.ontimeout = function () {
      reject({
        success: false,
        status: request.status,
        response: request.responseText,
        request: request,
        timeout: true,
      })
    }
    const tail = defaultTail || `${Math.floor(Math.random() * 100000)}`
    request.upload.addEventListener('progress', (e) => {
      onProgress((e.loaded / e.total) * 100, `${tail}--` + file.name, e)
    })
    onProgress(0, `${tail}--` + file.name)
    request.send(data)
  })
}

export async function request({
  endpoint,
  token,
  contentType,
  body,
  headers = {},
  raw_body = false,
  method = 'GET',
  signout = () => {},
  responseAsJson = true,
  rawResponse = false,
  isAdmin = false,
  isAITools = false,
  ignoreOddStatus = true,
  unathorizedSignout = false,
  ...props
}) {
  if (token) {
    headers = generateOauthHeader(token, contentType)
  } else if (contentType) {
    headers['Content-Type'] = generateContentType(contentType)
  }
  if (body) {
    if (!raw_body) body = JSON.stringify(body)
  }

  try {
    const response = await fetch(
      (isAITools ? AI_TOOLS_URL : isAdmin ? ADMIN_URL : URL) + endpoint,
      {
        method,
        headers,
        body,
        ...props,
      },
    )
    if (rawResponse) return response

    if (response.status === 401) {
      // In this case we need to logout
      if (unathorizedSignout) {
        signout()
        return null
      } else {
        NotificationManager.warning(
          'You dont have permission to access this resource',
        )
        if (window?.navigateTo) {
          window.navigateTo('/')
          return null
        } else {
          return await new Promise((resolve) => {
            setTimeout(() => {
              //Some timeout before redirecting to notify user
              window.location = '/'
              resolve(null)
            }, 3000)
          })
        }
      }
    } else if (
      !ignoreOddStatus &&
      (response.status > 299 || response.status < 200)
    ) {
      return {
        status: response.status,
        response: response,
      }
    }
    if (responseAsJson) {
      const jsonResponse = await response.json()
      return jsonResponse
    }
    return await response.text()
  } catch (error) {
    console.error('Request to', endpoint, 'failed.', error)
    console.error('Data provided:', {
      method,
      headers,
      body,
      ...props,
    })
    return null
  }
}

export async function awaitTask({
  taskUuid,
  decode = null,
  sleep = 2000,
  timeout = null,
  maxRetries = 5,
  callback = null,
}) {
  if (!taskUuid) return null
  return new Promise(async (resolve, reject) => {
    await _awaitTask({
      taskUuid,
      decode,
      resolve,
      reject,
      sleep,
      limit: timeout ? Date.now() + timeout : null,
      maxRetries,
      callback,
    }).catch((e) => reject(e))
  })
}

async function _awaitTask({
  taskUuid,
  decode,
  resolve,
  reject,
  sleep,
  limit,
  maxRetries,
  callback,
}) {
  if (limit && Date.now() > limit) return reject('Timeout')
  const response = await request({
    endpoint: `/task/${decode ? 'decode/' : ''}${taskUuid}`,
  }).catch((e) => reject(e))
  if (callback !== null) {
    callback(response)
  }
  if (
    !response ||
    response.pending ||
    (response.error === 'Not found' && maxRetries > 0)
  )
    setTimeout((_) => {
      _awaitTask({
        taskUuid,
        decode,
        resolve,
        reject,
        sleep,
        limit,
        maxRetries: maxRetries - 1,
        callback,
      })
    }, sleep)
  else if (response.error) reject(response)
  else resolve(response.data)
}

export async function awaitTaskCall(fn, sleep, timeout, ...props) {
  const result = await fn(...props)
  const task_id = result?.task_id
  if (!task_id) throw new Error(`${fn.name} did not generate a task id`)
  return await awaitTask({ taskUuid: task_id, sleep, timeout })
}

async function createBaseReport({ source = 'app', files, userId = null }) {
  if (Array.isArray(files)) {
    files = await Promise.all(
      files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = (event) =>
              resolve(event.target.result.split(',')[1])
            reader.onerror = (event) => resolve(null)
            reader.readAsDataURL(file)
          }),
      ),
    )
  }

  return {
    location: window.location.pathname + window.location.search,
    time: Date.now(),
    files: files,
    userId: userId,
    source,
  }
}

export async function customReport({
  userId = null,
  message = 'Custom report',
  files = null,
  report,
  token,
  signout,
}) {
  if (window.disabledReports) return Promise.resolve((r) => r(true))
  return request({
    endpoint: `/model/report`,
    method: 'POST',
    body: {
      msg: message,
      metadata: {
        base: createBaseReport({ userId, files }),
        ...report,
      },
    },
    contentType: 'json',
    token,
    signout,
  })
}
