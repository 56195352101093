import React, { useState } from 'react'
import { Row, Col, Button, Form, Accordion } from 'react-bootstrap'
import { GridTable } from '../../grid-table/GridTable'
import { ResponsiveLine } from '@nivo/line'
import $ from 'jquery'
import { round } from '../../utils/formating'
import { BasicTooltip } from '@nivo/tooltip'

export function ConfigForecastingHorizon({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 11, w: 7, x: 0, y: 0 },
    ...config,
    title: $('.forecasting-horizon-title').val(),
    requests: ['horizon'],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="forecasting-horizon-title"
            defaultValue={`${config.title ?? 'Forecasting Accuracy'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function VisualizeForecastLine({ columns, data, ...props }) {
  const [selected, setSelected] = useState('Accuracy')
  const dataIndex = columns.indexOf(selected)
  const lineData = [
    {
      id: selected,
      color: 'var(--nextbrain-tables-graph-bar-color)',
      data: data.map((d) => ({
        x: d[0],
        y: round(d[dataIndex]),
      })),
    },
  ]
  if (selected === 'Accuracy') {
    lineData[0].data = [{ x: 0, y: 100 }, ...lineData[0].data]
  } else {
    lineData[0].data = [{ x: 0, y: 0 }, ...lineData[0].data]
  }

  return (
    <Row>
      <Col xs={12}>
        <Form.Select
          onChange={(e) => {
            setSelected(e.target.value)
          }}
          value={selected}
          {...props}
          className={`nb-input-soft  ${props.className ?? ''}`}
        >
          {columns.slice(1).map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs={12} style={{ minHeight: '300px' }}>
        <ResponsiveLine
          data={lineData}
          margin={{ top: 40, right: 50, bottom: 50, left: 110 }}
          xScale={{ type: 'linear', min: 'auto' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
          }}
          curve={'monotoneX'}
          yFormat=" >-.2f"
          xFormat=" >-.2f"
          lineWidth={3}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Horizon (in days)',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          colors={(d) => d.color}
          pointLabelYOffset={-12}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${selected}`,
            legendOffset: -100,
            legendPosition: 'middle',
          }}
          pointSize={10}
          enableGridX={true}
          enableGridY={true}
          useMesh={true}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'points',
            'slices',
            'mesh',
            'legends',
          ]}
          theme={{
            textColor: 'var(--nextbrain-tables-font)',
            axis: {
              ticks: {
                text: {
                  fill: 'var(--nextbrain-widget-axis-legend)',
                },
              },
              legend: {
                text: {
                  fill: 'var(--nextbrain-widget-axis-legend)',
                },
              },
            },
          }}
          tooltip={({ point }) => (
            <BasicTooltip
              id={selected}
              value={`${point.data.y} Horizon (in days):${point.data.x}`}
              color={point.color}
              enableChip
            />
          )}
          legends={[]}
        />
      </Col>
    </Row>
  )
}

export function WidgetForecastingHorizon({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  const rq = requestedData['horizon']
  if (!model || !config || !rq || !(typeof rq === 'object'))
    return <>Loading...</>

  return (
    <Row className="w-100 h-100 overflow-hidden" id={id}>
      <Col
        className="header-data-distribution d-inline-block text-truncate widget-title"
        xs={12}
      >
        {config.title}
      </Col>
      <Col xs={12} style={{ overflow: 'auto' }}>
        <Accordion bsPrefix="nb" className="animated-arrow nb-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Row className="w-100">
                <Col md={'auto'} className="text-left">
                  <span>Metrics table</span>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body className="overflow-scroll">
              <GridTable
                className="widget-report-grid-table"
                header={[rq.columns]}
                headerElement={(h) => <div>{h}</div>}
                rows={rq.data.map((d) => d.map((dd) => round(dd)))}
                rowsPerPage={10}
                defaultColumnWidth={() => 100}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      <Col xs={12} style={{ overflow: 'auto' }}>
        <VisualizeForecastLine columns={rq.columns} data={rq.data} />
      </Col>
    </Row>
  )
}
