import React, { useMemo, useState, useEffect } from 'react'
import { colors } from './config'
import { useModels } from '../../providers/ModelProvider'
import { ResponsiveLine } from '@nivo/line'
import { getMMMLagCarryover } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery } from 'react-query'
import Loading from '../loading/LoadingSmall'
import { zip } from '../../util/other'
import { generateMMMColorMap } from '../../util/models'
import { useTranslation } from 'react-i18next'
import { integerParams } from './config'
import { nbTheme } from '../utils/ui'
import { useInteractiveLineLegend } from '../graph-layers/useInteractiveLineLegend'

export default function LagAndCarryover({ model = null, isInView = true }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const { activeModel } = useModels()

  if (!model) model = activeModel

  const { data, isLoading, isSuccess } = useQuery(
    ['lag-carryover', model.id],
    async () => {
      return await getMMMLagCarryover({
        modelId: model.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )
  const [lines, params, maxY] = useMemo(() => {
    if (data && data.data) {
      const params = integerParams
      const colorMap = generateMMMColorMap(model, colors)

      return [
        zip([data.columns, ...data.data])
          .slice(1)
          .map(([key, ...data]) => ({
            id: key,
            color: colorMap[key],
            data: data.map((d, i) => ({ x: i, y: d })),
          })),
        params,
        Math.max(...data.data.map((d) => Math.max(...d.slice(1)))),
      ]
    }
    return [null, {}, 'auto']
    // eslint-disable-next-line
  }, [data])

  const csvData = lines?.[0]?.data
    ? [
        ['lag in weeks', ...lines.map((line) => line.id)],
        ...zip([
          lines[0].data.map((s) => s.x),
          ...lines.map((line) => line.data.map((d) => d.y)),
        ]),
      ]
    : []

  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess) {
      setShownData({
        lines: lines.map((line) => ({
          ...line,
          data: line.data.map((s) => ({ ...s, y: 0 })),
        })),
      })
      if (isInView) {
        setTimeout(
          () =>
            setShownData({
              lines,
            }),
          100,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, data])

  const LegendLayer = useInteractiveLineLegend({
    marginBottom: 150,
    onGraphWidth: 280,
  })
  const [filters, setFilters] = useState({})

  return !shownData || isLoading ? (
    <Loading />
  ) : (
    <ResponsiveLine
      {...params}
      data={shownData.lines.filter((d) => !filters[d.id])}
      margin={{ top: 60, right: 20, bottom: 60, left: 80 }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: maxY,
        stacked: false,
        reverse: false,
      }}
      colors={(d) => d.color}
      curve={'monotoneX'}
      yFormat=" >-.2f"
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        ...params.axisBottom,
        legend: t('Lag (in weeks)'),
        legendOffset: 35,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Carryover'),
        legendOffset: -70,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      legends={[]}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
        () => (
          <div
            className="data-holder display-none"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`lag_carryover__${model.id}`}
          ></div>
        ),
        (props) => (
          <LegendLayer
            {...props}
            data={shownData.lines}
            filters={filters}
            setFilters={setFilters}
          />
        ),
      ]}
      theme={{
        ...nbTheme,
        tooltip: {
          container: {
            fontSize: 11,
          },
        },
      }}
    />
  )
}
