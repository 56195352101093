import React, { useRef, useEffect } from 'react'
import { round, readableNumber, readableNumberMMM } from '../utils/formating'
import { GridTable, DownloadPosition } from '../grid-table/GridTable'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Loading from '../loading/LoadingSmall'
import { useAuth } from '../../providers/AuthProvider'
import { getMMMSaturationCurves } from '../../services/model'
import { useQuery } from 'react-query'
import './Table.css'

const _cell_size = [4, 3, 2, 3, 2, 2]
const _total = _cell_size.reduce((a, b) => a + b, 0)

export default function Table({
  model,
  maxHeight = 300,
  table = 'modelTable',
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [width, setWidth] = React.useState(0)
  const rowRef = useRef()
  if (table === 'modelTable') table = model.mmm.table

  const header = table?.columns?.map((c) => (c === 'CPA' ? 'ROI' : c))
  if (Array.isArray(header)) header[0] = 'Media'

  useEffect(() => {
    if (rowRef.current) {
      setWidth(rowRef.current.offsetWidth - 40)
    }
  }, [rowRef])

  const { data, isSuccess, isLoading } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      const res = await getMMMSaturationCurves({
        modelId: model.id,
        token,
        signout,
      })
      return res
    },
    { staleTime: Infinity },
  )

  if (!table) return <></>

  // Get index of Share
  const mediaSpendIndex = header.indexOf('Media spend')
  const mediaOutcomeIndex = header.indexOf('Media outcome')
  const mediaContributionIndex = header.indexOf('Media contribution')
  const recommendedSpendIndex = header.indexOf('Recommended spend')
  const ROIIndex = header.indexOf('ROI')
  const maximizedOutcomeIndex = header.indexOf('Maximized outcome')
  const channelMaxSpendIndex = header.indexOf('Channel Max spend')
  const indexOfMediaContributions = header.indexOf('Contribution %')
  let totalMediaSpend = 0
  let totalMediaOutcome = 0

  let smallROI = table.data.reduce(
    (ac, row) => ac || 1 / row[ROIIndex] < 0.0001,
    false,
  )

  const rows = table.data.map((row) =>
    row
      .map((cell, index) => {
        const value = isNaN(cell) ? cell : cell < 0.01 ? cell : round(cell, 2)
        switch (index) {
          case mediaContributionIndex:
            return `${value}%`
          case recommendedSpendIndex:
          case ROIIndex:
            return (
              <strong className="text-success">
                {readableNumberMMM(((smallROI ? 1000 : 1) * 1) / value)}
              </strong>
            )
          case maximizedOutcomeIndex:
          case channelMaxSpendIndex:
            return null
          case mediaSpendIndex:
            totalMediaSpend += value
            return readableNumber(value)
          case mediaOutcomeIndex:
            totalMediaOutcome += value
            return readableNumber(value)
          case indexOfMediaContributions:
            return value
          default:
            return readableNumber(value)
        }
      })
      .filter((v) => v !== null),
  )

  const mediaSpendContributionTotal = rows
    .map((r) => r[2])
    .reduce((partial, a) => partial + parseFloat(a.replaceAll('%', '')), 0)

  rows.forEach(
    (r) =>
      (r[indexOfMediaContributions] =
        readableNumber(
          round(
            (r[indexOfMediaContributions] * 100) / mediaSpendContributionTotal,
          ),
          2,
        ) + '%'),
  )

  const dataHeader = header
    .map((c, i) =>
      i === ROIIndex ? (smallROI ? 'Media ROI x1000' : 'Media ROI') : c,
    )
    .filter((c) => c !== 'Channel Max spend')

  let convertibility = false
  let cell_size = _cell_size
  let total = _total
  let otherFooter = []
  if (data && isSuccess) {
    let sumConvertibility = 0
    cell_size = [..._cell_size, 2]
    total += 2
    convertibility = true
    dataHeader.push('Convertibility')
    rows.forEach((r, i) => {
      if (!data?.[r[0]]) return
      try {
        const c =
          Number.parseFloat(r?.[1]?.replaceAll(',', '')) / data?.[r[0]]?.km
        if (isNaN(c)) r.push('')
        else {
          sumConvertibility += c
          r.push(round(c, 2))
        }
      } catch (e) {
        return
      }
    })
    otherFooter = [<strong>{round(sumConvertibility, 2)}</strong>]
  }

  return !width || isLoading ? (
    <Row ref={rowRef} className="w-100">
      <Col xs={12}>
        <Loading />
      </Col>
    </Row>
  ) : (
    <Row>
      <Col xs={12}>
        <GridTable
          header={[dataHeader]}
          rows={rows}
          download={DownloadPosition.INDEX}
          downloadFilename={`Dataset_${model?.dataset?.name}`}
          downloadProcessCells={(cell) => {
            const data = String(
              typeof cell === 'object' ? cell?.props?.children ?? cell : cell,
            ).replaceAll(',', '')
            return data
          }}
          rowsPerPage={rows.length}
          index={(i) => (
            <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
              {i ? i : ''}
            </div>
          )}
          className="w-100 table-view-data mmm-table"
          style={{ maxHeight: maxHeight }}
          defaultColumnWidth={(str, i) =>
            Math.floor((cell_size[i] * width) / total)
          }
          groupings={{
            [t('Input')]: 2,
            [t('Output')]: header.length - 3 + (convertibility ? 1 : 0),
          }}
          groupingElement={({ name }) => (
            <Row className="media-marketing-grouping-element px-0 gx-0 py-3">
              <Col xs={12}>{name}</Col>
            </Row>
          )}
          stylesByColumn={[
            undefined,
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
          ]}
          footer={[
            <strong>Total</strong>,
            <strong>{readableNumber(round(totalMediaSpend, 2))}</strong>,
            <strong>{round(mediaSpendContributionTotal, 2)}%</strong>,
            <strong>{readableNumber(round(totalMediaOutcome, 2))}</strong>,
            <strong>{readableNumber(round(100, 2))}%</strong>,
            <strong className="text-success">
              {readableNumberMMM(
                ((smallROI ? 1000 : 1) * totalMediaOutcome) / totalMediaSpend,
              )}
            </strong>,
            ...otherFooter,
          ]}
          pagerLast={true}
        />
      </Col>
    </Row>
  )
}
