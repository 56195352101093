import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import {
  FaFileDownload,
  FaTable,
  FaCloudDownloadAlt,
  FaChevronRight,
  FaChevronLeft,
  FaListUl,
  FaSpinner,
} from 'react-icons/fa'
import { IoSearchSharp } from 'react-icons/io5'
import { BiDetail } from 'react-icons/bi'
import { FcMultipleInputs } from 'react-icons/fc'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { TiSortAlphabetically } from 'react-icons/ti'
import { RiShareBoxFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

import { GridTable, DownloadPosition } from '../grid-table/GridTable'
import { useAuth } from '../../providers/AuthProvider'
import { modelIsImporting } from '../../util/models'
import StatusMessage from '../model-content/StatusMessage'
import ColumnAndType from './ColumnAndType'
import ViewDataEdit from './ViewDataEdit'
import './view-data.css'
import {
  getModelById,
  getModelDeletedColumns,
  getModelSummary,
  updateModelDataset,
} from '../../services/model'
import { useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'
import SyntheticDataSummary from '../model-content/model-summary/SyntheticDataSummary'
import { defaultFormat } from '../utils/formating'
import ViewDataDeletedColumn from './ViewDataDeletedColumn'
import PbarProgress from '../pbar-progress/PbarProgress'
import { config } from '../../Constants'
import FillColumns from './FillColumns'
import { downloadDataset } from '../../services/model'
import NextbrainSelect from '../model-content/NextbrainSelect'
import $ from 'jquery'
import { v4 as uuidv4 } from 'uuid'
import RoleDisable from '../utils/RoleDisable'
import { useModels } from '../../providers/ModelProvider'

function AWrap({ children, ...props }) {
  return <a {...props}>{children}</a>
}

async function downloadItem({ message, promise }) {
  const id = 'unique_selector' + uuidv4().split('-')[0]
  NotificationManager.info(
    <Row className={`${id}`}>
      <Col className="text-center" xs={9}>
        {message}
      </Col>
      <Col
        className="d-inline-flex justify-content-center align-items-center"
        xs={3}
      >
        <FaSpinner className="rotating-2" size={20} />
      </Col>
    </Row>,
    null,
    20000000,
  )
  await promise
  setTimeout(() => {
    document.querySelector(`.${id}`)?.click()
  }, 500)
}

async function _downloadDescriptions(event, model, descriptions) {
  const target = event.currentTarget
  target?.classList?.add('disabled-section')
  const blob = Object.entries(descriptions)
    .map(([k, v]) => {
      const v_adjust = v.replaceAll('"', '\\"')
      return `${k},"${v_adjust}"`
    })
    .join('\n')
  const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `Columns_descriptions_${model?.dataset?.name}`)
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    URL.revokeObjectURL(url)
  }, 5000)

  target?.classList?.remove('disabled-section')
}

function NavigatePage({
  page,
  pageOptions,
  columnOptions,
  onChangePage,
  columnsPerPage,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Row {...props}>
      <Col className="mb-1" xs={12}>
        <Row>
          <Col xs={6} className="mt-2">
            {t('Select page')}:
          </Col>
          <Col xs={6}>
            <NextbrainSelect
              value={{ label: page + 1, value: page }}
              onChange={(value) => onChangePage(value?.value)}
              options={pageOptions}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder={'Select a page'}
              type={'dark'}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-3 mb-1" xs={12}>
        {t('Go to page containing column')}:
      </Col>
      <Col xs={12}>
        <NextbrainSelect
          value={null}
          onChange={(value) => {
            onChangePage(value?.index)
          }}
          options={columnOptions}
          hideSelectedOptions={false}
          isClearable={false}
          placeholder={'Select a column'}
          type={'dark'}
        />
      </Col>
    </Row>
  )
}

function ContextMenuViewData({
  visible,
  setVisible,
  model,
  sampleData,
  ...props
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    const clear = () => setVisible(false)
    document.querySelector('body').addEventListener('click', clear)
    return () =>
      document.querySelector('body').removeEventListener('click', clear)
  }, [setVisible])

  return (
    <span
      className="position-relative"
      onClick={(e) => {
        e.stopPropagation()
        setVisible(!visible)
      }}
    >
      {visible && (
        <Row
          className="my-2 px-4 py-3 custom-context-view-data"
          onClick={(e) => e.stopPropagation()}
          style={{}}
        >
          <RoleDisable className="px-0">
            <Col
              className="linkshare link white-link"
              xs={12}
              title={t('Share dataset')}
              as={AWrap}
              href={`/view-data/${model.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="d-inline-block text-truncate color-default like-span"
                style={{ float: 'left' }}
              >
                {t('Share dataset')}
              </span>
              <RiShareBoxFill
                style={{ float: 'right' }}
                className="color-default"
              />
            </Col>
          </RoleDisable>
          <Col
            className="linkshare link white-link mt-2 synthetic-summary-context-menu"
            xs={12}
            title={
              model?.dataset?.rows > 5000
                ? t('Can not generate synthetic datafor this model')
                : t('Generate synthetic data')
            }
          >
            <SyntheticDataSummary model={model} generate={true} />
          </Col>
          <Col
            className="linkshare white-link link mt-2"
            xs={12}
            title={t('Download original dataset')}
            onClick={(e) => {
              const promise = downloadDataset({
                modelId: model.id,
                token,
                signout,
              })
                .then((response) => response.blob())
                .then((blob) => {
                  const name = model?.dataset?.name ?? 'dataset'
                  // Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob]))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', name + '.csv')

                  // Append to html link element page
                  document.body.appendChild(link)

                  // Start download
                  link.click()

                  // Clean up and remove the link
                  link.parentNode.removeChild(link)
                })
                .catch((e) => {
                  NotificationManager.error(t('Error downloading dataset'))
                })
              document.querySelector('body')?.click()
              downloadItem({
                promise,
                message: 'Download original dataset',
              })
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <span
                className="d-inline-block text-truncate"
                style={{ float: 'left' }}
              >
                {t('Download original dataset')}
              </span>
              <FaFileDownload
                style={{
                  marginLeft: 5,
                  float: 'right',
                }}
              />
            </div>
          </Col>
          <Col
            className="linkshare link white-link mt-2 position-relative cursor-pointer"
            xs={12}
            title={t('Download modified dataset')}
            target="_blank"
            rel="noreferrer"
            href={`${config.API_URL}/model/csv_transformed/${
              model.id
            }?token=${encodeURIComponent(token)}`}
            as={AWrap}
          >
            <span
              className="d-inline-block text-truncate color-default  like-span"
              style={{ float: 'left', maxWidth: '80%' }}
            >
              {t('Download modified dataset')}
            </span>
            <FaCloudDownloadAlt
              className="color-default"
              style={{ float: 'right' }}
            />
          </Col>
          <Col
            className="linkshare link white-link mt-2"
            xs={12}
            title={t(`Download columns descriptions`)}
            onClick={(event) =>
              _downloadDescriptions(
                event,
                model,
                sampleData.columns_description,
              ).catch((e) => {
                NotificationManager.error(
                  t('Error downloading columns descriptions'),
                )
                event.target?.classList?.remove('disabled-section')
              })
            }
          >
            <div style={{ cursor: 'pointer' }}>
              <span
                className="d-inline-block text-truncate"
                style={{
                  float: 'left',
                  maxWidth: '80%',
                }}
              >
                {t(`Download columns descriptions`)}
              </span>
              <TiSortAlphabetically style={{ float: 'right' }} size={20} />
            </div>
          </Col>
        </Row>
      )}
      <BsThreeDotsVertical className="icon-btn context-menu" size={30} />
    </span>
  )
}

export default function ViewDataTable({
  model,
  getHead,
  dataId,
  onChange,
  setTitle,
  customOrder,
  updateModel = () => {},
  rowsPerPage = Infinity,
  titlePrefix = 'Sample',
  exportButton = false,
  columnToType = {},
  maxHeight = '500px',
  onColumnTypeChanged = (column, newType, dateFormat) =>
    console.log(
      'Change column',
      column,
      'to the type',
      newType,
      'with format',
      dateFormat,
    ),
  showStatistics = true,
  errorMessage = 'Failed to retrieve data',
  hasEditMode = false,
  onLoad = () => {},
  linkFlow,
  additionHeaders,
  ...props
}) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const { role } = useModels()
  const param = useParams()
  const [rows, setRows] = useState([])
  const [formatedRows, setFormatedRows] = useState([])
  const [originalRulesRows, setOriginalRulesRows] = useState(null)
  const [rulesRows, setRulesRows] = useState(null)
  const [newDeleteColumns, setNewDeletedColumns] = useState(new Set([]))
  const [sort, setSort] = useState(null)
  const [columnToFill, setColumnToFill] = useState(null)
  const isImporting = modelIsImporting(model)
  const queryClient = useQueryClient()
  const readOnly = role !== 'editor'
  const [contextVisible, setContextVisible] = useState(false)

  const { data: summary } = useQuery(
    ['model-summary', model?.id, model?.dataset?.status],
    async () => {
      if (model?.dataset?.status === 'imported') {
        const summary = await getModelSummary({
          modelId: model?.id,
          token,
          signout,
        })
        if (!summary)
          setTimeout(
            () =>
              queryClient.invalidateQueries([
                'model-summary',
                model?.id,
                model?.dataset?.status,
              ]),
            2000,
          )
        else return summary
      }

      return null
    },
    { staleTime: Infinity },
  )

  const { data: baseSample, fetchNextPage } = useInfiniteQuery(
    ['viewData-infinite', dataId, model?.dataset?.status],
    async ({ pageParam = 0 }) => {
      if (model && model?.dataset?.status !== 'imported') return null
      let response = await getHead({
        modelId: dataId ? dataId : param.id,
        pageSize: 100,
        pageNumber: pageParam + 1,
        rules_matrix: true,
        token,
        signout,
      })

      if (!response || response.status === 'error' || response.detail) {
        response = { status: 'error', message: t(errorMessage) }
      } else if (!response) {
        response = {
          status: 'error',
          message: t('Error retrieving data'),
        }
      } else if (Array.isArray(response?.pages))
        response?.pages.forEach((r) => {
          if (r.head)
            Object.keys(r.head).forEach(
              (k) => (r.head[k.replace(/^(ln_)||(sq_)/, '')] = r.head[k]),
            )
          if (r.head_rules)
            Object.keys(r.head_rules).forEach(
              (k) =>
                (r.head_rules[k.replace(/^(ln_)||(sq_)/, '')] =
                  r.head_rules[k]),
            )
        })
      return response
    },
    {
      getNextPageParam: (_, items) => {
        return items.length
      },
      staleTime: Infinity,
    },
  )

  const { data: deletedColumns } = useQuery(
    ['columnsDeleted', model?.id],
    async () =>
      model?.id
        ? await getModelDeletedColumns({ modelId: model?.id, token, signout })
        : {},
    { staleTime: Infinity },
  )

  const sampleData = useMemo(() => {
    if (baseSample?.pages?.filter((v) => v)?.length) {
      const adjustedSample = {
        columns_description:
          baseSample?.pages
            ?.filter((v) => v)
            ?.find((p) => p.columns_description)?.columns_description ?? {},
        columns_order:
          baseSample?.pages?.filter((v) => v)?.find((p) => p.columns_order)
            ?.columns_order ?? [],
        head:
          baseSample?.pages
            ?.filter((v) => v)
            ?.reduce((acc, p) => {
              if (p?.head) {
                Object.keys(p.head).forEach((k) => {
                  acc[k] = acc[k] ?? []
                  acc[k].push(...p.head[k])
                })
              }
              return acc
            }, {}) ?? {},
        head_rules:
          baseSample?.pages
            ?.filter((v) => v)
            ?.reduce((acc, p) => {
              if (p?.head_rules) {
                Object.keys(p.head_rules).forEach((k) => {
                  acc[k] = acc[k] ?? []
                  acc[k].push(...p.head_rules[k])
                })
              }
              return acc
            }, {}) ?? {},
      }
      try {
        const keymap = Object.entries(deletedColumns).reduce((acc, [k, v]) => {
          if (Array.isArray(v)) v?.forEach((c) => (acc[c] = k))
          return acc
        }, {})
        const len = Object.values(adjustedSample?.head ?? {})?.[0]?.length ?? 0
        return {
          ...adjustedSample,
          columns_order: [
            ...adjustedSample.columns_order,
            ...Object.keys(keymap),
          ],
          head: {
            ...adjustedSample.head,
            ...Object.keys(keymap).reduce((acc, k) => {
              acc[k] = Array(len).fill(null)
              return acc
            }, {}),
          },
          head_rules: {
            ...adjustedSample.head_rules,
            ...Object.keys(keymap).reduce((acc, k) => {
              acc[k] = Array(len).fill(null)
              return acc
            }, {}),
          },
          deletedColumns: keymap,
        }
      } catch (e) {
        return adjustedSample
      }
    }
    return null
    // eslint-disable-next-line
  }, [baseSample, deletedColumns])

  const headerRef = useRef()
  const footerRef = useRef()
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState(maxHeight)

  useEffect(() => {
    onLoad(sampleData)
    const widthCallback = () => {
      if (headerRef.current) {
        const position = $(headerRef.current)?.offset()?.top
        const height = $(headerRef.current)?.outerHeight(true)
        if (Number.isNaN(position) || Number.isNaN(height)) return
        setCalculatedMaxHeight(`calc(100vh - ${position + height + 15}px)`)
      }
    }
    widthCallback()
    window.addEventListener('resize', widthCallback)
    return () => window.removeEventListener('resize', widthCallback)
    // eslint-disable-next-line
  }, [sampleData])

  const columnSegmentation = useMemo(() => {
    if (sampleData?.columns_order) {
      const columnsPerPage = 100
      const columns = sampleData.columns_order
      const pages = Math.ceil(columns.length / columnsPerPage)
      const pageOptions = new Array(pages).fill(0).map((_, i) => ({
        label: i + 1,
        value: i,
      }))
      const columnOptions = columns.map((c, i) => ({
        label: c,
        value: c,
        index: Math.floor(i / columnsPerPage),
      }))
      return { columns, pages, columnsPerPage, pageOptions, columnOptions }
    }
  }, [sampleData])

  const [columnPage, setColumnPage] = useState(0)

  const { header } = useMemo(() => {
    if (!sampleData || !('columns_order' in sampleData)) return {}

    const begin = columnPage * columnSegmentation.columnsPerPage
    const end = (columnPage + 1) * columnSegmentation.columnsPerPage

    const segmentedSampleData = {
      deletedColumns: sampleData.deletedColumns,
      columns_description: sampleData.columns_description,
      columns_order: sampleData.columns_order.slice(begin, end),
      head: sampleData.head,
      head_rules: sampleData.head_rules,
    }

    const currentColumnOrder =
      segmentedSampleData?.columns_order?.filter(
        (c) => !newDeleteColumns.has(c),
      ) ?? []
    const customFormat = {}
    currentColumnOrder.forEach((k) => {
      switch (model?.dataset?.final_column_status?.[k]) {
        case 'Integer':
        case 'Double':
          customFormat[k] = segmentedSampleData?.head?.[k]?.map((v) =>
            defaultFormat({
              num: v,
              digits: 2,
              wrap: true,
            }),
          )
          break
        default:
          break
      }
    })
    let numRows =
      segmentedSampleData?.head[currentColumnOrder?.[0]]?.length ?? 0

    let rows = []
    let formatedRows = []
    for (let i = 0; i < numRows; i++) {
      let row = {}
      let formatedRow = {}
      for (let col of currentColumnOrder) {
        row[col] = segmentedSampleData.head?.[col]?.[i]
        formatedRow[col] =
          customFormat[col]?.[i] ?? segmentedSampleData.head?.[col]?.[i]
      }
      rows.push(row)
      formatedRows.push(formatedRow)
    }
    if (segmentedSampleData.target) {
      currentColumnOrder.push(
        currentColumnOrder.splice(
          currentColumnOrder.indexOf(segmentedSampleData.target),
          1,
        )[0],
      )
    }
    setRows(rows.map((r) => currentColumnOrder.map((k) => r[k])))
    setFormatedRows(
      formatedRows.map((r) => currentColumnOrder.map((k) => r[k])),
    )

    if (sampleData?.head_rules) {
      const value = rows.map((r, i) =>
        currentColumnOrder.map((k) => sampleData?.head_rules?.[k]?.[i]),
      )
      setRulesRows(value)
      setOriginalRulesRows(value)
    } else {
      setRulesRows(null)
      setOriginalRulesRows(null)
    }

    return { header: [currentColumnOrder] }
    // eslint-disable-next-line
  }, [sampleData, newDeleteColumns, columnPage])

  useEffect(() => {
    if (setTitle) setTitle(`${t('View data')} | ${t('NextBrain')}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableReady = Array.isArray(rows) && Array.isArray(header)

  const deleteColumn = async (col) => {
    const currentColumns = newDeleteColumns
    setNewDeletedColumns((c) => new Set([...c, col]))
    return updateModelDataset({
      modelId: model.id,
      columnsToDelete: [col],
      columnsTypesToUpdate: [],
      token,
      signout,
    })
      .then(async (response) => {
        if (response?.ok) {
          const updatedModel = await getModelById(model.id, token, signout)
          await updateModel(null, updatedModel)
          queryClient.invalidateQueries([
            'viewData-infinite',
            model?.id,
            'imported',
          ])
          queryClient.invalidateQueries(['model-summary', model.id, 'imported'])
          queryClient.invalidateQueries(['model-kmean-clusters', model?.id])
          queryClient.invalidateQueries(['columnsDeleted', model?.id])
        } else {
          NotificationManager.error(t('Error updating dataset'))
          setNewDeletedColumns(currentColumns)
        }
      })
      .catch((e) => {
        NotificationManager.error(t('Error updating dataset'))
        setNewDeletedColumns(currentColumns)
      })
  }

  const ColNavigation = ({ ...props }) => (
    <span className={`${props?.className ?? ''}`}>
      <Row className="justify-content-end" style={{ maxWidth: '150px' }}>
        <Col
          xs={12}
          className="flex-row d-flex smallp justify-content-center mb-1"
        >
          <div className="flex-nowrap d-flex flex-row">
            {t('Showing columns')}
            <strong className="mx-1">
              {1 + columnPage * columnSegmentation.columnsPerPage}
            </strong>
            —
            <strong className="mx-1">
              {(header[0]?.length ?? 0) +
                columnPage * columnSegmentation.columnsPerPage}
            </strong>
          </div>
        </Col>
        <Col xs={12} className="justify-content-center d-flex flex-nowrap">
          <span
            className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
            disabled={columnPage === 0}
            onClick={() => setColumnPage(Math.max(columnPage - 1, 0))}
          >
            <FaChevronLeft size={24} />
          </span>
          <OverlayTrigger
            rootClose={true}
            trigger={'click'}
            placement="auto"
            delay={{ show: 200, hide: 0 }}
            overlay={(props) => (
              <Popover {...props} style={{ ...props.style, minWidth: '300px' }}>
                <div
                  style={{
                    width: '300px',
                    backgroundColor: 'var(--nextbrain-body-75)',
                    border: '1px solid var(--nextbrain-background)',
                    borderRadius: 5,
                  }}
                >
                  <NavigatePage
                    page={columnPage}
                    pageOptions={columnSegmentation.pageOptions}
                    columnOptions={columnSegmentation.columnOptions}
                    onChangePage={(p) => setColumnPage(p)}
                    columnsPerPage={columnSegmentation.columnsPerPage}
                    className="p-3"
                  />
                </div>
              </Popover>
            )}
          >
            <span className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap">
              <IoSearchSharp size={18} />
            </span>
          </OverlayTrigger>
          <span
            className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
            disabled={columnPage === columnSegmentation.pages - 1}
            onClick={() =>
              setColumnPage(
                Math.min(columnPage + 1, columnSegmentation.pages - 1),
              )
            }
          >
            <FaChevronRight size={24} />
          </span>
        </Col>
      </Row>
    </span>
  )

  const enableDetails =
    model &&
    (true ||
      (!Number.isNaN(model?.dataset?.cols) && model?.dataset?.cols < 100))

  const [tab, setTab] = useState(0)

  const viewItem = footerRef.current
  useEffect(() => {
    if (viewItem) {
      let options = {
        root: document.querySelector('.grid-table'),
        rootMargin: '500px',
        threshold: 1,
      }
      //const observer = new IntersectionObserver(fetchNextPage, options)
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) fetchNextPage()
      }, options)
      observer.observe(viewItem)
      return () => observer.disconnect()
    }
    // eslint-disable-next-line
  }, [viewItem])

  return (
    <div
      {...props}
      className={`view-data-component ${
        hasEditMode ? 'with-edit-mode' : 'no-edit-mode'
      } ${props?.className ?? ''}`}
    >
      <Tabs selectedIndex={tab} onSelect={() => {}}>
        {(columnSegmentation?.pages ?? 0) > 1 && (
          <Row className="d-lg-none d-flex mb-1">
            <Col className="d-flex justify-content-end pe-0" xs={12}>
              <ColNavigation className="d-lg-none d-inline-block" />
            </Col>
          </Row>
        )}
        <Row
          className="alt-tabsdiv-table-view-data justify-content-start"
          style={{
            transitionDuration: '0.5s',
            opacity: tableReady ? 1 : 0,
            zIndex: 0,
          }}
        >
          <Col
            className={`d-none ps-0`}
            xs={12}
            style={{ maxWidth: '350px', marginLeft: '4px' }}
          >
            <TabList className="ps-2 mb-0 d-flex flex-nowrap">
              <Tab>
                <div className="mt-2">
                  <span className="h6 mx-3">
                    <FaTable className="me-1" size={17} />
                    {t('Table')}
                  </span>
                </div>
              </Tab>
              <Tab>
                <div className="mt-2">
                  <span className="h6 mx-3">
                    <BiDetail size={20} /> {t('Details')}{' '}
                  </span>
                </div>
              </Tab>
            </TabList>
          </Col>
        </Row>
        <TabPanel>
          <div className="view-data-table-container w-100">
            {tableReady ? (
              <GridTable
                key={columnPage}
                rows={formatedRows}
                rowsPerPage={rowsPerPage}
                header={header}
                datasetLength={model?.dataset?.rows}
                download={DownloadPosition.INDEX}
                downloadFilename={`Dataset_${model?.dataset?.name}`}
                downloadProcessCells={(cell) =>
                  String(cell).replaceAll(',', '.') ?? cell
                }
                customOrder={customOrder}
                cellElement={(str, _, position) => {
                  let isGood = true
                  let rule_value =
                    rulesRows !== null
                      ? rulesRows?.[position?.row]?.[position?.column]
                      : null

                  let reasons = []
                  if (rule_value !== null && rule_value > 0) {
                    isGood = false
                    if (rule_value % 10 === 1) {
                      reasons.push('Too many nulls in column')
                    }
                    if (Math.floor(rule_value / 10) % 10 === 1) {
                      reasons.push('Value is null')
                    }
                    if (Math.floor(rule_value / 100) % 10 === 1) {
                      reasons.push('Numerical outlier')
                    }
                    if (Math.floor(rule_value / 1000) % 10 === 1) {
                      reasons.push('Categorical outlier')
                    }
                    if (Math.floor(rule_value / 10000) % 10 === 1) {
                      reasons.push('Duplicated row')
                    }
                    if (Math.floor(rule_value / 100000) % 10 === 1) {
                      reasons.push(
                        'Potentially numerical column with inconsistent data types',
                      )
                    }
                  }

                  let element = (
                    <span className={`single-line ${isGood ? '' : 'bad-cell'}`}>
                      {str}
                    </span>
                  )
                  if (isGood) return element
                  return [element, reasons]
                }}
                headerElement={(column, width) =>
                  sampleData?.deletedColumns?.[column] ? (
                    <ViewDataDeletedColumn
                      column={column}
                      width={width}
                      reason={sampleData?.deletedColumns?.[column]}
                    />
                  ) : (
                    <ColumnAndType
                      column={column}
                      summary={summary?.[column]}
                      width={width}
                      columnToType={columnToType}
                      columnDescription={
                        sampleData.columns_description?.[column]
                      }
                      onFill={() => setColumnToFill(column)}
                      sort={sort}
                      header={header}
                      rows={rows}
                      setRows={setFormatedRows}
                      originalRulesRows={originalRulesRows}
                      setRulesRows={setRulesRows}
                      setSort={setSort}
                      onColumnTypeChanged={onColumnTypeChanged}
                      sampleData={sampleData}
                      model={model}
                      deleteColumn={deleteColumn}
                      readOnly={readOnly}
                    />
                  )
                }
                index={(i, v) => (
                  <div
                    className={`grid-table-cell index-cell ${i ? '' : 'first'}`}
                  >
                    <div className="d-flex flex-column ">{i ? i : ''}</div>
                  </div>
                )}
                className={`w-100 table-view-data`}
                defaultColumnWidth={(str) => Math.max(200, str.length * 12)}
                style={{ maxHeight: calculatedMaxHeight }}
                pagerLast={true}
                scroll={({ scrollElement }) => (
                  <div
                    className="d-inline-flex flex-nowrap align-items-center w-100"
                    ref={headerRef}
                    style={{
                      float: 'right',
                      marginBottom: '3px',
                    }}
                  >
                    {(columnSegmentation?.pages ?? 0) > 1 && (
                      <ColNavigation className="d-lg-inline-block d-none" />
                    )}
                    {model && (
                      <div className="d-flex flex-nowrap justify-content-between w-100">
                        <div
                          className="d-flex align-items-end"
                          style={{ maxWidth: 'calc(100% - 100px)' }}
                        >
                          {additionHeaders}
                        </div>
                        <div
                          className="d-flex align-items-end"
                          style={{ maxWidth: '100px' }}
                        >
                          {enableDetails && (
                            <RoleDisable>
                              <OverlayTrigger
                                rootClose={true}
                                trigger={['hover', 'focus']}
                                placement={'left'}
                                delay={{ show: 100, hide: 100 }}
                                overlay={(props) => (
                                  <Tooltip
                                    {...props}
                                    className={`${
                                      props?.className ?? ''
                                    } context-menu-column-type big-menu pe-none`}
                                  >
                                    {t('View details')}
                                  </Tooltip>
                                )}
                              >
                                <span className="me-2">
                                  {tab === 0 ? (
                                    <FaListUl
                                      className="icon-btn context-menu"
                                      size={28}
                                      onClick={() => setTab(1)}
                                    />
                                  ) : (
                                    <FaTable
                                      className="icon-btn context-menu"
                                      size={28}
                                      onClick={() => setTab(0)}
                                    />
                                  )}
                                </span>
                              </OverlayTrigger>
                            </RoleDisable>
                          )}
                          {linkFlow && (
                            <OverlayTrigger
                              rootClose={true}
                              trigger={['hover', 'focus']}
                              placement={'left'}
                              delay={{ show: 100, hide: 100 }}
                              overlay={(props) => (
                                <Tooltip
                                  {...props}
                                  className={`${
                                    props?.className ?? ''
                                  } context-menu-column-type big-menu pe-none`}
                                >
                                  {t('View dataflow')}
                                </Tooltip>
                              )}
                            >
                              <Link to={linkFlow}>
                                <FcMultipleInputs
                                  className="icon-btn context-menu"
                                  size={30}
                                />
                              </Link>
                            </OverlayTrigger>
                          )}
                          <ContextMenuViewData
                            visible={contextVisible}
                            setVisible={setContextVisible}
                            model={model}
                            sampleData={sampleData}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                modelId={model?.id}
              >
                <div
                  className="w-100 justify-content-center align-items-center px-2 py-4 bold position-sticky more-data-message-view-data"
                  style={{ left: '0%' }}
                  ref={footerRef}
                >
                  {t('Loading more rows')}{' '}
                  <FaSpinner className="ms-2 rotating-2" size={30} />
                </div>
              </GridTable>
            ) : (!isImporting && sampleData?.status === 'error') ||
              model?.status === 'error' ? (
              <div className="load-div">
                <StatusMessage
                  className="w-50 mb-5"
                  history={[
                    {
                      message: (
                        <div className="d-flex align-items-center justify-content-center">
                          {`${
                            sampleData?.message ??
                            model?.dataset?.csv?.description ??
                            'Error creating model'
                          }`}
                          {linkFlow && (
                            <OverlayTrigger
                              rootClose={true}
                              trigger={['hover', 'focus']}
                              placement={'left'}
                              delay={{ show: 100, hide: 100 }}
                              overlay={(props) => (
                                <Tooltip
                                  {...props}
                                  className={`${
                                    props?.className ?? ''
                                  } context-menu-column-type big-menu pe-none`}
                                >
                                  {t('View dataflow')}
                                </Tooltip>
                              )}
                            >
                              <Link to={linkFlow} className="ms-1">
                                <FcMultipleInputs
                                  className="icon-btn context-menu"
                                  size={30}
                                />
                              </Link>
                            </OverlayTrigger>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  showLoad={false}
                />
              </div>
            ) : model?.pbar_data ? (
              <Row className="justify-content-center w-100">
                <Col xl={6} sm={12}>
                  <PbarProgress model={model} />
                </Col>
              </Row>
            ) : (
              <div className="load-div">
                <StatusMessage
                  className="w-50 mb-5"
                  history={[{ message: t('Loading table...') }]}
                />
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <Row className="view-data-table-container-edit mx-0">
            <Col className="d-flex justify-content-end px-0" xs={12}>
              <div className="d-flex flex-nowrap justify-content-between w-100">
                <div>{additionHeaders}</div>
                <div className="d-inline-flex align-items-end">
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement={'left'}
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip
                        {...props}
                        className={`${
                          props?.className ?? ''
                        } context-menu-column-type big-menu pe-none`}
                      >
                        {t('View Table')}
                      </Tooltip>
                    )}
                  >
                    <span className="me-2 d-inline-flex align-items-end">
                      <FaTable
                        className="icon-btn context-menu"
                        size={28}
                        onClick={() => setTab(0)}
                      />
                    </span>
                  </OverlayTrigger>
                  <ContextMenuViewData
                    visible={contextVisible}
                    setVisible={setContextVisible}
                    model={model}
                    sampleData={sampleData}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12}>
              {hasEditMode && (
                <ViewDataEdit
                  className="mt-1"
                  model={model}
                  getHead={getHead}
                  columnToType={columnToType}
                />
              )}
            </Col>
          </Row>
        </TabPanel>
      </Tabs>
      {columnToFill && (
        <FillColumns
          model={model}
          column={columnToFill}
          onClose={() => setColumnToFill(false)}
        />
      )}
    </div>
  )
}
