import React, { useMemo } from 'react'
import { useModels } from '../../providers/ModelProvider'
import { ResponsiveLine } from '@nivo/line'
import { colors } from './config'
import { generateMMMColorMap } from '../../util/models'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import { getMMMSaturationCurves } from '../../services/model'
import { useQuery } from 'react-query'
import { zip } from '../../util/other'
import Loading from '../loading/LoadingSmall'

export function SaturationChannel({ model = null, fontSize = 12 }) {
  const models = useModels()
  const { token, signout } = useAuth()
  const activeModel = model ?? models.activeModel
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      return await getMMMSaturationCurves({
        modelId: activeModel.id,
        token,
        signout,
      })
    },
    { staleTime: 60 * 1000 },
  )

  const lineData = useMemo(() => {
    if (!data || isLoading) return []
    const colorMap = generateMMMColorMap(model, colors)
    const res = Object.keys(data).map((k) => {
      const channelData = data?.[k]
      return {
        id: k,
        color: colorMap[k],
        data: zip([channelData.x, channelData.y]).map(([x, y]) => ({ x, y })),
      }
    })
    return res
    // eslint-disable-next-line
  }, [data])

  if (isLoading) return <Loading />

  return (
    <ResponsiveLine
      data={lineData}
      margin={{ top: 10, right: 150, bottom: 60, left: 80 }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      colors={(d) => d.color}
      yFormat=" >-.2f"
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        legend: t('Weekly investment'),
        legendOffset: 46,
        legendPosition: 'middle',
        tickValues: 20,
        tickRotation: -22,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Outcome'),
        legendOffset: -70,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'square',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        },
      ]}
      theme={{
        fontSize: fontSize,
        textColor: 'var(--nextbrain-white-font)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-white-font)',
            },
          },
        },
        legends: {
          text: {
            fontSize: 11,
            fill: 'var(--nextbrain-white-font)',
          },
        },
      }}
    />
  )
}
