import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ResponsiveLine } from '@nivo/line'
import { BasicTooltip } from '@nivo/tooltip'
import { NotificationManager } from 'react-notifications'

import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import { getModelById, predictModel } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import NextbrainSelect from '../model-content/NextbrainSelect'

import { useNav } from '../../providers/NavProvider'
import { adjustLNSQ } from '../../util/other'
import { defaultFormat, round } from '../utils/formating'
import Loading from '../loading/LoadingSmall'
import Slider from '../slider/Slider'
import { getTextWidth } from '../utils/ui'
import CrashFallback from '../crash-fallback/CrashFallback'
import ErrorNB from '../loading/ErrorNB'
import ShareModelContextMenu from './ShareModelContextMenu'

const CustomAxisTick = ({ x, y, value }) => {
  const elipsis = value?.length > 13 + 2
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text
        x={elipsis ? 0 : 22 - value.length}
        y={0}
        dy={0}
        textAnchor="middle"
        fill="var(--graph-grid-color)"
        fontSize={16}
        transform="translate(0 48) rotate(-42)"
      >
        {isNaN(value)
          ? elipsis
            ? value.slice(0, 15) + '...'
            : value
          : defaultFormat({ num: value })}
      </text>
      <line
        x1={0}
        y1={y + 15}
        x2={0}
        y2={y + 5}
        stroke="var(--nextbrain-white-font-50)"
        strokeWidth={3}
      />
    </g>
  )
}

const formatDate = (date) => {
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1 // Months start at 0!
  let dd = date.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  return dd + '/' + mm + '/' + yyyy
}

const CustomAxisTickY = ({ x, y, value }) => {
  const elipsis = value?.length > 18 + 2
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={elipsis ? 0 : 15 - value.length}
        y={2}
        dy={0}
        textAnchor="middle"
        fill="var(--graph-grid-color)"
        fontSize={16}
        transform="translate(-45 0)"
      >
        {isNaN(value)
          ? elipsis
            ? value.slice(0, 20) + '...'
            : value
          : defaultFormat({ num: value })}
      </text>
      <line
        x1={-15}
        y1={0}
        x2={-5}
        y2={0}
        stroke="var(--nextbrain-white-font-50)"
        strokeWidth={3}
      />
    </g>
  )
}

const CustomGrid = ({ innerHeight, innerWidth }) => {
  return (
    <>
      <line
        x1={-10}
        y1={innerHeight + 10}
        x2={innerWidth + 10}
        y2={innerHeight + 10}
        stroke="var(--nextbrain-white-font-50)"
        strokeWidth={3}
      />
      <line
        x1={-10}
        y1={0}
        x2={-10}
        y2={innerHeight + 10}
        stroke="var(--nextbrain-white-font-50)"
        strokeWidth={3}
      />
    </>
  )
}

const numericTypes = ['Integer', 'Double']
function DynamicGraph({
  model,
  currentColumn,
  currentColumnValue,
  predictions,
}) {
  const { t } = useTranslation()

  const currentPointLayer = useMemo(() => {
    return ({ series, innerHeight, innerWidth }) => {
      const xVal = currentColumnValue?.value
      let lIndex = -1
      let rIndex = -1
      series[0].data.forEach(({ data }, index, arr) => {
        if (arr.length <= index + 1) {
          if (lIndex === -1) {
            lIndex = index
            rIndex = index
          }
          return
        }
        const next = arr[index + 1]
        if (next && data.x <= xVal && next.data.x >= xVal) {
          lIndex = index
          rIndex = index + 1
        }
      })

      const categoryMatch =
        model?.dataset?.final_column_status?.[currentColumn] === 'Categorical'
          ? series[0].data.find((d) => d.data.x === currentColumnValue?.value)
          : null
      if ((lIndex !== -1 && rIndex !== -1) || categoryMatch) {
        const l = series[0].data[lIndex]
        const r = series[0].data[rIndex]
        const yPos = categoryMatch
          ? categoryMatch.position.y
          : (r.position.y + l.position.y) / 2
        const xPos = categoryMatch
          ? categoryMatch.position.x
          : (r.position.x + l.position.x) / 2

        return (
          <>
            <circle
              cx={xPos}
              cy={yPos}
              r={4}
              fill="var(--nextbrain-tables-graph-bar-color)"
            />
            <line
              x1="0"
              y1={yPos}
              x2={xPos}
              y2={yPos}
              strokeDasharray="1 3"
              stroke="var(--nextbrain-tables-graph-bar-color)"
            />
            <line
              x1={xPos}
              y1={innerHeight}
              x2={xPos}
              y2={yPos}
              strokeDasharray="1 3"
              stroke="var(--nextbrain-tables-graph-bar-color)"
            />
          </>
        )
      }
    }
    // eslint-disable-next-line
  }, [currentColumnValue, currentColumn, model.target])

  if (!predictions) {
    return (
      <Row>
        <Col>{t('Please select a column')}</Col>
      </Row>
    )
  }
  const targetStats = model.dataset.statistics[model.target]
  const xStates = model.dataset.statistics[currentColumn]
  const isYNumeric = numericTypes.includes(
    model?.dataset?.final_column_status?.[model?.target],
  )
  const isXNumeric = numericTypes.includes(
    model?.dataset?.final_column_status?.[currentColumn],
  )
  const isYCategorical = targetStats.nunique < 25
  const isXCategorical = !isXNumeric && xStates.nunique < 25
  const isXDatetime =
    model?.dataset?.final_column_status?.[currentColumn] === 'Datetime'

  const getCurrentValue = () => {
    if (!predictions) return
    const xVal = currentColumnValue?.value
    const predictionValue = predictions.filter((e) => e.x === xVal)
    if (predictionValue.length > 0) {
      return {
        value: predictionValue[0].y,
        confidence: predictionValue[0].confidence,
      }
    }
    let lIndex = -1
    let rIndex = -1
    predictions.forEach((data, index, arr) => {
      if (arr.length <= index + 1) {
        if (lIndex === -1) {
          lIndex = index
          rIndex = index
        }
        return
      }
      const next = arr[index + 1]
      if (next && data.x <= xVal && next.x >= xVal) {
        lIndex = index
        rIndex = index + 1
      }
    })
    if (lIndex === -1 || rIndex === -1) return
    return {
      value: defaultFormat({
        num: predictions[rIndex].y,
      }),
      confidence: predictions[rIndex]?.confidence,
    }
  }

  const currentValue = getCurrentValue()

  return (
    <Row>
      <Col className="h4 color-white mt-3" xs={8}>
        {t('Outcome prediction of')}{' '}
        <i style={{ color: 'var(--nextbrain-datatypes-categorical)' }}>
          {model.target}{' '}
        </i>
        {t('across')}{' '}
        <i className="active-predict-input-head">{currentColumn}</i>{' '}
        {t('values')}
      </Col>
      <Col xs={4}>
        <div className="tag-dynamic-predict-share h4 text-center pt-3 pb-3 me-xl-5">
          <i style={{ color: 'var(--nextbrain-datatypes-categorical)' }}>
            {model.target}:
          </i>{' '}
          {defaultFormat({ num: currentValue?.value ?? 'Out of range' })}
          {currentValue?.confidence ? (
            <p className="mt-1 mb-0">
              <small className="h6 d-block text-secondary">
                {t('Confidence')}: <strong>{currentValue.confidence}%</strong>
              </small>
            </p>
          ) : (
            <></>
          )}
        </div>
      </Col>
      <Col
        xs={12}
        className="mb-4"
        style={{ minHeight: '500px', maxHeight: '500px' }}
      >
        <ResponsiveLine
          data={[
            {
              id: 'Prediction',
              color: 'var(--nextbrain-main-color)',
              data: predictions,
            },
          ]}
          colors={(d) => d.color}
          margin={{
            top: 50,
            right: 100,
            bottom: 100,
            left: 150,
          }}
          yScale={{
            type: isYNumeric ? 'linear' : 'point',
          }}
          xScale={
            isXDatetime
              ? {
                  type: 'time',
                  format: '%Y-%m-%d',
                  precision: 'day',
                }
              : {
                  type: 'point',
                }
          }
          tooltip={({ point }) => (
            <BasicTooltip
              id={`${currentColumn}: ${defaultFormat({ num: point.data.x })}`}
              value={`${model.target}: ${defaultFormat({ num: point.data.y })}`}
              enableChip
            />
          )}
          lineWidth={3}
          axisBottom={{
            legend: '',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 90,
            renderTick: ({ tickIndex, value, x, y }) => {
              if (
                isXCategorical ||
                isXDatetime ||
                !(tickIndex % 10) ||
                !tickIndex ||
                tickIndex === 99
              ) {
                return (
                  <CustomAxisTick
                    x={x}
                    y={y}
                    value={isXDatetime ? formatDate(value) : value}
                  />
                )
              }
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -120,
            renderTick: ({ tickIndex, value, x, y }) => {
              if (
                isYCategorical ||
                isYNumeric ||
                !(tickIndex % 5) ||
                !tickIndex ||
                tickIndex === 99
              ) {
                return <CustomAxisTickY x={x} y={y} value={value} />
              }
            },
          }}
          enableArea={false}
          areaBaselineValue={0}
          pointLabelYOffset={-12}
          pointSize={0}
          enableGridX={false}
          enableGridY={false}
          useMesh={true}
          theme={{
            fontSize: '15px',
            textColor: '#ADBAC7',
            axis: {
              legend: {
                text: {
                  fontSize: 17,
                },
              },
            },
          }}
          layers={[
            CustomGrid,
            'grid',
            ({ innerHeight, innerWidth }) => {
              const width = getTextWidth(model?.target, '17px sans-serif')
              const widthBot = getTextWidth(currentColumn, '17px sans-serif')
              return (
                <>
                  <text
                    x={0}
                    y={0}
                    transform={`translate(-120,  ${
                      innerHeight / 2 + width / 2
                    }) rotate(-90)`}
                    className="tooltip-dynamic-predict-target"
                  >
                    {t(model?.target)}
                  </text>
                  <text
                    x={0}
                    y={0}
                    transform={`translate(${innerWidth / 2 - widthBot / 2},  ${
                      innerHeight + 95
                    })`}
                    className="tooltip-dynamic-predict-column"
                  >
                    {currentColumn}
                  </text>
                </>
              )
            },
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            currentPointLayer,
            'points',
            'slices',
            'mesh',
            'legends',
          ]}
        />
      </Col>
    </Row>
  )
}

function InputSlider({ name, inputProps = {}, sliderProps = {}, ...props }) {
  const inputRef = useRef()
  return (
    <div className="input-slider dynamic-predict-input-slider" {...props}>
      <Form.Control ref={inputRef} {...inputProps} />
      <Slider
        updateLeft={false}
        updateRight={false}
        onChange={(e) => {
          inputRef.current.value = e
          inputProps?.onChange({
            target: {
              value: `${e}`,
              name,
            },
          })
        }}
        {...sliderProps}
      />
    </div>
  )
}

function InputSliderCategory({ name, selectProps = {}, sliderProps = {} }) {
  const [value, setValue] = useState(selectProps?.defaultValue)

  useEffect(() => {
    selectProps?.onChange(value)
    // eslint-disable-next-line
  }, [value])

  return (
    <>
      <div className="base-input" style={{ maxHeight: '31px' }}>
        <NextbrainSelect
          {...selectProps}
          onChange={(value) => setValue(value)}
          value={value}
        />
      </div>
      <div className="input-slider dynamic-predict-input-slider">
        <Slider
          updateLeft={false}
          updateRight={false}
          onChange={(e) =>
            setValue(selectProps.options[e] ?? selectProps.options[0])
          }
          min={0}
          max={
            selectProps?.options?.length ? selectProps?.options?.length - 1 : 1
          }
          ceiling={
            selectProps?.options?.length ? selectProps?.options?.length : 1
          }
          {...sliderProps}
        />
      </div>
    </>
  )
}

function minMax(model, column, margin = 0.3) {
  if (model.dataset.final_column_status[column] === 'Categorical')
    return {
      min: model.dataset.statistics[column].min,
      max: model.dataset.statistics[column].max,
    }

  const isDate = model.dataset.final_column_status[column] === 'Datetime'
  const min = isDate
    ? new Date(model.dataset.statistics[column].min).getTime()
    : model.dataset.statistics[column].min
  const max = isDate
    ? new Date(model.dataset.statistics[column].max).getTime()
    : model.dataset.statistics[column].max
  const dif = Math.abs(max - min)
  const res = {
    min: Math.floor(min - dif * margin),
    max: Math.ceil(max + dif * margin),
  }

  return res
}

function ModelFields({ model }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  let { signout, token, user, unlimitedPredictions } = useAuth()
  const blocked =
    user && !unlimitedPredictions && user.monthly_limits.predictions < 1

  const getDefaultValue = (column) => {
    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return model.dataset.statistics[column].mode
      case 'Double':
      case 'Float':
        return round(model.dataset.statistics[column].mode, 4)
      case 'Categorical':
        return model.dataset.categorical_to_unique[column]?.[0]
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        return new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
      default:
        return 0
    }
  }

  const validColumns = useMemo(() => {
    const importances = model?.details?.feature_importance?.reduce(
      (dict, feature) => {
        const [col] = adjustLNSQ(feature.feature, feature.importance)
        dict[col] = feature.importance
        return dict
      },
      {},
    )

    return (
      model &&
      model.status === 'trained' &&
      model.dataset &&
      model.dataset.columns_order
        ? model.dataset.columns_order.filter(
            (column) =>
              column in model.columns_active &&
              column in model.dataset.statistics &&
              column !== model.target,
          )
        : []
    )
      .map((k) => ({
        k,
        importance: importances?.[k] ?? 0,
      }))
      .sort((a, b) => b.importance - a.importance)
      .reduce(
        (acc, item) => {
          if (acc.acum < 0.9) {
            acc.acum += item.importance
            acc.elements.push(item.k)
          }
          return acc
        },
        { acum: 0, elements: [] },
      )
      .elements.slice(0, 8)
  }, [model])

  const getDefaultFormState = () => {
    let initialFormState = {}
    for (let column of validColumns)
      initialFormState[column] = {
        value: getDefaultValue(column),
        isDefault: true,
      }
    return initialFormState
  }

  const [formState, setFormState] = useState(getDefaultFormState())
  const [currentFocus, setCurrentFocus] = useState(validColumns[0])

  const generatePredictData = (mainColumn) => {
    const columnType = model.dataset.final_column_status[mainColumn]
    const { min, max } = minMax(model, mainColumn)
    const MIN_VALUE = min
    const MAX_VALUE = max

    const STEPS =
      columnType === 'Categorical'
        ? Math.min(model.dataset.categorical_to_unique[mainColumn].length, 100)
        : 100

    return {
      header: validColumns,
      rows: new Array(STEPS).fill().map((_, i) => {
        return validColumns.map((column) => {
          if (column !== mainColumn) return formState[column].value

          let value = formState[column].value
          switch (columnType) {
            case 'Double':
            case 'Float':
              value = MIN_VALUE + (MAX_VALUE - MIN_VALUE) * (i / STEPS)
              break
            case 'Integer':
              value = Math.round(
                MIN_VALUE + (MAX_VALUE - MIN_VALUE) * (i / STEPS),
              )
              break
            case 'Categorical':
              value = model.dataset.categorical_to_unique[column][i]
              break
            case 'Datetime':
              value = new Date(
                Math.round(MIN_VALUE + (MAX_VALUE - MIN_VALUE) * (i / STEPS)),
              )
              break
            default:
              break
          }
          return value
        })
      }),
    }
  }

  const requestBody =
    !currentFocus || !model?.id ? null : generatePredictData(currentFocus)

  const { data: predictData, isLoading: isPredictDataLoading } = useQuery(
    [
      'predict-data-dynamic',
      model?.id,
      { ...requestBody, [currentFocus]: true },
    ],
    async () => {
      if (!currentFocus || !model?.id) return null
      const request = requestBody
      const prediction = await predictModel(model?.id, request, token, signout)
      if (prediction?.status || !Array.isArray(prediction?.predictions)) {
        NotificationManager.error(
          'Could not generate a valid prediction from the model values',
        )
        return null
      } else {
        const index = request.header.indexOf(currentFocus)
        const data = prediction.predictions.map((p, i) => ({
          y: p[0],
          x: request.rows[i][index],
          confidence: p[1],
        }))
        if (model.dataset.final_column_status[currentFocus] === 'Categorical') {
          return data.sort((a, b) => a.x.localeCompare(b.x))
        }

        return data
      }
    },
    { staleTime: Infinity },
  )

  const renderPredictField = (column) => {
    const handleChange = (event) => {
      setFormState((prevState) => {
        if (event.target.value.length === 0)
          prevState[event.target.name] = {
            value: parseFloat(getDefaultValue(column)),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: parseFloat(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    const handleCategoricalChange = (event) => {
      setFormState((prevState) => {
        prevState[column] = {
          value: event.value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    const handleDateChange = (event) => {
      setFormState((prevState) => {
        if (isNaN(new Date(event.target.value)))
          prevState[event.target.name] = {
            value: getDefaultValue(column),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: new Date(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return (
          <InputSlider
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              placeholder: defaultFormat({
                num: getDefaultValue(column),
                digits: column < 1000 ? 0 : 2,
              }),
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column].value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
          />
        )
      case 'Double':
      case 'Float':
        return (
          <InputSlider
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              placeholder: defaultFormat({
                num: getDefaultValue(column),
                digits: 2,
              }),
              'aria-label': 'Number',
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column].value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
          />
        )
      case 'Categorical': {
        const options = [
          ...(model.dataset.categorical_to_unique?.[column] ?? []),
        ]
          .sort()
          .map((option) => ({
            value: option,
            label: option,
          }))
        return (
          <InputSliderCategory
            selectProps={{
              className: 'basic-single mt-0',
              type: 'thin',
              classNamePrefix: 'select',
              options,
              defaultValue: {
                value: getDefaultValue(column),
                label: getDefaultValue(column),
              },
              onChange: handleCategoricalChange,
              name: column,
              onFocus: () => setCurrentFocus(column),
            }}
            sliderProps={{
              onMouseDown: () => {
                setCurrentFocus(column)
              },
              value: options.findIndex(
                (e) => e.value === formState[column].value,
              ),
            }}
          />
        )
      }
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        now = new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
        return (
          <Form.Control
            name={column}
            type="datetime-local"
            placeholder="Datetime"
            aria-label="Number"
            className="no-arrows nb-input-soft force big-form mt-0"
            onChange={handleDateChange}
            defaultValue={now}
            onFocus={() => setCurrentFocus(column)}
          />
        )
      default:
        return <p className="my-2">WIP: Invalid type for now</p>
    }
  }

  return (
    <>
      <Container className="px-0">
        {title && (
          <Row className="">
            <Col xs={12} className="h3 ps-0">
              {title}
              {user && (
                <ShareModelContextMenu
                  model={model}
                  shareTooltip={t('Share dynamic prediction')}
                  name={t('What if analysis')}
                />
              )}
            </Col>
          </Row>
        )}
        <Row className="px-0">
          <Col className="h4 mb-2 color-white ps-0 " xs={12}>
            {t('Input values')}{' '}
            {model?.dataset?.name ? (
              <span>
                {t('for the {{modelName}} model', {
                  modelName: model.dataset.name,
                })}
              </span>
            ) : (
              <></>
            )}
            {!title && user ? (
              <ShareModelContextMenu
                model={model}
                shareTooltip={t('Share dynamic prediction')}
                name={t('What if analysis')}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col className="predict-form-inputs" xs={12}>
            <Row className="mx-5">
              {validColumns.length ? (
                validColumns.map((column) => (
                  <Col key={column} xl={4} md={6} sm={12}>
                    <Row className="mb-1 mx-2">
                      <Col md={12}>
                        <span
                          className={`predict-input-head ${
                            column === currentFocus
                              ? 'active-predict-input-head'
                              : ''
                          }`}
                        >
                          {column}
                        </span>
                      </Col>
                      <Col className="predict-input" md={12}>
                        {renderPredictField(column)}
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </Col>
          {blocked ? (
            <Col className="predict-form-results" xs={12}>
              <Row justify="center">
                <Col md={12}>
                  <div className="mb-2 col-md-12">
                    <span className="predict-input-head">&nbsp;</span>
                  </div>
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {t(
                          'Not enough rows left to predict this model, upgrade your plan to increase the monthly limit',
                        )}
                      </Tooltip>
                    )}
                  >
                    <div>
                      <Button
                        disabled
                        variant={'warning'}
                        className={`w-100 pointer-events-none original`}
                      >
                        {t('Plan limit reached')}
                      </Button>
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col className="predict-form-results px-0" xs={12}>
              {isPredictDataLoading ? (
                <Loading className="mt-5" />
              ) : (
                <CrashFallback
                  style={{ minHeight: '0px' }}
                  message={<ErrorNB style={{ minWidth: '300px' }} />}
                  t={t}
                  key={currentFocus}
                >
                  <DynamicGraph
                    model={model}
                    currentColumn={currentFocus}
                    currentColumnValue={formState[currentFocus]}
                    predictions={predictData}
                  />
                </CrashFallback>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default function DynamicPredictShare({
  setTitle,
  defaultModel = null,
  hideNav = true,
}) {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(defaultModel)
  const [isPrivatePredict, setIsPrivatePredict] = useState(true)
  const param = useParams()
  const { setShowNav } = useNav()

  useEffect(() => {
    if (hideNav && window.self !== window.top) {
      setShowNav(false)
      return () => setShowNav(true)
    }
    // eslint-disable-next-line
  }, [])

  const { isLoading, data } = useQuery(
    `model-${param.id}`,
    async () => defaultModel ?? (await getModelById(param.id, token, signout)),
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      setTitle(
        searchParams.get('title') || `${t('Predict')} | ${t('NextBrain')}`,
      )
      return
    }
    setTitle(
      searchParams.get('title') ||
        `${t('Predict')} ${model.dataset.name} | ${t('NextBrain')}`,
    )
    setIsPrivatePredict(model.is_private_predict)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading)
    return <LoadingModel shortMsg={t('Loading web app prediction')} />

  if (!model || (isPrivatePredict && !model.user_has_access_to_model))
    return <ModelNotFound />

  return (
    <>
      <Row className={`mb-4 header-app px-0`}>
        <Col md={12} className="d-flex align-items-center px-4">
          {model && model.status === 'trained'
            ? model.dataset.name + ' | '
            : ''}{' '}
          {t('What if analysis')}
        </Col>
      </Row>
      <Container>
        <ModelFields model={model} />
      </Container>
    </>
  )
}
