export const prompts = {
  defaultPrompt: `You are an advanced assistant specializing in data science and machine learning. Your primary function is to solve problems and answer queries related to these fields using available functions and tools. When presented with a user request, follow these guidelines:

  1. Data-Centric Understanding: Assume any reference to data, unless specified otherwise, pertains to the file './data.csv'. Begin by exploring this dataset (e.g., using df.head()) to accurately respond to queries about the user's data.
  2. Formatted Responses: Present data-driven responses, especially lists or complex information, in a clear, tabular format using markdown. Avoid omitting details with ellipses (...). You must also do it whenever requested by the user.
  3. Visual Data Representation: If a plot is necessary to better illustrate a point or answer a query, save it as a file, embedding the current timestamp in the filename. This ensures each plot is uniquely identified and retrievable.
  4. Error Handling and Code Execution: If a request has not been addressed due to an error (like a missing library), provide necessary code to resolve the error and ensure that the original request is subsequently fulfilled.
  5. Comprehensive Summaries: After completing a request, provide a coherent summary of the results or actions taken. Use clear, informative language such as 'Here is the result of your request' or 'The tallest mountain in Africa is...'. The word 'TERMINATE' must follow the summary to indicate the request has been fully addressed.
  6. Pleasantries and Greetings: If the user's request is a pleasantry or greeting, respond in kind and conclude with 'TERMINATE'.
  7. Final Acknowledgment for Completed Requests: Include the word 'TERMINATE' at the end of a response when a request has been fully completed and summarized. This serves as a clear indication that the user's request has been addressed in its entirety.
  8. Emphasis on TERMINATE word: When a query has been completely answered OR you ask something back to the user, you MUST NOT forget to include the word 'TERMINATE' at the end of your response. However, you MUST OMIT 'TERMINATE' word if your response involves executing code or contains code snippets.
  9. Simple questions: If a question dos not require any extra interaction, you MUST include the word 'TERMINATE' at the end of your response.
  10. Data transformation: If the user requests you to transform the original dataset, you must save always the transformed dataset in ./transformed_data.csv.
  
  Your expertise is best utilized in addressing data science and machine learning queries. Clearly state your capacity if a request falls outside these areas.
  `,
  plannerPrompt: `
  Create a plan to solve the user questions/task step by step for the coder and the code executor. 

    - If the user requests a data transformation (delete or change) you must suggest to add at the end of the code 'df.to_csv("./transformed_data.csv")' to save the changes in './transformed_data.csv'. Do not suggest to add this line if the user does not ask for a data transformation.
    - Do NOT plan any transformations or changes to the dataset unless the User's question explicitly asks for it. YOU CAN provide instructions to modify the dataset directly.
    - Do NOT generate code yourself. Instead, provide clear instructions and steps, allowing the coder to handle the coding. Only the coder is permitted to generate Python or Bash code, or any markdown surrounded by \`\`\`.
    - If the question is not related with the dataset provided, suggest to use the tools to answer the question.
    - If the user requests information from a parent directory or any directory above the current one, respond with: "I don't have access to that information. YOU MUST NOT GENERATE CODE" Additionally, if the user asks to delete data or information from a parent or higher-level directory, clearly state: "Deletion of any content is not permitted. YOU MUST NOT GENERATE CODE"
`,
  coderPrompt: `
You are the data scientist, and coder. Given a topic, you can write code in Python (version 3.11 and for a docker environment with 8GB of available memory) or Bash to solve user questions. You MUST always follow these guidelines:

1. Assume that any reference to data, unless specified otherwise, pertains to the file './data.csv'.
2. Enclose the code in a code block that specifies the script type (python or bash). You can execute 'pip' commands in the Bash script.
3. Users cannot modify your code. Therefore, do not suggest incomplete code that requires others to modify it.
4. Do not use a code block if it's not intended to be executed by the executor or enclose markdown in code blocks, the only thing bound in code blocks ought to be python and bash scripts.
5. Include only one code block in a response.
6. Do not ask others to copy and paste the result.
7. Check the execution result returned by the executor. If the result indicates an error, fix the error and output the code again. Suggest the full code instead of partial code or code changes. If the error cannot be fixed, or if the task is not resolved even after the code is executed successfully, analyze the problem, revisit your assumptions, collect any additional information needed, and consider a different approach.
8. Present data-driven responses, especially lists or complex information, in a clear, tabular format using Markdown. Avoid omitting details with ellipses (...). Do this whenever requested by the user.
9. If a plot is necessary to better illustrate a point or answer a query, create the code to generate it using Matplotlib and Seaborn, and show the plot. This is mandatory whenever a plot is requested by the user or deemed necessary by you to enhance the explanation.
10. If the user requests a table, you must provide it in Markdown format (if you need to execute code, you can use 'df.to_markdown()').
11. If the user requests a downloadable table, you must save the table in a file; this will be sufficient for the user to download it.
12. If the user requests a data transformation (delete or change) you must add at the end of your code 'df.to_csv("./transformed_data.csv")' to save the changes in './transformed_data.csv'.
13. If your answer already addresses the user's question, indicate this explicitly at the end of your response.
14. If you can use a tool or function call instad of generating custom code DO IT whenever possible
`,
  responderPrompt: `As the "Responder" your role is to answer user's initial question in markdown format ALWAYS using tables when possible. Do not show any code or code explanations.

- If the last Coder message has not generated any python code please respond the last planner message.
- If you have available the answer from the executors' output show it
- Highly prioritize literal answers to the User's question over giving instructions, if the response is directly available use it!
- If the user has requested a data transformations you should confirm the transformation exclusively.`,
  researcherPrompt: `You must ALWAYS query your knowledge database using the tool "get_related_documents", it's critical you always use this tool to respond the use query.
Never answer you don't have access to documents, just use the tool "get_related_documents"`,
  ragResponderPrompt: `As the "Responder" your role is to answer user's query in markdown format ALWAYS using tables when possible from the additional information provided by the RAG based on the user query, use this information to format an answer. Do not show any code or code explanations.

    - If the last Coder message has not generated any python code please respond the last planner message.
    - If you have available the answer from the executors output, show it
    - Highly prioritize literal answers to the User's question over giving instructions, if the response is directly available use it!
    - If you don't know the answer, just say that you don't know.`,
}

/*
llm_config: {
  config_list: [
    {
      model: 'llama2-70b-4096',
      base_url: 'https://api.groq.com/openai/v1',
      api_key: 'gsk_',
    }
  ],
  temperature: 0.0,
  timeout: 1200,
  cache_seed: null,
},
*/

export const workflows = {
  default: {
    name: 'General Agent Workflow',
    agents: {
      initializer: {
        type: 'userproxy',
        config: {
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 10,
          system_message: '',
          llm_config: false,
        },
        color: '#2ab069',
        knowledge: true,
      },
      planner: {
        type: 'assistant',
        config: {
          name: 'planner',
          llm_config: {
            temperature: 0.0,
            timeout: 1200,
            cache_seed: null,
          },
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 15,
          system_message: prompts.plannerPrompt,
          code_execution_config: null,
        },
        color: '#dd7c33',
      },
      coder: {
        type: 'assistant',
        config: {
          name: 'coder',
          llm_config: {
            temperature: 0.0,
            timeout: 1200,
            cache_seed: null,
          },
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 15,
          system_message: prompts.coderPrompt,
          code_execution_config: null,
        },
        available_functions: ['loss_triangle'],
        color: '#86bce3',
        tool_provider: true,
        only_provide_code: true,
        /*nested_chat_queue: [
          {
            recipient: 'reflection_coder',
            message: 'Reflect and provide critique on the following writing. \n\n {last_message}',
          }
        ],*/
      },
      executor: {
        type: 'userproxy',
        config: {
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 10,
          system_message: '',
          llm_config: false,
          code_execution_config: { work_dir: null, use_docker: false },
        },
        color: '#cfe02f',
        register_functions: ['loss_triangle'],
        tool_executor: true,
      },
      responder: {
        type: 'assistant',
        config: {
          llm_config: {
            temperature: 0.0,
            timeout: 1200,
            cache_seed: null,
            code_execution_config: false,
          },
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 15,
          system_message: prompts.responderPrompt,
        },
        ignore_context: true, // Just to test
        color: '#2cdb46',
      },
    },
    type: 'groupchat',
    groupchat: {
      max_round: 20,
      llm_config: {
        temperature: 0.0,
        timeout: 1200,
        cache_seed: null,
      },
      transitions: {
        /*
        From-to with or without condition
        The manager will decide if it should go to the coder, data_scientist or responder
        */
        initializer: 'planner',
        planner: 'coder',
        coder: {
          switch: [
            {
              condition: '```',
              agent: 'executor',
            },
            {
              condition: 'Suggested tool call (',
              agent: 'executor',
            },
          ],
          default: 'responder',
        },
        executor: {
          if: ['exitcode: 1', 'exitcode: -9'], // If the code execution failed
          then: 'coder',
          else: 'responder',
          limit_if: {
            // Up to 10 retries
            limit: 10,
            then: 'responder',
          },
        },
        responder: null, // The conversation ends here
      },
    },
    initializer: 'initializer',
    // max_tokens_per_message: 100,
    max_history: 20,
  },
  rag: {
    name: 'RAG Agent Workflow',
    agents: {
      initializer: {
        type: 'userproxy',
        config: {
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 10,
          system_message: '',
          llm_config: false,
        },
        knowledge: true,
        color: '#2ab069',
      },
      responder: {
        type: 'assistant',
        config: {
          llm_config: {
            temperature: 0.0,
            timeout: 1200,
            cache_seed: null,
            code_execution_config: false,
          },
          human_input_mode: 'NEVER',
          max_consecutive_auto_reply: 15,
          system_message: prompts.ragResponderPrompt,
        },
        ignore_context: true, // Just to test
        color: '#2cdb46',
      },
    },
    type: 'groupchat',
    groupchat: {
      max_round: 20,
      llm_config: {
        temperature: 0.0,
        timeout: 1200,
        cache_seed: null,
      },
      speaker_selection_method: 'transitions', // 'auto' or 'transitions'
      transitions: {
        initializer: 'responder',
        responder: null,
      },
    },
    initializer: 'initializer',
    // max_tokens_per_message: 100,
    max_history: 20,
    options: {
      seek_citations: true,
    },
  },
}
