import React, { forwardRef, useEffect, useRef } from 'react'
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import useStoreState from '../../hooks/UseStoreState'

export const AssistantCheck = forwardRef(
  (
    {
      onEdit = () => {},
      placement = 'bottom',
      onChange,
      enable = () => true,
      text = 'Use AI assistant',
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const containerRef = useRef()
    const [value, setIsChecked] = useStoreState({
      key: 'assistant-creating-model',
      isChecked: true,
    })
    const isChecked = value?.isChecked

    useEffect(
      () => onChange && onChange(isChecked),
      // eslint-disable-next-line
      [isChecked],
    )

    const enabled = enable()

    if (!enabled) return <></>

    return (
      <Row
        ref={containerRef}
        onClick={(e) => {
          e.stopPropagation()
          if (e.target.type !== 'checkbox') {
            e.currentTarget.querySelector('input[type="checkbox"]').click()
          }
        }}
        {...props}
        className={`assistant-check justify-content-between border-radius-5 
         cursor-pointer-and-children border-main-1  ${props?.className ?? ''}`}
      >
        <Col
          className="position-relative d-flex justify-content-center py-2"
          style={{ minWidth: '190px' }}
          xs={12}
        >
          <Form.Check
            ref={ref}
            onChange={(e) => {
              // if (onChange) onChange(e.target.checked)
              setIsChecked({ isChecked: e.target.checked })
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            label={
              <span className="d-flex align-items-center">
                {t(text)}
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={placement}
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {t(
                        'Use the AI assistant to discover solvable problems, gain further insights into your data and aid you in the creation of the model',
                      )}
                    </Tooltip>
                  )}
                >
                  <div className="ms-1 pb-1">
                    <BsInfoCircle size={20} />
                  </div>
                </OverlayTrigger>
              </span>
            }
            className="d-flex "
            checked={isChecked}
          />
        </Col>
      </Row>
    )
  },
)
