import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { FaCheckCircle, FaDocker, FaPlusCircle } from 'react-icons/fa'
import { readableNumber } from '../utils/formating'

export default function ProductDetails({ product, ...props }) {
  const { t } = useTranslation()

  const currencyToStr = (currency) => {
    switch (currency) {
      case 'usd':
        return 'US$'
      case 'eur':
        return '€'
      case 'gbp':
        return '£'
      default:
        return currency
    }
  }

  let interval = product.recurring_interval ?? 'month'
  if ((product.interval_count ?? 1) > 1) {
    interval = `${t('each')} ${product.interval_count} ${t(interval + 's')}`
  } else {
    interval = t(interval)
  }

  return (
    <Row className="dark-card mx-2 my-3" {...props}>
      <Col className="mx-3 my-4">
        <h3 className="text-center pt-2">{product.name}</h3>
        <p className="text-center" style={{ fontSize: '1.3rem' }}>
          {readableNumber(product.price, false)}{' '}
          {currencyToStr(product.currency)} / {interval}
        </p>
        <br />
        <p className="text-center">{product.description}</p>
        <br />
        {product.details.product_type === 'plan' ? (
          <>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>
                {readableNumber(product.details.predictions ?? 1000, false)}
              </strong>{' '}
              {t('predictions per month')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>
                {t('Up to')} {product.details.user_seats ?? 1}
              </strong>{' '}
              {t('user seats')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>
                {readableNumber(product.details.max_models ?? 15, false)}
              </strong>{' '}
              {t('models per month')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>
                {readableNumber(
                  product.details.max_rows_per_model ?? 1000,
                  false,
                )}{' '}
                {t('Max')} {t('Rows')}
              </strong>{' '}
              {t('per model')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <span>{t('Docs and Video Tutorials')}</span>
            </p>
            <p>
              <FaPlusCircle
                className="text-warning"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>
                {product.details.onboarding
                  ? t(product.details.onboarding)
                  : '30 min'}{' '}
                {t('onboarding')}
              </strong>
            </p>
            <p>
              <FaPlusCircle
                className="text-warning"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              Email + {t('Chat Support')} +{' '}
              <strong>{t('Video Call Support')}</strong>
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Data cleanup system')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Classification Models')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Regression Models')}
            </p>
            {product.details.forecasting_models ? (
              <p>
                <FaCheckCircle
                  className="text-success"
                  style={{ marginTop: -5, marginRight: 10 }}
                />{' '}
                {t('Time Series Models')}
              </p>
            ) : (
              <></>
            )}
            {product.details.clustering_models ? (
              <p>
                <FaCheckCircle
                  className="text-success"
                  style={{ marginTop: -5, marginRight: 10 }}
                />{' '}
                <strong>{t('Clustering Models')}</strong>
              </p>
            ) : (
              <></>
            )}
            {product.details.synthetic_data ? (
              <p>
                <FaCheckCircle
                  className="text-success"
                  style={{ marginTop: -5, marginRight: 10 }}
                />{' '}
                <strong>{t('Synthetic Data')}</strong>
              </p>
            ) : (
              <></>
            )}
            {product.details.anomaly ? (
              <p>
                <FaPlusCircle
                  className="text-warning"
                  style={{ marginTop: -5, marginRight: 10 }}
                />{' '}
                <strong>{t('Anomaly')}</strong>
              </p>
            ) : (
              <></>
            )}
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t(
                'Access to Open Source code to automate training and prediction',
              )}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Zapier Integrations')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Web app')} + {t('Gsheet Addon')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('CSV Files')} + GSheet +{' '}
              <strong>Dataslayer API Query Manager</strong>
              {product.details.sql_db ? (
                <>
                  {' '}
                  + <strong>{t('SQL Database')}</strong> +{' '}
                  <strong>MongoDB</strong> + <strong>PowerBI</strong>
                </>
              ) : (
                <></>
              )}
              {product.details.external_api ? (
                <>
                  {' '}
                  + <strong>{t('External APIs')}</strong>
                </>
              ) : (
                <></>
              )}
            </p>
            <p>
              <FaPlusCircle
                className="text-warning"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              <strong>{t('Advanced Dataflow')}</strong>
            </p>
            <p>
              <FaPlusCircle
                className="text-warning"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Chat explore')}, <strong>{t('Chat transform')}</strong>
            </p>
            <p>
              <FaPlusCircle
                className="text-warning"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Configure your LLM (ChatGPT, Azure, Own implementation)')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Web App deployments')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('API deployments')}
            </p>
            <p>
              <FaCheckCircle
                className="text-success"
                style={{ marginTop: -5, marginRight: 10 }}
              />{' '}
              {t('Jupyter Notebook')}
            </p>
            {product.details.dockerfile ? (
              <p>
                <FaDocker
                  style={{ color: '#2391E6', marginTop: -5, marginRight: 10 }}
                />{' '}
                <strong>{t('Deploy in your own servers with Docker')}</strong>
              </p>
            ) : (
              <></>
            )}
            {product.details.data_scientist ? (
              <p>
                <FaPlusCircle
                  className="text-warning"
                  style={{ marginTop: -5, marginRight: 10 }}
                />{' '}
                <strong>{t('Dedicated data Scientist 5h/month')}</strong>
              </p>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {product.details.product_type === 'plugin' ? (
          <p>
            <FaCheckCircle
              className="text-success"
              style={{ marginTop: -5, marginRight: 10 }}
            />{' '}
            {t(
              t(
                'This product is a {{NextBrain}} plugin and will be asociated to your {{NextBrain}} company plan',
                {
                  NextBrain: t('NextBrain'),
                },
              ),
            )}
          </p>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  )
}
