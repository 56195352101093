import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

import './QuestionnaireForm.css'

export const industryDataProto = {
  industry_name: '',
  industry_type: '',
  industry_phone: '',
  emergency_number: '',
  estimate_users: '',
}
//If you're gonna add fields to industry info add them in the prototype too
//Input attr id must match key in prototype
export function IndustryInfoBody({
  industryData,
  coords,
  setCoords,
  advButton,
  ...props
}) {
  const { t } = useTranslation()
  return (
    <>
      <Container>
        <Row className="mx-xl-5">
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_1').click()}
            >
              <Image src="/iconsRegister/energy.png" />
              <h4>{t('Energy and environment')}</h4>
              <p>{t('Improve efficiency and reduce maintenance cost')}</p>
              <Form.Check
                type="radio"
                key={'industry_1'}
                id="industry_1"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_2').click()}
            >
              <Image src="/iconsRegister/financial-services.png" />
              <h4>{t('Financial services')}</h4>
              <p>{t('Improve customer relationship and risk management')}</p>
              <Form.Check
                type="radio"
                key={'industry_2'}
                id="industry_2"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_3').click()}
            >
              <Image src="/iconsRegister/gaming.png" />
              <h4>{t('Engineering')}</h4>
              <p>{t('Launch and scale your projects across platforms')}</p>
              <Form.Check
                type="radio"
                key={'industry_3'}
                id="industry_3"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_4').click()}
            >
              <Image src="/iconsRegister/government.png" />
              <h4>{t('Govermment')}</h4>
              <p>
                {t(
                  'Implement new sustainable citizen services more efficiently',
                )}
              </p>
              <Form.Check
                type="radio"
                key={'industry_4'}
                id="industry_4"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_5').click()}
            >
              <Image src="/iconsRegister/healthcare.png" />
              <h4>{t('Healthcare')}</h4>
              <p>
                {t(
                  'Boost patient engagement, empower provider collaboration, and improve operations',
                )}
              </p>
              <Form.Check
                type="radio"
                key={'industry_5'}
                id="industry_5"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_6').click()}
            >
              <Image src="/iconsRegister/manufacturing.png" />
              <h4>{t('Manufacturing')}</h4>
              <p>
                {t(
                  'Improve operational efficiencies, reduce costs, and reduce time to introduce new capabilities',
                )}
              </p>
              <Form.Check
                type="radio"
                key={'industry_6'}
                id="industry_6"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_7').click()}
            >
              <Image src="/iconsRegister/media-entertainment.png" />
              <h4>{t('Media & entertaiment')}</h4>
              <p>
                {t(
                  'Controls your audience engagement, improve customer experience',
                )}
              </p>
              <Form.Check
                type="radio"
                key={'industry_7'}
                id="industry_7"
                {...props}
              />
            </div>
          </Col>
          <Col className="mt-2" lg={3} md={6}>
            <div
              className="registercheckbox"
              onClick={() => document.getElementById('industry_8').click()}
            >
              <Image src="/iconsRegister/retail.png" />
              <h4>{t('Marketing and Sales')}</h4>
              <p>
                {t(
                  'Personalize customer experiences and optimize your product availability',
                )}
              </p>
              <Form.Check
                type="radio"
                key={'industry_8'}
                id="industry_8"
                {...props}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Row>
        <Row className="mt-3 ">
          <Col md={12}>
            <Row>
              <Col className="mt-3 mb-4">{advButton()}</Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  )
}
