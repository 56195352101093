import React, { useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Slider as MUISlider } from '@material-ui/core'
import $ from 'jquery'

export default function Slider({
  leftText = '',
  rightText = '',
  min = 0,
  max = 100,
  ceiling = 100,
  value,
  onChange = () => {},
  updateLeft = true,
  updateRight = true,
  percUpdate = true,
  ...props
}) {
  const sliderRef = useRef()

  useEffect(
    () => {
      if (updateLeft)
        $(sliderRef.current)
          .find('.MuiSlider-track')
          .attr('data-trainTooltip', `${value}`)
      if (updateRight)
        $(sliderRef.current)
          .find('.MuiSlider-rail')
          .attr(
            'data-testTooltip',
            `${ceiling - value}${percUpdate ? '%' : ''}`,
          )
    },
    //eslint-disable-next-line
    [value],
  )

  return (
    <Row {...props}>
      <Col className="train-split-slider" xs={12}>
        <MUISlider
          ref={sliderRef}
          onChange={(e, v) => onChange(Math.max(Math.min(v, max), min))}
          value={value}
          min={min}
          max={max}
        />
      </Col>
      <Col className="d-inline-blox text-truncate" xs={6}>
        <strong>{leftText}</strong>
      </Col>
      <Col align={'right'} className="d-inline-blox text-truncate" xs={6}>
        <strong>{rightText}</strong>
      </Col>
    </Row>
  )
}
