import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import packageJson from '../package.json'
import moment from 'moment'
import { useQuery } from 'react-query'
import { config } from './Constants'
import { formatDate } from './util/other'

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true
  } else {
    return false
  }
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [build, setBuild] = useState({})

    useEffect(() => {
      const iv = setInterval(() => setRefresh((r) => !r), 20000)
      return () => clearInterval(iv)
    }, [])

    const { data } = useQuery(
      'version',
      async () => {
        const response = await fetch(config.API_URL, { cache: 'no-store' })
        const data = await response.json()
        return data?.version
      },
      {
        staleTime: Infinity,
      },
    )

    useEffect(() => {
      fetch('/meta.json', { cache: 'no-store' })
        .then((response) => {
          try {
            return response.json()
          } catch (e) {
            return { buildDate: Date.now() }
          }
        })
        .then((meta) => {
          const latestVersionDate = meta.buildDate
          const currentVersionDate = packageJson.buildDate
          setBuild((b) => ({ ...b, date: new Date(latestVersionDate) }))

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
          )
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            refreshCacheAndReload()
          } else {
            setIsLatestBuildDate(true)
          }
        })
        .catch((e) => {})
    }, [refresh])

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name)
          }
        })
      }
      // delete browser cache and hard reload
      window.location.reload(true)
    }

    return (
      <React.Fragment>
        <div className="build-release">
          <Row>
            <Col className="text-center" xs={12}>
              {data}
            </Col>
            <Col className="text-center" xs={12}>
              {build?.date ? formatDate(build?.date) : ''}
            </Col>
          </Row>
        </div>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
