import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Modal,
  Form,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ConfirmButton from '../confirm-button/ConfirmButton'
import SingleAccordion from '../single-accordion/SingleAccordion'
import { NotificationManager } from 'react-notifications'
import { reportIssue } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'

function objectStr(obj) {
  if (typeof obj === 'object') {
    return JSON.stringify(obj)
  }
  return obj
}

function ReportImages({ images }) {
  if (Array.isArray(images) && images.length) {
    return (
      <Col className="mt-3" xs={12}>
        <Row>
          {images.map((image, index) => (
            <Col
              className="cursor-pointer position-relative d-flex align-items-center"
              xs={6}
              md={4}
              lg={2}
              onClick={() => {
                window.open(image, '_blank')
              }}
              key={index}
            >
              <Image
                width={'90%'}
                src={image}
                alt={'error'}
                style={{ objectFit: 'scale-down' }}
              />
            </Col>
          ))}
        </Row>
      </Col>
    )
  }

  return <></>
}

function Report({
  report,
  solveReport,
  deleteReport,
  token,
  signout = () => {},
  t,
}) {
  const [responding, setResponding] = useState(false)
  const [duplicated, setDupllicated] = useState(null)
  const responseRef = useRef()
  return (
    <Col className="admin-modal-panel-user mt-2" xs={12}>
      <Row>
        <Col className="d-inline-block text-truncate" xs={4} lg={2}>
          <Row>
            <Col xs={12}>
              {report?.metadata?.source === 'human' ? (
                <Link
                  to={`/user/?userId=${report?.metadata?.userId}&from=dashboard`}
                >
                  <span
                    style={{
                      color: 'var(--nextbrain-tables-blue-graph-bar-color)',
                    }}
                  >
                    {report?.email}
                  </span>
                </Link>
              ) : (
                <>
                  <span> Auto </span>
                  <div className="position-relative">
                    <Link
                      to={`/user/?userId=${report?.metadata?.userId}&from=dashboard`}
                    >
                      <span
                        className="smallp d-inline-block text-truncate w-100"
                        style={{
                          color: 'var(--nextbrain-tables-blue-graph-bar-color)',
                        }}
                      >
                        ({report?.email})
                      </span>
                    </Link>
                  </div>
                </>
              )}
            </Col>
            <Col className="mt-1" xs={12}>
              {report?.solved ? (
                <Button
                  className="original w-100"
                  variant={'primary'}
                  disabled={true}
                >
                  {t('Solved')}
                </Button>
              ) : (
                <Col className="d-flex align-items-center">
                  <Button
                    className="original w-100"
                    variant="success"
                    onClick={() => {
                      setResponding((r) => !r)
                    }}
                  >
                    {t(responding ? t('Cancel') : t('Solve'))}
                  </Button>
                </Col>
              )}
            </Col>
            <Col className="mt-1" xs={12}>
              {
                <Col className="d-flex align-items-center">
                  <ConfirmButton
                    className="original w-100"
                    variant="danger"
                    title={t('Delete report')}
                    onClick={() => {
                      deleteReport(report)
                    }}
                  >
                    {t('Delete')}
                  </ConfirmButton>
                </Col>
              }
            </Col>
          </Row>
        </Col>
        {responding ? (
          <>
            <Col xs={8} lg={10}>
              <Row>
                <Col xs={12}>
                  <Form.Control
                    className="nb-input-soft"
                    as="textarea"
                    placeholder={t('Respond to user...')}
                    rows={10}
                    ref={responseRef}
                  />
                </Col>
                <Col xs={12}>
                  <Button
                    className="original mt-2"
                    variant="success"
                    onClick={() => {
                      solveReport(report, responseRef.current.value)
                      report.solved = true
                      setResponding(false)
                    }}
                  >
                    {t('Send response')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col
              className="d-flex align-items-center"
              xs={report?.metadata?.modelId ? 7 : 8}
              lg={report?.metadata?.modelId ? 9 : 10}
            >
              <Row className="w-100">
                <Col xs={12} className="admin-report-message p-2 w-100">
                  <div className="small m-2 p-2 w-100">{report.msg}</div>
                </Col>
                {report?.metadata?.error?.message && (
                  <Col xs={12} className="admin-report-message p-2  mt-2 w-100">
                    <div className="small m-2 p-0 w-100">
                      {report.metadata.error.message}
                    </div>
                  </Col>
                )}
                {report?.metadata?.error?.stack && (
                  <Col xs={12} className="admin-report-message p-2  mt-2 w-100">
                    <SingleAccordion
                      defaultState={false}
                      title="stack trace"
                      headerProps={{ className: 'w-100' }}
                      className="w-100 mt-5 advance-options nb-accordion"
                      defaultStyles="p-2"
                    >
                      <div className="small m-2 p-0 w-100">
                        {
                          <Row>
                            {report.metadata.error.stack
                              .split('\n')
                              .map((line, index) => (
                                <Col key={index} xs={12}>
                                  {line}
                                </Col>
                              ))}
                          </Row>
                        }
                      </div>
                    </SingleAccordion>
                  </Col>
                )}
              </Row>
            </Col>
            {report?.metadata?.modelId && (
              <Col className="mt-3" xs={1}>
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={'auto'}
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <span className="">Duplicate model with this id.</span>
                    </Tooltip>
                  )}
                >
                  <Button
                    disabled={duplicated}
                    onClick={() => {
                      setDupllicated('Duplicating...')
                      reportIssue({
                        modelId: report?.metadata?.modelId,
                        msg: 'Duplicate model',
                        token,
                        signout,
                        rawResponse: true,
                      })
                        .then(async (res) => {
                          if (res.status === 200) {
                            const result = await res.json()
                            if (result?.id) setDupllicated('Duplicated')
                            else setDupllicated('Error')
                          } else setDupllicated('Error')
                        })
                        .catch((err) => {
                          setDupllicated(err?.message ?? 'unknown error')
                        })
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <span className="small">
                          {report?.metadata?.modelId}
                        </span>
                      </Col>
                      <Col xs={12}>{duplicated}</Col>
                    </Row>
                  </Button>
                </OverlayTrigger>
              </Col>
            )}
            {report?.metadata?.location && (
              <Col className="mt-3" xs={12}>
                {report?.metadata?.location
                  ? objectStr(report?.metadata?.location)
                  : 'Unknown location'}
              </Col>
            )}
            <ReportImages images={report?.metadata?.files} />
          </>
        )}
      </Row>
    </Col>
  )
}

export default function ReportList({
  reports,
  solveReport,
  deleteReport,
  visible,
  hide,
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [userOnly, setUserOnly] = useState(true)
  const modelIdRef = useRef()
  const nameRef = useRef()
  const passRef = useRef()
  const [result, setResult] = useState(null)

  return (
    <Modal show={visible} onHide={hide} size={'xl'}>
      <Modal.Header>
        <span className="h3">{t('Reports')}</span>
      </Modal.Header>
      <Modal.Body>
        {visible && (
          <>
            <Row style={{ position: 'sticky', top: 0 }}>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <SingleAccordion
                      defaultState={false}
                      title="Duplicate Model"
                      headerProps={{ className: 'duplicate-model-header' }}
                      className="w-100 mt-5 advance-options nb-accordion"
                    >
                      <Row className="mt-2">
                        <Col xs={12}>Model id</Col>
                        <Col className="mt-1" xs={12}>
                          <Form.Control
                            ref={modelIdRef}
                            className="nb-input-soft w-auto"
                            label={'model id'}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xs={12}>
                          <Button
                            onClick={() => {
                              const [id] = [modelIdRef, nameRef, passRef].map(
                                (r) => r.current?.value,
                              )
                              if (!id) {
                                NotificationManager.error(
                                  'Please fill all fields enabled',
                                )
                                return
                              }
                              reportIssue({
                                modelId: id,
                                msg: 'Duplicate model',
                                token,
                                signout,
                                rawResponse: true,
                              })
                                .then(async (res) => {
                                  if (res.status === 200) {
                                    const result = await res.json()
                                    if (result?.id) setResult('Duplicated')
                                    else setResult('Error')
                                  } else setResult('Error')
                                })
                                .catch((err) => {
                                  setResult(err?.message ?? 'unknown error')
                                })
                            }}
                          >
                            Duplicate
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-5">
                        <Col xs={12}>
                          {result && `Result to call: ${result}`}
                        </Col>
                      </Row>
                    </SingleAccordion>
                  </Col>
                </Row>
              </Col>
              <Col
                style={{ backgroundColor: 'var(--nextbrain-background)' }}
                xs={12}
              >
                <Form.Check
                  checked={userOnly}
                  type="checkbox"
                  label={t('User reports only')}
                  onChange={(e) => setUserOnly(e.target.checked)}
                />
              </Col>
              <Col
                style={{ backgroundColor: 'var(--nextbrain-background)' }}
                xs={12}
              >
                <Row>
                  <Col className="d-inline-block text-truncate" xs={2}>
                    <span className="small">{t('source')}</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={9}>
                    <span className="small">{t('message')}</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={1}>
                    <span className="small">{t('model id')}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ maxHeight: '50vh', overflow: 'auto' }}>
              {Array.isArray(reports) &&
                reports
                  .filter((r) => !userOnly || r?.metadata?.source === 'human')
                  .map((report) => (
                    <Report
                      key={report.id}
                      t={t}
                      report={report}
                      solveReport={solveReport}
                      deleteReport={deleteReport}
                    />
                  ))}
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
