export default function LLMPlugin() {
  return (
    <>
      The LLM plugin is an efficient and confidential tool created to improve
      your workflow through personalized suggestions. Using advanced machine
      learning techniques, it examines your data and generates customized
      recommendations to suit your individual requirements.
      <br />
      <br />
      For privacy concerns we use LLaMA and HuggingFace models.
    </>
  )
}
