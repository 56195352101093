import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdWarning } from 'react-icons/md'

export default function WarningMMM({ model, timeColumn, ...props }) {
  const { t } = useTranslation()
  const stats = model?.dataset?.statistics?.[timeColumn]
  let smallPeriod = false
  if (stats) {
    if (stats?.logical_type === 'Integer' && stats?.max - stats?.min < 52)
      smallPeriod = true
    else if (stats?.logical_type === 'Date') {
      const begin = new Date(stats?.min)
      const end = new Date(stats?.max)
      const diffTime = Math.abs(end - begin)
      smallPeriod = diffTime < 1000 * 60 * 60 * 24 * 365
    }
  }

  //If under a year
  if (smallPeriod)
    return (
      <Col className="visible-mmm" xs={12} md={6} lg={4} xl={3}>
        <Row {...props}>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center pe-0 "
            style={{ maxWidth: '50px' }}
          >
            <MdWarning
              color="var(--nextbrain-warning-color)"
              size={25}
              className="me-1"
            />
          </Col>
          <Col
            xs={12}
            className="ps-0 text-center"
            style={{ maxWidth: 'calc(100% - 50px)' }}
          >
            <strong className="small text-center">
              {t(
                'The dataset covers a time span of less than a year, planning features might be unavailable.',
              )}
            </strong>
          </Col>
        </Row>
      </Col>
    )

  return <></>
}
