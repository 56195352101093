import React, { useState } from 'react'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form,
  Modal,
  Button,
} from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import CodeMirror from '@uiw/react-codemirror'
import { solarizedDark } from '@uiw/codemirror-theme-solarized'
import { xml } from '@codemirror/lang-xml'

import NextbrainSelect from '../model-content/NextbrainSelect'
import { useAuth } from '../../providers/AuthProvider'
import { editPrivacy } from '../../services/model'

const placeholder = `<iframe 
  $SIZE_PLACEHOLDER 
  src="$URL_PLACEHOLDER">
</iframe>`

function ConfigureShare({ ...props }) {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [style, setStyle] = useState(null)
  const [size, setSize] = useState(null)

  const params = []
  if (title) params.push(`title=${encodeURIComponent(title)}`)

  const shareURL =
    window.location.origin +
    window.location.pathname +
    (params.length > 0 ? `?${params.join('&')}` : '')

  let embeed = placeholder
  let embeedURL = window.location.origin + window.location.pathname
  const embeedParams = []
  if (title) embeedParams.push(`title=${encodeURIComponent(title)}`)
  if (style) embeedParams.push(`theme=${style.value}`)
  embeedURL =
    embeedURL + (embeedParams.length > 0 ? `?${embeedParams.join('&')}` : '')
  embeed = embeed.replace('$URL_PLACEHOLDER', embeedURL)
  if (size)
    embeed = embeed.replace(
      '$SIZE_PLACEHOLDER',
      `width="${size.value}" height="500px"`,
    )
  else
    embeed = embeed.replace('$SIZE_PLACEHOLDER', `width="500px" height="500px"`)

  return (
    <Row
      {...props}
      className={`${props?.className ?? ''} justify-content-center`}
    >
      <Col className="mt-4" xs={12}>
        <Row className="justify-content-start">
          <Col xs={11}>
            <Form.Control
              className="nb-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('Title')}
            />
          </Col>
          <Col className="mt-3" xs={11}>
            <NextbrainSelect
              value={style}
              onChange={(v) => setStyle(v)}
              options={[
                { value: 'light', label: 'Light' },
                { value: 'dark', label: 'Dark' },
              ]}
              placeholder={t('Style')}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single`}
              classNamePrefix="select"
              isClearable={true}
              isSearchable={false}
              creatable={false}
            />
          </Col>
          <Col className="mt-3" xs={11}>
            <NextbrainSelect
              value={size}
              onChange={(v) => setSize(v)}
              options={[
                { value: '500px', label: 'Small' },
                { value: '700px', label: 'Medium' },
                { value: '100%', label: 'Full' },
              ]}
              placeholder={t('Embed size')}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single`}
              classNamePrefix="select"
              isClearable={true}
              isSearchable={false}
              creatable={false}
            />
          </Col>
          <Col className="mt-5 mb-1" xs={12}>
            <u>{t('Share link')}</u>
          </Col>
          <Col className="pe-0" xs={10}>
            <Form.Control
              className="nb-input"
              value={shareURL}
              as="textarea"
              disabled
            />
          </Col>
          <Col xs={1}>
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'top'}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  <span>{t('Copy to clipboard')}</span>
                </Tooltip>
              )}
            >
              <Button
                onClick={() => {
                  if (navigator?.clipboard?.writeText) {
                    navigator.clipboard.writeText(shareURL)
                    NotificationManager.info(t('Copied to clipboard'))
                  } else NotificationManager.error('Clipboard not supported')
                }}
                className="btn btn-primary"
              >
                <FaExternalLinkAlt />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col className="mt-2 mb-1" xs={12}>
            <u>{t('Embed for webpages')}</u>
          </Col>
          <Col className="pe-0" xs={10}>
            <Row
              className="me-1 py-1"
              style={{
                border: '1px solid #ced4da',
                borderRadius: '5px',
                marginLeft: '0px',
              }}
            >
              <Col xs={12}>
                <CodeMirror
                  value={embeed}
                  readOnly
                  extensions={[xml({})].filter((e) => e)}
                  width="100%"
                  editable={false}
                  onChange={() => {}}
                  theme={solarizedDark}
                  indentWithTab
                  options={{ lineWrapping: false }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={1}>
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'top'}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  <span>{t('Copy to clipboard')}</span>
                </Tooltip>
              )}
            >
              <Button
                onClick={() => {
                  if (navigator?.clipboard?.writeText) {
                    navigator.clipboard.writeText(embeed)
                    NotificationManager.info(t('Copied to clipboard'))
                  } else NotificationManager.error('Clipboard not supported')
                }}
                className="btn btn-primary"
              >
                <FaExternalLinkAlt />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function ShareModelContextMenu({
  shareTooltip = 'Share web app prediction',
  name = 'Predict',
  model,
}) {
  const [show, setShow] = useState({})
  const [isPrivatePredict, setIsPrivatePredict] = useState(
    model?.is_private_predict ?? true,
  )
  const { user, token, signout } = useAuth()
  const { t } = useTranslation()
  if (!user) return null

  return (
    <OverlayTrigger
      {...show}
      rootClose={true}
      trigger={'click'}
      placement={'left'}
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip
          {...props}
          className={`${
            props?.className ?? ''
          } context-menu-column-type big-menu`}
        >
          <Row className="my-2 px-4" onClick={(e) => e.stopPropagation()}>
            <Col
              className="linkshare link white-link d-flex flex-nowrap align-items-center"
              xs={12}
              title={t(shareTooltip)}
              onClick={(e) => {
                e.currentTarget?.querySelector('input')?.click()
              }}
            >
              <span
                className="d-inline-block text-truncate color-default linkshare link white-link"
                style={{ float: 'left' }}
              >
                {t(shareTooltip)}
              </span>
              <Form.Check
                type="switch"
                label={''}
                className="form-switch-share-menu d-inline-block ps-2 pe-none"
                defaultChecked={!isPrivatePredict}
                onChange={async (e) => {
                  const newIsPrivatePredict = !e.target.checked
                  if (!newIsPrivatePredict) setShow({ show: true })
                  else setShow({})

                  setIsPrivatePredict(newIsPrivatePredict)
                  const options = { private_predict: newIsPrivatePredict }
                  if (!newIsPrivatePredict) {
                    if (navigator?.clipboard?.writeText) {
                      options.private_report = false
                      navigator.clipboard.writeText(window.location.href)
                    } else NotificationManager.error('Clipboard not supported')
                  }
                  NotificationManager.info(
                    `${name} ` +
                      (newIsPrivatePredict
                        ? t('is now private')
                        : t('is now public')),
                  )
                  model.is_private_predict = options.private_report
                  await editPrivacy(model.id, options, token, signout)
                }}
              />
            </Col>
          </Row>
        </Tooltip>
      )}
    >
      <span onClick={(e) => e.stopPropagation()} style={{ float: 'right' }}>
        <BsThreeDotsVertical className="icon-btn context-menu" size={30} />
        {show?.show && (
          <Modal show={true} onHide={() => setShow({})}>
            <Modal.Header className="h5 mb-0" closeButton>
              <Row className="justify-content-between w-100 ">
                <Col xs={12} className="d-inline-box text-truncate">
                  {`${t('Share')} ${t(name)}`}
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <ConfigureShare />
            </Modal.Body>
            <Modal.Footer className="pt-0">
              <Row className="w-100">
                <Col className="ps-0" xs={12}>
                  <Button
                    variant="secondary"
                    className="original "
                    onClick={() => setShow({})}
                  >
                    <div className="h5 px-2 mb-0">{t('Close')}</div>
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        )}
      </span>
    </OverlayTrigger>
  )
}
