export default function Html({
  width = 50,
  height = 50,
  className = '',
  ...props
}) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 303.188 303.188"
      className={className}
      height={height}
      width={width}
      {...props}
    >
      <g>
        <polygon
          style={{ fill: '#E4E4E4' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <polygon
          style={{ fill: '#007934' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <g>
          <path
            style={{ fill: '#007934' }}
            d="M126.116,154.679L77.19,132.95v-8.323l48.925-24.487v14.373l-30.004,13.694l30.004,12.195
			L126.116,154.679L126.116,154.679z"
          />
          <path
            style={{ fill: '#007934' }}
            d="M173.395,91.526l-26.374,72.734h-16.744l26.374-72.734
			C156.651,91.526,173.395,91.526,173.395,91.526z"
          />
          <path
            style={{ fill: '#007934' }}
            d="M177.072,140.402l30.004-12.195l-30.004-13.694V100.14l48.925,24.487v8.323l-48.925,21.729V140.402
			z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M105.013,273.871H93.382V256.41H79.935v17.461H68.304v-42.832h11.631v15.879h13.447v-15.879h11.631
			C105.013,231.039,105.013,273.871,105.013,273.871z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M133.167,273.871h-11.572v-33.369h-10.459v-9.463h32.461v9.463h-10.43L133.167,273.871
			L133.167,273.871z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M168.382,273.871l-8.76-30.85h-0.264c0.41,5.254,0.615,9.336,0.615,12.246v18.604H149.72v-42.832
			h15.41l8.936,30.41h0.234l8.76-30.41h15.439v42.832h-10.635v-18.779c0-0.977,0.015-2.061,0.044-3.252s0.161-4.111,0.395-8.76
			h-0.264l-8.643,30.791L168.382,273.871L168.382,273.871z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M207.728,273.871v-42.832H219.3v33.486h16.494v9.346H207.728z"
          />
        </g>
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  )
}
