import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Alert, Form, Button, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import {
  getAllUsersPaginated,
  getAllUsersLength,
  getAllUsers,
  confirmUserToCompany,
} from '../../services/user'
import { useAuth } from '../../providers/AuthProvider'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import './UserManager.css'
import { useQuery, useQueryClient } from 'react-query'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { FcApproval } from 'react-icons/fc'
import {
  MdBatchPrediction,
  MdTransform,
  MdError,
  MdOutlineChatBubble,
} from 'react-icons/md'
import { SiTrainerroad } from 'react-icons/si'
import { FaWpexplorer } from 'react-icons/fa'
import CompanyManager from './CompanyManager'
import { round } from '../utils/formating'
import { NotificationManager } from 'react-notifications'
import Loading from '../loading/LoadingSmall'

const options = [
  { value: 'retail', label: 'Marketing and sales' },
  { value: 'energy', label: 'Energy and environment' },
  { value: 'financial', label: 'Financial services' },
  { value: 'engineering', label: 'Engineering' },
  { value: 'government', label: 'Government and public interest' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'media', label: 'Media and entertainment' },
]

function createFilterFn({
  zerotrain,
  zeropredict,
  zeroexplore,
  zerotransform,
  zerofailures,
  industries,
}) {
  const compose = (base, next) => (user) => base(user) && next(user)
  industries = (industries ?? []).map((v) => v.value)

  let base = () => true
  if (zerotrain) base = compose(base, (user) => (user?.trains ?? 0) > '0')

  if (zeropredict) base = compose(base, (user) => (user?.predicts ?? 0) > '0')

  if (zeroexplore)
    base = compose(base, (user) => (user?.langchains_explore ?? 0) > '0')

  if (zerotransform)
    base = compose(base, (user) => (user?.langchains_transform ?? 0) > '0')

  if (zerofailures) base = compose(base, (user) => (user?.failures ?? 0) > '0')

  if (Array.isArray(industries) && industries.length)
    base = compose(base, (user) =>
      user.user_industries.some((r) => industries.includes(r)),
    )

  return base
}

function Company({ user, company, confirmed, onConfirm, ...props }) {
  const { token, signout } = useAuth()
  const plans = {}
  company?.subscription?.forEach((sub) => {
    if (sub?.product_type === 'plan') {
      const config = sub.config
      if (config?.name && config?.name !== 'Free')
        plans[config.name] = sub.payment_status
    }
  })
  if (!Object.keys(plans).length) plans['Free'] = ''
  if (!confirmed) props.onClick = () => {}
  return (
    <Row
      {...props}
      className={`company-user-manager ${
        confirmed ? '' : 'unconfirmed-company-user-manager'
      } py-1 mx-2 ${props.className ?? ''}`}
    >
      <Col
        className="d-flex align-items-center breakable-text justify-content-center"
        xs={confirmed ? 7 : 12}
      >
        {company.name || '<Empty>'}
      </Col>
      {confirmed ? (
        <Col xs={4}>
          {Object.entries(plans).map(([plan, status], i) => (
            <Row
              className={`mb-1 subscription-status-user-manager pb-1 status-sub-${status}`}
              key={i}
            >
              <Col className="px-0 me-1" xs={12}>
                <span className="small">{plan}</span>
              </Col>
              {status && (
                <Col className="px-0" xs={12}>
                  <span className="small">{status}</span>
                </Col>
              )}
            </Row>
          ))}
        </Col>
      ) : (
        <>
          <Col className="my-2 text-center" xs={12}>
            <strong style={{ color: '#c20b8b' }}>Unconfirmed</strong>
          </Col>
          <Col className="dflex-center" xs={12}>
            <Button
              className="smallp"
              onClick={(e) => {
                const tgt = e.target
                tgt.disabled = true
                confirmUserToCompany({
                  userId: user.id,
                  companyId: company.id,
                  token,
                  signout,
                }).then((r) => {
                  if (r?.status === 200) {
                    onConfirm()
                    NotificationManager.info('User confirmed')
                  } else {
                    NotificationManager.error('Failed to confirm invitation')
                  }
                })
              }}
            >
              Confirm
            </Button>
          </Col>
        </>
      )}
    </Row>
  )
}

function UserIndustries({ user }) {
  const industries = user.user_industries

  return (
    <Row className="my-1">
      <Col xs={12}>
        <Row className="admin-user-manager-user-industries mx-0">
          <Col
            className={industries.includes('retail') ? 'active' : 'inactive'}
            title={'Marketing and sales'}
            xs={3}
          >
            <span>MA</span>
          </Col>
          <Col
            className={industries.includes('energy') ? 'active' : 'inactive'}
            title={'Energy and environment'}
            xs={3}
          >
            <span>EE</span>
          </Col>
          <Col
            className={industries.includes('financial') ? 'active' : 'inactive'}
            title={'Financial services'}
            xs={3}
          >
            <span>FS</span>
          </Col>
          <Col
            className={
              industries.includes('engineering') ? 'active' : 'inactive'
            }
            title={'Engineering'}
            xs={3}
          >
            <span>EN</span>
          </Col>
        </Row>
      </Col>
      <Col className="mt-1" xs={12}>
        <Row className="admin-user-manager-user-industries mx-0">
          <Col
            className={
              industries.includes('government') ? 'active' : 'inactive'
            }
            title={'Government and public interest'}
            xs={3}
          >
            <span>GO</span>
          </Col>
          <Col
            className={
              industries.includes('healthcare') ? 'active' : 'inactive'
            }
            title={'Healthcare'}
            xs={3}
          >
            <span>HE</span>
          </Col>
          <Col
            className={
              industries.includes('manufacturing') ? 'active' : 'inactive'
            }
            title={'Manufacturing'}
            xs={3}
          >
            <span>MN</span>
          </Col>
          <Col
            className={industries.includes('media') ? 'active' : 'inactive'}
            title={'Media and entertainment'}
            xs={3}
          >
            <span>ME</span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function UserManager({ usersPerPage = 25, ...props }) {
  const { t } = useTranslation()
  const [users, setUsers] = useState([])
  const [usersLength, setUsersLength] = useState()
  const [allData, setAllData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const updateSearch = useDebouncedCallback((v) => {
    setSearchTerm(v)
  }, 500)
  const queryClient = useQueryClient()
  const [error, setError] = useState(false)
  const [clean, setClean] = useState(false)
  const [ready, setReady] = useState(false)
  const { token } = useAuth()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(usersPerPage)
  const [columnOrder, setColumnOrder] = useState('created')
  const [descendent, setDescendent] = useState(true)
  const [filters, setFilters] = useState({
    zerotrain: false,
    zeropredict: false,
    zeroexplore: false,
    zerotransform: false,
    zerofailures: false,
    industries: [],
    zeroactivity: false,
    zerochats: false,
  })
  const [filterFn, setFilterFn] = useState(() => createFilterFn(filters))
  useEffect(() => {
    setFilterFn(() => createFilterFn(filters))
    setPageNum(1)
  }, [filters])

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  yesterday.setHours(0, 0, 0, 0)
  const customStyles = {
    table: {
      style: {
        padding: '0px', // override the cell padding for head cells
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        width: 'auto',
      },
    },
    noData: {
      style: {
        color: 'var(--nextbrain-white-font)',
        border: 'solid var(--nextbrain-secondary-border-color) 1px',
      },
    },
    pagination: {
      style: {
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        fill: 'white',
      },
    },
    rows: {
      style: {
        minHeight: '30px', // override the row height
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        borderBottom:
          'solid var(--nextbrain-secondary-border-color) 1px !important',
        borderLeft: 'solid var(--nextbrain-secondary-border-color) 1px',
        borderRight: 'solid var(--nextbrain-secondary-border-color) 1px',
      },
      stripedStyle: {
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        borderBottom:
          'solid var(--nextbrain-secondary-border-color) 1px !important',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        width: 'max-content',
        //fontWeight: '600',
        fontSize: '15px',
        //border: 'solid var(--nextbrain-secondary-border-color) 1px',
        color: 'var(--nextbrain-white-font)',
        backgroundColor: 'var(--nextbrain-secondary-color)',
      },
    },
    headRow: {
      style: {
        /*borderBottom: 'none',*/
        backgroundColor: 'var(--nextbrain-secondary-color)',
        border: 'solid var(--nextbrain-secondary-border-color) 1px !important',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        fontSize: '14px',
        fontWeight: '500',
        //border: 'solid var(--nextbrain-secondary-border-color) 1px',
        marginBottom: '0',
        width: 'max-content',
      },
    },
  }

  const [selectedCompany, setSelectedCompany] = useState(null)
  const columns = [
    {
      name: t('Email'),
      sortName: 'Email',
      selector: (row) => {
        const userData = users.find((user) => user.email === row.email)
        return (
          <Link
            className="d-inline-flex text-truncate align-items-center w-100"
            to={`/user/?userId=${userData?.id}&from=users`}
          >
            {row.verified ? <FcApproval size={15} /> : <></>}
            <div
              className="user-link breakable-text"
              style={{ color: 'var(--nextbrain-tables-blue-graph-bar-color)' }}
            >
              {row.email}
            </div>
          </Link>
        )
      },
      sortable: true,
      width: '240px',
    },
    {
      name: t('Name'),
      sortName: 'Name',
      selector: (row) => (
        <div
          title={row.name}
          className="w-100 overflow-visible d-block breakable-text"
        >
          {row.name}
        </div>
      ),
      sortable: true,
      width: '150px',
    },
    {
      name: t('Companies'),
      selector: (row) => (
        <Row className="px-0" style={{ minWidth: '255px' }}>
          {row?.company_users?.map(({ company, confirmed }, i) => (
            <Col key={company.id} className="my-3" xs={12}>
              <Company
                onClick={() => setSelectedCompany(company.id)}
                user={row}
                company={company}
                confirmed={confirmed}
                onConfirm={() => {
                  row.company_users[i] = {
                    company,
                    confirmed: true,
                  }
                  setUsers([...users])
                }}
              />
            </Col>
          ))}
        </Row>
      ),
      sortable: false,
      width: '255px',
    },
    {
      name: t('Country'),
      sortName: 'Country',
      selector: (row) => (
        <span title={row.country}>{row.country ? row.country : ''}</span>
      ),
      sortable: true,
      width: '95px',
    },
    {
      name: t('Introduction'),
      sortName: 'Introduction',
      selector: (row) => (row.introduction ? row.introduction : ''),
      sortable: true,
      width: '130px',
    },
    {
      id: 'order',
      name: t('Created'),
      sortName: 'Created',
      selector: (row) => {
        const userDate = new Date(row.created)
        const onlyDay = userDate.setHours(0, 0, 0, 0)
        return onlyDay === today.getTime()
          ? t('Today ') +
              row.created.replace('Z', ' ').replace('T', ' ').substring(10, 16)
          : onlyDay === yesterday.getTime()
          ? t('Yesterday ') +
            row.created.replace('Z', ' ').replace('T', ' ').substring(10, 16)
          : row.created.replace('Z', ' ').replace('T', ' ').substring(0, 16)
      },
      sortable: true,
      width: '160px',
    },
    {
      name: t('industries'),
      sortName: 'industries',
      selector: (row) => <UserIndustries user={row} />,
      sortable: false,
      width: '140px',
    },
    {
      name: t('Last action'),
      sortName: 'Last action',
      selector: (row) => {
        const userDate = new Date(row.lastAction)
        const onlyDay = userDate.setHours(0, 0, 0, 0)
        return row.lastAction
          ? onlyDay === today.getTime()
            ? t('Today') +
              row.lastAction
                .replace('Z', ' ')
                .replace('T', ' ')
                .substring(10, 16)
            : onlyDay === yesterday.getTime()
            ? t('Yesterday') +
              row.lastAction
                .replace('Z', ' ')
                .replace('T', ' ')
                .substring(10, 16)
            : row.lastAction
                .replace('Z', ' ')
                .replace('T', ' ')
                .substring(0, 16)
          : ''
      },
      sortable: true,
      width: '150px',
    },
    {
      name: t('Stats'),
      sortName: 'Stats',
      selector: (row) => (
        <Row>
          <Col
            className="py-1 d-flex align-items-center"
            title={'Train'}
            xs={6}
          >
            <SiTrainerroad className="me-1" />
            {row.trains ? row.trains : '0'}
          </Col>
          <Col
            title="Predicts"
            className="py-1 d-flex align-items-center"
            xs={6}
          >
            <MdBatchPrediction className="me-1" />
            {row.predicts ? row.predicts : '0'}
          </Col>
          <Col
            title="langchain explore"
            className="py-1 d-flex align-items-center"
            xs={6}
          >
            <FaWpexplorer className="me-1" />
            {row.langchains_explore ? row.langchains_explore : '0'}
          </Col>
          <Col
            title="langchain transforms"
            className="py-1 d-flex align-items-center"
            xs={6}
          >
            <MdTransform className="me-1" />
            {row.langchains_transform ? row.langchains_transform : '0'}
          </Col>
          <Col title="errors" className="py-1 d-flex align-items-center" xs={6}>
            <MdError className="me-1" color="#dd2266" />
            {row.failures ? (
              <div className="red-failure">{row.failures}</div>
            ) : (
              '0'
            )}
          </Col>
          <Col title="chats" className="py-1 d-flex align-items-center" xs={6}>
            <MdOutlineChatBubble className="me-1" />
            {row.chats ? <div>{row.chats}</div> : '0'}
          </Col>
        </Row>
      ),
      sortable: false,
      width: '200px',
    },
    {
      name: t('Hours'),
      sortName: 'minutes',
      selector: (row) => round((row.minutes ? row.minutes : 0) / 60, 2),
      width: '80px',
      sortable: true,
    },
    {
      name: t('Sessions'),
      sortName: 'sessions',
      selector: (row) => row?.sessions ?? 0,
      width: '100px',
      sortable: true,
    },
  ]

  const allUsersLength = useQuery(
    ['admin-all-users-length'],
    () => {
      return getAllUsersLength(token)
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (!allUsersLength.isLoading) {
      setUsersLength(allUsersLength?.data ?? [])
    }
  }, [allUsersLength])

  const allUsersPaginated = useQuery(
    [
      'admin-all-users-paginated',
      pageSize,
      pageNum,
      columnOrder,
      descendent,
      filters,
      searchTerm,
    ],
    () => {
      return getAllUsersPaginated(
        token,
        pageSize,
        pageNum,
        columnOrder,
        descendent,
        searchTerm || null,
        {
          ...filters,
          industries: (filters?.industries ?? []).map((v) => v.value),
        },
      )
    },
    { staleTime: Infinity },
  )

  const companies = useMemo(() => {
    if (Array.isArray(users))
      return users.reduce((acc, user) => {
        user?.company_users?.forEach(
          ({ company }) => (acc[company.id] = company),
        )
        return acc
      }, {})

    return {}
  }, [users])

  useEffect(() => {
    if (!allUsersPaginated.isLoading) {
      setUsers(
        Array.isArray(allUsersPaginated?.data) ? allUsersPaginated?.data : [],
      )
    }
    // eslint-disable-next-line
  }, [allUsersPaginated])

  useEffect(() => {
    if (searchTerm === '') {
      handleClean()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    if (ready && allData && allData.length > 0) {
      const fieldNames = Object.keys(allData[0])
      const rows = allData
        .filter(filterFn)
        .map((user) =>
          fieldNames.map((fieldName) =>
            Array.isArray(user[fieldName])
              ? user[fieldName].join(' ')
              : user[fieldName],
          ),
        )
      const csvContent =
        'data:text/csv;charset=utf-8,' +
        [fieldNames.join(',')].concat(rows.map((e) => e.join(','))).join('\n')
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'users.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      setReady(false)
    }
    // eslint-disable-next-line
  }, [ready, allData])

  useEffect(() => {
    setPageNum(1)
  }, [searchTerm])

  const handleClean = () => {
    setClean(!clean)
  }
  const handleDownload = () => {
    getAllUsers(token)
      .then((response) => {
        setAllData(response)
      })
      .catch((error) => {
        console.error(error)
        setError('Not able to load users')
      })
    setReady(true)
  }

  const changeOrderColumn = (e) => {
    if (columnOrder !== e.sortName.toLowerCase()) {
      setColumnOrder(e.sortName.toLowerCase().replace(' ', ''))
      setDescendent(!descendent)
    } else {
      setColumnOrder(e.sortName.toLowerCase().replace(' ', ''))
      setDescendent(!descendent)
    }
  }

  return (
    <>
      <Row className="px-3 px-md-5 admin-panel" {...props}>
        <Alert show={error}>{error ?? ''}</Alert>
        <div className="title">
          <div className="h2">
            {t('Users')} ({usersLength} total)
          </div>{' '}
          <span className="line" />
        </div>
        <div className="panel">
          <div className="table-functions ">
            <Col lg={3} xs={12} className="px-0 pe-lg-0 pe-3 float-left ">
              <Form.Control
                style={{
                  borderRadius: 8,
                }}
                className="with-icon nb-input"
                placeholder={t('Search user...')}
                onChange={(e) => {
                  updateSearch(e.currentTarget.value)
                }}
              />
              <i
                className="fas fa-search icon users-icon-search"
                style={{ marginLeft: -15 }}
              ></i>
            </Col>
            <Col>
              <Button className="csv-button" onClick={handleDownload}>
                {t('Download as CSV')}
              </Button>
            </Col>
          </div>
          <Col xs={12}>
            <Row className="justify-content-between">
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-train-cb"
                      className="mb-2"
                      checked={filters.zerotrain}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zerotrain: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-train-cb">
                      {t('Non zero train')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-predict-cb"
                      className="mb-2"
                      checked={filters.zeropredict}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zeropredict: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-predict-cb">
                      {t('Non zero predict')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-explore-cb"
                      className="mb-2"
                      checked={filters.zeroexplore}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zeroexplore: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-explore-cb">
                      {t('Non zero explore')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-transform-cb"
                      className="mb-2"
                      checked={filters.zerotransform}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zerotransform: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-transform-cb">
                      {t('Non zero transform')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-failures-cb"
                      className="mb-2"
                      checked={filters.zerofailures}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zerofailures: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-failures-cb">
                      {t('Non zero failures')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-failures-cb"
                      className="mb-2"
                      checked={filters.zerochats}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zerochats: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-chats-cb">
                      {t('Non zero chats')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={'auto'}>
                <Row>
                  <Col className="ge-0 pe-0 m-0 d-flex" xs={'auto'}>
                    <Form.Check
                      name="non-zero-failures-cb"
                      className="mb-2"
                      checked={!!filters.zeroactivity}
                      onChange={(e) =>
                        setFilters((f) => ({
                          ...f,
                          zeroactivity: e.target.checked,
                        }))
                      }
                    />
                  </Col>
                  <Col
                    className="d-flex align-items-center px-0 gx-0"
                    xs={'auto'}
                  >
                    <label htmlFor="non-zero-chats-cb">
                      {t('Non zero hours')}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={'auto'}
                style={{
                  minWidth: '300px',
                  maxWidth: '300px',
                  marginTop: '-5px',
                }}
              >
                <NextbrainSelect
                  value={filters?.industries}
                  type={'dark'}
                  className="basic-single mb-2"
                  classNamePrefix="select"
                  placeholder={'Filter industries'}
                  isClearable={true}
                  isMulti
                  onChange={(e) => setFilters((f) => ({ ...f, industries: e }))}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <DataTable
              className="users-table table"
              columns={columns}
              data={users}
              defaultSortFieldId="order"
              defaultSortAsc={false}
              striped={true}
              customStyles={customStyles}
              persistTableHead
              onChangePage={(e) => setPageNum(e)}
              pagination
              paginationServer
              paginationPerPage={pageSize}
              paginationTotalRows={
                users.length < pageSize
                  ? pageSize * (pageNum - 1) + users.length
                  : usersLength
              }
              paginationRowsPerPageOptions={[25, 50, 100, usersLength]}
              onChangeRowsPerPage={(e) => setPageSize(e)}
              sortServer
              onSort={(e) => changeOrderColumn(e)}
            />
            {allUsersPaginated?.isLoading && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  pointerEvents: 'none',
                  backgroundColor: '#00000080',
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </div>
      </Row>
      <Modal
        show={selectedCompany}
        onHide={() => setSelectedCompany(null)}
        size="xl"
      >
        <Modal.Header className="h3 mb-0" closeButton>
          <h4>
            {companies?.[selectedCompany]?.name ||
              `company id ${companies?.[selectedCompany]?.id}`}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <CompanyManager
            companyId={selectedCompany}
            companies={companies}
            onUpdate={() => {
              queryClient.invalidateQueries([
                'admin-all-users-paginated',
                pageSize,
                pageNum,
                columnOrder,
                descendent,
                filters,
                searchTerm,
              ])
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
