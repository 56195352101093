import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

export default function BouncyButton({
  alternative = false,
  loading = false,
  onClick,
  controlClick = false,
  children,
  ...props
}) {
  const [actionLoading, setActionLoading] = useState(false)
  const onclick = controlClick
    ? async () => {
        try {
          setActionLoading(true)
          await onClick()
        } catch (e) {}
        setActionLoading(false)
      }
    : onClick

  return (
    <Button
      {...props}
      disabled={actionLoading || props.disabled}
      className={`${props.className ?? ''} bouncy-button-component`}
      onClick={onclick}
    >
      {loading || actionLoading ? (
        <>
          <div
            className={`bouncing-loader my-1 ${
              alternative ? 'alternative' : ''
            }`}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : (
        <div>{children}</div>
      )}
    </Button>
  )
}
