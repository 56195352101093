import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import $ from 'jquery'

import {
  exchangeGoogleLogin,
  loginUser,
  signUp,
  microsoftLoginUser,
  passwordApplyRecovery,
  setNewPassword,
  signUpQuick,
} from '../services/login'
import { getUser, updateUser } from '../services/user'
import useStoreState from '../hooks/UseStoreState'
import { NotificationManager } from 'react-notifications'

const AuthContext = createContext(null)

const DISABLE_LIMITS = process.env?.['REACT_APP_DISABLELIMITS'] === '1'
const DISABLED_CRISP = process.env?.['REACT_APP_DISABLECRISP'] === '1'

const nullConfig = {
  data: {},
  ttl: 0,
  google_access_token: null,
}

function configureStripe(enabled) {
  if (!enabled) {
    $(`script[src="https://js.stripe.com/v3"]`)?.remove()
    document.cookie = `__stripe_mid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    document.cookie = `__stripe_sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }
}

function configureSupport(enabled) {
  if (enabled) {
    if (!DISABLED_CRISP && !window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '1ff7e612-5357-4ed7-8df0-ffeb3ff315e1'
      var d = document
      var s = d.createElement('script')
      s.id = 'crisp-script-element'
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    }

    if (
      (process.env.REACT_APP_MODE || process.env.NODE_ENV) !== 'development'
    ) {
      if (!window.sentry) {
        window.sentry = 'Enabled'
        Sentry.init({
          dsn: 'https://cd42abd5a305d367423904f906c3ef10@o4505640776957952.ingest.sentry.io/4505641323331584',
          integrations: [
            new Sentry.BrowserTracing({
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: [
                'http://localhost:3000',
                'https://app.nextbrain.ml',
                'https://app.nextbrain.ai',
              ],
            }),
            new Sentry.Replay(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
      }
    }
  } else {
    if ($('#crisp-script-element').length || window.sentry) {
      document.cookie.split(';').forEach((c) => {
        const items = c?.split('=')
        if (items?.[0]?.includes('crisp'))
          document.cookie = `${items[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      })
      window.location.reload()
    }
  }
}

function configureAnalytics(enabled) {
  if (enabled) {
    if (!window.analyticsEnabled) {
      //Hotjar
      // window.analyticsEnabled = true
      // ;(function (w, d, s, l, i) {
      //   w[l] = w[l] || []
      //   w[l].push({
      //     'gtm.start': new Date().getTime(),
      //     event: 'gtm.js',
      //   })
      //   var f = d.getElementsByTagName(s)[0],
      //     j = d.createElement(s),
      //     dl = l !== 'dataLayer' ? '&l=' + l : ''
      //   j.async = true
      //   j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      //   f.parentNode.insertBefore(j, f)
      // })(window, document, 'script', 'dataLayer', 'GTM-T7JSG3H')
      // $('body').append(
      //   `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T7JSG3H" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
      // )
    }
  } else {
    if (window.analyticsEnabled) {
      window.location.reload()
    }
  }
}

function adjustCookieServices({ configured, support, analytics, payment }) {
  configureStripe(configured && payment)
  configureSupport(configured && support)
  configureAnalytics(configured && analytics)
}

export function AuthProvider({ children }) {
  const [storedData, setStoredData, forceRewite] = useStoreState({
    key: 'auth',
    ...nullConfig,
  })
  const [cookies, setCookies] = useStoreState({
    key: 'cookies',
    configured: false,
    base: false,
    support: false,
    analytics: false,
    payment: false,
  })
  useEffect(() => {
    adjustCookieServices(cookies)
  }, [cookies])
  const [loginWithGoogle, setLoginWithGoogle] = useState(false)
  const [requiresValidation, setRequiresValidation] = useState(false)
  const signout = () => {
    setStoredData({ key: 'auth', data: {}, ttl: 0 })
  }

  const updateTokenData = (
    email,
    tokenData,
    user = {},
    forceRewrite = false,
  ) => {
    if (user?.invalid) return

    if (tokenData && 'access_token' in tokenData) {
      const update = {
        data: {
          token: tokenData.access_token,
          email,
          user: user,
          hasPassword: tokenData.has_password,
        },
        ttl: new Date(Date.now() + tokenData.expires_in * 1000).getTime(),
      }
      if (forceRewite) forceRewite(update)
      else setStoredData(update)
      return true
    } else {
      return false
    }
  }

  //There's a small timing issue, after loging
  //sometimes retrieving the user may 401 this request
  const retrieveUser = async (email, tokenData) => {
    const timewindow = 500
    let attempts = 5
    const tryRetrieve = (resolve, reject) => {
      setTimeout(async () => {
        attempts -= 1
        const response = await getUser(tokenData.access_token, signout)
        if (response?.status === 428) {
          setRequiresValidation({
            email,
            token: tokenData.access_token,
          })
          return resolve({ invalid: true, email: email })
        }
        const userData = await response?.json()
        if (userData?.email) {
          resolve(userData)
        } else {
          if (attempts > 0) return tryRetrieve(resolve, reject)
          else {
            return resolve({})
          }
        }
      }, Math.floor(timewindow / 5))
    }
    return new Promise(tryRetrieve)
  }

  const update = async (userFields) => {
    const res = await updateUser(userFields, storedData?.data?.token, signout)
    if (res && res.id && res.email) {
      setStoredData({
        ...storedData,
        data: {
          ...storedData.data,
          user: {
            ...res,
          },
        },
      })
      return true
    }
    return false
  }

  const reloadUser = async () => {
    const user = await retrieveUser(null, {
      access_token: storedData?.data?.token,
    })
    if (user?.id && !user.invalid) {
      setStoredData({
        ...storedData,
        data: {
          ...storedData.data,
          user: {
            ...user,
          },
        },
      })
      return true
    }
    return false
  }

  useEffect(() => {
    if (storedData?.data?.token) reloadUser()
    // eslint-disable-next-line
  }, [storedData?.data?.token])

  //Refresh user data every 15 minutes
  useEffect(() => {
    if (storedData?.data?.token) {
      const i = setInterval(reloadUser, 1000 * 60 * 15)
      return () => clearInterval(i)
    }
    // eslint-disable-next-line
  }, [storedData?.data?.token])

  const signin = async (email, password) => {
    const tokenData = await loginUser({ username: email, password })
    if (tokenData.access_token) {
      const userData = await retrieveUser(email, tokenData)
      if (userData?.email) return updateTokenData(email, tokenData, userData)
    }
    return updateTokenData(email, tokenData)
  }
  const applyRecovery = async (password, token) => {
    const tokenData = await passwordApplyRecovery(password, token)

    if (tokenData.access_token) {
      const userData = await retrieveUser(tokenData.email, tokenData)
      if (userData?.email)
        return updateTokenData(tokenData.email, tokenData, userData)
    }
    return updateTokenData(tokenData.email, tokenData)
  }
  const applySetNewPassword = async (oldPassword, password, token) => {
    const tokenData = await setNewPassword(oldPassword, password, token)
    if (tokenData === undefined) {
      return undefined
    }
    if (tokenData.access_token) {
      const userData = await retrieveUser(tokenData.email, tokenData)
      if (userData?.email)
        return updateTokenData(tokenData.email, tokenData, userData)
    }
    return updateTokenData(tokenData.email, tokenData)
  }
  const signinExternal = async (email, token, user = null) => {
    const tokenData = {
      access_token: token,
      expires_in: 4 * 3600,
    }
    const userData = user ?? (await retrieveUser(email, tokenData))
    if (userData?.email) return updateTokenData(email, tokenData, userData)

    return updateTokenData(email, tokenData)
  }

  const googleOpts = (token = false, bigQueryToken = false) => ({
    onSuccess: bigQueryToken
      ? async (tokenResponse) => {
          setLoginWithGoogle(true)
          const response = await exchangeGoogleLogin({
            code: tokenResponse.code,
            getGoogleAccessToken: true,
          })
          const { google_access_token } = response
          if (!google_access_token) {
            NotificationManager.error('Failed login to Google')
          } else {
            setStoredData({
              ...storedData,
              bigQueryAccessToken: google_access_token,
            })
          }
          setLoginWithGoogle(false)
        }
      : token
      ? async (tokenResponse) => {
          setLoginWithGoogle(true)
          const response = await exchangeGoogleLogin({
            code: tokenResponse.code,
            getGoogleAccessToken: true,
          })
          const { google_access_token } = response
          if (!google_access_token) {
            NotificationManager.error('Failed login to Google')
          } else {
            setStoredData({ ...storedData, google_access_token })
          }
          setLoginWithGoogle(false)
        }
      : async (tokenResponse) => {
          setLoginWithGoogle(true)
          const response = await exchangeGoogleLogin({
            code: tokenResponse.code,
          })
          const { email, ...tokenData } = response
          const userData = await retrieveUser(email, tokenData)
          updateTokenData(email, tokenData, { ...userData })
          setLoginWithGoogle(false)
        },
    onError: async () => {
      NotificationManager.error('Failed login to Google')
      setLoginWithGoogle(false)
    },
    flow: 'auth-code',
  })

  const scopesForSpreadsheets = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/spreadsheets.readonly',
    'https://www.googleapis.com/auth/drive.readonly',
  ]

  const scopesForBigQuery = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/bigquery.readonly',
    'https://www.googleapis.com/auth/devstorage.read_only',
  ]

  const googleLogin = useGoogleLogin(googleOpts())

  const googleLoginWithFiles = useGoogleLogin({
    ...googleOpts(true),
    scope: scopesForSpreadsheets.join(' '),
  })

  const googleLoginBigQuery = useGoogleLogin({
    ...googleOpts(true, true),
    scope: scopesForBigQuery.join(' '),
  })

  const microsoftLogin = async ({ account, accessToken }) => {
    const response = await microsoftLoginUser(accessToken)
    const { email, ...tokenData } = response
    const userData = await retrieveUser(email, tokenData)
    updateTokenData(email, tokenData, { ...userData }, true)
    //Awful hack to force a reload of the page, state becomes unresponsive  when using MSAL 🤷
    window.location.reload()
  }

  const signup = async ({
    name,
    email,
    password,
    student,
    platform_use_description,
    country,

    introduction,
    previous_platforms,
    industries,
    ml_level,
  }) => {
    try {
      const tokenData = await signUp({
        name,
        email,
        password,
        student,
        platform_use_description,
        country,
        introduction,
        previous_platforms,
        industries,
        ml_level,
      })
      if (tokenData.detail) {
        return tokenData
      }
      const userData = await retrieveUser(email, tokenData)
      return updateTokenData(email, tokenData, userData)
    } catch (error) {
      console.log('Failed to signup', error)
      return false
    }
  }

  const signupquick = async ({ email, password, country }) => {
    try {
      const tokenData = await signUpQuick({
        email,
        password,
        country,
      })
      if (tokenData.status !== 200) {
        let result = {
          detail: 'Error during signup, try again later',
        }
        try {
          const jresult = await tokenData.json()
          if (jresult?.detail) result.detail = jresult.detail
        } catch (e) {}
        return {
          error: true,
          message: result.detail,
        }
      } else {
        const authInfo = await tokenData.json()
        return {
          error: false,
          login: async () => {
            const userData = await retrieveUser(email, authInfo)
            return updateTokenData(email, authInfo, userData)
          },
        }
      }
    } catch (error) {
      return {
        error: true,
        message: 'Error during signup, try again later',
      }
    }
  }

  const validlogin = () => {
    return storedData?.ttl && storedData.ttl > Date.now()
  }

  useEffect(() => {
    if (storedData.ttl && storedData.ttl < Date.now()) {
      setStoredData(nullConfig)
    }
    // eslint-disable-next-line
  }, [storedData])

  const clearGoogleAccessToken = () => {
    setStoredData({ ...storedData, google_access_token: null })
  }

  const clearBigQueryGoogleAccessToken = () => {
    setStoredData({ ...storedData, bigQueryAccessToken: null })
  }

  const subscriptions = (storedData?.data?.user?.company
    ? [storedData?.data?.user?.company]
    : []
  )
    ?.filter(
      (company) =>
        new Date(company.subscription?.to_date) > new Date(Date.now()),
    )
    ?.reduce(
      (config, company) => {
        try {
          if (company?.subscription?.config?.name)
            config.plans.add(company?.subscription?.config?.name)

          Object.keys(company.subscription.config).forEach((key) => {
            switch (typeof config[key]) {
              case 'number':
                config[key] = Math.max(
                  config[key],
                  company.subscription.config[key],
                )
                break
              case 'boolean':
                config[key] = config[key] || company.subscription.config[key]
                break
              case 'string':
                config[key] =
                  config[key] + ', ' + company.subscription.config[key]
                break
              default:
                config[key] = company.subscription.config[key]
            }
          })
        } catch (e) {
          console.error(e)
        }
        return config
      },
      { plans: new Set(['Free']) },
    ) ?? { plans: new Set(['Free']) }

  const MMMEnabled = () => {
    const plugin = storedData?.data?.user?.company?.plugin_subscriptions?.find(
      (p) => p?.config?.plugin_type?.toLowerCase() === 'mmm',
    )
    if (
      plugin &&
      plugin?.to_date &&
      new Date(plugin.to_date).getTime() > Date.now()
    )
      return true
    return false
  }

  const clusteringEnabled = () => {
    const subscription = storedData?.data?.user?.company?.subscription
    if (
      subscription &&
      new Date(subscription.to_date)?.getTime() > Date.now() &&
      subscription?.config?.clustering_models
    )
      return true
    return false
  }

  const hasAnomalyPlugin = useMemo(
    () =>
      storedData?.data?.user?.company?.plugin_subscriptions?.some((p) => {
        if (p?.config?.plugin_type?.toLowerCase() === 'anomaly') {
          const begin = new Date(p.from_date.replace(',', '')).getTime()
          const end = new Date(p.to_date.replace(',', '')).getTime()
          const now = Date.now()
          return now >= begin && now <= end
        }
        return false
      }),
    [storedData],
  )

  const value = {
    user: storedData.data.user,
    MMMEnabled,
    hasAnomalyPlugin,
    reloadUser: reloadUser,
    hasPassword: storedData.data.hasPassword,
    token: storedData.data.token,
    google_access_token: storedData.google_access_token,
    bigQueryAccessToken: storedData?.bigQueryAccessToken,
    clearGoogleAccessToken,
    clearBigQueryGoogleAccessToken,
    signin,
    applyRecovery,
    applySetNewPassword,
    signout,
    signup,
    signupquick,
    validlogin,
    updateuser: update,
    updateuserData: setStoredData,
    googleLogin,
    googleLoginWithFiles,
    googleLoginBigQuery,
    loginWithGoogle,
    microsoftLogin,
    signinExternal,
    unlimitedPredictions:
      DISABLE_LIMITS ||
      storedData?.data?.user?.monthly_limits?.predictions === 'unlimited',
    unlimitedTraining:
      DISABLE_LIMITS ||
      storedData?.data?.user?.monthly_limits?.training_rows === 'unlimited',
    maxRowsPerModel: DISABLE_LIMITS
      ? 'unlimited'
      : storedData?.data?.user?.monthly_limits?.max_rows_per_model ??
        'unlimited',
    maxModels: DISABLE_LIMITS
      ? Infinity
      : storedData?.data?.user?.monthly_limits?.max_models ?? Infinity,
    subscriptions,

    freeUser: DISABLE_LIMITS
      ? false
      : subscriptions.plans.size === 1 && subscriptions.plans.has('Free'),
    requiresValidation,
    setRequiresValidation,
    usingGPT3:
      storedData?.data?.user?.llm_config?.model_kwargs?.openai_model_name ===
      'gpt-3.5-turbo',
    usingCustomAPIKey:
      !!storedData?.data?.user?.llm_config?.model_kwargs?.openai_api_key,
    clusteringEnabled,
    llmDisabled: storedData?.data?.user?.llm_config?.disabled,
    cookies,
    setCookies,
  }
  window.userAuth = value
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
export function RequireAuth({ children }) {
  let auth = useAuth()
  let location = useLocation()

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
