import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { colors } from '../../mmm/config'
import { Row, Col } from 'react-bootstrap'
import {
  generateMMMPresentation,
  getMMMDynamicSpend,
  getMMMFunnelEffect,
  getMMMInfluence,
  getMMMLagCarryover,
  getMMMModelStackedPlot,
  getMMMOptimizedTable,
  getMMMStatistics,
} from '../../../services/model'
import { useAuth } from '../../../providers/AuthProvider'
import {
  generateMMMColorMap,
  getMMMDataColumnInfo,
  testAccuracy,
} from '../../../util/models'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { zip } from '../../../util/other'
import {
  exportSpreadsheetExcel,
  exportSpreadsheetGsheets,
} from '../../../services/spreadsheet'
import './Summary.css'
import html2canvas from 'html2canvas'
import { awaitTask } from '../../../services/base'

const MMMExportContext = createContext({})

function RGBtof(num) {
  return num / 255
}

function numberBlock(columns, offset = 1) {
  return new Array(columns).fill(null).reduce((a, _, i) => {
    a[i + offset] = { numberFormat: { type: 'NUMBER', pattern: '#,###0.##' } }
    return a
  }, {})
}

export function MMMProvideExport({ model, children }) {
  const {
    token,
    signout,
    google_access_token,
    clearGoogleAccessToken,
    googleLoginWithFiles,
    loginWithGoogle,
  } = useAuth()

  const colorMap = useMemo(() => generateMMMColorMap(model, colors), [model])
  const { t } = useTranslation()
  const refBuffer = useRef({ waitingToLogWithGoogle: false })
  const [working, setWorking] = useState(false)
  const [offscreenItems, setOffscreenItems] = useState([])
  const [executePPExport, setExecutePPExport] = useState(false)

  const {
    data: optimizedTable,
    isLoading: optimizedTableLoading,
    isSuccess: optimizedTableSuccess,
  } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      return await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: false,
        original: true,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const {
    data: mediaContribution,
    isLoading: mediaContributionLoading,
    isSuccess: mediaContributionSuccess,
  } = useQuery(
    ['mediaContribution-export', model.id], //Bad
    async () => {
      const response = await getMMMModelStackedPlot({
        modelId: model.id,
        token,
        signout,
      })

      const header = response.columns
      const data = response.data
      const finalData = header.slice(1).map((column, i) => {
        const col = column === 'baseline' ? 'Baseline' : column
        return {
          id: col,
          data: data.map((row) => {
            return {
              x: row[0],
              y: row[i + 1],
            }
          }),
          color: colorMap?.[col],
        }
      })

      return finalData
    },
    { staleTime: Infinity },
  )

  const {
    data: mmmstats,
    isLoading: mmmstatsLoading,
    isSuccess: mmmstatsSuccess,
  } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
        signout,
      })
      if (!response)
        NotificationManager.error(t('Failed to retrieve original forecast'))

      return response
    },
    { staleTime: Infinity },
  )

  const tableData = model?.mmm?.table?.data,
    tableDataLoading = false,
    tableDataSuccess = true

  const {
    data: funnel,
    isLoading: funnelLoading,
    isSuccess: funnelSuccess,
  } = useQuery(
    ['mmm-funnel-effect', model?.id],
    async () => {
      if (model?.id)
        return getMMMFunnelEffect({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const {
    data: lagCarryover,
    isLoading: lagCarryoverLoading,
    isSuccess: lagCarryoverSuccess,
  } = useQuery(
    ['lag-carryover', model.id],
    async () => {
      return await getMMMLagCarryover({
        modelId: model.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const {
    data: dynamicSpend,
    isLoading: dynamicSpendLoading,
    isSuccess: dynamicSpendSuccess,
  } = useQuery(
    ['MMMDynamicSpend', model.id],
    async () => {
      return await getMMMDynamicSpend({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const {
    data: influence,
    isLoading: influenceLoading,
    isSuccess: influenceSuccess,
  } = useQuery(
    ['MMMInfluence', model.id],
    async () => {
      return await getMMMInfluence({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const isLoading =
    optimizedTableLoading ||
    mediaContributionLoading ||
    mmmstatsLoading ||
    tableDataLoading ||
    funnelLoading ||
    lagCarryoverLoading ||
    dynamicSpendLoading ||
    dynamicSpendLoading ||
    influenceLoading ||
    loginWithGoogle ||
    working

  const doExport = (excel = false) => {
    setWorking(true)
    const errors = []
    const spreadsheets = []
    const columnInfo = getMMMDataColumnInfo(model)

    if (!mmmstatsSuccess)
      errors.push({
        'Model accuracy': t('Failed to retrieve model prediction'),
      })
    else {
      const numFormat = {
        type: 'NUMBER',
        pattern: '#,###0.##',
      }
      const d = zip([
        mmmstats.media_data.map((d) => ''),
        mmmstats.y,
        mmmstats.y_pred,
        mmmstats.upper_bound,
        mmmstats.lower_bound,
      ])
      spreadsheets.push({
        title: 'Model accuracy',
        data: [
          {
            header: [
              'Week',
              'Original',
              'Predicted',
              'Upper Bound',
              'Lower Bound',
            ],
            values: d,
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel
              ? null
              : {
                  1: {
                    numberFormat: numFormat,
                    backgroundColor: {
                      red: RGBtof(0xd9),
                      green: RGBtof(0xea),
                      blue: RGBtof(0xd3),
                    },
                  },
                  2: {
                    numberFormat: numFormat,
                    backgroundColor: {
                      red: RGBtof(0xcf),
                      green: RGBtof(0xe2),
                      blue: RGBtof(0xf3),
                    },
                  },
                  3: {
                    numberFormat: numFormat,
                    backgroundColor: {
                      red: RGBtof(0xf4),
                      green: RGBtof(0xcc),
                      blue: RGBtof(0xcc),
                    },
                  },
                  4: {
                    numberFormat: numFormat,
                    backgroundColor: {
                      red: RGBtof(0xf4),
                      green: RGBtof(0xcc),
                      blue: RGBtof(0xcc),
                    },
                  },
                },
          },
          {
            header: null,
            top_offset: 1,
            values: mmmstats.media_data
              .map((d) => columnInfo.map(parseInt(d)))
              .map((d) => [d]),
          },
        ],
      })
    }

    if (
      !mediaContributionSuccess ||
      !mmmstatsSuccess ||
      !Array.isArray(mediaContribution)
    )
      errors.push({
        'Media effect weekly': t('Failed to retrieve Media effect weekly'),
      })
    else {
      const finalData = mmmstats.y_pred
      const lineData = mediaContribution.map((d) => ({
        id: d.id,
        data: d.data.map((d, i) => Math.max(0, d.y * finalData[i])),
      }))
      const d = zip([
        mmmstats.media_data.map((d) => ''),
        ...lineData.map((l) => l.data),
      ])
      spreadsheets.push({
        title: 'Weekly Media effects',
        data: [
          {
            header: ['Week', ...lineData.map((l) => l.id)],
            values: d,
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel
              ? null
              : numberBlock(lineData.length),
          },
          {
            header: null,
            top_offset: 1,
            values: mmmstats.media_data
              .map((d) => columnInfo.map(parseInt(d)))
              .map((d) => [d]),
          },
        ],
      })
    }

    if (!tableDataSuccess)
      errors.push({
        'Average Media Effect': t('Failed to retrieve Media effect weekly'),
      })
    else {
      const numFormat = {
        type: 'PERCENT',
        pattern: '####0.##',
      }
      const data = model?.mmm?.table?.data ?? []
      spreadsheets.push({
        title: 'Average Media Effect',
        data: [
          {
            header: ['Channel', 'Contribution'],
            values: data.map((d) => [d[0], d[2]]),
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel
              ? null
              : {
                  1: {
                    numberFormat: numFormat,
                  },
                },
          },
        ],
      })
    }

    if (!lagCarryoverSuccess)
      errors.push({
        'Lag & Carryover': t('Failed to retrieve Lag & Carryover'),
      })
    else {
      spreadsheets.push({
        title: 'Lag & Carryover',
        data: [
          {
            header: ['Lag in weeks', ...lagCarryover.columns.slice(1)],
            values: lagCarryover.data.map(([v, ...r]) => [v + 1, ...r]),
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel
              ? null
              : numberBlock(lagCarryover.columns.length),
          },
          {
            header: null,
            top_offset: 1,
            values: lagCarryover.data.map(([v, ...r]) => [v + 1]),
          },
        ],
      })
    }

    if (!dynamicSpendSuccess)
      errors.push({
        'Consumer response': t('Failed to retrieve Consumer response'),
      })
    else {
      spreadsheets.push({
        title: 'Consumer response',
        data: [
          {
            header: ['Week', 'Spend	adstock', 'Adstock', 'Difference'],
            values: new Array(dynamicSpend.spend.y.length)
              .fill(null)
              .map((_, i) => [
                i + 1,
                dynamicSpend.spend.y[i],
                dynamicSpend.adstock.y[i],
                dynamicSpend.adstock.y[i] - dynamicSpend.spend.y[i],
              ]),
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel ? null : numberBlock(3),
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
          },
          {
            header: null,
            top_offset: 1,
            values: new Array(dynamicSpend.spend.y.length)
              .fill(null)
              .map((_, i) => [i + 1]),
          },
        ],
      })
    }

    if (!influenceSuccess)
      errors.push({
        'Non Marketing contribution': t(
          'Failed to retrieve Non Marketing contribution',
        ),
      })
    else {
      spreadsheets.push({
        title: 'Non Marketing contribution',
        data: [
          {
            header: ['Week', ...influence.columns],
            values: influence.data.map((d, i) => [i + 1, ...d]),
            cell_format: excel ? null : {},
            cell_format_per_column_index: excel
              ? null
              : numberBlock(influence.columns.length),
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
          },
          {
            header: null,
            top_offset: 1,
            values: influence.data.map((d, i) => [i + 1]),
          },
        ],
      })
    }

    if (!funnelSuccess)
      errors.push({
        'Funnel effect': t('Failed to retrieve Funnel effect'),
      })
    else {
      const numFormat = {
        type: 'NUMBER',
        pattern: '#,###0.##',
      }
      const border = {
        style: 'SOLID',
        colorStyle: {
          rgbColor: {
            red: 0,
            green: 0,
            blue: 0,
          },
        },
      }
      let offset = 0
      const entries = Object.entries(funnel).map(([week, data]) => {
        const res = {
          header: [`Week ${week}`, ...data.columns],
          values: data.data.map((d, i) => [data.index[i], ...d]),
          top_offset: offset,
          format: excel ? { num_format: '#,##0.00' } : null,
          header_format: excel ? { bold: true } : null,
          cell_format: excel
            ? null
            : {
                numberFormat: numFormat,
                horizontalAlignment: 'CENTER',
              },
          cell_format_per_column_index: excel
            ? null
            : new Array(data.columns.length + 1)
                .fill(null)
                .reduce((a, _, i) => {
                  if (i) {
                    a[i] = {
                      borders: {
                        top: border,
                        right: border,
                        bottom: border,
                        left: border,
                      },
                    }
                  }
                  return a
                }, {}),
        }
        offset += data.data.length + 2
        return res
      })
      spreadsheets.push({
        title: 'Funnel effect',
        data: entries,
      })
    }

    if (!optimizedTableSuccess)
      errors.push({
        'Optimized Spend': t('Failed to retrieve Optimized Spend'),
      })
    else {
      const numFormat = {
        type: 'NUMBER',
        pattern: '#,###0.##',
      }
      const index = optimizedTable.columns.indexOf('index')
      const mediaSpend = optimizedTable.columns.indexOf('Media spend')
      const optimized = optimizedTable.columns.indexOf('Recommended spend')
      spreadsheets.push({
        title: 'Optimized spend',
        data: [
          {
            header: ['Channel', 'Original', 'Optimized'],
            values: optimizedTable.data.map((o) => [
              o[index],
              o[mediaSpend],
              o[optimized],
            ]),
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : { numberFormat: numFormat },
            cell_format_per_column_index: excel ? null : {},
          },
        ],
      })
    }

    if (!tableDataSuccess)
      errors.push({
        'Table resume': t('Failed to retrieve Table resume'),
      })
    else {
      const numFormat = {
        type: 'NUMBER',
        pattern: '#,###0.##',
      }
      spreadsheets.push({
        title: 'Table resume',
        data: [
          {
            header: ['Channel', ...model.mmm.table.columns.slice(1)],
            values: tableData,
            format: excel ? { num_format: '#,##0.00' } : null,
            header_format: excel ? { bold: true } : null,
            cell_format: excel ? null : { numberFormat: numFormat },
            cell_format_per_column_index: excel ? null : {},
          },
        ],
      })
    }

    if (excel)
      exportSpreadsheetExcel({
        workbookName: `MMM insights ${model.id}`,
        spreadsheets,
        token,
        signout,
      })
        .then(async (response) => {
          if (response.status !== 200)
            NotificationManager.error(t('Failed to export to Excel'))
          else {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `MMM insights ${model.id}.xlsx`)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
          }
        })
        .catch((error) => {
          NotificationManager.error(t('Failed to export to Excel'))
        })
        .finally(() => setWorking(false))
    else
      exportSpreadsheetGsheets({
        googleToken: google_access_token,
        workbookName: `MMM insights ${model.id}`,
        spreadsheets,
        token,
        signout,
      })
        .then(async (response) => {
          if (response.status !== 200) {
            if (response.status === 401) {
              clearGoogleAccessToken()
              NotificationManager.error(t('Credentials expired, log in again'))
            } else
              NotificationManager.error(t('Failed to export to Google Sheets'))
          } else {
            const result = await response.json()
            if (result?.success) {
              if (errors.length) {
                NotificationManager.warn(
                  <Row>
                    <Col xs={12}>Some data failed to export:</Col>
                    {Object.keys(errors).map((key) => (
                      <Col key={key} xs={12}>
                        {key}
                      </Col>
                    ))}
                  </Row>,
                )
              }
              window.open(result?.url, '_blank')
            } else
              NotificationManager.error(t('Failed to export to Google Sheets'))
          }
        })
        .catch((error) => {
          NotificationManager.error(t('Failed to export to Google Sheets'))
        })
        .finally(() => setWorking(false))
  }

  const exportGsheets = async () => {
    if (!google_access_token) {
      refBuffer.current.waitingToLogWithGoogle = true
      await googleLoginWithFiles()
      return
    }
    doExport()
  }

  const exportExcel = () => {
    doExport(true)
  }
  const exportPP = () => {
    setWorking(true)
    generateMMMPresentation({ modelId: model.id, token, signout })
      .then(async (r) => {
        if (!r?.task_id) {
          NotificationManager.error('Failed export')
        } else {
          const result = await awaitTask({
            taskUuid: r.task_id,
            sleep: 4000,
            timeout: 150000,
            decode: true,
            maxRetries: 2,
          })
          if (!result || result?.error) {
            NotificationManager.error('Failed export ' + result?.message ?? '')
          } else {
            const link = document.createElement('a')
            link.setAttribute(
              'href',
              `data:application/octet-stream;base64,${result}`,
            )
            link.setAttribute('download', `MMM_${model.dataset.name}.pptx`)
            document.body.appendChild(link)
            link.click()
          }
        }
        setWorking(false)
      })
      .catch((e) => {
        NotificationManager.error('Failed export')
        setWorking(false)
      })
  }
  useEffect(() => {
    if (offscreenItems.length && executePPExport) {
      setExecutePPExport(false)
      const promiseImages = offscreenItems.map((e) => {
        return new Promise((resolve, reject) => {
          const element = document.getElementById(`offscreen-el-${e.index}`)
          if (!element) {
            return resolve({ error: true, name: e.name })
          }
          html2canvas(element, {
            backgroundColor: null,
            fill: 'black',
          })
            .then(function (canvas) {
              const dataURL = canvas.toDataURL('image/png')
              resolve({ image: dataURL, name: e.name })
            })
            .catch((e) => {
              resolve({ error: true, name: e.name })
            })
        })
      })
      Promise.all(promiseImages).then((images) => {
        fetch('http://localhost:8080/slideshow', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: model.dataset.name,
            target: model.target,
            accuracy: testAccuracy(model),
            active_columns: optimizedTable.data.map((d) => d[0]),
            effects: model?.mmm?.table?.data.reduce((a, [k, _, v]) => {
              a[k] = v
              return a
            }, {}),
            images: images
              .filter((i) => !i?.error)
              .reduce((a, i) => {
                a[i.name] = i.image
                return a
              }, {}),
          }),
        })
          .then(async (response) => {
            if (response.status === 200) {
              const blob = await response.blob()
              const url = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `MMM_insights_${model.id}.pptx`)
              document.body.appendChild(link)
              link.click()
              link.parentNode.removeChild(link)
            } else NotificationManager.error('Failed export')
          })
          .finally(() => {
            setWorking(false)
            setOffscreenItems([])
          })
      })
    }
    // eslint-disable-next-line
  }, [executePPExport, offscreenItems])

  useEffect(() => {
    if (google_access_token && refBuffer.current.waitingToLogWithGoogle) {
      refBuffer.current.waitingToLogWithGoogle = false
      doExport()
    }
    // eslint-disable-next-line
  }, [google_access_token])

  const values = {
    exportGsheets,
    exportExcel,
    exportPP,
    isLoading,
  }

  return (
    <>
      <MMMExportContext.Provider value={values}>
        <div className="mmm-offscreen-renderer">
          {offscreenItems.map(({ element }) => element)}
        </div>
        {children}
      </MMMExportContext.Provider>
    </>
  )
}

export function useMMMProviderExport() {
  return useContext(MMMExportContext)
}
