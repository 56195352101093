import React, { useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../../providers/AuthProvider'
import NextbrainSelect, { categoryToColor } from '../NextbrainSelect'
import ProblemButton from './ProblemButton'
import { getDateTypeColumns } from '../../../util/models'

import { modelProblemTypesPerColumn } from '../../../util/models'
import DataTypeIcon from '../../data-types/DataTypeIcon'
import { useNav } from '../../../providers/NavProvider'

function columnValidation(model, column) {
  if (
    model?.dataset?.final_column_status?.[column] === 'Categorical' &&
    (model?.dataset?.statistics?.[column]?.nunique ?? 0) > 99
  ) {
    return 'Invalid, too many categories'
  }
}

export default function TargetSelect({
  model,
  target,
  problemType,
  forecastingDate,
  onChange = (target, problemTypes, forecastingDate) => {
    console.log(
      `selected target ${target} with problem types`,
      problemTypes,
      forecastingDate,
    )
  },
  ...props
}) {
  const { t } = useTranslation()
  const { MMMEnabled, hasAnomalyPlugin } = useAuth()
  const hasPlugin = MMMEnabled()
  const { mode } = useNav()
  const targets = modelProblemTypesPerColumn(
    model,
    hasPlugin,
    mode,
    hasAnomalyPlugin,
  )
  const validTargets = Object.keys(targets).reduce((d, p) => {
    if (targets[p].length) d[p] = targets[p]
    return d
  }, {})
  const dateTypes = useMemo(() => getDateTypeColumns(model), [model])
  const selectedProblemType =
    model.special_model_type === 'mmm' ? 'Marketing Mix Modeling' : problemType
  const selectedForecastDate = forecastingDate

  const PrefixColumn = ({ column }) => {
    const type = (model?.dataset?.final_column_status ?? {})[column]
    return (
      <DataTypeIcon
        style={{
          borderColor: categoryToColor[type],
        }}
        color={categoryToColor[type]}
        size={28}
        type={type}
      />
    )
  }

  const LabelTargetType = ({ column, invalid }) => {
    return (
      <div
        className={`d-flex align-items-center justify-content-between ${
          invalid ? 'invalid-option-target' : ''
        }`}
      >
        <div
          title={column}
          style={{ maxWidth: 'calc(100% - 28px)' }}
          className="d-inline-block text-truncate"
        >
          {column}
          {invalid ? (
            <strong
              className="small ms-2 color-red"
              style={{ color: 'var(--nextbrain-error-color)' }}
            >
              {invalid}
            </strong>
          ) : (
            <></>
          )}
        </div>
        <PrefixColumn column={column} />
      </div>
    )
  }

  useEffect(
    () => {
      //No need to ask the user if there's only 1 problem type
      if (target && !selectedProblemType && validTargets[target].length === 1)
        onChange(target, validTargets[target][0], selectedForecastDate)
      //No need to ask the user if there's only 1 date for forecasting
      if (
        target &&
        selectedProblemType === 'Forecast' &&
        !selectedForecastDate &&
        dateTypes.length === 1
      )
        onChange(target, selectedProblemType, dateTypes[0])
    },
    // eslint-disable-next-line
    [target, problemType, forecastingDate],
  )

  useEffect(() => {
    const cb = async () => {
      if (target && !selectedProblemType) onChange(null, null, null)
    }
    document.querySelector('body')?.addEventListener('click', cb)
    return () =>
      document.querySelector('body')?.removeEventListener('click', cb)
    // eslint-disable-next-line
  }, [target, selectedProblemType])

  const options = (model?.dataset?.columns_order ?? [])
    .map((element) =>
      validTargets[element]
        ? {
            value: element,
            label: (
              <LabelTargetType
                column={element}
                invalid={columnValidation(model, element)}
              />
            ),
            type: (model?.dataset?.final_column_status ?? {})[element],
            invalid: columnValidation(model, element),
          }
        : null,
    )
    .filter((e) => e)

  if (!options?.length) {
    if (mode === 'mmm')
      return (
        <Row className="smallp">
          <Col xs={12}>
            <strong>
              {t('Your current dataset can not be used to solve MMM problems.')}
            </strong>
          </Col>
          <Col xs={12}>
            <strong>
              {t(
                'We require numeric columns for the target and channels and 1 date column or 1 integer column to interpret as period.',
              )}
            </strong>
          </Col>
        </Row>
      )
    else
      return (
        <Row>
          <Col xs={12}>
            {t(
              'Your current dataset has no valid problems to solve in the current mode.',
            )}
          </Col>
        </Row>
      )
  }

  return (
    <Row {...props} className={`target-select ${props.className ?? ''}`}>
      <Col onClick={(e) => e.stopPropagation()} xs={12}>
        <NextbrainSelect
          value={
            target
              ? {
                  value: target,
                  label: <LabelTargetType column={target} />,
                  type: (model?.dataset?.final_column_status ?? {})[target],
                }
              : null
          }
          onChange={(value) => {
            if (value?.invalid) return
            onChange(value?.value, selectedProblemType, selectedForecastDate)
          }}
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          name="Column to predict"
          options={options}
        />
      </Col>
      <Col xs={12} className="mt-2 position-relative">
        <Row
          style={{ zIndex: 1000 }}
          className={`justify-content-center select-problem-type-container 
          ${
            !selectedProblemType && validTargets?.[target]?.length
              ? 'position-absolute w-100 with-background'
              : ''
          }
      select-problem-type-container-${validTargets?.[target]?.length ?? 0}  ${
            selectedProblemType ? 'active' : ''
          }`}
        >
          {target ? (
            (validTargets?.[target] ?? []).map((p) => (
              <Col key={`${target}-${p}`} xs={11} onClick={() => {}}>
                <ProblemButton
                  problemType={p}
                  className={`p-3 mt-2 ${
                    p === selectedProblemType ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    onChange(target, p, selectedForecastDate)
                  }}
                  onDeselect={(e) => {
                    e.stopPropagation()
                    onChange(target, null, null)
                  }}
                />
              </Col>
            ))
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs={12}>
        {target &&
        selectedProblemType === 'Forecast' &&
        dateTypes.length > 1 ? (
          <Row className="mt-2 forecast-date-select">
            <Col xs={12}>{t('Date for forecast')}</Col>
            <Col xs={12}>
              <NextbrainSelect
                value={
                  selectedForecastDate
                    ? {
                        value: selectedForecastDate,
                        label: selectedForecastDate,
                        type: 'Datetime',
                      }
                    : null
                }
                onChange={(value) =>
                  onChange(target, selectedProblemType, value.value)
                }
                className="basic-single mt-2"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                name="Column to predict"
                options={dateTypes
                  .map((element) => ({
                    value: element,
                    label: element,
                    type: 'Datetime',
                  }))
                  .filter((e) => e)}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  )
}
