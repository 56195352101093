import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import ConcatTable from './ConcatTable'

export default function ConfigureConcat({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [leftNode, setLeftNode] = useState(null)
  const [rightNode, setRightNode] = useState(null)
  const [sample, setSample] = useState(null)

  useEffect(() => {
    const leftEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_left_target`,
    )
    const rightEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_right_target`,
    )
    if (!leftEdge) {
      NotificationManager.error('Missing top connection')
      close()
    } else if (!rightEdge) {
      NotificationManager.error('Missing bottom connection')
      close()
    } else {
      const leftNode = getNode(leftEdge.source)
      const rightNode = getNode(rightEdge.source)
      if (!leftNode?.data?.valid || !rightNode?.data?.valid) {
        NotificationManager.error(
          'Connected nodes are invalid, configure them first',
        )
        close()
      } else {
        if (!leftNode?.data?.sample || !rightNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          setRightNode(rightNode)
          setLeftNode(leftNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!leftNode || !rightNode) return <Loading />

  return (
    <Row>
      <Col className="mt-2" xs={12}>
        <span className="h4">
          <strong>{t('Preview')}</strong>
        </span>
      </Col>
      <Col
        className="my-2 merge-table-flow position-relative"
        style={{
          overflow: 'auto',
          position: 'relative',
          zIndex: 0,
        }}
      >
        {leftNode && rightNode && (
          <ConcatTable
            sampleLeft={leftNode.data.sample}
            sampleRight={rightNode.data.sample}
            onDataLoad={(data) => setSample(data)}
          />
        )}
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              left: leftNode.id,
              right: rightNode.id,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
