import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import './upload-csv.css'

function getRegex(delimiter) {
  // This regex skips splits inside "
  return new RegExp(`${delimiter}(?=(?:(?:[^"]*"){2})*[^"]*$)`)
}

export function stringToCsv(text, maxLineLength = Infinity) {
  const firstSplit = text.indexOf('\n')
  if (firstSplit !== -1 && firstSplit > maxLineLength) return null

  if (text.length > 1000000) {
    const lastIndex = text.indexOf('\n', 1000000)
    if (lastIndex !== -1) text = text.slice(0, lastIndex)
  }
  const adjustedText = text.split('\n').reduce(
    (acc, line) => {
      acc[1] += line
      const split = acc[1].split('"')
      if (split.length % 2 !== 0) {
        acc[0].push(acc[1])
        acc[1] = ''
      }
      return acc
    },
    [[], ''],
  )[0]

  let delimiter = guessDelimiters(adjustedText, [',', '\t', ';', ' '])
  if (delimiter.length > 0) delimiter = delimiter[0]
  else return null

  const completeData = adjustedText.map((row) => row.split(getRegex(delimiter)))
  const transposedData = completeData[0].map((_, colIndex) =>
    completeData.map((row) => row[colIndex]),
  )
  let head = {}
  for (let col of transposedData) head[col[0]] = col.slice(1)

  return {
    columns_order: completeData[0],
    head: head,
    target: null,
    ok: true,
  }
}

export function guessDelimiters(splitText, possibleDelimiters) {
  for (let delimiter of possibleDelimiters)
    if (weedOut(delimiter)) return [delimiter]

  return []

  function weedOut(delimiter) {
    let cache = -1
    const reg = getRegex(delimiter)
    return splitText.every(checkLength)

    function checkLength(line) {
      if (!line) return true

      let length = line.split(reg).length
      if (cache < 0) cache = length

      return cache === length && length > 1
    }
  }
}

export default function UploadCsv({
  onHandleFile,
  header,
  limit = false,
  buttonText,
  style,
  loading = null,
  concern = true,
}) {
  const { t } = useTranslation()
  if (!header) header = t('Drag and drop your dataset file here or')
  if (!buttonText) buttonText = t('Upload a file')

  // drag state
  const [dragActive, setDragActive] = useState(false)
  // ref
  const inputRef = useRef(null)

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onHandleFile(e.dataTransfer.files[0])
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      onHandleFile(e.target.files[0])
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  return (
    <form
      id="form-file-upload"
      style={{ ...style, pointerEvents: limit ? 'none' : 'all' }}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        id="build_model_response"
        accept=".csv,.zip,.txt,.sav,.xls,.xlsx,.xlsm,.xlsb,.odf,.ods,.odt"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="build_model_response"
        className={`cursor-pointer p-3 ${limit ? 'plan-limit' : ''} ${
          dragActive ? 'drag-active' : ''
        }`}
      >
        {loading !== null ? (
          <div className="upload-progress-csv">
            <CircularProgressbar
              value={Math.min(parseInt(loading), 96)}
              text={`${Math.min(parseInt(loading), 99)}%`}
              strokeWidth={10}
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: 'rounded',
                pathTransitionDuration: 0.5,
                pathColor: `#4240B5`,
                trailColor: 'var(--nextbrain-background)',
                textColor: 'var(--nextbrain-white-font)',
                text: {
                  fontSize: '20px',
                },
              })}
            />
          </div>
        ) : (
          <></>
        )}
        <div>
          {limit ? (
            <>
              <button
                className="upload-button"
                disabled
                onClick={onButtonClick}
              >
                {t('Plan limit reached')}
              </button>
              <p className="small text-secondary mt-3 ms-auto me-auto">
                {t('Monthly limit reached')}
              </p>
            </>
          ) : (
            <>
              <p>{header}</p>
              <button className="upload-button" onClick={onButtonClick}>
                {buttonText}
              </button>
              <p className="small text-secondary mt-3 ms-auto me-auto">
                Allowed formats:
                <br />
                csv, zip, txt, sav, xls, xlsx, xlsm, xlsb, odf, ods or odt
              </p>
            </>
          )}
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      {concern && (
        <div className="mt-2 text-secondary">
          <span>
            Concerned about privacy when uploading your data? Secure it by
            anonymizing{' '}
            <a
              href="https://anonymizer.nextbrain.ai/"
              rel="noreferrer"
              target="_blank"
            >
              here
            </a>{' '}
            first.
          </span>
        </div>
      )}
    </form>
  )
}
