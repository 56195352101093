import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'

import $ from 'jquery'
import { useQuery } from 'react-query'
import { getROCAUCCurve } from '../../../services/model'
import { useAuth } from '../../../providers/AuthProvider'
import NextbrainSelect from '../../model-content/NextbrainSelect'
import { ResponsiveLine } from '@nivo/line'

export function ConfigRocAUCurve({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.roc-auc-title').val(),
    graphFontSize: Number.parseInt($('.roc-auc-graph-font-size').val()) ?? 12,
    requests: [],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="roc-auc-title"
            defaultValue={`${config.title ?? 'ROC Curve'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="roc-auc-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function Curve({ model, config, curveData }) {
  const graphData = useMemo(() => {
    if (curveData) {
      const points = curveData.fpr.length
      const generateX = (d) => d / (points - 1)
      const result = []
      result.push({
        id: 'Random classifier',
        color: 'red',
        data: curveData.fpr.map((d, i) => ({
          x: generateX(i),
          y: generateX(i),
        })),
      })
      result.push({
        id: 'Model ROC Curve',
        color: 'var(--nextbrain-main-color)',
        data: curveData.fpr.map((d, i) => ({
          x: generateX(i),
          y: curveData.tpr[i],
        })),
      })
      return result
    }

    return []
  }, [curveData])

  return (
    <ResponsiveLine
      data={graphData}
      margin={{ top: 10, right: 10, bottom: 70, left: 60 }}
      axisTop={null}
      axisRight={null}
      yFormat={' >-.2f'}
      xFormat={' >-.2f'}
      axisBottom={{
        orient: 'bottom',
        tickSize: 2,
        tickPadding: 5,
        legendOffset: 40,
        legendPosition: 'middle',
        format: (() => {
          let visited = new Set()
          return (v) => {
            const val = `${v}`.slice(0, 3)
            if (val === '0' && visited.has('0.0')) return ''
            if (val === '0.0' && visited.has('0')) return ''
            console.log(val)
            if (visited.has(val)) return ''
            visited.add(val)
            if (val === '1') visited = new Set()
            return `${val}`
          }
        })(),
        tickRotation: -45,
        legend: 'False positive rate',
      }}
      curve={'monotoneY'}
      axisLeft={{
        orient: 'left',
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        format: ' >-.1f',
        legend: 'True positive rate',
        legendOffset: -50,
        legendPosition: 'middle',
      }}
      enableGridX={false}
      enableGridY={false}
      colors={(d) => d.color}
      enableArea={false}
      enablePoints={false}
      enableSlices="x"
      lineWidth={2}
      legends={[]}
      theme={{
        fontSize: '13px',
        textColor: '#ADBAC7',
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        ({ xScale, yScale }) => {
          return (
            <polyline
              points={graphData[1].data
                .map((d) => `${xScale(d.x)} ${yScale(d.y)}`)
                .join(' ')}
              fill={'var(--nextbrain-main-color-25)'}
              strokeWidth="0"
            />
          )
        },
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
      ]}
    />
  )
}

export function WidgetRocAUCurve({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  const { token, signout } = useAuth()

  const { data: curveData, isLoading } = useQuery(
    ['roc-auc-curve', model?.id],
    async () => {
      if (!model?.id) return null
      return await getROCAUCCurve({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const curveOptions = useMemo(() => {
    if (!curveData) return []
    return Object.keys(curveData).map((d) => ({
      value: d,
      label: d,
    }))
  }, [curveData])

  const [selectedKey, setSelectedKey] = useState(null)

  useEffect(() => {
    if (!selectedKey && curveOptions.length > 0) {
      setSelectedKey(curveOptions[0])
    }
    // eslint-disable-next-line
  }, [curveOptions])

  if (!model || !config || isLoading) return <>Loading...</>
  if (!isLoading && !curveData)
    return (
      <Row>
        <Col className="dflex-center my-4" xs={12}>
          Retrain your model to generate the roc curve
        </Col>
      </Row>
    )

  return (
    <Row className="w-100 h-100" id={id}>
      <Col className="d-inline-block text-truncate widget-title" xs={12}>
        {config.title}
      </Col>
      <Col className="my-2" xs={12}>
        <div
          style={{ maxWidth: '300px', maxHeight: '50px', minHeight: '50px' }}
        >
          <NextbrainSelect
            value={selectedKey}
            onChange={(value) => setSelectedKey(value)}
            options={curveOptions}
            hideSelectedOptions={false}
            isClearable={false}
            placeholder={'Select a column'}
            type={'dark'}
          />
        </div>
      </Col>
      <Col
        xs={12}
        style={{ height: 'calc(100% - 80px)' }}
        className={`header-data-distribution-num-pie`}
      >
        <Curve
          model={model}
          config={config}
          curveData={curveData?.[selectedKey?.value]}
        />
      </Col>
    </Row>
  )
}
