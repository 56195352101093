import { useTranslation } from 'react-i18next'
import { Row, Col, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../loading/LoadingSmall'

function Error({ error }) {
  return (
    <Col className="admin-modal-panel-user mt-2" xs={12}>
      <Row>
        <Col className="d-inline-block text-truncate" xs={4}>
          <Link to={`/user/?userId=${error?.user?.id}&from=dashboard`}>
            <span
              style={{ color: 'var(--nextbrain-tables-blue-graph-bar-color)' }}
            >
              {error?.user?.email}
            </span>
          </Link>
        </Col>
        <Col className="d-inline-block text-truncate" xs={4}>
          {error?.action?.action_type}
        </Col>
        <Col className="d-inline-block text-truncate" xs={4}>
          {error?.action?.action_from}
        </Col>
      </Row>
    </Col>
  )
}

export default function ErrorList({ errors, isLoading, visible, hide }) {
  const { t } = useTranslation()

  return (
    <Modal show={visible} onHide={hide} size={'xl'}>
      <Modal.Header>
        <span className="h3">{t('Errors')}</span>
      </Modal.Header>
      <Modal.Body>
        {visible &&
          (isLoading ? (
            <Loading />
          ) : (
            <Row style={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Col xs={12}>
                <Row>
                  <Col className="d-inline-block text-truncate" xs={4}>
                    <span className="small">Email</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={4}>
                    <span className="small">Action</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={4}>
                    <span className="small">Source</span>
                  </Col>
                </Row>
              </Col>
              {Array.isArray(errors) &&
                errors.map((error, i) => <Error key={i} error={error} />)}
            </Row>
          ))}
      </Modal.Body>
    </Modal>
  )
}
