import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import Loading from '../loading/LoadingSmall'
import CountrySelector from './CountrySelector'
import './pricing.css'
import {
  getTaxIdTypesByCountry,
  getCustomerBillingInfo,
  updateCustomerBillingInfo,
} from '../../services/pricing'
import { useAuth } from '../../providers/AuthProvider'

export default function CheckoutForm({ ...props }) {
  const { t } = useTranslation()
  const { signout, token, user, cookies } = useAuth()

  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')

  const { data: countries } = useQuery('countries', getTaxIdTypesByCountry, {
    staleTime: Infinity,
  })

  const { data: billingInfo, status: billingInfoStatus } = useQuery(
    ['billingInfo', token],
    () => getCustomerBillingInfo({ token, signout }),
    { staleTime: 60 * 1000 },
  )

  useEffect(() => {
    if (!stripe || !cookies.payment) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe, cookies])

  useEffect(() => {
    if (billingInfoStatus === 'success') {
      setSelectedCountry(billingInfo?.address?.country)
    }
  }, [billingInfoStatus, billingInfo])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements || !cookies.payment) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    // Before confirming the payment, update the billing details
    // with the user's input.
    const billingInfo = {
      name: e.target.billingName.value,
      address: {
        country: e.target.country.value,
        city: e.target.billingCity.value,
        state: e.target.billingState.value,
        postal_code: e.target.billingPostalCode.value,
        line1: e.target.billingAddressLine1.value,
        line2: e.target.billingAddressLine2.value,
      },
      tax_type: e.target?.taxType?.value,
      tax_id: e.target?.taxId?.value,
    }

    await updateCustomerBillingInfo({ token, signout, billingInfo })

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.protocol + '//' + window.location.host,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  const paymentElementOptions = {
    layout: 'tabs',
  }

  if (user.companies.filter((c) => c.role === 'owner').length === 0) {
    return (
      <Row className="dark-card mx-2 my-3">
        <Col md={12} className="mx-3 my-4">
          <h3 className="my-2">{t('Cannot access billing info')}</h3>
          <p className="my-2">
            {t(
              "You cannot change the plan since you are not the company's owner.",
            )}
            <br />
            {t('Contact with him or her if you want to modify your plan.')}
          </p>
        </Col>
      </Row>
    )
  }

  if (billingInfoStatus === 'loading') {
    return <Loading />
  }

  return (
    <Form id="payment-form" onSubmit={handleSubmit} {...props}>
      <h2>{t('Company details')}</h2>
      <Form.Group controlId="billingName">
        <Form.Label>
          {t('Billing Company / name')} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          defaultValue={billingInfo?.name}
          name="billingName"
          className="mb-2"
          type="text"
          placeholder={t('Enter your company name')}
        />
      </Form.Group>

      <Row className="mt-1">
        <Col md={6} style={{ paddingRight: 5 }}>
          <CountrySelector
            countries={countries}
            country={selectedCountry}
            setCountry={setSelectedCountry}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 5 }}>
          <Form.Group controlId="billingCity">
            <Form.Label>
              {t('City')} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              defaultValue={billingInfo?.address?.city}
              name="billingCity"
              className="mb-2"
              type="text"
              placeholder={t('Enter your city')}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={6} style={{ paddingRight: 5 }}>
          <Form.Group controlId="billingState">
            <Form.Label>
              {t('State')} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              defaultValue={billingInfo?.address?.state}
              name="billingState"
              className="mb-2"
              type="text"
              placeholder={t('Enter your state')}
            />
          </Form.Group>
        </Col>
        <Col md={6} style={{ paddingLeft: 5 }}>
          <Form.Group controlId="billingPostalCode">
            <Form.Label>
              {t('Postal code')} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              defaultValue={billingInfo?.address?.postal_code}
              name="billingPostalCode"
              className="mb-2"
              type="text"
              placeholder={t('Enter your postal code')}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={6} style={{ paddingRight: 5 }}>
          <Form.Group controlId="billingAddressLine1">
            <Form.Label>
              {t('Address line')} 1 <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              defaultValue={billingInfo?.address?.line1}
              name="billingAddressLine1"
              className="mb-2"
              type="text"
              placeholder={`${t('Enter your Address line')} 1`}
            />
          </Form.Group>
        </Col>
        <Col md={6} style={{ paddingLeft: 5 }}>
          <Form.Group controlId="billingAddressLine2">
            <Form.Label>{t('Address line')} 2</Form.Label>
            <Form.Control
              defaultValue={billingInfo?.address?.line2}
              name="billingAddressLine2"
              className="mb-2"
              type="text"
              placeholder={`${t('Enter your Address line')} 2`}
            />
          </Form.Group>
        </Col>
      </Row>

      {countries &&
      countries[selectedCountry] &&
      countries[selectedCountry].tax_id_types.length ? (
        <Row className="mt-1">
          <Col md={6} style={{ paddingRight: 5 }}>
            <Form.Group>
              <Form.Label>
                {t('Tax Type')} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                defaultValue={billingInfo?.tax_type ?? ''}
                name="taxType"
                autoComplete="tax type"
                {...props}
              >
                <option value="">{t('Select')}</option>
                {countries[selectedCountry].tax_id_types.map((tax) => (
                  <option key={tax.tax_id} value={tax.tax_id}>
                    {tax.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} style={{ paddingLeft: 5 }}>
            <Form.Group controlId="taxId">
              <Form.Label>
                {t('Tax ID (VAT Number)')}{' '}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                defaultValue={billingInfo?.tax_id}
                name="taxId"
                className="mb-2"
                type="text"
                placeholder={t('Enter your TAX ID (VAT Number)')}
              />
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <p className="small text-secondary text-center w-100">
        If you do not fill in all the mandatory{' '}
        <span className="text-danger">*</span> company details, a{' '}
        <strong style={{ color: 'white' }}>21% TAX (VAT)</strong> will be added
        to your payment.
        <br />
        If you are a company with a valid VAT/CIF number, you can deduct the VAT
        from your invoice.
      </p>

      <h2 className="mt-2">{t('Payment')}</h2>
      {cookies?.payment && (
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      )}
      <Button
        className="action-button w-100 my-3"
        disabled={isLoading || !stripe || !elements || !cookies.payment}
        id="submit"
        type="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            t('Suscribe now')
          )}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-center mb-3">
          {message}
        </div>
      )}
      <p
        className="small text-secondary w-100 text-center"
        style={{ marginTop: -10 }}
      >
        {t('Payments powered by')}{' '}
        <span style={{ color: 'var(--nextbrain-white-font)' }}>Stripe</span>
      </p>
      <div className="mb-3"></div>
    </Form>
  )
}
