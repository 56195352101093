import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../providers/AuthProvider'
import { v4 } from 'uuid'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import { InlineEdit } from '../../inline-edit/InlineEdit'
import { datasourceConfig } from './config'
import { useProps } from '../../../providers/PropsProvider'
import InvalidWarning from './InvalidWarning'
import { BiLoaderAlt } from 'react-icons/bi'

function SelectDatasource({ type, node, position, children }) {
  const { t } = useTranslation()
  const { createNewNode, setNodes } = useProps()

  if (node && type === 'Config') {
    if (node && !node.id) node.id = `datasourceflow-context-menu-${v4()}`

    return (
      <div className="dataflow-config-wrapper cursor-pointer position-relative">
        <ContextMenuTrigger
          id={node.id}
          mouseButton={0}
          disableIfShiftIsPressed={true}
        >
          {children}
        </ContextMenuTrigger>
        <ContextMenu
          id={node.id}
          className="menu-context-dataflow config-forced-context-menu"
        >
          {Object.keys(datasourceConfig)
            .filter((k) => k !== 'Config')
            .map((key) => (
              <MenuItem key={key}>
                <div
                  className="d-inline-block text-nowrap py-2 h5 mx-3"
                  onClick={() => {
                    setNodes((p) => p.filter((n) => n?.data?.id !== node.id))
                    createNewNode({
                      type: key,
                      position: position,
                      replacedNode: node,
                      forceActive: true,
                    })
                  }}
                >
                  {datasourceConfig[key].iconSmall}
                  {t(key)}
                </div>
              </MenuItem>
            ))}
        </ContextMenu>
      </div>
    )
  }

  return children
}

function Title({ type, node }) {
  const { t } = useTranslation()
  if (type === 'Upload local file' && node.valid) return t('File uploaded')

  return t(type)
}

export function Template({ id, type, node, position, children, ...props }) {
  const source = datasourceConfig[type]
  const customSubtitle = node?.userText ?? node?.subtitle
  const { t } = useTranslation()
  const isPlaceholder = node?.placeholderNode
  return (
    <ContextMenuTrigger
      id={`context-menu-node-${id}`}
      disableIfShiftIsPressed={true}
      onContextMenu={(e) => {
        e.stopPropagation()
      }}
    >
      <Row
        {...props}
        className={`datasource-template position-relative justify-content-center align-items-center  d-flex datasource-template-${type} ${
          props.className ?? ''
        } ${isPlaceholder ? 'placeholder-node' : ''}`}
        onClick={() => {
          if (type === 'Config') return
          node.controls.onUpdate({ ...node, active: true })
        }}
      >
        <SelectDatasource type={type} node={node} position={position}>
          {isPlaceholder ? (
            <BiLoaderAlt className="rotating-2" size={55} />
          ) : (
            source.icon
          )}
          {children}
        </SelectDatasource>
        <InvalidWarning node={node} />

        <ContextMenu
          id={`context-menu-node-${id}`}
          className="menu-context-dataflow config-forced-context-menu-node px-0"
          onClick={(e) => e.stopPropagation()}
        >
          <MenuItem className="px-1">
            <div
              className="d-flex flex-nowrap text-nowrap"
              onClick={(e) => {
                e.stopPropagation()
                node?.controls?.onDelete()
              }}
            >
              {t('Delete node')}
            </div>
          </MenuItem>
        </ContextMenu>
        {type !== 'Config' && (
          <div className="datasource-template-context">
            <Row>
              <Col
                className="d-flex flex-nowrap text-nowrap justify-content-center"
                xs={12}
              >
                <Title type={type} node={node} />
              </Col>
              <Col
                align="center"
                xs={12}
                className="position-relative d-flex flex-nowrap justify-content-center text-center text-nowrap"
                style={{
                  maxWidth: '400px',
                  minWidth: '50px',
                  cursor: customSubtitle ? 'text' : 'grab',
                }}
              >
                {customSubtitle ? (
                  <InlineEdit
                    onEdit={(value) => {
                      node.userText = value
                    }}
                    text={customSubtitle}
                    key={customSubtitle}
                    className="d-inline-block text-truncate"
                    style={{ maxWidth: '100%' }}
                  />
                ) : (
                  t(source.subtext)
                )}
              </Col>
              <Col
                className="d-flex justify-content-center align-items-start"
                xs={12}
              >
                <span className="invalid-span">
                  ({t('Click to configure')})
                </span>
              </Col>
            </Row>
          </div>
        )}
      </Row>
    </ContextMenuTrigger>
  )
}

export default function DatasourceConnectors({ create_node_fn, ...props }) {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
    const rect = event.target.getBoundingClientRect()
    event.dataTransfer.setData('dragXOffset', event.clientX - rect.left)
    event.dataTransfer.setData('dragYOffset', event.clientY - rect.top)
  }

  const { subscriptions } = useAuth()
  return (
    <Row {...props}>
      {Object.entries(datasourceConfig).map(([k, v]) => (
        <Col
          draggable
          key={k}
          className={`mt-2 ${
            Array.isArray(v.requires) &&
            !v.requires.reduce((ac, k) => ac && subscriptions[k], true)
              ? 'disabled-feature'
              : ''
          }`}
          xs={12}
          onDragStart={(event) => onDragStart(event, k)}
          onClick={() => create_node_fn(k, {})}
        >
          <Template type={k} />
        </Col>
      ))}
    </Row>
  )
}
