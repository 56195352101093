import { useMemo, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { checkChangeType } from '../../../../services/csv'
import { useAuth } from '../../../../providers/AuthProvider'
import { awaitTaskCall } from '../../../../services/base'
import Loading from '../../../loading/LoadingSmall'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import TableError from './TableError'
import NextbrainSelect from '../../NextbrainSelect'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'

const columnTypeOptions = [
  { label: 'default', value: 'default' },
  { label: 'number', value: 'number' },
  { label: 'text', value: 'text' },
  { label: 'category', value: 'category' },
  { label: 'date', value: 'date' },
]

export const typeMappingToBackend = {
  number: 'number',
  text: 'string',
  date: 'date',
  category: 'category',
}

function ColumnType(column, width, typeMapping, setTypeMapping) {
  const value = typeMapping?.[column] ?? columnTypeOptions[0].value
  return (
    <Row className="h-100" style={{ zIndex: 10 }}>
      <Col xs={12}>
        <div title={column} className="d-inline-block text-truncate">
          {column}
        </div>
      </Col>
      <Col className="mt-2" xs={12}>
        <NextbrainSelect
          value={{ label: value, value: value }}
          onChange={(value) =>
            setTypeMapping({ ...typeMapping, [column]: value.value })
          }
          options={columnTypeOptions}
          hideSelectedOptions={false}
          type={'dark'}
        />
      </Col>
    </Row>
  )
}

export default function ChangeTypeTable({
  input,
  typeMapping,
  onDataLoad = () => {},
  onChange = () => {},
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const { isLoading, data } = useQuery(
    ['changeTypeCheck', typeMapping],
    async () => {
      if (!Object.keys(typeMapping).length) return null
      let error = null
      const result = await awaitTaskCall(checkChangeType, 1000, 20000, {
        input: {
          columns: input.columns,
          data: input.sample,
        },
        typeMapping: Object.entries(typeMapping).reduce((acc, [k, v]) => {
          acc[k] = typeMappingToBackend[v]
          return acc
        }, {}),
        token,
        signout,
      }).catch((e) => {
        console.error('Failed to retrieve aggregation for table')
        error = e
      })
      return error || result
    },
    { staleTime: Infinity, retry: false },
  )

  useEffect(() => {
    if (data && !data.error) {
      setError(null)
      onDataLoad({
        columns: data.columns,
        sample: data.data,
      })
    } else {
      setError(data?.error)
      onDataLoad(null)
    }
    // eslint-disable-next-line
  }, [data])

  const tableData = useMemo(() => {
    if (!data || data?.error) return null
    const res = {
      columns: [data.columns],
      data: data.data,
    }
    return res
    // eslint-disable-next-line
  }, [data])

  const defaultData = useMemo(() => {
    if (!input)
      return {
        columns: [],
        data: [],
      }
    return {
      columns: [input.columns],
      data: input.sample,
    }
  }, [input])

  const ChangeTypeElement = useMemo(() => {
    return (column, width) => ColumnType(column, width, typeMapping, onChange)
    // eslint-disable-next-line
  }, [typeMapping])

  const showData = tableData || defaultData
  return error ? (
    <TableError
      error="Failed to change types with current configuration"
      subtext={error}
    />
  ) : isLoading ? (
    <ColumnPaginatedGridTable
      key="placeholder"
      stableHeight={true}
      placeholder={true}
      headerElement={ChangeTypeElement}
      emptyDatasetMessage={<Loading message={t('Loading preview')} />}
      header={[['.']]}
      rows={Array(10).fill(['.'])}
      rowsPerPage={10}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data table-placeholder h-auto"
      Pager={() => <ReactPaginate className="paginate-view-data" />}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  ) : (
    <ColumnPaginatedGridTable
      key="data"
      stableHeight={true}
      header={showData.columns}
      headerElement={ChangeTypeElement}
      headerElementProps={typeMapping}
      rows={showData.data}
      rowsPerPage={10}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data"
      Pager={({ maxPage, onChange, page }) => (
        <ReactPaginate
          className="paginate-view-data"
          breakLabel="..."
          nextLabel={t('next')}
          onPageChange={({ selected }) => onChange(selected)}
          pageRangeDisplayed={3}
          pageCount={maxPage}
          previousLabel={t('previous')}
          renderOnZeroPageCount={null}
        />
      )}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  )
}
