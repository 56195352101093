import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Image } from 'react-bootstrap'
import './cookies.css'
import CookieConfig from './CookieConfig'
import { useAuth } from '../../providers/AuthProvider'

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export default function CookieBanner() {
  const [showConfig, setShowConfig] = useState(false)
  const { t } = useTranslation()
  const { cookies, setCookies } = useAuth()

  const [localConfig, setLocalconfig] = useState({
    base: true,
    support: false,
    analytics: false,
    payment: false,
  })

  if (cookies?.configured || inIframe()) return null

  return (
    <Row className="cookies-banner p-3 flex-column">
      <Col className="mb-3 d-flex align-items-center" xs={12}>
        <strong className="h3 ms-3 color-white">{t('We use cookies')}</strong>
        <strong
          className="bg-container position-relative d-inline-block"
          style={{ minWidth: '100px', minHeight: '50px' }}
        >
          <Image
            src={'/cookie.svg'}
            className="bg-product-card"
            style={{ filter: 'invert(1)' }}
          />
        </strong>
      </Col>
      <Col className="mt-3" xs={12}>
        <Row className="flex-row">
          <Col
            className="color-white mt-2 "
            lg={showConfig ? 12 : 7}
            xs={12}
            style={{ fontSize: '16px' }}
          >
            {showConfig ? (
              <CookieConfig
                defaultCookies={localConfig}
                onChange={(c) => setLocalconfig((o) => ({ ...o, ...c }))}
              />
            ) : (
              <>
                {t(
                  "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking 'Accept', you consent to the use of ALL the cookies. However you may visit Cookie Settings to provide a controlled consent.",
                )}
              </>
            )}
          </Col>
          <Col className="mt-lg-0 mt-3" lg={showConfig ? 12 : 5} xs={12}>
            <>
              <Row className="justify-content-between mt-3">
                <Col md={12} xl={6} xs={12}>
                  <Button
                    className="w-100 p-2 h-100"
                    onClick={() => {
                      setCookies({
                        configured: true,
                        base: true,
                        support: true,
                        analytics: true,
                        payment: true,
                      })
                    }}
                  >
                    {t('Accept all')}
                  </Button>
                </Col>
                <Col className="mt-xl-0 mt-2" md={12} xl={6} xs={12}>
                  <Button
                    className="w-100 p-2"
                    onClick={() => {
                      setCookies({
                        configured: true,
                        base: true,
                        support: false,
                        analytics: false,
                        payment: false,
                      })
                    }}
                  >
                    {t('Reject all')}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  {showConfig ? (
                    <Button
                      className="w-100 p-2 original"
                      variant="secondary"
                      onClick={() =>
                        setCookies({ configured: true, ...localConfig })
                      }
                    >
                      {t('Accept cookies')}
                    </Button>
                  ) : (
                    <Button
                      className="w-100 p-2 original"
                      variant="secondary"
                      onClick={() => setShowConfig(true)}
                    >
                      {t('Manage cookies individually')}
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
