import React from 'react'
import { Row, Col } from 'react-bootstrap'

import './trialheader.css'

export default function MaintenanceWarning() {
  return (
    <Row className="px-0 mx-0">
      <Col md={12} className="maintenance-warning-header">
        The APP is in maintenance mode. regular users cant access it, you can
        disable maintenance from administration
      </Col>
    </Row>
  )
}
