import React, { useState, useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import {
  HiOutlineClipboardCopy,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import { useQueryClient } from 'react-query'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { revokeToken } from '../../services/user'
import QuestionaireIndustryWrapper from './QuestionaireIndustryWrapper'
import RegisterQuestionaire from '../register-form/RegisterQuestionaire'
import AccountProfile from './AccountProfile'
import AccountActivity from './AccountActivity'
import AccountLevelInd from './AccountLevelInd'

import './Account.css'
import Company from './Company'
import LLMConfig from './LLMConfig'

const EDIT_NONE = 0
const EDIT_INDUSTRY = 1
const EDIT_LEVEL = 2
export const EDIT_TYPES = { EDIT_NONE, EDIT_INDUSTRY, EDIT_LEVEL }

export default function Account({ setTitle = () => {}, ...props }) {
  const { t } = useTranslation()
  const auth = useAuth()
  const { token, signout, user, updateuser, reloadUser } = auth
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialTab = parseInt(searchParams.get('tab') ?? 0)
  const [index, setIndex] = useState(initialTab ?? 0)
  const redirect = searchParams.get('origin_login')
  const [editingWindow, setEditingWindow] = useState(() =>
    redirect ? EDIT_INDUSTRY : EDIT_NONE,
  )
  const queryClient = useQueryClient()

  const userIndustry = (user?.industries ?? []).reduce((dict, k) => {
    dict[k] = true
    return dict
  }, {})

  useEffect(
    () => {
      setTitle('Nextbrain | My Account')
    },
    // eslint-disable-next-line
    [],
  )

  function Token({ ...props }) {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const [revoking, setRevoking] = useState(false)
    return (
      <Row {...props}>
        <Col className="profile-section-separator" xs={12}>
          <strong>{t('Access Tokens')}</strong>
        </Col>
        <Col lg={6} md={4} xs={12} className="mb-3 ">
          {t(
            'Access tokens allow you to programatically access the services of the',
          )}{' '}
          <strong>{t('NextBrain')} API</strong>. <br />
          <br />
          {t('examples can be found in most models in the section')}{' '}
          <strong>
            <u>{t('Predict with API')}</u>
          </strong>
          .
        </Col>
        <Col className="token-box p-3" lg={6} md={8} xs={12}>
          <Row>
            <Col xs={12} className="mb-2">
              <strong> {t('Current token')}</strong>
            </Col>
            <Col
              style={{ minWidth: '100%' }}
              className="position-relative mb-3"
            >
              {show ? (
                <input
                  className="w-100 token-input"
                  onChange={() => {}}
                  value={user.access_token}
                />
              ) : (
                <input
                  className="w-100 token-input"
                  type="password"
                  onChange={() => {}}
                  value={user.access_token}
                />
              )}
              <Button
                onClick={() => {
                  setShow((s) => !s)
                }}
                className="inside-input-button"
                style={{ right: '39px' }}
              >
                {show ? (
                  <HiOutlineEye size={20} />
                ) : (
                  <HiOutlineEyeOff size={20} />
                )}
              </Button>
              <Button
                onClick={() => {
                  if (navigator?.clipboard?.writeText) {
                    NotificationManager.info(t('Copied to clipboard'))
                    navigator.clipboard.writeText(user.access_token)
                  } else NotificationManager.error('Clipboard not supported')
                }}
                className="inside-input-button"
                style={{ right: '12px' }}
              >
                <HiOutlineClipboardCopy size={20} />
              </Button>
            </Col>
            <Col xs={12} />
            <Col xs={12} md={6} lg={4}>
              <Button
                className="original w-100"
                variant="danger"
                disabled={revoking}
                onClick={async () => {
                  setRevoking(true)
                  revokeToken(token, signout).then((d) => {
                    reloadUser()
                      .then((d) => {
                        if (d) NotificationManager.success(t('Token revoked'))
                        else
                          NotificationManager.error(t('Error revoking token'))
                      })
                      .finally((_) => setRevoking(false))
                  })
                }}
              >
                {t('Revoke token')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const onSelectIndex = (newIndex) => {
    setIndex(newIndex)
    searchParams.tab = newIndex
    setSearchParams({ ...searchParams })
  }
  let content
  switch (editingWindow) {
    case EDIT_INDUSTRY:
      content = (
        <QuestionaireIndustryWrapper
          industry={userIndustry}
          redirect={redirect}
          onFinish={(userIndustry) => {
            if (redirect) navigate(redirect)
            updateuser({
              industries: Object.keys(userIndustry).filter(
                (k) => userIndustry[k] && k !== 'undefined',
              ),
            })
              .then((d) => {
                if (!d)
                  NotificationManager.error(t('Failed to update industry'))
                else {
                  NotificationManager.info(t('Updated industries'))
                  queryClient.invalidateQueries(`workspace-${token}`)
                }
              })
              .finally(() => {})
            setEditingWindow(EDIT_NONE)
          }}
          className="questionaire-industry-account"
        />
      )
      break
    case EDIT_LEVEL:
      content = (
        <RegisterQuestionaire
          className="mx-5 mt-3"
          finishButton="Finish"
          onExit={async (level) => {
            if (level)
              if (await updateuser({ ml_level: level })) {
                NotificationManager.info(
                  `${t('Your machine learning level is')} ${level}`,
                )
              } else {
                NotificationManager.error(
                  `Failed to update machine learning level.`,
                )
              }
            setEditingWindow(EDIT_NONE)
          }}
        />
      )
      break
    default:
      content = (
        <>
          <Tabs selectedIndex={index} onSelect={onSelectIndex}>
            <Row
              {...props}
              className={`g-0 ${props.className ?? ''} justify-content-center`}
            >
              <Col xs={12} md={10} xl={9}>
                <TabList className="tabs-profile ps-0">
                  <Row className="justify-content-center">
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(0)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('Profile')}
                        >
                          {t('Profile')}
                        </span>
                      </Tab>
                    </Col>
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(1)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('Company')}
                        >
                          {t('Company')}
                        </span>
                      </Tab>
                    </Col>
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(2)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('Activity')}
                        >
                          {t('Activity')}
                        </span>
                      </Tab>
                    </Col>
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(3)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('Level/Industries')}
                        >
                          {t('Level/Industries')}
                        </span>
                      </Tab>
                    </Col>
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(4)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('Tokens')}
                        >
                          {t('Tokens')}
                        </span>
                      </Tab>
                    </Col>
                    <Col className="px-0" xs={2}>
                      <Tab
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSelectIndex(5)
                        }}
                      >
                        <span
                          className="d-inline-block text-truncate w-100"
                          title={t('LLM')}
                        >
                          {t('LLM')}
                        </span>
                      </Tab>
                    </Col>
                  </Row>
                </TabList>
              </Col>
              <Col
                className="account-content-column account-content-column-users px-5 mb-3"
                xs={12}
                md={10}
                xl={9}
              >
                <TabPanel>
                  <AccountProfile auth={auth} />
                </TabPanel>
                <TabPanel>
                  <Company />
                </TabPanel>
                <TabPanel>
                  <AccountActivity token={token} user={user} />
                </TabPanel>
                <TabPanel>
                  <AccountLevelInd
                    auth={auth}
                    setEditingWindow={setEditingWindow}
                  />
                </TabPanel>
                <TabPanel>
                  <Row
                    className="justify-content-lg-end mx-3"
                    style={{ marginBottom: '100px' }}
                  >
                    <Col xs={12} className="">
                      <Token />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row
                    className="justify-content-lg-end mx-3"
                    style={{ marginBottom: '100px' }}
                  >
                    <Col className="mt-3" xs={12}>
                      <LLMConfig />
                    </Col>
                  </Row>
                </TabPanel>
              </Col>
            </Row>
          </Tabs>
        </>
      )
      break
  }

  useEffect(() => {
    if (searchParams.get('tab')) {
      setIndex(parseInt(searchParams.get('tab')) || 0)
    }
  }, [searchParams])

  return content
}
