import React from 'react'
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './ProgressTabs.css'
import { useTranslation } from 'react-i18next'
import { MdWarning } from 'react-icons/md'

function Tag({
  name = 'nameless',
  active,
  index,
  children,
  disabled,
  disabledMessage,
  ...props
}) {
  const { t } = useTranslation()

  const element = (
    <Col
      className={`progress-tabs-step position-relative ${
        disabled ? 'disabled-step' : ''
      } mt-1 ${active ? 'step-active' : ''} step-name-${name}`}
      xs="auto"
      {...props}
    >
      <div className="body-fill"></div>
      <div className="border-marker"></div>
      <div className="edge-marker"></div>
      <div className="edge-marker-back"></div>
      {/* <span className="progress-tabs-ball rounded-circle">{index + 1}</span> */}
      <span className="me-3 ms-2">{children}</span>
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="bottom"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <div className="h5 p-2 mb-0">
              {t(
                'Your dataset has changed and the current training does not reflect changes in your data',
              )}
            </div>
          </Tooltip>
        )}
      >
        <div className="warning-retrain">
          <MdWarning color={'var(--nextbrain-warning-color)'} />
        </div>
      </OverlayTrigger>
    </Col>
  )

  return disabled ? (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip {...props}>
          <div className="h5 p-2 mb-0">{t(disabledMessage)}</div>
        </Tooltip>
      )}
    >
      {element}
    </OverlayTrigger>
  ) : (
    element
  )
}

export default function ProgressTabs({
  steps = ['My data', 'Explore', 'Apps', 'Dashboard'],
  disabled = [],
  currentStep = 0,
  disabledMessage = 'Train your model first',
  setStep = () => {},
  ...props
}) {
  currentStep = Math.min(Math.max(0, currentStep), steps.length - 1)
  const { t } = useTranslation()

  return (
    <Row
      {...props}
      className={`${
        props?.className ?? ''
      } justify-content-center d-inline-flex overflow-visible w-100`}
    >
      {steps.map((step, i) => (
        <Tag
          name={step}
          disabledMessage={disabledMessage}
          disabled={disabled[i]}
          index={i}
          active={i === currentStep}
          key={i}
          onClick={() => !disabled?.[i] && setStep(i)}
        >
          {step.includes('\n')
            ? step.split('\n').map((line, i) => (
                <span
                  style={i === 0 ? { marginTop: -33, display: 'block' } : {}}
                  key={i}
                >
                  {t(line)}
                </span>
              ))
            : t(step)}
        </Tag>
      ))}
    </Row>
  )
}
