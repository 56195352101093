import React, { useState, useEffect, useRef } from 'react'
import { Button, Offcanvas, Col, Row, Form, Modal } from 'react-bootstrap'
import {
  FaPlus,
  FaTrash,
  FaRegEdit,
  FaSave,
  FaCamera,
  FaFileDownload,
} from 'react-icons/fa'
import { WidthProvider, Responsive } from 'react-grid-layout'
import { useParams, useSearchParams, Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { NotificationManager } from 'react-notifications'

import DashboardIntroScreen from '../widget-report/DashboardIntroScreen'
import MenuCreateWidget from './MenuCreateWidget'
import { getModelById } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { WidgetStorytelling, createHeader } from './Widgets/Storytelling'
import { widgetsMap } from './MenuCreateWidget'
import { getTemplate } from './ModelTemplate'
import {
  editModelWidgetReport,
  getDistribution,
  getForecastPerformance,
  editPrivacy,
  getForecastDataTrend,
  getForecastWeeklyTrend,
  getForecastYearlyTrend,
} from '../../services/model'
import { getHead } from '../../services/model'
import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import './WidgetReport.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import useStoreState from '../../hooks/UseStoreState'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../providers/NavProvider'
import { getGoalVisualizations } from '../../services/lida'

const ReactGridLayout = WidthProvider(Responsive)

const breakpointOrder = ['xl', 'lg', 'md', 'sm', 'xs', 'xxs']
const breakpoints = {
  xl: 1800,
  lg: 1800,
  md: 1800,
  sm: 1800,
  xs: 1800,
  xxs: 1800,
}
const cols = { xl: 12, lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
const fitLayout = (l) =>
  Object.keys(cols).reduce((dict, k) => {
    dict[k] = l
    return dict
  }, {})
const width2break = (w) => {
  for (const i of breakpointOrder) {
    if (w > breakpoints[i]) return i
  }
  return ''
}
const handlePNGDownload = (board) => {
  const element = document.getElementById(board.help)
  const firstBackgroundColor = element.style.backgroundColor
  const firstColor = element.style.color
  const firstFill = element.style.fill
  element.style.setProperty('--graph-grid-color', 'black')
  element.style.backgroundColor = 'white'
  element.style.fill = 'black'
  html2canvas(element, {
    backgroundColor: getComputedStyle(element).getPropertyValue(
      '--nextbrain-background',
    ),
  }).then(function (canvas) {
    // Get the data URL of the canvas
    var dataURL = canvas.toDataURL('image/png')

    // Create a link element to trigger the download
    var link = document.createElement('a')
    link.download = `${board.help}.png`
    link.href = dataURL
    link.click()
  })
  element.style.backgroundColor = firstBackgroundColor
  element.style.color = firstColor
  element.style.fill = firstFill
  element.style.setProperty('--graph-grid-color', 'unset')
}
const handleCSVDownload = (board) => {
  let csvData,
    element = document.getElementById(board.help)
  if (!element.classList.contains('data-holder'))
    element = element.querySelector('.data-holder')
  if (
    element &&
    (csvData = JSON.parse(decodeURIComponent(element.dataset.csv))) &&
    csvData.length > 0
  ) {
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      csvData.map((x) => x.join(',')).join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${element.dataset.filename}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } else {
    NotificationManager.error('No data to download')
  }
}

// eslint-disable-next-line
function serializeTemplateReport(report) {
  const nulled = (id) => (id === 10 ? { editor: '' } : {})
  return report.map((e) => ({ ...e.config, ...nulled(e.config.id) }))
}

function deserializeTemplateReport(model, LIDAGraphs) {
  const template = getTemplate(model, LIDAGraphs)
  if (template) {
    return template.map((e, i) => {
      if (e.config) e = e.config
      const r = {
        config: e,
        widget: widgetsMap[e.id].widget,
        help: widgetsMap[e.id].help,
        key: e.layout.i,
      }
      if (e.id === 10 && e.mode === 'gt') r.config.editor = createHeader(model)

      return r
    })
  }

  return null
}

export default function WidgetReport({ setTitle, hideNav = false, ...props }) {
  const { token, signout, llmDisabled } = useAuth()
  const [model, setModel] = useState(null)
  const [elements, _setElements] = useState([])
  const [requestedData, setRequestedData] = useState({})
  // eslint-disable-next-line
  const [isPrivateReport, setIsPrivateReport] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [firstVisitDashboard, setFirstVisitDashboard] = useStoreState({
    key: 'dashboard-first-visit',
  })
  const [savingReport, setSavingReport] = useState(0)
  const { t } = useTranslation()
  const widgetContainerRef = useRef()
  const { setShowNav } = useNav()
  useEffect(() => {
    setShowNav(false)
    return () => setShowNav(true)
    // eslint-disable-next-line
  }, [])
  const [searchParams] = useSearchParams()
  const readOnly = searchParams?.get('readOnly')?.toLowerCase() === 'true'

  const isEditable = !!token && !readOnly
  const saveReport = useDebouncedCallback(async () => {
    if (model && token) {
      setSavingReport((s) => s + 1)
      editModelWidgetReport({
        modelId: model.id,
        widgets_report: {
          data: elements,
          breakpoint: width2break(window.outerWidth),
        },
        token,
        signout,
        rawResponse: true,
      }).finally(() => setSavingReport((s) => s - 1))
    }
  }, 4000)

  const setElements = (elements) => {
    _setElements(elements)
    saveReport(elements)
    window.dispatchEvent(new Event('resize'))
  }

  const params = useParams()

  const createKeygen = (n = 0) => {
    let k = n
    return (n = true) => (n ? k++ : k)
  }

  const [keygen, setKeygen] = useState(createKeygen)

  const [showMenuNew, setShowMenuNew] = useState(false)
  const finishWidget = (widget, config, editting, help) => {
    setShowMenuNew(false)
    if (config) {
      if (showMenuNew.config && editting) {
        showMenuNew.config = config
        setElements([...elements])
      } else {
        const key = `${keygen()}`
        config.layout.i = key
        let xmax = 0,
          ymax = 0
        elements.forEach((i) => {
          xmax = Math.max(xmax, i.config.layout.x)
          ymax = Math.max(ymax, i.config.layout.y)
        })
        // config.layout.x = xmax + 1;
        // config.layout.y = ymax + 1;
        setElements([...elements, { key, config, widget, help }])
      }
    }
  }

  const updateLayout = (newLayout) => {
    newLayout.forEach((l) => {
      const el = elements.find((e) => e?.config?.layout?.i === l?.i)
      if (el?.config) el.config.layout = l
    })
    setElements([...elements])
  }
  useEffect(() => {
    if (setTitle) {
      setTitle('Widget report')
    }
    getModelById(params.id, token, signout)
      .then(async (d) => {
        if (!d) {
          setModel('error')
          return
        }

        setModel(d)
        setIsPrivateReport(d.is_private_report)
        if (d.widgets_report === '') d.widgets_report = null

        const report = JSON.parse(d?.widgets_report ?? '{"data":[]}')
        if (report?.data?.length) {
          const newElements = report.data.map((e) => {
            e.widget = widgetsMap[e.config.id].widget
            e.help = widgetsMap[e.config.id].help
            return e
          })
          let mt = 0
          newElements.forEach((e) => {
            mt = Math.max(
              mt,
              Number.parseInt(e.key) ? Number.parseInt(e.key) : 0,
            )
          })
          setKeygen(() => createKeygen(mt + 1))
          setElements(newElements)
        } else {
          const customGraphs = await getGoalVisualizations({
            modelId: d?.id,
            token,
            signout,
          })
          const template = deserializeTemplateReport(d, customGraphs?.data)
          if (template) {
            let mt = 0
            template.forEach((e) => {
              mt = Math.max(
                mt,
                Number.parseInt(e.key) ? Number.parseInt(e.key) : 0,
              )
            })
            setKeygen(() => createKeygen(mt + 1))
            setElements(template)
          } else
            setElements([
              {
                key: 'start',
                widget: WidgetStorytelling,
                config: {
                  layout: { x: 0, y: 0, w: 12, h: 4, i: 'start' },
                  editor: createHeader(d),
                  mode: 'gt',
                  id: 10,
                  name: 'Storytelling',
                },
              },
            ])
        }
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      if (!model) return

      const requests = []
      elements.forEach((e) => {
        if (Array.isArray(e.config.requests) && e.config.requests.length) {
          e.config.requests.forEach((r) => {
            if (!requestedData[r]) {
              requestedData[r] = 'loading'
              requests.push(r)
            }
          })
        }
      })

      if (requests.size) setRequestedData((r) => ({ ...r }))

      requests.forEach((request) => {
        const params = request.split('$$$')
        let r = params[0]
        switch (r) {
          case 'sample':
            getHead({
              modelId: model.id,
              pageSize: 100,
              pageNumber: 1,
              token,
              signout,
            }).then((d) =>
              setRequestedData((rd) => ({ ...rd, sample: d ?? 'error' })),
            )
            break
          case 'correlation':
            getDistribution({
              modelId: model.id,
              first_column: params[1],
              second_column: params[2],
              samples: params[3],
              token,
              signout,
            }).then((d) => {
              setRequestedData((rd) => ({ ...rd, [request]: d }))
            })
            break
          case 'horizon':
            getForecastPerformance({
              modelId: model.id,
              token,
              signout,
            }).then((d) => {
              setRequestedData((rd) => ({ ...rd, horizon: d }))
            })
            break
          case 'dataTrendPoints':
            getForecastDataTrend({
              modelId: model.id,
              token,
              signout,
            }).then((d) => {
              setRequestedData((rd) => ({ ...rd, dataTrendPoints: d }))
            })
            break
          case 'weeklyTrendPoints':
            getForecastWeeklyTrend({
              modelId: model.id,
              token,
              signout,
            }).then((d) => {
              setRequestedData((rd) => ({ ...rd, weeklyTrendPoints: d }))
            })
            break
          case 'yearlyTrendPoints':
            getForecastYearlyTrend({
              modelId: model.id,
              token,
              signout,
            }).then((d) => {
              setRequestedData((rd) => ({ ...rd, yearlyTrendPoints: d }))
            })
            break
          default:
            console.log(`unknown request in widget report ${r}`)
            break
        }
      })
    },
    // eslint-disable-next-line
    [elements, model],
  )

  return isLoading ? (
    <LoadingModel shortMsg="Loading Report" />
  ) : model === 'error' ? (
    <ModelNotFound />
  ) : (
    <>
      <Row className="justify-content-end align-items-start no-print">
        <Col
          style={{ minWidth: 'calc(100% - 300px)' }}
          className="ms-3 pe-0 my-1 "
        >
          {hideNav ? (
            <></>
          ) : (
            <Row>
              <div className="col-auto d-inline-block text-truncate mt-2 hover-link cursor-pointer">
                <Link to={-1}>
                  <div
                    style={{
                      cursor: 'pointer',
                      fontSize: '24px',
                      color: 'var(--nextbrain-white-font)',
                    }}
                  >
                    <i className="fas fa-angle-left fa-lg"></i>
                    <strong className="ml-2">
                      {' ' + (model ? model.dataset.name : '')}
                    </strong>
                  </div>
                </Link>
              </div>
            </Row>
          )}
          <Row className="align-items-center  mt-2">
            <Col xs={'auto'} className="h3 ">
              <strong className="d-inline-block text-truncate">
                Model Dashboard
              </strong>
            </Col>
            <Col
              xs={'auto'}
              className={`save-report  ${savingReport ? 'fade-save' : ''}`}
              style={{ minHeight: '25px' }}
            >
              <span className={`me-2 `}>Saving report</span>
              <FaSave className="saving-icon-report" />
            </Col>
          </Row>
        </Col>
        <Col className="ps-0 mt-2" style={{ maxWidth: '400px' }}>
          {isEditable && (
            <Row className="controls-widget-report">
              <Col xs={12}>
                <Form>
                  <Form.Group className="my-2">
                    <Form.Check
                      type="switch"
                      disabled={!model}
                      label={
                        <span className="d-inline-block text-truncate">
                          {t('Share Dashboard')}
                        </span>
                      }
                      className="form-switch-share"
                      onChange={async (e) => {
                        const newIsPrivateReport = !e.target.checked
                        setIsPrivateReport(newIsPrivateReport)
                        const options = { private_report: newIsPrivateReport }
                        if (newIsPrivateReport) {
                          options.private_predict = true
                          NotificationManager.info(
                            'The dashboard is now private',
                          )
                        } else {
                          if (navigator?.clipboard?.writeText) {
                            navigator.clipboard.writeText(window.location.href)
                            NotificationManager.info(
                              <Row>
                                <Col xs={12}>
                                  {t('URL copied to clipboard')}
                                </Col>
                                <Col className="mt-3" xs={12}>
                                  {t('The dashboard is now public')}
                                </Col>
                              </Row>,
                            )
                          } else
                            NotificationManager.error('Clipboard not supported')
                        }
                        await editPrivacy(model.id, options, token, signout)
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {isEditable && (
        <div className={`floating-add-widget no-print`}>
          <Button
            onClick={() => {
              setShowMenuNew(true)
            }}
            style={{ height: '100%' }}
          >
            <FaPlus style={{ float: 'right' }} size={24} />
          </Button>
        </div>
      )}
      <div ref={widgetContainerRef} className="w-100 position-relative">
        <ReactGridLayout
          className="layout"
          rowHeight={30}
          breakpoints={breakpoints}
          cols={cols}
          layouts={fitLayout(
            elements.map((e) => ({ ...e.config.layout, static: !isEditable })),
          )}
          onLayoutChange={updateLayout}
          measureBeforeMount={true}
          verticalCompact={true}
          isResizable={isEditable}
          resizeHandles={['se']}
        >
          {elements.map((e) => {
            const Element = e.widget
            return (
              <span className={`block keep-bg-for-print`} key={e.key}>
                {[2, 5, 6, 14, 16].includes(e.config.id) && (
                  <span
                    className="csv-widget no-print"
                    onClick={() => handleCSVDownload(e)}
                  >
                    <FaFileDownload size={19} />
                  </span>
                )}
                {e.config.id !== 19 &&
                  e.config.id !== 7 &&
                  e.config.id !== 17 && (
                    <span
                      className="camera-widget no-print"
                      onClick={() => handlePNGDownload(e)}
                    >
                      <FaCamera size={19} />
                    </span>
                  )}
                {isEditable && (
                  <span
                    onClick={(event) => {
                      if (event.ctrlKey) {
                        setElements(elements.filter((el) => e.key !== el.key))
                        return
                      }
                      NotificationManager.info(
                        <Row>
                          <Row>Delete widget {e.config.name}?</Row>
                          <Row className="my-3">
                            <Button
                              className="float-end original"
                              variant="danger"
                              onClick={() => {
                                setElements(
                                  elements.filter((el) => e.key !== el.key),
                                )
                              }}
                            >
                              Confirm
                            </Button>
                          </Row>
                        </Row>,
                        null,
                        10000,
                      )
                    }}
                    className="delete-widget no-print"
                  >
                    <FaTrash size={17} />
                  </span>
                )}
                {isEditable && (
                  <span
                    className="edit-widget  no-print"
                    onClick={() => setShowMenuNew(e)}
                  >
                    <FaRegEdit size={19} />
                  </span>
                )}
                <Element
                  model={model}
                  config={e.config}
                  id={e.help}
                  requestedData={requestedData}
                  editing={false}
                />
              </span>
            )
          })}
        </ReactGridLayout>
      </div>
      <Modal
        size={'lg'}
        show={!firstVisitDashboard.visited}
        onHide={() => setFirstVisitDashboard({ visited: true })}
      >
        <DashboardIntroScreen
          onFinish={() => {
            setFirstVisitDashboard({ visited: true })
          }}
        />
      </Modal>
      <Offcanvas
        className="offcanvas-menu-widgets p-0 m-0 no-print"
        show={showMenuNew}
        backdrop={true}
        onHide={() => {
          setShowMenuNew(false)
        }}
        style={{ width: '600px' }}
      >
        <Offcanvas.Body style={{ maxHeight: '100vh' }} className="p-0 m-0">
          <MenuCreateWidget
            model={model}
            config={showMenuNew.config ? showMenuNew : null}
            onFinish={finishWidget}
            className="gx-0"
            style={{ boxSizing: 'border-box', maxWidth: '100%' }}
            llmDisabled={llmDisabled}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
