import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import TextareaAutosize from 'react-textarea-autosize'
import AITransformTable from './AITransformTable'

export default function ConfigureAITransform({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [inputNode, setInputNode] = useState(null)
  const inputRef = useRef()

  const [query, setQuery] = useState(configuration?.prompt ?? '')
  const [sample, setSample] = useState(null)
  const [code, setCode] = useState(configuration?.code)

  useEffect(() => {
    const inputEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_uniq_target`,
    )

    if (!inputEdge) {
      NotificationManager.error('Missing connection')
      close()
    } else {
      const inputNode = getNode(inputEdge.source)
      if (!inputNode?.data?.valid) {
        NotificationManager.error(
          t('Connected nodes are invalid, configure them first'),
        )
        close()
      } else {
        if (!inputNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          setInputNode(inputNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  if (!inputNode) return <Loading />
  return (
    <Row
      className="flex-column flex-nowrap justify-content-between"
      style={{ minHeight: 'calc(90vh - 150px)' }}
    >
      <Col xs={12}>
        <Row className="h5">
          <Col
            className="d-flex align-items-start justify-content-start mb-2"
            xs={12}
          >
            <span className="theme-color mt-2">{t('Transform my data: ')}</span>
          </Col>
          <Col
            xs="auto"
            style={{
              maxWidth: 'calc(100% - 150px)',
              minWidth: 'calc(100% - 150px)',
            }}
          >
            <TextareaAutosize
              className="nb-input match-select-height w-100 p-2"
              placeholder={`Write your transformation in natural language here \n\n`}
              defaultValue={query}
              ref={inputRef}
              rows={3}
              style={{ borderRadius: '15px' }}
            />
          </Col>
          <Col xs="auto" style={{ maxWidth: '100px', minWidth: '100px' }}>
            <Button
              className="config-button empty-secondary original"
              onClick={() => {
                setQuery(inputRef.current.value)
              }}
            >
              {t('Apply')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" xs={12}>
            <div className="h5 mb-0">{t('Preview')}</div>
          </Col>
          <Col
            className="d-inline-block mt-1"
            xs={12}
            style={{ minHeight: '350px', maxHeight: '350px', zIndex: '0' }}
          >
            <AITransformTable
              input={inputNode.data.sample}
              prompt={query}
              onDataLoad={(data) => setSample(data)}
              onCodeLoad={(code) => setCode(code)}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        <Row>
          <Col className="mt-4 d-flex justify-content-end" xs={12}>
            <Button
              disabled={!sample || !query || !code}
              className="config-button"
              onClick={() => {
                onFinish(sample, {
                  input: inputNode.id,
                  prompt: query,
                  code,
                })
              }}
            >
              {t(actionLabel)}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
