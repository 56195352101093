import { Row, Col } from 'react-bootstrap'
import PredictionChart from '../mmm/PredictionChart'
import MediaContribution from '../mmm/MediaContribution'
import MediaContributionBars from '../mmm/MediaContributionBars'
import BestSpend from '../mmm/BestSpend'
import LagAndCarryover from '../mmm/LagAndCarryover'
import InsightHeading from './InsightHeading'
import OriginalSpend from '../mmm/OriginalSpend'
import Influence from '../mmm/Influence'

export function MMMModelSummaryEngagement({ model, children }) {
  return (
    <Row className="py-2">
      <Col xs={12}>
        <Row className="h-100 insight-model-content">
          <InsightHeading
            title="Current media mix"
            description="Individual effects of media on weekly outcomes for input data’s time period."
          />
          <Col style={{ minHeight: '250px' }} xs={12}>
            <OriginalSpend
              model={model}
              margin={{ top: 20, right: 0, bottom: 30, left: 0 }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        className="mt-1"
        style={{
          background: 'var(--nextbrain-background)',
          borderRadius: '5px',
          border: '1px solid var(--nextbrain-secondary-border-color)',
        }}
        xs={12}
      >
        <Row className="my-1">
          <Col xs={12}>
            <MediaContribution model={model} />
          </Col>
        </Row>
      </Col>
      <Col className={'insight-model-content'} xs={12}>
        <Row>
          <InsightHeading
            title={'Media effects - average'}
            description={`Media channels average influence on outcome. The sum of this effects is equal to 100% because baseline effect is considered separately.`}
            className="px-2 mt-2"
          />
        </Row>
        <MediaContributionBars style={{ minHeight: '300px' }} model={model} />
      </Col>
      <Col xs={12}>
        <Row className="my-2 insight-model-content">
          <InsightHeading
            title={'Response Model accuracy'}
            description={`Accuracy of the response model in predicting target outcomes given current data on consumer and market responses to the marketing mix.`}
            className="px-2 mt-2"
          />
          <Col
            style={{
              minHeight: '450px',
              maxHeight: '450px',
            }}
            className="py-3"
            xs={12}
          >
            <PredictionChart model={model} />
          </Col>
        </Row>
      </Col>
      <Col className="my-2 insight-model-content" xs={12}>
        <Row>
          <InsightHeading
            title={'Lag and Carryover'}
            description={`The carryover effect is the portion of the effect that occurs after the advertisement pulse. These aftereffects of advertising can occur immediately after the pulse or after some lag period.`}
            className="px-2 mt-2"
          />
          <Col style={{ minHeight: '400px' }} xs={12}>
            <LagAndCarryover model={model} />
          </Col>
        </Row>
      </Col>
      <Col className="my-2 insight-model-content" xs={12}>
        <Row>
          <InsightHeading
            title={'Underlying effects'}
            description={`Effects of other variables aside from media channel spending, such as total trend, seasonality or other additional factors.`}
            className="px-2 my-2"
          />
          <Col style={{ minHeight: '400px' }} xs={12}>
            <Influence model={model} />
          </Col>
        </Row>
      </Col>
      <Col className="my-2 insight-model-content" xs={12}>
        <Row>
          <InsightHeading
            title={'Shape effect (diminishing returns)'}
            description={`The shape effect refers to the change in sales in response to increasing intensity of advertising in the same time period`}
            className="px-2 my-2"
          />
          <Col style={{ minHeight: '600px' }} xs={12}>
            <BestSpend model={model} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
