import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  Button,
  Form,
  ListGroup,
  Tab as BsTab,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { AiFillWarning } from 'react-icons/ai'

import { useTranslation } from 'react-i18next'
import $ from 'jquery'
import { NotificationManager } from 'react-notifications'
import ViewDataTable from '../../view-data/view-data'

import { createModelFromCSV } from '../../../services/model'
import { deleteCsv, getCsvHead, getAllCSV } from '../../../services/csv'
import { useAuth } from '../../../providers/AuthProvider'
import { useModels } from '../../../providers/ModelProvider'
import { zip } from '../../../util/other'
import { AssistantCheck } from '../AssistantCheck'
import CrashFallback from '../../crash-fallback/CrashFallback'
import ErrorNB from '../../loading/ErrorNB'
import RoleDisable from '../../utils/RoleDisable'

export default function ExistingData({
  actionLabel = 'Create new model',
  configuration,
  onNameChange = () => {},
  onFinish = null,
  single,
  sampleData = false,
  onAskAi = false,
}) {
  const { token, signout } = useAuth()
  const [selectedCsv, setSelectedCsv] = useState(configuration?.selectedCsv)
  const [creatingModelFromCSV, setCreatingModelFromCSV] = useState(false)
  const [sample, setSample] = useState(null)

  const [csvData, setCsvData] = useState([])
  const { t } = useTranslation()
  const { addModel, models } = useModels()

  const csvAssociatedToModels = useMemo(() => {
    return new Set(models.map((m) => m?.dataset?.csv?.id))
  }, [models])

  const handleCreateByCsv = () => {
    setCreatingModelFromCSV(true)
  }

  const handleDeleteCsv = (selectedCsv) => {
    deleteCsv(selectedCsv, token, signout).then(() => {
      setCsvData((currentCsvData) => {
        let finalCsvData = currentCsvData.filter(
          (csv) => csv.id !== selectedCsv,
        )

        setSelectedCsv(finalCsvData?.[0]?.id)
        return finalCsvData
      })
    })
  }

  const refreshCSVList = () => {
    getAllCSV(token, signout).then((response) => {
      if (!Array.isArray(response)) {
        NotificationManager.error(t('Failed to retrieve CSVS'))
      } else {
        setCsvData(response.filter((csv) => csv.status === 'imported'))
        if (response.length > 0) {
          setSelectedCsv(selectedCsv ?? response[0].id)
        } else {
          //if (tabIndex === 1) setTabIndex(2)
          setSelectedCsv(null)
        }
      }
    })
  }

  if (!onFinish)
    onFinish = (sample, selectedCsv) =>
      createModelFromCSV(selectedCsv, token, signout)
        .then((response) => {
          if (!response.id) {
            NotificationManager.error(
              response.detail ?? t('Failed to create model from CSV'),
            )
            return
          }
          addModel(response)
        })
        .finally(() => {
          setCreatingModelFromCSV(false)
        })

  useEffect(() => {
    refreshCSVList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(
    () => {
      ;(async () => {
        if (!creatingModelFromCSV) return
        let sample = null
        const data = await getCsvHead(selectedCsv, 30, token, signout, true)
        if (data?.status === 'error') {
          NotificationManager.error(t('Invalid CSV'))
          setCreatingModelFromCSV(false)
          return
        }
        sample = {
          columns: data.columns_order,
          sample: zip(
            data.columns_order.map((c) => data.head[c]),
            30,
          ),
        }
        onFinish(
          sample,
          selectedCsv,
          csvData.find((c) => c.id === selectedCsv)?.file_path,
        )
      })()
    },
    // eslint-disable-next-line
    [creatingModelFromCSV],
  )

  return (
    <CrashFallback message={<ErrorNB />} t={t}>
      <>
        <Row style={{ minHeight: '400px' }}>
          <Col xs={12} className="nopaddingright mt-3">
            <p>{t('Create new model from previously stored data.')}</p>
          </Col>
          <Col
            xs={4}
            className="d-flex flex-column"
            style={{ height: '348px' }}
          >
            <div
              className="flex-grow-1 d-flex flex-column"
              style={{ flexBasis: '0px', height: '100%' }}
            >
              <Form.Control
                placeholder={t('Search a model...')}
                aria-label="Search"
                className=" with-icon nb-input-soft"
                onChange={(event) => {
                  $('.csv-stored')
                    .addClass('d-none')
                    .filter((dex, el) =>
                      el.dataset.pathcsv
                        .toLocaleLowerCase()
                        .includes(
                          event.currentTarget.value.toLocaleLowerCase(),
                        ),
                    )
                    .removeClass('d-none')
                }}
              />
              <i className="fas fa-search icon"></i>
              <BsTab.Container activeKey={selectedCsv ? `#${selectedCsv}` : ''}>
                <ListGroup className="stored-csv-list" style={{}}>
                  {csvData.map((csv) => (
                    <ListGroup.Item
                      as="li"
                      data-pathcsv={
                        csv.file_path.toLowerCase() + csv.id.toLowerCase()
                      }
                      className="d-flex justify-content-between align-items-center cursor-pointer text-break csv-stored"
                      key={csv.id}
                      onClick={(e) => {
                        if (csv.file_path)
                          onNameChange(csv.file_path?.replace('.csv', ''))
                        setSelectedCsv(csv.id)
                      }}
                      variant="dark-blue"
                      href={`#${csv.id}`}
                      action
                    >
                      {csv.file_path}
                      {csvAssociatedToModels.has(csv.id) ? (
                        <OverlayTrigger
                          rootClose={true}
                          trigger={['hover', 'focus']}
                          delay={{ show: 100, hide: 100 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {t(`Dataset in use by active models`)}.
                            </Tooltip>
                          )}
                        >
                          <div
                            className="d-flex justify-content-center"
                            style={{ minWidth: '30px' }}
                          >
                            <AiFillWarning size={21} />
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div
                          className="icon-trash delete-csv-st"
                          onClick={(e) => {
                            if (e.ctrlKey) {
                              handleDeleteCsv(csv.id)
                              e.preventDefault()
                              e.stopPropagation()
                              return
                            }
                            e.stopPropagation()
                            NotificationManager.info(
                              <Row>
                                <Col
                                  title={`${t(
                                    'Are you sure you want to delete',
                                  )} ${csv.file_path}?`}
                                  className="text-center"
                                  xs={12}
                                >
                                  {t('Are you sure you want to delete')}{' '}
                                  {csv.file_path}?
                                </Col>
                                <Col xs={12}>
                                  <Button
                                    className="original w-100 my-3"
                                    variant="danger"
                                    onClick={() => {
                                      handleDeleteCsv(csv.id)
                                    }}
                                  >
                                    {t('Confirm')}
                                  </Button>
                                </Col>
                              </Row>,
                              null,
                              10000,
                            )
                          }}
                        >
                          {' '}
                        </div>
                      )}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </BsTab.Container>
            </div>
          </Col>
          <Col xs={8} className="d-flex flex-column position-relative">
            {selectedCsv ? (
              <ViewDataTable
                key={selectedCsv}
                style={{ minHeight: '300px' }}
                maxHeight="350px"
                getHead={async ({ modelId, token, signout }) =>
                  await getCsvHead(modelId, 2000, token, signout)
                }
                dataId={selectedCsv}
                onChange={selectedCsv}
                className="stored-csv-table"
                onLoad={(d) => setSample(d)}
                rowsPerPage={10}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col className="mb-2 d-flex justify-content-end" xs={12}>
            <Row className="w-100 mb-2 mt-3 justify-content-end align-items-center">
              <Col xs="auto">
                {onAskAi && (
                  <AssistantCheck
                    onChange={onAskAi}
                    enable={() => {
                      const cols = sample?.columns_order?.length
                      if (cols && cols > 500) return false
                      return true
                    }}
                  />
                )}
              </Col>
              <Col className="d-flex justify-content-end" xs={'auto'}>
                {selectedCsv ? (
                  <RoleDisable className="w-100">
                    <Button
                      onClick={handleCreateByCsv}
                      className="config-button float-md-end"
                      disabled={creatingModelFromCSV || !sample}
                    >
                      {t(actionLabel)}
                    </Button>
                  </RoleDisable>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </CrashFallback>
  )
}
