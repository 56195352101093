import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './RecoverForm.css'
import { getUser, resetVerification } from '../../services/user'
import { NotificationManager } from 'react-notifications'
import { BouncyLoader } from '../utils/ui'

export default function Validate({ email, token, reset, success }) {
  const { t } = useTranslation()
  const [sentEmail, setSentEmail] = useState(false)

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await getUser(token)
      if (response?.status === 200) {
        const userData = await response.json()
        success(email, token, userData)
        reset()
      }
    }, 5000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  return (
    <Row id="boxlogin" style={{ minHeight: '800px' }}>
      <Col id="LoginContainer" md={6}>
        <Row className="h-100 justify-content-center flex-column">
          <Row>
            <Col className="h4 mb-2" md={12}>
              {t('Validate your account')}
            </Col>
            <p>
              {t(`We have sent an email to`) +
                ` ${email} ` +
                t(
                  `with a link to validate your account. Please check your inbox and follow the instructions.`,
                )}
            </p>
          </Row>
          <Row className="mb-5" align="center">
            <Button
              id="loginButton"
              onClick={() => {
                resetVerification({ token })
                setSentEmail(true)
                NotificationManager.info(t('Email sent'))
              }}
              className="w-100 p-2"
              variant="primary"
              type="submit"
              disabled={sentEmail}
            >
              {sentEmail ? <BouncyLoader /> : t('Resend email')}
            </Button>
            <strong
              className="mt-2 go-back-validate"
              style={{ color: 'var(--nextbrain-white-font)' }}
              onClick={reset}
            >
              {t('Go back')}
            </strong>
          </Row>
        </Row>
      </Col>
      <Col md={6} className="p-0 imglogin">
        <div className="logologin">
          <Image
            alt="NextBrain Logo"
            height={80}
            width={80}
            src="/LogoLogin.svg"
          />
        </div>
      </Col>
    </Row>
  )
}
