import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getModelById } from '../../services/model'
import CustomHeatmap from '../model-content/CustomHeatmap'
import CustomSankey from '../model-content/CustomSankey'
import CustomScatter from '../model-content/CustomScatter'
import { ColumnImportance } from '../model-content/CustomBar'
import { ForecastPredict } from '../predict-form/predict-form'

import { round } from '../utils/formating'
import { useAuth } from '../../providers/AuthProvider'

import './insights.css'
import { useNav } from '../../providers/NavProvider'

function getMostImportantMetric(problem_type) {
  switch (problem_type) {
    case 'regression':
      return 'ExpVariance'
    case 'time_series_regression':
      return '100 - Weighted MAPE'
    case 'binary':
    case 'time_series_binary':
      return 'Accuracy Binary'
    case 'multiclass':
    case 'time_series_multiclass':
      return 'Accuracy Multiclass'
    default:
      return 'precission'
  }
}

export function AccInsight({ model, style = { minHeight: '250px' } }) {
  const { t } = useTranslation()
  const metric = model ? getMostImportantMetric(model.problem_type) : null
  let testAcc =
    model && model.status === 'trained'
      ? parseInt(parseFloat(model.acc[metric]) * 100)
      : 0
  let trainAcc =
    model && model.status === 'trained'
      ? parseInt(parseFloat(model.acc_train[metric]) * 100)
      : 0

  if (testAcc > 999 || trainAcc > 999) {
    testAcc = round(testAcc / 100, 2)
    trainAcc = round(trainAcc / 100, 2)
  }

  const hasBaseline =
    model && model.status === 'trained' && model.baseline !== null

  const syntheticSimilarity = model?.dataset?.synthetic_similarity

  return (
    <Row style={style} className="insights-card py-2 px-2">
      <Col md={6}>
        <span className="pl-2 card-title">{t('Model accuracy:')}</span>
      </Col>

      {hasBaseline ? (
        <Col md={6}>
          <span className="pl-2 card-title">{t('Baseline:')}</span>
        </Col>
      ) : (
        <></>
      )}
      <Col
        align="center"
        md={hasBaseline ? 6 : 12}
        className="my-4 text-secondary big-text"
      >
        <strong>{testAcc}%</strong>
      </Col>
      <Col
        md={hasBaseline ? 6 : 12}
        align="center"
        className="mb-4 text-secondary"
        style={
          hasBaseline
            ? {
                marginTop: '2.4rem',
              }
            : {}
        }
      >
        {hasBaseline ? (
          <span title={t('Baseline Explanation')}>
            <strong className="medium-text">
              {round(testAcc / parseInt(model.baseline * 100), 1)}x
            </strong>{' '}
            {t('better than baseline')}
          </span>
        ) : (
          <></>
        )}
        {hasBaseline && syntheticSimilarity ? <br /> : <></>}
        {syntheticSimilarity ? (
          <span title={t('Synthetic Similarity Explanation')}>
            <strong className="medium-text">
              {round(parseInt(syntheticSimilarity * 100), 1)}
            </strong>{' '}
            {t('synthetic similarity to original data')}
          </span>
        ) : (
          <></>
        )}
      </Col>
      <Col className="mt-2" md={12}>
        <span className="pl-2 card-title">
          {t('Accuracy during training the model:')}{' '}
          <span className="text-secondary">{trainAcc}%</span>
        </span>
      </Col>
    </Row>
  )
}

function getModelCorrelation(model) {
  const getStandardDeviation = (array) => {
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
    )
  }

  let completeData = model.correlation.map((d) => ({
    x: d.y_pred,
    y: d.y_pred - d.y,
  }))

  const std = getStandardDeviation(completeData.map((d) => d.y))
  return [
    {
      id: 'Good',
      data: completeData.filter((d) => Math.abs(d.y) <= std),
    },
    {
      id: 'Bad',
      data: completeData.filter((d) => Math.abs(d.y) > std),
    },
  ]
}

export default function Insights({ setTitle, modelId, showHead = true }) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(null)
  const param = useParams()
  const { setShowNav } = useNav()
  useEffect(() => {
    setShowNav(false)
    return () => setShowNav(true)
    // eslint-disable-next-line
  }, [])
  if (!setTitle) setTitle = () => {}

  useEffect(() => {
    setTitle(`Insights | ${t('NextBrain')}`)
    getModelById(modelId ?? param.id, token, signout).then((response) => {
      setModel(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!model || !model.dataset) return
    setTitle(`${model.dataset.name} Insights | ${t('NextBrain')}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  const confusionMatrixHeatmap =
    model && model.confusion_matrix
      ? Object.keys(model.confusion_matrix.test).map((column) => ({
          id: column,
          data: Object.keys(model.confusion_matrix.test[column]).map(
            (column2) => ({
              x: column2,
              y:
                (100 * model.confusion_matrix.test[column][column2]) /
                Object.keys(model.confusion_matrix.test[column]).reduce(
                  (accumulator, k) => {
                    return accumulator + model.confusion_matrix.test[column][k]
                  },
                  0,
                ),
            }),
          ),
        }))
      : []

  return (
    <Container>
      <Row className="mt-2">
        <Col md={12}>
          <h5>
            {model && model.status === 'trained'
              ? model.dataset.name + ' | '
              : ''}{' '}
            Model insights
          </h5>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Col md={12}>
            <AccInsight model={model} />
          </Col>
        </Col>
        {
          // If it is not correlation nor forecasting
          model &&
          model.columns_active &&
          Object.keys(model.columns_active).length > 2 ? (
            <>
              {model.problem_type !== 'regression' ? (
                <Col md={4}>
                  <CustomHeatmap
                    header={
                      <span className="px-2 card-title">
                        Model performance:
                      </span>
                    }
                    className="insights-card py-2 px-2"
                    data={confusionMatrixHeatmap}
                    target={model ? model.target : 'Target'}
                    height={204}
                    forceSquare={false}
                  />
                </Col>
              ) : (
                <Col md={4}>
                  <CustomScatter
                    data={{
                      id: model.target,
                      data: model.true_vs_predict,
                    }}
                    header={
                      <span className="px-2 card-title">
                        Predicted vs Actual values:
                      </span>
                    }
                    className="insights-card py-2 px-2"
                    height={204}
                  />
                </Col>
              )}
              <Col md={4}>
                <ColumnImportance
                  header={
                    <span className="px-2 card-title">Column importance:</span>
                  }
                  className="insights-card py-2 px-2"
                  model={model}
                  height={204}
                />
              </Col>
            </>
          ) : (
            <></>
          )
        }
        {
          // If it is correlation
          model &&
          model.columns_active &&
          Object.keys(model.columns_active).length === 2 &&
          model.problem_type !== 'time_series_regression' ? (
            <>
              <Col md={4}>
                <CustomScatter
                  data={{
                    id: model.target,
                    data: model.true_vs_predict,
                  }}
                  header={
                    <span className="px-2 card-title">
                      Predicted vs Actual values:
                    </span>
                  }
                  className="insights-card py-2 px-2"
                  height={204}
                />
              </Col>
              <Col md={4}>
                <CustomScatter
                  data={getModelCorrelation(model)}
                  header={<span className="px-2 card-title">Residuals:</span>}
                  leftLegend="Prediction minus True value"
                  bottomLegend={`Predicted ${model.target}`}
                  addLineLayer={false}
                  showLegend={true}
                  className="insights-card py-2 px-2"
                  height={204}
                />
              </Col>
            </>
          ) : (
            <></>
          )
        }
      </Row>
      {
        // If it is nor correlation nor forecasting (2nd row)
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length > 2 ? (
          <Row>
            <Col md={12}>
              <Row className="mt-3" justify="center">
                <Col md={12}>
                  <CustomSankey
                    header={
                      <span className="px-2 card-title">
                        How most importants features affect to predict your
                        target?
                      </span>
                    }
                    className="insights-card py-2 px-2"
                    data={
                      model && model.details
                        ? model.details.sankey_feature_importance
                        : null
                    }
                    target={model ? model.target : null}
                  />
                </Col>
              </Row>
              <Row className="mt-3" justify="center">
                <Col align="center" md={12}>
                  Aquí va feature vs target con algo de interactividad
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )
      }
      {
        // If it is forecasting (2nd row)
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length === 2 &&
        model.problem_type === 'time_series_regression' ? (
          <Row className="mt-3">
            <Col md={12}>
              <div className="insights-card py-2 px-2">
                <div className="mx-3">
                  <ForecastPredict model={model} />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )
      }
      {
        // If it is correlation
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length === 2 &&
        model.problem_type !== 'time_series_regression' ? (
          <Row className="mt-3">
            <Col md={12}>
              <CustomScatter
                data={[
                  {
                    id: 'True',
                    data: model.correlation,
                  },
                  {
                    id: 'Predicted',
                    data: model.correlation.map((d) => ({
                      x: d.x,
                      y: d.y_pred,
                    })),
                  },
                ]}
                header={<span className="px-2 card-title">Correlation:</span>}
                leftLegend={model.target}
                bottomLegend={
                  Object.keys(model.columns_active).filter(
                    (c) => c !== model.target,
                  )[0]
                }
                addLineLayer={false}
                showLegend={true}
                className="insights-card py-2 px-2"
                height={400}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )
      }
    </Container>
  )
}
