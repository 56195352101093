import React from 'react'
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsInfoCircle } from 'react-icons/bs'

export default function MMMInsightHeading({
  title,
  description,
  message,
  help = true,
  children,
  popupWidth = 650,
  isBold = true,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={12} {...props} className={`  ${props.className ?? ''}`}>
        <Row className="pt-1">
          <Col
            className={`h4 ${isBold ? 'bold' : ''}`}
            style={{ maxWidth: 'calc(100% - 50px)' }}
            xs={'auto'}
          >
            <span>{t(title)}</span>
          </Col>
          {help ? (
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'auto'}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) => (
                <Popover
                  {...props}
                  style={{
                    ...(props?.style ?? {}),
                    minWidth: `${popupWidth + 100}px`,
                    zIndex: 10000,
                  }}
                >
                  <Row className="p-3" style={{ minWidth: `${popupWidth}px` }}>
                    <Col xs={12}>
                      {description
                        ? typeof description === 'string'
                          ? t(description)
                          : description
                        : children}
                    </Col>
                  </Row>
                </Popover>
              )}
            >
              <Col
                className="no-print"
                style={{
                  maxWidth: '50px',
                  cursor: 'pointer',
                  color: 'var(--nextbrain-link-color)',
                }}
                xs={'auto'}
              >
                <BsInfoCircle size={18} />
              </Col>
            </OverlayTrigger>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </>
  )
}
