import { request, upload } from './base'

export async function getRepositories({ repositoryId = null, token, signout }) {
  return request({
    endpoint: `/document/repositories${
      repositoryId ? `?repository_id=${repositoryId}` : ''
    }`,
    method: 'GET',
    token,
    signout,
  })
}

export async function shareRepositoryById(repository_id, data, token, signout) {
  return request({
    endpoint: `/document/repository/${repository_id}/share`,
    contentType: 'json',
    method: 'POST',
    body: data,
    token,
    signout,
  })
}

export async function createRepository({ name, token, signout }) {
  return request({
    endpoint: `/document/repository`,
    method: 'POST',
    body: {
      name,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function updateRepository({ repositoryId, name, token, signout }) {
  return request({
    endpoint: `/document/repository/${repositoryId}`,
    method: 'PUT',
    body: {
      name,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function deleteRepository({ repositoryId, token, signout }) {
  return request({
    endpoint: `/document/repository/${repositoryId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function createDocument({
  repositoryId,
  file,
  alternative,
  onProgress,
  token,
  signout,
}) {
  if (!file && alternative)
    return request({
      endpoint: `/document/upload_source/${repositoryId}`,
      method: 'POST',
      body: alternative,
      contentType: 'json',
      token,
      signout,
    })
  else
    return upload({
      token,
      endpoint: `/document/upload/${repositoryId}`,
      headers: {
        Accept: 'application/json',
      },
      filename: 'file',
      file,
      onProgress,
    })
}

export async function updateDocument({
  documentId,
  alternative,
  file,
  onProgress,
  token,
}) {
  if (!file && alternative)
    return request({
      endpoint: `/document/source/${documentId}`,
      method: 'PUT',
      body: alternative,
      contentType: 'json',
      token,
    })
  else
    return upload({
      token,
      method: 'PUT',
      endpoint: `/document/${documentId}`,
      headers: {
        Accept: 'application/json',
      },
      filename: 'file',
      file,
      onProgress,
    })
}

export async function getDocuments({ repositoryId, token, signout }) {
  return request({
    endpoint: `/document/?repository_id=${repositoryId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function deleteDocument({ documentId, token, signout }) {
  return request({
    endpoint: `/document/${documentId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function downloadDocument({ documentId, token, signout }) {
  return request({
    endpoint: `/document/${documentId}/download`,
    rawResponse: true,
    method: 'GET',
    token,
    signout,
  })
}

export async function getSingleDocument({ documentId, token, signout }) {
  return request({
    endpoint: `/document/${documentId}/single_document`,
    method: 'GET',
    token,
    signout,
  })
}

export async function queryRepository({
  problem,
  repositoryId,
  token,
  signout,
}) {
  return request({
    endpoint: `/document/query/${repositoryId}`,
    method: 'POST',
    body: {
      problem,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function editRepositoryPrivacy({
  repositoryId,
  isPublic,
  token,
  signout,
}) {
  return request({
    endpoint: `/document/privacy/${repositoryId}?is_public=${isPublic}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function refreshRepository({ repositoryId, token, signout }) {
  return request({
    endpoint: `/document/refresh_repository/${repositoryId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getTags({ repositoryId, token, signout }) {
  return request({
    endpoint: `/document/tags-by-repository/${repositoryId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getTagsByDocument({ documentId, token, signout }) {
  return request({
    endpoint: `/document/tags/${documentId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function setTagsByDocument({ documentId, tags, token, signout }) {
  return request({
    endpoint: `/document/set-tags/${documentId}`,
    method: 'POST',
    body: tags,
    contentType: 'json',
    token,
    signout,
  })
}

export async function getDocumentChunks({ repositoryId, map, token, signout }) {
  return request({
    endpoint: `/document/chunks-content/${repositoryId}`,
    method: 'POST',
    body: map,
    contentType: 'json',
    token,
    signout,
  })
}

export async function getCompletion({ query, context, token, signout }) {
  return request({
    endpoint: `/document/completion`,
    method: 'POST',
    body: {
      query,
      context,
    },
    contentType: 'json',
    token,
    signout,
  })
}
