import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function RegisterSteps({
  steps,
  at,
  max,
  ok = new Array(steps).fill(false),
  onClick = (index) => {},
  ...props
}) {
  const getStage = (dex) => {
    if (dex === at) return 'stageCurrent'
    else if (ok[dex]) return 'stageOk'
    else return 'stageFail'
  }

  return (
    <Row className="step-header  justify-content-between">
      {new Array(steps).fill(null).map((k, index) => (
        <Col
          key={index}
          xs={'auto'}
          xl={'auto'}
          style={{ zIndex: steps - index }}
          onClick={() => {
            onClick(index)
          }}
        >
          <div className={getStage(index)}>
            {ok[index] && index !== at ? (
              <img alt="check" src="/check.png" width="20" />
            ) : (
              index + 1
            )}
          </div>
        </Col>
      ))}
    </Row>
  )
}
