import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import ChangeTypeTable from './ChangeTypeTable'

export default function ConfigureChangeTypes({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [node, setNode] = useState(null)
  const [sample, setSample] = useState(null)
  const [typeMapping, setTypeMapping] = useState(
    configuration?.type_mapping ?? {},
  )

  useEffect(() => {
    const inputEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_uniq_target`,
    )

    if (!inputEdge) {
      NotificationManager.error('Missing connection')
      close()
    } else {
      const inputNode = getNode(inputEdge.source)
      if (!inputNode?.data?.valid) {
        NotificationManager.error(
          t('Connected nodes are invalid, configure them first'),
        )
        close()
      } else {
        if (!inputNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          setNode(inputNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!node) return <Loading />
  return (
    <Row className="flex-column">
      <Col
        className="my-2 dataflow-change-type-container"
        style={{ minHeight: '520px', maxHeight: '520px', overflow: 'auto' }}
      >
        <ChangeTypeTable
          input={node.data.sample}
          typeMapping={typeMapping}
          onChange={setTypeMapping}
          onDataLoad={(data) => setSample(data)}
        />
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample || !Object.keys(typeMapping).length}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              input: node.id,
              type_mapping: typeMapping,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
