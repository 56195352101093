import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  getAllUsers,
  getAllActionsByID,
  getAllActionsCountByID,
} from '../../services/user'
import { useAuth } from '../../providers/AuthProvider'
import { FcOk, FcHighPriority, FcLineChart, FcSettings } from 'react-icons/fc'
import { MdOutlineChatBubble } from 'react-icons/md'
import { formatDate } from '../../util/other'
import './UserDetails.css'
import { useQuery } from 'react-query'
import Loading from '../loading/LoadingSmall'

export default function UserDetails() {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const userID = params.get('userId')
  const start = params.get('start')
  const end = params.get('end')
  const from = params.get('from')
  const { token } = useAuth()
  const [changedEnd, setChangedEnd] = useState(end ?? formatDate(new Date()))
  const [changedStart, setChangedStart] = useState(
    start ??
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      ),
  )
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  yesterday.setHours(0, 0, 0, 0)
  const { data: users, isLoading: usersLoading } = useQuery(
    'get-all-users-admin',
    () => getAllUsers(token),
    { staleTime: Infinity },
  )
  const user = usersLoading ? {} : users?.find((user) => user.id === userID)
  const userDate = new Date(user?.created)

  const { data: actionsByID, isLoading: actionsByIDLoading } = useQuery(
    ['get-all-users-actions-by-id', changedStart, changedEnd, userID],
    () => getAllActionsByID(token, changedStart, changedEnd, userID),
    { staleTime: Infinity },
  )

  const { data: actionsCountByID, isLoading: actionsCountByIDLoading } =
    useQuery(
      ['get-all-users-actions-count-by-id', changedStart, changedEnd, userID],
      () => getAllActionsCountByID(token, changedStart, changedEnd, userID),
      { staleTime: Infinity },
    )

  userDate.setHours(0, 0, 0, 0)
  const sessions = actionsByID?.length
    ? actionsByID.filter((action) => action.action_type === 'session')?.length
    : 0

  const mmmTrains =
    actionsByID?.filter((a) => a.model_problem_type === 'mmm')?.length ?? 0

  return (
    <>
      <Row
        className="justify-content-between top-controls mx-2 user-details-component position-sticky"
        style={{ top: 0 }}
      >
        <Col className="mb-3 mt-3" xs={12}>
          <Link
            to={
              from === 'users'
                ? `/${from}`
                : `/${from}/?start=${start}&end=${end}`
            }
            style={{ color: 'var(--nextbrain-white-font)' }}
          >
            <div
              className="col-auto hover-link cursor-pointer"
              style={{ cursor: 'pointer', fontSize: '24px' }}
            >
              <i className="fas fa-angle-left fa-lg"></i>
              <strong className="ml-2"> {t('Back')}</strong>
            </div>
          </Link>
          <div
            className="col-auto"
            style={{ color: 'var(--nextbrain-white-font)' }}
          ></div>
        </Col>
      </Row>
      <Row className="px-3 detail-panel">
        <Row className="px-3">
          <Col>
            <div className="title mb-3">
              <div className="h2">{t('User details')}</div>{' '}
              <span className="line2" />
            </div>
          </Col>
        </Row>
        {user ? (
          <Row className="details">
            <Col xs={12} lg={6}>
              {usersLoading || actionsCountByIDLoading || !user.id ? (
                <Loading />
              ) : (
                <table className="custom-table">
                  <thead className=" custom-thead">
                    <tr className="custom-tr">
                      <th className="custom-th" colSpan="2">
                        {t('User')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="custom-tr">
                      <td className="custom-td"> Id</td>
                      <td className="custom-td">{user.id}</td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Name')}</td>
                      <td className="custom-td">{user.name}</td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Email')}</td>
                      <td className="custom-td">{user.email}</td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Created')}</td>
                      {today.getTime() === userDate.getTime() && (
                        <td className="custom-td">
                          {t('Today')}
                          {user.created
                            .replace('Z', ' ')
                            .replace('T', ' ')
                            .substring(10, 16)}
                        </td>
                      )}
                      {yesterday.getTime() === userDate.getTime() && (
                        <td className="custom-td">
                          {t('Yesterday')}
                          {user.created
                            .replace('Z', ' ')
                            .replace('T', ' ')
                            .substring(10, 16)}
                        </td>
                      )}
                      {today.getTime() !== userDate.getTime() &&
                        yesterday.getTime() !== userDate.getTime() && (
                          <td className="custom-td">
                            {user.created
                              .replace('Z', ' ')
                              .replace('T', ' ')
                              .substring(0, 16)}
                          </td>
                        )}
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Country')}</td>
                      <td className="custom-td">
                        {user.country ? user.country : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">
                        {t('Platform use description')}
                      </td>
                      <td className="custom-td">
                        {user.previous_platforms ? user.previous_platforms : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Introduction')}</td>
                      <td className="custom-td">
                        {user.introduction ? t(user.introduction) : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('ML Level')}</td>
                      <td className="custom-td">
                        {user.ml_level ? t(user.ml_level) : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Previous platforms')}</td>
                      <td className="custom-td">
                        {user.previous_platforms ? user.previous_platforms : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Student')}</td>
                      <td className="custom-td">
                        {user.student
                          ? user.student
                            ? t('Yes')
                            : t('No')
                          : ''}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Trainings')}</td>
                      <td className="custom-td">
                        {actionsCountByID?.trains ?? 0}
                        {mmmTrains ? (
                          <span className="theme-color-mmm">
                            {` (${mmmTrains} MMM)`}
                          </span>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Predictions')}</td>
                      <td className="custom-td">
                        {actionsCountByID?.predicts ?? 0}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Failures')}</td>
                      <td className="custom-td">
                        {actionsCountByID?.failures ?? 0}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Chats')}</td>
                      <td className="custom-td">
                        {actionsCountByID?.chats ?? 0}
                      </td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Sessions')}</td>
                      <td className="custom-td">{sessions}</td>
                    </tr>
                    <tr className="custom-tr">
                      <td className="custom-td">{t('Cost')}</td>
                      <td className="custom-td">{actionsCountByID?.cost}$</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Col>
            <Row className="px-3 detail-input">
              <Col>
                <div>
                  <div className="label-text">{t('From')}</div>
                  <input
                    className="input-date nb-input"
                    style={{ minWidth: '300px' }}
                    type="date"
                    id="start"
                    value={changedStart}
                    onChange={(e) => setChangedStart(e.currentTarget.value)}
                  />
                  <input
                    className="input-date nb-input"
                    style={{ minWidth: '300px' }}
                    type="date"
                    id="end"
                    value={changedEnd}
                    onChange={(e) => setChangedEnd(e.currentTarget.value)}
                  />
                </div>
              </Col>
            </Row>
            <Col>
              {actionsByIDLoading || !actionsByID ? (
                <Loading />
              ) : (
                <table className="custom-table">
                  <thead className="custom-thead">
                    <tr className="custom-tr">
                      <th className="custom-th" colSpan="11">
                        {t('User actions')}
                      </th>
                    </tr>
                    <tr className="custom-tr">
                      <th className="custom-th">{t('Action')}</th>
                      <th className="custom-th">{t('Model id')}</th>
                      <th className="custom-th">{t('Model name')}</th>
                      <th className="custom-th">{t('Problem type')}</th>
                      <th className="custom-th">{t('Target')}</th>
                      <th className="custom-th">{t('Date')}</th>
                      <th className="custom-th">{t('Execution time')}</th>
                      <th className="custom-th">{t('Trained columns')}</th>
                      <th className="custom-th">{t('Row numbers')}</th>
                      <th className="custom-th">{t('Success')}</th>
                      <th className="custom-th">Gsheet</th>
                    </tr>
                  </thead>
                  {actionsByID?.length ? (
                    <tbody>
                      {actionsByID.map((action, key) => {
                        if (
                          action.action_type === 'session' ||
                          action.action_type === 'autogen'
                        )
                          return null
                        const diff =
                          new Date(action.end_time).getTime() -
                          new Date(action.start_time).getTime()

                        const time = (diff / 1000).toFixed(2)

                        const start = new Date(action.start_time)
                        start.setHours(0, 0, 0, 0)
                        const isToday = start.getTime() === today.getTime()
                        const isYesterday = start.getTime() === yesterday

                        return (
                          <tr className="custom-tr" key={key}>
                            {action.action_type === 'train' ? (
                              <td className="custom-td">
                                <FcSettings />{' '}
                                {t(
                                  action.action_type.charAt(0).toUpperCase() +
                                    action.action_type.slice(1),
                                )}
                              </td>
                            ) : (
                              <td className="custom-td">
                                {action.action_type === 'chat' ? (
                                  <MdOutlineChatBubble />
                                ) : (
                                  <FcLineChart />
                                )}{' '}
                                {t(
                                  action.action_type.charAt(0).toUpperCase() +
                                    action.action_type.slice(1),
                                )}
                              </td>
                            )}
                            <td className="custom-td">{action.model_id}</td>
                            <td className="custom-td">{action.name}</td>
                            <td className="custom-td">
                              {action.model_problem_type?.replace('_', ' ')}
                            </td>
                            <td className="custom-td">{action.target}</td>
                            {isToday && (
                              <td className="custom-td">
                                Today
                                {action.start_time
                                  .replace('Z', ' ')
                                  .replace('T', ' ')
                                  .substring(10, 16)}
                              </td>
                            )}
                            {isYesterday && (
                              <td className="custom-td">
                                Yesterday
                                {action.start_time
                                  .replace('Z', ' ')
                                  .replace('T', ' ')
                                  .substring(10, 16)}
                              </td>
                            )}
                            {!isToday && !isYesterday && (
                              <td className="custom-td">
                                {action.start_time
                                  .replace('Z', ' ')
                                  .replace('T', ' ')
                                  .substring(0, 16)}
                              </td>
                            )}
                            <td className="custom-td">{time} s</td>
                            <td className="custom-td">{action.df_cols}</td>
                            {action.action_type === 'train' ? (
                              <td className="custom-td"> {action.df_rows}</td>
                            ) : (
                              <td className="custom-td"> {action.num_rows}</td>
                            )}
                            <td className="custom-td">
                              {!action.is_error ? <FcOk /> : <FcHighPriority />}
                            </td>
                            {action.action_from === 'gsheet' ? (
                              <td className="custom-td">
                                {' '}
                                {t('Yes')} <FcOk />
                              </td>
                            ) : (
                              <td className="custom-td">
                                {' '}
                                {t('No')} <FcHighPriority />
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <div>{t('This user has 0 actions')}</div>
                  )}
                </table>
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <h5>{t('No user selected')}</h5>
          </Row>
        )}
      </Row>
    </>
  )
}
