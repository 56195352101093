import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

export default function ErrorNB({
  errorMessage = 'Something went wrong',
  size = 6,
  ...props
}) {
  const { t } = useTranslation()
  return (
    <Row
      className={`w-100 h-100 align-items-center justify-content-center ${
        props?.className ?? ''
      }`}
      {...props}
    >
      <Col align="center" xs={size}>
        <div className="p-3 h4">
          {typeof errorMessage === 'string' ? t(errorMessage) : errorMessage}
        </div>
        <br />
        <Image src={'/BrainWarning.svg'} style={{ maxHeight: '40vh' }} />
      </Col>
    </Row>
  )
}
