import { useTranslation } from 'react-i18next'
import { Row, Col, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FcApproval } from 'react-icons/fc'
import Loading from '../loading/LoadingSmall'

function User({ user }) {
  return (
    <Col className="admin-modal-panel-user mt-2" xs={12}>
      <Row>
        <Col className="d-inline-block text-truncate" xs={3}>
          <Link to={`/user/?userId=${user.id}&from=dashboard`}>
            <span
              style={{ color: 'var(--nextbrain-tables-blue-graph-bar-color)' }}
            >
              {user.email}
            </span>
          </Link>
        </Col>
        <Col className="d-inline-block text-truncate" xs={3}>
          {user.country}
        </Col>
        <Col className="d-inline-block text-truncate" xs={3}>
          {user.previous_platforms}
        </Col>
        <Col className="d-inline-block text-truncate" xs={2}>
          {user.introduction}
        </Col>
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={1}
        >
          {user.verified ? <FcApproval /> : <></>}
        </Col>
      </Row>
    </Col>
  )
}

export default function UserList({ users, isLoading, visible, hide }) {
  const { t } = useTranslation()

  return (
    <Modal show={visible} onHide={hide} size={'xl'}>
      <Modal.Header>
        <span className="h3">{t('New Users')}</span>
      </Modal.Header>
      <Modal.Body>
        {visible &&
          (isLoading ? (
            <Loading />
          ) : (
            <Row style={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Col xs={12}>
                <Row>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Email</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Contry</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Previous platforms</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={2}>
                    <span className="small">Introduction</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={1}>
                    <span className="small">Verified</span>
                  </Col>
                </Row>
              </Col>
              {Array.isArray(users) &&
                users.map((user) => <User key={user.id} user={user} />)}
            </Row>
          ))}
      </Modal.Body>
    </Modal>
  )
}
