import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import { ResponsiveBar } from '@nivo/bar'
import { round } from '../utils/formating'
import { getMMMMediaAverageContribution } from '../../util/models'

function HelpOptimizedMediaOutcomeBudget({ model, description }) {
  const { t } = useTranslation()
  const mediaContribution = getMMMMediaAverageContribution(model)
  return (
    <Row className="p-4">
      <Col xs={6} className="d-flex text-center align-items-center">
        {t(description)}
      </Col>
      <Col style={{ minHeight: '150px' }} xs={6}>
        <ResponsiveBar
          data={[
            {
              index: 'Outcome from media contribution',
              'Outcome from media contribution': round(
                mediaContribution * 100,
                2,
              ),
              color: 'var(--nextbrain-tables-graph-bar-color)',
            },
          ]}
          keys={['Outcome from media contribution']}
          indexBy="index"
          margin={{
            top: 10,
            right: 30,
            bottom: 5,
            left: 50,
          }}
          valueFormat={(n) => `${round(n, 0)}%`}
          groupMode="stacked"
          label={(d) => <>{`${d.value}%`}</>}
          layout={'vertical'}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(d) => d.data.color}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Contribution %',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipHeight={0}
          labelTextColor="white"
          labelSkipWidth={10}
          legends={[]}
          theme={{
            textColor: 'var(--nextbrain-white-font)',
            fontSize: 13,
            legends: {
              text: {
                fontSize: 10,
                fill: 'var(--nextbrain-widget-axis-legend)',
              },
            },
          }}
        />
      </Col>
    </Row>
  )
}

function HelpOptimizedMediaOutcomeBudgetTotal({ model, description }) {
  const { t } = useTranslation()
  const mediaContribution = getMMMMediaAverageContribution(model)
  return (
    <Row className="p-4">
      <Col xs={4} className="d-flex text-center align-items-center">
        {t(description)}
      </Col>
      <Col style={{ minHeight: '150px' }} xs={8}>
        <ResponsiveBar
          data={[
            {
              index: 'Outcome from media contribution',
              'Outcome from media contribution': round(
                mediaContribution * 100,
                2,
              ),
              color: 'var(--nextbrain-tables-graph-bar-color)',
            },
            {
              index: 'Outcome from non media contribution',
              'Outcome from non media contribution': round(
                100 - mediaContribution * 100,
                2,
              ),
              color: 'var(--nextbrain-tables-blue-graph-bar-color)',
            },
          ]}
          keys={[
            'Outcome from media contribution',
            'Outcome from non media contribution',
          ]}
          indexBy="index"
          margin={{
            top: 50,
            right: 30,
            bottom: 5,
            left: 50,
          }}
          valueFormat={(n) => `${round(n, 0)}%`}
          groupMode="stacked"
          label={(d) => <>{`${d.value}%`}</>}
          layout={'vertical'}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(d) => d.data.color}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Contribution %',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipHeight={0}
          labelTextColor="white"
          labelSkipWidth={10}
          legends={[
            {
              dataFrom: 'indexes',
              anchor: 'top',
              direction: 'column',
              justify: false,
              translateX: -85,
              translateY: -50,
              itemsSpacing: 4,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              itemTextColor: 'var(--nextbrain-white-font)',
              symbolSize: 10,
              effects: [],
            },
          ]}
          theme={{
            textColor: 'var(--nextbrain-white-font)',
            fontSize: 13,
            legends: {
              text: {
                fontSize: 10,
                fill: 'var(--nextbrain-widget-axis-legend)',
              },
            },
          }}
        />
      </Col>
    </Row>
  )
}

export function HelpOptimizedMediaOutcome({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudget
      model={model}
      description={'Outcome coming from your media investment.'}
    />
  )
}

export function HelpOptimizedMediaOutcomeRoi({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudget
      model={model}
      description={'Return on media investment.'}
    />
  )
}

export function HelpOptimizedMediaOutcomeTotal({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudgetTotal
      model={model}
      description={
        'Total outcome (from media investment and non media contribution).'
      }
    />
  )
}

export function HelpOptimizedMediaOutcomeRoiTotal({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudgetTotal
      model={model}
      description={
        'Total return (from media investment and non media contribution).'
      }
    />
  )
}

export function HelpOptimizedOutcome({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudgetTotal
      model={model}
      description={`Media investment required to get the selected outcome.`}
    />
  )
}

export function HelpOptimizedOutcomeRoi({ model }) {
  return (
    <HelpOptimizedMediaOutcomeBudgetTotal
      model={model}
      description={`Return on media investment.`}
    />
  )
}
