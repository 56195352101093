import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Image, Form } from 'react-bootstrap'
import {
  FaExternalLinkAlt,
  FaEye,
  FaFileCsv,
  FaFilter,
  FaGlasses,
  FaLink,
  FaObjectGroup,
} from 'react-icons/fa'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import NextbrainSelect from '../../NextbrainSelect'
import { useDebouncedCallback } from 'use-debounce'
import { useQuery, useQueryClient } from 'react-query'
import { searchPublicDatasets } from '../../../../services/serp'
import { useAuth } from '../../../../providers/AuthProvider'
import { awaitTaskCall } from '../../../../services/base'
import { checkPublicCSV } from '../../../../services/csv'
import Loading from '../../../loading/LoadingSmall'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'
import ReactPaginate from 'react-paginate'
import HelpTooltip from '../../HelpTooltip'

function SearchResult({ result }) {
  return (
    <Row>
      {result?.favicon && (
        <Col
          className="d-flex align-items-center"
          xs={12}
          style={{ maxWidth: '30px' }}
        >
          <Image
            src={result.favicon}
            style={{ width: '20px', borderRadius: '5px' }}
          />
        </Col>
      )}
      <Col
        xs={12}
        style={{ maxWidth: result?.favicon ? 'calc(100% - 40px)' : '100%' }}
      >
        <Row>
          <Col className="d-inline-block text-truncate" xs={12}>
            {result.title}
          </Col>
          <Col
            className="d-inline-block text-truncate"
            style={{ fontSize: '11px' }}
            xs={12}
          >
            {result.snippet}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function isValidURL(string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(string)
}

const DEFAULT_WEBSITE_OPTIONS = [
  {
    value: 'http://github.com/',
    label: 'http://github.com/',
  },
  {
    value: 'https://data.ohio.gov',
    label: 'https://data.ohio.gov',
  },
  {
    value: 'https://data.gov/',
    label: 'https://data.gov/',
  },
]

export default function ConfigurePublicDataset({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [sample, setSample] = useState(null)
  const [selectedCSV, setSelectedCSV] = useState(
    configuration?.selectedCSV ?? null,
  )
  const [search, setSearch] = useState(configuration?.search ?? '')
  const inputRef = useRef()
  const [selectedWebsite, setSelectedWebsite] = useState(null)
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState(false)

  const filterData =
    filters && selectedWebsite?.value
      ? {
          website: selectedWebsite?.value,
        }
      : {}
  const { data, isLoading } = useQuery(
    ['getPublicDataset', search, filterData],
    async () => {
      if (!search) return []
      setSelectedCSV(null)
      const res = await searchPublicDatasets({
        query: search,
        ...filterData,
        page: 0,
        token,
        signout,
      })
      if (Array.isArray(res)) return res
      return []
    },
    { staleTime: Infinity },
  )
  const newSearch = useDebouncedCallback((target) => setSearch(target), 200)

  const { data: preview, isLoading: previewIsLoading } = useQuery(
    ['getPublicDatasetPreview', selectedCSV?.link],
    async () => {
      if (!selectedCSV?.link) return []
      const sample = await awaitTaskCall(checkPublicCSV, 1000, null, {
        url: selectedCSV?.link,
        token,
        signout,
      }).catch((e) => {})
      if (Array.isArray(sample?.columns) && Array.isArray(sample?.data)) {
        return {
          columns: sample.columns,
          sample: sample.data,
        }
      }
      return {
        columns: [],
        sample: [],
      }
    },
    { staleTime: Infinity },
  )
  useEffect(() => {
    if (preview?.sample?.length) setSample(preview)
  }, [preview])

  return (
    <Row
      className="flex-column justify-content-between"
      style={{ minHeight: '60vh' }}
    >
      <Col xs={12}>
        <Row className="mb-2">
          <Col
            className="my-2"
            xs={'auto'}
            style={{ minWidth: '180px', maxWidth: '180px' }}
          >
            <FaObjectGroup size={20} className="me-2" />
            <strong>{t('Google search')}</strong>
          </Col>
          <Col
            xs={'auto'}
            className="px-0 d-inline-flex "
            style={{ minWidth: '500px', maxWidth: 'calc(100% - 410px)' }}
          >
            <Form.Control
              className="nb-input-soft py-2 mt-0 w-100"
              ref={inputRef}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const value = event.target.value
                  newSearch(value)
                }
              }}
              autoFocus
            />
          </Col>
          <Col
            xs={12}
            className="px-0"
            style={{ minWidth: '230px', maxWidth: '230px' }}
          >
            <span className="d-inline-flex align-items-center ms-2 mt-1">
              <Button
                onClick={() => {
                  const value = inputRef.current.value.trim()
                  if (isValidURL(value)) {
                    const result = [
                      {
                        title: 'Custom link',
                        link: value,
                        snippet: 'Custom link',
                        snippet_highlighted_words: [],
                        favicon: '',
                        position: 1,
                      },
                    ]
                    queryClient.setQueryData(
                      ['getPublicDataset', value, filterData],
                      result,
                    )
                    setSearch(value)
                    setSelectedCSV(result[0])
                  } else newSearch(value)
                }}
              >
                {t('Search')} <FaGlasses />
              </Button>
              <Button
                className="ms-2 empty-secondary original"
                onClick={() => {
                  setFilters((f) => !f)
                }}
              >
                {t('Filters')}
                {filters ? <FaFilter /> : <FaFilterCircleXmark />}
              </Button>
            </span>
          </Col>
        </Row>
        {filters && (
          <Row
            className={`${
              filters ? '' : 'hidden-search'
            } results-public-datasource mb-2`}
          >
            <Col className="my-2" xs={'auto'} style={{ minWidth: '180px' }}>
              <FaLink size={20} className="me-2" />
              <strong>{t('Website')}</strong>
            </Col>
            <Col xs={'auto'} className="px-0 d-inline-flex position-relative">
              <span
                className="d-inline-flex align-items-center"
                style={{ maxWidth: '500px', minWidth: '500px' }}
              >
                <NextbrainSelect
                  creatable={true}
                  className="basic-single w-100 mt-1"
                  classNamePrefix="select"
                  placeholder={t('Write a url or select a default option')}
                  isSearchable={true}
                  isClearable={true}
                  value={selectedWebsite}
                  onChange={(v) => setSelectedWebsite(v)}
                  options={DEFAULT_WEBSITE_OPTIONS}
                  autoFocus
                />
              </span>
              <span
                className="d-inline-flex align-items-center ms-2 position-absolute"
                style={{ right: '-20px', top: 'calc(50% - 10px)' }}
              >
                <HelpTooltip
                  className="help-select-icon"
                  message={t('Search only in this website')}
                />
              </span>
            </Col>
          </Row>
        )}
        <Row
          className={`${
            search ? '' : 'hidden-search'
          } results-public-datasource`}
        >
          <Col className="my-2" xs={'auto'} style={{ minWidth: '180px' }}>
            <FaFileCsv size={20} className="me-2" />
            <strong className={isLoading ? 'loading-tooltip' : ''}>
              {t('Results')}{' '}
              {!isLoading && data?.length ? `(${data?.length})` : ''}
            </strong>
          </Col>
          <Col
            xs={'auto'}
            className="px-0"
            style={{ maxWidth: '500px', minWidth: '500px', minHeight: '70px' }}
          >
            <NextbrainSelect
              value={
                selectedCSV
                  ? {
                      label: <SearchResult result={selectedCSV} />,
                      value: selectedCSV.link,
                      result: selectedCSV,
                    }
                  : null
              }
              onChange={(v) => setSelectedCSV(v.result)}
              className=""
              options={(data ?? []).map((result) => ({
                label: <SearchResult result={result} />,
                value: result.link,
                result,
              }))}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              placeholder={
                isLoading ? (
                  <span className="loading-tooltip">Searching</span>
                ) : (
                  t('Select a public dataset')
                )
              }
              noOptionsMessage={() =>
                isLoading ? (
                  <span className="loading-tooltip">Searching</span>
                ) : (
                  t('No public dataset found')
                )
              }
            />
          </Col>
          {selectedCSV && (
            <Col xs="auto" className="d-flex">
              <a href={selectedCSV.link} target="_blank" rel="noreferrer">
                <FaExternalLinkAlt size={30} className="icon-btn mt-2" />
              </a>
            </Col>
          )}
        </Row>
      </Col>
      {selectedCSV && (
        <>
          <Col className="mt-2" xs={12}>
            <span className="d-inline-flex align-items-center">
              <FaEye size={20} className="me-2" />
              <strong>{t('Preview')}</strong>
            </span>
          </Col>
          <Col
            className="mb-2 merge-table-flow position-relative"
            style={{
              overflow: 'auto',
              position: 'relative',
              zIndex: 0,
              minHeight: 'max(25vh, 300px)',
              maxHeight: 'max(25vh, 300px)',
            }}
          >
            {previewIsLoading || !preview ? (
              <Loading message={t('Loading dataset')} />
            ) : (
              <ColumnPaginatedGridTable
                key="data"
                stableHeight={true}
                header={[preview?.columns ?? []]}
                rows={preview?.sample ?? []}
                rowsPerPage={8}
                headerElement={(c) => (
                  <span className="text-truncate">{c}</span>
                )}
                cellElement={(c) => <span className="text-truncate">{c}</span>}
                index={(i) => (
                  <div
                    className={`grid-table-cell index-cell ${i ? '' : 'first'}`}
                  >
                    {i ? i : ''}
                  </div>
                )}
                className="w-100 table-view-data"
                Pager={({ maxPage, onChange, page }) => (
                  <ReactPaginate
                    className="paginate-view-data"
                    breakLabel="..."
                    nextLabel={t('next')}
                    onPageChange={({ selected }) => onChange(selected)}
                    pageRangeDisplayed={3}
                    pageCount={maxPage}
                    previousLabel={t('previous')}
                    renderOnZeroPageCount={null}
                  />
                )}
                pagerLast={true}
                style={{
                  minHeight: '150px',
                }}
              />
            )}
          </Col>
        </>
      )}
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample?.sample?.length}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              selectedCSV,
              search,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
