import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareError } from 'react-icons/bi'

export default function InvalidOptimization({ ...props }) {
  const { t } = useTranslation()

  return (
    <Row {...props} className={`${props?.className ?? ''}`}>
      <Col xs={12}>
        <Row className="insight-model-content p-3">
          <Col xs={12} style={{ maxWidth: 'calc(100% - 150px)' }}>
            <Row>
              <Col xs={12}>
                <p>
                  {t(
                    'We were unable to calculate a plan under these parameters. You can try to change the total spend or the time.',
                  )}
                  <br />
                  {t(
                    'If you have used some constrains, also you could change it. Remember that the model takes the following factors into account.',
                  )}
                  :
                </p>
                <ul>
                  <li>{t('Channel carryover and lag')}</li>
                  <li>{t('Channel influence')}</li>
                  <li>{t('Channel ROI')}</li>
                  <li>{t('Consumer saturation curve')}</li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ maxWidth: '150px' }}>
            <BiMessageSquareError
              size={150}
              color={'var(--nextbrain-warning-color)'}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
