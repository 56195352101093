import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import {
  getMMMInfluence,
  getMMMModelStackedPlot,
  getMMMStatistics,
} from '../../services/model'
import { colors, dateParams, integerParams } from './config'
import { generateMMMColorMap, getMMMDataColumnInfo } from '../../util/models'
import { useModels } from '../../providers/ModelProvider'
import { zip } from '../../util/other'
import Loading from '../loading/LoadingSmall'
import { useTranslation } from 'react-i18next'
import { ResponsiveLine } from '@nivo/line'
import { nbTheme } from '../utils/ui'
import { nivoLineProps } from '../utils/ui'
import YearlyLayer from './YearlyLayer'
import { useInteractiveLineLegend } from '../graph-layers/useInteractiveLineLegend'
import { NotificationManager } from 'react-notifications'

export default function Influence({ model, isInView = true }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const { activeModel } = useModels()
  if (!model) model = activeModel

  const { data, isLoading, isSuccess } = useQuery(
    ['MMMInfluence', model.id],
    async () => {
      return await getMMMInfluence({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const { data: stackedPlot, isLoading: stackedPlotIsLoading } = useQuery(
    ['mediaContribution', model.id],
    async () => {
      return await getMMMModelStackedPlot({
        modelId: model.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const baseline = useMemo(() => {
    try {
      const i = stackedPlot.columns.indexOf('baseline')
      return stackedPlot.data.map((d) => d[i])
    } catch (e) {
      return null
    }
  }, [stackedPlot])

  const { data: mmmstats, isLoading: isLoadingStats } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
        signout,
      })
      if (!response)
        NotificationManager.error(
          t('Failed to retrieve original weekly Non marketing contributions'),
        )
      return response
    },
    { staleTime: Infinity },
  )

  const [lines, params] = useMemo(() => {
    const mmmDateColInfo = getMMMDataColumnInfo(model)
    const params =
      mmmDateColInfo.mode === 'datetime' ? dateParams : integerParams
    if (data && !isLoading && !stackedPlotIsLoading && !isLoadingStats) {
      const colorMap = generateMMMColorMap(model, colors)
      colorMap['Seasonability'] = 'orange'
      colorMap['Seasonality'] = 'orange'
      colorMap['Trend'] = 'cyan'
      const lines = zip([data.columns, ...data.data]).map(
        ([name, ...values]) => ({
          id: name,
          color: colorMap[name],
          data: values.map((v, i) => ({
            x: mmmDateColInfo.map(i),
            y: v * (baseline?.[i] ?? 1) * (mmmstats?.y?.[i] ?? 1),
          })),
        }),
      )
      const baselineWeekly = new Array(lines[0].data.length).fill(0)
      lines.forEach((channel) => {
        channel.data.forEach((v, i) => {
          baselineWeekly[i] += Number.isNaN(v.y) ? 0 : v.y
        })
      })
      lines.push({
        id: t('Other non media contribution'),
        color: '#dadada',
        data: baselineWeekly.map((v, i) => ({
          x: mmmDateColInfo.map(i),
          y: (baseline?.[i] ?? 1) * (mmmstats?.y_pred?.[i] ?? 1) - v,
        })),
      })
      const sm = {
        id: 'Sum non media contributions = Baseline',
        color: '#3ec73e',
        data: lines[0].data.map(({ x, y }, i) => ({
          x: x,
          y: lines.reduce((acc, line) => acc + line.data[i].y, 0),
        })),
      }
      lines.push(sm)

      return [lines, params]
    }
    return [[], params]
    // eslint-disable-next-line
  }, [data, isLoading, stackedPlotIsLoading, isLoadingStats, model])

  const [shownData, setShownData] = useState(null)

  const csvData = useMemo(() => {
    if (!Array.isArray(shownData?.lines)) return []
    return zip(
      shownData.lines.map((line) => [line.id, ...line.data.map((d) => d.y)]),
    )
  }, [shownData])

  useEffect(() => {
    if (isSuccess) {
      setShownData({
        lines: lines.map((line) => ({
          ...line,
          data: line.data.map((s) => ({ ...s, y: 0 })),
        })),
        customLayer: () => {},
        params,
      })
      if (isInView) {
        let start = 0,
          end = 0
        if (Array.isArray(lines?.[0]?.data)) {
          const base = lines[0].data
          start = base[0]?.x
          end = base[base.length - 1]?.x
        }
        setTimeout(
          () =>
            setShownData({
              lines,
              customLayer: (props) => (
                <YearlyLayer
                  start={start}
                  end={end}
                  ignoreLessThanYear={true}
                  {...props}
                />
              ),
              params,
            }),
          100,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, data, lines])

  const LegendLayer = useInteractiveLineLegend({
    marginBottom: 50,
    paddingLeft: 40,
  })
  const [filters, setFilters] = useState({})

  const [filterData, min] = useMemo(() => {
    if (!Array.isArray(shownData?.lines)) return [[], 'auto']

    const data = shownData.lines.filter((d) => !filters[d.id])
    for (const k of data)
      for (const v of k.data) if (v.y < 0) return [data, 'auto']

    return [data, 0]
  }, [shownData, filters])

  return !shownData || isLoading ? (
    <Loading />
  ) : (
    <ResponsiveLine
      {...nivoLineProps}
      {...shownData.params}
      data={filterData}
      margin={{ top: 20, right: 300, bottom: 100, left: 80 }}
      yScale={{
        type: 'linear',
        min: min,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      areaBlendMode="normal"
      enableArea={true}
      areaOpacity={0.5}
      colors={(d) => d.color}
      curve={'monotoneX'}
      yFormat=" >-.2f"
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        ...shownData.params.axisBottom,
        legend: t('Week'),
        legendOffset: 80,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: model?.target ?? t('Influence'),
        legendOffset: -70,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      legends={[]}
      theme={{
        ...nbTheme,
        tooltip: {
          container: {
            fontSize: 11,
          },
        },
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        (props) => (
          <LegendLayer
            {...props}
            data={shownData.lines}
            filters={filters}
            setFilters={setFilters}
          />
        ),
        () => (
          <div
            className="data-holder display-none"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`underlying_effects__${model.id}`}
          ></div>
        ),
        shownData.customLayer,
      ]}
    />
  )
}
