import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CustomOptimize from './CustomOptimize'
export default function OptimizeTab({ model, ...props }) {
  return (
    <Row {...props}>
      <Col xs={12}>
        <CustomOptimize model={model} className="mx-2 mt-2" />
      </Col>
    </Row>
  )
}
