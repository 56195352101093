import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { WidgetPearsonMatrix } from '../widget-report/Widgets/PearsonMatrix'
import HelpTooltip from '../model-content/HelpTooltip'

import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'
import { getPearson } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'

function estimateFontSizePearson(model) {
  const keys = Object.keys(model.pearson).sort((a, b) => b.length - a.length)
  if (!keys.length) return 15

  const longestKey = keys[0]
  return Math.max(11, 30 - longestKey.length * 0.4 - keys.length * 1.5)
}

const sampleData = {
  'Days of contact': {
    'Days of contact': 1,
    'Deal - Value': 0.5108429882753575,
  },
  'Deal - Value': { 'Days of contact': 0.5108429882753575, 'Deal - Value': 1 },
}

export default function CorrelatinMatrixViewdata({
  model,
  updateModel = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()

  useEffect(() => {
    if (model && (!model.pearson || !Object.keys(model.pearson)?.length)) {
      const tid = setInterval(() => {
        getPearson({ modelId: model.id, token, signout }).then((pearson) => {
          if (pearson && Object.keys(pearson).length) {
            model.pearson = pearson
            updateModel(model.id, { ...model })
            clearInterval(tid)
          }
        })
      }, 10000)
      return () => clearInterval(tid)
    }
    // eslint-disable-next-line
  }, [model])

  if (!model) return <></>

  const validPearson = model.pearson && Object.keys(model.pearson).length
  const localModel = validPearson ? model : { ...model, pearson: sampleData }

  return (
    <Row {...props} className={`position-relative ${props.className ?? ''}`}>
      {validPearson ? (
        <></>
      ) : (
        <Col className="middle-tooltip-loading">
          <div>
            {t('Processing correlation matrix')}
            <div className="bouncing-loader my-1">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Col>
      )}
      <Col className="d-inline-block text-truncate widget-title col-12" xs={12}>
        {t('Correlation Matrix')}{' '}
        <HelpTooltip
          className="help-select-icon"
          message={t('correlation matrix view data')}
          helpLink={'pearson-matrix-1bue39'}
        />
      </Col>
      <Col
        xs={12}
        style={{
          minHeight: '600px',
        }}
      >
        <DownloadGraphOverlay>
          <WidgetPearsonMatrix
            className={`${validPearson ? '' : 'diffuminated-pearson'}`}
            model={localModel}
            config={{
              tooltipFontSize: 15,
              graphFontSize: estimateFontSizePearson(localModel),
              title: '',
            }}
          />
        </DownloadGraphOverlay>
      </Col>
      <Col xs={12}>
        <p>{t('Correlation matrix description')}</p>
      </Col>
    </Row>
  )
}
