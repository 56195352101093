import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useDebouncedCallback } from 'use-debounce'
import $ from 'jquery'
import { default as d3_timeseries } from '../forecast/d3_timeseries.js'

import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import {
  getModelById,
  previewMultivariableForecast,
  trainMultivariableForecast,
} from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import NextbrainSelect, { Option } from '../model-content/NextbrainSelect'

import { useNav } from '../../providers/NavProvider'
import { defaultFormat, round } from '../utils/formating'
import Loading from '../loading/LoadingSmall'
import Slider from '../slider/Slider'
import CrashFallback from '../crash-fallback/CrashFallback'
import ErrorNB from '../loading/ErrorNB'
import { awaitTaskCall } from '../../services/base'
import { zip } from '../../util/other.js'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay.jsx'

const accuracyOptions = [
  { value: 'arima', label: 'Fast (Arima)' },
  { value: 'sarima', label: 'Medium (Sarima)' },
  { value: 'prophet', label: 'Medium (Prophet)' },
  { value: 'bayesian', label: 'Accurate (Slow, Bayesian)' },
]

const quantileOptions = [
  { value: null, label: 'None' },
  { value: 0.95, label: 'Exclude above 95%' },
  { value: 0.9, label: 'Exclude above 90%' },
  { value: 0.85, label: 'Exclude above 85%' },
  { value: 0.8, label: 'Exclude above 80%' },
]

const COLORMAP = {
  20: '#df997f66',
  35: '#df997f55',
  45: '#df997f55',
  50: '#df997f44',
}

function getBands(bands, data, tail) {
  if (!Array.isArray(bands?.index)) return [[], []]
  const keys = new Set(
    bands.columns
      .map((c) =>
        c.replace('band ', '').replace('-up', '').replace('-down', ''),
      )
      .map((v) => parseInt(v)),
  )
  const indexMap = bands.columns.reduce((a, c, i) => {
    a[c] = i
    return a
  }, {})
  const res = [
    [...keys].map((k) => [
      `+${k}% error margin`,
      `-${k}% error margin`,
      COLORMAP[k],
    ]),
    bands.index.map((i, j) => {
      const res = {
        ds: new Date(i),
      }
      for (let k of keys) {
        res[`+${k}% error margin`] = bands.data[j][indexMap[`band ${k}-up`]]
        res[`-${k}% error margin`] = bands.data[j][indexMap[`band ${k}-down`]]
      }
      res[`y`] = data[j]?.forecast
      return res
    }),
  ]
  if (tail) {
    res[1].unshift({
      ds: new Date(tail?.ds),
      ...[...keys].reduce((a, c) => {
        a[`+${c}% error margin`] = tail?.original
        a[`-${c}% error margin`] = tail?.original
        return a
      }, {}),
      y: tail?.original,
    })
  }
  return res
}

function BaseGraph({ model, forecast, baseForecast, height, target, date }) {
  const [downloadData, setDownloadData] = useState([])

  // eslint-disable-next-line
  const drawGraph = () => {
    const preForecast =
      baseForecast?.forecast?.map((d) => ({
        ds: new Date(d.ds),
        original: d.y,
      })) ?? []
    const data = Array.isArray(forecast?.time_series_forecast?.data)
      ? zip([
          forecast.time_series_forecast.data,
          forecast.time_series_forecast.index,
        ]).map(([d, i]) => ({
          ds: new Date(i),
          forecast: d.reduce((a, c) => a + c, 0) / d.length,
        }))
      : []
    const [bandKeys, bands] = getBands(
      forecast.bands,
      data,
      data?.length ? preForecast[preForecast.length - 1] : null,
    )

    let minY = preForecast?.[0]?.original,
      maxY = minY
    if (preForecast?.length)
      preForecast.forEach(({ original }) => {
        minY = Math.min(minY, original)
        maxY = Math.max(maxY, original)
      })

    data.forEach((d) => {
      minY = Math.min(minY, d.forecast)
      maxY = Math.max(maxY, d.forecast)
    })
    const diff = (maxY - minY) * 0.3

    const existsForecast = data?.length
    const header = ['Date', 'Original']
    if (forecast) header.push('Forecast')
    const downloadData = [header]
    downloadData.push(
      ...preForecast.map((d, i) =>
        existsForecast
          ? [d.ds.toISOString(), d.original, '']
          : [d.ds.toISOString(), d.original],
      ),
    )
    if (existsForecast)
      downloadData.push(
        ...data.map((d) => [d.ds.toISOString(), '', d.forecast]),
      )

    setDownloadData(
      <div
        className="d-none data-holder"
        data-csv={encodeURIComponent(JSON.stringify(downloadData))}
        data-filename={`forecast_${model?.dataset?.name}`}
      ></div>,
    )

    $(`#timeseries-mvforecast-model-${model?.id}`).html('')
    let chart = d3_timeseries()
    for (const [bot, top, color] of bandKeys) {
      chart = chart.addSerie(
        bands,
        { x: 'ds', y: 'y', ci_down: bot, ci_up: top },
        { interpolate: 'monotone', color: color, hidden: true },
      )
    }
    chart = chart
      .addSerie(
        preForecast,
        { x: 'ds', y: 'original' },
        { interpolate: 'monotone', color: '#df997f' },
      )
      .addSerie(
        data,
        { x: 'ds', y: `forecast` },
        { interpolate: 'monotone', color: 'red' },
      )
      .margin({ left: 50, right: 50, top: 0, bottom: 20 })
      .width($(`#timeseries-mvforecast-model-${model?.id}`).width())
      .height(height)
      .yscale.domain([minY - diff, maxY + diff])

    chart(`#timeseries-mvforecast-model-${model?.id}`)
  }

  useEffect(() => {
    drawGraph()
    // eslint-disable-next-line
  }, [forecast, baseForecast])

  useEffect(() => {
    window.addEventListener('resize', drawGraph)
    return () => window.removeEventListener('resize', drawGraph)
    // eslint-disable-next-line
  }, [drawGraph])

  return (
    <>
      <div
        id={`timeseries-mvforecast-model-${model?.id}`}
        className="time-series-container-original"
        style={{ minWidth: '100%' }}
      ></div>
      {downloadData}
    </>
  )
}

function DynamicGraph({ model, forecast, target, date }) {
  return (
    <Row>
      <Col style={{ minHeight: '500px', maxHeight: '500px' }} xs={12}>
        <BaseGraph
          forecast={forecast.future_forecast}
          baseForecast={{ forecast: forecast.forecast }}
          model={model}
          height={500}
          target={target}
          date={date}
        />
      </Col>
    </Row>
  )
}

function InputSliderDouble({
  name,
  inputProps = {},
  sliderProps = {},
  defaultLeft,
  defaultRight,
  ...props
}) {
  const inputRef = useRef()
  const inputRef2 = useRef()
  const { t } = useTranslation()

  const update = (left) => {
    let a = inputRef.current.value
    let b = inputRef2.current.value
    const [min, max] = [Math.min(a, b), Math.max(a, b)]
    inputRef.current.value = min
    inputRef2.current.value = max
    inputProps?.onChange({
      target: {
        value: [min, max],
        name,
      },
    })
  }
  return (
    <div
      className="input-slider dynamic-predict-input-slider position-relative"
      {...props}
    >
      <Row>
        <Col xs={6}>
          <div>{t('From')}</div>
          <Form.Control
            ref={inputRef}
            {...inputProps}
            placeholder={defaultLeft}
            onChange={update}
          />
          <Slider
            updateLeft={false}
            updateRight={false}
            onChange={(e) => {
              inputRef.current.value = e
              update(true)
            }}
            {...sliderProps}
            value={sliderProps?.value?.[0] ?? sliderProps?.min}
            min={sliderProps?.min}
            max={/*sliderProps?.value?.[1] ??*/ sliderProps?.max}
            ceiling={sliderProps?.value?.[1] ?? sliderProps?.max}
          />
        </Col>
        <Col xs={6}>
          <div className="d-flex">{t('To')}</div>
          <Form.Control
            ref={inputRef2}
            {...inputProps}
            placeholder={defaultRight}
            onChange={() => update()}
          />
          <Slider
            updateLeft={false}
            updateRight={false}
            onChange={(e) => {
              inputRef2.current.value = e
              update()
            }}
            {...sliderProps}
            value={sliderProps?.value?.[1] ?? sliderProps?.max}
            min={/*sliderProps?.value?.[0] ??*/ sliderProps?.min}
            max={sliderProps?.max}
            ceiling={sliderProps?.max}
          />
        </Col>
      </Row>
    </div>
  )
}

function InputSliderCategory({ selectProps = {} }) {
  return (
    <>
      <div className="base-input" style={{ maxHeight: '70px' }}>
        <NextbrainSelect
          {...selectProps}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
          maxSelected={1}
        />
      </div>
    </>
  )
}

function minMax(model, column, margin = 0.3) {
  if (model.dataset.final_column_status[column] === 'Categorical')
    return {
      min: model.dataset.statistics[column].min,
      max: model.dataset.statistics[column].max,
    }

  const isDate = model.dataset.final_column_status[column] === 'Datetime'
  const min = isDate
    ? new Date(model.dataset.statistics[column].min).getTime()
    : model.dataset.statistics[column].min
  const max = isDate
    ? new Date(model.dataset.statistics[column].max).getTime()
    : model.dataset.statistics[column].max
  const dif = Math.abs(max - min)
  const res = {
    min: min > 0 ? 0 : Math.floor(min - dif * margin),
    max: Math.ceil(max + dif * margin),
  }

  return res
}

const validTargetType = new Set(['Integer', 'Double', 'Float'])

function ConfigureHead({
  model,
  columns,
  target,
  date,
  selectedFeatures,
  onChange,
  accuracy,
  quantile,
  forcedAccuracy,
  setAccuracy,
  setQuantile,
}) {
  const { t } = useTranslation()
  selectedFeatures = [...selectedFeatures]

  const dateColumns = useMemo(
    () =>
      columns.filter(
        (c) => model.dataset.final_column_status[c] === 'Datetime',
      ),
    // eslint-disable-next-line
    [],
  )

  if (dateColumns.length === 1) date = dateColumns[0]

  return [
    <Row className="mx-5 mt-3">
      <Col xl={3} lg={4} md={6} sm={12}>
        <Row className="mx-2">
          <Col className="mb-2" xs={12}>
            {t('Forecast target')}
          </Col>
          <Col xs={12}>
            <NextbrainSelect
              onChange={(value) =>
                onChange(
                  value?.value,
                  date,
                  selectedFeatures.filter((s) => s !== value?.value),
                )
              }
              options={columns
                .filter(
                  (c) =>
                    c !== date &&
                    validTargetType.has(model?.dataset.final_column_status[c]),
                )
                .map((c) => ({ value: c, label: c }))}
              value={target ? { value: target, label: target } : null}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={3} lg={4} md={6} sm={12}>
        <Row className="mx-2">
          <Col className="mb-2" xs={12}>
            {t('Date column')}
          </Col>
          <Col xs={12}>
            <NextbrainSelect
              onChange={(value) =>
                onChange(
                  target,
                  value?.value,
                  selectedFeatures.filter((s) => s !== value?.value),
                )
              }
              options={dateColumns
                .filter((c) => c !== target)
                .map((c) => ({ value: c, label: c }))}
              value={date ? { value: date, label: date } : null}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              disabled={dateColumns.length === 1}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={3} lg={4} md={6} sm={12}>
        <Col xs={12}>
          <Row className="mx-2">
            <Col className="mb-2" xs={12}>
              {t('Model accuraccy')}
            </Col>
            <Col style={{ opacity: forcedAccuracy ? 0.5 : 1 }} xs={12}>
              <NextbrainSelect
                onChange={(value) => setAccuracy(value)}
                options={accuracyOptions}
                value={forcedAccuracy || accuracy}
                isDisabled={!!forcedAccuracy}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
              />
            </Col>
          </Row>
        </Col>
      </Col>
      <Col xl={3} lg={4} md={6} sm={12}>
        <Col xs={12}>
          <Row className="mx-2">
            <Col className="mb-2" xs={12}>
              {t('Outlier threshold')}
            </Col>
            <Col xs={12}>
              <NextbrainSelect
                onChange={(value) => setQuantile(value)}
                options={quantileOptions}
                value={quantile}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
              />
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>,
    <Col xl={3} lg={4} md={6} sm={12}>
      <Row className="me-5">
        <Col xs={12}>
          <NextbrainSelect
            onChange={(value) =>
              onChange(target, date, value?.map((v) => v.value) ?? [])
            }
            options={columns
              .filter(
                (d) =>
                  d !== target &&
                  d !== date &&
                  model?.dataset?.final_column_status[d] !== 'Datetime',
              )
              .map((c) => ({ value: c, label: c }))}
            value={selectedFeatures.sort().map((v) => ({ value: v, label: v }))}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti
            components={{
              Option,
            }}
            maxSelected={2}
          />
        </Col>
      </Row>
    </Col>,
  ]
}

function ModelFields({ model }) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()

  const [target, setTarget] = useState(null)
  const [date, setDate] = useState(null)
  const [enabledFeatures, setEnabledFeatures] = useState(new Set())
  const [accuracy, setAccuracy] = useState(accuracyOptions[2])
  const [quantile, setQuantile] = useState(quantileOptions[0])

  const getDefaultValue = (column) => {
    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return model.dataset.statistics[column].mode
      case 'Double':
      case 'Float':
        return round(model.dataset.statistics[column].mode, 4)
      case 'Categorical':
        return model.dataset.categorical_to_unique[column]?.[0]
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        return new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
      default:
        return 0
    }
  }

  const validColumns = useMemo(() => {
    return Object.keys(model?.dataset?.final_column_status ?? {}).sort((a, b) =>
      model.dataset.final_column_status[a] <
      model.dataset.final_column_status[b]
        ? -1
        : 1,
    )
  }, [model])

  const getDefaultFormState = () => ({})

  const [formState, setFormState] = useState(() => getDefaultFormState())
  const [currentFocus, setCurrentFocus] = useState(validColumns[0])
  const currentConfiguration = Object.keys(formState)
    .filter((k) => enabledFeatures.has(k))
    .reduce((acc, k) => {
      if (formState?.[k]?.value?.length)
        acc[k] =
          model.dataset.final_column_status[k] === 'Categorical'
            ? formState[k]?.value?.map((v) => v.value)
            : {
                min: formState[k]?.value?.[0],
                max: formState[k]?.value?.[1],
              }

      return acc
    }, {})

  const [forecastMode, setForecastMode] = useState(false)

  const [currentQuery, setCurrentQuery] = useState(null)
  const { data: forecastData, isLoading } = useQuery(
    ['mv-forecast', model?.id, currentQuery],
    async () => {
      if (!currentQuery) return null
      const result = await awaitTaskCall(
        trainMultivariableForecast,
        1500,
        Infinity,
        {
          modelId: model.id,
          data: {
            target: currentQuery.target,
            date_column: currentQuery.date,
            column_to_value: currentQuery.currentConfiguration,
            model_type: currentQuery.accuracy?.value,
            quantile: currentQuery.quantile?.value,
          },
          token,
          signout,
        },
      ).catch((e) => {
        return null
      })
      if (!result) NotificationManager.error('Failed to generate forecast')
      return result
    },
    { staleTime: Infinity },
  )

  const [currentPreviewQuery, setCurrentPreviewQuery] = useState(null)
  const { data: previewData, isLoading: previewIsLoading } = useQuery(
    ['mv-forecast-preview', model?.id, currentPreviewQuery],
    async () => {
      if (!currentPreviewQuery) return null
      const result = await previewMultivariableForecast({
        modelId: model.id,
        data: {
          target: currentPreviewQuery.target,
          date_column: currentPreviewQuery.date,
          column_to_value: currentPreviewQuery.currentConfiguration,
          model_type: 'arima',
        },
        token,
        signout,
      })
      if (!result) NotificationManager.error('Failed to load preview')
      else {
        const indexDate = result.columns.indexOf(currentPreviewQuery.date)
        const indexTarget = result.columns.indexOf(currentPreviewQuery.target)
        const forecast = result.data.map((d) => ({
          ds: d[indexDate],
          original: d[indexTarget],
          y: d[indexTarget],
        }))

        return {
          filtered_rows: result.filtered_rows,
          preview: true,
          forecast,
          future_forecast: {
            time_series_forecast: {
              columns: [],
              index: [],
              data: [],
            },
            bands: {
              columns: [],
              index: [],
              data: [],
            },
          },
        }
      }
    },
    { staleTime: Infinity },
  )

  const updatePreview = useDebouncedCallback(
    (target, date, currentConfiguration) => {
      if (target && date) {
        setCurrentPreviewQuery(
          JSON.parse(
            JSON.stringify({
              target,
              date,
              currentConfiguration,
            }),
          ),
        )
      }
    },
    500,
  )
  const currentPreviewQueryStr = JSON.stringify(currentConfiguration)
  useEffect(() => {
    updatePreview(target, date, currentConfiguration)
    // eslint-disable-next-line
  }, [target, date, currentPreviewQueryStr])

  const renderPredictField = (column) => {
    const handleCategoricalChange = (value) => {
      setFormState((prevState) => {
        prevState[column] = {
          value: value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    const handleDateChange = (event) => {
      setFormState((prevState) => {
        if (isNaN(new Date(event.target.value)))
          prevState[event.target.name] = {
            value: getDefaultValue(column),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: new Date(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    const handleDoubleChange = (event) => {
      setFormState((prevState) => {
        prevState[column] = {
          value: event.target.value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return (
          <InputSliderDouble
            model={model}
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleDoubleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column]?.value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            defaultLeft={formState[column]?.value?.[0]}
            defaultRight={formState[column]?.value?.[1]}
          />
        )
      case 'Double':
      case 'Float':
        return (
          <InputSliderDouble
            model={model}
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleDoubleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column]?.value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            defaultLeft={formState[column]?.value?.[0]}
            defaultRight={formState[column]?.value?.[1]}
          />
        )
      case 'Categorical': {
        const options = [
          ...(model.dataset.categorical_to_unique?.[column] ?? []),
        ]
          .sort()
          .map((option) => ({
            value: option,
            label: option,
          }))
        return (
          <InputSliderCategory
            selectProps={{
              className: 'basic-single mt-2',
              type: 'train',
              classNamePrefix: 'select',
              options,
              defaultValue: [],
              onChange: handleCategoricalChange,
              name: column,
              onFocus: () => setCurrentFocus(column),
              value: formState[column]?.value ?? [],
            }}
          />
        )
      }
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        now = new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
        return (
          <Form.Control
            name={column}
            type="datetime-local"
            placeholder="Datetime"
            aria-label="Number"
            className="no-arrows nb-input-soft force big-form mt-0"
            onChange={handleDateChange}
            defaultValue={now}
            onFocus={() => setCurrentFocus(column)}
          />
        )
      default:
        return <p className="my-2">WIP: Invalid type for now</p>
    }
  }

  const lowSamples =
    (previewData?.filtered_rows || previewData?.filtered_rows === 0) &&
    previewData?.filtered_rows <= 10
  const noSamples = lowSamples && previewData?.filtered_rows === 0

  const [head, select] = ConfigureHead({
    model,
    columns: validColumns,
    target,
    date,
    selectedFeatures: enabledFeatures,
    forcedAccuracy: lowSamples
      ? accuracyOptions[accuracyOptions.length - 1]
      : null,
    onChange: (target, date, selectedFeatures) => {
      setTarget(target)
      setDate(date)

      for (const feature of selectedFeatures) {
        if (!formState[feature]?.value) {
          if (model?.dataset?.final_column_status?.[feature] === 'Categorical')
            formState[feature] = { value: [], isDefault: true }
          else
            formState[feature] = {
              value: [
                model?.dataset?.statistics?.[feature]?.min ?? 0,
                model?.dataset?.statistics?.[feature]?.max ?? 0,
              ],
              isDefault: true,
            }
        }
      }
      setForecastMode(false)
      setEnabledFeatures(new Set(selectedFeatures))
    },
    accuracy,
    quantile,
    setAccuracy: (v) => {
      setForecastMode(false)
      setAccuracy(v)
    },
    setQuantile,
  })
  const graphData = forecastMode ? forecastData : previewData
  const isLoadingG = forecastMode ? isLoading : previewIsLoading

  const acc = defaultFormat({
    num: graphData?.scores?.['100 - Weighted MAPE'] ?? 0,
  })

  const formatDate = (date) => {
    // If date is string
    if (typeof date === 'string' || date instanceof String) {
      return date.split('T')[0]
    }
    return date.toISOString().split('T')[0]
  }

  return (
    <>
      <Row className={`mb-4 header-app px-0`}>
        <Col md={12} className="d-flex align-items-center px-4">
          {t('Multivariable Forecast')}
        </Col>
      </Row>
      <Container className="px-0">
        <Row className="px-0 justify-content-end mt-3">
          <Col className="h4 mb-2 color-white ps-0" xs={12}>
            {t('Input values')}{' '}
            {model?.dataset?.name ? (
              <span>
                {t('for the {{modelName}} model', {
                  modelName: model.dataset.name,
                })}{' '}
                {t('forecast')}
              </span>
            ) : (
              <></>
            )}
          </Col>
          <Col xs={12}>{head}</Col>
          <Col className="predict-form-inputs mt-4" xs={12}>
            <Row className="me-5">
              <Col
                className="h4 mb-2 color-white ps-0 dflex-center"
                xs={'auto'}
              >
                {t('Filters')}
              </Col>
              {select}
            </Row>
            <Row>
              <Col className="predict-form-inputs mt-3" xs={12}>
                {enabledFeatures.size > 0 && validColumns && (
                  <Row>
                    <Col className="h4 mb-2 color-white ps-0" xs={12}>
                      {t('For values')}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row className="mx-5">
              {enabledFeatures.size > 0 && validColumns ? (
                <>
                  {validColumns
                    .filter((c) => enabledFeatures.has(c))
                    .map((column) => (
                      <Col key={column} xl={3} lg={4} md={6} sm={12}>
                        <Row className="mb-1 mx-2 flex-column">
                          <Col md={12}>
                            <span
                              className={`predict-input-head d-inline-block text-truncate ${
                                column === currentFocus
                                  ? 'active-predict-input-head'
                                  : ''
                              }`}
                            >
                              {model?.dataset?.final_column_status?.[column] ===
                              'Categorical'
                                ? t(`With {{column}} values equal to:`, {
                                    column,
                                  })
                                : t(`With {{column}} values between:`, {
                                    column,
                                  })}
                            </span>
                          </Col>
                          <Col className="predict-input" md={12}>
                            {renderPredictField(column)}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xl={'auto'}>
                <Row className="mt-3 p-3 mx-2">
                  <Col className="d-flex align-items-end" xs={'auto'}>
                    <Button
                      className="action-button"
                      onClick={() => {
                        setForecastMode(true)
                        setCurrentQuery({
                          target,
                          date,
                          currentConfiguration,
                          accuracy: lowSamples
                            ? accuracyOptions[accuracyOptions.length - 1]
                            : accuracy,
                          quantile: quantile,
                        })
                      }}
                      disabled={isLoading || !target || !date || noSamples}
                    >
                      {t('Generate Forecast')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {noSamples ? (
            <Col className="text-center my-2 h5" xs={12}>
              {t('No rows found with the current filters')}
            </Col>
          ) : lowSamples ? (
            <Col className="text-center my-2 h5" xs={12}>
              {t(
                'Low number of rows found with the current filters, only bayesian model supported for this forecast.',
              )}
            </Col>
          ) : (
            <></>
          )}
          <Col xs={12}>
            <Row className="flex-column" style={{ minHeight: '550px' }}>
              {isLoadingG && (
                <Col className="mt-5" xs={12}>
                  <Loading />
                </Col>
              )}
              {!isLoadingG && graphData && (
                <>
                  <Col className="h4 mt-4 color-white ps-0" xs={12}>
                    {forecastMode ? (
                      <>
                        {t('Forecast')}{' '}
                        <span className="ms-3" style={{ fontSize: '17px' }}>
                          <span
                            style={{ color: acc > 40 ? '#3ec73e' : '#DA5B0C' }}
                          >
                            {acc}%
                          </span>{' '}
                          {t('Accuracy')}
                        </span>
                      </>
                    ) : (
                      <>
                        {t('Data preview')}{' '}
                        <span
                          className="blockquote-footer small"
                          style={{ fontSize: '.6em' }}
                        >
                          <span className="text-white">
                            {` ${defaultFormat({
                              num: graphData.filtered_rows,
                            })}`}
                          </span>{' '}
                          <span>rows.</span>
                          {graphData.forecast.length ? (
                            <>
                              <span>From</span>
                              <span className="text-white">
                                {` ${formatDate(graphData.forecast[0]?.ds)} `}
                              </span>
                              <span>to</span>
                              <span className="text-white">
                                {` ${formatDate(
                                  graphData.forecast[
                                    graphData.forecast.length - 1
                                  ]?.ds,
                                )}`}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </>
                    )}
                  </Col>
                  <Col className="predict-form-results px-0 mb-3" xs={12}>
                    <CrashFallback
                      style={{ minHeight: '0px' }}
                      message={<ErrorNB style={{ minWidth: '300px' }} />}
                      t={t}
                    >
                      <DownloadGraphOverlay
                        buttonsStyle={{ top: '3px', right: '5px' }}
                      >
                        <DynamicGraph
                          model={model}
                          forecast={graphData}
                          target={target}
                          date={date}
                        />
                      </DownloadGraphOverlay>
                    </CrashFallback>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default function MultivariableForecast({
  setTitle,
  defaultModel = null,
  hideNav = true,
}) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(defaultModel)
  const param = useParams()
  const { setShowNav } = useNav()

  useEffect(() => {
    if (hideNav && window.self !== window.top) {
      setShowNav(false)
      return () => setShowNav(true)
    }
    // eslint-disable-next-line
  }, [])

  const { isLoading, data } = useQuery(
    `model-${param.id}`,
    async () => defaultModel ?? (await getModelById(param.id, token, signout)),
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      setTitle(`${t('Multivariable forecast')} | ${t('NextBrain')}`)
      return
    }
    setTitle(
      `${t('Multivariable forecast')} ${model.dataset.name} | ${t(
        'NextBrain',
      )}`,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading)
    return <LoadingModel shortMsg={t('Loading multivariable forecast')} />

  if (!model) return <ModelNotFound />

  return <ModelFields model={model} />
}
