import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsiveLine } from '@nivo/line'

import $ from 'jquery'

export function ConfigDataTrend({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.trend-title').val(),
    graphFontSize: Number.parseInt($('.trend-graph-font-size').val()) ?? 12,

    requests: [`dataTrendPoints`],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="trend-title"
            defaultValue={`${config.title ?? 'Data Trend'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="trend-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}
export function WidgetDataTrend({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  let data = []
  if (requestedData?.dataTrendPoints?.data) {
    data.push({
      id: 'trend',
      data: requestedData?.dataTrendPoints?.data.map((point) => {
        const [date, value] = point
        const formattedDate = new Date(date).toISOString().slice(0, 7)
        return { x: formattedDate, y: value }
      }),
    })
  }
  if (!model || !config || data.length === 0) {
    return <>Loading...</>
  }

  return (
    <>
      <Col xs={12} style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col
            className="d-inline-block text-truncate mx-2 "
            xs={12}
            style={{ height: '30px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {config.title}
          </Col>
          <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
            {data.length !== 0 && (
              <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                  stacked: false,
                  reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                axisBottom={{
                  tickValues: data[0].data
                    .filter((d, i) => i % 12 === 0)
                    .map((d) => d.x),
                  tickFormat: (value) => value.substring(0, 7),
                  tickRotation: 45,
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fill: '#F0EFFF88',
                      },
                    },
                  },
                }}
                useMesh={true}
                colors={'#1972f5'}
              />
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}
