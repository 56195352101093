import { useState } from 'react'
import { Row, Col, Button, Image, Alert } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import { useAuth } from '../../providers/AuthProvider'
import SpreadsheetNav from './SpreadsheetNav'
import RoleDisable from '../utils/RoleDisable'

export default function SpreadsheetLogin({
  actionLabel = 'Create model',
  configuration,
  onFinish = null,
  onNameChange = () => {},
  sampleData,
  close,
  onAskAi = false,
  ...props
}) {
  const { t } = useTranslation()
  const {
    google_access_token,
    clearGoogleAccessToken,
    googleLoginWithFiles,
    loginWithGoogle,
  } = useAuth()

  const [authError, setAuthError] = useState(false)

  return google_access_token ? (
    <Row className="my-2">
      <Col className="mb-3" xs={12} style={{ minHeight: '150px' }}>
        <SpreadsheetNav
          actionLabel={actionLabel}
          onAuth={() => setAuthError(false)}
          onFailedAuth={() => setAuthError(true)}
          onFinish={onFinish}
          sampleData={sampleData}
          onNameChange={onNameChange}
          onAskAi={onAskAi}
          {...props}
        />
      </Col>
      <Col className="d-flex justify-content-end pe-0" xs={12}>
        <Button
          disabled={loginWithGoogle}
          className="w-100 p-2 logingooglebutton"
          style={{ maxWidth: '400px' }}
          onClick={() => {
            clearGoogleAccessToken()
          }}
        >
          {
            <>
              <Image
                alt={t('Google icon')}
                src="/Google-icon.svg"
                height={25}
                width={25}
                style={{ display: 'inline-block' }}
              />
              <strong className="text-dark mx-3" style={{ fontSize: 16 }}>
                {t('Disconnect')}
              </strong>
            </>
          }
        </Button>
      </Col>
    </Row>
  ) : (
    <Row className="justify-content-center" {...props}>
      <Col xs={'auto'}>
        <RoleDisable className="w-100">
          <Button
            disabled={loginWithGoogle}
            className="w-100 p-2 logingooglebutton"
            style={{ maxWidth: '400px' }}
            onClick={() => {
              googleLoginWithFiles()
            }}
          >
            {loginWithGoogle ? (
              <div className="bouncing-loader alternative my-1">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <>
                <Image
                  alt={t('Google icon')}
                  src="/Google-icon.svg"
                  height={25}
                  width={25}
                  style={{ display: 'inline-block' }}
                />
                <strong className="text-dark mx-3" style={{ fontSize: 16 }}>
                  {t('Connect to Google sheets')}
                </strong>
              </>
            )}
          </Button>
        </RoleDisable>
      </Col>
      <Col xs={12}></Col>
      {authError ? (
        <Col xs={'auto'} className="mt-2">
          <Alert variant="danger">
            {t(
              'Auth failed, make sure when you share your spreadsheets and files when connecting to Google sheets',
            )}
          </Alert>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  )
}
