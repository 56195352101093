import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { ResponsiveScatterPlotCanvas } from '@nivo/scatterplot'
import $ from 'jquery'
import { line, curveNatural } from 'd3-shape'
import { round } from '../../utils/formating'

export function ConfigPredictedActual({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.predictedactual-title').val(),
    graphFontSize:
      Number.parseInt($('.predictedactual-graph-font-size').val()) ?? 16,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="predictedactual-title"
            defaultValue={`${config.title ?? 'Predicted VS Actual values'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="predictedactual-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 10}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function LineLayer(data) {
  const lineGenerator = line()
    .curve(curveNatural)
    .x((d) => d.x)
    .y((d) => d.y)

  return (
    <path
      d={lineGenerator([
        { x: data.innerWidth, y: 0 },
        { x: 0, y: data.innerHeight },
      ])}
      fill="none"
      stroke="#a1a1a1"
      strokeWidth={1}
      style={{
        pointerEvents: 'none',
        strokeDasharray: '3,3',
      }}
    />
  )
}

const roundIfNumber = (v) => (typeof v !== 'string' ? round(v, 2) : v)

function PAScatter({ model, config, editing }) {
  const Component = editing
    ? ResponsiveScatterPlotCanvas
    : ResponsiveScatterPlot

  return (
    <Component
      data={[{ id: model.target, data: model.true_vs_predict }]}
      margin={{ top: 10, right: 30, bottom: 60, left: 70 }}
      xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
      colors={(d) =>
        d.serieId === 'Predicted' || d.serieId === 'Bad' ? '#B32318' : '#21cd99'
      }
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `True ${model.target}`,
        legendPosition: 'middle',
        legendOffset: 46,
        format: '>-.2s',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `Predicted ${model.target}`,
        legendPosition: 'middle',
        legendOffset: -60,
        format: '>-.2s',
      }}
      tooltip={({ node }) => (
        <BasicTooltip
          id={node.serieId}
          value={`True: ${roundIfNumber(
            node.xValue,
          )}, Predicted: ${roundIfNumber(node.yValue)}`}
          enableChip
        />
      )}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 130,
          translateY: 0,
          itemWidth: 100,
          itemHeight: 12,
          itemsSpacing: 5,
          itemDirection: 'left-to-right',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      layers={[
        'grid',
        LineLayer,
        'axes',
        'nodes',
        ...(editing ? [] : ['markers']),
        'mesh',
        'legends',
        'annotations',
        () => (
          <div
            className="data-holder display-none"
            data-csv={encodeURIComponent(
              JSON.stringify([
                ['true', 'predicted'],
                ...model.true_vs_predict.map((d) => [d.x, d.y]),
              ]),
            )}
            data-filename={`predicted_vs_actual__${model.id}`}
          ></div>
        ),
      ]}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
      animate={false}
    />
  )
}

export function WidgetPredictedActual({
  model,
  config,
  id,
  requestedData = {},
  editing = false,
  ...props
}) {
  if (!model || !config || !model.true_vs_predict) return <>Loading...</>

  const graph = () => {
    switch (config.graphType) {
      default:
      case 'pie':
        return <PAScatter editing={editing} model={model} config={config} />
    }
  }

  return (
    <Row className="w-100 h-100" id={id}>
      <Col className="d-inline-block text-truncate widget-title" xs={12}>
        {config.title}
      </Col>
      <Col xs={12} style={{ height: 'calc(100% - 60px)' }} className="pie">
        {graph()}
      </Col>
    </Row>
  )
}
