import { URL } from './base'

/**
 *
 * @param username str
 * @param password str
 * @returns {
 *  access_token: str,
 *  token_type: str (Bearer)
 * }
 */
export async function loginUser({ username, password }) {
  return fetch(`${URL}/login/access-token`, {
    method: 'POST',
    body: `grant_type=&username=${username}&password=${password}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((data) => {
    if (data.status >= 500)
      return {
        error: true,
        title: 'Server under maintenance',
        message: 'Please wait a few minutes.',
      }
    return data.json()
  })
}

export async function microsoftLoginUser(token) {
  return fetch(
    `${URL}/microsoft-login?token=${encodeURIComponent(
      token,
    )}&from_gsheet=false`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((data) => {
    if (data.status >= 500)
      return {
        error: true,
        title: 'Server under maintenance',
        message: 'Please wait a few minutes.',
      }
    return data.json()
  })
}

/**
 *
 * @param email str
 * @param password str
 * @param student bool
 * @param platform_use_description str max length of 1024
 * @param industry str one of:
 *  energy, financial, engineering, government, healthcare, manufacturing, media, retail, unknown
 * @param ml_level str one of:
 *  beginner, intermediate, advanced
 * @returns {
 *  access_token: str,
 *  token_type: str (Bearer)
 * }
 */
export async function signUp({
  name,
  email,
  password,
  student,
  platform_use_description,
  country,
  introduction,
  previous_platforms,
  industries,
  ml_level,
}) {
  return fetch(`${URL}/sign_up?from_gsheet=false`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      password,
      student,
      platform_use_description,
      country,
      introduction,
      previous_platforms,
      industries,
      ml_level,
      user_type: 'advanced', // TODO: Remove this, deprecated field, always advanced
    }),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((data) => {
      if (data.status >= 500)
        return {
          error: true,
          title: 'Server under maintenance',
          message: 'Please wait a few minutes.',
        }
      return data.json()
    })
    .catch((e) => {
      console.log('Error', e)
    })
}

export async function signUpQuick({ email, password, country }) {
  return fetch(`${URL}/sign_up_quick?from_gsheet=false`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      country,
    }),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function exchangeGoogleLogin({
  code,
  getGoogleAccessToken = false,
}) {
  let redirectUri = window.location.host
  if (redirectUri.includes('localhost')) {
    redirectUri = 'http://' + redirectUri
  } else {
    redirectUri = 'https://' + redirectUri
  }
  const path = getGoogleAccessToken ? 'google-token' : 'google-login'
  const extraQueryParams = getGoogleAccessToken ? '' : '&from_gsheet=false'
  return fetch(
    `${URL}/${path}?code=${encodeURIComponent(
      code,
    )}&redirect_uri=${encodeURIComponent(redirectUri)}${extraQueryParams}`,
    {
      method: 'GET',
    },
  )
    .then((data) => {
      if (data.status >= 500)
        return {
          error: true,
          title: 'Server under maintenance',
          message: 'Please wait a few minutes.',
        }
      return data.json()
    })
    .catch((e) => {
      return {
        error: true,
        title: 'Server under maintenance',
        message: 'Please wait a few minutes.',
      }
    })
}

export async function recoverPassword(email) {
  return fetch(`${URL}/password-recovery?email=${encodeURIComponent(email)}`, {
    method: 'POST',
  }).then(async (d) => {
    switch (d.status) {
      case 200:
        return { ok: true }
      default:
        return {
          ok: false,
          msg: (await d.json()).msg,
        }
    }
  })
}
export async function passwordApplyRecovery(password, token) {
  return fetch(`${URL}/password-apply-recovery`, {
    method: 'POST',
    body: JSON.stringify({ password, token }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async (d) => {
    switch (d.status) {
      case 200:
        return await d.json()
      default:
        return d.status
    }
  })
}

export async function setNewPassword(old_password, new_password, token) {
  return fetch(`${URL}/update-password`, {
    method: 'POST',
    body: JSON.stringify({ old_password, new_password, token }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async (d) => {
    switch (d.status) {
      case 200:
        return await d.json()
      default:
        return
    }
  })
}
