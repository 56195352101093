function getXY(base, index) {
  const width = base
  const x = (index * base) % 12
  const y = Math.floor((index * base) / 12) * 8
  return { w: width, x, y }
}

export function getTemplate(model, LIDAGraphs) {
  const baseWidth = window.innerWidth / 60
  let widthItem = 4
  if (baseWidth < 24) widthItem = 6
  if (baseWidth < 16) widthItem = 12

  const res = [
    {
      key: 1,
      config: {
        layout: {
          h: 8,
          ...getXY(widthItem, 0),
          i: '19',
          moved: false,
          static: false,
        },
        title: 'Model Accuracy',
        graphFontSize: 16,
        accuracy: 'bo',
        graphType: 'pie',
        id: 3,
        name: 'Global accuracy',
      },
      help: 'global-accuracy-1yzxobx',
    },
    {
      key: 2,
      config: {
        layout: {
          h: 8,
          ...getXY(widthItem, 1),
          i: '20',
          moved: false,
          static: false,
        },
        title: 'Advanced Statistics',
        fontSize: 12,
        precission: 4,
        id: 13,
        name: 'Advanced Statistics',
      },
      help: 'advanced-statistics-1skca2c',
    },
    {
      key: 3,
      config: {
        layout: {
          h: 8,
          ...getXY(widthItem, 2),
          i: '21',
          moved: false,
          static: false,
        },
        title: 'Column importance',
        graphFontSize: 12,
        graphType: 'col',
        limit: '6',
        requests: [],
        id: 5,
        name: 'Column Importance',
      },
      help: 'column-importance-4vp1ng',
    },
  ]
  if (Array.isArray(LIDAGraphs))
    LIDAGraphs.forEach((graph, index) => {
      res.push({
        key: 4 + index,
        config: {
          layout: {
            h: 8,
            ...getXY(widthItem, 3 + index),
            i: `${23 + index}`,
            moved: false,
            static: false,
          },
          title: graph?.goal?.question,
          goal: graph?.goal?.id,
          id: 36,
          name: 'AI Generated graphs',
        },
        help: 'lida-graphs',
      })
    })

  return res
}
