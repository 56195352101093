import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import { editPrivacy, getModelById } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'

import { NotificationManager } from 'react-notifications'
import { useNav } from '../../providers/NavProvider'
import NBSpreadsheet from './Spreadsheet'

export default function SpreadsheetShare({
  setTitle,
  defaultModel = null,
  hideNav = true,
}) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(defaultModel)
  const [isPrivatePredict, setIsPrivatePredict] = useState(true)
  const param = useParams()
  const { setShowNav } = useNav()
  useEffect(() => {
    if (hideNav) {
      setShowNav(false)
      return () => setShowNav(true)
    }
    // eslint-disable-next-line
  }, [])

  const { isLoading, data } = useQuery(
    `model-${param.id}`,
    async () => defaultModel ?? (await getModelById(param.id, token, signout)),
  )

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      setTitle(`${t('Predict')} | ${t('NextBrain')}`)
      return
    }
    setTitle(`${t('Predict')} ${model.dataset.name} | ${t('NextBrain')}`)
    setIsPrivatePredict(model.is_private_predict)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading)
    return <LoadingModel shortMsg={t('Loading web app prediction')} />

  if (!model || (isPrivatePredict && !model.user_has_access_to_model))
    return <ModelNotFound />

  return (
    <Container>
      <Row className="mt-4 mb-2">
        <Col md={12}>
          <h5>
            {model && model.status === 'trained'
              ? model.dataset.name + ' | '
              : ''}{' '}
            {t('Prediction')}
          </h5>
        </Col>
      </Row>
      <Row>
        {model?.user_has_access_to_model ? (
          <Col align="right" md={12} style={{ marginTop: -40 }}>
            <Form className="form-inline">
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label={t('Share web app prediction')}
                  className="form-switch-share"
                  defaultChecked={!isPrivatePredict}
                  onChange={async (e) => {
                    const newIsPrivatePredict = !e.target.checked
                    setIsPrivatePredict(newIsPrivatePredict)
                    const options = { private_predict: newIsPrivatePredict }
                    // In order to predict a model the report should be also publicly accessible
                    // We can change it in the future, it is just because it call the same /model/get_model/:model-id
                    if (!newIsPrivatePredict) {
                      if (navigator?.clipboard?.writeText) {
                        options.private_report = false
                        navigator.clipboard.writeText(window.location.href)
                      } else
                        NotificationManager.error('Clipboard not supported')
                    }
                    NotificationManager.info(
                      newIsPrivatePredict
                        ? t('Predict is now private')
                        : t('Predict is now public'),
                    )
                    await editPrivacy(model.id, options, token, signout)
                  }}
                />
              </Form.Group>
            </Form>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <NBSpreadsheet model={model} />
    </Container>
  )
}
