import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import BouncyButton from '../../bouncy-button/BouncyButton'
import { modelIsTraining } from '../../../util/models'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { MdOutlineWarning } from 'react-icons/md'
import { useAuth } from '../../../providers/AuthProvider'

function Warning({ children }) {
  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="auto"
      delay={{ show: 100, hide: 1000 }}
      overlay={(props) => <Tooltip {...props}>{children}</Tooltip>}
    >
      <Button className="w-100 text-center original" variant="warning">
        <MdOutlineWarning size={25} color="black" />
      </Button>
    </OverlayTrigger>
  )
}

export default function TrainButton({
  model,
  children,
  trainProps = {},
  stopProps = {},
  enoughRows = true,
  needsPlugin = false,
  ...props
}) {
  const { t } = useTranslation()
  const { maxModels } = useAuth()
  const [stoped, setStoped] = useState(false)

  const training = modelIsTraining(model)
  useEffect(() => {
    if (stoped && !training) {
      setStoped(false)
    }
  }, [stoped, training])

  if (!model) return <Warning>{t('No model')}</Warning>
  return (
    <>
      {needsPlugin ? (
        <>
          <Link to="/plan?tab=1">
            <Warning>
              <span className="small">
                {t('Marketing Mix Modeling Plugin required to train')}
              </span>
            </Warning>
          </Link>
        </>
      ) : maxModels < 1 ? (
        <Warning>
          {t('Plan limit reached')}
          <br />
          {t('Not enough models left on your current plan')}
        </Warning>
      ) : enoughRows ? (
        <BouncyButton
          disabled={true}
          {...props}
          {...trainProps}
          className={`${props.className ?? ''} ${
            trainProps.className ?? ''
          } justify-content-center ${!training ? 'd-flex' : 'd-none'}`}
        >
          {t('Train')}
          {model?.status === 'trained' || model?.status === 'error'
            ? ' ' + t('again')
            : ''}
        </BouncyButton>
      ) : (
        <Warning>
          {t(
            'Not enough rows left to train this model, upgrade your plan to increase the monthly limit',
          )}
        </Warning>
      )}

      <BouncyButton
        {...props}
        {...stopProps}
        loading={stoped}
        disabled={stoped}
        onClick={() => {
          setStoped(true)
          stopProps?.onClick()
        }}
        className={`${props.className ?? ''} ${stopProps.className ?? ''} ${
          training ? 'd-flex justify-content-center' : 'd-none'
        }`}
      >
        {t('Stop training')}
      </BouncyButton>
    </>
  )
}
