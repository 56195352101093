import { FaDatabase, FaFile, FaUpload } from 'react-icons/fa'
import { MdModelTraining, MdPublic } from 'react-icons/md'
import { SiDuckdb, SiGooglesheets, SiMongodb } from 'react-icons/si'
import { FaFilter, FaObjectGroup, FaPlus } from 'react-icons/fa'
import { TiFlowMerge } from 'react-icons/ti'
import { VscServerProcess } from 'react-icons/vsc'
import { BsUnion } from 'react-icons/bs'
import { MdAddToQueue } from 'react-icons/md'
import ExistingData from '../new-model-source/ExisistingData'
import LocalFile from '../new-model-source/LocalFile'
import SpreadsheetLogin from '../../spreadshet/SpreadsheetLogin'
import Database from '../new-model-source/Database'
import ApiSource from '../new-model-source/ApiSource'
import ConfigureMerge from './configure-action/ConfigureMerge'
import ConfigureFilter from './configure-action/ConfigureFilter'
import DatasourceConnectorNode from './DatasourceConnectorNode'
import DatasourceActionNode from './DatasourceActionNode'
import DefaultOutputNode from './DatasourceOutputNode'
import ConfigureTransform from './configure-action/ConfigureTransform'
import MongoDB from '../new-model-source/MongoDB'
import ConfigureConcat from './configure-action/ConfigureConcat'
import ConfigureAggregate from './configure-action/ConfigureAggregate'
import ConfigureChangeTypes from './configure-action/ConfigureChangeTypes'
import ConfigureEnrichment from './configure-action/ConfigureEnrichment'
import ConfigurePublicDataset from './configure-action/ConfigurePublicDataset'
import { GiArtificialIntelligence } from 'react-icons/gi'
import ConfigureAITransform from './configure-action/ConfigureAITransform'
import ConfigureDataWarehouse from './configure-action/ConfigureDataWarehouse'

/*
Guide to adding new components:
  1) Add the name of the component to the datasourceConfig or actionConfig and basic configuration
  2) The prepare function in the objects does validation ensuring the data is valid for nodes down the line
  3) Add a function actionUnbind object with the item name to be called when the node is unbound
  4) Add a function to actionBind/dataSource bind with further to decide wheter the node is valid or not depending on the inputs,
     generally datasource nodes have no input so they dont require this 
  5) Create a component and update the object to fullfill the mision of the component, update the object with this component.
     Components can receive inputs and must produce an output consisting of a sample and a configuration to be validated by the functions
     defined in the object with name prepareData
  6) Add serialization and deserialization in the file validation.js 
  7) Optional: If its a datasource SingleImport.jsx should be updated if you want to support it as single import   
*/

const DISABLE_OAUTH = process.env?.['REACT_APP_DISABLEOAUTH'] === '1'
const ENABLE_WAREHOUSE = process.env?.['REACT_APP_ENABLE_WAREHOUSE'] === '1'
// Data sources
export const datasourceConfig = {
  Config: {
    subtext: 'Select datasource',
    icon: (
      <FaPlus
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 65 }}
      />
    ),
    component: () => null,
    prepareData: (data, selectedCsv, name) => {
      data.valid = false
      return data
    },
    skipConfigByDefault: true,
  },
  'Stored data': {
    subtext: 'Data already stored in the platform',
    icon: (
      <FaFile
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <FaFile className="me-1" style={{ color: '#4372EF', fontSize: 25 }} />
    ),
    component: ExistingData,
    prepareData: (data, selectedCsv, name) => {
      if (selectedCsv) {
        data.config.selectedCsv = selectedCsv
        data.subtitle = name
        data.valid = true
      } else {
        data.config.selectedCsv = null
        data.valid = false
      }
      return data
    },
  },
  'Upload local file': {
    subtext: 'Data stored in your computer',
    icon: (
      <FaUpload
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <FaUpload className="me-1" style={{ color: '#EC812F', fontSize: 25 }} />
    ),
    component: LocalFile,
    prepareData: (data, userId, fileToUpload, taskId) => {
      if (fileToUpload) {
        data.config.fileToUpload = fileToUpload
        data.config.taskId = taskId
        data.subtitle = fileToUpload?.name
        data.valid = true
      } else {
        data.config.fileToUpload = null
        data.valid = false
      }
      return data
    },
  },
  ...(DISABLE_OAUTH
    ? {}
    : {
        'Google Sheets': {
          subtext: 'Connect to Google Sheets',
          icon: (
            <SiGooglesheets
              className="icon-btn"
              style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
            />
          ),
          iconSmall: (
            <SiGooglesheets
              className="me-1"
              style={{ color: '#0F9D58', fontSize: 25 }}
            />
          ),
          component: SpreadsheetLogin,
          prepareData: (
            data,
            spreadsheet_id,
            workbook_name,
            google_token,
            spreadsheet_name,
          ) => {
            if (spreadsheet_id && workbook_name && google_token) {
              data.config = { spreadsheet_id, workbook_name, google_token }
              data.valid = true
              data.subtitle = `${spreadsheet_name}/${workbook_name}`
            } else {
              data.config = {}
              data.valid = false
            }
            return data
          },
        },
        // bigQuery: {
        //   subtext: 'Connect to BigQuery',
        //   icon: (
        //     <MdOutlineQueryStats
        //       className="icon-btn"
        //       style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
        //     />
        //   ),
        //   iconSmall: (
        //     <MdOutlineQueryStats
        //       className="me-1"
        //       style={{ color: '#0F9D58', fontSize: 25 }}
        //     />
        //   ),
        //   component: BigQueryLogin,
        //   prepareData: (data) => {
        //     data.config = {}
        //     data.valid = false
        //     return data
        //   },
        // },
      }),
  Database: {
    subtext: 'Connect to your SQL database',
    icon: (
      <FaDatabase
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <FaDatabase className="me-1" style={{ color: '#00A1E0', fontSize: 25 }} />
    ),
    component: Database,
    prepareData: (data, dbData) => {
      const correct = Object.entries(dbData).reduce(
        (acc, [k, v]) => acc && v,
        true,
      )
      if (correct) {
        data.config = dbData
        data.subtitle = `${dbData.link} ${dbData.sql}`
        data.valid = true
      } else {
        data.config = dbData
        data.valid = false
      }
      return data
    },
    requires: ['sql_db'],
  },
  Mongo: {
    subtext: 'Connect to MongoDB',
    icon: (
      <SiMongodb
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <SiMongodb className="me-1" style={{ color: '#0F9D58', fontSize: 30 }} />
    ),
    component: MongoDB,
    prepareData: (data, config) => {
      if (config?.uri && config?.database && config?.collection) {
        data.config = config
        data.valid = true
        data.subtitle = `${config?.database ?? ''}/${config?.collection ?? ''}`
      } else {
        data.config = config
        data.valid = false
      }
      return data
    },
    requires: ['external_api'],
  },
  'General API': {
    subtext: 'Connect to any API',
    icon: (
      <svg
        className="icon-btn"
        stroke="var(--nextbrain-white)"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="55px"
        width="55px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M4 13h5"></path>
        <path d="M12 16v-8h3a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-3"></path>
        <path d="M20 8v8"></path>
        <path d="M9 16v-5.5a2.5 2.5 0 0 0 -5 0v5.5"></path>
      </svg>
    ),
    iconSmall: (
      <svg
        stroke="#e24819"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="30px"
        width="30px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M4 13h5"></path>
        <path d="M12 16v-8h3a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-3"></path>
        <path d="M20 8v8"></path>
        <path d="M9 16v-5.5a2.5 2.5 0 0 0 -5 0v5.5"></path>
      </svg>
    ),
    component: ApiSource,
    prepareData: (data, apiConfig) => {
      if (apiConfig?.link) {
        data.config = apiConfig
        data.valid = true
        data.subtitle = apiConfig.link
      } else {
        data.config = apiConfig
        data.valid = false
      }
      return data
    },
    requires: ['external_api'],
  },
  'Public Datasets': {
    subtext: 'Connect to public datasets',
    icon: (
      <MdPublic
        className="icon-btn"
        style={{ color: 'var(--nextbrain-white)', fontSize: 60 }}
      />
    ),
    iconSmall: (
      <MdPublic className="me-1" style={{ color: '#3071E0', fontSize: 25 }} />
    ),
    component: ConfigurePublicDataset,
    prepareData: (data, config) => {
      if (config?.selectedCSV) {
        data.config = config
        data.valid = true
        data.subtitle = config?.selectedCSV?.result?.title
      } else {
        data.config = config
        data.valid = false
      }
      return data
    },
  },
  /*'Power BI': {
    subtext: 'Connect to Power BI',
    icon: <SiPowerbi style={{ color: '#0F9D58', fontSize: 30 }} />,
    component: PowerBiLogin,
    prepareData: (data, config) => {
      if (config?.uri && config?.database && config?.collection) {
        data.config = config
        data.valid = true
        data.subtitle = `${config?.database ?? ''}/${config?.collection ?? ''}`
      } else {
        data.config = config
        data.valid = false
      }
      return data
    },
    requires: ['external_api'],
  },*/
  /*
  'Dataslayer AI': {
    subtext: 'Import marketing data',
    icon: <Image className="icon-btn" src={DataslayerIcon} width={55} />,
    iconSmall: <Image className="me-1" src={DataslayerIcon} width={25} />,
    component: Dataslayer,
    prepareData: (data, link) => {
      if (link) {
        data.config.link = link
        data.valid = true
        data.subtitle = link
      } else {
        data.config.link = null
        data.valid = false
      }
      return data
    },
  },*/
  ...(ENABLE_WAREHOUSE
    ? {
        Duckdb: {
          subtext: 'Large scale data storage',
          icon: (
            <SiDuckdb
              className="icon-btn"
              style={{ color: '#fff100', fontSize: 55 }}
            />
          ),
          iconSmall: (
            <SiDuckdb
              className="me-1"
              style={{ color: '#fff100', fontSize: 25 }}
            />
          ),
          component: ConfigureDataWarehouse,
          prepareData: (data, csvId) => {
            debugger
            if (csvId) {
              data.config = { csvId }
              data.valid = true
            } else {
              data.config = {}
              data.valid = false
            }
            return data
          },
        },
      }
    : {}),
}

// Actions
export const actionConfig = {
  Merge: {
    subtext: 'Combine datasets',
    icon: (
      <TiFlowMerge
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 55, transform: 'rotate(180deg)' }}
      />
    ),
    iconSmall: (
      <TiFlowMerge
        className="me-1"
        style={{ color: '#3399ff', fontSize: 25, transform: 'rotate(180deg)' }}
      />
    ),
    component: ConfigureMerge,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Merge'
      return data
    },
    inputs: 2,
  },
  Concatenate: {
    subtext: 'Concatenate datasets',
    icon: (
      <BsUnion
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 50 }}
      />
    ),
    iconSmall: (
      <BsUnion className="me-1" style={{ color: '#3399ff', fontSize: 20 }} />
    ),
    component: ConfigureConcat,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Concatenate'
      return data
    },
    inputs: 2,
  },
  Filter: {
    subtext: 'Selectively trim your dataset',
    icon: (
      <FaFilter
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 50 }}
      />
    ),
    iconSmall: (
      <FaFilter className="me-1" style={{ color: '#3399ff', fontSize: 20 }} />
    ),
    component: ConfigureFilter,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Filter'
      return data
    },
  },
  Transform: {
    subtext: 'Modify your data',
    icon: (
      <VscServerProcess
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 50 }}
      />
    ),
    iconSmall: (
      <VscServerProcess
        className="me-1"
        style={{ color: '#3399ff', fontSize: 20 }}
      />
    ),
    component: ConfigureTransform,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Transform'
      return data
    },
  },
  'LLM transform': {
    subtext: 'Transform your data with AI',
    icon: (
      <GiArtificialIntelligence
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <GiArtificialIntelligence
        className="me-1"
        style={{ color: '#3399ff', fontSize: 25, marginLeft: '-3px' }}
      />
    ),
    component: ConfigureAITransform,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = config?.target?.value ?? 'Transform your data with AI'
      return data
    },
  },
  Aggregate: {
    subtext: 'Group your data',
    icon: (
      <FaObjectGroup
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 50 }}
      />
    ),
    iconSmall: (
      <FaObjectGroup
        className="me-1"
        style={{ color: '#3399ff', fontSize: 20 }}
      />
    ),
    component: ConfigureAggregate,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Aggregate'
      return data
    },
  },
  'Change types': {
    subtext: 'Adjust data types',
    icon: (
      <MdModelTraining
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <MdModelTraining
        className="me-1"
        style={{ color: '#3399ff', fontSize: 25 }}
      />
    ),
    component: ConfigureChangeTypes,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = 'Change types'
      return data
    },
  },
  'Enrich data': {
    subtext: 'Add additional fields',
    icon: (
      <MdAddToQueue
        className="icon-btn"
        style={{ color: '#3399ff', fontSize: 55 }}
      />
    ),
    iconSmall: (
      <MdAddToQueue
        className="me-1"
        style={{ color: '#3399ff', fontSize: 25 }}
      />
    ),
    component: ConfigureEnrichment,
    prepareData: (data, config) => {
      data.config = config
      data.valid = true
      data.subtitle = config?.target?.value ?? 'Enrich data'
      return data
    },
  },
}

export const typeToComponent = {
  Output: DefaultOutputNode,
}

Object.keys(datasourceConfig).forEach(
  (type) => (typeToComponent[type] = DatasourceConnectorNode),
)
Object.keys(actionConfig).forEach(
  (type) => (typeToComponent[type] = DatasourceActionNode),
)

export const unbindToType = {}

const actionUnbind = {
  Merge: (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  Filter: (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  Transform: (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  Concatenate: (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  Aggregate: (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  'Change types': (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  'Enrich data': (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
  'LLM transform': (node) => {
    node.data = { ...node.data, config: {}, valid: false }
  },
}

const datasourceUnbind = {}

Object.entries(actionUnbind).forEach(([k, c]) => (unbindToType[k] = c))
Object.entries(datasourceUnbind).forEach(([k, c]) => (unbindToType[k] = c))

export const bindToType = {}

const singleBind = (node, nodes, edges) => {
  const connectedNodes = edges
    .filter((e) => e.target === node.id)
    .map((e) => nodes.find((n) => n.id === e.source))
    .filter((e) => e)

  if (connectedNodes.length === 1 && connectedNodes[0].data.valid)
    node.data = { ...node.data, config: {}, valid: false, active: true }
}

const doubleBind = (node, nodes, edges) => {
  const connectedNodes = edges
    .filter((e) => e.target === node.id)
    .map((e) => nodes.find((n) => n?.id === e.source))
    .filter((e) => e)

  if (
    connectedNodes.length === 2 &&
    connectedNodes.reduce((acc, n) => acc && n.data.valid, true)
  )
    node.data = { ...node.data, config: {}, valid: false, active: true }
}

const actionBind = {
  Merge: doubleBind,
  Concatenate: doubleBind,
  Aggregate: singleBind,
  Filter: singleBind,
  Transform: singleBind,
  'Change types': singleBind,
  'Enrich data': singleBind,
  'LLM transform': singleBind,
}

const datasourceBind = {}

Object.entries(actionBind).forEach(([k, c]) => (bindToType[k] = c))
Object.entries(datasourceBind).forEach(([k, c]) => (bindToType[k] = c))
