import { useMemo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { checkAggregate } from '../../../../services/csv'
import { useAuth } from '../../../../providers/AuthProvider'
import { awaitTaskCall } from '../../../../services/base'
import Loading from '../../../loading/LoadingSmall'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import TableError from './TableError'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'

export default function AggregateTable({
  input,
  aggregateBy,
  aggregations,
  onDataLoad,
  defaultBehavior,
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const { isLoading, data } = useQuery(
    ['aggregateCheck', aggregateBy, aggregations, defaultBehavior],
    async () => {
      if (!aggregateBy?.length) return null
      let error = null
      const result = await awaitTaskCall(checkAggregate, 1000, 20000, {
        input: {
          columns: input.columns,
          data: input.sample,
        },
        aggregateBy: aggregateBy.map((c) => c.value),
        aggregations: aggregations.reduce((acc, { column, func }) => {
          acc[column] = func
          return acc
        }, {}),
        defaultBehavior,
        token,
        signout,
      }).catch((e) => {
        console.error('Failed to retrieve aggregation for table')
        error = e
      })
      return error || result
    },
    { staleTime: Infinity, retry: false },
  )

  useEffect(() => {
    if (data && !data.error) {
      setError(null)
      onDataLoad({
        columns: data.columns,
        sample: data.data,
      })
    } else {
      setError(data?.error)
      onDataLoad(null)
    }
    // eslint-disable-next-line
  }, [data])

  const tableData = useMemo(() => {
    if (!data || data?.error) return null
    const res = {
      columns: [data.columns],
      data: data.data,
    }
    return res
    // eslint-disable-next-line
  }, [data])

  const defaultData = useMemo(() => {
    if (!input)
      return {
        columns: [],
        data: [],
      }
    return {
      columns: [input.columns],
      data: input.sample,
    }
  }, [input])

  const showData = tableData || defaultData

  return error ? (
    <TableError
      error="Failed to generate aggregation from current configuration"
      subtext={error}
    />
  ) : isLoading ? (
    <ColumnPaginatedGridTable
      key="placeholder"
      stableHeight={true}
      placeholder={true}
      emptyDatasetMessage={<Loading message={t('Loading preview')} />}
      header={[['.']]}
      rows={Array(10).fill(['.'])}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data table-placeholder h-auto"
      Pager={() => <ReactPaginate className="paginate-view-data" />}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  ) : (
    <ColumnPaginatedGridTable
      key="data"
      stableHeight={true}
      header={showData.columns}
      rows={showData.data}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data"
      Pager={({ maxPage, onChange, page }) => (
        <ReactPaginate
          className="paginate-view-data"
          breakLabel="..."
          nextLabel={t('next')}
          onPageChange={({ selected }) => onChange(selected)}
          pageRangeDisplayed={3}
          pageCount={maxPage}
          previousLabel={t('previous')}
          renderOnZeroPageCount={null}
        />
      )}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  )
}
