export default function Py({
  width = 50,
  height = 50,
  className = '',
  ...props
}) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 303.188 303.188"
      className={className}
      height={height}
      width={width}
      {...props}
    >
      <g>
        <polygon
          style={{ fill: '#E8E8E8' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <g>
          <g>
            <path
              style={{ fill: '#004A94' }}
              d="M150.848,67.41c-15.19,0-25.222,6.307-25.222,12.325v19.196h25.222v2.866h-47.284
				c-6.02,0-12.324,10.031-12.324,25.222s6.305,25.222,12.324,25.222h19.193v-11.996c0-8.086,6.578-14.665,14.665-14.665
				l26.848-0.002c6.505,0,11.798-5.293,11.798-11.799V93.041h0.002V79.735C176.069,73.717,166.038,67.41,150.848,67.41z"
            />
            <circle
              style={{ fill: '#FFFFFF' }}
              cx="137.179"
              cy="84.409"
              r="3.63"
            />
          </g>
          <g>
            <path
              style={{ fill: '#FB9200' }}
              d="M198.132,101.798h-19.198v11.981c0,8.086-6.578,14.664-14.664,14.664l-26.849,0.003
				c-6.505,0-11.797,5.293-11.797,11.799v13.234l0.002-0.003v20.827c0,6.019,10.031,12.325,25.222,12.325
				s25.222-6.307,25.222-12.325v-19.196h-25.222v-2.866h47.284c6.02,0,12.324-10.031,12.324-25.222S204.151,101.798,198.132,101.798
				z"
            />
            <circle
              style={{ fill: '#FFFFFF' }}
              cx="164.517"
              cy="169.63"
              r="3.63"
            />
          </g>
        </g>
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M147.304,241.152c0,5.36-1.581,9.5-4.742,12.421c-3.162,2.921-7.652,4.382-13.472,4.382h-3.644
			v15.916h-12.963v-47.979h16.607c6.059,0,10.61,1.324,13.652,3.972C145.782,232.51,147.304,236.273,147.304,241.152z
			 M125.446,247.387h2.363c1.947,0,3.495-0.546,4.645-1.641c1.148-1.094,1.723-2.604,1.723-4.528c0-3.237-1.795-4.856-5.383-4.856
			h-3.348C125.446,236.362,125.446,247.387,125.446,247.387z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M172.081,243.679l8.27-17.787h14.046l-15.785,29.241v18.738H165.55v-18.346l-15.785-29.634h14.111
			L172.081,243.679z"
          />
        </g>
        <polygon
          style={{ fill: '#004A94' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  )
}
