import { request } from './base'

export async function getVideos(token, type, industry, problem, level, page) {
  return request({
    endpoint: `/video/getVideos?industry=${industry.join(
      ',',
    )}&problem=${problem.join(',')}&type=${type}&level=${level}&page=${page}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
