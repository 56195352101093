import { useState, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { FaCopy, FaDownload, FaFileCode, FaTrash } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'

import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { dracula } from '@uiw/codemirror-theme-dracula'
import {
  createAITool,
  deleteAITool,
  getAITool,
  getAITools,
} from '../../services/aiTools'
import Loading from '../loading/LoadingSmall'
import { useAuth } from '../../providers/AuthProvider'
import './AiTools.css'
import { NotificationManager } from 'react-notifications'

function ModalTool({ tool, onHide }) {
  const { token, signout } = useAuth()
  const [code, setCode] = useState(tool?.code ?? '')
  const nameRef = useRef()
  const disableRef = useRef()
  const descriptionRef = useRef()
  const queryClient = useQueryClient()

  return (
    <Modal show={true} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <h5>{tool?.name ? `Edit ${tool.name}` : 'New tool'}</h5>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="py-1" xs={12}>
            <Form.Check
              defaultChecked={tool?.metadata?.disabled ?? false}
              type="checkbox"
              label={'Tool disabled'}
              ref={disableRef}
              id="disablecheck"
            />
          </Col>
          <Col className="mb-1" xs={12}>
            Name
          </Col>
          <Col xs={12}>
            <Form.Control
              disabled={!!tool}
              type={'text'}
              className="nb-input"
              defaultValue={tool?.name ?? ''}
              ref={nameRef}
            />
          </Col>
          <Col className="mt-3 mb-1" xs={12}>
            Description (This is used by the LLM to decide which tool to use!)
          </Col>
          <Col xs={12}>
            <Form.Control
              type={'text'}
              className="nb-input"
              defaultValue={tool?.description ?? ''}
              ref={descriptionRef}
            />
          </Col>
          <Col className="mt-3 mb-2 d-flex justify-content-between" xs={12}>
            <span>Code</span>
            <span>
              <FaFileCode
                title="Copy"
                className="icon-btn"
                size={25}
                onClick={() => {
                  if (navigator?.clipboard?.writeText) {
                    navigator.clipboard.writeText(code)
                    NotificationManager.info('Code copied to clipboard')
                  } else NotificationManager.error('Clipboard not supported')
                }}
              />
            </span>
          </Col>
          <Col xs={12}>
            <CodeMirror
              value={code}
              height="400px"
              extensions={[python({})].filter((e) => e)}
              onChange={(value) => setCode(value)}
              theme={dracula}
              indentWithTab
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 justify-content-end">
          <Col xs={'auto'}>
            <Button
              className="original ms-5 button-cancel-tool"
              variant="secondary"
              onClick={async () => onHide()}
            >
              Cancel
            </Button>
            <Button
              className="ms-2"
              onClick={async () => {
                if (!nameRef?.current?.value)
                  NotificationManager.error('Missing name of the tool')
                else if (!descriptionRef?.current?.value)
                  NotificationManager.error('Missing description of the tool')
                else if (!code)
                  NotificationManager.error('Missing code of the tool')
                else {
                  createAITool({
                    name: nameRef.current.value?.replace(/ /g, '_'),
                    description: descriptionRef.current.value,
                    disabled: disableRef.current.checked,
                    code,
                    overwrite: !!tool,
                    token,
                    signout,
                  })
                    .then(async (r) => {
                      if (r?.status) {
                        NotificationManager.success('Tool saved')
                        onHide()
                        queryClient.invalidateQueries(['tools-admin'])
                      } else {
                        try {
                          NotificationManager.error(
                            r?.message ?? 'Error creating tool',
                          )
                        } catch (e) {
                          NotificationManager.error('Error creating tool')
                        }
                      }
                    })
                    .catch(() => {
                      NotificationManager.error('Error creating tool')
                    })
                }
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

const dateFormat = (date) => {
  const dt = new Date(date).toLocaleDateString('default', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  if (dt === 'Invalid Date') return '-'
  return dt
}

function Tool({ tool }) {
  const [activeComponent, setActiveComponent] = useState(null)
  const { token, signout } = useAuth()
  const queryClient = useQueryClient()

  return (
    <>
      {activeComponent}
      <Row
        className="tool-AI-tools p-3 mx-2 h-100"
        onClick={async () => {
          const info = await getAITool({ filename: tool[1], token, signout })
          if (!info?.code) {
            NotificationManager.error('Error retrieving tool')
            return
          }
          setActiveComponent(
            <ModalTool tool={info} onHide={() => setActiveComponent(null)} />,
          )
        }}
      >
        <Col xs={12} className="d-inline-block text-truncate">
          {tool[0]}
          {tool?.[4]?.disabled && (
            <span className="ms-1">
              <strong
                className="small"
                style={{
                  color: 'var(--nextbrain-tables-negative-graph-bar-color)',
                }}
              >
                (disabled)
              </strong>
            </span>
          )}
        </Col>
        <Col xs={12}>
          <span className="small">
            {dateFormat(new Date(tool?.[3] * 1000))}
          </span>
        </Col>
        <Col xs={12} className="mt-4">
          <div className="smallp">{tool[2]}</div>
        </Col>
        <Col xs={12}>
          <Row className="justify-content-end mt-2 row-tools-controls">
            <Col xs="auto">
              <FaCopy
                className="icon-btn"
                size={25}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const info = await getAITool({
                    filename: tool[1],
                    token,
                    signout,
                  })
                  if (navigator?.clipboard?.writeText) {
                    navigator.clipboard.writeText(info.code)
                    NotificationManager.info('Code copied to clipboard')
                  } else NotificationManager.error('Clipboard not supported')
                }}
              />
            </Col>
            <Col xs="auto">
              <FaDownload
                className="icon-btn"
                size={25}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const info = await getAITool({
                    filename: tool[1],
                    token,
                    signout,
                  })
                  const link = document.createElement('a')
                  link.setAttribute(
                    'href',
                    `data:application/octet-stream;base64,${btoa(
                      JSON.stringify(info),
                    )}`,
                  )

                  link.setAttribute('download', `${info.name}_export.json`)
                  document.body.appendChild(link)
                  link.click()
                }}
              />
            </Col>
            <Col xs="auto">
              <FaTrash
                className="icon-btn"
                size={25}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  NotificationManager.warning(
                    <Row className="my-2">
                      <Col xs={12}>
                        Are you sure you want to delete the tool?
                        <br />
                        <Button
                          className="original px-2 py-1 mt-4"
                          variant="danger"
                          onClick={async () => {
                            const info = await getAITool({
                              filename: tool[1],
                              token,
                              signout,
                            })
                            deleteAITool({
                              filename: info?.name + '.py',
                              token,
                              signout,
                            })
                              .then(async (r) => {
                                if (r?.status) {
                                  NotificationManager.success('Tool deleted')
                                  queryClient.invalidateQueries(['tools-admin'])
                                } else {
                                  try {
                                    NotificationManager.error(
                                      r?.message ?? 'Error deleting tool',
                                    )
                                  } catch (e) {
                                    NotificationManager.error(
                                      'Error deleting tool',
                                    )
                                  }
                                }
                              })
                              .catch(() => {
                                NotificationManager.error('Error deleting tool')
                              })
                          }}
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>,
                  )
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default function AiTools() {
  const { token, signout } = useAuth()
  const uploadRef = useRef()
  const queryClient = useQueryClient()

  const [activeElement, setActiveElement] = useState(null)

  const { data: tools } = useQuery(
    ['tools-admin'],
    async () => {
      const tools = await getAITools({ token, signout })
      return tools
    },
    { staleTime: Infinity },
  )

  return (
    <>
      <Row
        className="p-4 ms-5 flex-column"
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (e?.dataTransfer?.files?.length) {
            uploadRef.current.files = e.dataTransfer.files
            uploadRef.current.dispatchEvent(
              new Event('change', { bubbles: true }),
            )
          }
        }}
        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{ minHeight: '100vh' }}
      >
        {activeElement}
        <Col xs={12}>
          <Form.Control
            ref={uploadRef}
            type="file"
            className="d-none"
            multiple
            accept=".json,.txt"
            onChange={async (e) => {
              const imports = await Promise.all(
                [...e.currentTarget.files].map(
                  async (f) =>
                    new Promise((r) => {
                      const reader = new FileReader()
                      reader.onload = function () {
                        try {
                          r({
                            result: JSON.parse(reader.result),
                            file: f,
                          })
                        } catch {
                          r({
                            result: null,
                            file: f,
                          })
                        }
                      }
                      reader.onerror = function () {
                        r({
                          result: null,
                          file: f,
                        })
                      }
                      reader.readAsBinaryString(f)
                    }),
                ),
              )
              const failed = imports.filter((i) => !i.result)
              if (failed?.length) {
                NotificationManager.error(
                  `Error reading files ${failed
                    .map((f) => f.file.name)
                    .join('\n')}`,
                )
                return
              }
              const flatMap = imports
                .map((i) => {
                  if (Array.isArray(i.result)) return i.result
                  return i.result
                })
                .flat()

              await Promise.all(
                flatMap.map(async (t) => {
                  return await createAITool({
                    name: t.name,
                    description: t.description,
                    code: t.code,
                    disabled: t.metadata?.disabled ?? false,
                    token,
                    signout,
                  })
                }),
              )
              queryClient.invalidateQueries(['tools-admin'])
            }}
          />
        </Col>
        <Col xs={12}>
          <Row className="justify-content-between">
            <Col xs={'auto'}>
              <h2>
                {'Skills'} <GiArtificialIntelligence size={35} />
              </h2>
            </Col>
            <Col className="me-5" xs={'auto'}>
              <Button
                className="new-tool-button"
                onClick={() => {
                  setActiveElement(
                    <ModalTool
                      tool={null}
                      onHide={() => {
                        setActiveElement(null)
                      }}
                    />,
                  )
                }}
              >
                New tool
              </Button>
              <OverlayTrigger
                rootClose={true}
                trigger={'click'}
                placement="auto"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <Row>
                      <Col className="mb-2" xs={12}>
                        <Button
                          onClick={() => uploadRef.current.click()}
                          className="w-100"
                        >
                          Import file
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <Button
                          onClick={async () => {
                            const items = await Promise.all(
                              tools.map(async (t) => {
                                return await getAITool({
                                  filename: t[1],
                                  token,
                                  signout,
                                })
                              }),
                            )
                            const link = document.createElement('a')
                            link.setAttribute(
                              'href',
                              `data:application/octet-stream;base64,${btoa(
                                JSON.stringify(items),
                              )}`,
                            )

                            link.setAttribute('download', `tools_export.json`)
                            document.body.appendChild(link)
                            link.click()
                          }}
                          className="w-100"
                        >
                          Export all tools
                        </Button>
                      </Col>
                    </Row>
                  </Tooltip>
                )}
              >
                <Button className="new-tool-button-side">
                  <BsThreeDotsVertical size={20} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
        <hr></hr>

        <Col xs={12} className="mt-3">
          <Row>
            {Array.isArray(tools) ? (
              tools.map((t) => (
                <Col className="mb-2" xs={3} key={t[0]}>
                  <Tool tool={t} />
                </Col>
              ))
            ) : (
              <Loading />
            )}
          </Row>
        </Col>
      </Row>
    </>
  )
}
