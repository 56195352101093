import { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import { checkEnrich } from '../../../../services/csv'
import { useAuth } from '../../../../providers/AuthProvider'
import { awaitTaskCall } from '../../../../services/base'
import Loading from '../../../loading/LoadingSmall'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import TableError from './TableError'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'

export default function EnrichTable({ input, config, targetName, onDataLoad }) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const { isLoading, data } = useQuery(
    ['enrichCheck', input, config],
    async () => {
      if (config && targetName) {
        let error = null
        const result = await awaitTaskCall(checkEnrich, 1000, 100000, {
          config: {
            input: {
              columns: input.columns,
              data: input.sample,
            },
            name: targetName,
            columns_to_add: (config?.columns_to_add ?? [])?.map((c) => c.value),
            kwargs: Object.entries(config?.args ?? {}).reduce((d, [k, v]) => {
              if (typeof v.value === 'object')
                d[k] = { column: v?.value?.value }
              else d[k] = { constant: v?.value }
              return d
            }, {}),
          },
          token,
          signout,
        }).catch((e) => {
          console.error('Failed to retrieve enrichment data for table')
          error = e.error
        })

        if (error) return { error }
        return result
      }
      return null
    },
    { staleTime: Infinity, retry: false },
  )

  const defaultData = useMemo(() => {
    if (!input)
      return {
        columns: [],
        data: [],
      }
    return {
      columns: [input.columns],
      data: input.sample,
    }
  }, [input])

  const tableData = useMemo(() => {
    if (!data || data?.error) return null
    const res = {
      columns: [data.columns],
      data: data.data,
    }
    return res
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (data && !data?.error)
      onDataLoad({
        columns: data.columns,
        sample: data.data,
      })
    else onDataLoad(null)
    // eslint-disable-next-line
  }, [data])

  const showData = tableData || defaultData

  return data?.error ? (
    <TableError error="Failed to enrich data" subtext={data.error} />
  ) : isLoading ? (
    <ColumnPaginatedGridTable
      key="placeholder"
      stableHeight={true}
      placeholder={true}
      emptyDatasetMessage={<Loading message={t('Loading preview')} />}
      header={[['.']]}
      rows={Array(10).fill(['.'])}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data table-placeholder h-auto"
      Pager={() => <ReactPaginate className="paginate-view-data" />}
      pagerLast={true}
      style={{
        minHeight: '120px',
        maxHeight: '120px',
      }}
    />
  ) : (
    <ColumnPaginatedGridTable
      key="data"
      stableHeight={true}
      header={showData.columns}
      rows={showData.data}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data"
      Pager={({ maxPage, onChange, page }) => (
        <ReactPaginate
          className="paginate-view-data"
          breakLabel="..."
          nextLabel={t('next')}
          onPageChange={({ selected }) => onChange(selected)}
          pageRangeDisplayed={3}
          pageCount={maxPage}
          previousLabel={t('previous')}
          renderOnZeroPageCount={null}
        />
      )}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  )
}
