import React, { useState, Fragment, useMemo } from 'react'
import { Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import Avatar from '../../../../assets/images/avatar.png'
import SingleAccordion from '../../../single-accordion/SingleAccordion'
import { useAuth } from '../../../../providers/AuthProvider'
import { gptSpecificTask } from '../../../../services/model'
import { useModels } from '../../../../providers/ModelProvider'
import ConfirmButton from '../../../confirm-button/ConfirmButton'
import { modelProblemTypesPerColumn } from '../../../../util/models'
import { useNav } from '../../../../providers/NavProvider'

const PROBLEM_TYPES = [
  'Forecast',
  'Anomaly Detection',
  'Regression',
  'Marketing Mix Modeling',
  'Anomaly Detection',
  'Classification',
]

function hamming(str1, str2) {
  let distance = 0
  for (let i = 0; i < str1.length && i < str2.length; i++) {
    if (str1[i] !== str2[i]) distance++
  }
  return distance
}

function strWindow(potentialMatch, target) {
  const windowSize = 5
  for (let i = 0; i < potentialMatch.length - windowSize; i++) {
    let window = potentialMatch.substring(i, i + windowSize)
    if (target.includes(window)) return true
  }
  return false
}

const problemMap = PROBLEM_TYPES.reduce((d, p) => {
  d[p.toLocaleLowerCase()] = p
  return d
}, {})

function tryProblemType(problem) {
  problem = problem?.trim()
  if (typeof problem === 'string' || problem.length > 6) {
    problem = problem.toLocaleLowerCase()

    if (problemMap[problem]) return problemMap[problem]

    for (const p of Object.keys(problemMap)) {
      if (strWindow(problem, p)) return problemMap[p]
    }

    for (const p of Object.keys(problemMap)) {
      const distance = hamming(problem, p)
      if (distance < Math.ceil(p.length / 2)) return problemMap[p]
    }
  }
}

function Problem({
  model,
  title,
  description,
  problem_type,
  targets,
  onConfigure = () => {},
  ...props
}) {
  if (targets.length === 1) {
    props.onClick = () => onConfigure(targets[0], problem_type)
    props.className = `${props.className ?? ''} cursor-pointer`
  }
  return (
    <Row {...props}>
      <Col xs={12}>
        <strong>{title}</strong>
      </Col>
      <Col className="smallp my-1" xs={12}>
        {description}
      </Col>
      <Col xs={12}>
        <ul className="mb-0 smallp">
          <li>Problem type: {problem_type}</li>
        </ul>
      </Col>
      <Col xs={12}>
        <ul className="mb-0 smallp">
          <li>
            Targets:{' '}
            {targets?.map((l, i) => (
              <Fragment key={l}>
                <div
                  className={`
                ${
                  model?.dataset?.final_column_status?.[l]
                    ? 'active-target-assistant'
                    : ''
                }
                 d-inline-block me-1
                `}
                >
                  {model?.dataset?.final_column_status?.[l] ? (
                    <span
                      className="link"
                      onClick={() => onConfigure(l, problem_type)}
                    >
                      {`${l}`}
                    </span>
                  ) : (
                    <span>{`${l}`}</span>
                  )}
                  {i === targets.length - 1 ? '' : ', '}
                </div>
              </Fragment>
            )) ?? 'N/A'}
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default function ProblemSuggestions({
  defaultOpen = false,
  currentTrainingConfig = {},
  ...props
}) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [show, setShow] = useState(defaultOpen)
  const [working, setWorking] = useState(false)

  const { activeModel } = useModels()
  const { token, signout, MMMEnabled, hasAnomalyPlugin } = useAuth()
  const { mode } = useNav()
  const problemsPerColumn = useMemo(
    () =>
      modelProblemTypesPerColumn(
        activeModel,
        MMMEnabled(),
        mode,
        hasAnomalyPlugin,
      ),
    // eslint-disable-next-line
    [activeModel],
  )

  const { data: problems } = useQuery(
    ['gpt-specific-task-problems_selected', activeModel?.id],
    async () => {
      if (!activeModel) return []
      const res = await gptSpecificTask({
        modelId: activeModel?.id,
        taskType: 'selected_problems_to_solve',
        token,
        signout,
      })
      if (!Array.isArray(res)) throw new Error('Invalid problems, retrying')

      return res
    },
    {
      staleTime: Infinity,
      initialData: [],
    },
  )

  const filteredProblems = problems.filter(
    (p) =>
      p?.problem_type !== 'clustering' &&
      Array.isArray(p?.targets) &&
      p?.targets.filter((t) => t in activeModel.dataset.final_column_status)
        .length,
  )
  if (!filteredProblems.length) return null

  return (
    <Row
      {...props}
      className={`insights-card insights-card-alt mt-3 ${
        props?.className ?? ''
      }`}
    >
      <Col
        className="cursor-pointer pb-1"
        onClick={() => setShow((s) => !s)}
        xs={12}
      >
        <Row className="justify-content-between">
          <Col xs="auto">
            <Image src={Avatar} width={48} />
            <span className="mx-2">
              {t('Problem suggestions for your current model')}
              <FaChevronRight
                className="ms-2"
                style={{
                  transitionDuration: '0.25s',
                  transform: `rotate(${show ? 90 : 0}deg)`,
                }}
                size={25}
              />
            </span>
          </Col>
          <Col className="dflex-center" xs="auto">
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  {t(
                    'This will remove the suggestions permanently for this model',
                  )}
                </Tooltip>
              )}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="d-flex justify-content-center"
              >
                <ConfirmButton
                  className="me-2"
                  disabled={working}
                  asOutside={Col}
                  inProps={{ className: 'me-2' }}
                  onClick={async () => {
                    try {
                      setWorking(true)
                      await gptSpecificTask({
                        modelId: activeModel?.id,
                        taskType: 'selected_problems_to_solve',
                        method: 'DELETE',
                        token,
                        signout,
                      })
                      queryClient.invalidateQueries([
                        'gpt-specific-task-problems_selected',
                        activeModel?.id,
                      ])
                    } catch (e) {
                    } finally {
                      setWorking(false)
                    }
                  }}
                  onEvent={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <button
                    type="button"
                    className="btn-close p-0"
                    style={{ minWidth: '40px', minHeight: '40px' }}
                    aria-label="Close"
                  />
                </ConfirmButton>
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <SingleAccordion
          header={false}
          forceStatus={show}
          className="nb-accordion-invisible"
        >
          <Row>
            {filteredProblems.map((problem, i) => (
              <Col key={i} xl={4} lg={6} xs={12}>
                <Problem
                  model={activeModel}
                  onConfigure={(problem, problemType) => {
                    const config = {
                      target: problem,
                    }
                    let match = tryProblemType(problemType)
                    if (
                      match === 'Regression' &&
                      !problemsPerColumn?.[problem]?.includes('Regression') &&
                      problemsPerColumn?.[problem]?.includes('Classification')
                    )
                      match = 'Classification'
                    if (match && problemsPerColumn?.[problem]?.includes(match))
                      config.problemType = match

                    currentTrainingConfig?.setTargetConfig(config)
                    setShow(false)
                  }}
                  className="mt-2 mx-1 problem-suggestion-train"
                  {...problem}
                />
              </Col>
            ))}
          </Row>
        </SingleAccordion>
      </Col>
    </Row>
  )
}
