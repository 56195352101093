import { Row, Col } from 'react-bootstrap'
import { zeroPad, defaultFormat } from '../utils/formating'
import { FaTrashAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import './PredictResultPie.css'

function formatValue(v) {
  if (Object.prototype.toString.call(v) === '[object Date]')
    return v.toLocaleString()
  return v
}

export default function PredictResultPie({
  predictResponse,
  model,
  columns,
  onDelete = () => {},
  ...props
}) {
  const { t } = useTranslation()
  if (!predictResponse) return null

  const now = new Date()
  const hour = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()
  const executionTime = `${zeroPad(hour, 2)}:${zeroPad(minutes, 2)}:${zeroPad(
    seconds,
    2,
  )}`

  if (
    'detail' in predictResponse ||
    (predictResponse.status && predictResponse.status !== 200)
  ) {
    if (predictResponse.status === 402) {
      return (
        <p key={executionTime}>
          {t(
            'The model was trained with a different version of the library. Please retrain the model.',
          )}
        </p>
      )
    }
    return (
      <p key={executionTime}>
        {t(
          'Prediction failed, please retry in a few seconds or contact the administrator',
        )}
        <br />
        {t(
          'If the problem persists we recommend you to report the model to the administrator',
        )}
      </p>
    )
  }

  let prediction = predictResponse.predictions[0][0]
  let predictionConfidence = predictResponse.predictions[0][1]

  return (
    <>
      <Row
        style={{ zIndex: 2 }}
        className="input-predict-card w-100 mb-4 align-items-center"
        {...props}
      >
        <FaTrashAlt
          size={30}
          className="delete-card-button svg-active"
          onClick={onDelete}
        />
        <Col xs={12}>
          <Row className="p-0 m-0 h-100">
            <Col xs={12} className="mt-2  align-self-end">
              <Row
                style={{
                  borderBottom: '1px solid var(--nextbrain-automl-color)',
                }}
              >
                <Col
                  xs={12}
                  align="center"
                  className="px-0 mx-0 d-inline-block text-truncate"
                >
                  <span>
                    {t('Prediction for {{columnName}} column', {
                      columnName: predictResponse.target,
                    })}
                  </span>
                </Col>
                <Col xs={12}>
                  <Row className="p-0 m-0 justify-content-center">
                    <Col
                      xs={'auto'}
                      title={predictResponse.target}
                      align="center"
                      className="p-0 m-0 my-2 d-inline-block text-truncate"
                    >
                      <strong className="h4">
                        {predictResponse.problem_type === 'regression'
                          ? model?.dataset?.final_column_status?.[
                              model?.target
                            ] === 'Datetime'
                            ? new Date(prediction).toLocaleString()
                            : defaultFormat({
                                num: prediction,
                                digits: Math.abs(prediction) > 100 ? 2 : 4,
                              })
                          : defaultFormat({ num: prediction })}
                      </strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              className="px-0 mx-0 mt-2 d-inline-block text-truncate d-block"
            >
              <span>{columns.length ? 'Input' : 'Default inputs'}</span>
            </Col>
            <Col
              xs={12}
              style={{
                maxHeight: '15vh',
                overflowY: 'auto',
              }}
              className="p-0 m"
            >
              {columns.length ? (
                columns.map(([name, value]) => (
                  <Row className="px-0 mx-0 text-truncate" key={name}>
                    <Col
                      align="center"
                      title={`${name[0].toUpperCase()}${name.substr(
                        1,
                      )}: ${value}`}
                      className="ps-0 px-0 mx-0 text-truncate d-block"
                      xs={'auto'}
                    >
                      <span className="enphasis">{`${name[0].toUpperCase()}${name.substr(
                        1,
                      )}: `}</span>
                      <span>{formatValue(value)}</span>
                    </Col>
                  </Row>
                ))
              ) : (
                <></>
              )}
            </Col>
            <Col xs={12}>
              <Row className="justify-content-between">
                <Col
                  xs={'auto'}
                  title={`${t('Execution at')} ${executionTime}`}
                  className="mt-2 text-truncate d-block px-0 mx-0"
                  style={{ color: '#A8A8A8' }}
                >
                  <small>{`${t('Execution at')} ${executionTime}`}</small>
                </Col>
                {predictionConfidence && predictionConfidence !== 0 ? (
                  <Col
                    xs={'auto'}
                    title={`${t('Execution at')} ${executionTime}`}
                    className="mt-2 text-truncate d-block px-0 mx-0"
                    style={{ color: '#A8A8A8' }}
                  >
                    {t('Confidence')}: <small>{predictionConfidence}</small>%
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
