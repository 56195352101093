import React from 'react'
import { ResponsiveWaffle } from '@nivo/waffle'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { round } from '../utils/formating'

const DEFAULT_DATA = []

export function HorizontalWaffle({
  data,
  className = '',
  header,
  height = 204,
  circle = true,
  fillDirection = 'left',
}) {
  const CircleCell = ({
    position,
    size,
    x,
    y,
    color,
    fill,
    opacity,
    borderWidth,
    borderColor,
    data,
    onHover,
    onLeave,
    onClick,
  }) => (
    <circle
      r={size / 2}
      cx={x + size / 2}
      cy={y + size / 2}
      fill={fill || color}
      strokeWidth={borderWidth}
      stroke={borderColor}
      opacity={opacity}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={(event) => {
        onClick({ position, color, x, y, data }, event)
      }}
    />
  )

  let extraProps = {}
  if (circle) extraProps.cellComponent = CircleCell

  return (
    <div className={className}>
      {header ? header : <></>}
      <Row className="mt-2 mx-1" style={{ height: `${height}px` }}>
        <ResponsiveWaffle
          data={data ? data : DEFAULT_DATA}
          total={100}
          rows={4}
          columns={25}
          fillDirection={fillDirection}
          colors={(d) => d.color}
          padding={0}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          emptyOpacity={0.25}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={11}
          legends={[]}
          {...extraProps}
        />
      </Row>
    </div>
  )
}

export default function CustomWaffle({
  data,
  msg = null,
  limitTypes = 0,
  normalize = false,
  showLegend = true,
  colors = { scheme: 'nivo' },
  height = 200,
  className = '',
}) {
  const { t } = useTranslation()
  const MARGIN_RIGHT = showLegend ? 30 : 0

  msg = msg ? msg : t('Extra information')

  if (normalize && data) {
    const totalValues = data.reduce((n, { value }) => n + value, 0)
    data = data.map((element) => {
      element.value = round((100 * element.value) / totalValues)
      return element
    })
  }

  if (data) {
    data = data
      .filter((element) => element.value >= 1)
      .sort((a, b) => a.value - b.value)
      .reverse()

    if (limitTypes > 0) {
      data = data.slice(0, limitTypes)
      const totalValues = data.reduce((n, { value }) => n + value, 0)
      if (100 - totalValues >= 1) {
        data.push({
          id: 'Other',
          label: 'Other',
          value: round(100 - totalValues),
        })
      }
    }

    data = data.map((element) => {
      if (element.id.length > 15) {
        element.id = element.id.substring(0, 13) + '...'
        element.label = element.id
      }
      return element
    })
  }

  return (
    <div className={className}>
      <Row className={`mt-2`} style={{ height: `${height}px` }}>
        <ResponsiveWaffle
          data={data ? data : DEFAULT_DATA}
          total={100}
          rows={10}
          columns={10}
          padding={0}
          margin={{ top: 0, right: MARGIN_RIGHT, bottom: 0, left: 0 }}
          emptyOpacity={0.5}
          colors={colors}
          borderWidth={1}
          borderColor="#ffffff"
          animate={true}
          motionStiffness={90}
          motionDamping={11}
          theme={{
            legends: {
              text: {
                fontSize: 9,
              },
            },
          }}
          legends={
            showLegend
              ? [
                  {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 6,
                    itemWidth: 40,
                    itemHeight: 35,
                    itemDirection: 'top-to-bottom',
                    itemOpacity: 1,
                    itemTextColor: '#777',
                    symbolSize: 15,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                          itemBackground: '#f7fafb',
                        },
                      },
                    ],
                  },
                ]
              : []
          }
        />
      </Row>
      <Row justify="center" className="mt-3">
        <Col align="center">
          <span style={{ marginRight: `${MARGIN_RIGHT}px` }}>{msg}</span>
        </Col>
      </Row>
    </div>
  )
}
