import { request } from './base'

export async function searchPublicDatasets({
  query,
  page = 0,
  website,
  token,
  signout,
}) {
  // await new Promise((resolve) => setTimeout(resolve, 2000))
  // return [
  //   {
  //     title: 'https://www.ine.es/jaxi/files/_px/es/csv/t25/p444/...',
  //     link: 'https://www.ine.es/jaxi/files/_px/es/csv/t25/p444/a2003/l0/pobl005.csv',
  //     snippet:
  //       'Indicadores Sociales Población Estructura de población por Comunidad Autónoma y Provincia, tipo de indicador y años ... ESPAÑA: Total EXTRANJEROS RESIDENTES EN ...',
  //     snippet_highlighted_words: [
  //       'Población',
  //       'población por',
  //       'años',
  //       'ESPAÑA',
  //     ],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72ba90c5bf4c831a6cf951c67efe014c7f.png',
  //     position: 1,
  //   },
  //   {
  //     title: 'https://www.ine.es/jaxi/files/tpx/es/csv/61423.csv',
  //     link: 'https://www.ine.es/jaxi/files/tpx/es/csv/61423.csv',
  //     snippet:
  //       'Censo de Población Resultados por municipios. 2021-2023 Población y edad media por sexo y país de nacimiento (España/extranjero) Unidades: personas/años ...',
  //     snippet_highlighted_words: [
  //       'Población',
  //       'por',
  //       'Población',
  //       'por',
  //       'España',
  //       'años',
  //     ],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72f5e19914926fc590dac46d10ea4f79f8.png',
  //     position: 2,
  //   },
  //   {
  //     title: 'https://datos.gob.es/es/catalogo/ea0010587-poblaci...',
  //     link: 'https://datos.gob.es/es/catalogo/ea0010587-poblacion-por-sexo-edad-ano-a-ano-y-nacionalidad-espanola-extranjera-identificador-api-tpx-poblacion_1827-censo_anual_pobl_22_23_10358-02nac_10360-l0-0201-px1.csv',
  //     snippet:
  //       '... Población por sexo, edad (año a año) y ... años//1 año//10 años//100 y más años//11 años//12 ... España//Andalucía//Almería//Cádiz//Córdoba//Granada//Huelva ...',
  //     snippet_highlighted_words: [
  //       'Población por',
  //       'años',
  //       'años',
  //       'años',
  //       'años',
  //       'España',
  //     ],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72223a4761c5d5deec58d958fe0f029226.png',
  //     position: 3,
  //   },
  //   {
  //     title: 'https://datos.gob.es/es/catalogo/ea0010587-poblaci...',
  //     link: 'https://datos.gob.es/es/catalogo/ea0010587-poblacion-espanoles-extranjeros-por-edad-ano-a-ano-y-sexo-identificador-api-t20-e245-p04-a2019-l0-00012002-px.csv',
  //     snippet:
  //       '... Población (españoles/extranjeros) por edad (año a ... años//1 año//10 años//100 y más//11 años//12 años ... España,,,https://www.ine.es/dyngs/IOE/es/operacion ...',
  //     snippet_highlighted_words: [
  //       'Población',
  //       'por',
  //       'años',
  //       'años',
  //       'años',
  //       'años',
  //       'España',
  //     ],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d7221ab18c837323e445a38abfd595d1fa8.png',
  //     position: 4,
  //   },
  //   {
  //     title: 'https://www.eustat.eus/elementos/xls0021467_c.csv',
  //     link: 'https://www.eustat.eus/elementos/xls0021467_c.csv',
  //     snippet:
  //       'Población de 16 y más años de la C.A. de Euskadi por el grupo de país de ... España;1641592;790422;851170;238114;115904;122210;868521;414697;453824;534957;259821; ...',
  //     snippet_highlighted_words: ['Población', 'años', 'por', 'España'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72aadd8adc307baefc197314060873c1b5.png',
  //     position: 5,
  //   },
  //   {
  //     title: 'Listado Campañas Informativas 2005-2022',
  //     link: 'https://www.sanidad.gob.es/campannas/portada/campanasInformativas.csv',
  //     snippet:
  //       '... Población General"",," "2005,""Preservativo. Por ... años"",""Mayores"",,," "2006,""Estas en la edad ... España alrededor de los 13 años."",""Adolescentes ...',
  //     snippet_highlighted_words: ['Población', 'Por', 'años', 'España', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d720b0f0292a32090e5e9f1ae844a107f3a.png',
  //     position: 6,
  //   },
  //   {
  //     title: 'Descargar - dades Obertes - Generalitat Valenciana',
  //     link: 'https://dadesobertes.gva.es/cataleg.csv?public=true',
  //     snippet:
  //       '... población) de disponibilidad de acceso a redes móviles 5G (por ... España - IDEV","pdf" "ARI. Áreas de ... años siguientes al año en que se ...',
  //     snippet_highlighted_words: ['población', 'por', 'España', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72a624c00a7da531fe93b01dfdd1371a4f.png',
  //     position: 7,
  //   },
  //   {
  //     title: 'https://www.transparencia.gob.sv/institutions/4/of...',
  //     link: 'https://www.transparencia.gob.sv/institutions/4/officials.csv?&institution_id=4&page=2',
  //     snippet:
  //       '... por 20 años. Consultor y Auditor Externo en ... España (Carlos III de Madrid, Autónoma de ... población salvadoreña, entre ellos: la Ley de la Carrera ...',
  //     snippet_highlighted_words: ['por', 'años', 'España', 'población'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72f737b18849d36604f434ed1e99f64d96.png',
  //     position: 8,
  //   },
  //   {
  //     title: 'https://ibestat.caib.es/ibestat/service/ibestat/px...',
  //     link: 'https://ibestat.caib.es/ibestat/service/ibestat/pxcontent/6/es/aa21d6a7-347c-43e8-91a6-13d468ff210e/I302033_0708.csv',
  //     snippet:
  //       '... años por periodo y sexo.", "source": "Institut d ... España (CC BY 3.0)", "updated": "2024-01-26 ... Población Activa (EPA)", "baseperiod": "", "metadata ...',
  //     snippet_highlighted_words: ['años por', 'España', 'Población'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d724d939e86ec27b9324f2df6a38cbbcb9f.png',
  //     position: 9,
  //   },
  //   {
  //     title: 'https://transparencia.villahermosa.gob.mx/doctos/f...',
  //     link: 'https://transparencia.villahermosa.gob.mx/doctos/files/2023/finanzas/jun/NOTAS_A_LOS_EDOS_FINANC_JUNIO_2023.csv',
  //     snippet:
  //       '... España dispuso elegir el primer ayuntamiento de ... por lo que se propuso al Congreso Constituyente ... población urbana y rural del municipio del Centro, en ...',
  //     snippet_highlighted_words: ['España', 'por', 'población'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d723b598d7779d554bbd8d6d725c782841c.png',
  //     position: 10,
  //   },
  //   {
  //     title: 'Series temporales en formato CSV',
  //     link: 'https://www.bde.es/webbe/es/estadisticas/compartido/datos/csv/si_1_5.csv',
  //     snippet:
  //       '... Población. 25 o más años","Estadísticas generales. EPA. Hogares","Inversion extranjera en inmuebles. Tasa interanual.","Inversion extranjera en inmuebles ...',
  //     snippet_highlighted_words: ['Población', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72c8e1dfacdea5b226413e4db3c2e136b4.png',
  //     position: 11,
  //   },
  //   {
  //     title: 'CSV',
  //     link: 'https://micrositios.inai.org.mx/gobiernoabiertoytransparencia/wp-content/uploads/2023/06/Banco-de-Practicas.CSV.Actualizacion.03.19.csv',
  //     snippet:
  //       '... población.;"La iniciativa fue liderada por la ciudad de Geraldton Greenough e incluyó un proyecto de investigación de 3 años ... España, Suecia, Zurich, Suiza, ...',
  //     snippet_highlighted_words: ['población', 'por', 'años', 'España'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d726e3086a8bc61ebffd45e20939fb92375.png',
  //     position: 12,
  //   },
  //   {
  //     title: 'CSV',
  //     link: 'https://sic.gob.mx/opendata/d/20_grupo_etnico_directorio.csv',
  //     snippet:
  //       '... por los nonoalcas debido al gran respeto que ... población por registro de epidemias ... años 700 y 200 a.C., se establecen varias aldeas. De este último año ...',
  //     snippet_highlighted_words: ['por', 'población por', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d7267c0eb4900015aeeb43ce57a8df23bdd.png',
  //     position: 13,
  //   },
  //   {
  //     title: 'https://proxy.hxlstandard.org/data/download/hven22...',
  //     link: 'https://proxy.hxlstandard.org/data/download/hven22-ven-projects.csv?url=https%3A%2F%2Fapi.hpc.tools%2Fv2%2Fpublic%2Fproject%2Fsearch%3FplanCodes%3DHVEN22%26excludeFields%3Dlocation%2CgoverningEntities%2Ctargets%26limit%3D100000&tagger-match-all=on&tagger-01-header=name&tagger-01-tag=%23activity%2Bname&tagger-02-header=versioncode&tagger-02-tag=%23activity%2Bcode%2Bv_hpc&tagger-03-header=currentrequestedfunds&tagger-03-tag=%23value%2Brequested%2Busd&tagger-05-header=objective&tagger-05-tag=%23description%2Bobjective&tagger-06-header=partners&tagger-06-tag=%23org%2Bimpl%2Bname%2Blist&tagger-07-header=startdate&tagger-07-tag=%23date%2Bstart&tagger-08-header=enddate&tagger-08-tag=%23date%2Bend&tagger-09-header=governingEntities&tagger-09-tag=%23sector%2Bcluster%2Blocal%2Bname&tagger-17-header=globalclusters&tagger-17-tag=%23sector%2Bcluster%2Bglobal%2Bname&tagger-18-header=organizations&tagger-18-tag=%23org%2Bprog%2Bname&tagger-19-header=plans&tagger-19-tag=%23response%2Bplan%2Bname&header-row=1&filter01=cut&cut-skip-untagged01=on&filter02=add&add-tag02=%23response%2Bplan%2Bcode&add-value02=%7B%7B%23response%2Bplan%2Bname%7D%7D&add-header02=Response+plan+code&filter03=jsonpath&jsonpath-path03=$%5B0%5D.name&jsonpath-patterns03=%23*%2Bname&filter04=jsonpath&jsonpath-path04=$%5B0%5D.code&jsonpath-patterns04=%23*%2Bcode&filter05=clean&clean-date-tags05=%23date&_gl=1*1pie5e1*_ga*MTI1MTE3OTIzNy4xNjk1OTA2MTk3*_ga_E60ZNX2F68*MTY5NjUxMTk5Mi43LjEuMTY5NjUxMjAwMC41Mi4wLjA.',
  //     snippet:
  //       '... población en necesidad, se requieren ... por el principio de subsidiariedad “tan local como sea posible, tan internacional como sea necesario”, para los años ...',
  //     snippet_highlighted_words: ['población', 'por', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72fdab67abaa126c3a4192e76f10721441.png',
  //     position: 14,
  //   },
  //   {
  //     title: 'listado en formato CSV - Datos Abiertos',
  //     link: 'https://datosabiertos.jcyl.es/web/jcyl/risp/es/ciencia-tecnologia/general/1284166186527.csv',
  //     snippet:
  //       '... población, municipio, provincia, código de ... por la que se desarrolla parcialmente el Decreto ... años 2013, 2014 y 2015.</p> <p>Puede consultar el listado ...',
  //     snippet_highlighted_words: ['población', 'por', 'años'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d726ed0b753b25f7faabb69b1a2fafc58cd.png',
  //     position: 15,
  //   },
  //   {
  //     title: 'https://www.opendatalapalma.es/datasets/5a37711630...',
  //     link: 'https://www.opendatalapalma.es/datasets/5a37711630d344f28e8d35fc1a35bd21_4.csv?outSR=%7B%22latestWkid%22%3A32628%2C%22wkid%22%3A32628%7D',
  //     snippet:
  //       '... por el Ayuntamiento, la Plaza de España y la iglesia de El Salvador",http ... años de la Conquista según consta en las datas de 23 de diciembre de 1507. La villa ...',
  //     snippet_highlighted_words: ['por', 'España', 'años'],
  //     favicon: '',
  //     position: 16,
  //   },
  //   {
  //     title: 'CSV publicaciones',
  //     link: 'https://csic.edu.uy/csv/publicaciones.csv',
  //     snippet:
  //       '... años de suicidio en Uruguay: 7 disciplinas, 7 ... por parte del escribano acerca de quienes ... población, estaríamos simplemente falseando la comprensión y ...',
  //     snippet_highlighted_words: ['años', 'por', 'población'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72f8edd2d6d821521dace3ca2c9acc2a8f.png',
  //     position: 17,
  //   },
  //   {
  //     title: 'https://www.ine.gob.cl/docs/default-source/demogra...',
  //     link: 'https://www.ine.gob.cl/docs/default-source/demografia-y-migracion/cuadros-estadisticos/migraci%C3%B3n-internacional/estimaci%C3%B3n-poblaci%C3%B3n-extranjera-en-chile-por-sexo-grupo-de-edad-y-pa%C3%ADs-seg%C3%BAn-a%C3%B1os-2020-2019-2018-y-regiones-(csv).csv',
  //     snippet:
  //       '... España;2018;Antofagasta;3 H;00 a 04;España;2018;Arica y Parinacota;4 H;00 a 04;España;2018;Atacama;7 H;00 a 04;España;2018;Aysén del General Carlos Ibáñez ...',
  //     snippet_highlighted_words: ['España', 'España', 'España', 'España'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72282672bf037de965012fc52156445829.png',
  //     position: 18,
  //   },
  //   {
  //     title: 'publicaciones biblioteca3.csv',
  //     link: 'https://intranet.secretariajuridica.gov.co/sites/default/files/datos_abiertos/publicaciones%20biblioteca3.csv',
  //     snippet:
  //       '... por razón de la misma obra.","Alcalde Mayor",26 ... población trabajadora de Bogotá, propiciando ... años, incluyendo estadísticas por localidades, rangos de ...',
  //     snippet_highlighted_words: ['por', 'población', 'años', 'por'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d72e51eebbff798a95a2c7889454d071fe6.png',
  //     position: 19,
  //   },
  //   {
  //     title: 'Descarga CSV',
  //     link: 'https://www.transparencia.gob.sv/institutions/63/officials.csv?&institution_id=fantel&page=3',
  //     snippet:
  //       '... por la Infancia durante la finalización del ... años más tarde, se incorporó como funcionaria y ... España. Diplomado en Legislación Municipal, de la ...',
  //     snippet_highlighted_words: ['por', 'años', 'España'],
  //     favicon:
  //       'https://serpapi.com/searches/664d8d061baebb3d9a5ba45a/images/28b2b57c4226174446d6dc643c304d726239bf7d62436e563458ed58b9424aeb.png',
  //     position: 20,
  //   },
  // ]
  return request({
    endpoint: `/serp/search?q=${query}&page=${page}${
      website ? `&website=${encodeURIComponent(website)}` : ''
    }`,
    token,
    signout,
  })
}
