import React, { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import NextbrainSelect from '../../NextbrainSelect'

const WEEKS_MONTH = 4.34524

export const options = {
  Monthly: WEEKS_MONTH,
  Quarterly: WEEKS_MONTH * 3,
  'Four-month': WEEKS_MONTH * 4,
  Biannual: WEEKS_MONTH * 6,
  Yearly: 53,
}

const fromDate = (v) => {
  let date = new Date(v)
  if (isNaN(date) && typeof v === 'string') {
    date = new Date(v + 'Z')
    if (isNaN(date)) date = new Date()
  }
  return date
}

export default function Periodicity({
  model,
  problemType,
  periodicity,
  onChange = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const periodOptions = useMemo(() => {
    if (problemType !== 'Anomaly Detection') return []
    const dateType = Object.entries(model?.dataset?.statistics ?? {}).filter(
      ([, value]) => value.logical_type === 'Datetime',
    )?.[0]

    let begin = dateType?.[1]?.min ?? Date.now()
    let end = dateType?.[1]?.max ?? Date.now()
    begin = fromDate(begin)
    end = fromDate(end)
    const period = Math.max(
      WEEKS_MONTH,
      (end.getTime() - begin.getTime()) / (1000 * 60 * 60 * 24 * 7),
    )

    return Object.entries(options)
      .filter(([, value]) => value <= period)
      .map(([key]) => ({ label: t(key), value: key }))
  }, [t, problemType, model])

  if (problemType !== 'Anomaly Detection') return <></>

  return (
    <Row {...props}>
      <Col xs={12}>
        <NextbrainSelect
          value={
            periodicity ? { label: t(periodicity), value: periodicity } : null
          }
          onChange={(value) => {
            onChange(value?.value)
          }}
          className="basic-single mt-2"
          classNamePrefix="select"
          name={t('Periodicity')}
          options={periodOptions}
          defaultValue={{ value: 'Monthly', label: t('Monthly') }}
        />
      </Col>
    </Row>
  )
}
