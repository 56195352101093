import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const round = (num, decimals = 2) =>
  Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)
export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const readableNumber = (num, decimalComma = true) => {
  if (!num && num !== 0) return ''
  if (Math.abs(num) < 1) return Number(num.toFixed(num > 0.0001 ? 4 : 10))
  const [integer, decimal] = String(num).split('.')
  const integerFormatted = integer.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    decimalComma ? ',' : '.',
  )
  const decimalFormatted = decimal || ''
  if (decimalFormatted.length === 0) {
    return integerFormatted
  }
  if (decimalComma) {
    return `${integerFormatted}.${decimalFormatted}`
  }
  return `${integerFormatted},${decimalFormatted}`
}

export const readableNumberMMM = (
  num,
  roundTo = 2,
  roundToUnderzero = null,
) => {
  if (!num && num !== 0) return ''
  if (roundToUnderzero && num < 1) roundTo = roundToUnderzero

  const res = readableNumber(round(num, roundTo))
  if (num && `${res}` === '0') return num.toExponential(2)
  return res
}

export const textEllipsis = (text, length = 20) => {
  if (text.length <= length) {
    return text
  }
  return `${text.slice(0, length - 3)}...`
}

const lookup = [
  { value: 1e18, symbol: 'E' },
  { value: 1e15, symbol: 'P' },
  { value: 1e12, symbol: 'T' },
  { value: 1e9, symbol: 'G' },
  { value: 1e6, symbol: 'M' },
  { value: 1e3, symbol: 'k' },
  { value: 1, symbol: '' },
]

export function nFormatter(num, digits = 2) {
  let n = ''
  if (num < 0) {
    n = '-'
    num = Math.abs(num)
  }

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup.find(function (item) {
    return num >= item.value
  })

  return (
    n +
    (item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : num)
  )
}

const lookupDefault = [
  { value: 1e9, symbol: 'B' },
  { value: 1e6, symbol: 'M' },
  { value: 1, symbol: '' },
]

export function defaultFormat({ num, digits = 2, wrap, ...props }) {
  let result
  // eslint-disable-next-line
  if (num == 0) result = 0
  else {
    if (Number.isNaN(Number.parseFloat(num))) {
      result = num
      wrap = false
    } else {
      num = parseFloat(num)
      let n = ''
      if (num < 0) {
        n = '-'
        num = Math.abs(num)
      }

      const item = lookupDefault.find(function (item) {
        return num >= item.value
      })
      if (!item?.symbol) wrap = false

      result =
        n +
        (item
          ? round(num / item.value, digits) + item.symbol
          : num < Math.pow(10, -digits)
          ? num?.toExponential(digits)
          : round(num, digits))
    }
  }

  if (wrap) {
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="auto"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => <Tooltip {...props}>{num}</Tooltip>}
      >
        <span {...props}>{result}</span>
      </OverlayTrigger>
    )
  } else return result
}

export function defaultFormatRange({ range, digits = 2, wrap }) {
  if (!range) return range

  const parts = range.split(' to ')
  let from = ''

  if (parts?.[0]?.toLowerCase()?.startsWith('from ')) {
    from = parts[0].substring(0, 5)
    parts[0] = parts[0].slice(5)
  }

  if (parts.length === 2) {
    if (wrap)
      return (
        <>
          {from}
          {defaultFormat({ num: parts[0], digits, wrap })}
          {' to '}
          {defaultFormat({
            num: parts[1],
            digits,
            wrap,
          })}
        </>
      )
    else
      return `${from} ${defaultFormat({
        num: parts[0],
        digits,
      })} to ${defaultFormat({
        num: parts[1],
        digits,
      })}`
  } else return defaultFormat({ num: range, digits, wrap })
}
