import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

export default function Loading({
  imageHeight = '70px',
  message = 'Loading',
  alter = false,
  loading = false,
  maxHeight = true,
  imageProps = {},
  ...props
}) {
  const { t } = useTranslation()
  return (
    <Row
      {...props}
      className={`w-100 ${
        maxHeight ? 'h-100' : ''
      } align-items-center justify-content-center user-select-none ${
        props?.className ?? ''
      }`}
    >
      <Col align="center" xs={6}>
        <Image
          src={alter ? '/Logo/4.svg' : '/Logo/2.svg'}
          style={{
            height: imageHeight,
            filter: 'var(--nextbrain-default-full-invert)',
          }}
          {...imageProps}
        />
        <br />
        <span className="loading-tooltip">
          {typeof message === 'string' ? t(message) : message}
        </span>
      </Col>
    </Row>
  )
}
