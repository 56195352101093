import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { FiChevronsRight } from 'react-icons/fi'

import SingleAccordion from '../single-accordion/SingleAccordion'
import './cookies.css'

function AccordionCookie({
  active,
  setActive,
  name,
  checkProps = {},
  children,
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Row className="accordion-cookies my-2">
        <Col xs={12} onClick={() => setOpen((o) => !o)}>
          <Row>
            <Col xs={12} style={{ maxWidth: 'calc(100% - 100px)' }}>
              <strong className="ms-2">
                {name}
                <FiChevronsRight
                  size={20}
                  className="shift-icon"
                  style={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              </strong>
            </Col>
            <Col
              xs={12}
              style={{ maxWidth: '100px' }}
              onClick={(e) => e.stopPropagation()}
            >
              <Form.Check
                type="switch"
                label={''}
                className="form-switch-share ps-0"
                checked={active}
                onChange={async (e) => setActive(!active)}
                {...checkProps}
              />
            </Col>
          </Row>
        </Col>
        {open && (
          <Col className="pt-2 accordion-cookies-container p-2" xs={12}>
            <SingleAccordion
              header={false}
              forceStatus={open}
              className="single-accordion-cookies w-100"
              defaultStyles=""
              bodyProps={{
                style: { fontSize: '14px' },
              }}
            >
              {children}
            </SingleAccordion>
          </Col>
        )}
      </Row>
    </>
  )
}

export default function CookieConfig({ defaultCookies, onChange, ...props }) {
  const { t } = useTranslation()
  const [active, setActive] = useState(
    defaultCookies ?? {
      base: true,
      support: false,
      analytics: false,
      payment: false,
    },
  )

  useEffect(() => {
    onChange(active)
    // eslint-disable-next-line
  }, [active])

  return (
    <Row className={`p-1 flex-column flex-config ${props?.className ?? ''}`}>
      <Col xs={12}>
        <AccordionCookie
          name={t('Strictly Neccesary')}
          checkProps={{
            checked: true,
            disabled: true,
          }}
        >
          {t(
            'These cookies are essential for the essential for the proper functioning of the website.',
          )}
        </AccordionCookie>
        <AccordionCookie
          active={active.analytics}
          setActive={(e) => setActive({ ...active, analytics: e })}
          name={t('Performance & Analytics')}
        >
          {t(
            'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
          )}
        </AccordionCookie>
        <AccordionCookie
          active={active.support}
          setActive={(e) => setActive({ ...active, support: e })}
          name={t('Support & Chat')}
        >
          {t('These cookies are used to provide you to online chat support.')}
        </AccordionCookie>
        <AccordionCookie
          active={active.payment}
          setActive={(e) => setActive({ ...active, payment: e })}
          name={t('Payment Systems')}
        >
          {t('These cookies are used to provide you with payment systems.')}
        </AccordionCookie>
      </Col>
    </Row>
  )
}
