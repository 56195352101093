import React, { useRef } from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MdRefresh } from 'react-icons/md'
import { useQueryClient } from 'react-query'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { FaPenAlt, FaTrash } from 'react-icons/fa'
import { BsLink45Deg } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { InlineEdit } from '../inline-edit/InlineEdit'
import { useAuth } from '../../providers/AuthProvider'
import {
  deleteRepository,
  refreshRepository,
  updateRepository,
} from '../../services/document'
import { NotificationManager } from 'react-notifications'
export default function Repository({
  repository,
  onDelete,
  onShare,
  ...props
}) {
  const queryClient = useQueryClient()
  const { token, signout, user } = useAuth()
  const { t } = useTranslation()
  const editRef = useRef()

  const created = new Date(repository.updated_at)
  const createdStr = `${created.getDate()} ${created.toLocaleString('default', {
    month: 'short',
  })} ${created.getFullYear()}`
  const isLoading = repository.loading

  return (
    <div {...props}>
      <Row className="d-inline-flex justify-content-between flex-nowrap w-100 relative">
        {isLoading && (
          <span
            className="position-absolute smallp loading-tooltip"
            style={{
              top: '40px',
              left: '4px',
              width: 'fit-content',
            }}
          >
            {t('Updating')}
          </span>
        )}
        <Col
          xs={11}
          className="display-block text-truncate"
          onClick={(e) => e.stopPropagation()}
        >
          <strong className="h5">
            <InlineEdit
              ref={editRef}
              text={repository.name}
              onEdit={(text) =>
                updateRepository({
                  repositoryId: repository.id,
                  name: text,
                  token,
                  signout,
                }).then(() => {
                  queryClient.invalidateQueries([
                    'document-repository',
                    repository.id,
                    token,
                  ])
                })
              }
            />
          </strong>
        </Col>
        <Col
          xs={1}
          className="p-0 d-flex justify-content-end"
          onClick={(e) => e.stopPropagation()}
        >
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="left"
            delay={{ show: 250, hide: 100 }}
            overlay={(props) => (
              <Tooltip
                {...props}
                className={`context-menu-column-type big-menu  ${
                  props?.className ?? ''
                }`}
              >
                <Row className="p-2">
                  <Col
                    className="d-flex justify-content-start align-items-center cursor-pointer icon-btn"
                    xs={12}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      document.querySelector('body').click()
                      editRef.current.click()
                    }}
                  >
                    <FaPenAlt size={20} className="me-2" /> {t('Rename')}
                  </Col>
                  <Col
                    className={`
                    d-flex justify-content-start align-items-center mt-3 cursor-pointer icon-btn ${
                      isLoading ? 'pe-none opacity-50' : ''
                    }
                    `}
                    xs={12}
                    onClick={async (e) => {
                      const target = e.currentTarget
                      target.classList.add('pe-none')
                      target.classList.add('opacity-50')
                      document.querySelector('body')?.click()
                      await refreshRepository({
                        repositoryId: repository.id,
                        token,
                        signout,
                      })
                        .catch((e) => {})
                        .finally(() => {
                          target?.classList?.remove('pe-none')
                          target?.classList?.remove('opacity-50')
                          queryClient.invalidateQueries([
                            'document-repositories',
                            token,
                          ])
                        })
                    }}
                  >
                    <MdRefresh size={20} className="me-2" /> {t('Refresh data')}
                  </Col>
                  {repository.user_id === user.id ? (
                    <Col
                      className={`
                        d-flex justify-content-start align-items-center mt-3 cursor-pointer icon-btn`}
                      xs={12}
                      onClick={async (e) => onShare()}
                    >
                      <BsLink45Deg size={20} className="me-2" /> {t('Share')}
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col
                    className="d-flex justify-content-start align-items-center mt-3 cursor-pointer icon-btn"
                    xs={12}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      const body = `{\n  "prompt": "Your question here",\n  "destination_email": "<can be omitted>",\n  "delete_files": false,\n  "repository_id": "${repository.id}"\n}`
                      const mailtoLink = `mailto:upload@nextbrain.ai?subject=${encodeURIComponent(
                        '[RAG] Your subject',
                      )}&body=${encodeURIComponent(body)}`
                      window.open(mailtoLink, '_blank')
                      document.querySelector('body').click()
                    }}
                  >
                    <MdEmail size={20} className="me-2" /> {t('Query by email')}
                  </Col>
                  <Col
                    className="d-flex justify-content-start align-items-center mt-3 cursor-pointer icon-btn"
                    xs={12}
                    onClick={() => {
                      document.querySelector('body')?.click()
                      onDelete()
                      deleteRepository({
                        repositoryId: repository.id,
                        token,
                        signout,
                      })
                        .then((r) => {
                          if (!r?.ok)
                            NotificationManager.error(
                              t('Failed to delete repository'),
                            )
                        })
                        .finally(() => {
                          queryClient.invalidateQueries([
                            'document-repositories',
                            token,
                          ])
                        })
                    }}
                  >
                    <FaTrash size={20} className="me-2" /> {t('Delete')}
                  </Col>
                </Row>
              </Tooltip>
            )}
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                $('body').click()
              }}
            >
              <BiDotsVerticalRounded
                className="float-right reactive-icon"
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col>
          <span style={{ opacity: 0.75 }}>
            {repository.user_id === user.id
              ? 'Workspace owner'
              : 'Shared with me'}
          </span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Row className="justify-content-between align-items-center ms-0">
          <Col md={'auto'} xs={'auto'} className="ps-0">
            {createdStr}
          </Col>
          <Col md={'auto'} xs={'auto'} className="ps-0 smallp">
            {repository.num_documents}{' '}
            {t('document') + (repository.num_documents === 1 ? '' : 's')}
          </Col>
        </Row>
      </Row>
    </div>
  )
}
