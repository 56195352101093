import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import StatusMessage from './StatusMessage'
import { useModels } from '../../providers/ModelProvider'
import { useSockets } from '../../providers/SocketProvider'

export default function TrainProgress({ clustering = false, kmean = false }) {
  const { activeModel } = useModels()
  const {
    lastStatusMessage,
    onStatusMessage,
    offStatusMessage,
    lastClusterMessage,
    onClusterMessage,
    offClusterMessage,
    onKmeanMessage,
    offKmeanMessage,
  } = useSockets()

  const [statusMessage, setStatusMessage] = useState(
    clustering ? lastClusterMessage : lastStatusMessage,
  )

  useEffect(
    () => {
      if (activeModel) {
        const subscribe = clustering
          ? onClusterMessage
          : kmean
          ? onKmeanMessage
          : onStatusMessage
        const unsubscribe = clustering
          ? offClusterMessage
          : kmean
          ? offKmeanMessage
          : offStatusMessage

        const id = activeModel.id
        const onNewMessage = (model, message) => {
          if (message) setStatusMessage(message)
        }
        subscribe(onNewMessage, id)
        return () => unsubscribe(onNewMessage, id)
      }
    },
    // eslint-disable-next-line
    [activeModel],
  )

  const parseProgress = parseInt(
    clustering
      ? activeModel?.last_clustering_socket?.percent
      : kmean
      ? activeModel?.last_kmean_socket?.percent
      : activeModel.percent_train,
  )
  const progress = Number.isNaN(parseProgress) ? 0 : Math.max(parseProgress, 0)

  return (
    <Col>
      <Row className="justify-content-center">
        <Col className="h-100 mt-5 d-flex align-items-center justify-content-center">
          <div style={{ width: '250px' }}>
            <CircularProgressbar
              value={Math.min(progress, 96)}
              text={`${Math.min(progress, 99)}%`}
              strokeWidth={10}
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: 'rounded',
                pathTransitionDuration: 0.5,
                pathColor: `#4240B5`,
                trailColor: 'var(--nextbrain-background)',
                textColor: 'var(--nextbrain-white-font)',
                text: {
                  fontSize: '20px',
                },
              })}
            />
          </div>
        </Col>
        <Col xs={12}></Col>
        <Col className="mt-3" xs={10}>
          <StatusMessage
            history={
              statusMessage && statusMessage?.level !== 'ERROR'
                ? [statusMessage]
                : null
            }
          />
        </Col>
      </Row>
    </Col>
  )
}
