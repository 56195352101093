import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import Loading from '../../loading/LoadingSmall'

export function Status({ status, message }) {
  const { t } = useTranslation()
  if (status) {
    const len = Object.keys(status).length
    switch (len) {
      case 0:
        break
      case 1:
        const [k, v] = Object.entries(status)[0]
        return (
          <Row className="justify-content-center">
            <Col className="text-center" xs={12}>
              {t('Uploading ')}
              <strong>{k.replace(/^[0-9]*--/, '')}</strong>
            </Col>
            <Col
              className="position-relative py-3"
              style={{ maxWidth: '250px' }}
            >
              <CircularProgressbar
                value={Math.floor(Math.min(v, 96))}
                text={`${Math.floor(Math.min(v, 99))}%`}
                strokeWidth={10}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: 'rounded',
                  pathTransitionDuration: 0.5,
                  pathColor: `#4240B5`,
                  trailColor: 'var(--nextbrain-secondary-color)',
                  textColor: 'var(--nextbrain-white-font)',
                  text: {
                    fontSize: '20px',
                  },
                  height: '250px',
                })}
              />
            </Col>
          </Row>
        )
      default:
        return (
          <Row className="justify-content-center">
            <Col className="text-center" xs={12}>
              {t('Uploading files')}
            </Col>
            {Object.entries(status).map(([k, v], i) => (
              <Col key={`${k}-${i}`} xs={12}>
                <Row>
                  <Col className="d-inline-block text-truncate" xs={6}>
                    {k?.replace(/^[0-9]+--(.*)/, '$1') ?? k}
                  </Col>
                  <Col className="d-flex align-items-center" xs={5}>
                    <Row className="w-100">
                      <Col
                        className="single-import-load-bar-upload"
                        style={{ maxWidth: `${v}%`, minWidth: `${v}%` }}
                        xs={'auto'}
                      ></Col>
                    </Row>
                  </Col>
                  <Col xs={1}>{Math.floor(v)}%</Col>
                </Row>
              </Col>
            ))}
          </Row>
        )
    }
  }

  return <Loading message={message} />
}
