import React, { createContext, useContext } from 'react'

const LocalPropsProvider = createContext({})

// This provider is intended to be used to pass props to children components
//that are managed by external libraries but allow for customization.
export default function PropsProvider({ children, ...props }) {
  return (
    <LocalPropsProvider.Provider value={props}>
      {children}
    </LocalPropsProvider.Provider>
  )
}

export function useProps() {
  return useContext(LocalPropsProvider)
}
