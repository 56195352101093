import { Handle, Position } from 'reactflow'
import { Row, Col, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function DefaultOutputNode({ id, data, isConnectable }) {
  const { t } = useTranslation()

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id={`${id}_target`}
        style={{
          visibility: 'hidden',
          pointerEvents: 'none',
          background: '#555',
          left: '-35px',
          minWidth: '25px',
          minHeight: '25px',
        }}
        isConnectable={isConnectable}
      />
      <Row
        className="datasource-output-node flex-column justify-content-center pe-none"
        style={{ visibility: 'hidden' }}
      >
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={12}
        >
          {t('End ')}
          <Image
            className="d-inline-block ms-1"
            src={'/goal.svg'}
            height={20}
          />
        </Col>
        <Col className="d-flex justify-content-center" xs={12}>
          <span className="small text-nowrap mt-0">{t('Final dataset')}</span>
        </Col>
      </Row>
    </>
  )
}
