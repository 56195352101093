import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'

export default function CustomPopover({
  trigger = 'click',
  placement = 'bottom-end',
  children,
  button = (
    <BsThreeDotsVertical
      className="iconscontentpoints"
      style={{ marginTop: '-5px' }}
    />
  ),
  containerProps = {},
  ...props
}) {
  return (
    <OverlayTrigger
      rootClose
      trigger={trigger}
      placement={placement}
      overlay={
        <Popover
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className="floating-box"
        >
          <Popover.Body>{children}</Popover.Body>
        </Popover>
      }
      {...props}
    >
      <div
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        {...containerProps}
      >
        {button}
      </div>
    </OverlayTrigger>
  )
}
