import React, { useRef, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
  checkDb,
  getRelationalDatabases,
  uploadDb,
} from '../../../services/csv'
import { awaitTask } from '../../../services/base'

import { useAuth } from '../../../providers/AuthProvider'
import { SqlEditor } from '../../editor/editor'
import { useModels } from '../../../providers/ModelProvider'
import BouncyButton from '../../bouncy-button/BouncyButton'
import { NotificationManager } from 'react-notifications'
import { GridTable } from '../../grid-table/GridTable'
import { FaInfoCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { awaitTaskCall } from '../../../services/base'
import NextbrainSelect from '../NextbrainSelect'
import { FaSpinner } from 'react-icons/fa'
import { AssistantCheck } from '../AssistantCheck'
import RoleDisable from '../../utils/RoleDisable'

export default function Database({
  actionLabel = 'Create new model',
  configuration,
  onFinish = null,
  sampleData = null,
  tableControlWidth = 200,
  single,
  onNameChange = () => {},
  onAskAi = false,
}) {
  const { addModel } = useModels()
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [sqlValue, setSqlValue] = useState(
    configuration?.sql ?? `SELECT * FROM yourtable WHERE yourcondition`,
  )
  const [isChecking, setIsChecking] = useState(false)
  const [sample, setSample] = useState(sampleData)

  const linkRef = useRef()
  const usernameRef = useRef()
  const passwordRef = useRef()
  const dbTypeRef = useRef()
  const dbRef = useRef()
  const [database, setDatabase] = useState(configuration?.database ?? '')

  const [listParams, setListParams] = useState({
    link: configuration?.link ?? '',
    username: configuration?.username ?? '',
    password: configuration?.password ?? '',
    database_type: configuration?.database_type ?? '',
  })
  const update = (e) => {
    setListParams((p) => ({
      ...p,
      [e.target.dataset['connectionParam']]: e.target.value.trim(),
    }))
  }

  const { data: databaseInfo, isLoading } = useQuery(
    ['listRelationalDatabases', listParams],
    async () => {
      if (
        !listParams.link ||
        !listParams.username ||
        !listParams.password ||
        !listParams.database_type
      )
        return []

      try {
        const db = await awaitTaskCall(getRelationalDatabases, 1000, 50000, {
          ...listParams,
          token,
          signout,
        })
        return db.databases.map((d) => ({ label: d, value: d }))
      } catch (e) {
        if (e.error && typeof e.error === 'string') {
          NotificationManager.error(
            <Row>
              {e.error.split('\n').map((m, i) => (
                <Col className="mt-2" key={i} xs={12}>
                  {m}
                </Col>
              ))}
            </Row>,
          )
          return
        }
        NotificationManager.error(t(e?.error ?? 'Failed to list databases'))
        return []
      }
    },
    { staleTime: Infinity, keepPreviousData: false },
  )

  if (!onFinish)
    onFinish = (sample, dbData) => {
      uploadDb(dbData, token, signout).then((response) => {
        if (!response || response.length === 0) return
        addModel(response[0])
      })
    }

  return (
    <Row justify="between">
      <Col md={12} className="nopaddingright mt-3">
        <p>{t('Create new model from database')}</p>
      </Col>
      <Col md={12}>
        <Form>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formDBURL">
                <Form.Label>{t('Database URL')}</Form.Label>
                <Form.Control
                  ref={linkRef}
                  defaultValue={configuration?.link ?? ''}
                  className="nb-input-soft py-2"
                  type="text"
                  placeholder={t('Enter your database URL (port included)')}
                  onBlur={update}
                  data-connection-param="link"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formDBUsername">
                <Form.Label>{t('Username')}</Form.Label>
                <Form.Control
                  ref={usernameRef}
                  className="nb-input-soft py-2"
                  defaultValue={configuration?.username ?? ''}
                  type="text"
                  placeholder={t('Username')}
                  onBlur={update}
                  data-connection-param="username"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDBPassword">
                <Form.Label className="d-flex align-items-center">
                  {t('Password')}
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {t(`We encrypt all sensitive data`)}.
                      </Tooltip>
                    )}
                  >
                    <span className="mx-2">
                      <FaInfoCircle style={{ marginTop: '-10px' }} size={15} />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  ref={passwordRef}
                  className="nb-input-soft py-2"
                  defaultValue={configuration?.password ?? ''}
                  type="password"
                  placeholder={t('Password')}
                  onBlur={update}
                  data-connection-param="password"
                />
                {/* <Form.Text className="text-muted">
                  {t('We will never store your password.') +
                    ' ' +
                    t(
                      'If you automated updates we will explicitly ask you the password to store',
                    )}
                </Form.Text> */}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formDBDatabase">
                <Form.Label>{t('Type of database')}</Form.Label>
                <select
                  ref={dbTypeRef}
                  defaultValue={configuration?.database_type ?? 'mysql'}
                  className="form-select mt-2 nb-input-soft raw py-2"
                  onBlur={update}
                  data-connection-param="database_type"
                >
                  <option value="mysql">MySQL</option>
                  <option value="mariadb">MariaDB</option>
                  <option value="postgresql">PostgreSQL</option>
                  <option value="sqlserver">SQL Server</option>
                  <option value="as400">DB2</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDBDatabase">
                <Form.Label>
                  {t('Database')}
                  {isLoading && (
                    <FaSpinner size={12} className={`spinner-mongodb ms-1`} />
                  )}
                </Form.Label>
                <Form.Control
                  ref={dbRef}
                  type="text"
                  defaultValue={configuration?.database ?? ''}
                  className="nb-input-soft d-none"
                  placeholder={t('Database')}
                />
                <NextbrainSelect
                  value={database ? { label: database, value: database } : null}
                  onChange={(v) => {
                    dbRef.current.value = v.value
                    setDatabase(v.value)
                  }}
                  options={Array.isArray(databaseInfo) ? databaseInfo : []}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  className={`basic-single `}
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  creatable={true}
                  isMulti={false}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <SqlEditor sqlValue={sqlValue} setSqlValue={setSqlValue} />
            </Col>
          </Row>
        </Form>
      </Col>

      <Col
        className="mt-2"
        style={{
          minWidth: `${Math.max(tableControlWidth, 250)}px`,
          maxWidth: `${Math.max(tableControlWidth, 250)}px`,
        }}
      >
        <Row>
          <Col xs={12}>
            <RoleDisable className="w-100">
              <BouncyButton
                loading={isChecking}
                disabled={isChecking}
                alternative={true}
                onClick={() => {
                  const dbData = {
                    link: linkRef.current.value,
                    username: usernameRef.current.value,
                    password: passwordRef.current.value,
                    database_type: dbTypeRef.current.value,
                    database: dbRef.current.value,
                    sql: sqlValue,
                  }
                  setSample(null)
                  setIsChecking(true)
                  checkDb(dbData, token, signout)
                    .then(async (response) => {
                      if (response.task_id) {
                        awaitTask({ taskUuid: response.task_id })
                          .then((r) => {
                            setSample({
                              columns: r.columns,
                              sample: r.data,
                            })
                            setIsChecking(false)
                          })
                          .catch((e) => {
                            NotificationManager.error(
                              e?.error ??
                                'Failed to retrieve data from database',
                            )
                            setIsChecking(false)
                          })
                      } else {
                        NotificationManager.error('Failed to connect')
                        setIsChecking(false)
                      }
                    })
                    .catch((e) => setIsChecking(false))
                }}
                className="config-button w-100"
                style={{
                  backgroundColor: isChecking ? '#DCDCDC' : '#4240B5',
                }}
              >
                {t('Test')}
              </BouncyButton>
            </RoleDisable>
          </Col>
          <Col xs={12} className="mt-2">
            <RoleDisable className="w-100">
              <Button
                onClick={() => {
                  const dbData = {
                    link: linkRef.current.value,
                    username: usernameRef.current.value,
                    password: passwordRef.current.value,
                    database_type: dbTypeRef.current.value,
                    database: dbRef.current.value,
                    sql: sqlValue,
                  }
                  onFinish(sample, dbData)
                }}
                className="config-button w-100"
                disabled={!sample}
              >
                {t(actionLabel)}
              </Button>
            </RoleDisable>
          </Col>
          <Col className="mt-1" xs={12}>
            {onAskAi && (
              <AssistantCheck
                className="w-100 ms-0 mb-3"
                onChange={onAskAi}
                enable={() => {
                  const cols = sample?.columns?.length
                  if (cols && cols > 500) return false
                  return true
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col
        className="mt-2"
        xs={8}
        style={{
          minWidth: `calc(100% - ${Math.max(tableControlWidth, 250)}px)`,
          maxWidth: `calc(100% - ${Math.max(tableControlWidth, 250)})`,
        }}
      >
        {sample && (
          <Row>
            <Col xs={12}>
              <GridTable
                rows={sample.sample}
                rowsPerPage={30}
                header={[sample.columns]}
                index={(i) => (
                  <div
                    className={`grid-table-cell index-cell ${i ? '' : 'first'}`}
                  >
                    {i ? i : ''}
                  </div>
                )}
                className="w-100 table-view-data"
                defaultColumnWidth={(str) => Math.max(200, str.length * 12)}
                style={{ maxHeight: '300px' }}
                pagerLast={true}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
