import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdOutlineWarning } from 'react-icons/md'

import { MdPreview } from 'react-icons/md'
import { FaUpload } from 'react-icons/fa'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import useUploadCsv from '../../upload-csv/useUploadCSV'
import { uploadCSV } from '../../../services/csv'

import { useAuth } from '../../../providers/AuthProvider'
import { useModels } from '../../../providers/ModelProvider'
import { zip } from '../../../util/other'
import { NotificationManager } from 'react-notifications'
import { AssistantCheck } from '../AssistantCheck'
import CrashFallback from '../../crash-fallback/CrashFallback'
import { awaitTask, upload } from '../../../services/base'
import ColumnPaginatedGridTable from '../../grid-table/ColumnPaginatedGridTable'
import RoleDisable from '../../utils/RoleDisable'
import Slider from '../../slider/Slider'

export default function LocalFile({
  actionLabel = 'Create new model from local file',
  single = false,
  configuration,
  onFinish = null,
  sampleData = null,
  enableEager = false,
  onNameChange = () => {},
  onAskAi = null,
  sampleSize = null,
  setSampleSize = null,
}) {
  const { addModel, role } = useModels()
  const { user, token, signout } = useAuth()
  const { t } = useTranslation()
  const [fileToUpload, setFileToUpload] = useState(null)
  const [uploadingFile, setUploadingFile] = useState(null)
  const [taskId, setTaskId] = useState(null)
  const [newCsvData, setNewCsvData] = useState(null)
  const [UploadCsv, setFileLoadProgress, progress] = useUploadCsv()
  const assistantRef = useRef()

  useEffect(() => {
    const rows = newCsvData?.data?.rows
    if (setSampleSize) setSampleSize(rows > 200000 ? 200000 : rows)
    // eslint-disable-next-line
  }, [newCsvData])

  const handleFileCallback = async (file, retries = 10) => {
    setFileToUpload(file)
    onNameChange(file.name.replace(/\..*$/, ''))
    setFileLoadProgress(0)
    setTaskId(null)
    const uploadResult = await upload({
      endpoint: '/csv/check-file',
      token,
      filename: 'csv_file',
      file,
      onProgress: (progress) => setFileLoadProgress(progress),
      json: true,
    }).catch((e) => ({ failed: true }))

    if (uploadResult?.failed && retries) {
      await new Promise((resolve) => setTimeout(resolve, 4000))
      NotificationManager.warning(t('Upload failed, retring') + '...')
      return await handleFileCallback(file, retries - 1).catch((e) => {})
    }
    if (!uploadResult?.status) {
      NotificationManager.error(t('Invalid file type'))
      setFileLoadProgress(null)
      return
    }
    setTaskId(uploadResult?.response?.task_id)
    const tableData = await awaitTask({
      taskUuid: uploadResult.response.task_id,
      sleep: 1000,
    }).catch((e) => {
      setFileLoadProgress(null)
    })

    if (tableData?.columns && tableData?.data) {
      setNewCsvData({
        type: 'csv',
        data: {
          columns_order: tableData.columns,
          head: tableData.columns.reduce((acc, col, i) => {
            acc[col] = []
            for (let row of tableData.data) {
              acc[col].push(row[i])
            }
            return acc
          }, {}),
          rows: tableData.rows,
          target: null,
          ok: true,
        },
      })
      setFileLoadProgress(null)
    } else {
      let reader = new FileReader()
      reader.onload = function () {
        setNewCsvData({
          type: 'binary',
          data: reader.result,
        })
        reader.onerror = function () {
          setFileLoadProgress(null)
        }
        setFileLoadProgress(null)
      }
    }
    return
  }

  if (!onFinish)
    onFinish = (sample, userId, fileToUpload, taskId) =>
      uploadCSV(userId, fileToUpload, token, signout, true, null, {}, taskId)
        .then((response) => {
          for (let model of response) {
            addModel(model)
          }
          setFileToUpload(null)
        })
        .finally(() => {
          setUploadingFile(false)
        })

  useEffect(
    () => {
      if (!uploadingFile /* || !newCsvData*/) return
      if (!newCsvData || newCsvData.type === 'binary') {
        onFinish({ columns: [], sample: [] }, user.id, fileToUpload, taskId)
        return
      }

      let sample = null
      sample = {
        columns: newCsvData.data?.columns_order ?? [],
        sample: zip(
          (newCsvData.data?.columns_order ?? []).map(
            (c) => newCsvData.data.head[c],
          ),
          30,
        ),
      }
      if (fileToUpload === null) return
      onFinish(sample, user.id, fileToUpload, taskId)
    },
    // eslint-disable-next-line
    [uploadingFile],
  )

  useEffect(() => {
    if (configuration?.fileToUpload) {
      if (sampleData) {
        setFileToUpload(configuration?.fileToUpload)
        setNewCsvData({
          type: 'csv',
          data: {
            columns_order: sampleData.columns,
            head: sampleData.columns.reduce((acc, col, i) => {
              acc[col] = []
              for (let row of sampleData.sample) {
                acc[col].push(row[i])
              }
              return acc
            }, {}),
            target: null,
            ok: true,
          },
        })
      } else {
        handleFileCallback(configuration?.fileToUpload)
      }
    }
    // eslint-disable-next-line
  }, [])

  const tableData = useMemo(() => {
    if (newCsvData?.data) {
      try {
        return [
          [newCsvData.data.columns_order],
          zip(
            newCsvData.data.columns_order.map((c) => newCsvData.data.head[c]),
            10,
          ),
        ]
      } catch (e) {}
    }
    return null
  }, [newCsvData])

  if (role !== 'editor') {
    return (
      <Row>
        <Col className="dflex-center" style={{ minHeight: '40vh' }} xs={12}>
          {t('File uploading disabled in this shared workspace')}
        </Col>
      </Row>
    )
  }

  let enableSample = false
  const rows = newCsvData?.data?.rows
  let cells = rows * newCsvData?.data?.columns_order?.length
  if (setSampleSize && !Number.isNaN(Number.parseInt(cells))) {
    if (cells > 5 * 1000 * 1000 && rows > 200000) enableSample = true
  } else {
    cells = null
  }

  return (
    <Row>
      <Col
        className={`mb-md-0 mb-4 ${fileToUpload ? 'd-none' : ''}`}
        md={12}
        xs={12}
        align="center"
      >
        <UploadCsv
          onHandleFile={(...args) => {
            setNewCsvData(null)
            handleFileCallback(...args)
          }}
          style={{
            height: '100%',
          }}
          concern={true}
          header={
            <>
              {t('Drag and drop your predicting data file here')}
              <br />
              <FaUpload size={60} />
            </>
          }
        />
      </Col>
      <Col md={12} xs={12} className="mt-5 mt-md-0">
        {fileToUpload ? (
          newCsvData?.type === 'csv' && Array.isArray(tableData) ? (
            <>
              <span className="small">
                <strong className="ms-2 d-flex align-items-center">
                  <MdPreview className="me-1" size={20} />
                  {t('Preview')}
                </strong>
              </span>
              <CrashFallback
                style={{ minHeight: '0px' }}
                message={
                  <Row className="mt-5">
                    <Col md={12} align="center">
                      <h5 className="text-secondary">
                        {t('Preview cannot be displayed')}
                      </h5>
                    </Col>
                  </Row>
                }
                t={t}
              >
                <div
                  className="mt-2"
                  style={{
                    maxWidth: '100%',
                    overflow: 'auto',
                    maxHeight: '500px',
                  }}
                >
                  <ColumnPaginatedGridTable
                    className="table-view-data"
                    rows={tableData[1]}
                    rowsPerPage={10}
                    header={tableData[0]}
                    headerElement={(c) => (
                      <span className="d-inline-block text-nowrap">{c}</span>
                    )}
                    cellElement={(c) => (
                      <span className="d-inline-block text-nowrap">{c}</span>
                    )}
                    navProps={{
                      className: 'd-inline-block',
                    }}
                  />
                </div>
              </CrashFallback>
            </>
          ) : progress !== null ? (
            <Row className="justify-content-center flex-row">
              {progress === 100 ? (
                <Col
                  className="dflex-center"
                  xs={12}
                  style={{ minHeight: '350px' }}
                >
                  <span className="loading-tooltip">{t('Parsing data')}</span>
                </Col>
              ) : (
                <Col
                  className="position-relative"
                  xs={12}
                  style={{ maxWidth: '300px' }}
                >
                  <CircularProgressbar
                    value={Math.min(parseInt(progress), 100)}
                    text={`${Math.min(parseInt(progress), 100)}%`}
                    strokeWidth={10}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: 'rounded',
                      pathTransitionDuration: 0.5,
                      pathColor: `#4240B5`,
                      trailColor: 'var(--nextbrain-background)',
                      textColor: 'var(--nextbrain-white-font)',
                      text: {
                        fontSize: '20px',
                      },
                    })}
                  />
                </Col>
              )}
            </Row>
          ) : (
            <Row className="mt-5">
              <Col md={12} align="center">
                <h5 className="text-secondary">
                  {t('Preview cannot be displayed')}
                </h5>
              </Col>
            </Row>
          )
        ) : (
          <></>
        )}
        <Row className="text-center justify-content-center">
          {fileToUpload && fileToUpload.size > 1024 * 1024 * 50 && (
            <Col xs={'auto'}>
              <MdOutlineWarning
                size={30}
                color="var(--nextbrain-warning-color)"
              />
              {t('Large file detected')}
              {` (${(fileToUpload.size / 1024 / 1024).toFixed(2)} MB)`}
              <div>
                {t(
                  'Importing and preparing this dataset might take a few minutes',
                )}
              </div>
            </Col>
          )}
        </Row>
      </Col>

      <Col className="mb-2 mt-3 d-flex justify-content-end" xs={12}>
        <Row
          className={
            fileToUpload ? 'w-100 mb-2 mt-3 justify-content-end' : 'd-none'
          }
        >
          {enableSample && (
            <Col xs="auto" className="position-relative me-auto">
              <label
                className="position-absolute"
                style={{ top: '-20px', fontSize: '13px' }}
                htmlFor="local-file-sample-size"
              >
                {t('Sample size (optional)')}
              </label>
              <div className="input-slider dynamic-predict-input-slider position-relative">
                <Form.Control
                  name="sample"
                  type="number"
                  placehodler={200000}
                  ariaLabel="number"
                  onKeyPress={(event) => {
                    // Allow minus only at first position
                    if (event.key === '-' && event.target.value.length === 0) {
                      return
                    }
                    // Allow only integers
                    if (isNaN(event.key)) {
                      event.preventDefault()
                      event.stopPropagation()
                    }
                  }}
                  className="no-arrows big-form nb-input-soft force mt-0 py-0 base-input"
                  value={sampleSize}
                  onChange={(event) => {
                    setSampleSize((prevState) => {
                      if (event.target.value.length === 0) prevState = 200000
                      else prevState = parseFloat(event.target.value)
                      return prevState
                    })
                  }}
                />
                <Slider
                  updateLeft={false}
                  updateRight={false}
                  onChange={(e) => {
                    setSampleSize((prevState) => {
                      return parseFloat(e)
                    })
                  }}
                  min={Math.min(200000, rows)}
                  max={rows}
                  ceiling={rows}
                  value={sampleSize}
                  onMouseDown={() => {}}
                />
              </div>
            </Col>
          )}
          <Col xs="auto">
            {onAskAi && (
              <AssistantCheck
                onChange={onAskAi}
                ref={assistantRef}
                enable={() => {
                  const cols = tableData?.[0]?.[0]?.length
                  if (cols && cols > 500) return false
                  return true
                }}
              />
            )}
          </Col>
          <Col className="d-flex justify-content-end pe-0" xs={'auto'}>
            <Button
              onClick={() => {
                setFileToUpload(null)
              }}
              label={t('Cancel')}
              className="config-button float-md-end original empty-secondary"
              disabled={!fileToUpload}
              style={{ maxHeight: 48 }}
            >
              {t('Cancel')}
            </Button>
          </Col>
          <Col className=" d-flex justify-content-end" xs={'auto'}>
            <RoleDisable className="w-100">
              <Button
                onClick={() => {
                  setUploadingFile(true)
                }}
                label={t(actionLabel)}
                className="config-button float-md-end"
                disabled={
                  uploadingFile || (!enableEager && !newCsvData) || !taskId
                }
                style={{ maxHeight: 48 }}
              >
                {t(actionLabel)}
              </Button>
            </RoleDisable>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
