import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { Row, Col, Form } from 'react-bootstrap'
import { getMMMTotalFunnelEffect } from '../../services/model'
import Loading from '../loading/LoadingSmall'
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import { defaultFormat, round } from '../utils/formating'
import { BasicTooltip } from '@nivo/tooltip'
import { getMMMDataColumnInfo } from '../../util/models'
import { useTranslation } from 'react-i18next'

const MARGIN_TOP = 10
const MARGIN_RIGHT = 120
const MARGIN_BOTTOM = 80
const MARGIN_LEFT = 0

const monthFormat = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')

  return `${year}-${month}`
}

export default function TotalFunnelEffect({
  model,
  inView = true,
  isInView,
  ...props
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const ref = useRef()
  const [graphHeight, setGraphHeight] = useState(250)

  const { isLoading: loadingFunnel, data } = useQuery(
    ['mmm-total-funnel-effect', model?.id],
    async () => {
      if (model?.id)
        return getMMMTotalFunnelEffect({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )
  const [mode, setMode] = useState(false)

  const [heatmaps, isDate, columnInfo, bounds] = useMemo(() => {
    if (data && data?.columns && data?.data && data?.index) {
      const columnInfo = getMMMDataColumnInfo(model, 'week ')

      const dateCol = model?.mmm?.datetime_col
      const isDate =
        model?.dataset?.final_column_status?.[dateCol] === 'Datetime'

      let min = Infinity
      let max = -Infinity

      const res = data.index.map((v, i) => {
        return {
          id: v,
          data: data.data[i].map((val, j) => {
            min = Math.min(min, val)
            max = Math.max(max, val)
            return {
              x: j,
              y: Math.abs(val) < 0.01 ? 0 : val,
            }
          }),
        }
      })
      const bound = Math.max(Math.abs(min), Math.abs(max))

      res.forEach((d) =>
        d.data.forEach((c) => {
          if (!c.y) {
            c.y = 0
            c.o = 0
            return
          }
          const [n, base] = c.y < 0 ? [-1, Math.abs(min)] : [1, Math.abs(max)]
          let l = 0.05 * (1 - Math.log(Math.abs(c.y)) / Math.log(base))
          l = n * l * bound + c.y
          c.o = c.y
          c.y = l
        }),
      )

      if (mode) {
        const res2 = []
        res.forEach((d, i) => {
          const temp = []
          d.data.forEach((c, j) => {
            if (j % 4 === 0) {
              temp.push({
                x: j / 4,
                y: c.y,
                o: c.o,
              })
            } else {
              const last = temp[temp.length - 1]
              last.y += c.y
              last.o += c.o
            }
          })
          res2.push({
            id: d.id,
            data: temp,
          })
        })
        min = Infinity
        max = -Infinity
        res2.forEach((d) => {
          d.data.forEach(({ y }) => {
            min = Math.min(min, y)
            max = Math.max(max, y)
          })
        })
        const bound2 = Math.max(Math.abs(min), Math.abs(max))
        return [res2, isDate, columnInfo, bound2 * 0.5]
      }

      return [res, isDate, columnInfo, bound * 0.5]
    }
    return []
    // eslint-disable-next-line
  }, [data, mode])

  useEffect(() => {
    if (ref.current) {
      setGraphHeight(
        Math.max(ref.current.getBoundingClientRect().height - 90, 250),
      )
    }
  }, [ref, heatmaps])

  const labelsEvery = mode ? 2 : 5
  const labelFactor = mode ? 4 : 1
  const labelFormat = mode ? monthFormat : null

  const csvData = useMemo(() => {
    if (heatmaps) {
      const dates = heatmaps[0].data
        .map((d) => d.x)
        .map((v) =>
          isDate
            ? columnInfo.map(
                Number.parseInt(v) * labelFactor,
                labelFormat,
                mode,
              )
            : `${mode ? t('month') : t('week')} ${Number.parseInt(v) + 1}`,
        )
      const values = heatmaps.map((d) => d.data.map((v) => v.o))
      const result = [
        ['', ...dates],
        ...values.map((v, i) => [heatmaps[i].id, ...v]),
      ]

      return encodeURIComponent(JSON.stringify(result))
    }
    return null
    // eslint-disable-next-line
  }, [heatmaps])

  if (!loadingFunnel && !heatmaps) return null
  return !heatmaps ? (
    <Loading />
  ) : (
    <Row>
      <Col className="d-flex justify-content-start" xs={12}>
        <div className="d-inline-flex justify-content-end w-100">
          <Form.Check
            type="switch"
            label={t('Show monthly')}
            className="form-switch-share ps-0 me-5"
            checked={mode}
            onChange={(e) => setMode(e.target.checked ? true : false)}
          />
        </div>
      </Col>
      <div
        className="data-holder display-none"
        data-csv={csvData}
        data-filename={`funnel_effect__${model.id}`}
      ></div>
      <Col
        className="pe-0 "
        xs={3}
        style={{
          maxWidth: '190px',
          marginTop: `${MARGIN_TOP}px`,
          marginBottom: `${MARGIN_BOTTOM}px`,
          marginRight: '0px',
        }}
      >
        <Row className="w-100" style={{ minHeight: '100%' }}>
          {heatmaps?.map((d, i) => (
            <Col
              className="d-flex align-items-center justify-content-end pe-0 legend-funnel"
              key={d.id}
              xs={12}
              title={d.id}
            >
              <span className="d-inline-block text-truncate">{d.id}</span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        {...props}
        className={`${props?.className ?? ''} px-0`}
        style={{
          maxWidth: 'calc(100% - 190px)',
          ...(props?.style ?? {}),
          minHeight: `${heatmaps?.length * 20 + 50}px`,
        }}
        xs={12}
        ref={ref}
      >
        <ResponsiveHeatMapCanvas
          data={heatmaps}
          margin={{
            top: MARGIN_TOP,
            right: MARGIN_RIGHT,
            bottom: MARGIN_BOTTOM,
            left: MARGIN_LEFT,
          }}
          valueFormat=">-.2f"
          label={({ data }) => {
            return data.y ? `${round(data.y, 2)}%` : ''
          }}
          labelTextColor="#000"
          enableLabels={false}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 3,
            tickPadding: 5,
            legendOffset: 60,
            legendPosition: 'middle',
            tickRotation: -45,
            format: (d) => {
              return (d && !(d % labelsEvery)) ||
                d === heatmaps?.[0]?.data?.length - 1
                ? columnInfo.map(d * labelFactor, labelFormat)
                : ''
            },
          }}
          emptyColor="#ffffff"
          colors={{
            type: 'diverging',
            scheme: 'red_blue',
            divergeAt: 0.5,
            minValue: -bounds,
            maxValue: bounds,
          }}
          theme={{
            fontSize: 22,
            textColor: '#ADBAC7',
            legends: {
              ticks: {
                text: {
                  fontSize: 13,
                  fill: '#ADBAC7',
                },
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 10,
                  fill: '#ADBAC7',
                },
              },
            },
          }}
          tooltip={({ cell }) => {
            return (
              <BasicTooltip
                id={
                  isDate
                    ? columnInfo.map(cell.data.x * labelFactor)
                    : `week ${cell.data.x + 1}`
                }
                color={cell.color}
                value={defaultFormat({
                  num: cell.data.o === null ? 0 : cell.data.o,
                })}
                enableChip
              />
            )
          }}
          legends={[
            {
              anchor: 'top-right',
              translateX: 45,
              translateY: 0,
              length: graphHeight,
              thickness: 10,
              direction: 'column',
              tickPosition: 'after',
              tickSize: 5,
              tickSpacing: 4,
              tickOverlap: false,
              tickFormat: '>-.2s',
              title: `${model?.target} →`,
              titleAlign: 'middle',
              titleOffset: 4,
            },
          ]}
          borderWidth={3}
          borderColor={{ from: 'color', modifiers: [] }}
        />
      </Col>
    </Row>
  )
}
