import React, { useMemo, useEffect } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import HelpTooltip from '../HelpTooltip'
import { FaChevronRight, FaTrash } from 'react-icons/fa'
import SingleAccordion from '../../single-accordion/SingleAccordion'
import { TRAINFLOW_ACTIONS, TUNNING_STEP } from './trainflowReducer'
import NBCheck from '../../form/NBCheck'
import NextbrainSelect from '../NextbrainSelect'
import { MdOutlineWarning } from 'react-icons/md'
import { isValidDate, zip } from '../../../util/other'
import './TrainFlow.css'
import { getCsvSeriesHead } from '../../../services/csv'
import { useAuth } from '../../../providers/AuthProvider'
import NBGraphDateSelector from '../../form/NBGraphDateSelector'

const NO_FINE_TUNNING = {
  'Marketing Mix Modeling': false,
  'Anomaly Detection': false,
}

const hideIf = (b) => (b ? 'd-none' : '')

const PERIODICITY_OPTIONS = [
  { label: 'Auto', value: 'Auto' },
  { label: 'Weekly', value: '7' },
  { label: 'Monthly', value: '4' },
  { label: 'Yearly', value: '12' },
]

export default function FineTunning({
  target,
  problemType,
  dispatch,
  advancedOpen,
  setAdvancedOpen,
  shouldUseSynthetic,
  useSynthetic,
  syntheticEnabled,
  balanceDataEnabled,
  updateSynthetic,
  switchSynthetic,
  switchBalanceData,
  removeOutliers,
  removeOutliersEnabled,
  updateRemoveOutliers,
  switchRemoveOutliers,
  periodicicty,
  setPeriodicity = () => {},
  step,
  model,
  parameters,
  forecastingDate,
  forecast_limit,
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const disabled = !!NO_FINE_TUNNING[problemType]

  const periodicicty_options = useMemo(
    () =>
      PERIODICITY_OPTIONS.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  )

  const { data: seriesForecast } = useQuery(
    [
      'series-csv-forecast',
      problemType,
      model?.dataset?.csv?.id,
      target,
      forecastingDate,
    ],
    async () => {
      if (
        model?.id &&
        problemType === 'Forecast' &&
        target &&
        forecastingDate
      ) {
        const data = await getCsvSeriesHead(
          model?.dataset?.csv?.id,
          [target, forecastingDate],
          token,
          signout,
        )
        if (data?.head) {
          const items = zip([data.head[target], data.head[forecastingDate]])
            .map(([t, f]) => [t, new Date(f)])
            .sort((a, b) => a[1].getTime() - b[1].getTime())

          return [items.map((i) => i[0]), items.map((i) => i[1])]
        }
      }
    },
    [problemType, model?.dataset?.csv?.id, target, forecastingDate],
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (!disabled) {
      const cb = () => {
        setAdvancedOpen(false)
      }
      const body = document.querySelector('body')
      body.addEventListener('click', cb)
      return () => body.removeEventListener('click', cb)
    }
    // eslint-disable-next-line
  }, [disabled])

  let minDateForecastLimit = new Date()
  if (problemType === 'Forecast') {
    const lim = model?.dataset?.statistics?.[forecastingDate]?.min?.replace(
      ',',
      '',
    )
    const limDate = new Date(lim)
    if (isValidDate(limDate))
      minDateForecastLimit = new Date(limDate.getTime() + 1000 * 60 * 60 * 24)
  }

  if (disabled) return <></>
  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col className="position-relative" xs={12}>
        <Row
          className="cursor-pointer d-flex align-items-center border-theme pt-1 rounded-top w-100"
          onClick={() => setAdvancedOpen((a) => !a)}
        >
          <Col className="pe-0 mb-2 py-1" xs={'auto'}>
            <span>{t('Advanced (Optional)')} </span>
            <HelpTooltip
              className="help-select-icon"
              message={t('fine tunning')}
            />
          </Col>
          <Col className="pe-0" xs="auto">
            <FaChevronRight
              style={{
                transitionDuration: '0.25s',
                transform: `rotate(${advancedOpen ? '90' : '0'}deg)`,
              }}
              size={30}
            />
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="px-0" xs={12}>
            <SingleAccordion
              className="advance-options nb-accordion w-100"
              headerProps={{ className: 'd-none' }}
              forceStatus={advancedOpen}
              style={{ maxWidth: 'calc(100% - 25px)' }}
            >
              <Row className={`my-2 show-classification`}>
                {model?.dataset?.rows > 200_000 && (
                  <Col className="px-0" xs={12}>
                    <NBCheck
                      checked={parameters?.sample ?? false}
                      onChange={(e) => {
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            param: 'sample',
                            value: e.currentTarget.checked,
                          },
                        })
                      }}
                    >
                      {t('Use sampling')}{' '}
                      <HelpTooltip
                        className="help-select-icon"
                        message={
                          'Use a reduced sample of the dataset to speed up the training process'
                        }
                      />
                    </NBCheck>
                  </Col>
                )}
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={
                      (parameters?.balance_target ?? false) ||
                      balanceDataEnabled
                    }
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'balance_target',
                          value: e.currentTarget.checked,
                        },
                      })
                      switchBalanceData()
                    }}
                  >
                    {t('Balance target')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={
                        'Balance the target variable to have the same number of samples for each class'
                      }
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row
                className={`my-2 hide-forecast hide-mmm w-100  ${hideIf(
                  !shouldUseSynthetic,
                )}`}
              >
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={useSynthetic || syntheticEnabled}
                    onChange={(e) => {
                      updateSynthetic(e.currentTarget.checked)
                      switchSynthetic()
                    }}
                  >
                    {t('Power up with synthetic data')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={'generate synthetic'}
                      helpLink={'synthetic-data-1gie2rb'}
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row className={`my-2 hide-forecast`}>
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={removeOutliers || removeOutliersEnabled}
                    onChange={(e) => {
                      updateRemoveOutliers(e.currentTarget.checked)
                      switchRemoveOutliers()
                    }}
                  >
                    {t('Remove outliers')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={'remove outliers'}
                      helpLink={'synthetic-data-1gie2rb'}
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row className={`my-2 hide-forecast`}>
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={parameters?.ignore_id_columns ?? true}
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'ignore_id_columns',
                          value: e.currentTarget.checked,
                        },
                      })
                    }}
                  >
                    {t('Ignore ID columns')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={
                        'Replace values in categorical columns that are not in the top 32 most common values with the "Other" value.'
                      }
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row className={`my-2 hide-forecast`}>
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={parameters?.ignore_too_many_nulls ?? true}
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'ignore_too_many_nulls',
                          value: e.currentTarget.checked,
                        },
                      })
                    }}
                  >
                    {t('Ignore columns with too many null values')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={
                        'Columns that have more than 50% of null values will be ignored'
                      }
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row className={`my-2 hide-forecast`}>
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={
                      parameters?.ignore_high_correlated_with_target ?? true
                    }
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'ignore_high_correlated_with_target',
                          value: e.currentTarget.checked,
                        },
                      })
                    }}
                  >
                    {t('Ignore highly correlated columns with the target')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={
                        "Columns that have a very high correlation with the target could lead to overfitting, reducing the model's ability to generalize to new data"
                      }
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row className={`my-2 hide-forecast`}>
                <Col className="px-0" xs={12}>
                  <NBCheck
                    checked={
                      parameters?.replace_excess_categories_with_other ?? true
                    }
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'replace_excess_categories_with_other',
                          value: e.currentTarget.checked,
                        },
                      })
                    }}
                  >
                    {t('Replace excess categories with "Other" category')}{' '}
                    <HelpTooltip
                      className="help-select-icon"
                      message={
                        'Replace values in categorical columns that are not in the top 32 most common values with the "Other" value.'
                      }
                    />
                  </NBCheck>
                </Col>
              </Row>
              <Row
                className={`align-items-center mt-3 ${
                  step !== TUNNING_STEP ? 'inactive-' : ''
                }tunning-target-flow show-forecast`}
              >
                <Col className="px-0 mb-1 d-flex align-items-center" xs={12}>
                  {t('Periodicity')}{' '}
                  <HelpTooltip
                    className="help-select-icon ms-2"
                    message={t('Select a custom period to group your data')}
                  />
                  {periodicicty &&
                    periodicicty?.value !== 'Auto' &&
                    model?.dataset?.rows > 300 && (
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        placement="top"
                        delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {t(
                              'Your dataset has a high number of samples, selecting a custom periodicity may cause your model to train for a long time',
                            )}
                          </Tooltip>
                        )}
                      >
                        <div className="d-inline-block ms-2">
                          <MdOutlineWarning
                            size={20}
                            color="var(--nextbrain-warning-color)"
                          />
                        </div>
                      </OverlayTrigger>
                    )}
                </Col>
                <Col className=" px-0" xs={12}>
                  <NextbrainSelect
                    value={periodicicty || null}
                    onChange={(value) => {
                      setPeriodicity(value)
                    }}
                    options={periodicicty_options}
                    hideSelectedOptions={false}
                    isClearable={false}
                    placeholder={'Periodicity'}
                  />
                </Col>
              </Row>
              <Row
                className={`align-items-center mt-3 ${
                  step !== TUNNING_STEP ? 'inactive-' : ''
                }tunning-target-flow show-forecast`}
              >
                <Col className="px-0 mb-1 d-flex align-items-center" xs={12}>
                  {t('Forecast end')}{' '}
                  <HelpTooltip
                    className="help-select-icon ms-2"
                    message={t(
                      'Filter all points after this date for training the model',
                    )}
                  />
                </Col>
                <Col
                  className="px-0 position-relative d-flex align-items-center"
                  xs={12}
                >
                  <Form.Control
                    className="nb-input d-inline-block"
                    type="date"
                    min={minDateForecastLimit.toISOString()?.split('T')?.[0]}
                    value={
                      (forecast_limit || new Date())
                        ?.toISOString()
                        ?.split('T')?.[0]
                    }
                    style={{ maxWidth: 'calc(100% - 40px)' }}
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'forecast_limit',
                          value: e.target.valueAsDate,
                        },
                      })
                    }}
                  />
                  <div
                    className="d-inline-flex align-items-center justify-content-center active-icon"
                    style={{ maxWidth: '40px', minWidth: '40px' }}
                    onClick={() => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'forecast_limit',
                          value: null,
                        },
                      })
                    }}
                  >
                    <FaTrash size={25} />
                  </div>
                </Col>
                {Array.isArray(seriesForecast) && seriesForecast[0].length && (
                  <NBGraphDateSelector
                    time_axis={seriesForecast[1]}
                    data_axis={seriesForecast[0]}
                    value={forecast_limit}
                    onChange={(e) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'forecast_limit',
                          value: e,
                        },
                      })
                    }}
                  />
                )}
              </Row>
            </SingleAccordion>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
