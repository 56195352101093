import { default as d3_timeseries } from './d3_timeseries'
import { useEffect, useState, useRef } from 'react'
import $ from 'jquery'
import './TimeseriesForecast.css'

export default function TimeseriesForecast({
  forecast,
  model,
  height = 600,
  ...props
}) {
  const containerRef = useRef()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [csvData, setCsvData] = useState([])
  useEffect(() => {
    const updateWidth = () => setTimeout(() => setWindowWidth(Date.now()), 500)
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  useEffect(
    () => {
      if (!forecast || !forecast.length || !model) return
      const prediction = forecast.filter((f) => f.y_lower !== null)
      const first = prediction[0].ds
      const data = forecast.filter((f) => f.y_lower === null && f.ds < first)
      data.push({
        ds: first,
        [model.target]: prediction[0].y,
        y: prediction[0].y,
      })
      let minY = prediction?.[0]?.y,
        maxY = minY
      data.forEach((d) => {
        minY = Math.min(minY, d.y)
        maxY = Math.max(maxY, d.y)
      })
      prediction.forEach((d) => {
        minY = Math.min(minY, d.y_lower)
        maxY = Math.max(maxY, d.y_upper)
      })
      const diff = (maxY - minY) * 0.1

      $(containerRef.current).html('')
      var chart = d3_timeseries()
        .addSerie(
          [...data, ...prediction],
          { x: 'ds', y: model.target },
          { interpolate: 'monotone', color: '#00000000' },
        )
        .addSerie(
          prediction,
          {
            x: 'ds',
            y: `${model.target} prediction`,
            ci_down: 'y_lower',
            ci_up: 'y_upper',
          },
          { interpolate: 'monotone', color: '#df997f' },
        )
        .addSerie(
          prediction.map((p) => ({
            ...p,
            [`${model.target} upper error margin`]: p.y_upper,
          })),
          { x: 'ds', y: `${model.target} upper error margin` },
          { interpolate: 'monotone', color: '#750a03c8' },
        )
        .addSerie(
          prediction.map((p) => ({
            ...p,
            [`${model.target} lower error margin`]: p.y_lower,
          })),
          { x: 'ds', y: `${model.target} lower error margin` },
          { interpolate: 'monotone', color: '#e66a6088' },
        )
        .addSerie(
          data,
          {
            x: 'ds',
            y: model.target,
            ci_down: 'y_lower',
            ci_up: 'y_upper',
            min: minY - diff,
            max: maxY + diff,
          },
          {
            interpolate: 'monotone',
            color: 'var(--nextbrain-tables-blue-graph-bar-color)',
          },
        )
        .margin({ left: 50, right: 50, top: 0, bottom: 20 })
        .width($(containerRef.current).width())
        .height(height)
        .yscale.domain([minY - diff, maxY + diff])

      setCsvData([
        ['Date', 'Prediction', 'Upper Error Margin', 'Lower Error Margin'],
        ...prediction.map((p) => [
          new Date(p.ds).toDateString(),
          p.y,
          p.y_upper,
          p.y_lower,
        ]),
      ])

      chart(containerRef.current)
    },
    // eslint-disable-next-line
    [forecast, model, windowWidth],
  )

  return (
    <>
      <div
        className="d-none data-holder"
        data-csv={encodeURIComponent(JSON.stringify(csvData))}
        data-filename={`forecasting__${model?.dataset?.name}`}
      ></div>
      <div ref={containerRef} className="time-series-container"></div>
    </>
  )
}
