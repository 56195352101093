import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveWaffle } from '@nivo/waffle'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { colorPalette1 } from './common'
import { round } from '../../utils/formating'

export function ConfigAlgorithmsUsed({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.algorithm-title').val(),
    graphFontSize: Number.parseInt($('.algorithm-graph-font-size').val()) ?? 16,
    graphType: $('.algorithm-graph-type').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="algorithm-title"
            defaultValue={`${config.title ?? 'Algorithms used'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="algorithm-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="algorithm-graph-type raw"
            defaultValue={`${config.graphType ?? 'col'}`}
          >
            <option value="pie">Pie chart</option>
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="waf">Waffle</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function AUPie({ model, data, config }) {
  return (
    <ResponsivePie
      data={data.map((d, i) => ({
        id: d.id,
        label: d.id,
        value: d.value,
        color: colorPalette1[i++],
      }))}
      tooltip={({ datum }) => (
        <BasicTooltip
          id={datum.id}
          value={`${round(datum.value)}%`}
          color={datum.color}
          enableChip
        />
      )}
      valueFormat=">-.2f"
      arcLabel={({ value }) => `${round(value)}%`}
      margin={{ top: 10, right: 10, bottom: 20, left: 130 }}
      cornerRadius={5}
      activeOuterRadiusOffset={3}
      colors={(d) => d.data.color}
      borderWidth={1}
      animate={false}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      enableArcLinkLabels={false}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
        legends: {
          text: {
            fontSize: 11,
            fill: 'var(--nextbrain-widget-axis-legend)',
          },
        },
      }}
      legends={[
        {
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -130,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: 'circle',
          effects: [],
        },
      ]}
    />
  )
}

function AUCol({ model, data, config }) {
  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  }
  return (
    <ResponsiveBar
      data={data.map((d, i) => ({
        id: d.id,
        label: d.id,
        value: d.value,
        color: colorPalette1[i++],
      }))}
      tooltip={(props) => (
        <BasicTooltip
          id={props.id}
          value={`${round(props.value)}%`}
          color={props.color}
          enableChip
        />
      )}
      valueFormat=">-.2f"
      keys={['value']}
      indexBy="id"
      enableGridY={false}
      margin={{ top: 10, right: 20, bottom: 40, left: horizontal ? 120 : 0 }}
      groupMode="grouped"
      layout={horizontal ? 'horizontal' : 'vertical'}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      axisBottom={legend}
      label={({ value }) => `${round(value)}%`}
      labelSkipHeight={0}
      labelTextColor="#000"
      legends={[]}
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      labelSkipWidth={50}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
        legends: {
          text: {
            fontSize: 11,
            fill: 'var(--nextbrain-widget-axis-legend)',
          },
        },
      }}
    />
  )
}

function AUWaf({ model, data, config }) {
  return (
    <ResponsiveWaffle
      data={data.map((d, i) => ({
        id: d.id,
        label: d.id,
        value: d.value,
        color: colorPalette1[i++],
      }))}
      tooltip={(props) => (
        <BasicTooltip
          id={props.id}
          value={`${round(props.value)}%`}
          color={props.color}
          enableChip
        />
      )}
      valueFormat=">-.2f"
      total={100}
      rows={5}
      columns={5}
      margin={{ top: 30, right: 10, bottom: 10, left: 10 }}
      colors={(d) => d.color}
      animate={true}
      motionStiffness={90}
      motionDamping={11}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
        legends: {
          text: {
            fontSize: 11,
            fill: 'var(--nextbrain-widget-axis-legend)',
          },
        },
      }}
      label={(d) => `${round(d.value)}%`}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -30,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: 'var(--nextbrain-widget-axis-legend)',
          symbolSize: 20,
          effects: [],
        },
      ]}
    />
  )
}

function buildData(model) {
  const groups = model.mljar_description.reduce((d, { model, repeat }) => {
    const name = model.split('_').at(-1)
    d[name] = d[name] ?? 0
    d[name] += repeat
    return d
  }, {})
  const total = Object.keys(groups).reduce((acc, k) => acc + groups[k], 0)
  return Object.keys(groups).map((k) => ({
    id: k,
    value: (100 * groups[k]) / total,
  }))
}

export function WidgetAlgorithmsUsed({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const graph = () => {
    switch (config.graphType) {
      default:
      case 'pie':
        return <AUPie model={model} data={buildData(model)} config={config} />
      case 'col':
      case 'coh':
        return <AUCol model={model} data={buildData(model)} config={config} />
      case 'waf':
        return <AUWaf model={model} data={buildData(model)} config={config} />
    }
  }

  return (
    <Row className="w-100 h-100" id={id}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col xs={12} style={{ height: 'calc(100% - 50px)' }}>
        {model.mljar_description ? graph() : <>Invalid model</>}
      </Col>
    </Row>
  )
}
