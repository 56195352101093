import { ResponsiveLineCanvas } from '@nivo/line'
import { useMemo } from 'react'
import { getDateTypeColumns } from '../../util/models'
import { useTranslation } from 'react-i18next'

const formatBottomAxis = (value) => {
  // Customize the formatting based on your needs
  return value
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')
}

function ChangePoints({ ctx, series, points, innerHeight }) {
  const changePoints = series?.[0]?.anomalies?.change_points
  if (changePoints) {
    ctx.strokeStyle = '#FFEE96'
    ctx.setLineDash([5, 5])
    changePoints.forEach((i) => {
      const { x } = points[i] ?? {}
      if (!x) return
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.beginPath()
      ctx.moveTo(x, 0)
      ctx.lineTo(x, innerHeight)
      ctx.stroke()
    })
  }
}

function GraphLegend({ innerWidth, ctx }) {
  const x = innerWidth
  const y = 0

  ctx.fillStyle = '#2573DD'
  ctx.fillRect(x + 18, y + 4, 7, 10)
  ctx.fillStyle = '#ADBAC7'
  ctx.fillText('Predictibility', x + 28, y + 13)
}

const MARGINLEFT = 100
const MARGINRIGHT = 120

export default function AnomaliesAccuracy({ model }) {
  const { t } = useTranslation()
  const [graphData, csvData] = useMemo(() => {
    if (
      !model ||
      !Array.isArray(model?.anomaly?.lmd) ||
      !Array.isArray(model?.anomaly?.final_df?.columns)
    )
      return [[], []]

    const lmd = model.anomaly.lmd.map((v) => (v ? 1 / v : 0))
    const max = Math.max(...lmd)
    const data = model?.anomaly?.final_df?.data
    const columns = model?.anomaly?.final_df?.columns
    const dateIndex =
      getDateTypeColumns(model)
        .map((c) => columns.indexOf(c))
        .find((i) => i !== -1) ?? -1

    if (dateIndex === -1) return [[], []]

    const lineData = {
      id: model.dataset.name,
      anomalies: model?.anomaly,
      color: '#2573DD',
      data: lmd.map((lmd, i) => ({
        x: new Date(data[i][dateIndex] + 'Z'),
        y: (lmd / max ? 1 - lmd / max : 0) + 1,
      })),
    }

    return [
      [lineData],
      [['Date', 'Predictibility'], ...lineData.data.map(({ x, y }) => [x, y])],
    ]
  }, [model])

  return (
    <>
      <ResponsiveLineCanvas
        data={graphData}
        margin={{ top: 10, right: MARGINRIGHT, bottom: 50, left: MARGINLEFT }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d', // Specify the date format of your x values
          precision: 'day', // Adjust precision as needed
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: 'linear', min: 0.8, max: 'auto' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 3,
          tickPadding: 5,
          legendOffset: 60,
          legendPosition: 'middle',
          tickRotation: -45,
          format: formatBottomAxis,
        }}
        lineWidth={2}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('Predictibility'),
          legendOffset: -80,
          legendPosition: 'middle',
        }}
        enableGridX={false}
        colors={(d) => d.color}
        enableArea={false}
        enablePoints={false}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        gridXValues={[0, 20, 40, 60, 80, 100, 120]}
        gridYValues={[0, 500, 1000, 1500, 2000, 2500]}
        legends={[]}
        theme={{
          fontSize: '13px',
          textColor: '#ADBAC7',
        }}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          GraphLegend,
          ChangePoints,
        ]}
      />
      <div
        className="data-holder display-none"
        data-csv={encodeURIComponent(JSON.stringify(csvData))}
        data-filename={`anomaly_accuracy_${model.id}`}
      ></div>
    </>
  )
}
