import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { MdEmail } from 'react-icons/md'
import { FaKey } from 'react-icons/fa'

import './RegisterForm.css'
import { useTranslation } from 'react-i18next'
import { getCountry } from '../../util/other'
import { useAuth } from '../../providers/AuthProvider'
import { NotificationManager } from 'react-notifications'
import BouncyButton from '../bouncy-button/BouncyButton'
import { useNavigate } from 'react-router-dom'

export default function RegisterQuick({
  googleButton = () => {},
  microsoftButton = () => {},
}) {
  const { t } = useTranslation()
  const { signupquick } = useAuth()
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [country, setCountry] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const mailRef = useRef()
  const GB = googleButton
  const MB = microsoftButton
  const navigate = useNavigate()

  useEffect(() => {
    setValidEmail(mailRef.current?.value && mailRef.current?.validity?.valid)
    mailRef.current?.focus()
  }, [])

  useEffect(() => {
    if (!country)
      fetch('https://ipinfo.io/country?token=3f627ae68c5239').then(
        async (d) => {
          if (d.status !== 200) {
            console.error('Failed to get country from ipinfo', d)
            setCountry(getCountry())
            return
          }
          try {
            const cc = (await d.text()).replace('\n', '')
            let regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
            setCountry(regionNames.of(cc) ?? getCountry())
          } catch (e) {
            setCountry(getCountry())
          }
        },
      )
  }, [country])

  const signup = async () => {
    setLoading(true)
    signupquick({
      email: mailRef.current.value,
      password: password,
      country,
    })
      .then((d) => {
        if (d.error) {
          NotificationManager.error(t(d.message))
        } else {
          setSuccess(d)
        }
      })
      .catch((e) => {
        NotificationManager.error(t('Unexpected error during signup'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        success.login()
        setSuccess(null)
      }, 3000)
    }
  }, [success])

  const validPassword = password.length > 6 && password === confirmPassword

  return (
    <>
      <Row className="justify-content-center">
        <Col className="d-flex justify-content-center mb-2" xs={'auto'}>
          <GB text={'Sign up with Google'} navigateProfile={false} />
        </Col>
        <Col className="d-flex justify-content-center mb-2" xs={'auto'}>
          <MB text={'Sign up with Microsoft'} />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center">
        <Col
          className="side-decorator"
          style={{ maxWidth: 'calc(50% - 30px)' }}
          xs={12}
        ></Col>
        <Col className="py-4" style={{ maxWidth: '60px' }} xs={'auto'}>
          <strong className="small">{t('OR')}</strong>
        </Col>
        <Col
          className="side-decorator"
          style={{ maxWidth: 'calc(50% - 30px)' }}
          xs={12}
        ></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row className="labellogin">
            <Col xs={12}>
              {t('Email')}
              <span className="text-danger"> *</span>
            </Col>
          </Row>
        </Col>
        <Col className="mt-2 position-relative" xs={12}>
          <MdEmail
            className="form-input-icon-left-icon pb-1"
            style={{ marginTop: '-4px' }}
          />
          <Form.Control
            className="inputnextbrain nb-input-soft show-shadow w-100"
            type={'email'}
            ref={mailRef}
            autoComplete="off"
            placeholder="example@email.com"
            style={{
              border: '1px solid var(--nextbrain-secondary-border-color)',
            }}
            required
            onChange={() =>
              setValidEmail(
                mailRef.current?.value && mailRef.current?.validity?.valid,
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col className="mt-2" md={6} xs={12}>
              <Form.Label>
                <Row className="labellogin">
                  <span className="d-inline-block text-truncate">
                    {t('Password')} <span className="text-danger">*</span>
                  </span>
                </Row>
              </Form.Label>
              <Col className="posrelative">
                <FaKey className="iconinput" />
                <Form.Control
                  key="password"
                  id="password"
                  name="password"
                  className="inputnextbrain nb-input-soft show-shadow"
                  type="password"
                  defaultValue={''}
                  autoComplete="off"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={password !== confirmPassword}
                />
              </Col>
            </Col>
            <Col className="mt-2" md={6} xs={12}>
              <Form.Label>
                <Row className="labellogin">
                  <span className="d-inline-block text-truncate">
                    {t('Confirm Password')}{' '}
                    <span className="text-danger">*</span>
                  </span>
                </Row>
              </Form.Label>
              <Col className="posrelative">
                <FaKey className="iconinput" />
                <Form.Control
                  key="confirm_password"
                  id="confirm_password"
                  name="confirm_password"
                  className="inputnextbrain nb-input-soft show-shadow"
                  type="password"
                  defaultValue={''}
                  autoComplete="off"
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isInvalid={confirmPassword && !validPassword}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {t('Passwords must match and be at least 6 characters long')}
                </Form.Control.Feedback>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3 mb-1 align-items-center">
        <Col
          xs={'auto'}
          className="pe-0  d-flex align-items-center check-terms-register"
          style={{ maxWidth: '100px' }}
        >
          <Form.Check
            className="d-inline-block no-wrap"
            name="conditions-checkbox"
            checked={termsAndConditions}
            onChange={(e) => {
              setTermsAndConditions(e.currentTarget.checked)
            }}
          />
        </Col>
        <Col
          className="ps-0"
          style={{ maxWidth: 'calc(100% - 100px)' }}
          xs={12}
        >
          <label className=" w-100" htmlFor="conditions-checkbox">
            {t('By signing up, you are agreeing to these')}{' '}
            <a
              className="link"
              href="https://nextbrain.ai/terms-and-condition"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                window.open(
                  'https://nextbrain.ai/terms-and-condition',
                  '_blank',
                )
              }}
            >
              {t('Terms and Conditions')}
            </a>
            .
          </label>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 d-flex justify-content-center" xs={12}>
          <BouncyButton
            loading={loading}
            id="register-button-2"
            type="submit"
            className="px-5 py-3 w-100 "
            variant="success"
            onClick={signup}
            disabled={
              !validPassword ||
              success ||
              loading ||
              !validEmail ||
              !termsAndConditions
            }
          >
            <strong>{t('Sign up')}</strong>
          </BouncyButton>
        </Col>
      </Row>
      {success && (
        <Row className="mt-3">
          <Col className="text-center" xs={12}>
            {t(`An email has been sent to `) +
              mailRef.current?.value +
              t(` to activate your account`)}
          </Col>
        </Row>
      )}
      <Row className="align-items-center justify-content-center">
        <Col
          className="side-decorator"
          style={{ maxWidth: 'calc(50% - 90px)' }}
          xs={12}
        ></Col>
        <Col
          className="py-4 d-flex justify-content-center"
          style={{ maxWidth: '150px', minWidth: '180px' }}
          xs={'auto'}
        >
          <strong className="small">{t('ALREADY REGISTERED?')}</strong>
        </Col>
        <Col
          className="side-decorator"
          style={{ maxWidth: 'calc(50% - 90px)' }}
          xs={12}
        ></Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-5">
        <Col xs={12}>
          <Button
            className="w-100 py-3 original"
            variant={'secondary'}
            onClick={() => {
              navigate('/login')
            }}
          >
            {t('Sign in')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
