import {
  PublicClientApplication,
  EventType,
  LogLevel,
} from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: 'c15d4110-db1b-41e3-b371-d2629d24af59', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/common', // cb95327b-b7e1-4008-b850-35b9f7dc592f Defaults to "https://login.microsoftonline.com/common"
    //redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    redirecUri: '/',
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        // eslint-disable-next-line
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})
