import React, { forwardRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TourProvider } from '@reactour/tour'
import { FaDatabase, FaFile, FaUpload, FaCrown } from 'react-icons/fa'
import { MdHttp } from 'react-icons/md'
import { FcMultipleInputs } from 'react-icons/fc'
import { SiGooglesheets, SiMongodb, SiPowerbi } from 'react-icons/si'
import {
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import Connector from './Connector'
import DataslayerIcon from '../../assets/images/dataslayer.ico'
import DatasourceFlow from './datasource-flow/DatasourceFlow'

import './model-content.css'
import { useModels } from '../../providers/ModelProvider'
import SingleImport from './datasource-flow/SingleImport'
import { useAuth } from '../../providers/AuthProvider'
import { useSearchParams } from 'react-router-dom'
import ConfirmButton from '../confirm-button/ConfirmButton'
import { confirmModel, deleteModel } from '../../services/model'
import { BsArrowLeft } from 'react-icons/bs'
import ModelAssistant from './model-assistant/ModelAssistant'
import { NotificationManager } from 'react-notifications'
import BouncyButton from '../bouncy-button/BouncyButton'
import { BiExit } from 'react-icons/bi'

const DISABLE_OAUTH = process.env?.['REACT_APP_DISABLEOAUTH'] === '1'
const DISABLE_LIMITS = process.env?.['REACT_APP_DISABLELIMITS'] === '1'

export function ButtonAsTooltip({ connector, t, ...props }) {
  switch (connector) {
    case 'existingData':
      return (
        <Connector
          name={t('Stored data')}
          description={t('Data already stored in the platform')}
          icon=<FaFile style={{ color: '#4372EF', fontSize: 35 }} />
          {...props}
        />
      )
    case 'localFile':
      return (
        <Connector
          name={t('Upload local file')}
          description={t('Data stored in your computer')}
          icon=<FaUpload style={{ color: '#EC812F', fontSize: 35 }} />
          {...props}
        />
      )
    case 'google':
      return DISABLE_OAUTH ? (
        <></>
      ) : (
        <Connector
          name="Google Sheets"
          description={t('Connect to Google Sheets')}
          icon=<SiGooglesheets style={{ color: '#0F9D58', fontSize: 35 }} />
          {...props}
        />
      )
    case 'database':
      return (
        <Connector
          name={t('Database')}
          description={t('Connect to your SQL database')}
          icon=<FaDatabase style={{ color: '#00A1E0', fontSize: 35 }} />
          {...props}
        />
      )
    case 'dataslayer':
      return (
        <Connector
          name="Dataslayer AI"
          description={t('Import marketing data')}
          icon=<Image src={DataslayerIcon} width={40} />
          {...props}
        />
      )
    case 'api':
      return (
        <Connector
          name={t('General API')}
          description={t('Connect to any API')}
          icon=<MdHttp style={{ color: '#0F9D58', fontSize: 35 }} />
          {...props}
        />
      )
    case 'Mongo':
      return (
        <Connector
          name={t('Mongo')}
          description={t('Connect to a MongoDB instance')}
          icon=<SiMongodb style={{ color: '#0F9D58', fontSize: 35 }} />
          {...props}
        />
      )
    case 'powerbi':
      return (
        <Connector
          name={t('Power BI')}
          description={t('Connect to any your Power BI data')}
          icon=<SiPowerbi style={{ color: '#F2C811', fontSize: 35 }} />
          {...props}
        />
      )
    case 'flow':
      return (
        <Connector
          name={t('Datasource combination')}
          description={t('Build a custom dataset from multiple sources')}
          icon=<FcMultipleInputs style={{ color: '#0F9D58', fontSize: 35 }} />
          {...props}
        />
      )
    default:
      return <></>
  }
}

function UserPlan() {
  const { maxRowsPerModel } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (DISABLE_LIMITS || maxRowsPerModel === 'unlimited') return <></>

  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="auto"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip {...props}>
          {t("With your current plan your models' datasets are limited to") +
            ` ${maxRowsPerModel} ${t('rows')}`}
          <br /> <br />
          {t('Click to upgrade your plan to increase this limit')}
        </Tooltip>
      )}
    >
      <Row
        onClick={() => navigate('/plan')}
        className=" w-100 limit-rows-per-model"
        style={{ maxWidth: '320px' }}
      >
        <Col
          className="d-flex align-items-center"
          style={{ maxWidth: '70px' }}
          xs={12}
        >
          <FaCrown color="white" className="" size={45} />
        </Col>
      </Row>
    </OverlayTrigger>
  )
}

const Div = forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} {...props}>
    {children}
  </div>
))

export default function NewModelContent() {
  const { t } = useTranslation()
  const { token, signout, llmDisabled } = useAuth()
  const navigate = useNavigate()
  const {
    loading,
    numModels,
    workspaceId,
    workspacename,
    modelsById,
    addModel,
    setActiveModel,
    role,
  } = useModels()
  const [searchParams, setSearchParams] = useSearchParams()
  const [checkModalTitle, setCheckModalTitle] = useState('')
  const checkModel = llmDisabled ? null : searchParams.get('check-model')
  const onCheckModel =
    llmDisabled || modelsById?.[checkModel]
      ? false
      : (modelId) => {
          searchParams.set('check-model', modelId)
          setSearchParams(searchParams)
        }

  const connectorsPage = searchParams.get('connector')
  const setConnectorsPage = (connector) => {
    if (!connector) searchParams.delete('connector')
    else searchParams.set('connector', connector)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!connectorsPage && !checkModel)
      navigate(`/model/${workspaceId}?wp-name=${workspacename}`)
    // eslint-disable-next-line
  }, [connectorsPage, checkModel])

  const contentPixels = Math.max(159 + 138 * numModels + 6, 260)

  function renderModal(connectorsPage) {
    switch (connectorsPage) {
      case null:
        break
      case 'dataflow': {
        const steps = [
          {
            selector: '.datasource-template-Config',
            content: t(
              'Select a datasource by clicking on the configuration nodes',
            ),
          },
          {
            selector: '.output-datasource-trigger ',
            content: t('Select a node to create a model from with this button'),
          },
          {
            selector: '.transform-datasource-trigger',
            content: t(
              'You can use this button to add transformations or merge multiple sources of data',
            ),
          },
          {
            selector: '.config-forced-actions-context-menu',
            content: t(
              'You can select all available transformation from the context menu after clicking the button',
            ),
          },
          {
            selector: '.add-new-datasource-button.tour-part',
            content: t(
              'Add additional datasources with the right click menu or with this button',
            ),
          },
        ]
        return (
          <TourProvider steps={steps}>
            <DatasourceFlow
              styleFlow={{ minHeight: '100vh', minWidth: '100px' }}
              onCheckModel={onCheckModel}
              preName={
                <Row>
                  <Col>
                    <div className="mb-2 width-auto col-auto py-2">
                      <span
                        onClick={() =>
                          navigate(
                            `/model/${workspaceId}?wp-name=${workspacename}`,
                          )
                        }
                        className="breadcrumbs-nav-report p-2 looks-like-a-2"
                        style={{
                          fontWeight: 'bold',
                          border: '1px solid var(--nextbrain-main-color)',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                      >
                        <BsArrowLeft size={25} />
                      </span>
                    </div>
                    <div className="col-auto">
                      <Row
                        className="button-as-tooltip"
                        style={{ minWidth: '300px' }}
                      >
                        <ButtonAsTooltip connector={connectorsPage} t={t} />
                      </Row>
                    </div>
                  </Col>
                </Row>
              }
            />
          </TourProvider>
        )
      }
      default:
        return (
          <SingleImport
            type={connectorsPage}
            onError={() => setConnectorsPage(null)}
            onCheckModel={onCheckModel}
          />
        )
    }
  }

  if (role !== 'editor') {
    return <Navigate to={`/model/${workspaceId}`} />
  }

  return (
    <div style={{ minHeight: `${contentPixels}px` }}>
      {checkModel && (
        <Modal
          size={'xl'}
          backdrop="static"
          show={true}
          onHide={() => {
            searchParams.delete('check-model')
            setSearchParams(searchParams)
          }}
        >
          <Modal.Header className="h4 mb-0">
            <Row className="justify-content-between w-100 ">
              <Col
                xs={12}
                style={{ maxWidth: 'calc(100% - 100px)' }}
                className="d-inline-box text-truncate"
              >
                <Row>
                  <Col xs={12}>{checkModalTitle}</Col>
                </Row>
              </Col>
              <Col
                className="d-flex align-items-top justify-content-end px-0"
                style={{ minHeight: '40px', maxWidth: '100px' }}
                xs={'auto'}
              >
                <ConfirmButton
                  asOutside={Col}
                  className="original"
                  title={t('Cancel creation')}
                  onClick={() => {
                    searchParams.delete('check-model')
                    setSearchParams(searchParams)
                    deleteModel(checkModel, token, signout)
                  }}
                >
                  <button
                    type="button"
                    className="btn-close p-0"
                    style={{ minWidth: '40px', minHeight: '40px' }}
                    aria-label="Close"
                  />
                </ConfirmButton>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <ModelAssistant
              newModelId={checkModel}
              setCheckModalTitle={setCheckModalTitle}
              skipButton={
                <ConfirmButton
                  asOutside={Div}
                  className="original"
                  inProps={{ className: 'py-2 px-4' }}
                  confirmText={t('Skip Assistant')}
                  variant="danger"
                  title={t('Cancel creation')}
                  onClick={async () => {
                    const model = await confirmModel({
                      modelId: checkModel,
                      dataCleaningtasks: [],
                      token,
                      signout,
                    })
                    if (!model?.dataset) {
                      NotificationManager.error(t('Error creating model'))
                    } else {
                      addModel(model)
                      setActiveModel(model.id)
                    }
                  }}
                >
                  <div>
                    <BouncyButton
                      className="py-2 px-4 original"
                      variant="secondary"
                      onClick={() => {}}
                    >
                      <span className="mb-0 dflex-center">
                        {t('Skip Assistant')}
                        <BiExit size={20} />
                      </span>
                    </BouncyButton>
                  </div>
                </ConfirmButton>
              }
            />
          </Modal.Body>
        </Modal>
      )}
      <div className="model-content-children px-3">
        <Row className={`${loading ? 'd-none' : ''}`}>
          <Col xs={12}>
            <Row
              className={`justify-content-between my-2 ${
                connectorsPage === 'dataflow' ? 'd-none' : ''
              }`}
              style={{ minHeight: '50px', maxHeight: '50px' }}
            >
              <Col className="d-flex align-items-center" xs={'auto'}>
                <Link to={`/model/${workspaceId}?wp-name=${workspacename}`}>
                  <span
                    className="breadcrumbs-nav-report p-2 looks-like-a-2"
                    style={{
                      fontWeight: 'bold',
                      border: '1px solid var(--nextbrain-main-color)',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                  >
                    <BsArrowLeft size={25} />
                  </span>
                </Link>
              </Col>
              <Col xs={'auto'}>
                <h3>
                  <strong>{t('Select a datasource')}</strong>
                </h3>
              </Col>
              <Col className="d-flex justify-content-end me-2" xs={'auto'}>
                <UserPlan />
              </Col>
            </Row>
            {renderModal(connectorsPage)}
          </Col>
        </Row>
      </div>
    </div>
  )
}
