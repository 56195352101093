import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveWaffle } from '@nivo/waffle'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { colorPalette1 } from './common'
import { testAccuracy, trainAccuracy } from '../../../util/models'
import AccuracyGauge from '../../model-content/AccuracyGauge'

export function ConfigModelAccuracy({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.accuracy-title').val(),
    graphFontSize: Number.parseInt($('.accuracy-graph-font-size').val()) ?? 16,
    accuracy: $('.accuracy-mode').val(),
    graphType: $('.accuracy-graph-type').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="accuracy-title"
            defaultValue={`${config.title ?? 'Model Accuracy'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="accuracy-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 16}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Mode:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="accuracy-mode raw"
            defaultValue={`${config.accuracy ?? 'pie'}`}
          >
            <option value="ta">Training accuracy</option>
            <option value="tr">Accuracy</option>
            <option value="bo">Both</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="accuracy-graph-type raw"
            defaultValue={`${config.graphType ?? 'pie'}`}
          >
            <option value="pie">Gauge</option>
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="waf">Waffle</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function data(model, config) {
  const res = { training: [], test: [] }
  if (config.accuracy !== 'tr') {
    const acc = trainAccuracy(model)
    res.training = [
      {
        title: `Accuracy`,
        value: acc,
      },
    ]
  }
  if (config.accuracy !== 'ta') {
    const acc = testAccuracy(model)
    res.test = [
      {
        title: `Accuracy`,
        value: acc,
      },
    ]
  }
  return res
}

function DDPie({ model, config }) {
  const rawData = data(model, config)
  const isMMM = model.special_model_type === 'mmm'
  return (
    <Row
      className="global-accuracy-widget-graph-container"
      style={{ height: '100%' }}
    >
      <AccuracyGauge
        testAcc={
          isMMM ? rawData?.training[0]?.value : rawData?.test[0]?.value ?? 0
        }
        trainAcc={isMMM ? null : rawData?.training[0]?.value ?? 0}
        height="100"
        units="%"
        theme={{ fontSize: config.graphFontSize }}
      />
    </Row>
  )
}

function DDCol({ model, config }) {
  const horizontal = config.graphType === 'coh'
  const rawData = data(model, config)
  const sets = Object.keys(rawData).filter((d) => rawData[d].length).length
  let i = 0
  return (
    <Row style={{ height: '100%' }}>
      {rawData.training.length ? (
        <Col
          xs={sets === 2 && !horizontal ? 6 : 12}
          style={{ height: sets === 2 && horizontal ? '50%' : '100%' }}
        >
          <Row style={{ height: '100%' }}>
            <Col
              className="d-inline-block text-truncate text-center"
              xs={12}
              style={{ height: '30px' }}
            >
              Training accuracy
            </Col>
            <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
              <ResponsiveBar
                data={rawData.training
                  .map((d) => ({
                    id: 'Accuracy',
                    label: 'Accuracy',
                    value: d.value,
                    color: colorPalette1[i++],
                  }))
                  .slice(0, 1)}
                tooltip={(props) => (
                  <BasicTooltip
                    id={`${props.id}`}
                    value={`${props.value}% correct`}
                    color={props.color}
                    enableChip
                  />
                )}
                keys={['value']}
                indexBy="id"
                enableGridY={false}
                margin={{
                  top: 10,
                  right: 20,
                  bottom: 50,
                  left: 10,
                }}
                groupMode="grouped"
                layout={horizontal ? 'horizontal' : 'vertical'}
                valueScale={{ type: 'linear', min: 0, max: 100 }}
                indexScale={{ type: 'band', round: true }}
                colors={(d) => d.data.color}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                axisBottom={null}
                labelSkipHeight={0}
                legends={[]}
                role="application"
                ariaLabel="Bar chart"
                barAriaLabel={function (e) {
                  return (
                    e.id +
                    ': ' +
                    e.formattedValue +
                    ' in column: ' +
                    e.indexValue
                  )
                }}
                label={(d) => `${d.value}%`}
                labelSkipWidth={50}
                theme={{
                  fontSize: config.graphFontSize,
                  textColor: 'var(--nextbrain-widget-graph-legend)',
                  axis: {
                    ticks: {
                      text: {
                        fill: 'var(--nextbrain-widget-axis-legend)',
                      },
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col align="center">{sets.length === 0 ? 'No data' : ''}</Col>
      )}
      {rawData.test.length ? (
        <Col
          xs={sets === 2 && !horizontal ? 6 : 12}
          style={{ height: sets === 2 && horizontal ? '50%' : '100%' }}
        >
          <Row style={{ height: '100%' }}>
            <Col
              className="d-inline-block text-truncate text-center"
              xs={12}
              style={{ height: '30px' }}
            >
              Testing accuracy
            </Col>
            <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
              <ResponsiveBar
                data={rawData.test
                  .map((d) => ({
                    id: 'Accuracy',
                    label: 'Accuracy',
                    value: d.value,
                    color: colorPalette1[i++],
                  }))
                  .slice(0, 1)}
                tooltip={(props) => (
                  <BasicTooltip
                    id={`${props.id}`}
                    value={`${props.value}% correct`}
                    color={props.color}
                    enableChip
                  />
                )}
                keys={['value']}
                indexBy="id"
                enableGridY={false}
                margin={{
                  top: 10,
                  right: 20,
                  bottom: 50,
                  left: 10,
                }}
                groupMode="grouped"
                layout={horizontal ? 'horizontal' : 'vertical'}
                valueScale={{ type: 'linear', min: 0, max: 100 }}
                indexScale={{ type: 'band', round: true }}
                colors={(d) => d.data.color}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                axisBottom={null}
                labelSkipHeight={0}
                labelTextColor="#000"
                legends={[]}
                role="application"
                ariaLabel="Bar chart"
                barAriaLabel={function (e) {
                  return (
                    e.id +
                    ': ' +
                    e.formattedValue +
                    ' in column: ' +
                    e.indexValue
                  )
                }}
                label={(d) => `${d.value}%`}
                labelSkipWidth={50}
                theme={{
                  fontSize: config.graphFontSize,
                  textColor: 'var(--nextbrain-widget-graph-legend)',
                  axis: {
                    ticks: {
                      text: {
                        fill: 'var(--nextbrain-widget-axis-legend)',
                      },
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col align="center">{sets.length === 0 ? 'No data' : ''}</Col>
      )}
    </Row>
  )
}

function DDWaf({ model, config }) {
  const rawData = data(model, config)
  const sets = Object.keys(rawData).filter((d) => rawData[d].length).length
  let i = 0,
    j = 0
  return (
    <Row style={{ height: '100%' }}>
      {rawData.training.length ? (
        <Col
          className={`${rawData.training.length ? '' : 'd-none'}`}
          xs={sets === 2 ? 6 : 12}
          style={{ height: '100%' }}
        >
          <Row style={{ height: '100%' }}>
            <Col
              className="d-inline-block text-truncate"
              xs={12}
              style={{ height: '30px' }}
            >
              Training accuracy
            </Col>
            <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
              {rawData.training.slice(0, 1).map((d) => (
                <div key={d.title} style={{ height: `100%` }}>
                  <ResponsiveWaffle
                    data={[
                      {
                        id: 'Accuracy',
                        label: 'Accuracy',
                        value: d.value,
                        color: colorPalette1[i++],
                      },
                    ]}
                    tooltip={(props) => (
                      <BasicTooltip
                        id={`${props.id}`}
                        value={`Correct prediction`}
                        color={props.color}
                        enableChip
                      />
                    )}
                    total={100}
                    rows={5}
                    columns={5}
                    margin={{ top: 15, right: 10, bottom: 10, left: 10 }}
                    colors={(d) => d.color}
                    animate={false}
                    motionStiffness={90}
                    motionDamping={11}
                    theme={{
                      fontSize: config.graphFontSize,
                      textColor: 'var(--nextbrain-widget-graph-legend)',
                      axis: {
                        ticks: {
                          text: {
                            fill: 'var(--nextbrain-widget-axis-legend)',
                          },
                        },
                      },
                    }}
                    legends={[]}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      ) : (
        <Col align="center">{sets.length === 0 ? 'No data' : ''}</Col>
      )}
      {rawData.test.length ? (
        <Col
          className={`${rawData.test.length ? '' : 'd-none'}`}
          xs={sets === 2 ? 6 : 12}
          style={{ height: '100%' }}
        >
          <Row style={{ height: '100%' }}>
            <Col
              className="d-inline-block text-truncate"
              xs={12}
              style={{ height: '30px' }}
            >
              Testing accuracy
            </Col>
            <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
              {rawData.test.slice(0, 1).map((d) => (
                <div key={d.title} style={{ height: `100%` }}>
                  <ResponsiveWaffle
                    data={[
                      {
                        id: 'Accuracy',
                        label: 'Accuracy',
                        value: d.value,
                        color: colorPalette1[j++],
                      },
                    ]}
                    tooltip={(props) => (
                      <BasicTooltip
                        id={`${props.id}`}
                        value={`Correct prediction`}
                        color={props.color}
                        enableChip
                      />
                    )}
                    total={100}
                    rows={5}
                    columns={5}
                    margin={{ top: 15, right: 10, bottom: 10, left: 10 }}
                    colors={(d) => d.color}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={11}
                    theme={{
                      fontSize: config.graphFontSize,
                    }}
                    legends={[]}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      ) : (
        <Col align="center">{sets.length === 0 ? 'No data' : ''}</Col>
      )}
    </Row>
  )
}

export function WidgetModelAccuracy({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const graph = () => {
    switch (config.graphType) {
      default:
      case 'pie':
        return <DDPie model={model} config={config} />
      case 'col':
      case 'coh':
        return <DDCol model={model} config={config} />
      case 'waf':
        return <DDWaf model={model} config={config} />
    }
  }

  return (
    <Row className="w-100 h-100" id={id}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col xs={12} style={{ height: 'calc(100% - 50px)' }}>
        {graph()}
      </Col>
    </Row>
  )
}
