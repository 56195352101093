import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveWaffle } from '@nivo/waffle'
import $ from 'jquery'
import { colorPalette2 } from './common'
import { round } from '../../utils/formating'

export function ConfigColumnDataDistribution({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col>
          <Form.Control
            className="data-col-title"
            defaultValue={`${config.title ?? 'Column data distribution'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="data-col-graph-type raw"
            defaultValue={`${config.graphType ?? 'col'}`}
          >
            <option value="pie">Pie chart</option>
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="waf">Waffle</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="data-col-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Column:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="data-col-column raw"
            defaultValue={`${config.column ?? ''}`}
          >
            {model.dataset.columns_order.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button
            onClick={() =>
              onFinish({
                layout: { h: 9, w: 3, x: 0, y: 0 },
                ...config,
                graphType: $('.data-col-graph-type').val(),
                column: $('.data-col-column').val(),
                title: $('.data-col-title').val(),
                graphFontSize:
                  Number.parseInt($('.data-col-graph-font-size').val()) ?? 12,
                requests: ['sample'],
              })
            }
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function CDDPie({ model, data, config }) {
  return (
    <ResponsivePie
      data={Object.keys(data).map((k, i) => ({
        id: `${k}`,
        label: `${config.column} ${k}`,
        value: data[k],
        color: colorPalette2[i % colorPalette2.length],
      }))}
      margin={{ top: 10, right: 0, bottom: 20, left: 100 }}
      cornerRadius={5}
      activeOuterRadiusOffset={3}
      colors={(d) => d.data.color}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 6]],
      }}
      theme={{
        fontSize: config.graphFontSize,
      }}
      legends={[
        {
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -100,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#000000',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  )
}

function CDDCol({ model, data, config }) {
  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  }

  return (
    <ResponsiveBar
      data={Object.keys(data).map((k, i) => ({
        id: `${k}`,
        label: `${config.column} ${k}`,
        value: data[k],
        color: colorPalette2[i % colorPalette2.length],
      }))}
      keys={['value']}
      indexBy="id"
      enableGridY={false}
      margin={{ top: 10, right: 10, bottom: 50, left: horizontal ? 120 : 10 }}
      groupMode="grouped"
      layout={horizontal ? 'horizontal' : 'vertical'}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      axisBottom={legend}
      labelSkipHeight={0}
      labelTextColor="#000"
      legends={[]}
      role="application"
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      label={(d) => `${d.value}`}
      labelSkipWidth={50}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-axis-legend)',
      }}
    />
  )
}

function CDDWaf({ model, data, config }) {
  const count = Object.entries(data).reduce((acc, e) => acc + e[1], 0)
  const side = Math.ceil(Math.sqrt(count))
  const rows = Math.ceil(side / 1.5)
  const cols = Math.ceil(side * 1.5)

  return (
    <ResponsiveWaffle
      data={Object.keys(data).map((k, i) => ({
        id: `${k}`,
        label: `${config.column} ${k}`,
        value: data[k],
        color: colorPalette2[i % colorPalette2.length],
      }))}
      total={count}
      rows={rows}
      columns={cols}
      margin={{ top: 10, right: 20, bottom: 30, left: 100 }}
      colors={(d) => d.color}
      animate={true}
      motionStiffness={90}
      motionDamping={11}
      theme={{
        fontSize: config.graphFontSize,
      }}
      legends={[
        {
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -100,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: '#000',
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                itemBackground: '#f7fafb',
              },
            },
          ],
        },
      ]}
    />
  )
}

function coalesceNumbers(data, steps = 5) {
  const notNull = data.filter((d) => d !== null).sort((a, b) => a - b)
  const step = (notNull[notNull.length - 1] - notNull[0]) / steps
  const start = notNull[0]
  let base = start + step
  const res = {}
  const mkkey = () => `${round(base)} - ${round(base + step)}`
  res[mkkey()] = 0
  notNull.forEach((d) => {
    if (d > base) {
      while (d > base) base += step
      res[mkkey()] = 1
    } else res[mkkey()]++
  })

  return {
    ranges: { ...res, 'no data': data.length - notNull.length },
    base: notNull[0],
    step: step,
  }
}

export function WidgetColumnDataDistribution({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (
    !model ||
    !config ||
    !requestedData.sample ||
    requestedData.sample === 'loading' ||
    requestedData.sample === 'error'
  )
    return <>Loading...</>

  const getData = () => {
    const data = requestedData.sample.head[config.column]
    const sample = data[0] ?? 0

    //float
    if (data.length > 10 && Number(sample) === sample && sample % 1 !== 0) {
      const ranges = coalesceNumbers(data)
      return ranges.ranges
    } else {
      return data.reduce((dic, data) => {
        dic[data] = dic[data] ?? 0
        dic[data]++
        return dic
      }, {})
    }
  }

  const graph = () => {
    switch (config.graphType) {
      case 'pie':
        return <CDDPie model={model} data={getData()} config={config} />
      case 'col':
      case 'coh':
        return <CDDCol model={model} data={getData()} config={config} />
      case 'waf':
        return <CDDWaf model={model} data={getData()} config={config} />
      default:
        return <></>
    }
  }

  return (
    <Row className="w-100 h-100" id={id} {...props}>
      <Col className="d-inline-block text-truncate widget-title" xs={12}>
        {config.title}
      </Col>
      <Col className="d-inline-block text-truncate" xs={12}>
        From a sample with {requestedData.sample.head[config.column].length}{' '}
        entries
      </Col>
      <Col
        xs={12}
        className={`${config.graphType === 'tex' ? 'd-none' : ''}`}
        style={{ height: 'calc(100% - 60px)' }}
      >
        {graph()}
      </Col>
    </Row>
  )
}
