import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import { useProps } from '../../../providers/PropsProvider'
import { InlineEdit } from '../../inline-edit/InlineEdit'
import { actionConfig } from './config'
import InvalidWarning from './InvalidWarning'

function SelectDatasource({ type, node, position, children }) {
  const { t } = useTranslation()
  const { createNewNode, setNodes } = useProps()

  if (node && type === 'Config') {
    if (node && !node.id) node.id = `datasourceflow-context-menu-${v4()}`

    return (
      <div className="dataflow-config-wrapper cursor-pointer position-relative">
        <ContextMenuTrigger
          id={node.id}
          mouseButton={0}
          disableIfShiftIsPressed={true}
        >
          {children}
        </ContextMenuTrigger>
        <ContextMenu
          id={node.id}
          className="menu-context-dataflow config-forced-context-menu"
        >
          {Object.keys(actionConfig)
            .filter((k) => k !== 'Config')
            .map((key) => (
              <MenuItem key={key}>
                <div
                  className="d-inline-block text-nowrap py-2 h5 mx-3"
                  onClick={() => {
                    setNodes((p) => p.filter((n) => n?.data?.id !== node.id))
                    createNewNode({
                      type: key,
                      position: position,
                      replacedNode: node,
                      forceActive: true,
                    })
                  }}
                >
                  {actionConfig[key].iconSmall}
                  {t(key)}
                </div>
              </MenuItem>
            ))}
        </ContextMenu>
      </div>
    )
  }

  return children
}

export function ActionTemplate({
  id,
  position,
  type,
  children,
  node = {},
  ...props
}) {
  const source = actionConfig[type]
  const customSubtitle = node?.userText ?? node?.subtitle
  const { t } = useTranslation()

  return (
    <ContextMenuTrigger
      id={`context-menu-node-${id}`}
      disableIfShiftIsPressed={true}
    >
      <ContextMenu
        id={`context-menu-node-${id}`}
        className="menu-context-dataflow config-forced-context-menu-node"
      >
        <MenuItem>
          <div
            className="d-flex flex-nowrap text-nowrap"
            onClick={() => node?.controls?.onDelete()}
          >
            {t('Delete node')}
          </div>
        </MenuItem>
      </ContextMenu>

      <Row
        {...props}
        className={`datasource-template  justify-content-center align-items-center  d-flex position-relative datasource-template-${type} ${
          props.className ?? ''
        }`}
        onClick={() => {
          node.controls.onUpdate({ ...node, active: true })
        }}
      >
        <SelectDatasource type={type} node={node} position={position}>
          {source.icon}
          {children}
        </SelectDatasource>
        <InvalidWarning node={node} />
        <div className="datasource-template-context">
          <Row>
            <Col
              align="center"
              xs={12}
              className="position-relative d-flex flex-nowrap justify-content-center text-center text-nowrap"
              style={{
                maxWidth: '400px',
                minWidth: '50px',
                cursor: customSubtitle ? 'text' : 'grab',
              }}
            >
              {customSubtitle ? (
                <InlineEdit
                  onEdit={(value) => {
                    node.userText = value
                  }}
                  text={customSubtitle}
                  key={customSubtitle}
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: '100%' }}
                />
              ) : (
                t(source.subtext)
              )}
            </Col>
          </Row>
        </div>
        {children}
      </Row>
    </ContextMenuTrigger>
  )
}

export default function DatasourceActions({ create_node_fn, ...props }) {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
    const rect = event.target.getBoundingClientRect()
    event.dataTransfer.setData('dragXOffset', event.clientX - rect.left)
    event.dataTransfer.setData('dragYOffset', event.clientY - rect.top)
  }
  return (
    <Row {...props}>
      {Object.keys(actionConfig).map((k) => (
        <Col
          draggable
          key={k}
          className="mt-2"
          xs={12}
          onDragStart={(event) => onDragStart(event, k)}
          onClick={() => create_node_fn(k, {})}
        >
          <ActionTemplate type={k} />
        </Col>
      ))}
    </Row>
  )
}
