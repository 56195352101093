import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsiveBar } from '@nivo/bar'
import $ from 'jquery'

export function ConfigWeeklyTrend({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.weekly-trend-title').val(),
    graphFontSize:
      Number.parseInt($('.weekly-trend-graph-font-size').val()) ?? 12,
    requests: [`weeklyTrendPoints`],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="weekly-trend-title"
            defaultValue={`${config.title ?? 'Weekly Trend Data'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="weekly-trend-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}
export function WidgetWeeklyTrend({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  let data = []

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  if (requestedData?.weeklyTrendPoints?.data) {
    // Calculate the average value for each day of the week and add a data point to the data array
    const groups = requestedData.weeklyTrendPoints.data.reduce(
      (acc, [day, value]) => {
        if (!acc[day]) {
          acc[day] = { sum: 0, count: 0 }
        }
        acc[day].sum += value
        acc[day].count++
        return acc
      },
      {},
    )
    data = Object.entries(groups).map(([day, { sum, count }]) => ({
      day: daysOfWeek[parseInt(day)],
      value: sum / count,
    }))
  }
  if (!model || !config) return <>Loading...</>
  return (
    <>
      <Col xs={12} style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col
            className="d-inline-block text-truncate mx-2"
            xs={12}
            style={{ height: '30px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {config.title}
          </Col>
          <Col xs={12} style={{ height: 'calc(100% - 30px)' }}>
            {data.length !== 0 && (
              <ResponsiveBar
                data={data}
                keys={['value']}
                indexBy="day"
                valueFormat=".2f"
                valueScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                }}
                size={{
                  key: 'volume',
                  values: [4, 20],
                  sizes: [4, 16],
                }}
                spacing={2}
                margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  lineWidth: 0.5, // reduce thickness
                  strokeOpacity: 0.25, // reduce opacity
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  lineWidth: 0.5, // reduce thickness
                  strokeOpacity: 0.25, // reduce opacity
                }}
                colors={'#3ec73e'}
                borderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                lineOpacity={0.05}
                label={(d) => ''}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fill: '#F0EFFF88',
                      },
                    },
                  },
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}
