import { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { ResponsiveLineCanvas } from '@nivo/line'

const formatBottomAxis = (value) => {
  // Customize the formatting based on your needs
  return value
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')
}

export default function NBGraphDateSelector({
  key,
  time_axis,
  data_axis,
  value,
  onChange = () => {},
  graphHeight = 200,
  coverColor = '0, 18, 36',
}) {
  const valid =
    Array.isArray(time_axis) &&
    Array.isArray(data_axis) &&
    time_axis.length === data_axis.length &&
    time_axis.length < 20000

  const [graphData, min] = useMemo(() => {
    if (valid) {
      const min = Math.min(...data_axis)

      const res = [
        {
          id: 'data',
          data: data_axis.map((d, i) => {
            return {
              x: time_axis[i],
              y: d,
            }
          }),
        },
      ]

      return [res, min - 100]
    }
    return []
    // eslint-disable-next-line
  }, [valid])

  if (!graphData) return null

  return (
    <Row>
      <Col
        className="px-0"
        xs={12}
        style={{ minHeight: `${graphHeight}px`, maxHeight: `${graphHeight}px` }}
      >
        <ResponsiveLineCanvas
          data={graphData}
          margin={{ top: 10, right: 10, bottom: 60, left: 50 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d', // Specify the date format of your x values
            precision: 'day', // Adjust precision as needed
          }}
          xFormat="time:%Y-%m-%d"
          yFormat={' >-.2f'}
          yScale={{ type: 'linear', min: min, max: 'auto' }}
          axisTop={null}
          colors={(d) => (d.id === 'data' ? '#df997f' : '#00000000')}
          axisRight={null}
          areaOpacity={0}
          axisBottom={{
            orient: 'bottom',
            tickSize: 3,
            tickPadding: 5,
            legendOffset: 60,
            legendPosition: 'middle',
            tickRotation: -45,
            format: formatBottomAxis,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'values',
            legendOffset: -80,
            legendPosition: 'middle',
          }}
          curve="linear"
          enableSlices="x"
          tooltip={() => null}
          enableGridX={false}
          enableGridY={false}
          enableArea={true}
          enablePoints={false}
          lineWidth={0}
          pointSize={2}
          theme={{
            fontSize: '13px',
            textColor: '#ADBAC7',
          }}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'points',
            ({ ctx, innerWidth, innerHeight, xScale }) => {
              if (value && !isNaN(value)) {
                const x = xScale(value)
                ctx.strokeStyle = 'white'
                ctx.beginPath()
                ctx.moveTo(x, 0)
                ctx.lineTo(x, innerHeight)
                ctx.stroke()
                ctx.closePath()
                ctx.fillStyle = `rgba(${coverColor}, 0.7)`
                ctx.fillRect(x, -10, innerWidth - x + 20, innerHeight + 10)
              }
            },
            'slices',
            'mesh',
            'legends',
          ]}
          onClick={(data) => {
            console.log(data?.data?.x)
            if (data?.data?.x) onChange(data.data.x)
          }}
          style={{ cursor: 'pointer' }}
        />
      </Col>
    </Row>
  )
}
