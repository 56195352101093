import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import AutogenBot from './AutogenBot'
import { useNav } from '../../providers/NavProvider'
import { useQuery } from 'react-query'
import { getModelById } from '../../services/model'
import Loading from '../loading/LoadingSmall'
import { useAuth } from '../../providers/AuthProvider'
import { getDocuments, getTags } from '../../services/document'

export default function ShareAutogenChat() {
  const { token, signout } = useAuth()
  const { sessionId } = useParams()
  const { setShowNav } = useNav()
  const isRepo = sessionId.startsWith('repository_')
  const modelId = isRepo ? sessionId : sessionId?.slice(0, 8)

  const { data: model, isLoading } = useQuery(['share-model', modelId], () =>
    isRepo
      ? {
          id: 'repository_' + sessionId.split('_')[1],
        }
      : getModelById(modelId, null, () => {}),
  )
  const { data: _tags } = useQuery(
    ['repository-tags', sessionId, isRepo],
    async () => {
      if (!isRepo) return []
      const tags = await getTags({
        repositoryId: sessionId.slice(11, 19),
        token,
        signout,
      })
      if (!Array.isArray(tags)) return []
      tags.sort((a, b) => a.localeCompare(b))
      return tags
    },
    { staleTime: Infinity },
  )

  const { data: documents } = useQuery(
    ['documents', sessionId, isRepo],
    async () => {
      if (!isRepo) return []
      return await getDocuments({
        repositoryId: sessionId.slice(11, 19),
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const tags = []

  if (Array.isArray(documents)) {
    tags.push({
      name: 'All documents',
      type: 'document',
    })
    tags.push(
      ...documents.map((d) => ({ name: d.name, type: 'document', id: d.id })),
    )

    if (Array.isArray(_tags))
      tags.push(..._tags.map((t) => ({ name: t, type: 'tag' })))
  }

  useEffect(() => {
    setShowNav(false)
    setTimeout(() => window?.$crisp?.push(['do', 'chat:hide']), 200)
    return () => {
      setShowNav(true)
      window?.$crisp?.push(['do', 'chat:show'])
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row className="mx-1">
      <Col xs={12}>
        {isLoading || !model ? (
          <Loading />
        ) : model?.status === 'error' ? (
          <div>
            <h1>Model is invalid</h1>
          </div>
        ) : (
          <AutogenBot
            chatWindow={'100vh'}
            shareSession={sessionId}
            workflow={isRepo ? 'rag' : 'default'}
            model={model}
            style={{
              minWidth: '100vw',
              maxWidth: '100vw',
            }}
            tags={tags}
          />
        )}
      </Col>
    </Row>
  )
}
