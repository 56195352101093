import React, { useState } from 'react'
import { Container, Row, Col, Image, Modal } from 'react-bootstrap'
import Connector from './Connector'
import { useTranslation } from 'react-i18next'
import { SiZapier } from 'react-icons/si'
import { MdHttp, MdQuestionAnswer } from 'react-icons/md'
import { FcRadarPlot, FcQuestions, FcComboChart } from 'react-icons/fc'
import PredictForDevelopers from '../predict-form/PredictForDevelopers'
import ZapierPrediction from '../predict-form/ZapierPrediction'

const DISABLED_ZAPIER = process.env?.['REACT_APP_DISABLEZAPPIER'] === '1'

export default function Deploy({ model, ...props }) {
  const { t } = useTranslation()
  const [showPredictModal, setShowPredictModal] = useState(0)

  return (
    <>
      <Container>
        <Row className="my-2">
          <Col xs={12}>
            <h5 className="mt-5 text-white">{t('Default Apps')}</h5>
          </Col>
        </Row>
        <Row>
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Web predict')}
            description={t('Predict using a web interface')}
            icon=<Image src={'/LogoBlue.png'} width={65} />
            onClick={() => window.open(`/predict/${model.id}`, '_blank')}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Visual Web predict')}
            description={t('Visualize your prediction in a web interface')}
            icon=<FcRadarPlot style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() =>
              window.open(`/predict-dynamic/${model.id}`, '_blank')
            }
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Counterfactual analysis')}
            description={t('What do I need to change to get the result I want')}
            icon=<FcQuestions style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => window.open(`/what-if-tool/${model.id}`, '_blank')}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Causal inference')}
            description={t('Measure the impact of a change')}
            disabled={
              (model?.dataset?.cols ?? 0) <= 2 ||
              (model?.dataset?.cols ?? 0) >= 200
            }
            disabledMessage={t(
              'The dataset must have between 3 and 200 columns',
            )}
            icon=<MdQuestionAnswer style={{ color: '#459DB9', fontSize: 35 }} />
            onClick={() =>
              window.open(`/causal-inference/${model.id}`, '_blank')
            }
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Multivariable forecast')}
            description={t('Forecast based on multiple columns')}
            icon=<FcComboChart style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => window.open(`/multiforecast/${model.id}`, '_blank')}
            disabled={
              !Object.entries(model?.dataset?.final_column_status ?? {}).some(
                ([, v], _, a) => v === 'Datetime' && a?.length > 2,
              )
            }
            disabledMessage={t(
              'This model does not have a datetime column and at least 2 other columns',
            )}
          />
          {/*<Connector
              xs={12}
              md={6}
              xl={4}
              name={t('Spreadsheet')}
              description={t('Predict data in bulk using an embeed spreadsheet')}
              icon=<SiGooglesheets style={{ color: '#0F9D58', fontSize: 35 }} />
              onClick={() =>
                window.open(`/predict-spreadsheet/${model.id}`, '_blank')
              }
            />*/}
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('API predict')}
            description={t('Deploy your model in your code')}
            icon=<MdHttp style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => setShowPredictModal(1)}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          {!DISABLED_ZAPIER ||
            (false && (
              <Connector
                xs={12}
                md={6}
                xl={4}
                name={t('Connect with zapier')}
                description={t('Create a connection with zapier')}
                icon=<SiZapier style={{ color: '#FF4A00', fontSize: 35 }} />
                onClick={() => setShowPredictModal(2)}
                disabled={model?.status !== 'trained'}
                disabledMessage={t('Train your model first')}
              />
            ))}
        </Row>
        <Row className="my-2">
          <Col xs={12}>
            <h5 className="mt-5 text-white">{t('Custom Apps')}</h5>
          </Col>
        </Row>
        <Row className="my-2 ms-2">
          <Col xs={12}>
            <em>Coming soon ...</em>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showPredictModal}
        onHide={() => setShowPredictModal(0)}
        size={'xl'}
      >
        <Modal.Header closeButton>
          <h3>
            {showPredictModal === 1 ? t('API predict') : t('Zapier predict')}
          </h3>
        </Modal.Header>
        <Modal.Body>
          {showPredictModal === 1 && <PredictForDevelopers model={model} />}
          {showPredictModal === 2 && <ZapierPrediction model={model} />}
        </Modal.Body>
      </Modal>
    </>
  )
}
