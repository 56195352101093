import React, { useState } from 'react'
import { Row, Col, Button, Image, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaUser, FaGlobeAmericas, FaPeopleArrows } from 'react-icons/fa'
import { BsFillGearFill } from 'react-icons/bs'
import { CountryDropdown } from 'react-country-region-selector'
import { getCountry } from '../../util/other'
import './RegisterForm.css'
import { IndustryInfoBody } from './RegisterIndustry'
import { useAuth } from '../../providers/AuthProvider'
import { RiPsychotherapyFill } from 'react-icons/ri'
import HelpTooltip from '../model-content/HelpTooltip'
import BouncyButton from '../bouncy-button/BouncyButton'

function StageBall({ active, children, ...props }) {
  return (
    <Col
      {...props}
      className={`rounded-circle stage-ball ${
        active ? 'stage-ball-active' : ''
      }`}
      xs={12}
    >
      <strong> {children}</strong>
    </Col>
  )
}

function Stage({ stage = 1, updateStage }) {
  return (
    <Row>
      <Col style={{ maxWidth: '30px' }}>
        <Row className="flex-column justify-content-between h-100">
          <StageBall onClick={() => updateStage(1)} active={stage === 1}>
            1
          </StageBall>
          <StageBall onClick={() => updateStage(2)} active={stage === 2}>
            2
          </StageBall>
        </Row>
      </Col>
      <Col style={{ maxWidth: '90px' }}>
        <Image
          style={{ height: '90px' }}
          src={`/iconsRegister/Brain${stage === 1 ? 1 : 3}.svg`}
        />
      </Col>
    </Row>
  )
}

export default function RegisterPostSetup({ show }) {
  const { t } = useTranslation()
  const { user, updateuser } = useAuth()
  const [userName, setUsername] = useState(user.configured_name ?? '')
  const [country, setCountry] = useState(user.country ?? getCountry())
  const [introduction, setIntroduction] = useState(user.introduction ?? '')
  const [previousPlatforms, setpreviousPlatforms] = useState(
    user.previous_platforms ?? '',
  )
  const [industryData, setIndustryData] = useState({
    industry: { retail: true },
  })

  const [stage, setStage] = useState(1)

  const canAdvance =
    stage === 2 || (stage === 1 && userName && country && introduction)

  const advanceStage = async () => {
    if (!canAdvance) return

    if (stage < 2) {
      setStage(stage + 1)
    } else {
      await updateuser({
        name: userName,
        country: country || null,
        introduction: introduction || null,
        previous_platforms: previousPlatforms || null,
        industries: Object.keys(industryData.industry).filter(
          (k) => industryData.industry[k] && k !== 'undefined',
        ),
      })
    }
  }

  return (
    show && (
      <Modal size={'xl'} show={true}>
        <Modal.Header>
          <Row className="w-100 justify-content-between align-items-center">
            <Col
              className="h3 mb-4"
              style={{ maxWidth: 'calc(100% - 120px)' }}
              xs={'auto'}
            >
              <BsFillGearFill /> {t(`Lets finish setting you up`)}
            </Col>
            <Col style={{ maxWidth: '120px' }} xs={'auto'}>
              <Stage
                stage={stage}
                updateStage={(s) => (canAdvance ? setStage(s) : null)}
              />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row
            className={`register-post-setup ${
              stage !== 1 ? 'register-post-setup-hiden' : ''
            }`}
          >
            <Form.Label>
              {t('Name')} <span className="text-danger">*</span>
            </Form.Label>
            <Col className="position-relative">
              <FaUser className="iconinput-2" />
              <Form.Control
                key="admin_name"
                placeholder={t('How should we refer to you?')}
                className="inputnextbrain nb-input show-shadow"
                autoComplete="off"
                required
                value={userName}
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
                isInvalid={!userName}
                autoFocus
              />
            </Col>

            <Col xs={12}>
              <Row>
                <Col className="my-3" xs={6}>
                  <Row style={{ minWidth: '100%' }}>
                    <Col xs={12}>
                      <Form.Label>
                        {t('Where are you from?')}
                        <span className="text-danger"> *</span>
                      </Form.Label>
                    </Col>
                    <Col className="position-relative" xs={12}>
                      <FaGlobeAmericas className="iconinput-2" />
                      <CountryDropdown
                        id="country"
                        name="country"
                        className="raw nb-input inputnextbrain gx-0 select-bg-dark"
                        value={country}
                        onChange={(val) => setCountry(val)}
                        key="country"
                        style={{ maxWidth: '100%', minWidth: '100%' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="my-3" xs={6}>
                  <Row style={{ minWidth: '100%' }}>
                    <Col xs={12}>
                      <Form.Label>
                        {t('How did you hear about us?')}
                        <span className="text-danger"> *</span>
                      </Form.Label>
                    </Col>
                    <Col xs={12} className="posrelative">
                      <FaPeopleArrows className="iconinput-2" />
                      <Form.Select
                        style={{
                          maxWidth: '100%',
                          minWidth: '100%',
                          paddingTop: '12px',
                          paddingBottom: '12px',
                        }}
                        key="introduction"
                        id="introduction"
                        name="introduction"
                        isInvalid={!introduction}
                        className="nb-input inputnextbrain raw  select-bg-dark"
                        value={introduction}
                        onChange={(e) => setIntroduction(e.target.value)}
                      >
                        <option value="" disabled></option>
                        <option value="Twitter">{t('Twitter')}</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Reddit">Reddit</option>
                        <option value="Friend/colleague">
                          {t('Friend/colleague')}
                        </option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="d-flex align-items-start" xs={12}>
              <Row style={{ minWidth: '100%' }}>
                <Col xs={12}>
                  <Form.Label>
                    {t(
                      'What other autoML tools or platforms have you used? (Optional)',
                    )}
                  </Form.Label>
                </Col>
                <Col className="position-relative" xs={12}>
                  <RiPsychotherapyFill className="iconinput-2" />
                  <Form.Control
                    key="previous_platforms"
                    id="previous_platforms"
                    name="previous_platforms"
                    className="inputnextbrain nb-input show-shadow"
                    type="text"
                    value={previousPlatforms}
                    onChange={(e) => setpreviousPlatforms(e.target.value)}
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className={`register-post-setup ${
              stage !== 2 ? 'register-post-setup-hiden' : ''
            }`}
          >
            <Col className="text-center h4" xs={12}>
              {t('Select your industry/industries')}{' '}
              <HelpTooltip
                className="help-select-icon"
                message={t(
                  'Select industries that you are interested in and we will generate a set of template models fitting your profile.',
                )}
                tooltip={true}
              />
            </Col>
            <Col xs={12}>
              <IndustryInfoBody
                industryData={industryData}
                singles={false}
                onChange={(e) => {
                  industryData.industry[e.target.dataset.industry] =
                    e.target.checked
                  setIndustryData({ ...industryData })
                }}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs={'auto'}>
              <Button
                variant={'secondary'}
                onClick={() => setStage((e) => e - 1)}
                className={`px-5 py-3 original ${stage === 1 ? 'd-none' : ''}`}
              >
                {t('Previous')}
              </Button>
            </Col>
            <Col xs={'auto'}>
              <BouncyButton
                disabled={!canAdvance}
                onClick={advanceStage}
                controlClick={true}
                className="px-5 py-3"
              >
                {stage === 1 ? t('Continue') : t('Finish')}
              </BouncyButton>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  )
}
