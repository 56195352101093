import React, { useState } from 'react'
import { Alert, Col, Row, Button, Form } from 'react-bootstrap'
import { FiUser } from 'react-icons/fi'
import { CgKeyhole } from 'react-icons/cg'
import { MdEmail } from 'react-icons/md'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { deleteUser } from '../../services/user'
import StatusMessage from '../model-content/StatusMessage'
import { useNavigate } from 'react-router-dom'
import CookieConfig from '../cookie/CookieConfig'
import { useAuth } from '../../providers/AuthProvider'

export default function AccountProfile({ auth, ...props }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [deletingAccount, setDeletingAccount] = useState(false)
  const [passChangeAlert, setPassChangeAlert] = useState(null)
  const [passChangeIsLoading, setPassChangeIsLoading] = useState(false)
  const { cookies, setCookies } = useAuth()

  const deleteAccountPopup = () =>
    NotificationManager.warning(
      <Row>
        <Col xs={12} className="mb-2">
          <strong>{t('Confirm account deletion')}</strong>
        </Col>
        <Col xs={12}>
          <Button
            onClick={() => {
              setDeletingAccount(true)
              deleteUser(auth.token, auth.signout).then((d) => {
                auth.signout()
                navigate('/')
              })
            }}
            className="original w-100 p-4"
            variant="danger"
          >
            {t('Confirm')}
          </Button>
        </Col>
      </Row>,
      null,
      10000,
    )

  const changePassword = (e) => {
    e.preventDefault()
    setPassChangeIsLoading(true)
    if (e.target.password.value !== e.target.password2.value) {
      setPassChangeAlert(t('Passwords do not match'))
      return
    } else {
      setPassChangeAlert(null)
    }
    auth
      .applySetNewPassword(
        auth.hasPassword === true ? e.target.oldPassword.value : '',
        e.target.password.value,
        auth.token,
      )
      .then((response) => {
        if (response === undefined) {
          setPassChangeAlert(t('Incorrect old password'))
          return
        }
        setPassChangeIsLoading(false)
        NotificationManager.info(t('Password changed successfully'))
      })
  }

  return (
    <Row
      className="mx-3 justify-content-center"
      style={{ marginBottom: '100px' }}
    >
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Info')}</strong>
      </Col>
      <Col xs={6}>
        <Row className="align-items-center">
          <Col xs={12} className="mb-2">
            {t('Name')}
          </Col>
          <Col xs={12}>
            <FiUser size="30" />
            {auth.user?.name ?? auth.user?.email}
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Row className="align-items-center">
          <Col xs={12} className="mb-2">
            {t('Email')}
          </Col>
          <Col xs={12}>
            <MdEmail size="30" /> {auth.user?.email}
          </Col>
        </Row>
      </Col>
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Change password')}</strong>
      </Col>
      <Form onSubmit={changePassword}>
        <Row>
          <Col xs={12} md={6} lg={4}>
            {auth.hasPassword === true ? (
              <Row className="mb-3">
                <Col xs={12} className="mb-2">
                  {t('Old password')}
                </Col>
                <Col className="position-relative" xs={12}>
                  <CgKeyhole className="form-input-icon-left-icon" />
                  <Form.Control
                    type="password"
                    className="form-input-icon-left nb-input w-100 mb-0"
                    bsPrefix="inputnextbrain"
                    name="oldPassword"
                    autoComplete="new-password"
                    required
                    onChange={(e) => {
                      setPassChangeIsLoading(false)
                      setPassChangeAlert(null)
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <p>
                {t(
                  'As you have logged in with a Google or Microsoft account you will need to set a new password.',
                )}
              </p>
            )}
          </Col>
          <Col md={6} className="d-none d-md-block d-lg-none" />
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Row className="align-items-center">
              <Col xs={12} className="mb-2">
                {t('New Password')}
              </Col>
              <Col className="position-relative" xs={12}>
                <CgKeyhole className="form-input-icon-left-icon" />
                <Form.Control
                  type="password"
                  className="form-input-icon-left nb-input w-100 mb-0"
                  bsPrefix="inputnextbrain"
                  name="password"
                  autoComplete="new-password"
                  required
                  onChange={(e) => {
                    setPassChangeIsLoading(false)
                    setPassChangeAlert(null)
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Row className="align-items-center">
              <Col xs={12} className="mb-2 d-inline-block text-truncate">
                {t('Confirm New Password')}
              </Col>
              <Col className="position-relative" xs={12}>
                <CgKeyhole className="form-input-icon-left-icon" />
                <Form.Control
                  type="password"
                  className="form-input-icon-left nb-input w-100 mb-0"
                  bsPrefix="inputnextbrain"
                  name="password2"
                  autoComplete="new-password"
                  required
                  onChange={(e) => {
                    setPassChangeIsLoading(false)
                    setPassChangeAlert(null)
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={4} className="">
            <Button
              className="w-100 py-2"
              type="submit"
              disabled={passChangeIsLoading}
            >
              {t('Change password')}
            </Button>
          </Col>
          <Col xs={12} className="mt-2">
            <Alert
              variant="danger"
              show={passChangeAlert !== null}
              onClose={() => {
                setPassChangeAlert('')
              }}
              transition={true}
            >
              {passChangeAlert}
            </Alert>
          </Col>
        </Row>
      </Form>
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Delete account')}</strong>
      </Col>

      <Col id="delete">
        <Row>
          {deletingAccount ? (
            <StatusMessage history={[{ message: 'Deleting account..' }]} />
          ) : (
            <Col>
              <Row>
                <Col xs={12} md={8} className="mb-2">
                  {t(
                    `Once an account is deleted, there's no going back and all your data will disappear. Please proceed only if you are absolutely sure.`,
                  )}
                </Col>
                <Col xs={12} md={4}>
                  <Button
                    onClick={deleteAccountPopup}
                    className="original w-100"
                    variant="danger"
                  >
                    {t(`Delete Account`)}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
      <Col className="profile-section-separator" xs={12}>
        <strong>{t('Configure cookies')}</strong>
      </Col>

      <Col>
        <Row>
          <CookieConfig
            defaultCookies={cookies}
            onChange={(e) => setCookies({ ...cookies, ...e })}
          />
        </Row>
      </Col>
    </Row>
  )
}
