import { Row, Col, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FaCrown, FaBackward, FaExternalLinkSquareAlt } from 'react-icons/fa'

export default function PaidFeature() {
  const { t } = useTranslation()

  return (
    <Row>
      <Col className="dflex-center mt-4" xs={12}>
        <Image src={'/Logo/3.svg'} style={{ height: '250px' }} />
      </Col>
      <Col className="dflex-center mt-4" xs={12}>
        {t('The current feature is not available with your current plan')}.
      </Col>
      <Col className="mt-5" xs={12}>
        <Row className="dflex-center">
          <Col xs={'auto'}>
            <Link to={-1}>
              <div className="icon-btn">
                <FaBackward size={40} /> <br />
                <i className="fas fa-angle-left fa-lg"></i>
                <strong className="ms-2">{t('Go back')}</strong>
              </div>
            </Link>
          </Col>
          <Col xs={1}></Col>
          <Col xs={'auto'}>
            <Link to={'/plan?tab=0'}>
              <div className="icon-btn">
                <FaCrown size={40} /> <br />
                <strong className="me-2">{t('Go to plans')}</strong>
                <i className="fas fa-angle-right fa-lg"></i>
              </div>
            </Link>
          </Col>
        </Row>
      </Col>
      <Col className="mt-5" xs={12}>
        <Row className="dflex-center">
          <Col xs={'auto'}>
            {t('For any questions or inquiries')}
            <span
              className="icon-btn ms-1 bold"
              onClick={() => {
                document.querySelector('.report-anchor-nav-column')?.click()
              }}
            >
              {t('please contact us')} <FaExternalLinkSquareAlt />
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
