import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function ModelNotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container>
      <Row className="mt-5">
        <Col align="center" md={12}>
          <Image
            alt={t('NextBrain')}
            id="logo"
            style={{ width: '79px', height: '79px', marginTop: '-14px' }}
            className="cursor-pointer"
            onClick={() => navigate('/')}
            src={'/Logo/1.svg'}
          />
          <p className="cursor-pointer" onClick={() => navigate('/')}>
            {t('NextBrain')}
          </p>
        </Col>
        <Col align="center" md={12} className="mt-2">
          <h5>
            {t('This model does not exist or you do not have access to it')}
          </h5>
          <p>{t('Please review the link the user shared with you.')}</p>
          <span className="text-muted">
            {t(
              'Also consider login into your account in case it is a completly private model.',
            )}
          </span>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
          <Button
            className="w-100 p-2 mt-4"
            variant="primary"
            onClick={() => navigate('/')}
          >
            {t('Back to Login screen')}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
