import { Row, Col, Image } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function DashboardIntroScreen({
  video = 'https://www.youtube.com/embed/I4Cgfnhb_x0',
  message = null,
  onFinish = () => {},
  ...props
}) {
  const { t } = useTranslation()
  message = message ?? t('Introduction to your dashboard')
  return (
    <Row
      className="p-4 intro-container"
      align="center"
      style={{ backgroundColor: '#4240B5', color: 'white', minHeight: '600px' }}
      {...props}
    >
      <Row>
        <Col md={12} style={{ zIndex: 1 }}>
          <Image
            onClick={onFinish}
            src={'/icons/Close.svg'}
            style={{
              float: 'right',
              height: '40px',
              cursor: 'pointer',
              fill: 'white',
            }}
            alt="close"
          />
        </Col>
        <Col style={{ fontWeight: 'bold', marginTop: '-50px' }} className="h3">
          {message}
        </Col>
      </Row>
      <div className="containervideo">
        <iframe
          width="740"
          height="415"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Row>
  )
}
