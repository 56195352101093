import React, { useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useModels } from '../../providers/ModelProvider'
import { ResponsiveLine } from '@nivo/line'
import { useTranslation } from 'react-i18next'
import { getMMMSaturationCurves } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery } from 'react-query'
import Loading from '../loading/LoadingSmall'
import { zip } from '../../util/other'
import NextbrainSelect from '../model-content/NextbrainSelect'

function SaturationLine(props) {
  const { yScale, innerWidth, innerHeight, data } = props
  const [base, top] = yScale.domain()
  const total = top - base
  const saturation = data[0]?.saturationPoint

  if (!saturation) return <></>

  const offset = innerHeight - (saturation * innerHeight) / total
  const color = '#f00303'
  return (
    <g transform={`translate(0,${offset})`}>
      <rect
        x={innerWidth / 2 - 45}
        y={-36}
        width={90}
        height={20}
        fill="var(--nextbrain-secondary-color)"
        stroke={color}
        strokeWidth={2}
        rx={4}
        ry={4}
      />
      <polyline
        points={`${innerWidth / 2} ${-6} ${innerWidth / 2 + 4} ${-16} ${
          innerWidth / 2 - 4
        } ${-16}`}
        fill={color}
        strokeWidth="1"
      />
      <text
        className="label-media-contribution"
        x={innerWidth / 2 - 40}
        y={-22}
        fill={color}
      >
        Saturation
      </text>
      <path
        d={`M ${0},${0} L${innerWidth},${0}`}
        className="simple-ant-trail"
        stroke={color}
        strokeWidth="1"
        fill="transparent"
        strokeDasharray="3 6"
      />
    </g>
  )
}

export function SaturationAll({ model = null, fontSize = 12 }) {
  const { token, signout } = useAuth()
  const models = useModels()
  const activeModel = model ?? models.activeModel
  const { t } = useTranslation()
  const [selectedChannel, setSelectedChannel] = useState('TV')

  const { data, isLoading } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      return await getMMMSaturationCurves({
        modelId: activeModel.id,
        token,
        signout,
      })
    },
    { staleTime: 20 * 60 * 1000 },
  )

  const lineData = useMemo(() => {
    if (!data) return {}
    setSelectedChannel(Object.keys(data)[0])
    return Object.keys(data).reduce((d, k) => {
      const channelData = data?.[k]
      d[k] = [
        {
          id: k,
          color: '#3ec73e',
          data: zip([channelData.x, channelData.y]).map(([x, y]) => ({ x, y })),
          saturationPoint: channelData.v_max,
        },
      ]
      return d
    }, {})
  }, [data])

  if (isLoading || !lineData[selectedChannel]) return <Loading />

  return (
    <Row className="position-relative h-100">
      <Col xs={12}>
        <Row style={{ minHeight: '60px', maxHeight: '60px' }}>
          <Col className="h5 mt-2 d-flex align-items-center" xs={'auto'}>
            <strong>Saturation line for </strong>
          </Col>
          <Col style={{ minWidth: '300px' }} xs={'auto'}>
            <NextbrainSelect
              type={'dark'}
              className="basic-single mt-2"
              classNamePrefix="select"
              isSearchable={true}
              isDisabled={isLoading}
              isClearable={false}
              value={{ label: selectedChannel, value: selectedChannel }}
              onChange={(e) => {
                setSelectedChannel(e.value)
              }}
              options={Object.keys(lineData).map((k) => ({
                label: k,
                value: k,
              }))}
            />
          </Col>
        </Row>
      </Col>
      <Col style={{ minHeight: 'calc(100% - 60px)' }} xs={12}>
        <ResponsiveLine
          data={lineData[selectedChannel]}
          margin={{ top: 30, right: 20, bottom: 80, left: 70 }}
          xScale={{ type: 'linear', min: 1 }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max:
              lineData?.[selectedChannel]?.[0]?.saturationPoint * 1.1 || 'auto',
            stacked: true,
            reverse: false,
          }}
          colors={(d) => d.color}
          yFormat=" >-.2f"
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            legend: t('Weekly investment'),
            legendOffset: 56,
            tickRotation: -22,
            legendPosition: 'middle',
            tickValues: 20,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('Outcome'),
            legendOffset: -60,
            legendPosition: 'middle',
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices="x"
          theme={{
            fontSize: fontSize,
            textColor: 'var(--nextbrain-white-font)',
            axis: {
              ticks: {
                text: {
                  fill: 'var(--nextbrain-white-font)',
                },
              },
            },
            legends: {
              text: {
                fontSize: 11,
                fill: 'var(--nextbrain-white-font)',
              },
            },
          }}
          layers={[
            'grid',
            'markers',
            'areas',
            'crosshair',
            'lines',
            'slices',
            'axes',
            'points',
            'legends',
            SaturationLine,
          ]}
        />
      </Col>
    </Row>
  )
}
