import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getTags } from '../../services/document'
import { Col, Row } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'

import { useAuth } from '../../providers/AuthProvider'
import HelpTooltip from '../model-content/HelpTooltip'

function empty() {}

export function Tag({ tag, active, onDelete, onClick }) {
  const cb = onClick || empty

  return (
    <Col
      xs="auto"
      className={`
      tag-autogen-documents border-theme d-inline-block text-truncate me-3 pe-3 ps-0 my-1  position-relative
      ${onDelete ? 'pe-4' : ''} ${onClick ? 'icon-btn' : ''} ${
        active ? 'active-tag' : ''
      }
      `}
      title={tag}
      draggable
      onClick={cb}
    >
      <strong
        className="px-1 bg-theme py-3 me-2 color-white"
        style={{ fontSize: '14px' }}
      >
        #
      </strong>
      {tag || <i>{'<empty>'}</i>}
      {onDelete && (
        <FaTrash
          className="icon-btn"
          size={10}
          style={{ position: 'absolute', right: '7px', top: 'calc(50% - 4px)' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onDelete(tag)
          }}
        />
      )}
    </Col>
  )
}

export default function TagManager({
  filters = [],
  setFilters,
  repositoryId,
  ...props
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()

  const { data: _tags } = useQuery(
    ['repository-tags', repositoryId],
    async () => {
      const tags = await getTags({
        repositoryId,
        token,
        signout,
      })
      if (!Array.isArray(tags)) return []
      tags.sort((a, b) => a.localeCompare(b))
      return tags
    },
    { staleTime: Infinity },
  )
  const tags = _tags || []

  return (
    <Row
      {...props}
      className={`tag-manger-container ${props?.className ?? ''}`}
    >
      <Col xs={12}>
        <Row className="align-items-center">
          <Col xs="auto">
            <HelpTooltip
              className="help-select-icon"
              message={'tags'}
              style={{ display: 'inline-block' }}
              helpLink={'column-to-predict-18qxfb2'}
            />
            {t('Tags')}:
          </Col>
          <Tag
            onClick={setFilters ? () => setFilters([]) : null}
            tag={t('Show all')}
          />
          {tags.map((tag, index) => (
            <Tag
              active={filters.includes(tag)}
              tag={tag}
              key={tag}
              onClick={
                setFilters
                  ? () => {
                      if (filters.includes(tag)) {
                        setFilters(filters.filter((f) => f !== tag))
                      } else {
                        setFilters([...filters, tag])
                      }
                    }
                  : null
              }
            />
          ))}
        </Row>
      </Col>
    </Row>
  )
}
