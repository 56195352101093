import React from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { BsStar, BsStarFill, BsLink45Deg } from 'react-icons/bs'
import { getProjectDate } from '../../util/projects'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

export default function ProjectCard({
  project,
  type = null,
  onMenu = () => {},
  onShare,
  menuTriggerElement = () => <></>,
  onFavourite = () => {},
  ...props
}) {
  const { t } = useTranslation()
  type =
    type ?? project.invited_users.length === 0
      ? t('Private workspace')
      : t('Shared workspace')

  const generateShareBadge = (key) => {
    const sp = key.split(new RegExp('\\._'))
    const tag =
      sp.length > 1 && sp[0].length && sp[1].length
        ? sp[0][0] + sp[1][0]
        : key.substr(0, 2)
    const extraStyles = {}
    if (project.owner.email === key) {
      extraStyles['borderColor'] = project.color ?? '#4240B5'
    }
    return (
      <Col
        className={`type-badge align-items-center rounded-circle py-1 px-2`}
        title={key}
        md={'auto'}
        style={{ marginLeft: '-10px', display: 'flex', ...extraStyles }}
        key={key}
      >
        <span>{tag.toUpperCase()}</span>
      </Col>
    )
  }

  const created = getProjectDate(project)
  const createdStr = `${created.getDate()} ${created.toLocaleString('default', {
    month: 'short',
  })} ${created.getFullYear()}`
  return (
    <div {...props}>
      <Row className="justify-content-between">
        <Col
          title={project.name}
          xs={'auto'}
          className="display-block text-truncate"
          style={{ maxWidth: 'calc(100% - 70px)' }}
        >
          <strong className="h5">{project.name}</strong>
        </Col>
        <Col xs={'auto'} style={{ maxWidth: '70px' }}>
          <Row>
            <Col
              md={'auto'}
              onClick={(e) => {
                e.stopPropagation()
                onMenu(project, e)
              }}
            >
              <Row>
                <Col xs={6} className="p-0">
                  <div
                    style={{
                      minWidth: '30px',
                      minHeight: '30px',
                      maxWidth: '30px',
                      maxHeight: '30px',
                      backgroundColor:
                        project?.color ?? 'var(--nextbrain-main-color)',
                      borderRadius: '40px',
                    }}
                  />
                </Col>
                <Col xs={6} className="p-0 d-flex align-items-end">
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={menuTriggerElement}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        $('body').click()
                      }}
                    >
                      <BiDotsVerticalRounded
                        className="float-right reactive-icon"
                        style={{ width: '30px', height: '30px' }}
                      />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs={12} className="mb-1" style={{ maxWidth: 'calc(100% - 70px)' }}>
          {type}
        </Col>
      </Row>
      <Row className="mx-2">
        {[project.owner, ...project.invited_users].map((user) =>
          generateShareBadge(user.email),
        )}
      </Row>
      <Row className="mt-4">
        <Row>
          <Col style={{ fontWeight: 'bold' }}>
            {t('Models')}: {project?.num_datasets ?? '-'}
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center ms-0">
          <Col md={'auto'} xs={'auto'} className="ps-0">
            {createdStr}
          </Col>
          <Col xs={'auto'}>
            <Row>
              {onShare && (
                <Col
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onShare()
                  }}
                  className="d-flex align-items-center justify-content-center"
                  style={{ maxWidth: '70px' }}
                >
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {t('Share your workspace with others')}
                      </Tooltip>
                    )}
                  >
                    <span className="reactive-icon">
                      <BsLink45Deg size={30} />
                    </span>
                  </OverlayTrigger>
                </Col>
              )}
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    {t(project.favourite ? 'Remove' : 'Add') +
                      t(' this workspace to favorites')}
                  </Tooltip>
                )}
              >
                <Col
                  md={'auto'}
                  xs={'auto'}
                  className="p-0 reactive-icon"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    project.favourite = !project.favourite
                    onFavourite(project.id, project.favourite)
                  }}
                >
                  {project.favourite ? (
                    <BsStarFill style={{ width: 25, height: 25 }} />
                  ) : (
                    <BsStar style={{ width: 25, height: 25 }} />
                  )}
                </Col>
              </OverlayTrigger>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  )
}
