import { ResponsiveBar } from '@nivo/bar'
import { Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { categories } from '../../util/aethetics'
import { round } from '../utils/formating'

const DEFAULT_DATA = []

function adaptData(data, barKey = 'Importance') {
  const percentToScale = (percent, minv, maxv) => {
    if (maxv < minv) {
      let aux = maxv
      maxv = minv
      minv = aux
      return maxv - (maxv - minv) * percent
    }
    return (maxv - minv) * percent + minv
  }

  const MIN_RGB = {
    r: 50,
    g: 140,
    b: 255,
  }

  const MAX_RGB = {
    r: 25,
    g: 35,
    b: 75,
  }

  const maxValue = data.reduce(
    (p, c) => ({ [barKey]: p[barKey] > c[barKey] ? p[barKey] : c[barKey] }),
    { [barKey]: 0 },
  )[barKey]

  const percentToRgbStr = (percent) => {
    let imp = percent / maxValue
    return `rgb(${parseInt(
      percentToScale(imp, MIN_RGB.r, MAX_RGB.r),
    )}, ${parseInt(percentToScale(imp, MIN_RGB.g, MAX_RGB.g))}, ${parseInt(
      percentToScale(imp, MIN_RGB.b, MAX_RGB.b),
    )})`
  }

  const adaptColumnName = (name) => {
    if (name.startsWith('ln_') || name.startsWith('sq_'))
      name = name.substring(3)
    return name.length > 20 ? name.substring(0, 19) + '...' : name
  }

  return data.map((d) => ({
    column: adaptColumnName(d.column),
    [barKey]: round(d[barKey], 1),
    color: d.color ? d.color : percentToRgbStr(d[barKey]),
  }))
}

export function ColumnImportance({ model, ...props }) {
  const colors = categories.greenScale.reverse()
  const sumFeatureImportance =
    model && model.status === 'trained' && model.details.feature_importance
      ? model.details.feature_importance.reduce((p, c) => ({
          importance_std: p.importance_std + c.importance_std,
        })).importance_std
      : 100

  const featureImportance =
    model && model.status === 'trained' && model.details.feature_importance
      ? model.details.feature_importance
          .map((item, i) => ({
            column: item.feature,
            Importance: (100 * item.importance_std) / sumFeatureImportance,
          }))
          .sort((a, b) => b.Importance - a.Importance)
          .slice(0, 7)
          .filter((a) => a.Importance >= 1)
          .sort((a, b) => a.Importance - b.Importance)
          .map((e, i) => ({
            ...e,
            color: colors[Math.min(i, colors.length - 1)],
          }))
      : []

  return <CustomBar data={featureImportance} {...props} barKey="Importance" />
}

export function AlgorithmsUsed({ model, ...props }) {
  const { t } = useTranslation()
  let modelTypes = {}
  const pipeline =
    model && model.status === 'trained' && model.mljar_description
      ? model.mljar_description.map((element) => {
          let value = element.model.split('_').at(-1)
          let transformedElement = {
            column:
              value in modelTypes ? value + ' ' + modelTypes[value] : value,
            Algorithm: element.repeat,
          }

          if (!(value in modelTypes)) modelTypes[value] = 1
          modelTypes[value] += 1
          return transformedElement
        })
      : []

  const totalValues = pipeline.reduce((n, { Algorithm }) => n + Algorithm, 0)
  const finalData = pipeline.map((element) => {
    element.Algorithm = round((100 * element.Algorithm) / totalValues)
    return element
  })
  return (
    <CustomBar
      barKey={t('Algorithm')}
      data={finalData}
      minValue={0}
      {...props}
    />
  )
}

export default function CustomBar({
  data,
  header,
  height = 525,
  barKey = null,
  className = '',
  mt = 0,
  mr = 50,
  mb = 0,
  ml = 130,
  animate = true,
  maxValue = 'auto',
  minValue = 'auto',
  layout = 'horizontal',
  axisBottom = null,
  calculateMargin = false,
  axisLeft = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  },
  barProps = {},
  ...props
}) {
  const { t } = useTranslation()
  barKey = barKey ?? t('Importance')
  if (data) data = adaptData(data, barKey)

  if (calculateMargin) {
    const longest = data.reduce(
      (l, d) => (l > d.column.length ? l : d.column.length),
      0,
    )
    ml = longest * 9
  }

  return (
    <div className={className} {...props}>
      {header ? header : <></>}
      <Row className="mt-2" style={{ height: `${height}px` }}>
        <ResponsiveBar
          animate={animate}
          maxValue={maxValue}
          minValue={minValue}
          data={data ? data : DEFAULT_DATA}
          keys={[barKey]}
          indexBy="column"
          enableGridY={false}
          margin={{ top: mt, right: mr, bottom: mb, left: ml }}
          groupMode="grouped"
          layout={layout}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(d) => d.data.color}
          axisTop={null}
          axisRight={null}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          labelSkipHeight={0}
          labelTextColor="#FFF"
          legends={[]}
          role="application"
          ariaLabel={t('Bar chart')}
          barAriaLabel={function (e) {
            return (
              e.id + ': ' + e.formattedValue + t(' in column: ') + e.indexValue
            )
          }}
          label={(d) => `${d.value} %`}
          labelSkipWidth={50} // labelSkipWidth = minimum bar size needed for a normal label
          theme={{
            textColor: 'var(--nextbrain-white-font)',
          }}
          layers={[
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            'annotations',
            ({ bars, labelSkipWidth }) => {
              return (
                <g>
                  {bars.map(({ width, height, y, data }, index) => {
                    // only show this custom outer label on bars that are too small
                    return width < labelSkipWidth ? (
                      <text
                        key={`${data.value}_${index}`}
                        transform={`translate(${width + 10}, ${
                          y + height / 2
                        })`}
                        textAnchor="left"
                        dominantBaseline="central"
                        color="#000000"
                        fontWeight="bold"
                      >
                        {`${data.value ?? ''} %`}
                      </text>
                    ) : null
                  })}
                </g>
              )
            },
          ]}
          {...barProps}
        />
      </Row>
    </div>
  )
}
