import React, { useRef, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
  Popover,
  Modal,
  Button,
} from 'react-bootstrap'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HELP_URL } from '../../Constants'

import { getRecentModels } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import {
  FaCog,
  FaCrown,
  FaUsers,
  FaChalkboard,
  FaChartBar,
  FaRegUserCircle,
  FaRegFolderOpen,
  FaSearch,
  FaExclamationTriangle,
  FaHome,
} from 'react-icons/fa'
import { MdOutlineMenuBook } from 'react-icons/md'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { CgComponents } from 'react-icons/cg'
import { BsTranslate } from 'react-icons/bs'
import { MdLogout } from 'react-icons/md'
import { SiKubernetes } from 'react-icons/si'
import { useDebouncedCallback } from 'use-debounce'

import './header.css'
import '../../styles/navbar.css'
import 'bootstrap/dist/css/bootstrap.css'
import EnglishFlag from '../../assets/images/english.png'
import SpanishFlag from '../../assets/images/spanish.png'
import { sendReport } from '../../services/report'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { StatusTooltip } from '../search-modal/SearchModal'
import NBInput from '../form/NBInput'
import { useNav } from '../../providers/NavProvider'

const DISABLED_PLAN = process.env?.['REACT_APP_DISABLEPLANS'] === '1'
const DISABLED_PLUGINS = process.env?.['REACT_APP_DISABLEPLUGINS'] === '1'
const DISABLED_ADMIN = process.env?.['REACT_APP_DISABLEADMIN'] === '1'
const DISABLED_REPORTS = process.env?.['REACT_APP_DISABLEREPORTS'] === '1'

function TooltipItem({ placement = 'auto', text = '', children }) {
  const { t } = useTranslation()
  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement={placement}
      delay={{ show: 100, hide: 200 }}
      overlay={(props) => (
        <Tooltip {...props}>
          <span className="">{t(text)}</span>
        </Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  )
}

export function NavRow({ children, ...props }) {
  return (
    <Row {...props} className={`${props.className ?? ''} nav-row`}>
      {children}
    </Row>
  )
}

export default function NavColumn({
  onSearch = () => {},
  setTitle = () => {},
}) {
  const { user, token, signout } = useAuth()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname.replace(/^\//, '').replace(/\/.+/, '')
  const planMarketPlace =
    path === 'plan' && location?.search?.includes('tab=1') ? 'marketplace' : ''
  const status = useRef({})
  const [showReport, setShowReport] = useState(false)
  const reportRef = useRef()
  const filesRef = useRef()
  const [sending, setSending] = useState(false)
  const { mode, setMode } = useNav()

  const [selectedReportModel, setSelectedReportModel] = useState(null)
  const fetchModels = useDebouncedCallback((inputValue, callback) => {
    getRecentModels({
      limit: 15,
      page: 0,
      name_like: inputValue,
      token,
      signout,
    }).then(({ models }) => {
      if (Array.isArray(models)) {
        const result = models.map((m) => ({
          value: m.id,
          label: (
            <Row>
              <Col xs={12}>{m.name}</Col>
              <Col xs={12}>
                <Row>
                  <StatusTooltip model={m} />
                </Row>
              </Col>
              <Col className="small" xs={12}>
                {m.project_name}
              </Col>
            </Row>
          ),
        }))
        callback(result)
      } else callback([])
    })
  }, 500)

  const userSendReport = async () => {
    setSending(true)
    const reportMessage = reportRef.current.value
    const modelId = selectedReportModel?.value
    const files = (
      await Promise.all(
        [...filesRef.current.files].map(
          (f) =>
            new Promise((resolve, reject) => {
              let reader = new FileReader()
              reader.addEventListener('load', () => resolve(reader.result))
              reader.addEventListener('error', () => resolve(null))
              reader.addEventListener('abort', () => resolve(null))
              reader.readAsDataURL(f)
            }),
        ),
      )
    ).filter((v) => v)

    const report = {
      userId: user.id,
      modelId,
      files,
      source: 'human',
      time: new Date().toISOString(),
    }

    const done = () => {
      setTimeout(() => {
        setShowReport(false)
        setSending(false)
        reportRef.current.value = ''
        filesRef.current.value = ''
        filesRef.current.type = 'text'
        filesRef.current.type = 'file'
        setSelectedReportModel(null)
      }, 2000)
    }

    if (!reportMessage && !files.length) {
      done()
      return
    }

    sendReport({ message: reportMessage, report, token, signout }).finally(done)
  }

  return (
    <Row
      className={`d-inline-flex flex-column justify-content-between h-100 ${planMarketPlace}${
        path ? path : mode ? 'home' : 'select-product'
      }-path`}
      style={{ flexWrap: 'nowrap' }}
    >
      <Col xs={'auto'}>
        <Row className="mt-3 nav-item-container justify-content-center">
          <Col className="mt-2" xs={12}>
            <Link
              onClick={() => {
                setMode(null)
                setTitle(t('NextBrain'))
              }}
              className="not-hover-effect"
              to="/"
            >
              <TooltipItem text={t('NextBrain AI')}>
                <div className="next-brain-logo icon-nav"></div>
              </TooltipItem>
            </Link>
          </Col>
          <Col className="cursor-pointer mt-2" onClick={onSearch}>
            <TooltipItem text="Search">
              <div>
                <FaSearch color="white" size={24} />
              </div>
            </TooltipItem>
          </Col>
          <Col className="home-col-nav">
            <TooltipItem text={t('Home')}>
              <Link
                to={'/'}
                onClick={() => {
                  setTitle(t('NextBrain'))
                  setMode(null)
                }}
                className="link-header"
              >
                <FaHome color={'white'} size={29} />
              </Link>
            </TooltipItem>
          </Col>
          <Col className="workspaces-col-nav">
            <TooltipItem text={t('Workspaces')}>
              <Link
                to={'/'}
                className="link-header"
                onClick={() => {
                  setTitle(t('NextBrain'))
                }}
              >
                <FaRegFolderOpen color={'white'} size={29} />
              </Link>
            </TooltipItem>
          </Col>
          {/* <Col className="models-col-nav">
            <TooltipItem text="Models">
              <Link to={'/model'} className="link-header">
                <div className="models-icon icon-nav"></div>
              </Link>
            </TooltipItem>
          </Col> */}
          <Col className="guides-col-nav">
            <TooltipItem text="Knowledge base">
              <Link
                to={'/knowledge-base'}
                className="link-header position-relative"
              >
                <MdOutlineMenuBook color="white" size={35} />
              </Link>
            </TooltipItem>
          </Col>
        </Row>
      </Col>
      <Col className="nav-item-container-full" xs={'auto'}>
        <Row className="mb-1  nav-item-container justify-content-center">
          {!DISABLED_REPORTS && (
            <>
              <Col>
                <TooltipItem text={t('Report issue / feedback')}>
                  <div
                    className="cursor-pointer report-anchor-nav-column"
                    onClick={() => setShowReport(true)}
                  >
                    <FaExclamationTriangle color={'white'} size={29} />
                  </div>
                </TooltipItem>
              </Col>
              <Modal show={showReport} onHide={() => setShowReport(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{t('Report issue / feedback')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mx-2">
                    <Col xs={12}>
                      <NBInput
                        ref={reportRef}
                        as="textarea"
                        className="form-control"
                        defaultValue=""
                        placeholder={t('Details of your issue...')}
                        style={{ height: '300px' }}
                        autoFocus
                      />
                    </Col>
                    <Col className="mt-2" xs={12}>
                      <span className="small">
                        {t('Attach aditional file/s (Optional)')}
                      </span>
                    </Col>
                    <Col xs={12}>
                      <Form.Control
                        ref={filesRef}
                        type="file"
                        accept="image/*"
                        className="nb-input"
                        title={t('Images up to 2MB')}
                        size="2000000"
                        multiple
                      />
                    </Col>
                    <Col className="mt-2" xs={12}>
                      <span className="small">{t('Model (Optional)')}</span>
                    </Col>
                    <Col xs={12}>
                      <NextbrainSelect
                        async={true}
                        loadOptions={fetchModels}
                        value={selectedReportModel}
                        onChange={(value) => {
                          setSelectedReportModel(value)
                        }}
                        isClearable={true}
                        placeholder={t('Search model')}
                        type={'dark'}
                        noOptionsMessage={() =>
                          t('Search your model by name/id')
                        }
                      />
                    </Col>
                    <Col xs={12}>
                      <span className="small">
                        <span
                          className="me-1"
                          style={{ color: 'red', fontSize: '14px' }}
                        >
                          *
                        </span>
                        {t(
                          'By selecting a model you allow our engineers to look into and inspect the data associated to the model.',
                        )}
                      </span>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Row className="w-100">
                    <Col className="d-flex justify-content-center mt-3" xs={12}>
                      <Button
                        className="py-3"
                        style={{ minWidth: '70%' }}
                        onClick={userSendReport}
                        disabled={sending}
                      >
                        {sending
                          ? `${t('Thank you for helping improve')} ${t(
                              'NextBrain',
                            )}!`
                          : t('Submit')}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Footer>
              </Modal>
            </>
          )}
          {!DISABLED_ADMIN && user.is_admin && (
            <Col className="d-flex justify-content-center dashboard-col-nav">
              <OverlayTrigger
                rootClose={true}
                trigger={'click'}
                placement="auto"
                delay={{ show: 100, hide: 300 }}
                onToggle={(show) => {
                  status.current.admin = show
                }}
                overlay={(props) => (
                  <Popover
                    {...props}
                    className={`${
                      props.className ?? ''
                    } no-bg-popover original`}
                  >
                    <NavRow
                      className="py-2 d-inline-flex flex-nowrap align-items-center justify-content-around"
                      style={{ minWidth: '350px', marginLeft: '5px' }}
                    >
                      <TooltipItem text={t('Dashboard')} placement="top">
                        <Col
                          xs={2}
                          onClick={() => {
                            navigate('/dashboard')
                          }}
                        >
                          <FaChalkboard size={35} />
                        </Col>
                      </TooltipItem>
                      <TooltipItem text={t('Users')} placement="top">
                        <Col
                          xs={2}
                          onClick={() => {
                            navigate('/users')
                          }}
                        >
                          <FaUsers size={35} />
                        </Col>
                      </TooltipItem>
                      <TooltipItem text={t('Activity')} placement="top">
                        <Col
                          xs={2}
                          onClick={() => {
                            navigate('/activity')
                          }}
                        >
                          <FaChartBar size={35} />
                        </Col>
                      </TooltipItem>
                      <TooltipItem text="Server" placement="top">
                        <Col
                          xs={2}
                          onClick={() => {
                            navigate('/server')
                          }}
                        >
                          <SiKubernetes size={35} />
                        </Col>
                      </TooltipItem>
                      <TooltipItem text="Tools" placement="top">
                        <Col
                          xs={2}
                          onClick={() => {
                            navigate('/tools')
                          }}
                        >
                          <GiArtificialIntelligence size={35} />
                        </Col>
                      </TooltipItem>
                    </NavRow>
                  </Popover>
                )}
              >
                <div
                  onMouseEnter={(e) => {
                    if (!status.current.admin) {
                      document.getElementsByTagName('body')?.[0]?.click()
                      e.target.click()
                    }
                  }}
                >
                  <div
                    className="admin-icon icon-nav"
                    style={{ paddingRight: '20px' }}
                  ></div>
                </div>
              </OverlayTrigger>
            </Col>
          )}
          {!DISABLED_PLUGINS && (
            <Col
              className="d-none justify-content-center cursor-pointer marketplace-col-nav"
              onClick={() => navigate('/plan?tab=1')}
            >
              <TooltipItem text={t('Plugins')}>
                <div>
                  <CgComponents color="white" size={29} />
                </div>
              </TooltipItem>
            </Col>
          )}
          {!DISABLED_PLAN && (
            <Col
              className="d-flex justify-content-center cursor-pointer plan-col-nav"
              onClick={() => navigate('/plan?tab=0')}
            >
              <TooltipItem text={t('My plan')}>
                <div>
                  <FaCrown color="white" size={29} />
                </div>
              </TooltipItem>
            </Col>
          )}
          <Col className="d-flex justify-content-center account-col-nav">
            <OverlayTrigger
              rootClose={true}
              trigger={'click'}
              placement="auto"
              delay={{ show: 100, hide: 300 }}
              onToggle={(show) => {
                status.current.user = show
              }}
              overlay={(props) => (
                <Popover
                  {...props}
                  className={`${props.className ?? ''} no-bg-popover original`}
                >
                  <NavRow
                    className="py-2 align-items-center justify-content-around"
                    style={{ minWidth: '336px', marginLeft: '5px' }}
                  >
                    <Col xs={3}>
                      <Row className="justify-content-left cursor-default">
                        <Col className="d-inline-block text-nowrap" xs={12}>
                          <BsTranslate color="white" />{' '}
                          <span style={{ color: 'white' }}>
                            {t('Language')}
                          </span>
                        </Col>
                        <Col className="offset-1" xs={3}>
                          <Image
                            className="cursor-pointer half-opacity-on-hover"
                            src={EnglishFlag}
                            width={18}
                            onClick={() => i18n.changeLanguage('en')}
                          />
                        </Col>
                        <Col xs={3}>
                          <Image
                            className="cursor-pointer half-opacity-on-hover"
                            src={SpanishFlag}
                            width={18}
                            onClick={() => i18n.changeLanguage('es')}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <TooltipItem text={t('Account')} placement="top">
                      <Col
                        xs={2}
                        onClick={() => {
                          navigate('/account?tab=0')
                        }}
                      >
                        <FaRegUserCircle size={29} color="white" />
                      </Col>
                    </TooltipItem>
                    <TooltipItem placement="top" text="Help">
                      <Col
                        xs={2}
                        onClick={() => window.open(`${HELP_URL}/en/`, '_blank')}
                      >
                        <div>
                          <div className="help-icon icon-nav" />
                        </div>
                      </Col>
                    </TooltipItem>
                    <TooltipItem text={t('Logout')} placement="top">
                      <Col
                        xs={2}
                        onClick={() => {
                          navigate('/')
                          signout()
                        }}
                      >
                        <MdLogout color="white" size={29} />
                      </Col>
                    </TooltipItem>
                  </NavRow>
                </Popover>
              )}
            >
              <div
                onMouseEnter={(e) => {
                  if (!status.current.user) {
                    document.getElementsByTagName('body')?.[0]?.click()
                    e.currentTarget?.click()
                  }
                }}
              >
                <FaCog size={28} color="white" />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
