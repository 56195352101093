export default function Txt({
  width = 50,
  height = 50,
  className = '',
  ...props
}) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 303.188 303.188"
      className={className}
      height={height}
      width={width}
      {...props}
    >
      <g>
        <polygon
          style={{ fill: '#E8E8E8' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <g>
          <rect
            x="92.817"
            y="166.37"
            style={{ fill: '#D1D3D3' }}
            width="49.543"
            height="12.865"
          />
          <rect
            x="92.817"
            y="141.729"
            style={{ fill: '#D1D3D3' }}
            width="107.543"
            height="12.865"
          />
          <rect
            x="92.817"
            y="117.087"
            style={{ fill: '#D1D3D3' }}
            width="117.551"
            height="12.865"
          />
          <rect
            x="92.817"
            y="92.445"
            style={{ fill: '#D1D3D3' }}
            width="82.209"
            height="12.865"
          />
          <rect
            x="92.817"
            y="67.804"
            style={{ fill: '#D1D3D3' }}
            width="117.551"
            height="12.865"
          />
        </g>
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M113.189,273.871h-12.963v-37.379H88.511v-10.6h36.361v10.6h-11.683V273.871z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M174.952,273.871h-14.998l-9.352-14.998l-9.256,14.998h-14.669l15.917-24.547l-14.965-23.433
			h14.373l8.664,14.834l8.336-14.834h14.801l-15.195,24.449L174.952,273.871z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M201.502,273.871h-12.963v-37.379h-11.716v-10.6h36.361v10.6h-11.683v37.379H201.502z"
          />
        </g>
        <polygon
          style={{ fill: '#A4A9AD' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  )
}
