import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { ResponsiveBar } from '@nivo/bar'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { round } from '../../utils/formating'
import { calculateMargin, colorPalette2 } from './common'

export function ConfigPPS({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.pps-title').val(),
    graphFontSize: Number.parseInt($('.pps-graph-font-size').val()) ?? 10,
    graphType: $('.pps-graph-type').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="pps-title"
            defaultValue={`${config.title ?? 'Predictive Power Score'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="pps-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 10}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="pps-graph-type raw"
            defaultValue={`${config.graphType ?? 'coh'}`}
          >
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="hmp">Heatmap</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function PPSHeatmap({ model, config }) {
  const total = Object.entries(model.pps.score).reduce(
    (acc, [k, obj]) => acc + obj.ppscore,
    0,
  )
  const data = Object.entries(model.pps.score).map(([k, v]) => ({
    id: k,
    data: [
      {
        x: model.pps.target,
        y: (100 * v.ppscore) / total,
      },
    ],
  }))
  const margin = calculateMargin(
    data.map((d) => d.id),
    config.graphFontSize,
  )
  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 50, right: 20, bottom: 20, left: margin * 0.7 }}
      valueFormat=">-.2f"
      label={({ value }) => `${round(value)}%`}
      labelTextColor="#000"
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 46,
      }}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -72,
      }}
      colors={{
        type: 'diverging',
        scheme: 'red_yellow_blue',
        divergeAt: 0.5,
        minValue: 0,
        maxValue: 100,
      }}
      emptyColor="#555555"
      legends={[]}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  )
}

function PPSCol({ model, config }) {
  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 0,
  }

  const total = Object.entries(model.pps.score).reduce(
    (acc, [k, obj]) => acc + obj.ppscore,
    0,
  )
  const data = Object.entries(model.pps.score)
    .map(([k, v], i) => ({
      id: k,
      label: k,
      value: (100 * v.ppscore) / total,
      color: colorPalette2[i++],
    }))
    .filter((d) => d.value > 1)
  const margin = Math.max(
    0,
    calculateMargin(
      data.map((d) => d.label),
      config.graphFontSize,
    ),
  )

  return (
    <ResponsiveBar
      data={data}
      tooltip={(props) => (
        <BasicTooltip
          id={props.id}
          value={`${round(props.value)}%`}
          color={props.color}
          enableChip
        />
      )}
      valueFormat=">-.2f"
      keys={['value']}
      indexBy="id"
      enableGridY={false}
      margin={{
        top: 10,
        right: 50,
        bottom: horizontal ? 50 : margin * 0.7 * 0.38,
        left: horizontal ? margin * 0.7 : 0,
      }}
      groupMode="stacked"
      layout={horizontal ? 'horizontal' : 'vertical'}
      valueScale={{ type: 'linear', min: 0, max: 'auto' }}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      axisBottom={{ ...legend, tickRotation: horizontal ? 0 : 22 }}
      label={({ value }) => `${round(value)}%`}
      labelSkipHeight={0}
      labelTextColor="#000"
      legends={[]}
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      labelSkipWidth={50}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  )
}

export function WidgetPPS({ model, config, id, requestedData = {}, ...props }) {
  if (!model || !config) return <>Loading...</>

  if (!model.pps) return <>No PPS available, try training the model again.</>

  const graph = () => {
    switch (config.graphType) {
      case 'coh':
      default:
      case 'col':
        return <PPSCol model={model} config={config} />
      case 'hmp':
        return <PPSHeatmap model={model} config={config} />
    }
  }

  return (
    <Row className="w-100 h-100" id={id}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col xs={12} style={{ height: 'calc(100% - 50px)' }}>
        {graph()}
      </Col>
    </Row>
  )
}
