import React, { useEffect, useState } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { components } from 'react-select'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { MdVerified } from 'react-icons/md'
import {
  FaArrowDown,
  FaArrowRight,
  FaTrash,
  FaUser,
  FaUserTie,
  FaUsersCog,
} from 'react-icons/fa'
import { useAuth } from '../../providers/AuthProvider'
import {
  moveUserToCompany,
  removeUser,
  searchUserCompanies,
} from '../../services/user'
import { useDebouncedCallback } from 'use-debounce'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

const ROLE_OPTIONS = [
  {
    label: (
      <>
        User will be added as user <FaUser className="ms-1" />
      </>
    ),
    value: 'user',
  },
  {
    label: (
      <>
        User will be added as admin <FaUsersCog className="ms-1" />
      </>
    ),
    value: 'admin',
  },
  {
    label: (
      <>
        User will be added as owner <FaUserTie className="ms-1" />
      </>
    ),
    value: 'owner',
  },
]

const Option = (props) => {
  const item = props?.data?.item
  return (
    <div>
      <components.Option {...props}>
        <Row className="justify-content-end">
          <Col xs={12}>{item?.email}</Col>
          {item.company_users.map(({ company, confirmed }) => (
            <Col
              key={company?.id}
              className="mt-1"
              xs={12}
              style={{ maxWidth: 'calc(100% - 25px)' }}
            >
              <span className="smallp">
                <MdVerified
                  color={
                    confirmed
                      ? 'var(--nextbrain-success-color)'
                      : 'var(--nextbrain-error-color)'
                  }
                />
                {company?.name || `Company ID: ${company?.id}`}
              </span>
            </Col>
          ))}
        </Row>
      </components.Option>
    </div>
  )
}

export const OptionMove = (props) => {
  const item = props?.data?.item
  return (
    <div>
      <components.Option {...props}>
        <Row className="justify-content-end">
          <Col xs={12}>{props?.data?.label}</Col>
          {(item.users ?? []).map((user) => (
            <Col
              key={user}
              className="mt-1"
              xs={12}
              style={{ maxWidth: 'calc(100% - 25px)' }}
            >
              <span className="smallp">
                <FaUser /> {user}
              </span>
            </Col>
          ))}
        </Row>
      </components.Option>
    </div>
  )
}

const customFilter = (option, searchText) => {
  return true
}

const customFilterMove = (option, searchText) => {
  return true
}

export default function UserCompanies({ ...props }) {
  const { token, signout } = useAuth()
  const queryClient = useQueryClient()
  const [currentTargetId, setCurrentTargetId] = useState(null)
  const [query, setQuery] = useState('')
  const { data } = useQuery(
    ['searchUserCompanies', query],
    async () => {
      if (!query) return []
      const res = await searchUserCompanies({ searchTerm: query, token })
      return res.map((user) => ({
        value: user.id,
        label: `${user.email}`,
        item: user,
      }))
    },
    { staleTime: 5000 },
  )
  const currentTarget = data?.find(({ value }) => value === currentTargetId)

  const loadQuery = useDebouncedCallback(
    (target) => target?.value && setQuery(target?.value),
    500,
  )

  const [moveUser, setMoveUser] = useState(false)
  const [currentMoveTarget, setCurrentMoveTarget] = useState(null)
  const [moveQuery, setMoveQuery] = useState('')
  const { data: moveData } = useQuery(
    ['searchUserCompaniesMove', moveQuery],
    async () => {
      if (!moveQuery) return []
      const res = await searchUserCompanies({ searchTerm: moveQuery, token })
      const companyMap = {}
      res.forEach((user) =>
        user.company_users.forEach(({ company }) => {
          companyMap[company.id] = companyMap[company.id] ?? {
            company: company,
            users: new Set(),
          }
          companyMap[company.id].users.add(user.email)
        }),
      )
      return Object.entries(companyMap).map(([id, company]) => ({
        value: id,
        label: `${company?.company?.name || `Company ID: ${id}`}`,
        item: {
          ...company,
          users: Array.from(company.users),
        },
      }))
    },
    { staleTime: 5000 },
  )

  const loadQueryMove = useDebouncedCallback(
    (target) => target?.value && setMoveQuery(target?.value),
    500,
  )

  useEffect(() => {
    if (!currentTarget) {
      setMoveUser(false)
      setCurrentMoveTarget(null)
    }
  }, [currentTarget])

  const [currentRole, setCurrentRole] = useState(ROLE_OPTIONS[0])

  return (
    <Row>
      <Col xs={12}>
        <NextbrainSelect
          className="basic-single w-100"
          classNamePrefix="select"
          placeholder={'Search users/companies'}
          isSearchable={true}
          isClearable={true}
          value={currentTarget}
          onChange={(v) => setCurrentTargetId(v?.value)}
          onKeyDown={(e) => loadQuery(e.target)}
          options={data}
          filterOption={customFilter}
          components={{
            Option,
          }}
        />
      </Col>
      {currentTarget &&
        (moveUser ? (
          <>
            <Col xs={12} className="dflex-center flex-column py-3">
              <h5>Move user to</h5>
              <FaArrowDown size={50} />
            </Col>
            <Col className="mt-2" xs={12}>
              <NextbrainSelect
                className="basic-single w-100"
                classNamePrefix="select"
                placeholder={'Search companies'}
                isSearchable={true}
                isClearable={true}
                value={currentMoveTarget}
                onChange={(v) => setCurrentMoveTarget(v)}
                onKeyDown={(e) => loadQueryMove(e.target)}
                options={moveData}
                filterOption={customFilterMove}
                components={{
                  Option: OptionMove,
                }}
              />
            </Col>
            {currentMoveTarget && currentTarget && (
              <Col className="mt-2" xs={12}>
                <Row>
                  <Col
                    xs={'auto'}
                    style={{ minWidth: '350px', maxWidth: '350px' }}
                  >
                    <NextbrainSelect
                      className="basic-single w-100"
                      classNamePrefix="select"
                      placeholder={'Assign role'}
                      value={currentRole}
                      onChange={(v) => setCurrentRole(v)}
                      options={ROLE_OPTIONS}
                      isClearable={false}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col className="mt-3 mb-2 dflex-center" xs={12}>
              <Button
                className="me-2 original"
                variant="success"
                disabled={!currentMoveTarget || !currentTarget}
                onClick={async (e) => {
                  e.target.disabled = true
                  const role = currentRole?.value ?? 'user'
                  await moveUserToCompany({
                    userId: currentTarget.value,
                    companyId: currentMoveTarget.value,
                    role,
                    token,
                    signout,
                  }).catch((e) => {})
                  NotificationManager.success('User moved to company')
                  setMoveUser(false)
                  setCurrentMoveTarget(null)
                  e.target.disabled = false
                  queryClient.invalidateQueries(['searchUserCompanies', query])
                }}
              >
                Confirm
              </Button>
              <Button
                className="original"
                variant="danger"
                onClick={() => {
                  setCurrentMoveTarget(null)
                  setMoveUser(false)
                }}
              >
                Cancel
              </Button>
            </Col>
          </>
        ) : (
          <Col xs={12}>
            {currentTarget?.item?.company_users.map(
              ({ company, confirmed }) => (
                <Row className="mt-3 mx-2 px-1 admin-subscription-config py-2">
                  <Col xs={'auto'}>
                    <MdVerified
                      className="me-2"
                      color={
                        confirmed
                          ? 'var(--nextbrain-success-color)'
                          : 'var(--nextbrain-error-color)'
                      }
                    />
                    {company?.name || `Company ID: ${company?.id}`}
                  </Col>
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement={'auto'}
                    delay={{ show: 100, hide: 200 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <span className="">Remove from company</span>
                      </Tooltip>
                    )}
                  >
                    <Col xs="auto">
                      <FaTrash
                        className="icon-btn"
                        onClick={async () => {
                          await removeUser({
                            userId: currentTarget?.value,
                            companyId: company?.id,
                            token,
                            signout,
                          })
                          queryClient.invalidateQueries([
                            'searchUserCompanies',
                            query,
                          ])
                        }}
                      />
                    </Col>
                  </OverlayTrigger>
                </Row>
              ),
            )}
            <Row
              className="mt-3 mx-2 px-1 justify-content-center admin-subscription-config py-2 cursor-pointer user-select-none icon-btn"
              onClick={() => setMoveUser(true)}
            >
              <Col xs={'auto'}>
                Move to other company <FaArrowRight />
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
  )
}
