import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { getAllUsersPaginatedByDate } from '../../services/user'
import { useAuth } from '../../providers/AuthProvider'
import { Link } from 'react-router-dom'
import { formatDate } from '../../util/other'
import './UserActivity.css'
import { useQuery } from 'react-query'
import Loading from '../loading/LoadingSmall'
import { round } from '../utils/formating'
import ErrorNB from '../loading/ErrorNB'

const formatHours = (date) => {
  const d = new Date(date)
  let h = d.getHours()
  let m = d.getMinutes()
  let s = d.getSeconds()
  return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${
    s < 10 ? '0' + s : s
  }`
}
export default function UserActivity({ usersPerPage = 25, ...props }) {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const startParam = params.get('start')
  const endParam = params.get('end')
  const [searchTerm, setSearchTerm] = useState('')
  const [pageNum, setPageNum] = useState(1)
  const [columnOrder, setColumnOrder] = useState('name')
  const [descendent, setDescendent] = useState(true)
  const [clean, setClean] = useState(false)
  const [end, setEnd] = useState(() => {
    const date = new Date()
    return endParam ?? formatDate(date)
  })
  const [start, setStart] = useState(() => {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    return startParam ?? formatDate(date)
  })
  useEffect(() => {
    setPageNum(1)
  }, [start, end, searchTerm])
  const { token } = useAuth()
  const customStyles = {
    table: {
      style: {
        padding: '0px', // override the cell padding for head cells
        backgroundColor: 'var(--nextbrain-background)',
        color: 'var(--nextbrain-white-font)',
      },
    },
    noData: {
      style: {
        color: 'var(--nextbrain-white-font)',
      },
    },
    pagination: {
      style: {
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        fill: 'white',
      },
    },
    rows: {
      style: {
        minHeight: '30px', // override the row height
        //backgroundColor: 'var(--nextbrain-secondary-color-50)',
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        borderBottom:
          'solid var(--nextbrain-secondary-border-color) 1px !important',
        borderLeft: 'solid var(--nextbrain-secondary-border-color) 1px',
        borderRight: 'solid var(--nextbrain-secondary-border-color) 1px',
      },
      stripedStyle: {
        //backgroundColor: 'var(--nextbrain-secondary-color)',
        backgroundColor: 'var(--nextbrain-body)',
        color: 'var(--nextbrain-white-font)',
        borderBottom:
          'solid var(--nextbrain-secondary-border-color) 1px !important',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        width: 'max-content',
        //fontWeight: '600',
        fontSize: '16px',
        //border: 'solid var(--nextbrain-secondary-border-color) 1px',
        color: 'var(--nextbrain-white-font)',
        backgroundColor: 'var(--nextbrain-secondary-color)',
      },
    },
    headRow: {
      style: {
        border: 'solid var(--nextbrain-secondary-border-color) 1px !important',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        fontSize: '14px',
        fontWeight: '500',
        //border: 'solid var(--nextbrain-secondary-border-color) 1px',
        marginBottom: '0',
        width: 'max-content',
      },
    },
  }
  const columns = (period) => {
    const localUsers = users[period]
    return [
      {
        name: '',
        selector: (row) => {
          const userData = localUsers.find((user) => user.email === row.email)
          return (
            <Link to={`/user/?userId=${userData?.id}&from=users`}>
              <div
                className="user-link"
                style={{
                  color: 'var(--nextbrain-tables-blue-graph-bar-color)',
                }}
              >
                {row.name}
              </div>
            </Link>
          )
        },
        sortable: false,
        width: '150px',
      },
      {
        name: '',
        selector: (row) => {
          const userData = localUsers.find((user) => user.email === row.email)
          return (
            <Link to={`/user/?userId=${userData?.id}&from=users`}>
              <div
                className="user-link"
                style={{
                  color: 'var(--nextbrain-tables-blue-graph-bar-color)',
                }}
              >
                {row.email}
              </div>
            </Link>
          )
        },
        sortable: false,
      },
      {
        name: t('Last session'),
        selector: (row) => {
          if (row.lastSession) return formatHours(row.lastSession)
        },
        sortable: false,
        width: '120px',
      },
      {
        name: t('Hours'),
        selector: (row) => round((row.minutes ? row.minutes : 0) / 60, 2),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Sessions'),
        selector: (row) => (row.sessions ? row.sessions : 0),
        sortable: false,
        width: '100px',
      },
      {
        name: t('Models'),
        selector: (row) => (
          <div className="d-flex justify-content-center">
            <strong className="theme-color" title="automl models">
              {row.automl_models ? row.automl_models : '0'}
            </strong>
            <span className="mx-2">/</span>
            <strong className="theme-color-mmm" title="mmm models">
              {row.mmm_models ? row.mmm_models : '0'}
            </strong>
          </div>
        ),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Trains'),
        selector: (row) => (row.trains ? row.trains : '0'),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Predicts'),
        selector: (row) => (row.predicts ? row.predicts : '0'),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Transform'),
        selector: (row) =>
          row.langchains_transform ? row.langchains_transform : '0',
        sortable: false,
        width: '80px',
      },
      {
        name: t('Failures'),
        selector: (row) =>
          row.failures ? (
            <div className="red-failure">{row.failures}</div>
          ) : (
            '0'
          ),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Chats'),
        selector: (row) => (row.chats ? <div>{row.chats}</div> : '0'),
        sortable: false,
        width: '80px',
      },
      {
        name: t('Cost'),
        selector: (row) =>
          row.chats ? (
            <div>{round(Number.parseFloat(row.cost), 3)}$</div>
          ) : (
            'No use'
          ),
        sortable: false,
        width: '80px',
      },
    ]
  }

  const { data: users, isLoading: usersLoading } = useQuery(
    [
      'admin-query-user-activity',
      pageNum,
      start,
      end,
      columnOrder,
      descendent,
      searchTerm,
    ],
    () => {
      return getAllUsersPaginatedByDate(
        token,
        1000,
        pageNum,
        start,
        end,
        columnOrder,
        descendent,
        searchTerm,
      )
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (searchTerm === '') {
      handleClean()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const handleClean = () => {
    setClean(!clean)
  }
  const changeOrderColumn = (e) => {
    if (columnOrder !== e.name.toLowerCase()) {
      setColumnOrder(e.name.toLowerCase().replace(' ', ''))
      setDescendent(!descendent)
    } else {
      setColumnOrder(e.name.toLowerCase().replace(' ', ''))
      setDescendent(!descendent)
    }
  }
  return (
    <>
      <Row className="px-3 admin-panel justify-content-center" {...props}>
        <div className="mb-5 title">
          <div className="h2">{t('Activity')}</div> <span className="line" />
        </div>
        <Col md={8}>
          <div className="backfilters-activity rounded">
            <Row>
              <Col lg={4} xs={12}>
                <div className="label-text">{t('From')}</div>
                <input
                  className="input-date w-100 nb-input-soft ps-2 mt-0"
                  style={{
                    paddingTop: 6,
                    paddingBottom: 6,
                    marginTop: '5px !important',
                  }}
                  type="date"
                  id="start"
                  value={start}
                  onChange={(e) => setStart(e.currentTarget.value)}
                />
              </Col>
              <Col lg={4} xs={12}>
                <div className="label-text">{t('To')}</div>
                <input
                  className="input-date w-100 nb-input-soft ps-2 mt-0"
                  style={{
                    paddingTop: 6,
                    paddingBottom: 6,
                    marginTop: '5px !important',
                  }}
                  type="date"
                  id="end"
                  value={end}
                  onChange={(e) => setEnd(e.currentTarget.value)}
                />
              </Col>
              <Col lg={4} xs={12}>
                <div className="label-text">{t('Search user...')}</div>
                <Form.Control
                  style={{
                    borderRadius: 8,
                  }}
                  className="with-icon nb-input-soft mt-0"
                  placeholder={t('Search user...')}
                  onChange={(e) => {
                    setSearchTerm(e.currentTarget.value)
                  }}
                />
                <i
                  className="fas fa-search icon users-icon-search"
                  style={{ marginLeft: -15 }}
                ></i>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={12}>
          {usersLoading ? (
            <Loading />
          ) : !users ? (
            <ErrorNB errorMessage={t('Failed to retrieve data')} />
          ) : (
            Object.entries(users)
              .filter(([_, users]) => users?.length)
              .map(([period, users]) => (
                <Fragment key={period}>
                  <div className="mb-1 title">
                    <div className="h5">{period}</div>
                    <span
                      className="line"
                      style={{ transform: 'scale(0.5)' }}
                    ></span>
                  </div>
                  <DataTable
                    className="table"
                    columns={columns(period)}
                    data={users ?? []}
                    striped={true}
                    customStyles={customStyles}
                    persistTableHead
                    onChangePage={(e) => {}}
                    sortServer
                    onSort={(e) => changeOrderColumn(e)}
                  />
                </Fragment>
              ))
          )}
        </Col>
      </Row>
    </>
  )
}
