import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import NextbrainSelect from '../../NextbrainSelect'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import FilterTable from './FilterTable'
import { AddRowButton, DeleteRowButton } from '../../../utils/ui'

export const operation = {
  '=': {
    hasValue: true,
  },
  '!=': {
    hasValue: true,
  },
  '>': {
    hasValue: true,
  },
  '>=': {
    hasValue: true,
  },
  '<': {
    hasValue: true,
  },
  '<=': {
    hasValue: true,
  },
  'starts with': {
    hasValue: true,
  },
  'ends with': {
    hasValue: true,
  },
  in: {
    hasValue: 'list',
  },
  'not in': {
    hasValue: 'list',
  },
  'is null': {
    hasValue: false,
  },
}

function ColumnFilter({
  column,
  operator,
  value,
  columns,
  operators,
  onChange,
  onDelete,
  showDeleteButton,
}) {
  const operatorConfig = operation[operator?.value]
  const hasValue = operatorConfig?.hasValue
  const freeInputRef = useRef()
  //Dont want to be calling a new sample for each keystroke
  const updateFreeInput = useDebouncedCallback(
    (v) => onChange(column, operator, v.target.value),
    1000,
  )

  useEffect(() => {
    if (freeInputRef.current) freeInputRef.current.value = value ?? ''
  }, [value])

  return (
    <Row className="align-items-center">
      <Col xs={11}>
        <Row className="w-100 align-items-center">
          <Col xs={4}>
            <NextbrainSelect
              value={column}
              onChange={(c) => onChange(c, operator, null)}
              options={columns}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single `}
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
            />
          </Col>
          <Col xs={4}>
            <NextbrainSelect
              value={operator}
              onChange={(o) => {
                let newVal = null
                if (
                  !operator ||
                  operation[o.value].hasValue ===
                    operation[operator.value].hasValue
                )
                  newVal = value
                onChange(column, o, newVal)
              }}
              options={operators}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single `}
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
            />
          </Col>
          <Col xs={4}>
            {hasValue && (
              <>
                {hasValue === 'list' ? (
                  <NextbrainSelect
                    value={value}
                    onChange={(v) => onChange(column, operator, v)}
                    options={[]}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    className={`basic-single `}
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    creatable={true}
                    isMulti={true}
                  />
                ) : (
                  <Form.Control
                    ref={freeInputRef}
                    className="h-100 py-2 nb-input"
                    onChange={updateFreeInput}
                    style={{ backgroundColor: '#EBEBEB' }}
                    defaultValue={value ?? ''}
                    placeholder={'Value'}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={1}>
        {showDeleteButton && <DeleteRowButton onClick={onDelete} />}
      </Col>
    </Row>
  )
}

export default function ConfigureFilter({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [inputNode, setInputNode] = useState(null)
  const [filters, setFilters] = useState(
    configuration?.filters ?? [{ column: null, operator: null, value: null }],
  )
  const columns = useRef(null)
  const operators = useRef(
    Object.keys(operation).map((o) => ({ value: o, label: o })),
  )
  const [sample, setSample] = useState(null)

  useEffect(() => {
    const inputEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_uniq_target`,
    )

    if (!inputEdge) {
      NotificationManager.error('Missing connection')
      close()
    } else {
      const inputNode = getNode(inputEdge.source)
      if (!inputNode?.data?.valid) {
        NotificationManager.error(
          t('Connected nodes are invalid, configure them first'),
        )
        close()
      } else {
        if (!inputNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          columns.current = inputNode.data.sample.columns.map((c) => ({
            value: c,
            label: c,
          }))
          setInputNode(inputNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!inputNode) return <Loading />
  const validFilters = filters.filter(
    (f) =>
      f.column &&
      f.operator &&
      (f.value || !operation[f.operator.value].hasValue),
  )

  return (
    <Row>
      <Col className="mb-1" xs={11}>
        <Row>
          <Col xs={4}>{t('Column')}</Col>
          <Col xs={4}>{t('Operator')}</Col>
          <Col xs={4}>{t('Value')}</Col>
        </Row>
      </Col>
      <Col className="d-flex align-items-center" xs={1}>
        <AddRowButton
          onClick={() =>
            setFilters((f) => [
              { column: null, operator: null, value: null },
              ...f,
            ])
          }
        />
      </Col>
      <Col xs={12}>
        {filters.map((f, i) => (
          <ColumnFilter
            key={i}
            operators={operators.current}
            columns={columns.current}
            onChange={(c, o, v) => {
              setFilters((f) =>
                f.map((f, j) =>
                  j === i ? { column: c, operator: o, value: v } : f,
                ),
              )
            }}
            onDelete={() => setFilters((f) => f.filter((_, j) => j !== i))}
            showDeleteButton={filters.length > 1}
            {...f}
          />
        ))}
      </Col>
      <Col className="mt-2" xs={12}>
        <span className="h4">
          <strong>{t('Preview')}</strong>
        </span>
      </Col>
      <Col
        className="my-2 filter-table-flow position-relative"
        style={{
          overflow: 'auto',
          position: 'relative',
          zIndex: 0,
        }}
      >
        {inputNode && (
          <FilterTable
            input={inputNode.data.sample}
            filters={validFilters}
            onDataLoad={(data) => setSample(data)}
          />
        )}
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample || !validFilters.length}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              input: inputNode.id,
              filters: filters,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
