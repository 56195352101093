import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveWaffle } from '@nivo/waffle'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { colorPalette1, calculateMargin } from './common'

export function ConfigDataDistribution({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.distribution-title').val(),
    graphFontSize:
      Number.parseInt($('.distribution-graph-font-size').val()) ?? 12,
    graphType: $('.distribution-graph-type').val(),
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="distribution-title"
            defaultValue={`${config.title ?? 'Data Distribution'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="distribution-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="distribution-graph-type raw"
            defaultValue={`${config.graphType ?? 'pie'}`}
          >
            <option value="pie">Pie chart</option>
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="waf">Waffle</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function DDPie({ model, config }) {
  let targetDistribution =
    model.details && model.details.target_distribution
      ? Object.keys(model.details.target_distribution)
          .map((k, i) => ({
            id: `${model.target} ${k}`,
            label: `${model.target} ${k}`,
            value: model.details.target_distribution[k],
            color: colorPalette1[i % colorPalette1.length],
          }))
          .sort((a, b) => b.value - a.value)
      : null

  return targetDistribution ? (
    <ResponsivePie
      data={targetDistribution}
      margin={{ top: 40, right: 0, bottom: 20, left: 10 }}
      cornerRadius={5}
      activeOuterRadiusOffset={3}
      colors={(d) => d.data.color}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 6]],
      }}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -30,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: 'var(--nextbrain-widget-axis-legend)',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  ) : (
    <>No data</>
  )
}

function DDCol({ model, config }) {
  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  }

  let targetDistribution =
    model.details && model.details.target_distribution
      ? Object.keys(model.details.target_distribution)
          .map((k, i) => ({
            id: `${model.target} ${k}`,
            label: `${model.target} ${k}`,
            value: model.details.target_distribution[k],
            color: colorPalette1[i % colorPalette1.length],
          }))
          .sort((a, b) => b.value - a.value)
      : null
  const margin = calculateMargin(
    targetDistribution.map((t) => t.label),
    config.graphFontSize,
  )
  return targetDistribution ? (
    <ResponsiveBar
      data={targetDistribution}
      keys={['value']}
      indexBy="id"
      enableGridY={false}
      margin={{
        top: 10,
        right: 40,
        bottom: Math.max(50, margin * 0.38 * 0.8),
        left: horizontal ? margin * 0.7 : 10,
      }}
      groupMode="grouped"
      layout={horizontal ? 'horizontal' : 'vertical'}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      axisBottom={{ ...legend, tickRotation: 22 }}
      labelSkipHeight={0}
      labelTextColor="#000"
      legends={[]}
      role="application"
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      label={(d) => `${d.value}`}
      labelSkipWidth={50}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  ) : (
    <>No data</>
  )
}

function DDWaf({ model, config }) {
  let total = 0
  let targetDistribution =
    model.details && model.details.target_distribution
      ? Object.keys(model.details.target_distribution)
          .map((k, i) => {
            total += model.details.target_distribution[k]
            return {
              id: `${model.target} ${k}`,
              label: `${model.target} ${k}`,
              value: model.details.target_distribution[k],
              color: colorPalette1[i % colorPalette1.length],
            }
          })
          .sort((a, b) => b.value - a.value)
      : null
  targetDistribution.forEach((t) => {
    t.original = t.value
    t.value = Math.round((200 * t.value) / total)
  })
  total = Math.min(
    200,
    targetDistribution.reduce((a, t) => a + t.value, 0),
  )

  const side = Math.ceil(Math.sqrt(total))
  const rows = Math.floor(side / 1.5)
  const cols = Math.floor(side * 1.5)

  return targetDistribution ? (
    <ResponsiveWaffle
      data={targetDistribution}
      total={total}
      rows={rows}
      columns={cols}
      margin={{ top: 40, right: 10, bottom: 30, left: 10 }}
      colors={(d) => d.color}
      animate={true}
      motionStiffness={90}
      motionDamping={11}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
      tooltip={(props) => (
        <BasicTooltip
          id={props.id}
          value={`${props.original}`}
          color={props.color}
          enableChip
        />
      )}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -30,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: 'var(--nextbrain-widget-axis-legend)',
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                itemBackground: '#f7fafb',
              },
            },
          ],
        },
      ]}
    />
  ) : (
    <>No data</>
  )
}

export function WidgetDataDistribution({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const graph = () => {
    switch (config.graphType) {
      default:
      case 'pie':
        return <DDPie model={model} config={config} />
      case 'col':
      case 'coh':
        return <DDCol model={model} config={config} />
      case 'waf':
        return <DDWaf model={model} config={config} />
    }
  }

  return (
    <Row className="w-100 h-100" id={id}>
      <Col
        className="header-data-distribution d-inline-block text-truncate widget-title"
        xs={12}
      >
        {config.title}
      </Col>

      <Col
        className="header-data-distribution-num-col d-inline-block text-truncate"
        xs={12}
      >
        <span>Number of columns</span>
        <strong className="ms-2">
          {model && model.columns_active
            ? Object.keys(model.columns_active).length
            : 0}
        </strong>
      </Col>
      <Col
        xs={12}
        className="d-inline-block text-truncate header-data-distribution-num-row"
      >
        <span>Number of rows</span>
        <strong className="ms-2">
          {model && model.dataset ? model.dataset.rows : 0}
        </strong>
      </Col>
      <Col
        xs={12}
        style={{ height: 'calc(100% - 100px)' }}
        className="header-data-distribution-num-pie"
      >
        {graph()}
      </Col>
    </Row>
  )
}
