import { request } from './base'

export async function getDataset(datasetId, token, signout) {
  return request({
    endpoint: `/dataset/get_dataset/${datasetId}`,
    token: token,
    signout: signout,
  })
}

export async function changeDatasetName(
  datasetId,
  dataset_name,
  token,
  signout,
) {
  return request({
    endpoint: `/dataset/set_name/${datasetId}`,
    method: 'POST',
    body: {
      name: dataset_name,
    },
    contentType: 'json',
    token: token,
    signout: signout,
  })
}

export async function setColumnType(
  datasetId,
  field,
  desiredType,
  token,
  signout,
) {
  return request({
    endpoint: `/dataset/set_column_type/${datasetId}`,
    method: 'POST',
    body: {
      field: field,
      type: desiredType,
    },
    contentType: 'json',
    token: token,
    signout: signout,
  })
}
