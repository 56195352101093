import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BiError } from 'react-icons/bi'

export default function TableError({ error = '', subtext = '', children }) {
  const { t } = useTranslation()

  return (
    <Row className="justify-content-center text-align-center p-3">
      <Col align="center" className="position-relative h4" xs={'auto'}>
        {t(error)}
        <BiError
          size={25}
          style={{
            position: 'absolute',
            top: '-15px',
            left: '-15px',
            color: 'var(--nextbrain-error-color)',
          }}
        />
      </Col>
      <Col align="center" xs={12}></Col>
      <Col align="center" xs={'auto'}>
        {t(subtext)}
      </Col>
      <Col xs={12}>{children}</Col>
    </Row>
  )
}
