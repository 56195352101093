import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { getMMMInfluence, getMMMModelStackedPlot } from '../../services/model'
import { Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Avatar from '../../assets/images/avatar.png'
import { useTranslation } from 'react-i18next'

function andJoin(data) {
  if (data.length === 0) return ''
  if (data.length === 1) return data[0]
  return data.reduce((prev, curr, index) => [
    <span key={`prev-${index}`}>{prev}</span>,
    <span key={`separator-${index}`}>
      {index === data.length - 1 ? ' and ' : ', '}
    </span>,
    <span key={`curr-${index}`}>{curr}</span>,
  ])
}

function CustomOverlay({
  overlay = null,
  children,
  onClick = () => console.log('Custom overlay clicked'),
}) {
  const { t } = useTranslation()
  overlay = overlay ?? t('Click to ignore extra feature')
  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => <Tooltip {...props}>{overlay}</Tooltip>}
    >
      <span onClick={onClick}>{children}</span>
    </OverlayTrigger>
  )
}

export default function HowToImproveMMMPerformance({
  model,
  trainingConfiguration,
  style = { minHeight: '120px' },
  ...props
}) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()

  const { data, isLoading } = useQuery(
    ['MMMInfluence', model.id],
    async () => {
      return await getMMMInfluence({ modelId: model.id, token, signout })
    },
    { staleTime: Infinity },
  )

  const { data: stackedPlot } = useQuery(
    ['mediaContribution', model.id],
    async () => {
      return await getMMMModelStackedPlot({
        modelId: model.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  const baseline = useMemo(() => {
    try {
      const i = stackedPlot.columns.indexOf('baseline')
      return stackedPlot.data.map((d) => d[i])
    } catch (e) {
      return null
    }
  }, [stackedPlot])

  const shouldHideExtraFeatures = useMemo(
    () => {
      try {
        if (!isLoading && baseline) {
          const {
            extraFeatures,
            setExtraFeatures,
            columnsToIgnore,
            setColumnsToIgnore,
          } = trainingConfiguration

          const ignoreFeature = (feature) => {
            setExtraFeatures(extraFeatures.filter((e) => e?.value !== feature))
            setColumnsToIgnore([
              ...columnsToIgnore,
              { value: feature, label: feature },
            ])
          }
          const mci = model.mmm.table.columns.indexOf('Media contribution')
          const meanMediaContribution =
            model.mmm.table.data.map((d) => d[mci]).reduce((a, b) => a + b, 0) /
            100
          const meanBaselineContribution = 1 - meanMediaContribution
          const extra_features = data.columns
            .filter(
              (d) =>
                d !== 'Trend' && d !== 'Seasonality' && d !== 'Seasonability',
            )
            .reduce((d, f) => {
              const findex = data.columns.indexOf(f)
              const avg =
                data.data.reduce((a, e) => a + e[findex], 0) / data.data.length
              d[f] = avg * meanBaselineContribution
              return d
            }, {})
          const shouldHide = []
          Object.entries(extra_features).forEach(([k, v]) => {
            if (
              !extraFeatures.find((e) => e?.value === k) ||
              columnsToIgnore.find((e) => e?.value === k)
            )
              return

            if (v > meanMediaContribution * 1.1)
              shouldHide.push(
                <CustomOverlay onClick={() => ignoreFeature(k)} key={k}>
                  <span className="link">{k}</span>
                </CustomOverlay>,
              )
          })
          return shouldHide
        }
      } catch (e) {}
      return null
    },
    // eslint-disable-next-line
    [isLoading, baseline, trainingConfiguration, t],
  )

  return Array.isArray(shouldHideExtraFeatures) &&
    shouldHideExtraFeatures.length ? (
    <Row
      style={style}
      {...props}
      className={`insights-card insights-card-alt py-2 px-1 ${
        props?.className ?? ''
      }`}
    >
      <Col xs={12}>
        <Image src={Avatar} width={64} />
        <strong className="mx-2" style={{ fontSize: 16 }}>
          {t("These are my suggestions for enhancing your model's accuracy.")}
        </strong>
      </Col>
      <Col className="mt-2 display-inline-block" xs={12}>
        <li className="w-100 ms-3">
          {t(
            'Ignore these extra features as they contribute more than your media investment and might distort the model results',
          )}
          {': '}
          <i>{andJoin(shouldHideExtraFeatures)}</i>
        </li>
      </Col>
    </Row>
  ) : (
    <></>
  )
}
