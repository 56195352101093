import { NotificationManager } from 'react-notifications'
import {
  uploadCSV,
  getAllCSV,
  checkFile,
  mergeSuggestion,
  checkMerge,
} from '../../../services/csv'
import { typeMappingToBackend } from './configure-action/ChangeTypeTable'
import { awaitTaskCall } from '../../../services/base'

async function serializeStoreData(node) {
  return {
    metadata: node,
    step_type: 'file',
    config: {
      csv_id: node.data.config.selectedCsv,
    },
  }
}

async function retryUploadCSV(
  userData,
  node,
  onProgress,
  tail = null,
  retries = 10,
  tryTask = false,
) {
  tail = tail ?? `${Math.floor(Math.random() * 100000)}`
  const args = [
    userData.user.id,
    node.data.config.fileToUpload,
    userData.token,
    userData.signout,
    false,
    onProgress || (() => {}),
    { defaultTail: tail },
  ]
  if (tryTask)
    args.push(node.data.config?.taskId, node.data.config?.fileToUpload?.name)

  const response = await uploadCSV(...args).catch((e) => ({ failed: true }))
  if (tryTask && node.data?.config?.taskId) {
    if (response?.id) {
      response.success = true
      return response
    }
    return await retryUploadCSV(userData, node, onProgress, tail, retries - 1)
  } else if (response?.failed && retries) {
    NotificationManager.warning('Failed to upload file, retrying...')
    await new Promise((resolve) => setTimeout(resolve, 4000))
    return await retryUploadCSV(userData, node, onProgress, tail, retries - 1)
  }
  return response
}

async function serializeUploadLocalFile(node, userData, onProgress, options) {
  let csv = node.data.createdCSV

  if (!csv) {
    const response = await retryUploadCSV(
      userData,
      node,
      onProgress,
      null,
      10,
      options?.tryTask,
    )
    const csvId = response?.response ?? response
    if (!response?.success || !csvId?.id) return null

    csv = csvId.id
    node.data.createdCSV = csv
  }

  return {
    metadata: {
      ...node,
      type: 'Stored data',
      data: {
        ...node.data,
        type: 'Stored data',
        config: {
          selectedCsv: csv,
        },
      },
    },
    step_type: 'file',
    config: {
      csv_id: csv,
    },
  }
}

async function serializeDuckdb(node, userData, onProgress, options) {
  debugger
  return {
    metadata: {
      ...node,
      type: 'Stored data',
      data: {
        ...node.data,
        type: 'Stored data',
        config: {
          selectedCsv: node.data.config.csvId,
        },
      },
    },
    step_type: 'file',
    config: {
      csv_id: node.data.config.csvId,
    },
  }
}

async function serializeGsheets(node) {
  return {
    metadata: node,
    step_type: 'spreadsheet',
    config: node.data.config,
  }
}

async function serializeDatabase(node) {
  return {
    metadata: node,
    step_type: 'database',
    config: { uploadCSVFromBBDD: node.data.config },
  }
}

async function serializeMongo(node) {
  const res = {
    metadata: node,
    step_type: 'mongo',
    config: {
      uri: node.data.config.uri,
      database: node.data.config.database,
      collection: node.data.config.collection,
      query: node.data.config.query,
      projection: node.data.config.projection,
      limit: node.data.config?.limit ?? null,
    },
  }

  const features = node?.data?.config?.features?.selections

  if (features && typeof features === 'object') {
    let valid = false
    const columns_to_keys_of_keys = Object.keys(features).reduce((ac, key) => {
      ac[key] = []
      Object.values(features[key]).forEach((v) => {
        if (v.status) {
          valid = true
          ac[key].push(v.path)
        }
      })
      return ac
    }, {})
    if (valid)
      res.config.extract_json = {
        columns_to_keys_of_keys,
      }
  }

  return res
}

async function serializeDataslayer(node) {
  return {
    metadata: node,
    step_type: 'api',
    config: {
      url: node.data.config.link,
      data_type: 'json',
    },
  }
}

async function serializeAPI(node) {
  return {
    metadata: node,
    step_type: 'api',
    config: {
      url: node.data.config.link,
      headers: node.data.config.headers,
      data_type: node.data.config.data_type,
      password_auth: node.data.config.password_auth,
      username_auth: node.data.config.username_auth,
    },
  }
}

async function serializeMerge(node) {
  return {
    metadata: node,
    step_type: 'merge',
    config: {
      left: node.data.config.left,
      right: node.data.config.right,
      join_type: node.data.config.joinType,
      visible_columns: node.data.config.visibleColumns.map((v) => v),
      avoid_duplicated_left_indexes: node.data.config.avoidDuplicatesLeft,
      avoid_duplicated_right_indexes: node.data.config.avoidDuplicatesRight,
      ...node.data.config.columnsPaired.reduce(
        (ac, p, i) => {
          ac.left_columns.push(p[0])
          ac.right_columns.push(p[1])
          if (p[2]) ac.constant_columns[i] = p[2]
          return ac
        },
        { left_columns: [], right_columns: [], constant_columns: {} },
      ),
    },
  }
}

async function serializeConcat(node) {
  return {
    metadata: node,
    step_type: 'concat',
    config: {
      first: node.data.config.left,
      second: node.data.config.right,
    },
  }
}

async function serializeAggregate(node) {
  return {
    metadata: node,
    step_type: 'aggregate',
    config: {
      input: node.data.config.input,
      groupby_columns: node.data.config.selectedIds.map((c) => c.value),
      column_to_function: node.data.config.aggregations.reduce(
        (acc, { column, func }) => {
          acc[column] = func
          return acc
        },
        {},
      ),
      general_function: node.data.config.defaultBehavior?.value ?? 'sum',
    },
  }
}

async function serializeFilter(node) {
  return {
    metadata: node,
    step_type: 'filter',
    config: {
      input: node.data.config.input,
      filter: node.data.config.filters.map((n) => {
        const res = {
          column: n.column.value,
          operator: n.operator.value,
        }
        if (typeof n.value === 'string') res.value = n.value
        else res.value = n.value.map((v) => v.value)
        return res
      }),
    },
  }
}

async function serializeTransform(node) {
  return {
    metadata: node,
    step_type: 'transform',
    config: {
      input: node.data.config.input,
      transform: node.data.config.transform.map((n) => {
        const res = {
          column: n.column.value,
          operator: n.operator.value,
          value: n.value,
          project: n.project,
        }
        if (n.selectColumn && n.selectOperator)
          res.select = {
            column: n.selectColumn.value,
            operator: n.selectOperator.value,
            value: Array.isArray(n.selectValue)
              ? n.selectValue.map((v) => v.value)
              : n.selectValue,
          }
        return res
      }),
    },
  }
}

async function serializeChangeType(node) {
  return {
    metadata: node,
    step_type: 'type_cast',
    config: {
      input: node.data.config.input,
      type_mapping: Object.entries(node.data.config.type_mapping).reduce(
        (d, [c, t]) => {
          console.log(c, t, typeMappingToBackend)
          d[c] = typeMappingToBackend[t]
          return d
        },
        {},
      ),
    },
  }
}

async function serializeEnrich(node) {
  return {
    metadata: node,
    step_type: 'enrichment',
    config: {
      input: node.data.config.input,
      name: node.data.config.target.value,
      columns_to_add: (node.data.config.config.columns_to_add ?? [])?.map(
        (c) => c.value,
      ),
      kwargs: Object.entries(node.data.config.config.args ?? {}).reduce(
        (d, [k, v]) => {
          if (typeof v.value === 'object') d[k] = { column: v?.value?.value }
          else d[k] = { constant: v?.value }
          return d
        },
        {},
      ),
    },
  }
}

async function serializePublic(node) {
  return {
    metadata: node,
    step_type: 'public_csv',
    config: {
      url: node.data.config.selectedCSV.link,
    },
  }
}

async function serializeAITransform(node) {
  return {
    metadata: node,
    step_type: 'ai_transform',
    config: {
      input: node.data.config.input,
      prompt: node.data.config.prompt,
      code: node.data.config.code,
    },
  }
}

export async function serialize(node, userData, onProgress, options) {
  if (!node.data.valid)
    return {
      metadata: node,
    }

  switch (node.type) {
    case 'Stored data':
      return serializeStoreData(node)
    case 'Upload local file':
      return serializeUploadLocalFile(node, userData, onProgress, options)
    case 'Google Sheets':
      return serializeGsheets(node)
    case 'Database':
      return serializeDatabase(node)
    case 'Mongo':
      return serializeMongo(node)
    case 'Dataslayer AI':
      return serializeDataslayer(node)
    case 'General API':
      return serializeAPI(node)
    case 'Duckdb':
      return serializeDuckdb(node)
    case 'Merge':
      return serializeMerge(node)
    case 'Filter':
      return serializeFilter(node)
    case 'Transform':
      return serializeTransform(node)
    case 'Concatenate':
      return serializeConcat(node)
    case 'Aggregate':
      return serializeAggregate(node)
    case 'Change types':
      return serializeChangeType(node)
    case 'Enrich data':
      return serializeEnrich(node)
    case 'Public Datasets':
      return serializePublic(node)
    case 'LLM transform':
      return serializeAITransform(node)
    default:
      return null
  }
}

const sourceKeys = ['left', 'right', 'input', 'first', 'second']

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function waitCSVImport(csvIds, userData, retries = 5) {
  if (!Array.isArray(csvIds) || !csvIds.length) return true

  const csvResults = await getAllCSV(userData.token, userData.signout)

  if (!Array.isArray(csvResults))
    if (retries > 0) {
      await sleep(2000)
      return await waitCSVImport(csvIds, userData, retries - 1)
    } else return false

  const csvs = csvResults.reduce((ac, c) => {
    ac[c.id] = c.status
    return ac
  }, {})

  if (csvIds.some((id) => csvs[id] === 'importing')) {
    await sleep(2000)
    return await waitCSVImport(csvIds, userData, retries)
  }

  return true
}

export async function validateStructure({
  nodes,
  edges,
  target,
  userData,
  onProgress,
  options = {},
}) {
  nodes = nodes?.filter((n) => n?.type !== 'Config') ?? []
  const result = {
    structure: null,
    error: true,
    target: 0,
    errors: [],
  }

  const idToNode = nodes.reduce((ac, n) => {
    ac[n.id] = n
    return ac
  }, {})

  const targetNode = nodes.find((n) => n.id === target)
  if (!targetNode) {
    result.errors.push('Output node not found, select a valid node as output.')
    return result
  }

  const connection = edges.find(
    (e) => e.target === target && idToNode[e.source],
  )
  if (!connection) {
    result.errors.push(
      'Output node not selected, hover over your output node and select it first.',
    )
    return result
  }

  const structure = []
  const currentNodes = [connection.source]
  while (currentNodes.length) {
    const nodeName = currentNodes.shift()
    const node = idToNode[nodeName]
    if (!node.data.valid) {
      const nodeName = node.data?.userText ?? node.data?.subtitle ?? node.type
      result.errors.push(`Node ${nodeName} is invalid`)
      return result
    }

    const serializedNode = await serialize(node, userData, onProgress, options)
    if (!serializedNode) {
      const nodeName = node.data?.userText ?? node.data?.subtitle ?? node.type
      result.errors.push(`Node ${nodeName} is invalid`)
      return result
    }
    sourceKeys.forEach((key) => {
      if (serializedNode?.config?.[key])
        currentNodes.push(serializedNode.config[key])
    })
    structure.push(serializedNode)
  }

  {
    const structureIds = new Set(structure.map((s) => s.metadata.id))
    const remainingNodes = nodes.filter(
      (n) => !structureIds.has(n.id) && n.id !== target,
    )
    await Promise.all(
      remainingNodes.map(async (n) =>
        structure.push(await serialize(n, null, onProgress, options)),
      ),
    )
  }

  const idToIndex = structure.reduce((ac, s, i) => {
    ac[s.metadata.id] = i
    return ac
  }, {})

  structure.forEach((s, i) => {
    sourceKeys.forEach((key) => {
      if (s?.config?.[key]) {
        s.config[key] = idToIndex[s.config[key]]
      }
    })
  })

  const csvs = structure.map((e) => e?.config?.csv_id).filter((e) => e)
  const successImports = await waitCSVImport(csvs, userData)
  if (!successImports) {
    result.errors.push('CSV import failed')
    return result
  }

  result.error = false
  result.structure = structure
  return result
}

const invalidSteps = new Set([])

function trimStructure(structure) {
  const index = structure.output_index
  const objects = structure.objects
  const outputObject = objects[index]
  if (!outputObject) return null

  if (invalidSteps.has(outputObject.step_type)) {
    const newSource = outputObject?.config?.input
    if (!objects[newSource]) return null
    const newObjects = [...objects]
    newObjects.splice(index, 1, null)
    return { index: newSource, objects: newObjects }
  }

  return structure
}

export function recoverFlow(structure) {
  structure = trimStructure(structure)
  if (!structure)
    return {
      nodes: [],
      edges: [],
    }

  const metanodes = new Set(
    structure.objects
      .map((n, i) => (n?.metadata?.metanode ? i : null))
      .filter((n) => n !== null),
  )

  structure.objects.forEach((n) => {
    ;['left', 'right', 'input'].forEach((k) => {
      if ((n.config[k] || n.config[k] === 0) && metanodes.has(n.config[k]))
        n.config[k]--
    })
  })
  const outId = structure.objects[structure.output_index].metadata.id

  const nodes = structure.objects.map((s) => s.metadata)

  const edges = structure.objects
    .map((o, i) => {
      const { left, right, input } = o.config
      const res = []
      if (left || left === 0)
        res.push({
          source: nodes[left].id,
          target: nodes[i].id,
          sourceHandle: `${nodes[left].id}_source`,
          targetHandle: `${nodes[i].id}_left_target`,
        })
      if (right || right === 0)
        res.push({
          source: nodes[right].id,
          target: nodes[i].id,
          sourceHandle: `${nodes[right].id}_source`,
          targetHandle: `${nodes[i].id}_right_target`,
        })
      if (input || input === 0)
        res.push({
          source: nodes[input].id,
          target: nodes[i].id,
          sourceHandle: `${nodes[input].id}_source`,
          targetHandle: `${nodes[i].id}_uniq_target`,
        })
      return res
    })
    .flat()
  edges.push({
    source: outId,
    target: 'output',
    sourceHandle: `${outId}_source`,
    targetHandle: `output_target`,
  })
  return { nodes, edges }
}

function deserializeStoreData(node) {
  return {
    type: 'Stored data',
    data: {
      config: {
        selectedCsv: node.config.csv_id,
      },
    },
  }
}

function deserializeGsheets(node) {
  return {
    type: 'Google Sheets',
    data: {
      config: {
        ...node.config,
      },
    },
  }
}

function deserializeDatabase(node) {
  return {
    type: 'Database',
    data: {
      config: {
        ...node.config.uploadCSVFromBBDD,
      },
    },
  }
}

function deserializeMongo(node) {
  return {
    type: 'Mongo',
    data: {
      config: {
        ...node.config,
      },
    },
  }
}

const dataslayerQuery = new RegExp('^https?://query-manager.dataslayer.ai')
function deserializeAPI(node) {
  return {
    type: dataslayerQuery.test(node.config.url)
      ? 'Dataslayer AI'
      : 'General API',
    data: {
      config: {
        ...node.config,
        link: node.config.url,
      },
    },
  }
}

function deserializePublic(node) {
  return {
    type: 'Public Datasets',
    data: {
      config: {
        ...node.config,
      },
    },
  }
}

export function deserialize(node) {
  switch (node.step_type) {
    case 'file':
      return deserializeStoreData(node)
    case 'spreadsheet':
      return deserializeGsheets(node)
    case 'database':
      return deserializeDatabase(node)
    case 'mongo':
      return deserializeMongo(node)
    case 'api':
      return deserializeAPI(node)
    case 'public_csv':
      return deserializePublic(node)
    default:
      return null
  }
}

export function createTemporalNode({ createNewNode, x, y }) {
  return createNewNode({
    type: 'Config',
    position: { x, y },
    additionalConfig: {},
    valid: true,
    forceActive: false,
    dataProps: {
      subtitle: 'Loading,',
      placeholderNode: true,
    },
  })
}

export async function createMerge({
  files,
  existingNodes,
  event,
  reactFlowInstance,
  reactFlowBounds,
  xoffset,
  yoffset,
  createNewNode,
  setNodes,
  edges,
  setEdges,
  token,
  signout,
  triedMerges,
}) {
  let temporaryNodes = []
  if (existingNodes) {
    triedMerges.current[
      `merge-${existingNodes[0].id}-${existingNodes[1].id}`
    ] = true
    triedMerges.current[
      `merge-${existingNodes[1].id}-${existingNodes[0].id}`
    ] = true
  } else {
    for (let i = 0; i < 2; i++) {
      temporaryNodes.push(
        createTemporalNode({
          createNewNode,
          ...reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - (xoffset || 0),
            y: event.clientY - reactFlowBounds.top - (yoffset || 0) + i * 250,
          }),
        }),
      )
    }
  }
  const uploads = existingNodes
    ? [
        {
          columns: existingNodes[0]?.data?.sample?.columns,
          data: existingNodes[0]?.data?.sample?.sample,
        },
        {
          columns: existingNodes[1]?.data?.sample?.columns,
          data: existingNodes[1]?.data?.sample?.sample,
        },
      ]
    : await Promise.all(
        files.map((csv_file) =>
          awaitTaskCall(checkFile, 1000, null, {
            head: 30,
            csv_file,
            signout,
            token,
          }),
        ),
      ).catch((e) => [])
  if (
    uploads?.length &&
    uploads.every((r) => r?.columns && (r?.data || r?.sample))
  ) {
    const nodes = uploads.map((upload, i) => {
      if (existingNodes) return existingNodes[i]

      const file = files[i]
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - (xoffset || 0),
        y: event.clientY - reactFlowBounds.top - (yoffset || 0) + i * 250,
      })
      return createNewNode({
        type: 'Upload local file',
        position,
        additionalConfig: {
          fileToUpload: file,
        },
        valid: true,
        forceActive: false,
        dataProps: {
          subtitle: file.name,
          sample: {
            columns: upload.columns,
            sample: upload.data,
          },
        },
      })
    })
    if (temporaryNodes?.length) {
      const ids = new Set(temporaryNodes.map((n) => n.id))
      setNodes((n) => n.filter((node) => !ids.has(node.id)))
    }
    triedMerges.current[`merge-${nodes[0].id}-${nodes[1].id}`] = true
    triedMerges.current[`merge-${nodes[1].id}-${nodes[0].id}`] = true
    const suggestion = await awaitTaskCall(mergeSuggestion, 1000, null, {
      df_1: uploads[0],
      df_2: uploads[1],
      signout,
      token,
    }).catch((e) => null)
    if (suggestion?.valid && suggestion.valid !== 'False') {
      const position = existingNodes
        ? {
            x: Math.max(...existingNodes.map((n) => n.position.x)) + 250,
            y: (existingNodes[0].position.y + existingNodes[1].position.y) / 2,
          }
        : reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - (xoffset || 0) + 200,
            y: event.clientY - reactFlowBounds.top - (yoffset || 0) + 125,
          })
      const mergeNode = createNewNode({
        type: 'Merge',
        position,
        additionalConfig: {
          avoidDuplicatesLeft: true,
          avoidDuplicatesRight: true,
          columnsPaired: [[suggestion?.left, suggestion?.right]],
          joinType: suggestion.join_type,
          left: nodes[0]?.id,
          right: nodes[1]?.id,
          visibleColumns: [],
        },
        valid: true,
        forceActive: false,
        dataProps: {
          subtitle: 'Merge suggestion',
          sample: null,
        },
      })
      const nid1 = nodes[0]?.id
      const nid2 = nodes[1]?.id
      const nid3 = mergeNode?.id
      setEdges((e) => [
        ...e,
        {
          id: `reactflow__edge-${nid1}${nid1}_source-${nid3}${nid3}_left_target`,
          source: `${nid1}`,
          sourceHandle: `${nid1}_source`,
          target: `${nid3}`,
          targetHandle: `${nid3}_left_target`,
        },
        {
          id: `reactflow__edge-${nid2}${nid2}_source-${nid3}${nid3}_right_target`,
          source: `${nid2}`,
          sourceHandle: `${nid2}_source`,
          target: `${nid3}`,
          targetHandle: `${nid3}_right_target`,
        },
      ])
      if (!edges.some((e) => e?.target === 'output')) {
        setEdges((e) => [
          ...e,
          {
            id: `reactflow__edge-${nid3}${nid3}_source-outputoutput _target`,
            source: `${nid3}`,
            sourceHandle: `${nid3}_source`,
            target: `output`,
            targetHandle: `output_target`,
          },
        ])
      }
      awaitTaskCall(checkMerge, 2000, null, {
        left: uploads[0],
        right: uploads[1],
        join_type: suggestion.join_type,
        left_columns: [suggestion.left],
        right_columns: [suggestion.right],
        visible_columns: [],
        token,
        signout,
      }).then((r) => {
        if (r?.columns && r?.data)
          mergeNode.data.sample = {
            columns: r.columns,
            sample: r.data,
          }
      })
      return true
    }
  }
  return false
}
