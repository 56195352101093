import React from 'react'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import IntersectionWrapper from '../../intersection-wrapper/IntersectionWrapper'

import MMMTable from '../../mmm/Table'
import ChannelSpendChange from '../../mmm/ChannelSpendChange'
import MMMPerformance from '../../mmm/MMMPerformance'
import MMMInsightHeading from './MMMInsightHeading'
import { useMMMProviderExport } from './MMMProvideExport'
import { BouncyLoader } from '../../utils/ui'

export default function MMMInsights({ currentTrainingConfig, activeModel }) {
  const { t } = useTranslation()
  const { exportGsheets, exportExcel, exportPP, isLoading } =
    useMMMProviderExport()

  return (
    <Row className="justify-content-center mx-xl-5 mx-lg-3 mx-md-0">
      <Col className="mb-4 ps-0" xs={12}>
        <Row>
          <Col className=" position-relative" xs="auto">
            <Dropdown className="export-mmm-drop">
              <Dropdown.Toggle className="py-1 btn" disabled={isLoading}>
                <strong
                  className=" d-inline-block"
                  style={{
                    color: 'var(--nextbrain-white-font)',
                    minWidth: '200px',
                  }}
                >
                  {isLoading ? (
                    <BouncyLoader className="my-2" />
                  ) : (
                    t('Export report')
                  )}
                </strong>
              </Dropdown.Toggle>
              <Dropdown.Menu className="">
                <Dropdown.Item
                  className=""
                  onClick={exportGsheets}
                  disabled={isLoading}
                >
                  {t('Export to Google Sheets')}
                </Dropdown.Item>
                <Dropdown.Item
                  className=""
                  onClick={exportExcel}
                  disabled={isLoading}
                >
                  Export Excel
                </Dropdown.Item>
                <Dropdown.Item
                  className=""
                  onClick={exportPP}
                  disabled={isLoading}
                >
                  Export Powerpoint
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <MMMInsightHeading
        title="Current scenario optimization"
        description={t(
          'Could we improve our outcomes while spending the same amount of money by optimizing the Media Mix in the input data?',
        )}
        className="mt-1 ps-0 heading-scenario-optimization"
        isBold={false}
      />
      <Col style={{ minHeight: '400px' }} xs={12}>
        <IntersectionWrapper
          component={ChannelSpendChange}
          visibleProps={{
            isInView: true,
          }}
          hiddenProps={{
            isInView: false,
          }}
          viewProps={{ amount: 0.3, once: true }}
        />
      </Col>
      <MMMInsightHeading
        title="Media mix model"
        description="Media mix distribution, spend, outcomes and cost per spend unit (CPx)"
        className="mt-4 mb-1 ps-0"
      />
      <Col className="mt-1 mt-auto mb-auto ps-0" xs={12}>
        <Row>
          <Col xs={12}>
            <MMMTable maxHeight={350} model={activeModel} />
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-start mt-2" xs={12}>
        <MMMPerformance mode={activeModel} />
      </Col>
    </Row>
  )
}
