import { ResponsiveHeatMap } from '@nivo/heatmap'
import { Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

const DEFAULT_DATA = []

export default function CustomHeatmap({
  data,
  header,
  target = 'Target',
  height = 475,
  className = '',
  forceSquare = true,
}) {
  const { t } = useTranslation()
  return (
    <div className={className}>
      {header ? header : <></>}
      <Row className="mt-2" style={{ height: `${height}px` }}>
        <ResponsiveHeatMap
          data={data ? data : DEFAULT_DATA}
          margin={{ top: 50, right: 50, bottom: 0, left: 70 }}
          valueFormat=">-1.2~f"
          hoverTarget={data.length <= 2 ? 'cell' : 'rowColumn'}
          forceSquare={forceSquare}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: `${target} ${t('predicted')}`,
            legendOffset: -40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${target} ${t('original')}`,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          colors={{
            type: 'diverging',
            scheme: 'purple_blue',
            minValue: 0,
            maxValue: 100,
            divergeAt: 0.45,
          }}
          emptyColor="#555555"
          inactiveOpacity={0.25}
          labelTextColor={(d) => (d.value > 50 ? '#fff' : '#000')}
          legends={[]}
          annotations={[]}
        />
      </Row>
    </div>
  )
}
