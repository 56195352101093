import { useState } from 'react'
import { Accordion, Row, Col } from 'react-bootstrap'
import { RiArrowRightSLine } from 'react-icons/ri'

import './SingleAccordion.css'

export default function SingleAccordion({
  Control = RiArrowRightSLine,
  title = '',
  defaultState = true,
  disabled = false,
  forceStatus,
  children,
  header = true,
  headerProps = {},
  bodyProps = {},
  defaultStyles = 'ms-2 p-2',
  itemProps = {},
  ...props
}) {
  const [active, setActive] = useState(defaultState)
  let status = active
  forceStatus = typeof forceStatus === 'function' ? forceStatus() : forceStatus

  if (forceStatus !== undefined && forceStatus !== null) {
    status = forceStatus
  }

  const DummyHeader = () => (
    <Row
      className={`single-accordion-control ${defaultStyles} ${
        disabled || (forceStatus !== undefined && forceStatus !== null)
          ? 'disabled'
          : ''
      } status-${status ? 'active' : 'inactive'} ${
        headerProps?.className ?? ''
      }`}
      onClick={() => setActive((a) => !a)}
    >
      <Col className="single-accordion-title m-0 p-0 h5">{title}</Col>
      <Col className="ms-4 p-0 single-accordion-icon">
        <Control size={30} />
      </Col>
    </Row>
  )

  return (
    <Accordion
      {...props}
      activeKey={status ? '0' : null}
      className={`${props.className ?? ''} single-accordion-component`}
    >
      <Accordion.Item eventKey="0" {...itemProps}>
        {header && <Accordion.Header as={DummyHeader}></Accordion.Header>}
        <Accordion.Body {...bodyProps}>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
