import { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './PbarProgress.css'
import Loading from '../loading/LoadingSmall'

function Progress({ steps, status, eta, description }) {
  const [current, setCurrent] = useState(status)
  useEffect(() => {
    if (current < status) {
      const timeout = setTimeout(() => setCurrent((c) => c + 1), 50)
      return () => clearTimeout(timeout)
    }
  }, [current, status])
  const { t } = useTranslation()
  const w = 80 / steps
  const cssw = `max(${w}% , 10px)`
  return (
    <Row className="position-relative w-100">
      <Col className="ms-2" xs={12}>
        <Loading message={`${t('Step')} ${current + 1}/${steps}`} />
      </Col>
      {description && (
        <Col
          className="mt-2 text-center w-100 d-inline-block text-truncate"
          xs={12}
        >
          {description}
        </Col>
      )}
      {/* <Col className="text-center mt-2 smallp" xs={12}>
        {round(eta, 2)} {t('estimated seconds remaining')}
      </Col> */}
      <Col className="position-relative dflex-center mt-4 w-100" xs={12}>
        {new Array(steps).fill(0).map((_, i) => (
          <span
            key={i}
            className={`pbar-step ${i === current ? 'current-pbar-step' : ''} ${
              i < current ? 'active-pbar-step' : ''
            }`}
            style={{ maxWidth: `${cssw}`, minWidth: `${cssw}` }}
          ></span>
        ))}
      </Col>
      <Col className="text-center mt-3 smallp" xs={12}>
        {t('Please wait, the data is loading and it may take a while.')}
        <br />
        {t(
          'You can close this window and we will notify you by email when it has been successfully loaded.',
        )}
      </Col>
    </Row>
  )
}

export default function PbarProgress({ model }) {
  return (
    <Progress
      description={model?.pbar_data?.description ?? ''}
      steps={model?.pbar_data?.total ?? 10}
      status={model?.pbar_data?.n ?? 1}
      eta={model?.pbar_data?.estimated_remaining_time ?? 60}
    />
  )
}
