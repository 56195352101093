import React, { useState } from 'react'

import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './Guides.css'
export default function VideoCard({
  url,
  name,
  created,
  difficulty,
  description,
  industry,
  problem_type,
  active,
  ...props
}) {
  const { t } = useTranslation()
  const [showPopup, setShowPopup] = useState(false)

  if (url === null || url === undefined) return <></>

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <Row {...props} className="videoCard mb-5">
      <Row className="videoCard-embed" onClick={openPopup}>
        <div style={{ position: 'relative', width: '740px', height: '280px' }}>
          <iframe
            className="videoCard-block"
            width="740"
            height="280"
            src={url?.replace('watch?v=', 'embed/')}
            title={name}
            //eslint-disable-next-line
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div
            style={{
              position: 'absolute',
              bottom: -5,
              right: 20,
            }}
          >
            <p style={{ color: '#fff', fontSize: '12px' }}>
              {created.split('T')[0]}
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              left: 15,
            }}
          >
            <p className={`mx-2 tag ${difficulty}`}>
              {difficulty !== 'unknown' ? t(difficulty) : ''}
            </p>
          </div>
        </div>
        {showPopup && (
          <VideoPopup url={url} name={name} closePopup={closePopup} />
        )}
      </Row>

      <Row className="videoCard-description ">
        <p className="mx-2">{description} </p>
      </Row>
      <Row className="videoCard-tags ">
        <p className={'mx-2 tag'}>
          {industry !== 'unknown' ? t(industry) : ''}
        </p>
        <p className={'mx-2 tag problem-type'}>
          {problem_type !== 'unknown' ? t(problem_type) : ''}
        </p>
      </Row>
    </Row>
  )
}

function VideoPopup({ url, name, closePopup }) {
  const handlePopupClick = (e) => {
    e.stopPropagation()
  }

  const handleOverlayClick = (e) => {
    closePopup()
    e.stopPropagation()
  }
  return (
    <div className="videoPopup" onClick={handleOverlayClick}>
      <div className="videoPopup-content" onClick={handlePopupClick}>
        <iframe
          width="700"
          height="400"
          src={url?.replace('watch?v=', 'embed/') + '?autoplay=1'}
          title={name}
          //eslint-disable-next-line
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
