import React, { useState } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { pruneAdmin, confirmPruneAdmin } from '../../services/user'
import Loading from '../loading/LoadingSmall'
import { NotificationManager } from 'react-notifications'

export default function PruneUsers({ onHide }) {
  const { token, signout } = useAuth()
  // eslint-disable-next-line
  const [inactiveSince, setInactiveSince] = useState(() =>
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 6).toISOString(),
  )
  const [keepUserModels, setKeepUserModels] = useState({})

  const { data: inactiveUsers, isLoading } = useQuery(
    ['inactiveUsers', inactiveSince],
    async () => {
      return await pruneAdmin({
        config: {
          inactive_time: new Date(
            Date.now() - 1000 * 60 * 60 * 24 * 30 * 6,
          ).toISOString(),
        },
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  return (
    <Modal show={true} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Inactive users for over 6 months</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>Keep models</Col>
          {isLoading && (
            <Col xs={12}>
              <Loading />
            </Col>
          )}
          {inactiveUsers?.map?.((u) => (
            <Col xs={12}>
              <Form.Check
                type="checkbox"
                className="d-flex align-items-center ms-4"
                label={<span className="ms-5">{u?.email}</span>}
                checked={keepUserModels[u?.id]}
                onChange={(e) => {
                  setKeepUserModels({
                    ...keepUserModels,
                    [u?.id]: e.target.checked,
                  })
                }}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col xs={12}>
            <Button
              disabled={!Array.isArray(inactiveUsers) || !inactiveUsers.length}
              onClick={() => {
                const users = inactiveUsers.filter(
                  (u) => !keepUserModels[u?.id],
                )
                if (!users.length) {
                  NotificationManager.error('No users selected')
                  return
                }
                const userIds = users.map((u) => u?.id)
                NotificationManager.info("Pruning users' models")
                confirmPruneAdmin({
                  userIds,
                  token,
                  signout,
                }).then(() => {
                  NotificationManager.success("Users' models pruned")
                })
              }}
            >
              Prune user's models
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
