import { useEffect } from 'react'
import { deleteUser } from '../../services/user'
import { useAuth } from '../../providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function DeleteAccount({ ...props }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    deleteUser(token, signout).then((d) => {
      signout()
      navigate('/')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{t('Deleting...')}</>
}
