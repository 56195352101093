import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useModels } from '../../providers/ModelProvider'
import { useTranslation } from 'react-i18next'

export default function RoleDisable({
  message = 'Disabled in viewer mode',
  children,
  className = 'd-inline-block',
  opacity = 50,
  screen = false,
  ...props
}) {
  const { role } = useModels()
  const { t } = useTranslation()

  if (role !== 'editor') {
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={'hover'}
        placement="auto"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => <Tooltip {...props}>{t(message)}</Tooltip>}
      >
        <div className={`${className} position-relative`} {...props}>
          {screen && (
            <div
              className="screen position-absolute"
              style={{
                width: '100%',
                height: '100%',
                zIndex: 2,
                top: 0,
                left: 0,
              }}
            ></div>
          )}
          <span className={`pe-none opacity-${opacity}`}>{children}</span>
        </div>
      </OverlayTrigger>
    )
  }
  return children
}
