import { forwardRef, useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'
import CrashFallback from '../crash-fallback/CrashFallback'
import ErrorNB from '../loading/ErrorNB'
import { useTranslation } from 'react-i18next'

const Div = forwardRef(({ children, ...props }, ref) => {
  return (
    <div className="h-100" ref={ref} {...props}>
      {children}
    </div>
  )
})

export const intersectionBehavior = Object.freeze({
  ONCE: 'once',
  ALWAYS: 'always',
})

export default function IntersectionWrapper({
  As = Div,
  period = intersectionBehavior.ONCE,
  onView = null,
  onHide = null,
  visibleProps = null,
  hiddenProps = null,
  viewProps = {},
  children,
  component = null,
  ...props
}) {
  const { t } = useTranslation()
  const ref = useRef()
  const state = useRef({
    triggerView: 0,
    triggerHide: 0,
    container: ref,
  })

  const inView = useInView(ref, viewProps)
  const _props = (inView ? visibleProps : hiddenProps) ?? props
  useEffect(() => {
    if (inView && onView) {
      if (period === intersectionBehavior.ONCE && state.current.triggerView)
        return
      state.current.triggerView++
      onView(state.current)
    } else if (!inView && onHide) {
      if (period === intersectionBehavior.ONCE && state.current.triggerHide)
        return
      state.current.triggerHide++
      onHide(state.current)
    }
    // eslint-disable-next-line
  }, [inView])

  const Component = component
  return (
    <CrashFallback message={<ErrorNB />} t={t}>
      <As ref={ref} {...(Component ? {} : _props)}>
        {Component ? <Component {..._props} /> : children}
      </As>
    </CrashFallback>
  )
}
