import React, { useState, useRef } from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { BsTrash, BsPencil } from 'react-icons/bs'
import {
  FaRegStopCircle,
  FaFileDownload,
  FaRegFolderOpen,
} from 'react-icons/fa'
import {
  deleteModel as deleteModelApi,
  reportIssue,
  stopTraining,
  downloadDataset,
} from '../../services/model'
import { changeDatasetName } from '../../services/dataset'
import { useAuth } from '../../providers/AuthProvider'
import CustomPopover from '../popover/popover'
import CustomModal from '../modal/modal'
import ModelStatusIcon from '../model/ModelStatusIcon'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { testAccuracy } from '../../util/models'
import { NotificationManager } from 'react-notifications'
import { InlineEdit } from '../inline-edit/InlineEdit'
import 'bootstrap/dist/css/bootstrap.css'
import { useNavigate } from 'react-router-dom'
import MoveProjectModel from './move-project-modal'
import { defaultFormat } from '../utils/formating'
import RoleDisable from '../utils/RoleDisable'

const status = { importing: 'Importing', readyToImport: 'Importing' }
function GetStatusTooltip({ model }) {
  const result = []

  result.push(
    status[model.status] ??
      model.status.charAt(0).toUpperCase() + model.status.slice(1),
  )
  result.push(testAccuracy(model))
  if (result[0] === 'Trained') result[0] = 'Analyzed'
  let accuracy = result[1]
  if (model.special_model_type === 'mmm') accuracy = testAccuracy(model)

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ModelStatusIcon
          className="model-status-icon"
          style={{ left: '13px' }}
          status={model.status}
        />
      </div>
      <Col xs={'auto'} style={{ marginLeft: 20 }}>
        {result[0]}
      </Col>
      {result[1] ? (
        <Col className="px-0 mx-0" xs={'auto'}>
          <span style={{ color: result[1] > 40 ? '#3ec73e' : '#DA5B0C' }}>
            <strong>{accuracy}%</strong>
          </span>
        </Col>
      ) : (
        <></>
      )}
    </>
  )
}

export function ModelPopoverContent(
  model,
  setModel,
  onDelete,
  editName,
  moveToProject,
  isReported,
  onReport,
  fullyLoaded = true,
) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()

  const lstopTraining = () => {
    $('body').click()
    stopTraining({ modelId: model.id, token, signout }).then(() => {
      NotificationManager.info(t('The model will stop training shortly'))
      model.status = 'created'
      setModel({ ...model })
    })
  }

  const name = (fullyLoaded ? model?.dataset?.name : model?.name) ?? 'dataset'

  return (
    <>
      <Row
        onMouseUp={(e) => {
          e.stopPropagation()
          $('body').click()
        }}
        align="center"
        style={{ width: '210px' }}
      >
        {fullyLoaded && (
          <RoleDisable className="px-0">
            <Col className="ps-0" align="left" xs={12}>
              <div
                style={{ cursor: 'pointer', height: '25px' }}
                className="menu-models-text d-inline-block text-truncate"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  editName()
                }}
              >
                <BsPencil style={{ marginLeft: '5px' }} />{' '}
                <span style={{ marginLeft: '5px', fontSize: '.875rem' }}>
                  {t('Edit')}
                </span>
              </div>
            </Col>
          </RoleDisable>
        )}
        <Col className="ps-0" align="left" xs={12}>
          <div
            style={{ cursor: 'pointer', height: '25px' }}
            className="menu-models-text d-inline-block text-truncate"
            onClick={(e) => {
              downloadDataset({ modelId: model.id, token, signout })
                .then((response) => response.blob())
                .then((blob) => {
                  // Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob]))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', name + '.csv')

                  // Append to html link element page
                  document.body.appendChild(link)

                  // Start download
                  link.click()

                  // Clean up and remove the link
                  link.parentNode.removeChild(link)
                })
            }}
          >
            <FaFileDownload style={{ marginLeft: '5px' }} />{' '}
            <span style={{ marginLeft: '5px', fontSize: '.875rem' }}>
              {t('Download original data')}
            </span>
          </div>
        </Col>
        <RoleDisable className="px-0">
          <Col className="ps-0" align="left" xs={12}>
            <div
              style={{ cursor: 'pointer' }}
              className="menu-models-text d-inline-block text-truncate"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                moveToProject()
              }}
            >
              <FaRegFolderOpen style={{ marginLeft: '5px' }} />{' '}
              <span style={{ marginLeft: '5px', fontSize: '.875rem' }}>
                {t('Move to another workspace')}
              </span>
            </div>
          </Col>
        </RoleDisable>
        <RoleDisable className="px-0">
          <Col
            align="left"
            className="d-flex align-items-end ps-0"
            style={{ marginTop: '5px' }}
            xs={12}
          >
            <span className=" detailed-text" style={{ marginLeft: '3px' }}>
              {
                <CustomModal
                  title={`Delete "${name}" model`}
                  useButton={false}
                  btn={
                    <div
                      className="menu-models-text"
                      onClick={(e) => {
                        if (e.ctrlKey) {
                          e.stopPropagation()
                          e.preventDefault()
                          onDelete(model.id)
                          deleteModelApi(model.id, token, signout)
                        }
                      }}
                    >
                      <BsTrash className="cursor-pointer overlay-details" />
                      <span style={{ marginLeft: '10px', fontSize: '.875rem' }}>
                        {t('Delete model')}
                      </span>
                    </div>
                  }
                  confirmText={t('Delete')}
                  confirmProps={{
                    variant: 'danger',
                    className: 'original',
                  }}
                  showVariant={null}
                  confirmCallback={() => {
                    onDelete(model.id)
                    deleteModelApi(model.id, token, signout)
                  }}
                >
                  {t(
                    'Once you delete it, there is no going back. Please be certain.',
                  )}
                </CustomModal>
              }
            </span>
          </Col>
        </RoleDisable>
        {model.status === 'training' && fullyLoaded ? (
          <RoleDisable>
            <Col
              className="mt-3 d-flex align-items-center"
              align="left"
              xs={12}
            >
              <div
                onClick={lstopTraining}
                style={{ cursor: 'pointer', height: '25px' }}
                className="menu-models-text d-inline-block text-truncate"
              >
                <FaRegStopCircle size={20} style={{ marginLeft: '2px' }} />{' '}
                <span style={{ marginLeft: '5px', fontSize: '.875rem' }}>
                  {t('Stop training')}
                </span>
              </div>
            </Col>
          </RoleDisable>
        ) : (
          <></>
        )}
      </Row>
    </>
  )
}

export default function ItemModel({
  model,
  projectName,
  projectID,
  onUpdate,
  onDelete,
  navigateProject = true,
  fullyLoaded = true,
  mode = 'automl',
  ...props
}) {
  const ref = useRef()
  const reportRef = useRef()
  const [isReported, setIsReported] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const { token, signout } = useAuth()
  const navigate = useNavigate()
  const [moveToProject, setMoveToProject] = useState(false)
  const { t } = useTranslation()

  let isWorkspace = window.location.pathname.includes(`/model/${projectID}`)

  let updated = model.updated
    ? new Date(model.updated)
    : new Date(model.created)
  if (isNaN(updated)) updated = new Date()
  const dateStr = `${updated.getDate()} ${updated.toLocaleString('default', {
    month: 'short',
  })} ${updated.getFullYear()}`

  let type = 'automl'
  if (
    model.special_model_type === 'mmm' ||
    (model.status !== 'trained' && mode === 'mmm')
  )
    type = 'mmm'
  return (
    <>
      <Row
        {...props}
        className={`${
          props?.className ?? ''
        } model-card-type-${type} color-white`}
        key={'content'}
      >
        <Row className="justify-content-between w-100 px-0 mx-0 mt-2">
          {fullyLoaded ? (
            <Col
              title={model?.dataset?.name}
              xs={11}
              className="model-name-card align-self-center text-truncate px-0 mx-0"
            >
              <strong
                style={{ cursor: 'text' }}
                className={`${model.placeholder ? 'pe-none' : ''}`}
                onMouseUp={(e) => {
                  e.stopPropagation()
                }}
              >
                <InlineEdit
                  ref={ref}
                  onEdit={(text) => {
                    model.dataset.name = text
                    onUpdate(model.id, model)
                    changeDatasetName(model.dataset.id, text, token, signout)
                  }}
                  text={
                    model?.dataset?.name?.[0]?.toUpperCase() +
                    model.dataset.name.substr(1)
                  }
                  onMouseUp={(e) => {
                    e.stopPropagation()
                  }}
                />
              </strong>
            </Col>
          ) : (
            <Col
              className="model-name-card align-self-center text-truncate px-0 mx-0"
              xs={11}
            >
              <strong>
                {model?.name?.[0]?.toUpperCase()}
                {model?.name?.substr(1)}
              </strong>
            </Col>
          )}
          <Col className="px-0 mx-0 float-right" xs={1}>
            <CustomPopover
              button={
                <BiDotsVerticalRounded
                  onClick={() => $('body').click()}
                  onMouseUp={(e) => {
                    e.stopPropagation()
                  }}
                  className="hover-link"
                  style={{ width: '30px', height: '30px' }}
                />
              }
              className="floating-box"
              placement="top"
            >
              {ModelPopoverContent(
                model,
                (updatedModel) => {
                  onUpdate(model.id, updatedModel)
                },
                onDelete,
                () => ref.current?.click(),
                () => setMoveToProject(true),
                isReported,
                () => setShowReport(true),
                fullyLoaded,
              )}
            </CustomPopover>
          </Col>
        </Row>
        {!isWorkspace ? (
          <Row
            xs={12}
            className={`foot-card justify-content-between navigate-project px-0 w-100 ${
              navigateProject ? '' : 'pe-none'
            }`}
            onMouseUp={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(
                `/model/${projectID}?wp-name=${encodeURIComponent(
                  projectName,
                )}`,
              )
            }}
          >
            <div style={{ position: 'relative' }}>
              <FaRegFolderOpen
                size={10}
                className="model-status-icon"
                style={{ left: '15px' }}
              />
            </div>
            <Col xs={'auto'} style={{ marginLeft: 20 }}>
              {projectName}
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row className="trainedaccuracy my-3 px-0 justify-content-between">
          <GetStatusTooltip model={model} />
        </Row>
        <Row className="foot-card justify-content-between px-0">
          <Col xs="auto" style={{ marginTop: 18, opacity: 0.6 }}>
            {dateStr}
          </Col>
          <Col xs="auto" className="pe-0">
            {model?.dataset?.rows && model?.dataset?.cols ? (
              <>
                <div style={{ width: 'fit-content', display: 'inline-block' }}>
                  <small style={{ opacity: 0.6 }}>rows</small>
                  <br />
                  <span>{defaultFormat({ num: model?.dataset?.rows })}</span>
                </div>
                <small className="mx-1">x</small>
                <div style={{ width: 'fit-content', display: 'inline-block' }}>
                  <small style={{ opacity: 0.6 }}>cols</small>
                  <br />
                  <span>{model?.dataset?.cols}</span>
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Row>
      <MoveProjectModel
        moveToProject={moveToProject}
        setMoveToProject={setMoveToProject}
        model={model}
      />
      <Modal centered show={showReport} onHide={() => setShowReport(false)}>
        <Modal.Header closeButton>
          <span className="h4">{t('Report issue')}</span>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-2">
            <Col md={12}>
              <textarea
                ref={reportRef}
                type="text"
                className="form-control nb-input"
                defaultValue=""
                placeholder={t('Details of your issue...')}
                style={{ height: '8rem' }}
                autoFocus
              />
            </Col>
            <Col md={12}>
              <span className="text-secondary small">
                {t('Submit issue conditions')}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="d-flex justify-content-end" xs={12}>
              <Button
                className="original"
                variant="danger"
                onClick={() => {
                  reportIssue({
                    modelId: model.id,
                    msg: reportRef.current.value,
                    token,
                    signout,
                  })
                  setIsReported(true)
                  setShowReport(false)
                  NotificationManager.info(
                    t(
                      'Your issue has been reported. The team will contact you soon.',
                    ),
                  )
                }}
              >
                {t('Send report')}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
