import React, { useState } from 'react'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { FaPlus, FaMinus } from 'react-icons/fa'
import './TreeSelect.css'

function TreeLine({
  name,
  checked = null,
  expand = null,
  status,
  onClick,
  disabled = false,
  ...props
}) {
  const Check = checked ? ImCheckboxChecked : ImCheckboxUnchecked
  const Expand = expand ? FaMinus : FaPlus

  return (
    <div
      onClick={onClick}
      {...props}
      className={`${disabled ? 'pe-none' : ' cursor-pointer'} tree-line  ${
        props.className ?? ''
      }`}
    >
      {checked !== null && (
        <span
          className={`me-1 tree-select-check tree-select-check-${
            checked ? 'checked' : 'unchecked'
          }`}
        >
          <Check />
        </span>
      )}
      {expand !== null && (
        <span
          className={`me-1 tree-select-expand tree-select-expand-${
            expand ? 'expanded' : 'collapsed'
          }`}
        >
          <Expand />
        </span>
      )}
      {disabled ? (
        <span className="d-inline-block text-nowrap">
          <u>
            <i>{name}</i>
          </u>
        </span>
      ) : (
        <span className="d-inline-block text-nowrap">{name}</span>
      )}
    </div>
  )
}

function TreeFrame({
  level = 0,
  activeNodes,
  setActiveNodes,
  idGen,
  name,
  path,
  keys = [],
  frame,
  config,
  collapse = true,
  onChange,
  onExpand,
  expand,
  ...props
}) {
  const [id] = useState(() => idGen())
  const [framekeys] = useState(() => [...keys, name])
  const [expanded, setExpanded] = useState(!!expand?.[id])
  const style = {
    ...(props.style ?? {}),
    marginLeft: `${level * config.levelPad}px`,
  }

  if (typeof frame === 'object' && frame !== null) {
    return (
      <>
        <TreeLine
          style={style}
          expand={expanded}
          name={name}
          onClick={(e) => {
            e.stopPropagation()
            onExpand({ [id]: !expanded })
            setExpanded((e) => !e)
          }}
          className={collapse ? 'collapse-tree-frame' : ''}
        />
        {Object.keys(frame).length ? (
          Object.entries(frame).map(([key, value], index) => (
            <TreeFrame
              key={index}
              level={level + 1}
              activeNodes={activeNodes}
              setActiveNodes={setActiveNodes}
              idGen={idGen}
              name={key}
              keys={framekeys}
              path={`${path}-${key}`}
              frame={value}
              config={config}
              collapse={collapse || !expanded}
              onChange={onChange}
              onExpand={onExpand}
            />
          ))
        ) : (
          <TreeLine
            name={'empty'}
            disabled={true}
            style={{ marginLeft: `${(level + 1) * config.levelPad}px` }}
            className={collapse || !expanded ? 'collapse-tree-frame' : ''}
          />
        )}
      </>
    )
  } else {
    return (
      <TreeLine
        style={{ marginLeft: `${level * config.levelPad}px` }}
        name={name}
        checked={!!activeNodes[path]}
        className={collapse ? 'collapse-tree-frame' : ''}
        onClick={(e) => {
          e.preventDefault()
          setActiveNodes((activeNodes) => {
            const newState = { ...activeNodes, [path]: !activeNodes[path] }
            onChange(newState, { path: framekeys, status: !activeNodes[path] })
            return newState
          })
        }}
      />
    )
  }
}

const defaultProps = Object.freeze({
  levelPad: 30,
})

export default function TreeSelect({
  frame,
  config = defaultProps,
  state,
  expand,
  onChange = () => {},
  onExpand = () => {},
}) {
  const [activeNodes, setActiveNodes] = useState(state || {})

  const [idGen] = useState(() => {
    let id = 1
    return () => id++
  })

  return Object.entries(frame).map(([key, value], index) => (
    <TreeFrame
      idGen={idGen}
      activeNodes={activeNodes}
      setActiveNodes={setActiveNodes}
      name={key}
      path={key}
      frame={value}
      key={index}
      config={config}
      collapse={false}
      expand={expand}
      onExpand={onExpand}
      onChange={onChange}
    />
  ))
}
