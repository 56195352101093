import { Image, Row, Col, Button } from 'react-bootstrap'

import './AssistantTooltips.css'

export function AssistantTooltips({ modalid, bottom = true, ...props }) {
  const title = [
    'What is machine learning?',
    'What is a model?',
    'What is a dataset',
    'What does it mean to train a model?',
    'What are predictions',
    'What is an advanced prediction?',
  ]
  const image = [
    '/Assistant/whatismachinelearning.svg',
    '/Assistant/model.svg',
    '/Assistant/dataset.svg',
    '/Assistant/model2.svg',
    '/Assistant/predictions.svg',
    '/Assistant/advancedprediction.svg',
  ]
  const desc = [
    'Machine learning (ML) is a type of artificial intelligence (AI) that allows software applications to become more accurate at predicting outcomes without being explicitly programmed to do so. Machine learning algorithms use historical data as input to predict new output values.',
    'A machine learning model is a program that can find patterns or make decisions from a previously unseen dataset. For example, in natural language processing, machine learning models can parse and correctly recognize the intent behind previously unheard sentences or combinations of words',
    'A dataset is the contents of a file that holds tabular data. NextBrain only works with tabular data, which is data structured in columns and rows, with each column representing a feature. In order to organize the data, the first row must contain the feature name.',
    "Once you have trained the model, you can use it to reason over data that it hasn't seen before, and make predictions about those data. For example, let's say you want to build an application that can recognize a user's emotions based on their facial expressions. You can train a model by providing it with images of faces that are each tagged with a certain emotion, and then you can use that model in an application that can recognize any user's emotion.",
    '“Prediction” refers to the output of an algorithm after it has been trained on a historical dataset and applied to new data when forecasting the likelihood of a particular outcome, such as whether or not a customer will churn in 30 days. The algorithm will generate probable values for an unknown variable for each record in the new data, allowing the model builder to identify what that value will most likely be.',
    'Instead of predicting a single target value by providing a value for each feature involved in the predictions, we can use a datafile (for example a spreadsheet file) containing tabular data with many rows and feature values to generate a prediction. We will get as many target predictions as there are rows in this file.',
  ]
  const learnmore = ['#', '#', '#', '#', '#', '#']

  return (
    <Row {...props} className={`assistantpopup ${props.className ?? ''}`}>
      <Col>
        <Row>
          <strong>{title[modalid]}</strong>
        </Row>
        <Row>
          <Image className="mt-4 mb-3" width="100%" src={image[modalid]} />
        </Row>
        <Row>
          <p className="smallp">{desc[modalid]}</p>
        </Row>
        {bottom ? (
          <Row>
            <Col md={6} className="ps-0 mt-2">
              <a href={learnmore[modalid]}>Learn more</a>
            </Col>
            <Col md={6} className="pe-0 text-right">
              <Button
                onClick={() => document.getElementsByTagName('body')[0].click()}
                className="p-2 float-end"
              >
                Close{' '}
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  )
}
