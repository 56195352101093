import React from 'react'
import { Row, Col } from 'react-bootstrap'

import HelpTooltip from './HelpTooltip'
import { useTranslation } from 'react-i18next'

export default function InsightHeading({
  title,
  description,
  message,
  help,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <>
      <Col
        xs={12}
        {...props}
        className={`insight-header ${props.className ?? ''}`}
      >
        <Row className="px-2">
          <Col className="h4 bold" xs={12}>
            <strong>{typeof title === 'string' ? t(title) : title}</strong>
            {message && help && (
              <HelpTooltip
                className="ms-1 help-select-icon"
                message={message}
                helpLink={help}
              />
            )}
          </Col>
          {description && (
            <Col className="text-secondary" xs={12}>
              {Array.isArray(description)
                ? description.map((d, i) => (
                    <Row key={i}>
                      <Col xs={12}>{t(d)}</Col>
                    </Row>
                  ))
                : t(description)}
            </Col>
          )}
        </Row>
      </Col>
    </>
  )
}
