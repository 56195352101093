import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Row, Col, Form, Modal } from 'react-bootstrap'
import { getRecentModels } from '../../services/model'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery, useInfiniteQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import { useNavigate } from 'react-router-dom'
import { FaArrowLeft, FaSearch } from 'react-icons/fa'
import { GrSearchAdvanced } from 'react-icons/gr'
import './SearchModal.css'
import { getAllWorkSpace } from '../../services/project'
import { testAccuracy } from '../../util/models'
import ModelStatusIcon from '../model/ModelStatusIcon'
import Loading from '../loading/LoadingSmall'
import IntersectionWrapper from '../intersection-wrapper/IntersectionWrapper'
import ModelTopView from '../model/ModelTopView'

export const StatusTooltip = ({ model }) => {
  const result = []
  const updated = model.updated
    ? new Date(model.updated)
    : new Date(model.created)
  const dateStr = `${updated.getDate()} ${updated.toLocaleString('default', {
    month: 'short',
  })} ${updated.getFullYear()}`

  result.push(
    { importing: 'Importing', readyToImport: 'Importing' }[model.status] ??
      model.status.charAt(0).toUpperCase() + model.status.slice(1),
  )
  result.push(
    Math.floor(
      Number.parseFloat(
        model.accuracy['Accuracy Binary']
          ? model.accuracy['Accuracy Binary'] * 100
          : model.accuracy['ExpVariance'] * 100,
      ),
    ),
  )

  let accuracy = result[1]
  if (model.special_model_type === 'mmm') accuracy = testAccuracy(model)

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ModelStatusIcon
          className="model-status-icon"
          style={{ left: '15px' }}
          status={model.status}
        />
      </div>
      <Col className="small" xs={'auto'} style={{ marginLeft: 20 }}>
        {result[0]}
      </Col>
      {result[1] ? (
        <Col className="px-0 mx-0 small" xs={'auto'}>
          <span style={{ color: result[1] > 40 ? '#3ec73e' : '#DA5B0C' }}>
            {accuracy}%
          </span>
        </Col>
      ) : (
        <></>
      )}
      <Col xs="auto" className="small">
        {dateStr}
      </Col>
    </>
  )
}

function SearchResult({
  projectName,
  projectId,
  models,
  navigate,
  handleClose,
}) {
  return (
    <Row className="mt-2 p-2 search-result-container">
      <Col
        className="project-result-search py-2"
        xs={12}
        onClick={(e) => {
          e.stopPropagation()
          navigate(`/model/${projectId}?wp-name=${projectName}`)
          handleClose()
        }}
      >
        <div className="workspace-icon workspace-icon-search icon-nav d-inline-block me-2"></div>
        <strong>{projectName}</strong>
      </Col>
      {models.map((m) => (
        <Col
          key={m.id}
          className="model-result-search mt-2 py-1"
          xs={12}
          onClick={(e) => {
            e.stopPropagation()
            navigate(
              `/model/${projectId}?wp-name=${projectName}&model-id=${m.id}`,
            )
            handleClose()
          }}
        >
          <Row className="justify-content-between w-100">
            <Col xs={12}>
              <div className="models-icon models-icon-search icon-nav d-inline-block me-1"></div>
              <strong>{m.name}</strong>
            </Col>
            <Col xs={12}>
              <Row>
                <StatusTooltip model={m} />
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default function SearchModal({ show, handleClose }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const updateSearch = useDebouncedCallback((e) => setSearch(e), 500)
  const navigate = useNavigate()

  useEffect(() => {
    updateSearch(value)
    // eslint-disable-next-line
  }, [value])

  const { isLoading: workspacesIsLoading, data: workspaces } = useQuery(
    `workspace-${token}`,
    async () => {
      return await getAllWorkSpace({ only_projects: true, token, signout })
    },
    { staleTime: Infinity },
  )

  const getMoreModels = useCallback(
    ({ pageParam = 0 }) => {
      return getRecentModels({
        limit: 10,
        page: pageParam,
        name_like: search,
        problem_type: null,
        token,
        signout,
      })
    },
    // eslint-disable-next-line
    [search],
  )

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(['user-search-modal-bs', token, search], getMoreModels, {
      getNextPageParam: (pages) => {
        if (pages) {
          const { actualPage, lastPage } = pages
          if (actualPage <= lastPage) return actualPage + 1
        }
        return 0
      },
      refetchOnWindowFocus: false,
    })

  const [searchResult, projectId2Name] = useMemo(
    () => {
      const res = {}
      const projects = {}
      if (!search || isLoading) return [res, projects]

      if (Array.isArray(workspaces)) {
        workspaces
          .filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
          .forEach((p) => {
            res[p.id] = []
            projects[p.id] = p.name
          })
      }
      const models = Object.values(
        data.pages
          .map((p) => p.models)
          .flat()
          .reduce((a, m) => {
            a[m.id] = m
            return a
          }, {}),
      )

      models.forEach((m) => {
        const matchProject = m.project_name
          .toLowerCase()
          .includes(search.toLowerCase())
        const matchModel =
          m.name.toLowerCase().includes(search.toLowerCase()) ||
          ('#' + m.id.toLowerCase()).includes(search.toLowerCase())
        if (matchProject || matchModel) {
          res[m.project_id] = res[m.project_id] ?? []
          if (matchModel) res[m.project_id].push(m)
        }
      })

      models.forEach((m) => (projects[m.project_id] = m.project_name))

      return [res, projects]
    },
    // eslint-disable-next-line
    [isLoading, data, search],
  )

  return (
    <Modal size={advancedSearch ? 'xl' : 'lg'} show={show} onHide={handleClose}>
      <Modal.Body>
        {!advancedSearch && (
          <Row>
            <Col className="position-relative" xs={12}>
              <FaSearch className="form-input-icon-left-icon" />
              <Form.Control
                type="text"
                className="nb-input form-input-icon-left"
                style={{
                  fontSize: '20px',
                  paddingLeft: '55px',
                  borderRadius: 10,
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={t('Search workspaces, models, etc.')}
                autoFocus
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col
            className={`smallp ${advancedSearch ? 'py-3' : 'py-1'}  ms-2`}
            xs={12}
          >
            {advancedSearch ? (
              <i className="icon-btn" onClick={() => setAdvancedSearch(false)}>
                <FaArrowLeft className="me-1" /> {t('Regular search')}
              </i>
            ) : (
              <i className="icon-btn" onClick={() => setAdvancedSearch(true)}>
                {t('Advanced search')} <GrSearchAdvanced />
              </i>
            )}
          </Col>
        </Row>
        {advancedSearch ? (
          <ModelTopView setTitle={() => {}} className="px-4" small={true} />
        ) : (
          <Row className="search-modal">
            {workspacesIsLoading || isLoading ? (
              <Row>
                <Col xs={12} style={{ minHeight: '200px' }}>
                  <Loading />
                </Col>
              </Row>
            ) : (
              <>
                {Object.entries(searchResult).map(([projectId, models]) => (
                  <SearchResult
                    navigate={navigate}
                    handleClose={handleClose}
                    key={projectId}
                    projectId={projectId}
                    projectName={projectId2Name[projectId]}
                    models={models}
                  />
                ))}
                <IntersectionWrapper
                  period={'always'}
                  onView={() => fetchNextPage()}
                >
                  <div></div>
                </IntersectionWrapper>
                {isFetchingNextPage && (
                  <Col xs={12}>
                    <Loading />
                  </Col>
                )}
              </>
            )}
          </Row>
        )}
      </Modal.Body>
    </Modal>
  )
}
