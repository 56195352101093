import React, { useMemo, useState, useEffect } from 'react'
import { colors } from './config'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { useModels } from '../../providers/ModelProvider'
import { ResponsivePie } from '@nivo/pie'
import { getMMMOptimizedTable } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery } from 'react-query'
import { generateMMMColorMap } from '../../util/models'
import { round } from '../utils/formating'
import Loading from '../loading/LoadingSmall'
import { nivoProps, abbrNum } from '../utils/ui'

function SelectModel({ showPercentages, setShowPercentages }) {
  return (
    <div className="select-original-spend-units no-print">
      <ButtonGroup>
        <Button
          onClick={() => setShowPercentages(true)}
          className={`py-0 original button-original-spend ${
            showPercentages ? 'active-op' : ''
          }`}
          variant="secondary"
        >
          <strong
            style={{ minWidth: '20px' }}
            className="d-flex justify-content-center smallp"
          >
            %
          </strong>
        </Button>
        <Button
          onClick={() => setShowPercentages(false)}
          className={`py-0 original button-original-spend ${
            showPercentages ? '' : 'active-op'
          }`}
          variant={'secondary'}
        >
          <strong
            style={{ minWidth: '20px' }}
            className="d-flex justify-content-center smallp"
          >
            0.0
          </strong>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default function OriginalSpend({
  model,
  legend = false,
  isInView = true,
  height = 250,
  ...props
}) {
  const { token, signout } = useAuth()
  const { activeModel } = useModels()
  if (!model) model = activeModel
  const [showPercentages, setShowPercentages] = useState(true)

  const { data, isLoading, isSuccess } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      let data = null
      try {
        data = await getMMMOptimizedTable({
          modelId: model.id,
          is_outcome_optimization: false,
          weekly: false,
          original: true,
          token,
          signout,
        })
      } catch (e) {
        console.info('Error retrieving mmm optimized table spend')
      }
      return data
    },
    { staleTime: Infinity },
  )

  const datasets = useMemo(() => {
    if (data) {
      const colorMap = generateMMMColorMap(model, colors)
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const datasets = data.data.reduce((ac, d) => {
        ac[d[keymap['index']]] = {
          spend: d[keymap['Media spend']],
          color: colorMap[d[keymap['index']]],
        }
        return ac
      }, {})

      const { total } = data.data.reduce(
        (acc, d) => {
          acc.total += d[keymap['Media spend']]
          return acc
        },
        { total: 0 },
      )
      Object.entries(datasets).forEach(([k, v]) => {
        v.spendPerc = (100 * v.spend) / total
      })
      const keys = Object.keys(datasets)
        .sort((a, b) => datasets[b].spendPerc - datasets[a].spendPerc)
        .slice(0, 10)
      return keys.reduce((ac, k) => {
        ac[k] = datasets[k]
        return ac
      }, {})
    }

    return null
    // eslint-disable-next-line
  }, [data])

  const pieData = datasets
    ? [
        Object.entries(datasets).map(([k, v]) => ({
          id: k,
          color: v.color,
          label: k,
          value: v.spendPerc,
        })),
        Object.entries(datasets).map(([k, v]) => ({
          id: k,
          color: v.color,
          label: k,
          value: v.spend,
        })),
      ]
    : null

  const csvData = pieData
    ? [
        ['channel', 'absolute effect', 'relative effect'],
        ...Object.entries(datasets).map(([k, v]) => [k, v.spend, v.spendPerc]),
      ]
    : null

  const [shownData, setShownData] = useState(null)
  useEffect(() => {
    if (isSuccess && isInView) {
      setShownData(pieData?.map((d) => d.map((v) => ({ ...v, value: 0 }))))
      setTimeout(() => setShownData(pieData), 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, datasets])

  return (
    <Row
      className="h-100 data-holder"
      data-csv={encodeURIComponent(JSON.stringify(csvData))}
      data-filename={`current_media_mix__${model.id}`}
    >
      <SelectModel
        showPercentages={showPercentages}
        setShowPercentages={setShowPercentages}
      />
      {isLoading || !shownData ? (
        <Loading message="Generating optimization, this might take a few minutes" />
      ) : (
        <>
          <Col
            className="graph position-relative"
            style={
              legend
                ? { maxWidth: `${height + 120}px`, maxHeight: `${height}px` }
                : {}
            }
            xs={12}
          >
            <ResponsivePie
              {...nivoProps}
              fit={false}
              data={showPercentages ? shownData[0] : shownData[1]}
              margin={{
                top: 0,
                bottom: 0,
                left: 100,
                right: 20,
              }}
              innerRadius={0.25}
              colors={(d) => d.data.color}
              padAngle={0.7}
              cornerRadius={0}
              height={height}
              activeOuterRadiusOffset={8}
              valueFormat={
                showPercentages ? (n) => `${round(n, 0)}%` : (n) => abbrNum(n)
              }
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]],
              }}
              enableArcLabels={true}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={15}
              arcLinkLabelsTextColor={(d) => d.data.color}
              arcLinkLabelsThickness={1}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={15}
              arcLabelsTextColor={'white'}
              defs={[]}
              fill={[]}
              legends={[]}
              layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
              {...props}
            />
          </Col>
          {legend && (
            <Col
              xs={12}
              className="px-0 overflow-hidden d-flex align-items-center position-relative overflow-hidden"
              style={{
                maxWidth: `calc(100% - ${height + 130}px`,
                maxHeight: `${height}px`,
              }}
            >
              <div className="w-100">
                {(Array.isArray(shownData?.[0]) ? shownData?.[0] : [])
                  .slice(0, 10)
                  .map((d) => (
                    <Row key={d.id} className="align-items-center">
                      <Col
                        xs={12}
                        className=" pe-0 d-flex align-items-center"
                        style={{ maxWidth: '20px' }}
                      >
                        <span
                          className="nextbrain-legend-ball"
                          style={{ backgroundColor: `${d.color}` }}
                        ></span>
                      </Col>
                      <Col
                        className="px-0"
                        xs={12}
                        style={{ maxWidth: 'calc(100% - 20px)' }}
                      >
                        <span
                          title={d.id}
                          className="d-inline-block text-truncate w-100 ms-1"
                          style={{ marginTop: '0px', fontSize: '10px' }}
                        >
                          <strong>{d.id}</strong>
                        </span>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
          )}
        </>
      )}
    </Row>
  )
}
