import React from 'react'
import { Row } from 'react-bootstrap'

import AccountPlan from '../profile/AccountPlan'

import '../profile/Account.css'

export default function PlansInfo({ ...props }) {
  return (
    <Row className="mt-3">
      <AccountPlan />
    </Row>
  )
}
