import { useEffect } from 'react'
import { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { IndustryInfoBody } from '../register-form/RegisterIndustry'

export default function QuestionaireIndustryWrapper({
  industry,
  redirect,
  onFinish,
  ...props
}) {
  const { t } = useTranslation()
  const [userIndustry, setUserIndustry] = useState(industry)

  useEffect(() => {
    setUserIndustry(industry)
  }, [industry])

  return (
    <IndustryInfoBody
      className={`my-4 ${props?.className ?? ''}`}
      industryData={{ industry: userIndustry }}
      header={
        <Col align="center" className="py-2 h3" xs={12}>
          <strong>
            {redirect
              ? t('Welcome, what are you gonna use {{NextBrain}} for?', {
                  NextBrain: t('NextBrain'),
                })
              : ''}
          </strong>
        </Col>
      }
      advButton={() => (
        <>
          <Row
            className="finish-button-container-industry"
            style={{ marginTop: '-50px' }}
          >
            <Col xs={12} className="d-flex justify-content-center">
              <Button
                className="btnregister p-2"
                disabled={
                  !Object.entries(userIndustry).filter(([k, v]) => v).length
                }
                style={{
                  height: 48,
                }}
                onClick={() => onFinish(userIndustry)}
                variant={'primary'}
              >
                {t('Confirm')}
              </Button>
            </Col>
          </Row>
        </>
      )}
      onChange={async (e) => {
        setUserIndustry((v) => ({
          ...v,
          [e.target.dataset.industry]: e.target.checked,
        }))
      }}
    />
  )
}
