import wave from '../../assets/images/wave.svg'
import waveLine from '../../assets/images/wave-linear.svg'
import { Row, Col, Image, Button } from 'react-bootstrap'

const offStyle = {
  opacity: 0.5,
  cursor: 'pointer',
}
export default function InterpolationSelector({ value, onChange, ...props }) {
  return (
    <Row
      className={` ${props?.className ?? ''}`}
      style={{
        maxWidth: '50px',
        position: 'absolute',
        top: '0',
        left: 'calc(100% + 5px)',
      }}
    >
      <Col style={value === 'linear' ? {} : offStyle} xs={12}>
        <Button onClick={() => onChange('linear')}>
          <Image src={waveLine} width="20px" />
        </Button>
      </Col>
      <Col style={value !== 'linear' ? {} : offStyle} className="mt-2" xs={12}>
        <Button onClick={() => onChange('cardinal')}>
          <Image src={wave} width="20px" />
        </Button>
      </Col>
    </Row>
  )
}
