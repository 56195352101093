import React, { useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { Row, Col, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../loading/LoadingSmall'

function ActiveUser({ user }) {
  const [expand, setExpand] = useState(false)
  return (
    <Col className="admin-modal-panel-user mt-4" xs={12}>
      <Row>
        <Col className="d-inline-block text-truncate" xs={3}>
          <Link to={`/user/?userId=${user.id}&from=dashboard`}>
            <span
              style={{ color: 'var(--nextbrain-tables-blue-graph-bar-color)' }}
            >
              {user.email}
            </span>
          </Link>
        </Col>
        <Col className="d-inline-block text-truncate" xs={3}>
          {user.country}
        </Col>
        <Col className="d-inline-block text-truncate" xs={3}>
          {user.previous_platforms}
        </Col>
        <Col className="d-inline-block text-truncate" xs={3}>
          {user.introduction}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs={12}>
          <FaArrowAltCircleRight
            size={30}
            onClick={() => setExpand(!expand)}
            style={{
              transform: `${expand ? 'rotate(90deg)' : 'rotate(0deg)'}`,
              cursor: 'pointer',
              transitionDuration: '0.25s',
            }}
          />
        </Col>
        <Col
          className={`${
            expand ? 'admin-panel-actions' : 'admin-panel-collapse-actions'
          }`}
          xs={12}
        >
          <Row>
            <Col
              className="d-inline-block text-truncate"
              style={{ marginLeft: '50px' }}
              xs={3}
            >
              <span className="small">Action</span>
            </Col>
            <Col className="d-inline-block text-truncate" xs={3}>
              <span className="small">Source</span>
            </Col>
            <Col className="d-inline-block text-truncate" xs={3}>
              <span className="small">Error</span>
            </Col>
          </Row>
          {Array.isArray(user.actions) &&
            user.actions.map((action, i) => (
              <Row key={i}>
                <Col c style={{ marginLeft: '50px' }} xs={3}>
                  {action.action_type}
                </Col>
                <Col xs={3}>in {action.action_from}</Col>
                <Col xs={3}>{action.is_error ? 'Error' : 'Successful'}</Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Col>
  )
}

export default function ActiveUserList({ users, isLoading, visible, hide }) {
  const { t } = useTranslation()

  return (
    <Modal show={visible} onHide={hide} size={'xl'}>
      <Modal.Header>
        <span className="h3">{t('Active Users')}</span>
      </Modal.Header>
      <Modal.Body>
        {visible &&
          (isLoading ? (
            <Loading />
          ) : (
            <Row style={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Col xs={12}>
                <Row>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Email</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Contry</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Previous platforms</span>
                  </Col>
                  <Col className="d-inline-block text-truncate" xs={3}>
                    <span className="small">Introduction</span>
                  </Col>
                </Row>
              </Col>
              {Array.isArray(users) &&
                users.map((user) => <ActiveUser key={user.id} user={user} />)}
            </Row>
          ))}
      </Modal.Body>
    </Modal>
  )
}
