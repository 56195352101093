import { Row, Col } from 'react-bootstrap'

export const help = {
  'Api administration': {
    required: {
      SERVER_HOST: {
        alias: 'Server name',
        help: (
          <Row>
            <Col xs={12}>
              The server name users will access the application from.
            </Col>
            <Col className="mt-2" xs={12}>
              For example if you're using this application in the URL
              http://mydeployment:8080/admin-manager-config, the correct value
              for this field would be http://mydeployment:8080
            </Col>
          </Row>
        ),
      },
      DEFAULT_COMPANY_NAME: {
        alias: 'Company name',
        help: (
          <Row>
            <Col xs={12}>
              This is the name of the company users will be assigned to when
              they create their accounts.
            </Col>
          </Row>
        ),
      },
      LICENSE_KEY: {
        alias: 'License key',
        help: (
          <Row>
            <Col xs={12}>
              This is the name of the company users will be assigned to when
              they create their accounts.
            </Col>
          </Row>
        ),
      },
    },
    optional: {
      REQUIRE_VALIDATION: {
        alias: 'Disable validation',
        boolean: true,
        help: (
          <Row>
            <Col xs={12}>
              Disable sending emails to new accounts for validation.
            </Col>
            <Col cclassName="mt-2" xs={12}>
              If this enabled new users will be able to login immediately after
              creating their account.
            </Col>
          </Row>
        ),
      },
      DISABLED_LLM_FEATURES: {
        alias: 'Disable Large language models by default',
        boolean: true,
        help: (
          <Row>
            <Col xs={12}>
              If enabled users will need to manually configure LLM features if
              they want assistance from the bot to explore their data and
              transform it.
            </Col>
          </Row>
        ),
      },
    },
  },
}
