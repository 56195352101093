import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Row, Col } from 'react-grid-system'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { getAllWorkSpace, moveModelToProjectId } from '../../services/project'
import { useAuth } from '../../providers/AuthProvider'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { FaRegFolderOpen } from 'react-icons/fa'

import NextbrainSelect from '../model-content/NextbrainSelect'
import { useNav } from '../../providers/NavProvider'

export default function MoveProjectModel({
  moveToProject,
  setMoveToProject,
  model,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token, signout } = useAuth()
  const [finalProject, setFinalProject] = useState(null)
  const { mode } = useNav()

  const { data: workspaces } = useQuery(
    `workspace-${token}-${mode}`,
    () =>
      getAllWorkSpace({
        only_projects: true,
        product_type: mode,
        token,
        signout,
      }),
    { staleTime: Infinity },
  )

  return (
    <Modal centered show={moveToProject} onHide={() => setMoveToProject(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FaRegFolderOpen style={{ marginLeft: '5px' }} />{' '}
          {t('Move to another workspace')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <NextbrainSelect
              value={finalProject}
              onChange={(value) => setFinalProject(value)}
              className="basic-single mt-2"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="Workspace"
              options={workspaces?.map((w) => ({
                value: w.id,
                label: w.name,
              }))}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setMoveToProject(false)
            if (!finalProject) return
            moveModelToProjectId(
              finalProject.value,
              model.id,
              token,
              signout,
            ).then((response) => {
              if (!response?.ok) return
              NotificationManager.success(
                `${t('Model moved to')} ${finalProject.label ?? ''}`,
              )
              navigate(
                `/model/${finalProject.value}?wp-name=${encodeURIComponent(
                  finalProject.label,
                )}&tab=3&model-id=${model.id}`,
              )
              setFinalProject(null)
            })
          }}
        >
          {t('Move')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
