import ErrorNB from './ErrorNB'
import Loading from './LoadingSmall'

export function ErrorLoadingContent({
  isLoading,
  isError,
  loadingMessage = 'Loading',
  errorMessage = 'Something went wrong',
  children,
  errorProps = {},
  ...props
}) {
  const Children = children
  return isError ? (
    <ErrorNB errorMessage={errorMessage} {...errorProps} />
  ) : isLoading ? (
    <Loading message={loadingMessage} />
  ) : typeof Children === 'function' ? (
    <Children />
  ) : (
    children
  )
}
