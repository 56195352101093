/* eslint-disable no-unused-vars */
// disabled unused vars check because of temporary disabled prices
//TODO: remove once prices are enabled
import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import {
  FaCheckCircle,
  FaCrown,
  FaTimesCircle,
  FaPlusCircle,
  FaDocker,
} from 'react-icons/fa'

import { getProductsInfo, getActiveSubscriptions } from '../../services/user'
import { changePlan, getNextBillingInfo } from '../../services/pricing'
import { readableNumber } from '../utils/formating'
import { useAuth } from '../../providers/AuthProvider'
import CustomModal from '../modal/modal'
import Loading from '../loading/LoadingSmall'
import { config } from '../../Constants'
import { SwitchButtons } from '../utils/ui'
import { round } from '../utils/formating'

const plan_configs = [
  {
    id: 'free',
    name: 'Free',
    description: 'Free plan',
    prices: [],
    details: {
      clustering_models: false,
      data_scientist: false,
      dockerfile: false,
      external_api: false,
      forecasting_models: false,
      onboarding: '',
      predictions: 500,
      sql_db: 'True',
      training_rows: 50000,
      user_seats: 1,
    },
  },
  {
    id: config.STRIPLE_PLANS['Freelance'].id,
    name: 'Freelance',
    description:
      'No-Code Machine Learning for personal use at your fingertips.',
    prices: [
      {
        id: config.STRIPLE_PLANS['Freelance'].usd.year,
        price: 500,
        currency: 'usd',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Freelance'].usd.month,
        price: 50,
        currency: 'usd',
        interval: 'month',
      },
      {
        id: config.STRIPLE_PLANS['Freelance'].eur.year,
        price: 500,
        currency: 'eur',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Freelance'].eur.month,
        price: 50,
        currency: 'eur',
        interval: 'month',
      },
    ],
    details: {
      max_models: '30',
      onboarding: '30 min',
      predictions: '2000',
      sql_db: 'True',
      training_rows: '500000',
      max_rows_per_model: '5000',
      user_seats: '1',
    },
    image: null,
  },
  {
    id: config.STRIPLE_PLANS['Small Business'].id,
    name: 'Small Business',
    description: 'No-Code Machine Learning for data driven teams.',
    prices: [
      {
        id: config.STRIPLE_PLANS['Small Business'].usd.year,
        price: 4500,
        currency: 'usd',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Small Business'].usd.month,
        price: 450,
        currency: 'usd',
        interval: 'month',
      },
      {
        id: config.STRIPLE_PLANS['Small Business'].eur.year,
        price: 4500,
        currency: 'eur',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Small Business'].eur.month,
        price: 450,
        currency: 'eur',
        interval: 'month',
      },
    ],
    details: {
      clustering_models: 'True',
      external_api: 'True',
      forecasting_models: 'True',
      max_models: '100',
      onboarding: '1 hour',
      predictions: '20000',
      sql_db: 'True',
      training_rows: '10000000',
      max_rows_per_model: '90000',
      user_seats: '3',
    },
    image: null,
  },
  {
    id: config.STRIPLE_PLANS['Enterprise'].id,
    name: 'Enterprise',
    description:
      'No-Code Machine Learning for an enterprise with challenging requirements.',
    prices: [
      {
        id: config.STRIPLE_PLANS['Enterprise'].usd.year,
        price: 25000,
        currency: 'usd',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Enterprise'].usd.month,
        price: 2500,
        currency: 'usd',
        interval: 'month',
      },
      {
        id: config.STRIPLE_PLANS['Enterprise'].eur.year,
        price: 25000,
        currency: 'eur',
        interval: 'year',
      },
      {
        id: config.STRIPLE_PLANS['Enterprise'].eur.month,
        price: 2500,
        currency: 'eur',
        interval: 'month',
      },
    ],
    details: {
      anomaly: 'True',
      clustering_models: 'True',
      data_scientist: 'True',
      dockerfile: 'True',
      external_api: 'True',
      onboarding: '1 hour',
      predictions: '100000',
      forecasting_models: 'True',
      max_models: '500',
      sql_db: 'True',
      synthetic_data: 'True',
      training_rows: '100000000',
      max_rows_per_model: '1000000',
      user_seats: '10',
    },
    image: null,
  },
]

function ProductDetails({
  product,
  previousProduct = null,
  activePlan = false,
  stripeSubscriptionId = null,
  current,
  endDate,
  loggedIn = false,
  showYearlyPrices = false,
  useUSD = false,
  idx = 0,
  ...props
}) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const { data: nextBillingData } = useQuery(
    ['nextBilling', token],
    async () => {
      const response = await getNextBillingInfo({
        token,
        signout,
        subscriptionId: stripeSubscriptionId,
      })
      return response
    },
    {
      enabled: !loggedIn,
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  )

  const isFree = product.id === 'free'
  const isEnterprise = product.name === 'Enterprise'

  const currencyToStr = (currency) => {
    switch (currency) {
      case 'usd':
        return 'USD'
      case 'eur':
        return '€'
      case 'gbp':
        return '£'
      default:
        return currency
    }
  }
  let pricing
  if (!isFree) {
    const freqFilteredPricing =
      product.prices.filter(
        (p) =>
          (p.recurring_interval ?? p.interval) ===
          (showYearlyPrices ? 'year' : 'month'),
      ) ?? product.prices
    pricing =
      freqFilteredPricing.find(
        (p) => p.currency === (useUSD ? 'usd' : 'eur'),
      ) ?? freqFilteredPricing[0]
  }

  function PlanFeature({ text, enabled = true, customIcon }) {
    return (
      <Row className="ms-1 mb-2">
        <span style={{ width: '15px' }}>
          {enabled ? (
            customIcon ? (
              customIcon
            ) : (
              <FaCheckCircle className="text-success" />
            )
          ) : (
            <FaTimesCircle style={{ color: 'grey' }} />
          )}
        </span>
        <Col>{text}</Col>
      </Row>
    )
  }

  const featureGroups = [
    {
      title: 'Service',
      features: [
        {
          text: (
            <>
              <strong>
                {product.details.predictions === 'Unlimited'
                  ? t('Unlimited')
                  : readableNumber(product.details.predictions ?? 1000, false)}
              </strong>{' '}
              {t('predictions per month')}
            </>
          ),
        },
        {
          text: (
            <>
              <strong>
                {product.details.user_seats === 'Unlimited'
                  ? t('Unlimited')
                  : `${t('Up to')} ${
                      product.details.user_seats ?? (isFree ? 1 : 3)
                    }`}
              </strong>{' '}
              {t('user seats')}
            </>
          ),
        },
        {
          text: (
            <>
              <strong>
                {readableNumber(product.details.max_models ?? 15, false)}
              </strong>{' '}
              {t('models per month')}
            </>
          ),
        },
        {
          text: (
            <>
              <strong>
                {readableNumber(
                  product.details.max_rows_per_model ?? 1000,
                  false,
                )}{' '}
                {t('Max')} {t('Rows')}
              </strong>{' '}
              {t('per model')}
            </>
          ),
        },
      ],
    },
    {
      title: 'Support',
      features: [
        {
          text: <span>{t('Docs and Video Tutorials')}</span>,
        },
        {
          enabled: !isFree,
          text: (
            <strong>
              {product.details.onboarding
                ? t(product.details.onboarding)
                : '30 min'}{' '}
              {t('onboarding')}
            </strong>
          ),
          customIcon: <FaPlusCircle className="text-warning" />,
        },
        {
          enabled: !isFree,
          text: (
            <>
              Email + {t('Chat Support')} +{' '}
              <strong>{t('Video Call Support')}</strong>
            </>
          ),
          customIcon: <FaPlusCircle className="text-warning" />,
        },
        {
          enabled: isEnterprise,
          text: <strong>{t('Dedicated data Scientist 5h/month')}</strong>,
          customIcon: <FaPlusCircle className="text-warning" />,
        },
      ],
    },
    {
      title: 'Model Features',
      features: [
        { text: t('Data cleanup system') },
        { text: t('Classification Models') },
        { text: t('Regression Models') },
        {
          text: t('Interactive Dashboard'),
          enabled: !isFree,
        },
        {
          text: t('Time Series Models'),
          enabled:
            product.details.forecasting_models === 'True' ||
            product.details.forecasting_models === true,
        },
        {
          text: t('Clustering Models'),
          enabled:
            product.details.clustering_models === 'True' ||
            product.details.clustering_models === true,
        },
        {
          text: t('Synthetic Data'),
          enabled:
            product.details.synthetic_data === 'True' ||
            product.details.synthetic_data === true,
        },
        {
          text: t('Anomaly'),
          enabled:
            product.details.anomaly === 'True' ||
            product.details.anomaly === true,
          customIcon: <FaPlusCircle className="text-warning" />,
        },
      ],
    },
    {
      title: 'Integrations',
      features: [
        {
          text: t(
            'Access to Open Source code to automate training and prediction',
          ),
        },
        { text: t('Zapier Integrations') },
        {
          text: (
            <>
              {t('Web app')} + {t('Gsheet Addon')}
            </>
          ),
        },
        {
          text: (
            <>
              {t('CSV Files')} + GSheet +{' '}
              <strong>Dataslayer API Query Manager</strong>
            </>
          ),
        },
        {
          enabled:
            product.details.sql_db === 'True' ||
            product.details.sql_db === true,
          text: (
            <strong>{`${t('SQL Database')} + ${t('External APIs')}`}</strong>
          ),
        },
        {
          enabled:
            product.details.sql_db === 'True' ||
            product.details.sql_db === true,
          text: <strong>MongoDB</strong>,
        },
        {
          enabled:
            product.details.sql_db === 'True' ||
            product.details.sql_db === true,
          text: <strong>PowerBI</strong>,
        },
        {
          text: <strong>{t('Advanced Dataflow')}</strong>,
          customIcon: <FaPlusCircle className="text-warning" />,
        },
      ],
    },
    {
      title: 'Natural Language',
      features: [
        {
          text: (
            <>
              {t('Chat explore')}, <strong>{t('Chat transform')}</strong>
            </>
          ),
          customIcon: <FaPlusCircle className="text-warning" />,
        },
        {
          text: (
            <>{t('Configure your LLM (ChatGPT, Azure, Own implementation)')}</>
          ),
          customIcon: <FaPlusCircle className="text-warning" />,
        },
      ],
    },
    {
      title: 'Deliverables',
      features: [
        { text: t('Web App deployments') },
        { text: 'API Deployments' },
        { text: 'Jupyter Notebook' },
        {
          enabled: isEnterprise,
          text: <strong>{t('Deploy in your own servers with Docker')}</strong>,
          customIcon: <FaDocker style={{ color: '#2391E6' }} />,
        },
      ],
    },
  ]

  return (
    <React.Fragment {...props}>
      <div
        className={`plan-row1 ${idx > 1 ? 'back' : ''} ${
          current ? 'plan-current' : 'plan-not-current'
        }`}
      >
        {current && (
          <Col xs={12} className="text-center" style={{ marginTop: 6 }}>
            <FaCheckCircle className="text-success" />
            <small className="ms-1">
              {isFree
                ? t('Current plan')
                : t('Current plan. Active until') +
                  ' ' +
                  new Date(endDate).toLocaleDateString()}
            </small>
          </Col>
        )}
      </div>
      <div
        className={`plan-row2 ${idx > 1 ? 'back' : ''} ${
          current ? 'plan-current' : 'plan-not-current'
        } pt-2 d-flex flex-column justify-content-between`}
      >
        <div className="plan-name p-3">
          {product.name.replace('NextBrain - ', '')}
        </div>
        <Col
          xs={12}
          className="d-flex my-3 flex-column justify-content-center flex-grow-1"
        >
          {isFree ? (
            <Row className="justify-content-center">
              {/* <Col xs={12} className="d-flex justify-content-center">
                {t('forever')}
              </Col> */}
            </Row>
          ) : !pricing ? (
            <Row className="justify-content-center">
              <Col
                xs={12}
                className="px-3 d-flex justify-content-center small-price text-center"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nextbrain.ai/contact-us"
                >
                  <strong>{t('Contact us')}</strong>
                </a>
              </Col>
            </Row>
          ) : (
            <Row className="justify-content-center"></Row>
          )}
        </Col>
      </div>
      <div
        className={`plan-row3  ${idx > 1 ? 'back' : ''} ${
          current ? 'plan-current' : 'plan-not-current'
        }`}
      >
        <Col xs={12} className="my-3" />
        <Col xs={12} className="d-flex justify-content-center mt-3">
          <div className="horizontal-bar w-75" />
        </Col>
        <Col xs={12} className="mt-4" style={{ fontSize: '1rem' }}>
          <p className="text-center">{t(product.description)}</p>
        </Col>
      </div>
      <div
        className={`plan-row4 ${idx > 1 ? 'back' : ''} ${
          current ? 'plan-current' : 'plan-not-current'
        }`}
      >
        <Col xs={12} className={`justify-content-center align-items-center`}>
          {/* {!activePlan &&
          (!isFree && !isEnterprise && !current && !activePlan ? (
            <Row className="mx-2">
              <Col xs={12}>
                <Button
                  className="w-100 btn-trial"
                  onClick={async () => {
                    const response = await startTrial({
                      companyId: user.selected_company,
                      token,
                      signout,
                    })
                    if (response) {
                      NotificationManager.success(
                        t(
                          'The trial has started. You will be able to access the full features of the platform for 7 days.',
                        ),
                      )
                    } else {
                      NotificationManager.error(
                        t(
                          'You cannot start a trial because you already have an active subscription or used a trial in the past.',
                        ),
                      )
                    }
                  }}
                >
                  {t('Free trial')}
                </Button>
              </Col>
            </Row>
          ) : (
            <div style={{ marginTop: 52 }}></div>
          ))} */}
          {!isFree && !current ? (
            <Row className="mt-3 mx-2">
              <Col xs={12}>
                <Button
                  className="w-100 py-3"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open('https://nextbrain.ai/contact-us', '_blank')
                  }}
                >
                  {t('Contact us')}
                </Button>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {!isFree && current ? (
            nextBillingData?.cancel_at_period_end ? (
              <Row className="mt-3 mx-2">
                <Col xs={12}>
                  <Button
                    className="w-100"
                    onClick={async () => {
                      const response = await changePlan({
                        token,
                        signout,
                        reactivate: true,
                      })
                      if (response?.ok) {
                        NotificationManager.info(
                          t(
                            'The renewal has been reactivated. You will be charged again on the next billing date.',
                          ),
                        )
                      } else {
                        NotificationManager.error(
                          t(
                            'There was an error reactivating the renewal. Please contact us.',
                          ),
                        )
                      }
                    }}
                  >
                    {t('Re-active renewal')}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="mt-3 mx-2">
                <Col xs={12}>
                  <CustomModal
                    title={t(`Cancel renewal`)}
                    useButton={false}
                    btn={
                      <Button className="w-100 py-3 original btn-danger">
                        {t('Cancel renewal')}
                      </Button>
                    }
                    confirmText={t('Cancel renewal')}
                    confirmProps={{
                      variant: 'danger',
                      className: 'original',
                    }}
                    showVariant={null}
                    confirmCallback={async () => {
                      const response = await changePlan({
                        token,
                        signout,
                        cancel: true,
                      })
                      if (response?.ok) {
                        NotificationManager.info(
                          t(
                            'The renewal has been canceled. The changes will be applied at the end of the current billing period.',
                          ),
                        )
                      } else {
                        NotificationManager.error(
                          t(
                            'There was an error while canceling the renewal. Please try again later.',
                          ),
                        )
                      }
                    }}
                  >
                    <p>
                      {t(
                        'You have an active subscription. If you cancel it, the changes will be applied at the end of the current billing period.',
                      )}
                    </p>
                    <br />
                    <strong>{t('Next billing date')}</strong>:{' '}
                    {nextBillingData?.next_billing_date}
                    <br />
                    <strong>{t('Next billing amount')}</strong>:{' '}
                    {nextBillingData?.next_billing_amount}{' '}
                    {currencyToStr(nextBillingData?.currency)}
                  </CustomModal>
                </Col>
              </Row>
            )
          ) : (
            <></>
          )}
        </Col>
      </div>
      <div
        className={`plan-row5 ${idx > 1 ? 'back' : ''} ${
          current ? 'plan-current' : 'plan-not-current'
        } pt-4`}
      >
        <Col
          xs={12}
          className="mt-3"
          style={{ fontSize: '0.9rem', minHeight: 440 }}
        >
          {featureGroups.map((featureGroup, idx) => (
            <div className="mt-2 mb-4">
              <div
                className="h5 ms-3 pb-2 mb-3"
                style={{
                  borderBottom: 'solid 2px var(--nextbrain-white-font)',
                }}
              >
                {featureGroup.title}
              </div>
              {featureGroup.features.map((feature) => (
                <PlanFeature key={idx} {...feature} />
              ))}
            </div>
          ))}
        </Col>
      </div>
    </React.Fragment>
  )
}

export async function getProducts() {
  const response = await getProductsInfo()
  response.sort((a, b) => {
    const priceA = a.prices.find(
      (price) => price.recurring_interval === 'month',
    )
    if (priceA === undefined) return -1
    const priceB = b.prices.find(
      (price) => price.recurring_interval === 'month',
    )
    if (priceB === undefined) return 1
    return (
      priceA.price / priceA.interval_count -
      priceB.price / priceB.interval_count
    )
  })
  return response
}

export default function AccountPlan({ setTitle, token, user, ...props }) {
  const { t } = useTranslation()

  const loggedIn = !!user

  // 0: monthly, 1: yearly
  const [showYearlyPrices, setShowYearlyPrices] = useState(false)

  // 0: €, 1: $
  const [useUSD, setUseUSD] = useState(false)

  const products = useQuery(
    ['products-info'],
    async () => {
      return await getProducts()
    },
    { staleTime: 60 * 1000 },
  )

  const activeSubscriptions = useQuery(
    ['active-subscriptions'],
    async () => {
      const response = await getActiveSubscriptions(token)
      return response
    },
    { staleTime: 60 * 1000, enabled: loggedIn },
  )

  let selectedCompany = null
  let currentProduct = 'free'
  let planColumns = (
    <Col style={{ gridColumn: '2/4' }} xs={12}>
      <Loading className="py-4 justify-content-center" />
    </Col>
  )
  if (products.isSuccess && (!loggedIn || activeSubscriptions.isSuccess)) {
    if (activeSubscriptions.isSuccess && activeSubscriptions.data) {
      selectedCompany = activeSubscriptions.data[user.selected_company]
      currentProduct =
        selectedCompany?.products.find(
          (product_id) =>
            products.data.find((prod) => prod.id === product_id)?.details
              .product_type !== 'plugin',
        ) ?? 'free'
      if (currentProduct === 'free') {
        currentProduct = selectedCompany?.subscription?.config?.name ?? 'free'
      }
    }
    planColumns = plan_configs.map((product, i) =>
      product.details.product_type !== 'plugin' ? (
        //<Col xs={'12'} md={'6'} lg={'3'} key={product.id}>
        <ProductDetails
          key={product.id}
          product={product}
          current={
            currentProduct === product.id || currentProduct === product.name
          }
          activePlan={currentProduct !== 'free'}
          endDate={selectedCompany?.subscription?.to_date}
          previousProduct={i > 0 ? products.data[i - 1] : null}
          stripeSubscriptionId={
            selectedCompany?.subscription?.stripe_subscription_id
          }
          loggedIn={loggedIn}
          showYearlyPrices={showYearlyPrices}
          useUSD={useUSD}
          idx={i}
        />
      ) : (
        //</Col>
        ''
      ),
    )
  }

  useEffect(
    () => {
      setTitle('My plan')
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <Row>
      <Col className="mt-2" xs={12}>
        <strong
          className="h3 d-flex align-items-center"
          style={{ marginLeft: '-40px' }}
        >
          {' '}
          <FaCrown className="me-1" size={29} /> Plan
        </strong>
      </Col>
      <Col xsl={'12'} lg={'6'} className="mt-2 d-none">
        <Row>
          <Col xs={5} className="text-end my-auto">
            {t('Billing frequency')}:
          </Col>
          <Col xs={7}>
            <SwitchButtons
              options={[t('Monthly') /*t('Yearly 20% OFF')*/]}
              selected={0}
              // setSelected={(index) => setShowYearlyPrices(index === 1)}
            />
          </Col>
        </Row>
      </Col>
      <Col xsl={'12'} lg={'6'} className="mt-2 d-none">
        <Row>
          <Col xs={6} className="text-end my-auto">
            {t('Currency')}:
          </Col>
          <Col xs={6}>
            <SwitchButtons
              options={[t('EUR'), t('USD')]}
              selected={useUSD ? 1 : 0}
              setSelected={(index) => setUseUSD(index === 1)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <div
          className="plans-container"
          style={{
            margin: '20px -2rem',
          }}
        >
          {planColumns}
        </div>
      </Col>
    </Row>
  )
}
