import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaFilter } from 'react-icons/fa'
import { BsInfoCircle } from 'react-icons/bs'
import NextbrainSelect from '../../NextbrainSelect'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import { enforceValidation } from '../../../../util/validation'
import { operation as operationsFilter } from './ConfigureFilter'
import TransformTable from './TransformTable'
import { useDebouncedCallback } from 'use-debounce'
import { AddRowButton, DeleteRowButton } from '../../../utils/ui'

const transformationOperations = {
  '+': {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column plus an arbitrary value',
  },

  '-': {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column minus an arbitrary value',
  },

  '*': {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column multiplied by an arbitrary value',
  },

  '/': {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column divided by an arbitrary value',
  },

  '^': {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column raised to an arbitrary value',
  },

  log: {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column logarithm of an arbitrary base',
  },

  log10: {
    value: false,
    tooltip: 'Base 10 logarith of column',
  },

  log2: {
    value: false,
    tooltip: 'Base 10 logarith of column',
  },

  exp: {
    value: false,
    tooltip: 'e raised to the power of column',
  },

  sqrt: {
    value: false,
    tooltip: 'Square root of column',
  },

  sin: {
    value: false,
    tooltip: 'Sine of column',
  },

  cos: {
    value: false,
    tooltip: 'Cosine of column',
  },

  tan: {
    value: false,
    tooltip: 'Tangent of column',
  },

  asin: {
    value: false,
    tooltip: 'Arc sine of column',
  },

  acos: {
    value: false,
    tooltip: 'Arc cosine of column',
  },

  atan: {
    value: false,
    tooltip: 'Arc tangent of column',
  },

  sinh: {
    value: false,
    tooltip: 'Hyperbolic sine of column',
  },

  cosh: {
    value: false,
    tooltip: 'Hyperbolic cosine of column',
  },

  tanh: {
    value: false,
    tooltip: 'Hyperbolic tangent of column',
  },

  asinh: {
    value: false,
    tooltip: 'Inverse hyperbolic sine of column',
  },

  acosh: {
    value: false,
    tooltip: 'Inverse hyperbolic cosine of column',
  },

  atanh: {
    value: false,
    tooltip: 'Inverse hyperbolic tangent of column',
  },

  ceil: {
    value: false,
    tooltip: 'Numeric column rounded up to the nearest integer',
  },

  abs: {
    value: false,
    tooltip: 'Absolute value of column',
  },

  trunc: {
    value: false,
    tooltip: 'Numeric column rounded down to the nearest integer',
  },

  mod: {
    value: true,
    validation: enforceValidation({ numeric: true }),
    tooltip: 'Numeric column modulo an arbitrary value',
  },

  format: {
    value: true,
    tooltip: 'Numeric column formatted with an arbitrary format string',
  },
  replace: {
    value: true,
    tooltip: 'Column replaced with an arbitrary value',
  },
  set: {
    value: true,
    tooltip: 'Column set with an arbitrary value',
  },
  'remove first n characters': {
    value: true,
    tooltip: 'Column with the first n characters removed',
  },
  'remove last n characters': {
    value: true,
    tooltip: 'Column with the last n characters removed',
  },
}

function Transform({
  transformation,
  column,
  columns,
  operator,
  operators,
  value,
  selectColumn,
  selectOperator,
  selectValue,
  onChange,
  onDelete,
  filterOperators,
  showDeleteButton,
  ...props
}) {
  operator = operators?.find((o) => o.value === operator?.value)
  const [showSelect, setShowSelect] = useState(false)
  const { t } = useTranslation()
  const [project, setProject] = useState(
    transformation.project
      ? {
          label: t('Insert into new column'),
          value: 'Insert into new column',
        }
      : {
          label: t('Update column'),
          value: 'Update column',
        },
  )
  const operatorConfig = transformationOperations[operator?.value] ?? {}
  const validation = operatorConfig?.validation ?? (() => null)
  const freeInputRef = useRef()
  const freeInputSelectRef = useRef()
  //Dont want to be calling a new sample for each keystroke
  const updateFreeInput = useDebouncedCallback(
    (...props) => onChange(...props),
    1000,
  )

  const operatorFilter = operationsFilter[selectOperator?.value] ?? {}
  const hasValueFilter = operatorFilter.hasValue

  useEffect(() => {
    if (freeInputRef.current) freeInputRef.current.value = value
  }, [value])
  useEffect(() => {
    if (freeInputSelectRef.current)
      freeInputSelectRef.current.value = selectValue
    // eslint-disable-next-line
  }, [selectValue])

  return (
    <Row className={`align-items-center ${props.className ?? ''}`}>
      <Col
        className="p-2"
        xs={12}
        style={{
          border: '1px solid var(--nextbrain-secondary-border-color)',
          borderRadius: '10px',
        }}
      >
        <Row className="mb-1 align-items-center">
          <Col xl={3} md={12}>
            <Row className="align-items-center">
              <Col
                className="d-flex justify-content-end"
                xl={3}
                md={'auto'}
                xs={'auto'}
              >
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="auto"
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`popover-help-tooltip ${
                        props.className ?? ''
                      }`}
                    >
                      <div>
                        {t('Filter rows to apply transformation (default all)')}
                      </div>
                    </Tooltip>
                  )}
                >
                  <Button
                    checked={showSelect}
                    onClick={() => setShowSelect(!showSelect)}
                    className={`nextbrain-outline d-flex p-2 ${
                      showSelect ? 'pressed' : ''
                    }`}
                  >
                    <FaFilter />
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col xl={9} md={11} xs={10}>
                <small>
                  {showSelect ? t('Find where') : t('Apply to all')}
                </small>
              </Col>
            </Row>
          </Col>
          {showSelect ? (
            <>
              <Col xl={4} md={12} className="mt-xl-0 mt-md-1 mt-1">
                <NextbrainSelect
                  value={selectColumn}
                  onChange={(c) =>
                    onChange({
                      column,
                      operator,
                      value,
                      selectColumn: c,
                      selectOperator,
                      selectValue: null,
                      project: transformation.project,
                    })
                  }
                  options={columns}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  className={`basic-single `}
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  isDisabled={!showSelect}
                  placeholder={t('column')}
                />
              </Col>
              <Col xl={2} md={12} className="mt-xl-0 mt-md-1 mt-1">
                <NextbrainSelect
                  value={selectOperator}
                  onChange={(o) =>
                    onChange({
                      column,
                      operator,
                      value,
                      selectColumn,
                      selectOperator: o,
                      selectValue: null,
                      project: transformation.project,
                    })
                  }
                  options={filterOperators}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  className={`basic-single `}
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  isDisabled={!showSelect}
                />
              </Col>
              <Col xl={3} md={12} className="mt-xl-0 mt-md-1 mt-1">
                {hasValueFilter === 'list' ? (
                  <NextbrainSelect
                    value={selectValue}
                    onChange={(v) =>
                      onChange({
                        column,
                        operator,
                        value,
                        selectColumn,
                        selectOperator,
                        selectValue: v,
                        project: transformation.project,
                      })
                    }
                    options={[]}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    className={`basic-single `}
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    creatable={true}
                    isMulti={true}
                    isDisabled={!showSelect}
                  />
                ) : (
                  <Form.Control
                    className="h-100 py-2 nb-input-soft mt-0"
                    ref={freeInputSelectRef}
                    onChange={(v) =>
                      updateFreeInput({
                        column,
                        operator,
                        value,
                        selectColumn,
                        selectOperator,
                        selectValue: v.currentTarget.value,
                        project: transformation.project,
                      })
                    }
                    style={{ backgroundColor: '#EBEBEB' }}
                    defaultValue={selectValue}
                    placeholder={'value'}
                    disabled={!showSelect}
                  />
                )}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row className="align-items-center">
          <Col xl={3} md={12} className="mt-xl-0 mt-md-1 mt-1">
            <Row>
              <Col xs={3} className="d-none d-md-block mb-2 mb-lg-0" />
              <Col xl={9} md={12}>
                <small>{t('Update with')}</small>
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={12} className="mt-xl-0 mt-md-1 mt-1">
            <NextbrainSelect
              value={column}
              onChange={(c) => {
                onChange({
                  column: c,
                  operator,
                  value: value,
                  selectColumn: selectColumn ?? c,
                  selectOperator,
                  selectValue,
                  project: transformation.project,
                })
              }}
              options={columns}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single `}
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
            />
          </Col>
          <Col xl={2} md={12} className="mt-xl-0 mt-md-1 mt-1">
            <NextbrainSelect
              value={operator}
              onChange={(o) =>
                onChange({
                  column,
                  operator: o,
                  value: value,
                  selectColumn,
                  selectOperator,
                  selectValue,
                  project: transformation.project,
                })
              }
              options={operators}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single `}
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
            />
          </Col>
          <Col
            className="enforced-validation-container mt-xl-0 mt-md-1 mt-1"
            xl={3}
            md={12}
            xs={12}
          >
            <Form.Control
              className="h-100 py-2 nb-input-soft mt-0"
              ref={freeInputRef}
              onChange={(v) =>
                updateFreeInput({
                  column,
                  operator,
                  value: v.currentTarget.value,
                  selectColumn,
                  selectOperator,
                  selectValue,
                  project: transformation.project,
                })
              }
              style={{ backgroundColor: '#EBEBEB' }}
              defaultValue={value}
              placeholder={'Value'}
              onKeyPress={validation}
            />
          </Col>
        </Row>
        <Row className="mt-2 justify-content-end">
          <Col xl={4} md={6} className="mt-1">
            <NextbrainSelect
              value={project}
              onChange={(v) => {
                setProject(v)
                onChange({
                  column,
                  operator: operator,
                  value: value,
                  selectColumn,
                  selectOperator,
                  selectValue,
                  project: null,
                })
              }}
              options={[
                {
                  label: t('Update column'),
                  value: 'Update column',
                },
                {
                  label: t('Insert into new column'),
                  value: 'Insert into new column',
                },
              ]}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              className={`basic-single`}
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
            />
          </Col>
          <Col xl={5} md={6} className="mt-1">
            {project?.value === 'Insert into new column' && (
              <Form.Control
                className="h-100 py-2 nb-input-soft mt-0"
                ref={freeInputRef}
                onChange={(v) =>
                  updateFreeInput({
                    column,
                    operator,
                    value: value,
                    selectColumn,
                    selectOperator,
                    selectValue,
                    project: v.currentTarget.value,
                  })
                }
                style={{ backgroundColor: '#EBEBEB' }}
                defaultValue={transformation?.project ?? ''}
                placeholder={'New column name'}
              />
            )}
          </Col>
        </Row>
        <Row>
          {showDeleteButton && (
            <Col className="active-icon mt-1" xs={2}>
              <DeleteRowButton
                onClick={onDelete}
                tooltip={t('Delete transformation')}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default function ConfigureTransform({
  id,
  actionLabel = '',
  configuration,
  onFinish,
  close,
}) {
  const [transformations, setTransformations] = useState(
    configuration.transform ?? [
      {
        column: null,
        operator: null,
        value: null,
        selectColumn: null,
        selectOperator: null,
        selectValue: null,
      },
    ],
  )

  const { getEdges, getNode } = useReactFlow()
  const [inputNode, setInputNode] = useState(null)
  const [sample, setSample] = useState(null)
  const columns = useRef(null)
  const operators = useRef(null)
  const filterOperators = useRef(null)
  const { t } = useTranslation()
  useEffect(
    () => {
      const inputEdge = getEdges().find(
        (e) => e.targetHandle === `${id}_uniq_target`,
      )
      if (!inputEdge) {
        NotificationManager.error(t('Missing connection'))
        close()
      } else {
        const inputNode = getNode(inputEdge.source)
        if (!inputNode?.data?.valid) {
          NotificationManager.error(
            t('Connected nodes are invalid, configure them first'),
          )
          close()
        } else {
          if (!inputNode?.data?.sample) {
            NotificationManager.error('Nodes missing sample data')
            close()
          } else {
            columns.current = inputNode.data.sample.columns.map((c) => ({
              value: c,
              label: c,
            }))
            filterOperators.current = Object.keys(operationsFilter).map(
              (k) => ({ label: k, value: k }),
            )
            operators.current = Object.entries(transformationOperations).map(
              ([k, v]) => ({
                value: k,
                label: (
                  <div className="d-flex align-items-center position-relative">
                    <OverlayTrigger
                      rootClose={true}
                      trigger={['hover', 'focus']}
                      placement="auto"
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (
                        <Tooltip
                          {...props}
                          className={`popover-help-tooltip ${
                            props.className ?? ''
                          }`}
                        >
                          <div>{t(v.tooltip)}</div>
                        </Tooltip>
                      )}
                    >
                      <span>
                        <BsInfoCircle size={15} />
                      </span>
                    </OverlayTrigger>
                    <span className="mx-2">{k}</span>
                  </div>
                ),
              }),
            )
            setInputNode(inputNode)
          }
        }
      }
    },
    // eslint-disable-next-line
    [],
  )

  const updateTransformations = useCallback(
    (
      index,
      {
        column,
        operator,
        value,
        selectColumn,
        selectOperator,
        selectValue,
        project,
      },
    ) => {
      setTransformations((t) => {
        t.splice(index, 1, {
          column,
          operator,
          value,
          selectColumn,
          selectOperator,
          selectValue,
          project,
        })
        return [...t]
      })
    },
    [setTransformations],
  )

  const deleteTransformation = useCallback(
    (index) => setTransformations((t) => t.filter((_, i) => i !== index)),
    [setTransformations],
  )

  if (!inputNode) return <Loading />

  const validTransformations = transformations
    .map((t) =>
      t.column &&
      t.operator &&
      (t.value !== null || !transformationOperations[t.operator.value].value)
        ? t
        : null,
    )
    .filter((t) => t)

  return (
    <Row>
      {transformations.map((t, i) => (
        <Col className="mt-2 py-2" key={i} xs={12}>
          <Transform
            transformation={t}
            column={t.column}
            columns={columns.current}
            operator={t.operator}
            operators={operators.current}
            filterOperators={filterOperators.current}
            value={t.value}
            selectColumn={t.selectColumn}
            selectOperator={t.selectOperator}
            selectValue={t.selectValue}
            onChange={(data) => updateTransformations(i, data)}
            onDelete={() => deleteTransformation(i)}
            showDeleteButton={transformations.length > 1}
          />
        </Col>
      ))}
      <Col
        className="mt-2 mb-3 d-flex justify-content-center align-items-center"
        xs={12}
      >
        <AddRowButton
          onClick={() => {
            setTransformations([
              ...transformations,
              {
                column: null,
                operator: null,
                value: null,
                selectColumn: null,
                selectOperator: null,
                selectValue: null,
              },
            ])
          }}
        />
      </Col>
      <Col className="mt-2" xs={12}>
        <span className="h4">
          <strong>{t('Preview')}</strong>
        </span>
      </Col>
      <Col
        className="my-2 filter-table-flow position-relative"
        style={{
          overflow: 'auto',
          position: 'relative',
          zIndex: 0,
        }}
      >
        {inputNode && (
          <TransformTable
            input={inputNode.data.sample}
            transformations={validTransformations}
            onDataLoad={(data) => setSample(data)}
          />
        )}
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!validTransformations.length || !sample}
          className="ggvfloat-md-end mb-4"
          onClick={() => {
            onFinish(sample, {
              input: inputNode.id,
              transform: transformations,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
