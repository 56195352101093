import { forwardRef } from 'react'
import { Form } from 'react-bootstrap'

const NBCheck = forwardRef(
  ({ label = null, children, labelProps = {}, ...props }, ref) => {
    return (
      <Form.Check
        ref={ref}
        type={'checkbox'}
        label={
          <div
            {...labelProps}
            className={`label-nb-check smallp ms-1 w-100`}
            onClick={(e) => {
              e.currentTarget?.parentNode?.parentNode
                ?.querySelector('input')
                .click()
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {label ?? children}
          </div>
        }
        {...props}
        className={`nb-check ${props.className ?? ''}`}
      />
    )
  },
)

export default NBCheck
