import { useState } from 'react'
import Confetti from 'react-confetti'

export default function useConfetti() {
  const [timeoutCft, setTimeoutCft] = useState({
    confetti: <></>,
  })

  return {
    getConfetti: (duration, onFinish = () => {}, props = {}) => {
      if (duration < 1) return timeoutCft.confetti

      if (!timeoutCft.timeout)
        setTimeoutCft((p) => ({
          timeout: setTimeout(() => {
            setTimeoutCft((p) => ({ confetti: <></> }))
            onFinish()
          }, duration),
          confetti: <Confetti {...props} />,
        }))

      return timeoutCft.confetti
    },
    confetti: timeoutCft.confetti,
  }
}
