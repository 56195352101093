import React, { createContext, useContext, useEffect, useState } from 'react'
import useStoreState from '../hooks/UseStoreState'

const NavContext = createContext({
  showNav: true,
  setShowNav: () => {},
  mode: 'default',
  setMode: () => {},
})

export function NavProvider({ children }) {
  const [showNav, setShowNav] = useState(true)
  const [{ mode }, setMode] = useStoreState({ key: 'currentMode', mode: null })
  const searchParams = new URL(window.location.href).searchParams
  const [currentTheme, setCurrentTheme] = useState(null)
  const theme = searchParams.get('theme')

  useEffect(() => {
    if (theme) {
      const newTheme = `theme-${theme}`
      if (newTheme === currentTheme) return

      if (currentTheme)
        document.querySelector('body').classList.remove(currentTheme)
      document.querySelector('body').classList.add(newTheme)
      setCurrentTheme(newTheme)
    }
  }, [theme, currentTheme])

  const value = {
    showNav,
    setShowNav,
    mode,
    setMode: (mode) =>
      setMode((d) => {
        if (d?.mode !== mode) return { mode }
        return d
      }),
  }
  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}

export function useNav() {
  return useContext(NavContext)
}
