import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FaObjectGroup } from 'react-icons/fa'
import { VscSymbolOperator } from 'react-icons/vsc'
import { useTranslation } from 'react-i18next'
import NextbrainSelect, { Option } from '../../NextbrainSelect'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import { DeleteRowButton } from '../../../utils/ui'
import AggregateTable from './AggregateTable'
import MMMInsightHeading from '../../model-summary/MMMInsightHeading'

const AGGREGATE_FUNCTIONS = [
  { value: 'sum', label: 'Sum' },
  { value: 'mean', label: 'Mean' },
  { value: 'median', label: 'Median' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
  { value: 'std', label: 'Standard Deviation' },
  { value: 'var', label: 'Variance' },
  { value: 'concatenate', label: 'Concatenate' },
]

const AGGREGATE_FUNCTIONS_DICT = AGGREGATE_FUNCTIONS.reduce((dict, f) => {
  dict[f.value] = f
  return dict
}, {})

function Aggregate({ column, func, onChange, onDelete }) {
  const funcLabel = AGGREGATE_FUNCTIONS_DICT[func]?.label
  return (
    <Row className="aggregation-dataflow">
      <Col
        className="d-flex align-items-center position-relative"
        xs={12}
        style={{
          maxWidth: 'calc(100% - 70px)',
          minWidth: 'calc(100% - 70px)',
        }}
      >
        <NextbrainSelect
          value={{
            label: funcLabel ? `Aggregate ${column} by ${funcLabel}` : column,
            value: func,
          }}
          onChange={(value) => onChange(value?.value)}
          options={AGGREGATE_FUNCTIONS}
          className="w-100"
          components={{
            Option,
          }}
          allowSelectAll={true}
        />
      </Col>
      <Col
        className="d-flex align-items-center pe-0 justify-content-end"
        xs={1}
        style={{ maxWidth: '70px' }}
      >
        <DeleteRowButton onClick={onDelete} />
      </Col>
    </Row>
  )
}

function AggregateColumns({
  groupedBy,
  grouped,
  columns,
  onChange,
  defaultBehavior,
  onDefaultBehavior,
  ...props
}) {
  const { t } = useTranslation()

  const empty = !groupedBy.length

  const usedColumns = new Set(
    grouped.map((g) => g.value).concat(groupedBy.map((g) => g.value)),
  )

  return (
    <Row {...props} className={`justify-content-between`}>
      <Col
        xs={12}
        className="d-flex align-items-end"
        style={{
          maxWidth: '400px',
          opacity: empty ? 0.5 : 1,
          marginLeft: '3px',
        }}
      >
        <NextbrainSelect
          value={null}
          onChange={(value) => {
            if (value?.value)
              onChange([
                ...grouped,
                {
                  column: value.value,
                  func: grouped?.[grouped?.length - 1]?.func ?? 'sum',
                },
              ])
          }}
          className=""
          options={columns
            .filter((c) => !usedColumns.has(c))
            .map((c) => ({ value: c, label: c }))}
          components={{
            Option,
          }}
          placeholder={
            empty ? (
              <div className="d-inline-flex align-items-center">
                <VscSymbolOperator size={22} color="white" className="me-1" />
                {t('Select first columns to aggregate')}
              </div>
            ) : (
              <div className="d-inline-flex align-items-center">
                <VscSymbolOperator size={22} color="white" className="me-1" />
                {t('Add column to aggregate')}
              </div>
            )
          }
          isDisabled={empty}
        />
      </Col>
      <Col
        xs={12}
        style={{
          maxWidth: '250px',
          minWidth: '250px',
          marginLeft: '3px',
        }}
      >
        <Row>
          <Col className="mb-2 position-relative mt-1" xs={12}>
            {t('Default behavior')}
            <MMMInsightHeading
              title=""
              style={{
                maxWidth: '50px',
                position: 'absolute',
                left: '-40px',
                top: 'calc(50% - 20px)',
              }}
              className="d-flex align-items-center h-auto"
              description={
                <Row>
                  <Col xs={12}>
                    <Row className="d-flex align-items-center h-100 text-start">
                      <Col xs={12}>
                        <p>
                          {t('The default behavior is used when there is a')}
                          <strong> {t('need to aggregate columns')} </strong>
                          {t('which have')}{' '}
                          <strong>
                            {' '}
                            {t('not been explicitly configured by the user')}
                          </strong>{' '}
                          {t('to be aggregated')}
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p>
                          {t(
                            'Numeric columns can be grouped using multiple functions, including',
                          )}
                          <strong> {t('addition')}</strong>,
                          <strong> {t('mean')}</strong>,
                          <strong> {t('median')}</strong>,
                          <strong> {t('minimal')} </strong>
                          {t('or')} <strong> {t('maximal')} </strong>{' '}
                          {t('value')},
                          <strong> {t('standard deviation')}</strong>,
                          <strong> {t('variance')}</strong>,{t('and')}{' '}
                          <strong> {t('concatenate')}</strong>.
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p>
                          {t(
                            'Text based columns can be concatenated under in sum and categories will be concatenated in a series of unique values.',
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col xs={12}>
            <NextbrainSelect
              value={defaultBehavior}
              onChange={(value) =>
                value?.value ? onDefaultBehavior(value) : null
              }
              className=""
              options={AGGREGATE_FUNCTIONS}
              components={{
                Option,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}></Col>
      <Col className="mt-3" xs={12}>
        <Row>
          {grouped.map(({ column, func }, i) => (
            <Col className="mb-3" key={column} md={12} xl={6}>
              <Aggregate
                column={column}
                func={func}
                onChange={(func) => {
                  const newGrouped = [...grouped]
                  newGrouped[i] = { column, func }
                  onChange(newGrouped)
                }}
                onDelete={() => {
                  grouped.splice(i, 1)
                  onChange([...grouped])
                }}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default function ConfigureAggregate({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [node, setNode] = useState(null)
  const [sample, setSample] = useState(null)
  const joinTargets = useRef([])

  const [defaultBehavior, setDefaultBehavior] = useState(
    configuration?.defaultBehavior ?? AGGREGATE_FUNCTIONS[0],
  )
  const [selectedIds, setSelectedIds] = useState(
    configuration?.selectedIds ?? [],
  )
  const [aggregations, setAggregations] = useState(
    configuration?.aggregations ?? [],
  )

  useEffect(() => {
    setAggregations((aggregations) => {
      const ids = new Set(selectedIds.map((i) => i.value))
      return aggregations.filter((a) => !ids.has(a.column))
    })
  }, [selectedIds])

  useEffect(() => {
    const inputEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_uniq_target`,
    )

    if (!inputEdge) {
      NotificationManager.error('Missing connection')
      close()
    } else {
      const inputNode = getNode(inputEdge.source)
      if (!inputNode?.data?.valid) {
        NotificationManager.error(
          t('Connected nodes are invalid, configure them first'),
        )
        close()
      } else {
        if (!inputNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          joinTargets.current = inputNode.data.sample.columns.map((c) => ({
            value: c,
            label: c,
          }))
          setNode(inputNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!node) return <Loading />
  return (
    <Row className="flex-column" style={{ minHeight: '60vh' }}>
      <Col xs={12}>
        <Row className="aggregation-dataflow-head">
          <Col className="my-2" xs={'auto'}>
            <FaObjectGroup size={20} className="me-2" />
            <strong>{t('Aggregate columns by')}</strong>
          </Col>
          <Col
            xs={'auto'}
            className="ps-0"
            style={{ maxWidth: '400px', minWidth: '400px' }}
          >
            <NextbrainSelect
              value={selectedIds}
              onChange={(value) => setSelectedIds(value ?? [])}
              className=""
              options={joinTargets.current}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              allowSelectAll={true}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-3" xs={12}>
        <AggregateColumns
          groupedBy={selectedIds}
          grouped={aggregations}
          columns={node.data.sample.columns}
          onChange={setAggregations}
          defaultBehavior={defaultBehavior}
          onDefaultBehavior={setDefaultBehavior}
        />
      </Col>
      <>
        <Col className="mt-2" xs={12}>
          <span className="h4">
            <strong>{t('Preview')}</strong>
          </span>
        </Col>
        <Col
          className="mb-2 merge-table-flow position-relative"
          style={{
            overflow: 'auto',
            position: 'relative',
            zIndex: 0,
            minHeight: 'max(25vh, 270px)',
            maxHeight: 'max(25vh, 270px)',
          }}
        >
          <AggregateTable
            input={node.data.sample}
            aggregateBy={selectedIds}
            aggregations={aggregations}
            onDataLoad={(data) => setSample(data)}
            defaultBehavior={defaultBehavior?.value ?? 'sum'}
          />
        </Col>
      </>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample}
          className="config-button mb-4"
          onClick={() => {
            onFinish(sample, {
              input: node.id,
              selectedIds: selectedIds,
              aggregations: aggregations,
              defaultBehavior: defaultBehavior,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
