import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../providers/AuthProvider'

import './trialheader.css'
const DISABLE_LIMITS = process.env?.['REACT_APP_DISABLELIMITS'] === '1'

export default function TrialHeader() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  let isTrial = false
  let isFree = true
  let selectedCompany = null

  if (DISABLE_LIMITS) return <></>

  for (let company of user?.company ? [user?.company] : []) {
    if (company?.subscription?.payment_type === 'trial') {
      isTrial = true
      selectedCompany = company
    } else if (company?.subscription?.payment_status === 'paid') {
      // If it is not trial and it is paid
      isTrial = false
      isFree = false
      selectedCompany = company
      break
    }
    if (company?.subscription) {
      isFree = false
    }
  }

  if (isTrial) {
    const untilStr = (selectedCompany.subscription.to_date?.split('T') ?? [
      '',
    ])[0]

    const today = new Date()
    let until = new Date()
    until.setDate(until.getDate() + 1) // tomorrow

    if (untilStr !== '') {
      const untilData = untilStr.split('-')
      until = new Date(
        parseInt(untilData[0]),
        parseInt(untilData[1]) - 1,
        parseInt(untilData[2]),
      )
    }

    const diffTime = Math.abs(until - today)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    return (
      <Row className="px-0 mx-0">
        <Col md={12} className="trial">
          <span>
            {t('You are now on')} <strong>{t('TRIAL')}</strong>.{' '}
            <strong>{diffDays}</strong> {t('days left')} ({t('until')}{' '}
            <strong>{untilStr === '' ? t('TOMORROW') : untilStr}</strong>)
          </span>
          <strong
            className="upgrade-now"
            onClick={() => navigate('/plan?tab=0')}
          >
            {t('Upgrade it now!')}
          </strong>
        </Col>
      </Row>
    )
  }

  if (isFree)
    return (
      <Row className="px-0 mx-0">
        <Col md={12} className="trial">
          <span>
            {t('You are now on the')} <strong>{t('FREE plan')}</strong>.{'   '}
          </span>
          <strong
            className="upgrade-now"
            onClick={() => navigate('/plan?tab=0')}
          >
            {t('Upgrade it now!')}
          </strong>
        </Col>
      </Row>
    )

  return <></>
}
