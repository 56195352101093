import { request } from './base'

export async function getPodsAndQueues({ token, signout }) {
  return request({
    endpoint: `/internal/pods-and-queues`,
    method: 'GET',
    token,
    signout,
  })
}
