import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Spreadsheet from 'react-spreadsheet'
import { Parser as FormulaParser } from 'hot-formula-parser'
import { useQuery } from 'react-query'
import shortHash from 'short-hash'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useDebouncedCallback } from 'use-debounce'

import { useAuth } from '../../providers/AuthProvider'
import { round, readableNumber } from '../utils/formating'
import { categoryToColor } from '../model-content/NextbrainSelect'
import DataTypeIcon from '../data-types/DataTypeIcon'
import { getCellValue } from './SpreadsheetUtils'
import {
  getSpreadsheet,
  saveSpreadsheet,
  predictModel,
} from '../../services/model'

import 'bootstrap/dist/css/bootstrap.min.css'
import './spreadsheet.css'
import { zip } from '../../util/other'

export default function NBSpreadsheet({ model }) {
  const { signout, token } = useAuth()
  const { t } = useTranslation()
  const [dataHash, setDataHash] = useState(null)
  const [dataSpreadsheet, setDataSpreadsheet] = useState([])
  const [predictedDataSpreadsheet, setPredictedDataSpreadsheet] = useState([])
  const [dataToPredict, setDataToPredict] = useState({})
  const [isPredicting, setIsPredicting] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  const registry = useRef({})

  const formulaParser = useMemo(() => new FormulaParser(), [])
  const featureImportance =
    model?.details?.feature_importance?.reduce((acc, item) => {
      acc[item.feature] = item.importance
      return acc
    }, {}) ?? {}

  const validColumns =
    model &&
    model.status === 'trained' &&
    model.dataset &&
    model.dataset.columns_order
      ? model.dataset.columns_order.filter(
          (column) =>
            column in model.columns_active &&
            column in model.dataset.statistics &&
            column !== model.target &&
            ((featureImportance?.[column] ?? 0) > 0.001 ||
              model?.dataset?.statistics?.[column]?.logical_type ===
                'Datetime'),
        )
      : []

  const updateQuery = useDebouncedCallback(async () => {
    if (!model?.id || Object.keys(dataToPredict).length === 0) return

    const rows = dataToPredict.rows
    const values = generateValuesFromSpreadsheet(dataSpreadsheet)
    const finalNonEmptyRows = getNonEmptyRows(values)

    for (const [i, row] of rows.entries()) {
      for (let j = 0; j < row.length; j++) {
        try {
          const newValue = getCellValue(formulaParser, dataSpreadsheet, {
            row: i + 1,
            column: j,
          })
          row[j] = (newValue ?? row[j])?.toString()
        } catch (error) {
          console.error(error)
        }
      }
    }

    let response = {}
    try {
      setIsPredicting(true)
      const newRows = rows.map((row) => {
        const key = JSON.stringify(row)
        return registry.current[key]
          ? [row, key, registry.current[key]]
          : [row, key, null]
      })
      const requirePrediction = newRows.filter(([r, k, p]) => !p)
      if (requirePrediction.length) {
        response = await predictModel(
          model.id,
          {
            header: dataToPredict.header,
            rows: requirePrediction.map((r) => r[0]),
          },
          token,
          signout,
        )
        setIsPredicting(false)
        if (response.status === 400) {
          NotificationManager.warning(
            response?.response?.detail ?? 'Failed to launch prediction',
          )
          return
        }
        zip([response.predictions, requirePrediction]).forEach(([p, r]) => {
          r[2] = p
          registry.current[r[1]] = p
        })
      }
      setIsPredicting(false)
      response.predictions = newRows.map((n) => n[2])
    } catch (error) {
      console.error(error)
      return
    }

    const parsedResponse =
      response.predictions?.map((prediction) => {
        const isDatetime =
          model?.dataset?.final_column_status?.[model?.target] === 'Datetime'
        let value = prediction?.[0]
        if (isDatetime) value = new Date(value).toLocaleString()
        else if (!isNaN(parseFloat(prediction?.[0])))
          value = round(parseFloat(prediction?.[0]), 4)
        const confidence = prediction[1] ? ` (${prediction[1]}%)` : ''
        return [
          {
            value: (
              <>
                <strong className="text-success">{value}</strong>
                <span>{confidence}</span>
              </>
            ),
            textValue: [value, confidence],
            readOnly: true,
          },
        ]
      }) ?? []

    const finalResponse = new Array(dataSpreadsheet.length - 1)

    for (const [count, rowIndex] of finalNonEmptyRows.entries()) {
      finalResponse[rowIndex] = parsedResponse[count]
    }

    const finalPredictedDataSpreadsheet = [
      [columnToHeader(model.target)],
    ].concat(
      finalResponse.map((row) => {
        if (row === undefined) {
          return [
            {
              value: '',
              readOnly: true,
            },
          ]
        }
        return row
      }),
    )
    setPredictedDataSpreadsheet(finalPredictedDataSpreadsheet)

    const dataToSave = {
      columnsData: [],
      predictionData: [],
      columnNames: [...validColumns, model?.target],
    }

    for (const index of finalNonEmptyRows) {
      dataToSave.columnsData.push(
        dataSpreadsheet[index + 1]?.map((v) => v?.value),
      )
      dataToSave.predictionData.push(
        finalPredictedDataSpreadsheet[index + 1]?.map((v) => v?.textValue),
      )
    }
    saveSpreadsheet({
      modelId: model.id,
      spreadsheet: dataToSave,
      token,
      signout,
    })
  }, 1000)

  const columnToType = model?.dataset?.final_column_status ?? {}

  const getRandomValueCategoricalColumn = (column) => {
    const uniqueValues = model.dataset.categorical_to_unique[column]
    if (!uniqueValues) return null
    return uniqueValues[Math.floor(Math.random() * uniqueValues.length)]
  }

  const getCurrentDate = () => {
    let now = new Date()
    let tzOffset = now.getTimezoneOffset() * 60 * 1000
    return new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
  }

  const getDefaultValue = (column, mode = 'mean') => {
    if (mode === 'mean') {
      switch (model.dataset.final_column_status[column]) {
        case 'Integer':
          return model.dataset.statistics[column].mode
        case 'Double':
        case 'Float':
          return round(model.dataset.statistics[column].mode, 4)
        case 'Categorical':
          return getRandomValueCategoricalColumn(column)
        case 'Datetime':
          return getCurrentDate()
        default:
          return 0
      }
    }
    if (mode === 'max') {
      switch (model.dataset.final_column_status[column]) {
        case 'Integer':
          return model.dataset.statistics[column].max
        case 'Double':
        case 'Float':
          return round(model.dataset.statistics[column].max, 4)
        case 'Categorical':
          return getRandomValueCategoricalColumn(column)
        case 'Datetime':
          return getCurrentDate()
        default:
          return 0
      }
    }
    if (mode === 'min') {
      switch (model.dataset.final_column_status[column]) {
        case 'Integer':
          return model.dataset.statistics[column].min
        case 'Double':
        case 'Float':
          return round(model.dataset.statistics[column].min, 4)
        case 'Categorical':
          return getRandomValueCategoricalColumn(column)
        case 'Datetime':
          return getCurrentDate()
        default:
          return 0
      }
    }
  }

  const columnToHeader = (column) => ({
    value: (
      <div className="d-inline-block cursor-normal">
        <OverlayTrigger
          rootClose
          trigger={['hover', 'focus']}
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => <Tooltip {...props}>{column}</Tooltip>}
        >
          <div
            className="d-inline-block text-truncate"
            style={{ maxWidth: 120 }}
          >
            {column}
          </div>
        </OverlayTrigger>
        <div className="Spreadsheet__columnd-and-type mt-2">
          <span
            align="left"
            className="ms-2 Spreadsheet__badge-datatype"
            style={{
              color: categoryToColor[columnToType[column]],
              backgroundColor: `${categoryToColor[columnToType[column]]}22`,
            }}
          >
            <div className="d-flex align-items-center">
              {columnToType[column] === 'Categorical' ? (
                <OverlayTrigger
                  rootClose
                  trigger={['hover', 'focus', 'click']}
                  placement="bottom"
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <div>
                        <strong>Values</strong>
                      </div>
                      <div className="allCategories">
                        {model.dataset.categorical_to_unique[column].map(
                          (v) => (
                            <div key={v}>{v}</div>
                          ),
                        )}
                      </div>
                    </Tooltip>
                  )}
                >
                  <span>{columnToType[column]}</span>
                </OverlayTrigger>
              ) : (
                <span>{columnToType[column]}</span>
              )}
              <DataTypeIcon
                className="ms-2 mt-1"
                size={19}
                color={categoryToColor[columnToType[column]]}
                type={columnToType[column]}
              />
            </div>
          </span>
        </div>
      </div>
    ),
    readOnly: true,
    className: 'Spreadsheet__column-header',
  })

  const generateValuesFromSpreadsheet = (spreadsheet) => {
    return spreadsheet?.slice(1)?.map((row) => {
      return row
        ?.map((cell) => {
          const cellValue = cell?.value
          if (cellValue === null || cellValue === undefined) return null
          if (!isNaN(parseFloat(cellValue))) {
            return parseFloat(cellValue)
          }
          return cellValue
        })
        ?.concat(new Array(Math.max(validColumns.length - row.length, 0)))
    })
  }

  const getNonEmptyRows = (values) => {
    values = JSON.parse(JSON.stringify(values))
    const response = values
      .map((row, index) => {
        if (row === undefined || row === null) return null
        if (row?.some((cell) => cell === null || cell === undefined)) {
          return null
        }
        return index
      })
      .filter((row) => row !== null)
    return response
  }

  const launchPrediction = () => {
    const values = generateValuesFromSpreadsheet(dataSpreadsheet)
    const finalNonEmptyRows = getNonEmptyRows(values)
    const finalNonEmptyRowsSet = new Set(finalNonEmptyRows)
    const newShortHash = shortHash(
      JSON.stringify(values.filter((_, i) => finalNonEmptyRowsSet.has(i))),
    )

    setDataHash((prevDataHash) => {
      if (prevDataHash === newShortHash) return prevDataHash

      const rows = values.filter((row, i) => {
        return finalNonEmptyRowsSet.has(i) && row
      })
      console.log(
        'Launching prediction',
        '| rows:',
        rows,
        '|',
        finalNonEmptyRowsSet,
      )

      setDataToPredict({
        header: validColumns,
        rows: rows,
      })

      return newShortHash
    })
  }

  useQuery([`predicted-query-model-${model?.id}`, dataToPredict], updateQuery, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  })

  useEffect(() => {
    if (!model) return

    const initialData = [
      validColumns.map((column) => columnToHeader(column)),
      validColumns.map((column) => ({
        value: getDefaultValue(column, 'min').toString(),
      })),
      validColumns.map((column) => ({
        value: getDefaultValue(column).toString(),
      })),
      validColumns.map((column) => ({
        value: getDefaultValue(column, 'max').toString(),
      })),
    ].concat(new Array(6))

    setDataSpreadsheet(initialData)

    const initialPredictedData = [[columnToHeader(model.target)]].concat(
      new Array(9).fill([{ readOnly: true }]),
    )

    setPredictedDataSpreadsheet(initialPredictedData)

    getSpreadsheet({ modelId: model.id, token, signout }).then((data) => {
      if (!data) {
        return
      }

      const { columnsData, predictionData, columnNames } = data

      if (
        !Array.isArray(columnNames) ||
        JSON.stringify(columnNames) !==
          JSON.stringify([...validColumns, model?.target])
      )
        return

      const newData = [
        validColumns.map((column) => columnToHeader(column)),
      ].concat(
        columnsData.map((row) => {
          return row.map((cell) => {
            return {
              value: cell,
            }
          })
        }),
      )

      const newPredictedData = [[columnToHeader(model.target)]].concat(
        predictionData.map((row) => {
          return row.map((cell) => {
            return {
              value: (
                <>
                  <strong className="text-success">{cell[0]}</strong>
                  <span>{cell[1]}</span>
                </>
              ),
              textValue: cell,
              readOnly: true,
            }
          })
        }),
      )

      // If length of rows is less than 10, add empty rows
      if (newData.length < 10) {
        newData.push(...new Array(10 - newData.length))
      }
      // Same for predicted data, but in this case they must be readOnly
      if (newPredictedData.length < 10) {
        newPredictedData.push(
          ...new Array(10 - newPredictedData.length).fill([{ readOnly: true }]),
        )
      }

      setDataSpreadsheet(newData)
      setPredictedDataSpreadsheet(newPredictedData)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  useEffect(() => {
    if (!model || !isPredicting) return
    const loadingData = [[columnToHeader(model.target)]].concat(
      new Array(predictedDataSpreadsheet.length - 1).fill([
        { readOnly: true, value: t('Loading...') },
      ]),
    )

    setPredictedDataSpreadsheet(loadingData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, isPredicting])

  const leftSpreadsheetMemo = (
    <Spreadsheet
      className="leftSpreadsheet"
      data={dataSpreadsheet}
      formulaParser={formulaParser}
      onChange={(newData) => {
        const values = generateValuesFromSpreadsheet(newData)
        const finalNonEmptyRows = new Set(getNonEmptyRows(values))
        // Get only non empty rows from values to create the shortHash
        const newShortHash = shortHash(
          JSON.stringify(values.filter((_, i) => finalNonEmptyRows.has(i))),
        )

        if (dataHash === newShortHash) return

        setDataSpreadsheet(newData)
      }}
      onModeChange={(mode) => {
        if (mode !== 'view') return
        launchPrediction()
      }}
      onSelect={(data) => {
        const selection = []
        for (const eachSelection of data) {
          const { row, column } = eachSelection
          if (row === 0 || dataSpreadsheet[row] === undefined) continue
          const value = dataSpreadsheet[row][column]?.value
          if (value === undefined) continue
          selection.push(value)
        }
        setSelectedValues(selection)
      }}
    />
  )

  const sumValues = selectedValues.reduce((acc, value) => {
    const valueAsFloat = parseFloat(value)
    if (isNaN(valueAsFloat)) return acc
    return acc + valueAsFloat
  }, 0)

  const minValues = selectedValues.reduce((acc, value) => {
    const valueAsFloat = parseFloat(value)
    if (isNaN(valueAsFloat)) return acc
    if (acc === null) return valueAsFloat
    if (valueAsFloat < acc) return valueAsFloat
    return acc
  }, null)

  const maxValues = selectedValues.reduce((acc, value) => {
    const valueAsFloat = parseFloat(value)
    if (isNaN(valueAsFloat)) return acc
    if (acc === null) return valueAsFloat
    if (valueAsFloat > acc) return valueAsFloat
    return acc
  }, null)

  return (
    <>
      <Row>
        <Col
          xs={7}
          sm={8}
          md={9}
          lg={10}
          onMouseEnter={() => {
            document.getElementsByClassName('leftSpreadsheet')[0].onscroll = (
              e,
            ) => {
              document.getElementsByClassName('rightSpreadsheet')[0].scrollTop =
                e.target.scrollTop
            }
          }}
          onMouseLeave={() => {
            document.getElementsByClassName('leftSpreadsheet')[0].onscroll =
              null
          }}
        >
          {leftSpreadsheetMemo}
        </Col>
        <Col
          xs={5}
          sm={4}
          md={3}
          lg={2}
          onMouseEnter={() => {
            document.getElementsByClassName('rightSpreadsheet')[0].onscroll = (
              e,
            ) => {
              document.getElementsByClassName('leftSpreadsheet')[0].scrollTop =
                e.target.scrollTop
            }
          }}
          onMouseLeave={() => {
            document.getElementsByClassName('rightSpreadsheet')[0].onscroll =
              null
          }}
        >
          <Spreadsheet
            className="rightSpreadsheet hide-numbers"
            data={predictedDataSpreadsheet}
            columnLabels={[t('Predicted value')]}
            onSelect={(data) => {
              const selection = []
              for (const eachSelection of data) {
                const { row } = eachSelection
                if (row === 0 || predictedDataSpreadsheet[row] === undefined)
                  continue
                const value = predictedDataSpreadsheet[row][0]?.textValue
                if (value === undefined) continue
                selection.push(value[0])
              }
              setSelectedValues(selection)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <Button
            onClick={() => {
              setDataSpreadsheet((oldData) => [...oldData, {}])
              setPredictedDataSpreadsheet((oldData) => [
                ...oldData,
                { readOnly: true, value: '' },
              ])
            }}
          >
            {t('Add row')}
          </Button>
          <span className="text-secondary mx-2 float-right">
            {t(
              'Introduce a complete a row and press ENTER to apply the prediction',
            )}
          </span>
        </Col>
        <Col md={2}>
          {selectedValues.length > 0 && minValues !== null ? (
            <>
              <span>Sum: {readableNumber(round(sumValues, 2))}</span>
              <br />
              <span>Min: {readableNumber(round(minValues, 2))}</span>
              <br />
              <span>Max: {readableNumber(round(maxValues, 2))}</span>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  )
}
