import {
  FaSpinner,
  FaQuestion,
  FaBell,
  FaCheckCircle,
  FaCloudUploadAlt,
} from 'react-icons/fa'
import { ImFileEmpty } from 'react-icons/im'
import { useTranslation } from 'react-i18next'

export default function ModelStatusIcon({ status, ...props }) {
  const { t } = useTranslation()
  switch (status.toLowerCase()) {
    case 'training':
      return (
        <FaSpinner
          title={t('The model is training')}
          size={12}
          {...props}
          className={`training-spinner-models ${props.className ?? ''}`}
        />
      )
    case 'importing':
      return (
        <FaCloudUploadAlt
          title={t('Importing')}
          size={16}
          {...props}
          className={`importing-spinner-models ${props.className ?? ''}`}
        />
      )
    case 'loading':
      return (
        <FaSpinner
          title={t('The model is loading')}
          size={12}
          {...props}
          className={`training-spinner-models  ${props.className ?? ''}`}
        />
      )
    case 'trained':
      return (
        <FaCheckCircle
          title={t('The model is ready')}
          size={16}
          {...props}
          style={{ ...(props?.style ?? {}), marginTop: '2px' }}
        />
      )
    case 'created':
    case 'readytotrain':
      return (
        <ImFileEmpty
          title={t('The model is ready to be trained')}
          color="var(--nextbrain-white-font)"
          size={12}
          {...props}
        />
      )
    case 'error':
      return (
        <FaBell
          title={t('Error training model')}
          color="#ff444488"
          size={12}
          {...props}
        />
      )
    case 'dataset-error':
      return (
        <FaBell
          title={t('Corrupt dataset')}
          color="#ff444488"
          size={12}
          {...props}
        />
      )
    default:
      return (
        <FaQuestion
          title={`${t('Unknown status:')} ${status}`}
          size={12}
          {...props}
        />
      )
  }
}
