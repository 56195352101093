import React from 'react'
import { Row, Col, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { SiGooglesheets } from 'react-icons/si'

export default function Connector({
  name = 'Google Sheets',
  description = 'Connect to Google Sheets',
  icon = <SiGooglesheets style={{ color: '#0F9D58', fontSize: 35 }} />,
  onClick = () => {},
  isPremium = false,
  disabled,
  disabledMessage,
  ...props
}) {
  const element = (
    <Col
      xs={12}
      md={6}
      xl={4}
      {...props}
      className={`my-3 py-0 ${disabled ? 'opacity-50' : ''} ${
        props?.className ?? ''
      }`}
    >
      <OverlayTrigger
        rootClose={true}
        trigger={isPremium ? ['hover', 'focus'] : []}
        placement="top"
        overlay={(props) => (
          <Popover {...props}>
            <div className="p-2" style={{ fontSize: 20 }}>
              Upgrade your plan to use this feature
            </div>
          </Popover>
        )}
      >
        <div
          className={`px-3 py-3 connector ${props.className ?? ''} ${
            disabled ? 'pe-none' : ''
          }`}
          onClick={disabled ? null : onClick}
        >
          {isPremium && (
            <div className="premium-connector-ribbon">
              <span>UPGRADE</span>
            </div>
          )}
          <Row
            className="align-items-center text-center"
            style={{ minHeight: 80 }}
          >
            <Col xs={12}>
              <span style={{ fontSize: 20 }}>
                {icon} {name}
              </span>
            </Col>
            <Col>
              <span
                className="text-secondary"
                style={{ fontSize: 12, bottom: 0 }}
              >
                {description}
              </span>
            </Col>
          </Row>
        </div>
      </OverlayTrigger>
    </Col>
  )

  if (disabled) {
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="top"
        overlay={(props) => (
          <Tooltip {...props}>
            <div className="p-2" style={{ fontSize: 20 }}>
              {disabledMessage}
            </div>
          </Tooltip>
        )}
      >
        {element}
      </OverlayTrigger>
    )
  }

  return element
}
