import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveWaffle } from '@nivo/waffle'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { testAccuracy } from '../../../util/models'
import { round } from '../../utils/formating'

function adaptData(data, barKey = 'Importance') {
  const percentToScale = (percent, minv, maxv) => {
    if (maxv < minv) {
      let aux = maxv
      maxv = minv
      minv = aux
      return maxv - (maxv - minv) * percent
    }
    return (maxv - minv) * percent + minv
  }

  const MIN_RGB = {
    r: 50,
    g: 140,
    b: 255,
  }

  const MAX_RGB = {
    r: 25,
    g: 35,
    b: 75,
  }

  const maxValue = data.reduce(
    (p, c) => ({ [barKey]: p[barKey] > c[barKey] ? p[barKey] : c[barKey] }),
    { [barKey]: 0 },
  )[barKey]

  const percentToRgbStr = (percent) => {
    let imp = percent / maxValue
    return `rgb(${parseInt(
      percentToScale(imp, MIN_RGB.r, MAX_RGB.r),
    )}, ${parseInt(percentToScale(imp, MIN_RGB.g, MAX_RGB.g))}, ${parseInt(
      percentToScale(imp, MIN_RGB.b, MAX_RGB.b),
    )})`
  }

  const adaptColumnName = (name) => {
    if (name.startsWith('ln_') || name.startsWith('sq_'))
      name = name.substring(3)
    return name.length > 20 ? name.substring(0, 19) + '...' : name
  }

  return data.map((d) => ({
    column: adaptColumnName(d.column),
    [barKey]: round(d[barKey], 1),
    color: d.color ? d.color : percentToRgbStr(d[barKey]),
  }))
}

export function ConfigPerformance({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col>
          <Form.Control
            className="performance-title"
            defaultValue={`${config.title ?? 'Performance'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph type:</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            className="performance-graph-type raw"
            defaultValue={`${config.graphType ?? 'col'}`}
          >
            <option value="pie">Pie chart</option>
            <option value="col">Bar chart</option>
            <option value="coh">Bar chart(Horizontal)</option>
            <option value="waf">Waffle</option>
            {/* <option value='tex'>Text</option> */}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="performance-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 10}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button
            onClick={() =>
              onFinish({
                layout: { h: 9, w: 3, x: 0, y: 0 },
                ...config,
                graphType: $('.performance-graph-type').val(),
                title: $('.performance-title').val(),
                graphFontSize:
                  Number.parseInt($('.performance-graph-font-size').val()) ??
                  12,
              })
            }
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function PPie({ model, config }) {
  const data = [
    {
      id: 'Our model',
      label: `Our model`,
      value: round(testAccuracy(model), 1),
      color: 'hsl(207, 70%, 50%)',
    },
    {
      id: 'Simpler model',
      label: `Simpler model`,
      value: round(model.baseline * 100, 1),
      color: 'rgba(108, 117, 125, 0.5)',
    },
  ].filter((d) => d.value)

  return data ? (
    <ResponsivePie
      data={data}
      tooltip={({ datum }) => (
        <BasicTooltip
          id={`${datum.id}`}
          value={`${datum.value}%`}
          color={datum.color}
          enableChip
        />
      )}
      margin={{ top: 10, right: 0, bottom: 20, left: 120 }}
      cornerRadius={5}
      activeOuterRadiusOffset={3}
      colors={(d) => d.data.color}
      labelTextColor={'red'}
      borderWidth={1}
      enableArcLinkLabels={false}
      arcLabelsTextColor={'#fff'}
      arcLabel={(d) => `${d.value} %`}
      legends={[
        {
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -120,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: 'var(--nextbrain-widget-axis-legend)',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  ) : (
    <>No data</>
  )
}

function PCol({ model, config }) {
  const barKey = 'Accuracy'
  const data = adaptData(
    [
      {
        column: 'Our model',
        Accuracy: round(testAccuracy(model), 1),
        color: 'hsl(207, 70%, 50%)',
      },
      {
        column: 'Simpler model',
        Accuracy: round(model.baseline * 100, 1),
        color: 'rgba(108, 117, 125, 0.5)',
      },
    ],
    barKey,
  ).filter((e) => e.Accuracy)

  const horizontal = config.graphType === 'coh'
  const legend = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  }

  return (
    <ResponsiveBar
      data={data}
      tooltip={(props) => (
        <BasicTooltip
          id={`${props.id}`}
          value={`${props.value}%`}
          color={props.color}
          enableChip
        />
      )}
      keys={[barKey]}
      indexBy="column"
      enableGridY={false}
      margin={{ top: 10, right: 10, bottom: 50, left: horizontal ? 120 : 10 }}
      groupMode="grouped"
      layout={horizontal ? 'horizontal' : 'vertical'}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => d.data.color}
      axisTop={null}
      axisRight={null}
      axisLeft={horizontal ? legend : null}
      axisBottom={legend}
      labelSkipHeight={0}
      labelTextColor="#fff"
      legends={[]}
      role="application"
      ariaLabel="Bar chart"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in column: ' + e.indexValue
      }}
      label={(d) => `${d.value} %`}
      labelSkipWidth={50}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        ({ bars, labelSkipWidth }) => {
          return (
            <g>
              {bars.map(({ width, height, y, data }, index) => {
                // only show this custom outer label on bars that are too small
                return width < labelSkipWidth ? (
                  <text
                    key={`${data.value}_${index}`}
                    transform={`translate(${width + 10}, ${y + height / 2})`}
                    textAnchor="left"
                    dominantBaseline="central"
                    color="#000"
                    fontWeight="bold"
                  >
                    {`${data.value ?? ''} %`}
                  </text>
                ) : null
              })}
            </g>
          )
        },
      ]}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
    />
  )
}

function PWaf({ model, config }) {
  const data = [
    {
      id: 'Our model',
      label: `Our model`,
      value: round(testAccuracy(model), 1),
      color: 'hsl(207, 70%, 50%)',
    },
    {
      id: 'Simpler model',
      label: `Simpler model`,
      value: round(model.baseline * 100, 1),
      color: 'rgba(108, 117, 125, 0.5)',
    },
  ].filter((d) => d.value)

  return data ? (
    <ResponsiveWaffle
      data={data}
      total={100}
      rows={10}
      columns={10}
      margin={{ top: 10, right: 5, bottom: 20, left: 125 }}
      colors={(d) => d.color}
      animate={true}
      motionStiffness={90}
      motionDamping={11}
      theme={{
        fontSize: config.graphFontSize,
        textColor: 'var(--nextbrain-widget-graph-legend)',
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-widget-axis-legend)',
            },
          },
        },
      }}
      legends={[
        {
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -125,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: 'var(--nextbrain-widget-axis-legend)',
          symbolSize: 20,
        },
      ]}
    />
  ) : (
    <>No data</>
  )
}

// eslint-disable-next-line
function PText({ model, config }) {
  //watodo 🤷
}

export function WidgetPerformance({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const graph = () => {
    switch (config.graphType) {
      case 'pie':
        return <PPie model={model} config={config} />
      case 'col':
      case 'coh':
        return <PCol model={model} config={config} />
      case 'waf':
        return <PWaf model={model} config={config} />
      case 'tex':
      default:
        return <></>
    }
  }

  const csvData = [
    ['model', 'accuracy'],
    ['Our model', testAccuracy(model)],
    ['Simpler model', model.baseline * 100],
  ]

  return (
    <Row
      className="w-100 h-100 data-holder"
      id={id}
      data-csv={encodeURIComponent(JSON.stringify(csvData))}
      data-filename={`performance__${model.id}`}
    >
      <Col
        className="header-data-distribution d-inline-block text-truncate widget-title"
        xs={12}
      >
        {config.title}
      </Col>
      <Col className="mt-2" xs={12} style={{ height: '30px' }}>
        {model && model.status === 'trained' && model.baseline !== null ? (
          testAccuracy(model) < parseInt(model.baseline * 100) ? (
            <div className="mb-2">
              <span className="px-2">
                There is not that much predictive power
              </span>
            </div>
          ) : (
            <span className="px-2">
              <strong className="medium-text">
                {round(
                  model && model.status === 'trained'
                    ? testAccuracy(model) / parseInt(model.baseline * 100)
                    : 0,
                  1,
                )}
                x
              </strong>{' '}
              better than a simpler model
            </span>
          )
        ) : (
          <></>
        )}
      </Col>
      <Col
        xs={12}
        className={`${config.graphType === 'tex' ? 'd-none' : ''}`}
        style={{ height: 'calc(100% - 60px)' }}
      >
        {graph()}
      </Col>
    </Row>
  )
}
