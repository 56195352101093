import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import TextareaAutosize from 'react-textarea-autosize'
import {
  FaAngleDoubleRight,
  FaArrowLeft,
  FaArrowRight,
  FaFlagCheckered,
  FaSave,
  FaTable,
  FaTrash,
  FaUndo,
} from 'react-icons/fa'
import { VscServerProcess } from 'react-icons/vsc'
import { GiTransform } from 'react-icons/gi'

import Loading from '../../loading/LoadingSmall'
import ErrorNB from '../../loading/ErrorNB'
import { awaitTaskCall } from '../../../services/base'
import {
  assistantQuestion,
  confirmModel,
  editAssistantResponse,
  getAssistantResponse,
  getModelById,
  getModelRepository,
  gptSetColumnsDescription,
  gptSetGPTSuggestedColumns,
  gptSpecificTask,
  setModelRepository,
} from '../../../services/model'
import { useAuth } from '../../../providers/AuthProvider'
import NextbrainSelect from '../NextbrainSelect'
import { InlineEdit } from '../../inline-edit/InlineEdit'

import '../new-model-assistant/NewModelAssistant.css'
import './ModelAssistant.css'

import {
  DESCRIPTION_PROMPT,
  COLUMN_DESCRIPTION_PROMPT,
  DATA_CLEANING_PROMPT,
} from './assistantConfig'
import { t } from 'i18next'
import { getCsvStatus } from '../../../services/csv'
import { useModels } from '../../../providers/ModelProvider'
import PbarProgress from '../../pbar-progress/PbarProgress'
import CrashFallback from '../../crash-fallback/CrashFallback'
import { ImCog } from 'react-icons/im'
import { getRepositories } from '../../../services/document'
import { RiShareBoxFill } from 'react-icons/ri'
import RoleDisable from '../../utils/RoleDisable'

function ModelAssistantDescription({
  model,
  suggestions,
  isLoading,
  onConfirm,
  onCancel = () => {},
  editMode,
}) {
  const { token, signout } = useAuth()
  const queryClient = useQueryClient()

  const profiles = [
    'Data analyst (senior)',
    'Data analyst (junior)',
    'Data scientist',
    'Engineer',
    'Marketing specialist',
    'Executive director',
  ]
  const { t } = useTranslation()
  const [currentContext, setCurrentContext] = useState(
    suggestions?.context ?? '',
  )
  const [currentProblem, setCurrentProblem] = useState(
    suggestions?.problem ?? '',
  )

  const { data: repos } = useQuery(
    ['document-repositories', token],
    async () => {
      const repos = getRepositories({ token, signout })
      return repos
    },
  )

  const [currentRepo, setCurrentRepo] = useState(null)

  useQuery(['modelAssistant-repo', model?.id], async () => {
    const repo = await getModelRepository({
      modelId: model?.id,
      token,
      signout,
    })
    if (!currentRepo && repo) {
      setCurrentRepo({
        label: repo.name,
        value: repo.id,
      })
    }
    return repo
  })

  const [currentProfile, setCurrentProfile] = useState(() => {
    return suggestions?.profile
      ? { label: t(suggestions.profile), value: suggestions.profile }
      : { label: t(profiles[0]), value: profiles[0] }
  })

  const baseProfileOptions = useMemo(() => {
    return profiles.map((profile) => ({
      label: t(profile),
      value: profile,
    }))
    // eslint-disable-next-line
  }, [t])
  return (
    <Row className="mx-2">
      {isLoading ? (
        <Loading />
      ) : !suggestions ? (
        <ErrorNB />
      ) : (
        <>
          <Col xs={12}>
            <Row>
              <Col
                xs={12}
                className="h4 text-center mt-3 d-flex align-items-center justify-content-center"
              >
                {t('General context')}{' '}
                <VscServerProcess className="mx-2" size={30} />
              </Col>
              <Col
                xs={12}
                className="smallp text-center d-flex align-items-center justify-content-center mb-3 color-white"
              >
                {t('Please review carefully before proceeding')}
              </Col>
            </Row>
            <Row className={`${editMode ? 'mb-3' : 'model-assistant-content'}`}>
              <Col xs={12}>
                <label className="w-100 mb-1">
                  {t('Context of your data')}
                  <TextareaAutosize
                    maxLength={4096}
                    className="nb-input-soft match-select-height w-100 p-2"
                    rows={1}
                    value={currentContext}
                    onChange={(e) => setCurrentContext(e.target.value)}
                  />
                </label>
              </Col>
              <Col className="mt-2" xs={12}>
                <label className="w-100 mb-1">
                  {t('Problem to solve')}
                  <TextareaAutosize
                    rows={1}
                    maxLength={4096}
                    className="nb-input-soft  match-select-height w-100 p-2"
                    value={currentProblem}
                    onChange={(e) => setCurrentProblem(e.target.value)}
                  />
                </label>
              </Col>
              <Col className="mt-2" xs={12}>
                <label className="w-100 mb-1">
                  {t('Your profile')}
                  <NextbrainSelect
                    creatable={true}
                    className="basic-single w-100 mt-1"
                    classNamePrefix="select"
                    placeholder={t(
                      'Select profile or write your own and press enter',
                    )}
                    isSearchable={true}
                    isClearable={true}
                    value={currentProfile}
                    onChange={(v) => setCurrentProfile(v)}
                    options={baseProfileOptions}
                  />
                </label>
              </Col>
              <Col className="mt-2" xs={12}>
                <label className="w-100 mb-1">
                  <span className="d-inline-flex align-items-center">
                    {t('Knowledge-Base repository')}
                    <span
                      title={t('View selected repository')}
                      className={`icon-btn ms-2 ${
                        currentRepo ? '' : 'pe-none opacity-50'
                      }`}
                      onClick={() => {
                        window.open(
                          `/knowledge-base/${currentRepo?.value}`,
                          '_blank',
                        )
                      }}
                    >
                      <RiShareBoxFill size={20} />
                    </span>
                  </span>
                  <div className="smallp ">
                    <i>{t('Knowledge base repository for model')}</i>
                  </div>
                  <NextbrainSelect
                    creatable={false}
                    className="basic-single w-100 mt-1"
                    classNamePrefix="select"
                    placeholder={t('Knowledge base repository for model')}
                    options={(repos ?? []).map((r) => ({
                      value: r.id,
                      label: r.name,
                    }))}
                    isSearchable={true}
                    isClearable={true}
                    value={currentRepo}
                    onChange={(v) => setCurrentRepo(v)}
                  />
                </label>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="mt-3 dflex-center">
            {editMode ? (
              <>
                <Button
                  className="p-2 original me-1 empty-secondary"
                  variant="secondary"
                  onClick={onCancel}
                >
                  <div className="mb-0">{t('Cancel')}</div>
                </Button>
                <RoleDisable>
                  <Button
                    className="p-2 ms-1"
                    disabled={!currentContext || !currentProblem}
                    onClick={async (e) => {
                      await setModelRepository({
                        modelId: model?.id,
                        repositoryId: currentRepo?.value,
                        token,
                        signout,
                      }).then((r) => {
                        if (r?.ok) {
                          queryClient.invalidateQueries([
                            'modelAssistant-repo-insights',
                            model?.id,
                          ])
                          queryClient.invalidateQueries([
                            'modelAssistant-repo',
                            model?.id,
                          ])
                        }
                      })

                      onConfirm({
                        context: currentContext,
                        problem: currentProblem,
                        profile: currentProfile?.value,
                      })
                    }}
                  >
                    <div className="mb-0 ">
                      {t('Save')}
                      <FaSave className="ms-2" />
                    </div>
                  </Button>
                </RoleDisable>
              </>
            ) : (
              <>
                <Button
                  className="p-2 original me-1"
                  variant="secondary"
                  disabled={true}
                >
                  <div className="h5 mb-0">
                    <FaArrowLeft className="me-2" />
                    {t('Back')}
                  </div>
                </Button>
                <Button
                  className="p-2 ms-1 "
                  disabled={!currentContext || !currentProblem}
                  onClick={async () => {
                    await setModelRepository({
                      modelId: model?.id,
                      repositoryId: currentRepo?.value,
                      token,
                      signout,
                    }).then((r) => {
                      if (r?.ok) {
                        queryClient.invalidateQueries([
                          'modelAssistant-repo',
                          model?.id,
                        ])
                        queryClient.invalidateQueries([
                          'modelAssistant-repo-insights',
                          model?.id,
                        ])
                      }
                    })
                    onConfirm({
                      context: currentContext,
                      problem: currentProblem,
                      profile: currentProfile?.value,
                    })
                  }}
                >
                  <div className="h5 mb-0 ">
                    {t('Next')}
                    <FaArrowRight className="ms-2" />
                  </div>
                </Button>
              </>
            )}
          </Col>
        </>
      )}
    </Row>
  )
}

function ModelAssistantColumns({
  columns,
  edited,
  deletedColumns,
  setDeletedColumns,
  onConfirm,
  onBack,
  ...props
}) {
  const { t } = useTranslation()

  const data = columns || {}

  return (
    <Row className="mx-2">
      <Row>
        <Col
          xs={12}
          className="h4 text-center mt-3 d-flex align-items-center justify-content-center"
        >
          {t('Column description and pruning')}{' '}
          <FaTable className="mx-2" size={28} />
        </Col>
        <Col
          xs={12}
          className="smallp text-center d-flex align-items-center justify-content-center mb-3 color-white"
        >
          {t('Please review carefully before proceeding')}
        </Col>
      </Row>
      <Col className="model-assistant-content" xs={12}>
        <table
          {...props}
          style={{
            ...(props.style ?? {}),
            maxWidth: '100%',
            overflow: 'hidden',
          }}
          className={`w-100 assistant-column-table overflow-auto position-relative ${props?.className}`}
        >
          <thead
            className="position-sticky"
            style={{
              top: '0px',
            }}
          >
            <tr>
              <th className="py-2 ps-1">
                <div style={{ minWidth: '150px' }}>{t('Column name')}</div>
              </th>
              <th className="py-2 ps-1 ">
                <div>{t('Column description (AI generated)')}</div>
              </th>
            </tr>
          </thead>
          <tbody className="w-100">
            {Object.keys(data || []).map((key, index) => (
              <tr
                key={key}
                className={`${deletedColumns?.[key] ? 'column-to-delete' : ''}`}
              >
                <td className="p-1">
                  <div className="w-auto ms-auto me-auto assistant-column-container">
                    {key}
                  </div>
                </td>
                <td
                  className="w-100  cursor-text assistant-description-container position-relative"
                  onClick={(e) => {
                    if (e.currentTarget === e.target && !deletedColumns?.[key])
                      e.target.querySelector('span').click()
                  }}
                >
                  <InlineEdit
                    className="w-100 h-100 d-block py-1 ps-1"
                    style={{ paddingRight: '60px' }}
                    text={edited[key] ?? data[key]}
                    area={true}
                    onEdit={(d) => {
                      edited[key] = d
                    }}
                  />
                  <div
                    className="assistant-columns-icon-delete-table"
                    onClick={() =>
                      setDeletedColumns({
                        ...deletedColumns,
                        [key]: !deletedColumns?.[key],
                      })
                    }
                  >
                    {deletedColumns?.[key] ? (
                      <FaUndo
                        className="icon-btn"
                        size={15}
                        title={t('Undo delete')}
                      />
                    ) : (
                      <FaTrash
                        className="icon-btn"
                        size={15}
                        title={t('Delete')}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
      <Col xs={12} className="mt-3 dflex-center">
        <Button
          className="p-2 original me-1"
          variant="secondary"
          onClick={onBack}
        >
          <div className="h5 mb-0">
            <FaArrowLeft className="me-2" />
            {t('Back')}
          </div>
        </Button>
        <Button className="p-2 ms-1" onClick={onConfirm}>
          <div className="h5 mb-0 ">
            {t('Next')}
            <FaArrowRight className="ms-2" />
          </div>
        </Button>
      </Col>
    </Row>
  )
}

function Task({ task, initialStatus, onChange }) {
  const checkRef = useRef()
  const [status, setStatus] = useState(initialStatus ?? true)

  return (
    <Row
      className="new-model-assistant-problem-task py-1 cursor-pointer"
      onClick={() => {
        onChange(task, !status)
        setStatus((status) => !status)
      }}
    >
      <Col
        xs={12}
        className="d-flex align-items-center"
        style={{ maxWidth: '55px' }}
      >
        <Form.Check
          ref={checkRef}
          type="switch"
          className="form-switch-flow"
          checked={status}
          onChange={() => {}}
        />
      </Col>
      <Col
        xs={12}
        className="d-flex align-items-center"
        style={{ maxWidth: 'calc(100% - 55px)' }}
      >
        <span>{task}</span>
      </Col>
    </Row>
  )
}

function ModelAssistantCleaning({
  tasks,
  status,
  onChange = () => {},
  onBack = () => {},
  onConfirm = () => {},
  ...props
}) {
  const { t } = useTranslation()
  tasks = tasks || []
  const [key, refresh] = useState(0)

  return (
    <Row className="mx-3">
      <Col
        xs={12}
        className="h4 text-center mt-3 d-flex align-items-center justify-content-center"
      >
        {t('Data preparation')} <GiTransform className="mx-2" size={28} />
      </Col>
      <Col
        xs={12}
        className="smallp text-center d-flex align-items-center justify-content-center mb-3 color-white"
      >
        {t('Please review carefully before proceeding')}
      </Col>
      <Col className="pb-3 position-relative model-assistant-content" xs={12}>
        <Row className="mb-1">
          <Col
            xs={12}
            className="d-flex align-items-center"
            style={{ maxWidth: '55px' }}
          >
            <Form.Check
              type="switch"
              className="form-switch-flow"
              defaultChecked={false}
              onChange={(e) => {
                onChange(
                  tasks.reduce((acc, task) => {
                    acc[task] = e.target.checked
                    return acc
                  }, {}),
                )
                refresh((n) => n + 1)
              }}
            />
          </Col>
        </Row>
        {tasks.length ? (
          tasks.map((task) => (
            <Task
              initialStatus={status?.[task] ?? true}
              key={task + `_${key}`}
              onChange={onChange}
              task={task}
            />
          ))
        ) : (
          <div className="dflex-center py-3">
            {t('You have an excellent dataset! No columns should be deleted')}
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-3 dflex-center">
        <Button
          className="p-2 original me-1"
          variant="secondary"
          onClick={onBack}
        >
          <div className="h5 mb-0">
            <FaArrowLeft className="me-2" />
            {t('Back')}
          </div>
        </Button>
        <Button className="p-2 ms-1" onClick={onConfirm}>
          <div className="h5 mb-0 ">
            {t('Next')}
            <FaArrowRight className="ms-2" />
          </div>
        </Button>
      </Col>
    </Row>
  )
}

function AssistantFeatures({
  newColumns,
  cancelledNewColumns,
  onBack,
  onConfirm,
  ...props
}) {
  const { t } = useTranslation()
  const tableRef = useRef()
  newColumns = newColumns || {}
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col
            xs={12}
            className="h4 text-center mt-3 d-flex align-items-center justify-content-center"
          >
            {t('New features')} <ImCog className="mx-2" size={28} />
          </Col>
          <Col
            xs={12}
            className="smallp text-center d-flex align-items-center justify-content-center mb-3 color-white"
          >
            {t('Please review carefully before proceeding')}
          </Col>
        </Row>
      </Col>
      <Col className="pb-3 position-relative model-assistant-content" xs={12}>
        <table
          {...props}
          style={{
            ...(props.style ?? {}),
          }}
          className={`w-100 overflow-auto assistant-column-table ${props?.className}`}
        >
          <thead
            className="position-sticky assistant-table-header-features"
            style={{
              top: '0px',
            }}
          >
            <tr>
              <th
                className="pt-2"
                style={{ minWidth: '100px', textAlign: 'center' }}
              >
                {t('Create')}
              </th>
              <th className="pt-2 ps-1">
                <div style={{ minWidth: '150px' }}>{t('Column name')}</div>
              </th>
              <th className="pt-2 ps-1">
                <div>{t('New column description')}</div>
              </th>
            </tr>
            <tr>
              <th className="check-table-columns new-model-assistant-problem-card-check-container">
                <div className="pb-2 dflex-center">
                  <Form.Check
                    type="switch"
                    style={{ marginTop: 0, cursor: 'pointer' }}
                    className="form-switch-flow"
                    onChange={(e) => {
                      const status = e.target.checked
                      const source = tableRef.current
                      const checks = [...source.querySelectorAll('input')]
                      checks.forEach((e) => status !== e.checked && e.click())
                    }}
                    defaultChecked={false}
                  />
                </div>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody ref={tableRef}>
            {Array.isArray(newColumns?.columns)
              ? (newColumns?.columns ?? [])
                  .filter((c) => c !== '0')
                  .map((column, index) => (
                    <tr
                      key={column.column_name}
                      className="cursor-pointer user-select-none"
                      onClick={(e) => {
                        if (e.target.type === 'checkbox') e.stopPropagation()
                        else
                          e.currentTarget
                            .querySelector('input[type="checkbox"]')
                            .click()
                      }}
                    >
                      <td>
                        <div className="dflex-center w-100 check-table-columns new-model-assistant-problem-card-check-container">
                          <Form.Check
                            type="switch"
                            style={{ marginTop: 0, cursor: 'pointer' }}
                            className="form-switch-flow"
                            onChange={(e) => {
                              cancelledNewColumns[column.column_name] =
                                !e.target.checked
                            }}
                            defaultChecked={
                              !(
                                cancelledNewColumns[column.column_name] ?? false
                              )
                            }
                          />
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="w-auto ms-auto me-auto assistant-column-container">
                          {column.column_name}
                        </div>
                      </td>
                      <td className="p-2">{column.description}</td>
                    </tr>
                  ))
              : Object.keys(newColumns)
                  .filter((c) => c !== '0')
                  .map((key, index) => (
                    <tr
                      key={key}
                      className="cursor-pointer user-select-none"
                      onClick={(e) => {
                        if (e.target.type === 'checkbox') e.stopPropagation()
                        else
                          e.currentTarget
                            .querySelector('input[type="checkbox"]')
                            .click()
                      }}
                    >
                      <td>
                        <div className="dflex-center w-100 check-table-columns new-model-assistant-problem-card-check-container">
                          <Form.Check
                            type="switch"
                            style={{ marginTop: 0, cursor: 'pointer' }}
                            className="form-switch-flow"
                            onChange={(e) => {
                              cancelledNewColumns[key] = !e.target.checked
                            }}
                            defaultChecked={
                              !(cancelledNewColumns[key] ?? false)
                            }
                          />
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="w-auto ms-auto me-auto assistant-column-container">
                          {key}
                        </div>
                      </td>
                      <td className="p-2">{newColumns[key]}</td>
                    </tr>
                  ))}
          </tbody>
        </table>
      </Col>
      <Col xs={12} className="mt-3 dflex-center">
        <Button
          className="p-2 original me-1"
          variant="secondary"
          onClick={onBack}
        >
          <div className="h5 mb-0">
            <FaArrowLeft className="me-2" />
            {t('Back')}
          </div>
        </Button>
        <Button className="p-2 ms-1" onClick={onConfirm}>
          <div className="h5 mb-0 ">
            {t('Finish')}
            <FaFlagCheckered className="ms-2" />
          </div>
        </Button>
      </Col>
    </Row>
  )
}

export default function ModelAssistant({
  skipButton,
  newModelId,
  setCheckModalTitle,
}) {
  const { token, signout, user } = useAuth()
  const { addModel, setActiveModel } = useModels()

  const [pBarData, setPBarData] = useState(null)

  const { data: model } = useQuery(
    ['modelAssistant-model', newModelId],
    async () => {
      const model = await getModelById(newModelId, token, signout)
      setPBarData(model?.pbar_data)
      switch (model?.dataset?.status) {
        case 'imported':
          setPBarData(null)
          return model
        case 'readyToImport':
        case 'importing':
          throw new Error('Dataset is not ready, retrying')
        default:
          setPBarData(null)
          return null
      }
    },
    { staleTime: Infinity, retry: true, retryDelay: 1500 },
  )

  useEffect(() => {
    if (model) {
      setCheckModalTitle(
        <Row className="h-100">
          <Col xs={12} className="d-flex align-items-center">
            {`${t('Assisted model creation')} ${model?.dataset?.name}`}
          </Col>
        </Row>,
      )
    }
  }, [model, setCheckModalTitle])

  const { data: _suggestions, isLoading: suggestionsIsLoading } = useQuery(
    ['modelAssistant-suggestions', model?.id],
    async () => {
      if (!model) return []
      const suggestions = await awaitTaskCall(assistantQuestion, 2000, 0, {
        modelId: model.id,
        prompt: DESCRIPTION_PROMPT,
        key: 'initialSuggestions',
        token,
        signout,
      }).catch((e) => {
        NotificationManager.error('Error in generating suggestions')
        return [
          {
            context: '',
            problem: '',
          },
          null,
        ]
      })
      if (suggestions?.error)
        return [
          {
            context: '',
            problem: '',
          },
          null,
        ]
      return [JSON.parse(suggestions?.data ?? null), suggestions]
    },
    { staleTime: Infinity },
  )
  const [suggestions, suggestionsResponse] = _suggestions ?? []

  const [description, setDescription] = useState(null)
  const [confirmedColumnStatus, setConfirmedColumnStatus] = useState(null)
  const [disabledTransformations, setDisabledTransformations] = useState({})
  const [
    confirmedDisabledTransformations,
    setConfirmedDisabledTransformations,
  ] = useState(null)
  const [cancelledNewColumns] = useState({ 0: true })

  useEffect(() => {
    if (description) {
      const copy = suggestionsResponse?.data
        ? {
            ...suggestionsResponse,
            data: JSON.stringify(description),
          }
        : {
            data: JSON.stringify(description),
            end_time: new Date().toISOString().slice(0, 23),
            error: false,
            key: 'initialSuggestions',
            start_time: new Date().toISOString().slice(0, 23),
            total_tokens: 983,
            user_id: user?.id,
          }
      editAssistantResponse({
        modelId: model?.id,
        key: 'initialSuggestions',
        data: copy,
        token,
        signout,
      }).then((r) => {
        if (!r?.ok) NotificationManager.error(t('Error updating context'))
      })
    }
    // eslint-disable-next-line
  }, [description])

  const { data: columnDescriptions, isLoading: columnDescriptionsIsLoading } =
    useQuery(
      ['modelAssistant-column-descriptions', model?.id, description],
      async () => {
        if (!model || !description) return null
        setColumnStatus({
          edited: {},
          deletedColumns: {},
        })
        let prompt = COLUMN_DESCRIPTION_PROMPT
        prompt = prompt.replace('$PROBLEM_REPLACE', description.problem)
        prompt = prompt.replace('$CONTEXT_REPLACE', description.context)
        prompt = prompt.replace(
          '$PROFILE_REPLACE',
          `${description?.profile}` ?? 'data scientist',
        )
        const descriptions = await awaitTaskCall(assistantQuestion, 2000, 0, {
          modelId: model.id,
          prompt: prompt,
          key: null,
          token,
          signout,
        }).catch((e) => {
          NotificationManager.error('Error in generating columns descriptions')
          return {}
        })
        if (descriptions?.error) return {}
        return JSON.parse(descriptions?.data ?? null)
      },
      { staleTime: Infinity },
    )

  const [columnStatus, setColumnStatus] = useState({
    edited: {},
    deletedColumns: {},
  })

  const getColumnConfig = () => {
    const deleted = Object.entries(confirmedColumnStatus?.deletedColumns ?? {})
      .filter(([_, v]) => v)
      .map(([k]) => k)

    const descriptions = Object.entries(columnDescriptions || {}).reduce(
      (acc, [k, v]) => {
        if (deleted.includes(k)) return acc
        acc[k] = confirmedColumnStatus?.edited?.[k] ?? v
        return acc
      },
      {},
    )

    return [descriptions, deleted]
  }

  const { data: transformations, isLoading: transformationsIsLoading } =
    useQuery(
      [
        'modelAssistant-column-transformations',
        model?.id,
        description,
        columnDescriptions,
        confirmedColumnStatus,
      ],
      async () => {
        if (
          !model ||
          !description ||
          !columnDescriptions ||
          !confirmedColumnStatus
        )
          return null

        setDisabledTransformations({})
        let prompt = DATA_CLEANING_PROMPT
        prompt = prompt.replace(
          '$PROFILE_REPLACE',
          `${description?.profile}` ?? 'data scientist',
        )
        prompt = prompt.replace('$PROBLEM_REPLACE', description.problem)
        prompt = prompt.replace('$CONTEXT_REPLACE', description.context)

        const [descriptions, deleted] = getColumnConfig()

        prompt = prompt.replace(
          '$COLUMNS_DESCRIPTION_REPLACE',
          Object.entries(descriptions)
            .map(([k, v]) => `${k}: ${v}`)
            .join('\n'),
        )

        if (deleted?.length)
          prompt = prompt.replace(
            '$COLUMNS_TO_DELETE_REPLACE',
            `\nThe following columns will be deleted by the user so do not consider and do not suggest changes or deletion under any case:\n${deleted.join(
              '\n',
            )}\n`,
          )
        else prompt = prompt.replace('$COLUMNS_TO_DELETE_REPLACE', '')
        const transformations = await awaitTaskCall(
          assistantQuestion,
          2000,
          0,
          {
            modelId: model.id,
            prompt: prompt,
            key: null,
            token,
            signout,
          },
        ).catch((e) => {
          NotificationManager.error('Error in generating transformations')
          return {}
        })
        if (transformations?.error) return []
        const result = JSON.parse(transformations?.data ?? null)
        setDisabledTransformations(
          result.reduce((acc, task) => {
            acc[task] = false
            return acc
          }, {}),
        )
        return result
      },
      { staleTime: Infinity },
    )

  const { data: newFeatures, isLoading: newFeaturesIsLoading } = useQuery(
    ['gpt-specific-task-gpt-columns', model?.id, transformations],
    async () => {
      if (!model?.id || !transformations) return null
      const res = await gptSpecificTask({
        modelId: model?.id,
        taskType: 'suggested_columns_with_description',
        token,
        signout,
      })

      if (!res || typeof res !== 'object')
        throw new Error('Invalid new columns')

      Object.keys(res).forEach((k) => (cancelledNewColumns[k] = true))
      return res
    },
    { staleTime: Infinity, retry: true, retryDelay: 1500 },
  )

  const [importing, setImporting] = useState(false)
  const [waitingForDataset, setWaitingForDataset] = useState(false)

  useEffect(
    () => {
      if (waitingForDataset) {
        const columnToDelete = getColumnConfig()[1]
        const dataCleaningtasks =
          transformations?.filter(
            (task) => disabledTransformations[task] !== false,
          ) ?? null
        let interval = {}

        confirmModel({
          modelId: newModelId,
          dataCleaningtasks,
          columnToDelete,
          token,
          signout,
        }).then((model) => {
          if (!model?.dataset) {
            NotificationManager.error(t('Error creating model'))
            setImporting(false)
            setWaitingForDataset(false)
          } else {
            interval.interval = setInterval(async () => {
              getCsvStatus({
                csvId: model.dataset.csv.id,
                token,
                signout,
              }).then((res) => {
                if (!res)
                  res = {
                    status: 'error',
                    description: 'Error confirming model, please try again',
                  }
                const { status, description } = res
                if (status === 'imported') {
                  getModelById(model.id, token, signout).then((finalModel) => {
                    addModel(finalModel)
                    setActiveModel(finalModel.id)
                    clearInterval(interval?.interval)
                  })
                } else if (status === 'error') {
                  NotificationManager.error('Error in importing model')
                  setImporting(false)
                  setWaitingForDataset(false)
                  clearInterval(interval?.interval)
                } else {
                  if (description.trim()) setImporting(description)
                }
              })
            }, 3000)
            return () => clearInterval(interval)
          }
        })
        return () => clearInterval(interval?.interval)
      }
    },
    // eslint-disable-next-line
    [waitingForDataset],
  )

  let [message, step] = !model
    ? [t('Preparing model'), 1]
    : suggestionsIsLoading
    ? [t('Generating suggestions'), 1]
    : columnDescriptionsIsLoading
    ? [t('Generating columns descriptions'), 2]
    : transformationsIsLoading
    ? [t('Generating possible dataset improvements'), 3]
    : newFeaturesIsLoading
    ? [t('Generating new features'), 4]
    : importing
    ? [importing, 5]
    : [
        null,
        !description
          ? 1
          : !confirmedColumnStatus
          ? 2
          : !confirmedDisabledTransformations
          ? 3
          : 4,
      ]

  useEffect(() => {
    if (model) {
      setCheckModalTitle(
        <Row className="h-100">
          <Col xs={12} className="d-flex align-items-center">
            <strong className="me-2 step-progress-model-assistant my-2 p-1 d-inline-flex align-items-center">
              {`${t('step')} ${step} / 5`}{' '}
              <FaAngleDoubleRight className="ms-1" />
            </strong>
            {`${t('Assisted model creation')} ${model?.dataset?.name}`}
          </Col>
        </Row>,
      )
    }
  }, [model, setCheckModalTitle, step])

  if (pBarData)
    return (
      <Row className="justify-content-center w-100">
        <Col xl={6} sm={12}>
          <PbarProgress
            model={{
              pbar_data: pBarData,
            }}
          />
        </Col>
      </Row>
    )

  if (message)
    return (
      <Row>
        <Col className="model-assistant-content" xs={12}>
          <Loading message={message} />
        </Col>
        <Col xs={12}>{skipButton}</Col>
      </Row>
    )
  return (
    <>
      <Row className="d-lg-none d-md-flex">
        <Col xs={12}>{skipButton}</Col>
      </Row>
      <>
        <CrashFallback message={<ErrorNB />}>
          {!description ? (
            <ModelAssistantDescription
              model={model}
              suggestions={description || suggestions}
              isLoading={suggestionsIsLoading}
              onConfirm={(description) => setDescription(description)}
            />
          ) : !confirmedColumnStatus ? (
            <ModelAssistantColumns
              columns={columnDescriptions}
              edited={columnStatus?.edited}
              deletedColumns={columnStatus?.deletedColumns}
              setDeletedColumns={(v) => {
                setColumnStatus((c) => ({
                  edited: c.edited,
                  deletedColumns: v,
                }))
              }}
              onConfirm={() =>
                setConfirmedColumnStatus(
                  JSON.parse(JSON.stringify(columnStatus)),
                )
              }
              onBack={() => {
                if (suggestions) {
                  suggestions.context = description.context
                  suggestions.problem = description.problem
                  suggestions.profile = description.profile
                }
                setDescription(null)
              }}
            />
          ) : !confirmedDisabledTransformations ? (
            <ModelAssistantCleaning
              status={disabledTransformations}
              tasks={transformations}
              onChange={(task, status) => {
                if (typeof task === 'object') {
                  setDisabledTransformations(task)
                  return
                }
                setDisabledTransformations((v) => ({
                  ...v,
                  [task]: status,
                }))
              }}
              onBack={() => {
                setConfirmedColumnStatus(null)
              }}
              onConfirm={async () => {
                setConfirmedDisabledTransformations(
                  JSON.parse(JSON.stringify(disabledTransformations)),
                )
              }}
            />
          ) : (
            <AssistantFeatures
              newColumns={newFeatures}
              cancelledNewColumns={cancelledNewColumns}
              onBack={() => {
                setConfirmedDisabledTransformations(null)
              }}
              onConfirm={async () => {
                setImporting(t('Importing model'))
                try {
                  const response = await gptSetGPTSuggestedColumns({
                    modelId: model?.id,
                    columns: Object.keys(newFeatures).filter(
                      (c) => !cancelledNewColumns?.[c] || c === '0' || c === 0,
                    ),
                    token,
                    signout,
                  })

                  if (!response?.ok) {
                    NotificationManager.error('Error in setting new features')
                    setImporting(false)
                  }

                  const columns = getColumnConfig()[0]
                  const res = await gptSetColumnsDescription({
                    modelId: newModelId,
                    columns: columns,
                    token,
                    signout,
                  })
                  if (!res?.ok) {
                    NotificationManager.error(
                      'Error in setting columns descriptions',
                    )
                    setImporting(false)
                  } else setWaitingForDataset(true)
                } catch (e) {
                  setImporting(false)
                }
              }}
            />
          )}
        </CrashFallback>
      </>
      <div
        className="d-lg-flex d-none"
        style={{
          position: 'absolute',
          marginTop: '-40px',
        }}
      >
        {skipButton}
      </div>
    </>
  )
}

export function EditContext({ model, onSave = () => {} }) {
  const { token, signout, user } = useAuth()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    ['model-context', model?.id],
    async () => {
      if (!model) return null
      const res = getAssistantResponse({
        modelId: model?.id,
        key: 'initialSuggestions',
        token,
        signout,
      })
      return res
    },
    { staleTime: Infinity },
  )

  if (isLoading) return <Loading />

  let baseContext = {
    context: '',
    problem: '',
  }

  try {
    const storedData = JSON.parse(data?.data)
    baseContext = {
      context: storedData?.context ?? '',
      problem: storedData?.problem ?? '',
      profile: storedData?.profile ?? '',
    }
  } catch (e) {}

  return (
    <ModelAssistantDescription
      model={model}
      suggestions={baseContext}
      isLoading={isLoading}
      editMode={true}
      onCancel={onSave}
      onConfirm={async (description) => {
        const copy = data?.data
          ? {
              ...data,
              data: JSON.stringify(description),
            }
          : {
              data: JSON.stringify(description),
              end_time: new Date().toISOString().slice(0, 23),
              error: false,
              key: 'initialSuggestions',
              start_time: new Date().toISOString().slice(0, 23),
              total_tokens: 983,
              user_id: user?.id,
            }
        await editAssistantResponse({
          modelId: model?.id,
          key: 'initialSuggestions',
          data: copy,
          token,
          signout,
        }).then((r) => {
          if (r?.ok) {
            queryClient.invalidateQueries(['model-context', model?.id])
            NotificationManager.success(t('Context updated'))
            onSave()
          } else {
            NotificationManager.error(t('Error updating context'))
          }
        })
      }}
    />
  )
}
