import { request } from './base'

export async function sendReport({ message, report, token, signout }) {
  return request({
    endpoint: `/model/report`,
    method: 'POST',
    body: { msg: message, metadata: report },
    contentType: 'json',
    token,
    signout,
  })
}

export async function solveReport({ id, message, token, signout }) {
  return request({
    endpoint: `/model/report/solved/${id}`,
    method: 'POST',
    body: { msg: message },
    contentType: 'json',
    token,
    signout,
  })
}

export async function deleteReport({ id, token, signout }) {
  return request({
    endpoint: `/model/report/${id}`,
    method: 'DELETE',
    contentType: 'json',
    token,
    signout,
  })
}
