import { ResponsiveSankey } from '@nivo/sankey'
import { Row } from 'react-grid-system'

const DEFAULT_DATA = {}

export function getColorsByTarget(data, target) {
  const VALID_COLORS = [
    'hsl(137, 70%, 50%)',
    'hsl(207, 70%, 50%)',
    'hsl(286, 70%, 50%)',
    'hsl(81, 70%, 50%)',
    'hsl(73, 70%, 50%)',
    'hsl(214, 70%, 50%)',
    //"hsl(250, 70%, 50%)"
  ]
  let targetToColor = {}
  const validOptions =
    data && data.nodes && data.nodes[target] ? [...data.nodes[target]] : []
  validOptions.push(`${target} Other`)
  for (const [i, option] of validOptions.entries()) {
    targetToColor[option] = VALID_COLORS[i % VALID_COLORS.length]
  }
  return {
    targetToColor,
    VALID_COLORS,
  }
}

function adaptData(data, target) {
  let adaptedLinks = {} // column value to target with max value and current max value

  const isString = (myVar) =>
    typeof myVar === 'string' || myVar instanceof String
  // Filter all links with not string type
  data.links = data.links.filter(
    (link) => isString(link.source) && isString(link.target),
  )

  for (const link of data.links) {
    // Skip if adapted link already added and have higher value
    if (
      link.source in adaptedLinks &&
      adaptedLinks[link.source].value >= link.value
    )
      continue
    adaptedLinks[link.source] = {
      target: link.target,
      value: link.value,
    }
  }

  const { targetToColor, VALID_COLORS } = getColorsByTarget(data, target)

  // Recursive source to color (Complexity: O(n))
  const sourceToColor = (source) => {
    let currentTarget
    try {
      currentTarget = adaptedLinks[source].target
    } catch {
      return targetToColor[source]
    }
    if (currentTarget.startsWith(`${target} (`)) {
      return targetToColor[currentTarget]
    }
    return sourceToColor(currentTarget)
  }

  let nodes = []
  let columnToIndex = {}
  for (const [i, column] of Object.keys(data.nodes).entries()) {
    columnToIndex[column] = i % VALID_COLORS.length
  }

  let validOptions = new Set()
  for (const columnOptions of Object.values(data.nodes)) {
    for (const option of columnOptions) {
      try {
        if (adaptedLinks[option].value < 5) continue
      } catch {}

      nodes.push({
        id: option,
        color: sourceToColor(option),
      })

      validOptions.add(option)
    }
  }

  return {
    links: data.links.filter(
      (link) =>
        link.value >= 5 &&
        validOptions.has(link.source) &&
        validOptions.has(link.target),
    ),
    nodes: nodes.filter((node) => node.color),
  }
}

export default function CustomSankey({
  data,
  header,
  target,
  height = 525,
  className = '',
  sankeyProps = {},
}) {
  if (data) {
    data = adaptData(data, target)
  } else {
    return (
      <div className={className}>
        {header ? header : <></>}
        <Row className="mt-2" style={{ height: `${height}px` }}>
          <div></div>
        </Row>
      </div>
    )
  }

  return (
    <div className={className}>
      {header ? header : <></>}
      <Row className="mt-2 mx-1" style={{ height: `${height}px` }}>
        <ResponsiveSankey
          data={data ? data : DEFAULT_DATA}
          margin={{ top: 20, right: 80, bottom: 20, left: 160 }}
          colors={(d) => d.color}
          sort="auto"
          nodeHoverOthersOpacity={0.35}
          nodeBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.8]],
          }}
          nodeBorderRadius={3}
          linkOpacity={0.5}
          linkHoverOpacity={0.7}
          labelPosition="outside"
          labelOrientation="horizontal"
          labelPadding={2}
          linkBlendMode={'normal'}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1]],
          }}
          legends={[]}
          {...sankeyProps}
        />
      </Row>
    </div>
  )
}
