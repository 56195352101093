import React, { useEffect, useState, useMemo } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { enforceValidation } from '../../../../util/validation'
import NextbrainSelect, { Option } from '../../NextbrainSelect'
import { NotificationManager } from 'react-notifications'
import { useReactFlow } from 'reactflow'
import Loading from '../../../loading/LoadingSmall'
import EnrichTable from './EnrichTable'
import { useQuery } from 'react-query'
import { getEnrichmentOption } from '../../../../services/csv'
import { useAuth } from '../../../../providers/AuthProvider'

const ENRICHMENTS = {
  weather: {
    multiple_columns_to_add: true,
    columns_to_add: [
      'tavg',
      'tmin',
      'tmax',
      'prcp',
      'snow',
      'wdir',
      'wspd',
      'wpgt',
      'pres',
      'tsun',
    ],
    columns_to_add_descriptions: {
      tavg: 'Average temperature',
      tmin: 'Minimum temperature',
      tmax: 'Maximum temperature',
      prcp: 'Precipitation',
      snow: 'Snow',
      wdir: 'Wind direction',
      wspd: 'Wind speed',
      wpgt: 'Peak gust',
      pres: 'Pressure',
      tsun: 'Sunshine',
    },
    args: [
      {
        name: 'date',
        description: 'The date to get the weather for',
        type: 'date',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: '2017-01-01 - 2018-01-01',
      },
      {
        name: 'place',
        description:
          'Location (use a single city/state/country format e.g. Madrid)',
        type: 'string',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: 'España, Madrid',
      },
    ],
  },
  GDP: {
    multiple_columns_to_add: true,
    // columns_to_add: {
    //   type: 'endpoint',
    //   url: 'gdp',
    // },
    columns_to_add: ['GDP'],
    columns_to_add_descriptions: {},
    args: [
      {
        name: 'Year',
        description: 'The year to get the GDP for',
        type: 'numeric',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: '2017',
      },
      {
        name: 'Country',
        description: 'The location where to get the GDP for',
        type: 'string',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: 'United states',
      },
    ],
  },
  'Consumer price index': {
    multiple_columns_to_add: true,
    // columns_to_add: {
    //   type: 'endpoint',
    //   url: 'gdp',
    // },
    note: 'The consumer price index is based on the average price of a basket of goods and services purchased by consumers, the reference year is 2010 and it is expressed as a % of the cost for the year to the reference.',
    columns_to_add: ['Consumer price index'],
    columns_to_add_descriptions: {},
    args: [
      {
        name: 'Year',
        description: 'The year to get the consumer price index for',
        type: 'numeric',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: '2017',
      },
      {
        name: 'Country',
        description: 'The location where to get the consumer price index for',
        type: 'string',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: 'United states',
      },
    ],
  },
  Geolocation: {
    multiple_columns_to_add: true,
    columns_to_add: ['country', 'region', 'city'],
    columns_to_add_descriptions: {
      country: 'Country (closest match)',
      region: 'Region (closest match if available)',
      city: 'City (closest match if available)',
    },
    args: [
      {
        name: 'Latitude',
        description: 'Numeric latitude for geolocation',
        type: 'numeric',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: '0.0',
      },
      {
        name: 'Longitude',
        description: 'Numeric longitude for geolocation',
        type: 'numeric',
        can_use_column: true,
        can_use_const: true,
        default: null,
        placeholder: '0.0',
      },
    ],
  },
}

function ArgFreeInput({ arg, onChange, value }) {
  let input = null
  console.log(arg)
  switch (arg.type) {
    case 'date':
      input = (
        <Form.Control
          type="date"
          className="nb-input match-select-height"
          placeholder={`e.g ${arg.placeholder}`}
          onBlur={(e) => {
            onChange(e.target.value)
          }}
          defaultValue={value}
        />
      )
      break
    case 'numeric':
      input = (
        <Form.Control
          type="number"
          className="nb-input match-select-height"
          placeholder={`e.g ${arg.placeholder}`}
          onKeyPress={enforceValidation({ numeric: true })}
          onBlur={(e) => {
            onChange(e.target.value)
          }}
          defaultValue={value}
        />
      )
      break
    default:
      input = (
        <Form.Control
          type="text"
          className="nb-input match-select-height"
          placeholder={`e.g ${arg.placeholder}`}
          onBlur={(e) => {
            onChange(e.target.value)
          }}
          defaultValue={value}
        />
      )
      break
  }

  return (
    <Row>
      <Col className="enforced-validation-container" xs={12}>
        {input}
      </Col>
    </Row>
  )
}

function TargetArg({ arg, config, setConfig, sample }) {
  const { t } = useTranslation()

  const isUsingConst =
    config?.args?.[arg?.name]?.use_const || !arg.can_use_column
  const canSwitch = arg.can_use_column && arg.can_use_const

  const constCheck = (
    <Form.Check
      checked={isUsingConst}
      type="switch"
      label={t('Use a constant value')}
      className="form-switch-share ps-0 mt-1 d-inline-block text-nowrap match-select-height"
      disabled={!canSwitch}
      onChange={(e) => {
        config.args = config.args || {}
        config.args[arg.name] = config.args[arg.name] || {}
        config.args[arg.name].value = null
        config.args[arg.name].use_const = e.target.checked
        config.args = { ...config.args }
        setConfig({ ...config })
      }}
    />
  )

  return (
    <Row>
      <Col xs={6} md={12}>
        <Row className="h-100 flex-row justify-content-center d-md-flex d-none mb-2">
          <Col xs={12} className="pe-0">
            <Row className="justify-content-between align-items-center">
              <Col className="" xs={'auto'}>
                {arg?.name}
              </Col>
              <Col
                className="smallp color-white d-inline-block text-truncate"
                xs={'auto'}
                style={{ textWrap: 'pretty', maxHeight: '30px' }}
              >
                <span>{constCheck}</span>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            title={arg?.description}
            className="d-inline-block text-truncate"
          >
            {arg?.description}
          </Col>
        </Row>
        <Row className="h-100 flex-column justify-content-center d-md-none d-flex">
          <Col className="" xs={12}>
            {arg?.name}
          </Col>
          <Col className="smallp color-white " xs={12}>
            {arg?.description}
          </Col>
        </Row>
      </Col>
      <Col xs={6} md={12}>
        <Row>
          <Col
            className="mb-1 d-md-none d-block"
            xs={'auto'}
            style={{ maxHeight: '30px' }}
          >
            {constCheck}
          </Col>
          <Col xs={12}>
            <div style={{ maxHeight: '50px', minHeight: '50px' }}>
              {isUsingConst ? (
                <ArgFreeInput
                  arg={arg}
                  value={config?.args?.[arg.name]?.value}
                  onChange={(value) => {
                    config.args = config.args || {}
                    config.args[arg.name] = config.args[arg.name] || {}
                    config.args[arg.name].value = value
                    config.args[arg.name] = { ...config.args[arg.name] }
                    config.args = { ...config.args }
                    setConfig({ ...config })
                  }}
                />
              ) : (
                <NextbrainSelect
                  value={config?.args?.[arg.name]?.value}
                  onChange={(value) => {
                    config.args = config.args || {}
                    config.args[arg.name] = config.args[arg.name] || {}
                    config.args[arg.name].value = value
                    config.args = { ...config.args }
                    setConfig({ ...config })
                  }}
                  options={sample?.columns.map((t) => ({ label: t, value: t }))}
                  closeMenuOnSelect={true}
                  isClearable={false}
                  className={`basic-single `}
                  classNamePrefix="select"
                  placeholder={`Select column to match with ${arg.name}`}
                  type={'dark'}
                />
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function validateConfig(target, config) {
  if (!target || !config) return false

  if (Array.isArray(target?.columns_to_add))
    if (
      !Array.isArray(config?.columns_to_add) ||
      config?.columns_to_add.length === 0
    )
      return false

  if (target?.args?.some((arg) => !config?.args?.[arg.name]?.value))
    return false

  return true
}

function TargetConfiguration({ target, config, setConfig, sample }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()

  const { data: columnsToAdd, isLoading } = useQuery(
    [target?.columns_to_add],
    async () => {
      if (target?.columns_to_add?.type === 'endpoint') {
        return (
          await getEnrichmentOption({
            option: target?.columns_to_add?.url,
            token,
            signout,
          })
        ).sort()
      }
      return target?.columns_to_add
    },
    { staleTime: Infinity },
  )

  if (
    Array.isArray(columnsToAdd) &&
    columnsToAdd.length === 1 &&
    (!config?.columns_to_add ||
      (Array.isArray(config?.columns_to_add) &&
        config?.columns_to_add.length === 0))
  ) {
    config.columns_to_add = [
      {
        label: columnsToAdd[0],
        value: columnsToAdd[0],
      },
    ]
  }

  if (!target) return null
  if (isLoading) return <Loading />

  return (
    <Row>
      {Array.isArray(columnsToAdd) && !target?.disable_columns_selection && (
        <Col className="mb-2" xs={12}>
          <Row className="mt-2">
            <Col className="dflex-center" xs={'auto'}>
              {t('Columns to add')}
            </Col>
            <Col xs={12} style={{ maxWidth: '450px' }}>
              <NextbrainSelect
                value={config?.columns_to_add}
                onChange={(value) =>
                  setConfig((c) => ({ ...c, columns_to_add: value }))
                }
                options={
                  columnsToAdd?.map((t) => ({
                    label: target?.columns_to_add_descriptions?.[t] ?? t,
                    value: t,
                  })) ?? []
                }
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isClearable={true}
                className={`basic-single `}
                classNamePrefix="select"
                isMulti={target?.multiple_columns_to_add}
                components={target?.multiple_columns_to_add ? { Option } : null}
                placeholder={'Select columns to add'}
                type={'dark'}
              />
            </Col>
          </Row>
        </Col>
      )}
      {Array.isArray(target?.args) &&
        target.args.map((arg) => (
          <Col className={'mt-3'} key={arg.name} xs={12} md={6}>
            <TargetArg
              arg={arg}
              config={config}
              setConfig={setConfig}
              sample={sample}
            />
          </Col>
        ))}
    </Row>
  )
}

export default function ConfigureEnrichment({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { getEdges, getNode } = useReactFlow()
  const [inputNode, setInputNode] = useState(null)

  const optionsTarget = useMemo(
    () =>
      Object.keys(ENRICHMENTS).map((key) => ({ label: t(key), value: key })),
    [t],
  )

  const [target, setTarget] = useState(
    configuration?.target ?? optionsTarget[0],
  )
  const [config, setConfig] = useState(configuration?.config ?? {})
  const [sample, setSample] = useState(null)

  const validConfig = validateConfig(ENRICHMENTS[target?.value], config)
  useEffect(() => {
    const inputEdge = getEdges().find(
      (e) => e.targetHandle === `${id}_uniq_target`,
    )

    if (!inputEdge) {
      NotificationManager.error('Missing connection')
      close()
    } else {
      const inputNode = getNode(inputEdge.source)
      if (!inputNode?.data?.valid) {
        NotificationManager.error(
          t('Connected nodes are invalid, configure them first'),
        )
        close()
      } else {
        if (!inputNode?.data?.sample) {
          NotificationManager.error('Nodes missing sample data')
          close()
        } else {
          setInputNode(inputNode)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!inputNode) return <Loading />
  return (
    <Row className="flex-column flex-nowrap" style={{ minHeight: '50vh' }}>
      <Col xs={12}>
        <Row className="h5">
          <Col
            className="dflex-center"
            xs="auto"
            style={{ maxWidth: 'calc(100% - 400px)' }}
          >
            <span className="theme-color">{t('Add to my table ')}</span>
          </Col>
          <Col xs="auto" style={{ maxWidth: '320px' }}>
            <div style={{ maxWidth: '320px', minWidth: '320px' }}>
              <NextbrainSelect
                value={target}
                onChange={(value) => {
                  setTarget(value)
                  setConfig({})
                  setSample(null)
                }}
                options={optionsTarget}
                hideSelectedOptions={false}
                isClearable={false}
                placeholder={'Select target'}
                type={'dark'}
              />
            </div>
          </Col>
          <Col
            className="dflex-center ms-2"
            xs="auto"
            style={{ maxWidth: '150px' }}
          >
            <span className="theme-color ">{t('data')}.</span>
          </Col>
        </Row>
      </Col>
      {ENRICHMENTS[target?.value]?.note && (
        <Col xs={12} className="smallp">
          {t(ENRICHMENTS[target.value].note)}
        </Col>
      )}
      <Col className="mt-3" xs={12}>
        <TargetConfiguration
          key={target?.value ?? 'empty'}
          config={config}
          setConfig={setConfig}
          target={ENRICHMENTS[target?.value]}
          sample={inputNode?.data?.sample}
        />
      </Col>
      <Col className="mt-3" xs={12}>
        <div className="h5 mb-0">{t('Preview')}</div>
      </Col>
      <Col
        className="d-inline-block mt-1"
        xs={12}
        style={{ minHeight: '250px', maxHeight: '250px', zIndex: '0' }}
      >
        <EnrichTable
          input={inputNode.data.sample}
          config={validConfig ? config : null}
          targetName={target?.value}
          onDataLoad={(data) => setSample(data)}
        />
      </Col>
      <Col className="mt-4 d-flex justify-content-end" xs={12}>
        <Button
          disabled={!sample || !validConfig}
          className="config-button"
          onClick={() => {
            onFinish(sample, {
              input: inputNode.id,
              target,
              config,
            })
          }}
        >
          {t(actionLabel)}
        </Button>
      </Col>
    </Row>
  )
}
