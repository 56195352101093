import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { defaultFormat } from '../utils/formating'

function getBins(summary, buckets) {
  if (Array.isArray(summary)) {
    const steps = Math.max(1, Math.ceil(summary.length / buckets))
    const result = summary.reduce(
      ({ bins, left, right, count }, [[start, end], total], index) => {
        if (!left) left = start
        right = end
        count += total
        if (!((index + 1) % steps)) {
          bins.push([
            defaultFormat({ num: left, wrap: true }),
            defaultFormat({ num: right, wrap: true }),
            count,
          ])
          left = null
          right = null
          count = 0
        }
        return { bins, left, right, count }
      },
      { bins: [], left: null, right: null, count: 0 },
    )

    if (result.left)
      result.bins.push([
        Math.round(result.left),
        Math.round(result.right),
        result.count,
      ])

    const bins = result.bins
    return [bins, bins.reduce((a, b) => a + b[2], 0)]
  }
  return []
}

export default function ViewDataResume({
  summary,
  width,
  height = 100,
  column,
  model,
  ...props
}) {
  if (!width || !height) return <></>
  const buckets = Math.max(1, Math.floor(width / 42))
  const [bins, total] = getBins(summary, buckets)

  try {
    if (Array.isArray(summary)) {
      const min = bins[0][0]
      const max = bins[bins.length - 1][1]
      return (
        <div
          style={{ minHeight: `${height}px`, maxHeight: `${height}px` }}
          className={`view-data-resume px-2 ${props?.className ?? ''}`}
        >
          <div
            style={{ minHeight: `${height - 35}px` }}
            className="h-100 w-100 view-data-resume-vertical-bar-container"
          >
            {bins.map(([l, r, c], i) => (
              <OverlayTrigger
                rootClose={true}
                key={i}
                trigger={['hover', 'focus']}
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`${props?.className ?? ''} pe-none`}
                  >
                    {c} elements
                    <>
                      <br />
                      {l} to {r}
                    </>
                  </Tooltip>
                )}
              >
                <div
                  className="resume-vertical-bar position-relative"
                  style={{
                    minHeight: `${3 + ((height - 35) * c) / total}px`,
                  }}
                >
                  {i === bins?.length - 1 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                      }}
                    >
                      {defaultFormat({ num: max, wrap: true })}
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            ))}
          </div>
          <div className="d-flex w-100  py-0 my-0 gy-0 justify-content-between">
            <div>{defaultFormat({ num: min, wrap: true })}</div>
          </div>
        </div>
      )
    } else if (summary && typeof summary === 'object') {
      const total = Object.values(summary).reduce((a, b) => a + b, 0)
      return (
        <div
          style={{
            minHeight: `${height}px`,
            maxHeight: `${height}px`,
            ...(props?.style ?? {}),
          }}
          className={`view-data-resume px-2 d-flex align-items-center position-relative flex-column justify-content-around ${
            props?.className ?? ''
          }`}
        >
          {Object.entries(summary).map(([k, v]) => (
            <div className="view-data-resume-row w-100" key={k}>
              <div className="d-flex justify-content-between">
                <div className="d-inline-block text-truncate">
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip
                        {...props}
                        className={`${props?.className ?? ''} pe-none`}
                      >
                        {k === 'Other categories' ? (
                          <>All other categories</>
                        ) : (
                          <>
                            Category {k}
                            <br />
                            <br /> {v} rows with category
                          </>
                        )}
                      </Tooltip>
                    )}
                  >
                    <div>
                      {k} <span className="count-category">({v} rows)</span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div>{Math.round((100 * v) / total)}%</div>
              </div>
              <div
                className="view-data-horizontal-bar"
                style={{ width: `${(100 * v) / total}%` }}
              ></div>
            </div>
          ))}
        </div>
      )
    }
  } catch (e) {}
  return (
    <div
      style={{ minHeight: `${height}px`, maxHeight: `${height}px` }}
      className={`view-data-resume px-2 d-flex align-items-center justify-content-center ${
        props?.className ?? ''
      }`}
    >
      {model.dataset.statistics?.[column]?.nunique} unique values
    </div>
  )
}
