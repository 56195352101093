import { request } from './base'

export async function createWorkspace({
  project,
  product_type = 'automl',
  token,
  signout,
}) {
  return request({
    endpoint:
      '/project/create_project?product_type=' +
      encodeURIComponent(product_type),
    contentType: 'json',
    method: 'POST',
    body: project,
    token,
    signout,
  })
}

export async function setProjectActive(id, token, signout) {
  return request({
    endpoint: `/project/set_active_project/${id}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getAllWorkSpace({
  only_projects = true,
  product_type,
  token,
  signout,
}) {
  return request({
    endpoint: `/project/get_projects?only_projects=${only_projects}${
      product_type ? `&product_type=${product_type}` : ''
    }`,
    token,
    signout,
  })
}

export async function deleteWorkspace(projectId, token, signout) {
  return request({
    endpoint: `/project/delete_project/${projectId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function editProjectById(projectId, data, token, signout) {
  return request({
    endpoint: `/project/edit_project/${projectId}`,
    contentType: 'json',
    method: 'POST',
    body: data,
    token,
    signout,
  })
}

export async function getProjectById(projectId, token, signout) {
  return request({
    endpoint: `/project/get_project/${projectId}`,
    token,
    signout,
  })
}

export async function moveModelToProjectId(projectId, modelId, token, signout) {
  return request({
    endpoint: `/project/move-to-project/${modelId}/${projectId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function duplicateProject({ projectId, token, signout }) {
  return request({
    endpoint: `/project/duplicate-project/${projectId}`,
    method: 'POST',
    token,
    signout,
  })
}
