import React, { useRef, useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Alert from 'react-bootstrap/Alert'
import './LoginForm.css'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { FaEnvelope, FaKey } from 'react-icons/fa'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../providers/AuthProvider'
import Loading from '../loading/Loading'
import { BouncyLoader } from '../utils/ui'
import RegisterQuick from '../register-form/RegisterQuick'
import EnglishFlag from '../../assets/images/english.png'
import SpanishFlag from '../../assets/images/spanish.png'
import { BsTranslate } from 'react-icons/bs'

const DISABLE_OAUTH = process.env?.['REACT_APP_DISABLEOAUTH'] === '1'

function MicrosoftSingInButton({ onLogin, text = 'Sign in with Microsoft' }) {
  const { instance, inProgress, accounts } = useMsal()
  const { t } = useTranslation()

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return

    const accessTokenRequest = {
      scopes: ['https://analysis.windows.net/powerbi/api/Dataset.Read.All'],
      account: accounts[0],
    }
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        //let accessToken = accessTokenResponse.accessToken
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
              let accessToken = accessTokenResponse.accessToken
              console.log('accessToken 2 ------------>', accessToken)
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error)
            })
        }
        console.log(error)
      })
  }, [instance, accounts, inProgress])

  if (DISABLE_OAUTH) return <></>

  const handleLogin = () => {
    instance
      .loginPopup({
        scopes: [
          'https://graph.microsoft.com/User.Read',
          'https://graph.microsoft.com/User.ReadBasic.All',
          //'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
        ],
      })
      .then(onLogin)
      .catch((e) => {
        console.error(e)
      })
  }
  return (
    <Button
      variant="secondary"
      className="w-100 p-2 loginmicrosoftbutton"
      onClick={handleLogin}
    >
      <Image
        alt={t('Microsoft icon')}
        src="/Microsoft-icon.svg"
        height={25}
        width={25}
        style={{ display: 'inline-block' }}
      />
      <span
        className="text-dark mx-2"
        style={{ fontSize: 14, fontFamily: 'Roboto' }}
      >
        {t(text)}
      </span>
    </Button>
  )
}

export default function LoginForm({ register = false }) {
  const { t, i18n } = useTranslation()
  const [failedLogin, setFailedLogin] = useState(false)
  const userRef = useRef()
  const passRef = useRef()
  const location = useLocation()
  const navigate = useNavigate()
  let { signin, googleLogin, loginWithGoogle, signinExternal, microsoftLogin } =
    useAuth()
  const [_loginDisabled, setLoginDisabled] = useState(false)
  const loginDisabled = _loginDisabled || loginWithGoogle
  const showRegister = register

  const [forwarding, setForwarding] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get('email')
  const forwardToken = searchParams.get('forwardToken')

  const doLogin = async () => {
    setLoginDisabled(true)
    const email = userRef.current.value
    const password = passRef.current.value
    await signin(email, password)
      .then((res) => {
        if (res) {
          if (location?.state?.onLogin) {
            navigate(location?.state?.onLogin)
          }
        } else
          setFailedLogin(t('Failed login. Retry it later or contact admin.'))
      })
      .catch((error) => {
        setFailedLogin(`${t('Failed login')} ${error}`)
      })
      .finally(() => setLoginDisabled(false))
  }

  useEffect(
    () => {
      if (email && forwardToken) {
        signinExternal(email, forwardToken)
        setForwarding(true)
        searchParams.delete('email')
        searchParams.delete('forwardToken')
        setSearchParams(searchParams)
      }
    },
    // eslint-disable-next-line
    [],
  )

  if ((email && forwardToken) || forwarding) return <Loading />

  if (window.self !== window.top) {
    return (
      <Row>
        <Col md={12} align="center">
          {t('Clickjacking attack')}
          <h2>{t('Clickjacking attack 2')}</h2>
          <h2>
            Click{' '}
            <Link
              href={window.self.location.href}
              title="Web Application"
              target="blank"
            >
              here
            </Link>{' '}
            to access WebApp safely.
          </h2>
        </Col>
      </Row>
    )
  }

  const I18N = () => {
    return (
      <Col
        className="px-0"
        style={{ maxWidth: '150px', marginRight: '-50px' }}
        xs={'auto'}
      >
        <Row
          style={{ maxWidth: '150px' }}
          className="cursor-default justify-content-center float-right ps-0"
        >
          <Col className="d-flex justify-content-center" xs={12}>
            <BsTranslate /> {t('Language')}
          </Col>
          <Col xs={3}>
            <Image
              className="cursor-pointer half-opacity-on-hover"
              src={EnglishFlag}
              width={18}
              onClick={() => i18n.changeLanguage('en')}
            />
          </Col>
          <Col xs={3}>
            <Image
              className="cursor-pointer half-opacity-on-hover"
              src={SpanishFlag}
              width={18}
              onClick={() => i18n.changeLanguage('es')}
            />
          </Col>
        </Row>
      </Col>
    )
  }

  const GoogleLogin = ({
    text = 'Sign in with Google',
    navigateProfile = true,
  }) => {
    if (DISABLE_OAUTH) return <></>
    return (
      <Button
        disabled={loginDisabled}
        className="w-100 p-2 logingooglebutton position-relative"
        onClick={() => {
          googleLogin()
          navigateProfile &&
            navigate(`/profile?origin_login=${encodeURIComponent('/')}`)
        }}
      >
        <div style={{ visibility: loginDisabled ? 'hidden' : 'visible' }}>
          <Image
            alt={t('Google icon')}
            src="/Google-icon.svg"
            height={25}
            width={25}
            style={{ display: 'inline-block' }}
          />
          <span
            className="text-dark mx-3"
            style={{ fontSize: 14, fontFamily: 'Roboto' }}
          >
            {t(text)}
          </span>
        </div>
        <div
          style={{
            visibility: loginDisabled ? 'visible' : 'hidden',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <BouncyLoader className="alternative my-1" />
        </div>
      </Button>
    )
  }

  return (
    <Row id="boxlogin">
      {showRegister ? (
        <Col
          className="d-flex justify-content-center register-container-login-embeed"
          md={8}
          lg={6}
          sm={12}
          style={{
            backgroundColor: 'var(--nextbrain-tables-sticky-color)',
          }}
        >
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <Row style={{ paddingTop: '60px' }}>
              <Col
                className="h4 px-0 d-inline-block"
                style={{ maxWidth: 'calc(100% - 150px)' }}
                md={12}
              >
                {t('Register')}
              </Col>
              <I18N />
            </Row>
            <RegisterQuick
              googleButton={GoogleLogin}
              microsoftButton={({ ...props }) => (
                <MicrosoftSingInButton onLogin={microsoftLogin} {...props} />
              )}
            />
          </div>
        </Col>
      ) : (
        <Col id="LoginContainer" md={8} lg={6} sm={12}>
          <Row className="justify-content-between">
            <Col
              className="h4 px-0 d-inline-block "
              style={{ maxWidth: 'calc(100% - 60px)' }}
              xs={'auto'}
            >
              {`${t('Log in to')} ${t('NextBrain')}`}
            </Col>
            <I18N />
          </Row>
          <Row className="labellogin">{t('Email')}</Row>
          <Row className="posrelative">
            <FaEnvelope className="iconinput iconinputlogin" />
            <Form.Control
              ref={userRef}
              type="email"
              disabled={loginDisabled}
              placeholder="example@email.com"
              defaultValue={location?.state?.defaultUser ?? ''}
              bsPrefix="inputnextbrain"
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  doLogin()
                }
              }}
            />
          </Row>
          <Row className="labellogin">{t('Password')}</Row>
          <Row className="posrelative">
            <FaKey className="iconinput iconinputlogin" />
            <Form.Control
              ref={passRef}
              type="password"
              disabled={loginDisabled}
              bsPrefix="inputnextbrain"
              defaultValue={location?.state?.defaultPass ?? ''}
              onKeyUp={(event) => {
                if (event.key === 'Enter' && !loginDisabled) {
                  doLogin()
                }
              }}
            />
          </Row>
          <Row className="justify-content-end">
            <Col md={12} align="right">
              <Link to={{ pathname: `/recover` }}>
                <strong>{t('Forgot your password?')}</strong>
              </Link>
            </Col>
          </Row>
          <Row align="center">
            <Button
              id="loginButton"
              disabled={loginDisabled}
              className="w-100 p-2 mt-4"
              variant="primary"
              onClick={doLogin}
            >
              {loginDisabled ? (
                <BouncyLoader className="alternative my-1" />
              ) : (
                <>{t('Log in')}</>
              )}
            </Button>
          </Row>
          <Row align="center" className="mt-3">
            <label>
              {t('By logging in to the application you are accepting our')}{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nextbrain.ai/terms-and-condition"
              >
                <strong>{t('Terms and Conditions')}</strong>
              </a>
              .
            </label>
          </Row>
          <Row className={`my-2 mt-3 justify-content-center`} align="center">
            <Col className="mt-2" xs={5}>
              <div className="mx-4">
                <div
                  className="w-100"
                  style={{ borderBottom: '1px solid #A8A8A8', height: 0 }}
                />
              </div>
            </Col>
            <Col xs={1}>
              <span style={{ color: '#A8A8A8' }}>{t('Or')}</span>
            </Col>
            <Col className="mt-2" xs={5}>
              <div className="mx-4">
                <div
                  className="w-100"
                  style={{ borderBottom: '1px solid #A8A8A8', height: 0 }}
                />
              </div>
            </Col>
          </Row>
          <Row align="center" className={`mt-3 justify-content-center`}>
            <GoogleLogin />
          </Row>

          <Row align="center" className={`mt-3 justify-content-center`}>
            <MicrosoftSingInButton onLogin={microsoftLogin} />
          </Row>

          <Row>
            <Col
              align="center"
              md={12}
              style={{ fontSize: '18px' }}
              className="mt-4 cursor-pointer user-select-none"
              onClick={() => navigate('/register')}
            >
              {t('Need an account?')}&nbsp;
              <u>
                <strong>{t('Sign up')}</strong>
              </u>
            </Col>
          </Row>
          <Row className="mt-5" align="center">
            <Alert variant={'danger'} transition={true} show={failedLogin}>
              {t('Login failed, incorrect user or password')}
            </Alert>
          </Row>
        </Col>
      )}
      <Col md={4} lg={6} className="p-0 imglogin d-md-block d-sm-none">
        <div className="logologin">
          <Image
            alt={t('NextBrain Logo')}
            height={80}
            width={80}
            src="/LogoLogin.svg"
          />
        </div>
      </Col>
    </Row>
  )
}
