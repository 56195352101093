import { Row, Col } from 'react-bootstrap'
import { FiLoader } from 'react-icons/fi'

export default function StatusMessage({ history, showLoad = true, ...props }) {
  if (!Array.isArray(history) || history.length === 0) return <></>

  const message = history[history.length - 1]

  return (
    <Row {...props} className={`model-status-message ${props.className ?? ''}`}>
      <Col align="center">
        {message.message}{' '}
        {showLoad ? <FiLoader className="status-message-loader" /> : <></>}
      </Col>
    </Row>
  )
}
