import { useMemo } from 'react'
import { NotificationManager } from 'react-notifications'
import { useQuery } from 'react-query'
import { ResponsiveLine } from '@nivo/line'

import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import { getMMMSeasonalData } from '../../services/model'
import { colors } from './config'
import Loading from '../loading/LoadingSmall'

export default function SeasonalData({ model, fontSize = 10, ...props }) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(
    ['mmm-model-seasonal-data', model.id],
    async () => {
      const response = await getMMMSeasonalData({
        modelId: model.id,
        token,
        signout,
      })

      if (!response)
        NotificationManager.error(t('Failed to retrieve seasonal data'))
      return response
    },
    { staleTime: 60 * 1000 },
  )

  const lineData = useMemo(() => {
    return data?.['trend']
      ? ['trend', 'season', 'total'].map((key, i) => ({
          id: key,
          color: colors[i],
          data: data[key].map((k, i) => ({ x: i + 1, y: k })),
        }))
      : null
  }, [data])

  return isLoading || !lineData ? (
    <Loading />
  ) : (
    <ResponsiveLine
      data={lineData}
      margin={{ top: 20, right: 90, bottom: 50, left: 60 }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      enablePoints={false}
      enableGridY={false}
      enableGridX={false}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 15,
        legend: 'Week',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Outcome',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemTextColor: 'var(--nextbrain-white-font)',
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        },
      ]}
      enableSlices="x"
      theme={{
        textColor: 'var(--nextbrain-white-font)',
        fontSize,
        axis: {
          ticks: {
            text: {
              fill: 'var(--nextbrain-white-font)',
            },
          },
          legend: {
            text: {
              fill: 'var(--nextbrain-white-font)',
            },
          },
        },
      }}
      {...props}
    />
  )
}
