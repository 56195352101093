import React, { useRef, useState } from 'react'
import { Row, Col, Form, Button, Image } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { Link } from 'react-router-dom'
import { MdArrowBackIosNew } from 'react-icons/md'

import './AdminManager.css'
import { loginToManager } from '../../services/adminManager'
import AdminPanel from './AdminPanel'

function AdminLogin({ setCredentials, ...props }) {
  const userRef = useRef(null)
  const passRef = useRef(null)

  const login = () => {
    const username = userRef.current.value.trim()
    const password = passRef.current.value.trim()
    if (!username || !password) {
      NotificationManager.error('Username and password are required')
    } else {
      loginToManager(username, password)
        .then((res) => {
          if (res.status === 200) setCredentials({ username, password })
        })
        .catch((err) => {
          NotificationManager.error('Failed authentication')
        })
    }
  }

  return (
    <Row
      {...props}
      className={`justify-content-center align-items-center h-100 ${
        props.className ?? ''
      }`}
    >
      <Col style={{ maxWidth: '300px' }}>
        <Row className="admin-manager-login align-items-center">
          <Col className="mb-1" xs={12}>
            username
          </Col>
          <Col xs={12}>
            <Form.Control
              required
              className="nb-input-soft"
              ref={userRef}
              onKeyDown={(e) => e.key === 'Enter' && login()}
              defaultValue={'admin'}
            />
          </Col>
          <Col className="mt-2 mb-1" xs={12}>
            password
          </Col>
          <Col xs={12}>
            <Form.Control
              onKeyDown={(e) => e.key === 'Enter' && login()}
              required
              className="nb-input-soft"
              type="password"
              ref={passRef}
              defaultValue={'1234'}
            />
          </Col>
          <Col className="py-3" xs={12}>
            <Button onClick={login}>Login</Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <Link to={'/'}>
              <div className="color-white d-flex align-items-center hover-underline">
                <MdArrowBackIosNew />
              </div>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function AdminManager({ ...props }) {
  const [credentials, setCredentials] = useState(null)

  if (!credentials)
    return <AdminLogin setCredentials={setCredentials} {...props} />

  return (
    <Row {...props}>
      <Col xs={12}>
        <Row className="position-relative ms-0">
          <Col className="admin-manager-header p-3 ms-0" xs={12}>
            <Image className="mx-2" height="50" src="/Logo/1.svg" alt="" />{' '}
            Admin Panel
          </Col>
        </Row>
        <AdminPanel
          credentials={credentials}
          exit={() => setCredentials(null)}
        />
      </Col>
    </Row>
  )
}
