import React from 'react'
import './homepage.css'
import { Row, Col, Button, Container, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaHome } from 'react-icons/fa'
import { useNav } from '../../providers/NavProvider'
import { useAuth } from '../../providers/AuthProvider'

const productSizes = {
  xl: 3,
  lg: 4,
  md: 6,
  xs: 12,
}

const DISABLED_PLAN = process.env?.['REACT_APP_DISABLEPLANS'] === '1'

function Product({
  title = '',
  icon = <></>,
  license,
  startTrial,
  active = false,
  useProduct,
  children,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Row
      {...props}
      className={`product-card ${
        active ? '' : 'inactive-product-card'
      } px-lg-2 px-1 pb-2 w-100 h-100 justify-content-between flex-column ${
        props?.className ?? ''
      }`}
    >
      <Col xs={12}>
        <Row>
          <Col
            className="product-icon-container d-flex justify-content-center"
            xs={12}
            style={{ position: 'relative', zIndex: 2 }}
          >
            {icon}
          </Col>
          <Col style={{ minHeight: '60px' }} xs={12}>
            <span
              className="d-flex text-center justify-content-center color-white bold"
              style={{ fontSize: '1.2rem' }}
            >
              {title}
            </span>
          </Col>
          <Col className="color-white smallp text-center px-0" xs={12}>
            <i>{children}</i>
          </Col>
        </Row>
      </Col>
      <Col className="pb-1" xs={12}>
        {license && (
          <Row className="mb-3">
            <Col xs={12}>{license}</Col>
          </Row>
        )}
        <Row className="justify-content-center">
          {useProduct && (
            <Col xs={6}>
              <Button className="w-100  py-1 h-100 rounded-30 dflex-center">
                <span
                  className="flex items-center"
                  style={{ fontSize: '14px' }}
                >
                  {t('Use Product')}
                </span>
              </Button>
            </Col>
          )}
          {startTrial && (
            <Col xs={6}>
              <Button
                onClick={() => startTrial()}
                className="w-100  py-1 h-100 rounded-30 dflex-center"
              >
                <span style={{ fontSize: '14px' }}>{t('START TRIAL')}</span>
              </Button>
            </Col>
          )}
          {!DISABLED_PLAN && (
            <Col className="" xs={6}>
              <Button
                className="w-100 bg-transparent outline-primary py-1 h-100 rounded-30 dflex-center"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  window.open('https://nextbrain.ai/contact-us/', '_blank')
                }}
              >
                <span style={{ fontSize: '14px' }}>{t('Contact sales')}</span>
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const dateFormat = (date) => {
  const dt = new Date(date).toLocaleDateString('default', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  if (dt === 'Invalid Date') return '-'
  return dt
}

export default function SelectProduct({ children }) {
  const { user } = useAuth()
  const { mode, setMode } = useNav()
  const { t } = useTranslation()
  const company = user?.company ?? user?.companies?.[0]
  const subscriptionName = company?.subscription?.config?.name

  const mmmPlugin = company?.plugin_subscriptions?.find(
    (e) => e?.config?.plugin_type?.toLowerCase() === 'mmm',
  )
  const mmmDate = new Date(mmmPlugin?.to_date)
  const validMMM =
    mmmPlugin && !isNaN(mmmDate) && mmmDate.getTime() > Date.now()

  if (mode) return children

  return (
    <Container>
      <Row className="mt-5 mb-4">
        <Col xs={12}>
          <h4 className="d-flex align-items-end color-white">
            <span
              className="justify-content-center align-items-center me-2"
              style={{
                padding: '2px',
                borderRadius: '50%',
                backgroundColor: 'white',
                color: 'var(--nextbrain-body)',
                display: 'inline-flex',
              }}
            >
              <FaHome />
            </span>
            {t('Modules')}
          </h4>
        </Col>
        <Col className="mt-4" xs={12}>
          <Row className="products-container justify-content-center">
            <Col {...productSizes}>
              <Product
                active={true}
                onClick={() => setMode('automl')}
                className="automl-product"
                title="AutoML"
                useProduct={true}
                icon={<Image src="/icons/robot-automl.svg" width="100px" />}
                license={
                  <Row className="color-white">
                    <Col xs={12}>
                      <ul className="mb-0 smallp">
                        <li>
                          {t('License')}:{' '}
                          <i>{subscriptionName ?? t('No license')}</i>
                        </li>
                        <li>
                          {t('To date')}:{' '}
                          <i>{dateFormat(company?.subscription?.to_date)}</i>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                }
              >
                {t(
                  'Unlock insights and shape your future with our personalized AutoML solution.',
                )}
              </Product>
            </Col>
            <Col {...productSizes}>
              <Product
                active={validMMM}
                onClick={() => validMMM && setMode('mmm')}
                className="mmm-product"
                title="Marketing Mix Modeling"
                icon={<Image src="/icons/MMM-35.svg" width="100px" />}
                license={
                  mmmPlugin ? (
                    <Row className="color-white">
                      <Col xs={12}>
                        <ul className="mb-0 smallp">
                          <li>
                            {t('Status')}:{' '}
                            <i>{validMMM ? t('Active') : t('Expired')}</i>
                          </li>
                          <li>
                            {t('To date')}: <i>{dateFormat(mmmDate)}</i>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  ) : null
                }
              >
                {t(`Elevate your marketing strategy with our MMM tool.`)}
              </Product>
            </Col>
            <Col {...productSizes}>
              <Product
                active={true}
                onClick={() =>
                  window.open('https://doccompare.nextbrain.ai/', '_blank')
                }
                className="inventory-management-product"
                title="Document comparison"
                icon={
                  <span style={{ minHeight: '100px' }}>
                    <Image src="/icons/docompare.webp" height="85px" />
                  </span>
                }
              >
                {t(
                  `The AI tool that instantly compares documents and generates detailed reports.`,
                )}
              </Product>
            </Col>
            <Col {...productSizes}>
              <Product
                active={true}
                onClick={() => setMode('sixsigma')}
                className="sixsigma-product"
                title="Six Sigma"
                icon={
                  <span style={{ minHeight: '100px' }}>
                    <Image src="/icons/sixsigma.webp" width="120px" />
                  </span>
                }
                license={
                  mmmPlugin ? (
                    <Row className="color-white">
                      <Col xs={12}>
                        <ul className="mb-0 smallp">
                          <li>
                            {t('Status')}: <i>{t('Active')}</i>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  ) : null
                }
              >
                {t(
                  `Improve business processes by reducing defects and errors.`,
                )}
              </Product>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
