import { useState } from 'react'
import UploadCsv from './upload-csv'

export default function useUploadCSV() {
  const [fileLoadProgress, setFileLoadProgress] = useState(null)
  const [component] = useState(() => {
    return ({ ...props }) => {
      return <UploadCsv {...props} loading={fileLoadProgress} />
    }
  })

  return [component, setFileLoadProgress, fileLoadProgress]
}
