import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'

export default function Loading({ ...props }) {
  const { t } = useTranslation()
  return (
    <Row
      style={{ height: '100vh' }}
      className="w-100 align-items-center justify-content-center"
      {...props}
    >
      <Col align="center" xs={6}>
        <Image src={'/Logo/3.svg'} style={{ height: '40vh' }} />
        <br />
        <span className="loading-tooltip">{t('Loading...')}</span>
      </Col>
    </Row>
  )
}
