import { forwardRef } from 'react'
import { Form } from 'react-bootstrap'

const NBInput = forwardRef(
  (
    {
      useWrapper = true,
      wrapperClass = '',
      validation = false,
      onFocus = () => {},
      onBlur = () => {},
      borderRadius = '0.25rem',
      soft = false,
      ...props
    },
    ref,
  ) => {
    return useWrapper ? (
      <div
        className={`nb-input-wrapper ${
          validation ? 'enforced-validation-container' : ''
        }`}
        style={{ borderRadius: borderRadius }}
      >
        <Form.Control
          ref={ref}
          {...props}
          className={`nb-input${soft ? '-soft' : ''} ${props.className ?? ''}`}
          onFocus={(e) => {
            e.currentTarget.parentNode.classList.add('wrapper-focus')
            onFocus(e)
          }}
          onBlur={(e) => {
            e.currentTarget.parentNode.classList.remove('wrapper-focus')
            onBlur(e)
          }}
        />
      </div>
    ) : (
      <Form.Control
        ref={ref}
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`nb-input${soft ? '-soft' : ''} ${props.className ?? ''}`}
      />
    )
  },
)

export default NBInput
