import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { useAuth } from '../../providers/AuthProvider'
import { getSpreadsheets, getWorkbooks } from '../../services/spreadsheet'
import { createModelFromCSV } from '../../services/model'
import { FaTable } from 'react-icons/fa'
import { FiLoader } from 'react-icons/fi'
import { SiGooglesheets } from 'react-icons/si'
import { useTranslation } from 'react-i18next'
import { GridTable } from '../grid-table/GridTable'
import { import_gsheet, getAllCSV } from '../../services/csv'
import { checkGsheet } from '../../services/csv'
import { awaitTask } from '../../services/base'
import { useModels } from '../../providers/ModelProvider'
import Loading from '../loading/LoadingSmall'

import './SpreadsheetNav.css'
import { AssistantCheck } from '../model-content/AssistantCheck'

function SpreadsheetPreview({
  columns,
  sample,
  cancel,
  loading,
  actionLabel,
  t,
  single,
  ...props
}) {
  return loading ? (
    <Loading message="Creating model" />
  ) : (
    <Row
      {...props}
      className={`${props.className ?? ''} py-2 spreadsheet-preview`}
    >
      <Col xs={12}>
        <GridTable
          rows={sample}
          rowsPerPage={30}
          header={[columns]}
          index={(i) => (
            <div
              key={i}
              className={`grid-table-cell index-cell ${i ? '' : 'first'}`}
            >
              {i ? i : ''}
            </div>
          )}
          className="w-100 table-view-data"
          defaultColumnWidth={(str) => Math.max(200, str.length * 12)}
          style={{ maxHeight: '300px' }}
          pagerLast={true}
        />
      </Col>
    </Row>
  )
}

export default function SpreadsheetNav({
  onFailedAuth = () => {},
  onAuth = () => {},
  onFinish,
  sampleData = false,
  actionLabel,
  onNameChange = () => {},
  onAskAi = false,
  ...props
}) {
  const { t } = useTranslation()
  const { token, signout, google_access_token, clearGoogleAccessToken } =
    useAuth()

  const assistantRef = useRef(null)

  const { addModel, role } = useModels()

  const [filterSheet, setFilterSheet] = useState('')

  const [workbooks, setWorkbooks] = useState({})

  const [spreadsheets, setSpreadsheets] = useState(null)
  const [activeSpreadsheets, setActiveSpreadsheets] = useState({})

  const [existingCSVs, setExistingCSVs] = useState(null)
  const [currentCSVId, setCurrentCSVId] = useState(null)
  const [requestedWorkbooks, setRequestedWorkbooks] = useState({})
  const [errorWorkbook, setErrorWorkbook] = useState({})

  const [createModel, setCreateModel] = useState(null)
  const sample = useRef({})

  const activateSheet = (i) => {
    setActiveSpreadsheets((p) => ({ ...p, [i]: !p[i] }))
    if (!workbooks[i]) {
      getWorkbooks(
        spreadsheets[i].id,
        google_access_token,
        token,
        signout,
      ).then((r) => {
        if (r.status === 401) {
          NotificationManager.error(
            t('Failed to retrieve spreadsheets'),
            null,
            10000,
          )
          clearGoogleAccessToken()
          r.json().then((d) => console.log(d))
        } else {
          r.json().then((j) => setWorkbooks((w) => ({ ...w, [i]: j })))
        }
      })
    }
  }

  const uploadSheet = (
    sample,
    spreadsheetId,
    workbookName,
    google_access_token,
  ) => {
    return import_gsheet(
      spreadsheetId,
      workbookName,
      google_access_token,
      token,
      signout,
    )
      .then(async (m) => {
        if (m.status === 401) {
          NotificationManager.error(
            t('Failed to retrieve workbooks'),
            null,
            10000,
          )
          clearGoogleAccessToken()
          return
        }
        const j = await m.json()
        if (j.id) {
          NotificationManager.info(j?.message ?? 'Loading data')
          setCurrentCSVId(j.id)
        } else {
          NotificationManager.error(t('Failed to retrieve spreadsheet'))
        }
      })
      .catch(async (e) => {
        NotificationManager.error(t('Failed to retrieve spreadsheet'))
      })
  }

  useEffect(() => {
    let csv
    if (currentCSVId) {
      if (
        existingCSVs &&
        (csv = existingCSVs.find((c) => c.id === currentCSVId)) &&
        csv.status !== 'importing'
      ) {
        if (csv.status === 'error') {
          NotificationManager.error(
            t('Failed to create a dataset from the spreadsheet'),
          )
          return
        }
        createModelFromCSV(currentCSVId, token, signout).then((m) => {
          addModel(m)
        })
        setCurrentCSVId(null)
      } else {
        setTimeout(() => {
          getAllCSV(token, signout).then(
            (d) =>
              Array.isArray(d) &&
              setExistingCSVs(d.filter((csv) => csv.status === 'imported')),
          )
        }, 3000)
      }
    }
    // eslint-disable-next-line
  }, [existingCSVs, currentCSVId])

  const loadSpreadsheet = (i, j) => {
    const sampleMap = sample.current
    sampleMap[i] = sampleMap[i] ?? {}
    if (!sampleMap[i][j]) {
      ;(async () => {
        if (sampleMap[i][j] === 'loading') return
        sampleMap[i][j] = 'loading'
        sampleMap[i][j] = sampleMap[i][j] ?? 'loading'
        const { task_id } = await checkGsheet({
          spreadsheet_id: spreadsheets[i].id,
          workbook_name: workbooks[i][j].name,
          google_token: google_access_token,
          token,
          signout,
        })
        awaitTask({ taskUuid: task_id })
          .then((result) => {
            const sample = {
              columns: result.columns,
              sample: result.data,
            }
            sampleMap[i][j] = sample
            setRequestedWorkbooks((rw) => ({ ...rw }))
          })
          .catch((e) => {
            setErrorWorkbook((w) => {
              w[i] = w[i] ?? {}
              w[i][j] = true
              return { ...w }
            })
            NotificationManager.error(
              t(e?.error ?? 'Error retrieving spreadsheet'),
            )
          })
      })()
    }
  }

  useEffect(
    () => {
      getSpreadsheets(google_access_token, token, signout).then((r) => {
        if (r.status === 401) {
          NotificationManager.error(
            t(
              'Authorization error, inability to access spreadsheets, insufficient permissions.',
            ),
            null,
            10000,
          )
          clearGoogleAccessToken()
          onFailedAuth()
        } else {
          onAuth()
          r.json().then((j) => setSpreadsheets(j))
        }
      })
    },
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    if (createModel) {
      const [i, j] = createModel
      const currentSample = sample.current[i][j]
      const spreadSheetId = spreadsheets[i].id
      const workbookName = workbooks[i][j].name
      if (onFinish)
        onFinish(
          currentSample,
          spreadSheetId,
          workbookName,
          google_access_token,
          spreadsheets[i].name,
        )
      else uploadSheet(sample, spreadSheetId, workbookName, google_access_token)
    }
    // eslint-disable-next-line
  }, [createModel])

  if (role !== 'editor') {
    return (
      <Row>
        <Col className="dflex-center" style={{ minHeight: '40vh' }} xs={12}>
          {t('Importing from google sheets disabled in this shared workspace')}
        </Col>
      </Row>
    )
  }

  return (
    <Row {...props} className={`${props.className ?? ''} `}>
      <Col xs={12}>
        <Row className="justify-content-between align-items-start">
          <Col
            className="order-1 order-sm-2 py-2 px-0 mb-1"
            style={{ maxHeight: '50px' }}
            xs={'auto'}
          >
            <Form.Control
              placeholder="Search a spreadsheet..."
              aria-label="Search"
              className=" with-icon nb-input"
              onChange={(event) => {
                setFilterSheet(event.currentTarget.value.toLowerCase())
              }}
            />
            <i className="fas fa-search icon"></i>
          </Col>
          <Col className="order-1 order-sm-2 py-2  mb-1" xs={'auto'}>
            {onAskAi && (
              <AssistantCheck
                ref={assistantRef}
                onChange={onAskAi}
                enable={() => {
                  const cols = sample?.columns?.length
                  if (cols && cols > 500) return false
                  return true
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col className="spreadsheet-container" xs={12}>
        {!Array.isArray(spreadsheets) ? (
          <>{t('Loading...')}</>
        ) : (
          <Row className="">
            {spreadsheets.map((s, i) =>
              !filterSheet ||
              s.name.toLowerCase().includes(filterSheet) ||
              activeSpreadsheets[i] ? (
                <React.Fragment key={i}>
                  <Col xs={12} onClick={() => activateSheet(i)}>
                    <Row
                      className={`spreadsheet-tag p-1 ${
                        activeSpreadsheets[i] ? 'active' : ''
                      }`}
                    >
                      <Col className="d-flex py-2 align-items-center">
                        <div className="multicon-spreadsheets me-2">
                          <SiGooglesheets size={25} />
                        </div>
                        {s.name}
                        {activeSpreadsheets[i] &&
                        !Array.isArray(workbooks[i]) ? (
                          <div className=" ms-3 bouncing-loader alternative">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {activeSpreadsheets[i] ? (
                    <Col className="workbook-container" xs={12}>
                      {
                        <Row>
                          {Array.isArray(workbooks[i]) ? (
                            <>
                              {workbooks[i].map((w, j) => (
                                <React.Fragment key={j}>
                                  <Col
                                    className={`p-1 py-2 d-flex justify-content-between align-items-center workbook-tag ${
                                      errorWorkbook?.[i]?.[j]
                                        ? 'workbook-error'
                                        : ''
                                    }`}
                                    xs={12}
                                    style={{
                                      pointerEvents:
                                        sample.current?.[i]?.[j] === 'loading'
                                          ? 'none'
                                          : 'all',
                                    }}
                                    onClick={() => {
                                      onNameChange(w.name)
                                      loadSpreadsheet(i, j)
                                      setRequestedWorkbooks((rw) => {
                                        rw[i] = rw[i] ?? {}
                                        rw[i][j] = !rw[i][j]
                                        return { ...rw }
                                      })
                                    }}
                                  >
                                    <div
                                      style={{ paddingLeft: '30px' }}
                                      className="d-inline-flex align-items-center justify-content-between w-100"
                                    >
                                      <div className="d-flex align-items-center">
                                        <FaTable
                                          title="workbook"
                                          className="workbook-icon me-1"
                                          size={20}
                                        />
                                        <strong style={{ marginLeft: '10px' }}>
                                          <small>{w.name}</small>
                                        </strong>
                                        {!errorWorkbook?.[i]?.[j] &&
                                          sample.current?.[i]?.[j] ===
                                            'loading' && (
                                            <FiLoader className="loading-spreadsheet-preview" />
                                          )}
                                      </div>

                                      {requestedWorkbooks?.[i]?.[j] &&
                                        sample.current?.[i]?.[j] &&
                                        sample.current?.[i]?.[j] !==
                                          'loading' && (
                                          <>
                                            <Col xs={'auto'}>
                                              <Row className="align-items-center">
                                                <Col xs={'auto'}>
                                                  <Button
                                                    onClick={() =>
                                                      setCreateModel([i, j])
                                                    }
                                                  >
                                                    {t(actionLabel)}
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </>
                                        )}
                                    </div>
                                  </Col>
                                  <Col style={{ paddingLeft: '50px' }}>
                                    {requestedWorkbooks?.[i]?.[j] &&
                                      sample.current?.[i]?.[j] &&
                                      sample.current[i][j] !== 'loading' && (
                                        <SpreadsheetPreview
                                          t={t}
                                          actionLabel={actionLabel}
                                          loading={!!createModel}
                                          cancel={() => {
                                            setRequestedWorkbooks((rw) => {
                                              rw[i][j] = false
                                            })
                                          }}
                                          {...sample.current[i][j]}
                                        />
                                      )}
                                  </Col>
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </Row>
                      }
                    </Col>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment key={i}></React.Fragment>
              ),
            )}
          </Row>
        )}
      </Col>
    </Row>
  )
}
