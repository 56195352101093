import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import React, { useState, useEffect } from 'react'
import RegisterSteps from './RegisterSteps'
import './RegisterForm.css'

const questionaire = [
  {
    question: [
      'You have a spreadsheet with seven columns and ten rows.',
      'How many variables are there?',
    ],
    answers: ['10', '7', '7 x 10 = 70'],
    correct: 2,
  },
  {
    question: 'What is an algorithm?',
    answers: [
      'A mathematical expression that finds an optimal solution to a problem.',
      'A blackbox where we enter data and get an output.',
      'A mathematical equation that gives a result.',
    ],
    correct: 1,
  },
  {
    question: 'What does it mean to ‘train’ an algorithm?',
    answers: [
      'To enter data to an algorithm in order to find patterns.',
      'To define the equation to solve the problem.',
      'To tell the algorithm what it has to do.',
    ],
    correct: 0,
  },
  {
    question: 'What is a machine learning model?',
    answers: [
      'An algorithm that has been trained with some data and is able to predict new data.',
      'The kind of algorithm we have used.',
      'The kind of response of the algorithm.',
    ],
    correct: 0,
  },
  {
    question:
      'What does it mean to do a prediction with a machine learning model?',
    answers: [
      'To use the model to predict some column values in the data.',
      'To make calculations with the algorithm.',
      'To get a result from a mathematical equation',
    ],
    correct: 0,
  },
]

export default function RegisterQuestionaire({
  onExit = (result) => {},
  enableSignup = true,
  finishButton = 'Sign up',
  termsAndConditions = () => {},
  ...props
}) {
  const [at, setAt] = useState(0)
  const [answers, setAnswers] = useState(
    new Array(questionaire.length).fill(-1),
  )
  const [max, setMax] = useState(0)
  if (at > max) setMax(at)

  const TermsAndCondition = termsAndConditions

  const results = () => (
    <Row>
      <Row className="px-0 mx-0">
        <Col
          className="px-0 mx-0 cursor-pointer"
          onClick={() => {
            setAt(at - 1)
          }}
        >
          <img src="/icons/Back.svg" alt="Back" className="custom-back" />
        </Col>
      </Row>
      {answers.map((i, index) => (
        <Row key={index}>
          <Row>
            <Col>{questionaire[index].question}</Col>
          </Row>
          <Row
            className={`question-card my-3 ${
              i === questionaire[index].correct ? 'correct' : 'incorrect'
            }`}
          >
            <Col
              className="px-0 my-0 d-flex align-items-center"
              md={'auto'}
              xs={'auto'}
            >
              <div className="dot-questionaire float-right">
                <div className={`dot-questionaire-small-on`} />
              </div>
            </Col>
            <Col>
              {i === -1 ? 'Unanswered' : questionaire[index].answers[i]}
            </Col>
            {i !== questionaire[index].correct && (
              <Col className="text-right">
                <small>{`Correct answer: ${
                  questionaire[index].answers[questionaire[index].correct]
                }`}</small>
              </Col>
            )}
          </Row>
        </Row>
      ))}
    </Row>
  )

  const Finish = () => {
    const correct = answers
      .map((a, dex) => questionaire[dex].correct === a)
      .filter((a) => a).length
    const level =
      correct < 2 ? 'beginner' : correct > 4 ? 'advanced' : 'intermediate'

    return at === questionaire.length + 1 ? (
      results()
    ) : (
      <Row>
        <Row className="justify-content-center">
          <Col sm={'auto'} className="h4">
            <strong>{`Congratulations! Your level is ${level}`}</strong>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={'auto'}>
            <span>You answered</span>
            <strong> {correct}</strong> out of{' '}
            <strong>{questionaire.length} </strong>
            questions correctly
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col sm={'auto'}>
            <Row className="justify-content-center">
              <Row className="result-questionaire-card pb-5">
                <Col className="justify-content-center d-flex mt-4" sm={'auto'}>
                  <img
                    className="mt-5"
                    style={{ width: '176px', height: '154px' }}
                    alt="User machine learning level"
                    src={
                      {
                        beginner: '/iconsRegister/Brain2.svg',
                        intermediate: '/iconsRegister/Brain1.svg',
                        advanced: '/iconsRegister/Brain3.svg',
                      }[level]
                    }
                  />
                </Col>
                <Row className="justify-content-center">
                  <Col className="h3" xs={'auto'}>
                    <strong>{`${level[0].toUpperCase()}${level.substr(
                      1,
                    )}`}</strong>
                  </Col>
                </Row>

                <Col align="center" className="pb-2" sm={'auto'}>
                  {
                    {
                      beginner:
                        'I’m new to machine learning and need to learn a lot',
                      intermediate:
                        'I have some experience but still need guideance',
                      advanced:
                        'I am familiar with machine learning and use it regularly',
                    }[level]
                  }
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col style={{ minWidth: '400px' }} xs={'auto'}>
            <Button
              className="w-100 alternate-button-2 py-3"
              onClick={() => setAt(at + 1)}
            >
              View results
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-center mt-3 ">
          <Col xl={6} md={10} xs={12}>
            <TermsAndCondition />
          </Col>
          <Col xs={12}></Col>
          <Col xl={6} md={10} xs={12}>
            <Button
              className="w-100 mt-2 py-3"
              disabled={!enableSignup}
              onClick={() => {
                onExit(level)
              }}
            >
              {finishButton}
            </Button>
          </Col>
        </Row>
      </Row>
    )
  }

  useEffect(
    () => {
      document.getElementById('differentsteps')?.classList?.add('d-none')
      return () => {
        document.getElementById('differentsteps')?.classList?.remove('d-none')
      }
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <Row {...props}>
      <Row
        className={`justify-content-center ${
          at >= questionaire.length ? 'd-none' : ''
        }`}
      >
        <Col md={'auto'} sm={'auto'}>
          <div style={{ width: '500px' }}>
            <RegisterSteps
              steps={5}
              at={at}
              max={max}
              ok={answers.map((a) => a !== -1)}
              onClick={(dex) => {
                if (dex <= max && at < questionaire.length) setAt(dex)
              }}
            />
          </div>
        </Col>
      </Row>
      {at >= questionaire.length ? (
        <Finish />
      ) : (
        <Row>
          <Row className="mt-4 mb-3">
            <Col className="question-header ">
              {Array.isArray(questionaire[at].question) ? (
                questionaire[at].question.map((h) => (
                  <Row key={h}>
                    <Col>{h}</Col>
                  </Row>
                ))
              ) : (
                <>
                  <Row>
                    <Col>{questionaire[at].question}</Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            {questionaire[at].answers.map((k, index) => (
              <Row
                className="question-card my-3"
                key={k}
                onClick={(e) => {
                  answers[at] = index
                  setAnswers([...answers])
                }}
              >
                <Col
                  className="px-0 my-0 d-flex align-items-center"
                  md={'auto'}
                  xs={'auto'}
                >
                  <div className="dot-questionaire float-right">
                    <div
                      className={`dot-questionaire-small${
                        answers[at] === index ? '-on' : ''
                      }`}
                    />
                  </div>
                </Col>
                <Col md={11} xs={11}>
                  {k}
                </Col>
              </Row>
            ))}
          </Row>
          <Row className="justify-content-center mt-xxl-5 mt-md-3">
            <Col md={6}>
              <Row>
                <Col>
                  <Button
                    className="w-100 py-3"
                    onClick={() => {
                      setAt(at + 1)
                    }}
                  >
                    {at === questionaire.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Button
                    className="w-100 alternate-button-2 py-3"
                    onClick={() => {
                      if (at === 0) onExit(null)
                      else setAt(at - 1)
                    }}
                  >
                    Go back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      )}
    </Row>
  )
}
