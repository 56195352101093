import Popover from 'react-bootstrap/Popover'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BsTrash, BsPencil } from 'react-icons/bs'
import { FaRegClone } from 'react-icons/fa'
import { BsLink45Deg, BsStar, BsStarFill } from 'react-icons/bs'
export default function EditOverlay(d, onEdit, filter = {}, t) {
  // TODO: Can't use use state in this overlay, we should think of a better way to do this
  return (props) => {
    return (
      <Popover
        key={`d.id_popover_edit`}
        {...props}
        className={`floating-box ${props.className ?? ''}`}
      >
        <Row
          className="overlay-edit mx-4"
          style={{ minWidth: '150px' }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {filter['rename'] ? (
            <></>
          ) : (
            <Row
              className="my-1"
              onClick={(e) => {
                e.stopPropagation()
                onEdit('edit', d)
              }}
            >
              <Col className="px-0 mx-0" xs="auto">
                <BsPencil className="cursor-pointer" />
              </Col>
              <Col className="pe-0" xs="auto">
                {t('Edit')}
              </Col>
            </Row>
          )}
          {filter['duplicate'] ? (
            <></>
          ) : (
            <Row
              className="my-1"
              onClick={(e) => {
                e.stopPropagation()
                onEdit('duplicate', d)
              }}
            >
              <Col className="px-0 mx-0" xs="auto">
                <FaRegClone className="cursor-pointer" />
              </Col>
              <Col className="pe-0" xs="auto">
                {t('Duplicate')}
              </Col>
            </Row>
          )}
          {filter['delete'] ? (
            filter?.leave && (
              <Row
                className="my-1"
                onClick={(e) => {
                  e.stopPropagation()
                  onEdit('leave', d)
                }}
              >
                <Col className="px-0 mx-0" xs="auto">
                  <BsTrash className="cursor-pointer" />
                </Col>
                <Col className="pe-0" xs="auto">
                  {t('Leave project')}
                </Col>
              </Row>
            )
          ) : (
            <Row
              className="my-1"
              onClick={(e) => {
                e.stopPropagation()
                onEdit('delete', d)
              }}
            >
              <Col className="px-0 mx-0" xs="auto">
                <BsTrash className="cursor-pointer" />
              </Col>
              <Col className="pe-0" xs="auto">
                {t('Delete')}
              </Col>
            </Row>
          )}
          {filter['shareMenu'] && (
            <Row
              className="my-1"
              onClick={(e) => {
                e.stopPropagation()
                onEdit('shareMenu', d)
              }}
            >
              <Col className="px-0 mx-0" xs={'auto'}>
                <BsLink45Deg className="cursor-pointer" size={20} />{' '}
                {t('Share')}
              </Col>
            </Row>
          )}
          <Row
            className="mb-1"
            onClick={(e) => {
              e.stopPropagation()
              onEdit('favourite', d)
            }}
          >
            <Col className="px-0 mx-0 d-flex align-items-center" xs={'auto'}>
              {d.favourite ? (
                <>
                  <BsStarFill className="cursor-pointer me-1" size={18} />
                  {t('Remove from favourites')}
                </>
              ) : (
                <>
                  <BsStar className="cursor-pointer me-1" size={18} />
                  {t('Add to favourites')}
                </>
              )}
            </Col>
          </Row>
        </Row>
      </Popover>
    )
  }
}
