import React, { useEffect, useState, useRef } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { useQuery, useQueryClient } from 'react-query'
import {
  FaDatabase,
  FaFile,
  FaPlusCircle,
  FaSpinner,
  FaTable,
  FaTrash,
  FaUpload,
} from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'

import { config } from '../../../../Constants'
import { InlineEdit } from '../../../inline-edit/InlineEdit'
import { MdEdit } from 'react-icons/md'
import Csv from '../../../knowledge-base/file-icons/csv'
import Doc from '../../../knowledge-base/file-icons/doc'
import Pdf from '../../../knowledge-base/file-icons/pdf'
import Py from '../../../knowledge-base/file-icons/py'
import Xls from '../../../knowledge-base/file-icons/xls'
import Txt from '../../../knowledge-base/file-icons/txt'
import Html from '../../../knowledge-base/file-icons/html'
import Ppt from '../../../knowledge-base/file-icons/ppt'
import { GrDocumentText } from 'react-icons/gr'
import Loading from '../../../loading/LoadingSmall'
import { useAuth } from '../../../../providers/AuthProvider'

async function waitQuery({ query, database, index = -1, onExport }) {
  const response = await fetch(
    `${config.WAREHOUSE_URL}/warehouse/query/${index}?file=${encodeURIComponent(
      database,
    )}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((r) => {
      return r.json()
    })
    .catch((e) => {
      return {
        error: 'Unknown error executing query',
      }
    })
  if (response?.error) {
    return [
      ['Failed operation'],
      {
        error: true,
      },
    ]
  }
  if (response?.processing) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return await waitQuery({ database, index })
  }
  return [
    [
      response?.response ?? '',
      ...(onExport
        ? [
            <Button
              className="mb-3"
              onClick={(e) => {
                e.target.disabled = true
                onExport()
              }}
            >
              Export to nextbrain
            </Button>,
          ]
        : []),
    ],
    {
      error: response?.error,
    },
  ]
}

async function executeQuery({
  query,
  path = 'query',
  currentDatabase,
  user,
  token,
  doExport,
  onFinish,
}) {
  let extraParams = ''
  if (doExport) extraParams = `&user_id=${user.id}&token=${token}&export=true`

  let response = await fetch(
    `${config.WAREHOUSE_URL}/warehouse/${path}?query=${encodeURIComponent(
      query,
    )}&file=${encodeURIComponent(currentDatabase)}${extraParams}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch((e) => {
    return {
      error: true,
      message: 'Unknown error executing query',
    }
  })

  if (!response?.ok || response?.error) {
    let errorMsg = 'Failed operation'
    try {
      const errorResponse = await response.json()
      errorMsg = errorResponse?.detail ?? errorMsg
    } catch (e) {}
    return [
      [errorMsg],
      {
        error: true,
      },
    ]
  }
  try {
    response = await response.json()
  } catch (e) {}

  const result = await waitQuery({
    database: currentDatabase,
    index: response?.query_index ?? -1,
    onExport: doExport
      ? null
      : () => {
          executeQuery({
            query,
            path,
            currentDatabase,
            user,
            token,
            doExport: true,
            onFinish,
          })
        },
  })

  if (doExport) {
    if (result?.[1]?.error) {
      NotificationManager.error('Failed to export data')
      if (Array.isArray(result[0])) result[0][0] = 'Export failed'
      return result
    } else {
      try {
        const jsonData = JSON.parse(result[0][0])
        if (jsonData?.id)
          onFinish(
            {
              columns: [],
              sample: [],
            },
            jsonData.id,
          )
      } catch (e) {
        NotificationManager.error('Failed to export data')
        if (Array.isArray(result[0])) result[0][0] = 'Export failed'
        return result
      }
    }
  }

  return result
}

async function openDatabase({ database }) {
  const response = await fetch(
    `${config.WAREHOUSE_URL}/warehouse/open?file=${encodeURIComponent(
      database,
    )}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch(async (e) => e)
  return !!response?.ok
}

async function closeDatabase({ database }) {
  const response = await fetch(
    `${config.WAREHOUSE_URL}/warehouse/close?file=${encodeURIComponent(
      database,
    )}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch(async (e) => e)
  return !!response?.ok
}

async function processCommand({
  command,
  currentDatabase,
  user,
  token,
  onFinish,
}) {
  const base = command.split(' ')[0]
  switch (base) {
    case '':
      return [null]
    case '.help':
    case 'help':
      return [
        [
          ` Available commands: `,
          `     show tables        Display existing tables`,
          `     .help              Display this help message`,
          `     [query]            Execute an arbitrary to extract data from an opened database`,
          `     clear              Clear the terminal screen`,
        ],
      ]
    case '.clear':
    case 'clear':
      return [-1]
    default:
      return await executeQuery({
        query: command,
        currentDatabase,
        user,
        token,
        onFinish,
      })
  }
}

function DbFileIcon({ filename }) {
  let documentIcon = <FaFile className="custom-svg" />
  switch (filename?.split('.').pop()) {
    case 'pdf':
      documentIcon = <Pdf className="custom-svg" />
      break
    case 'doc':
    case 'docx':
      documentIcon = <Doc className="custom-svg" />
      break
    case 'csv':
      documentIcon = <Csv className="custom-svg" />
      break
    case 'xlsx':
    case 'xls':
      documentIcon = <Xls className="custom-svg" />
      break
    case 'ppt':
    case 'pptx':
      documentIcon = <Ppt className="custom-svg" />
      break
    case 'py':
      documentIcon = <Py className="custom-svg" />
      break
    case 'html':
      documentIcon = <Html className="custom-svg" />
      break
    default:
      documentIcon = <Txt className="custom-svg" />
  }
  return documentIcon
}

function DbFile({ file, selected, onSelect, onRename, onDelete, placeholder }) {
  const { t } = useTranslation()
  if (placeholder) {
    return (
      <Row className="mb-4 position-relative contains-show-on-hover">
        <Col xs={12} className="dflex-center">
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement={'auto'}
            delay={{ show: 100, hide: 200 }}
            overlay={(props) => (
              <Tooltip {...props}>
                <span className="loading-tooltip pe-3">{t('Importing')}</span>
              </Tooltip>
            )}
          >
            <span>
              <FaSpinner size={50} className="rotating-2" />
            </span>
          </OverlayTrigger>
        </Col>
        <Col xs={12} className="dflex-center">
          <div className="d-inline-block text-truncate w-100 mt-1">
            {file.name}
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <Row className="mb-4 position-relative contains-show-on-hover">
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'auto'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <span className="">{t('Create a table using this dataset')}</span>
          </Tooltip>
        )}
      >
        <Form.Check
          type="checkbox"
          className="warehouse-check-use-file"
          style={{
            position: 'absolute',
            top: '-5px',
            right: 'calc(50% - 50px)',
            width: '25px',
          }}
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
        />
      </OverlayTrigger>
      <FaTrash
        size={20}
        className="position-absolute icon-btn p-0 hidden-over-hover"
        style={{
          top: '28px',
          right: 'calc(50% - 48px)',
          width: '20px',
        }}
        onClick={() => {
          NotificationManager.warning(
            <Row className="w-100">
              <Col className="text-center w-100 overflow-hidden" xs={12}>
                Confirm deletion of {file.name}
                <br />
                <Button className="mt-2" onClick={onDelete}>
                  Confirm
                </Button>
              </Col>
            </Row>,
          )
        }}
      />
      <Col xs={12} className="dflex-center">
        <DbFileIcon filename={file.name} />
      </Col>
      <Col xs={12} className="dflex-center">
        <div className="d-inline-block text-truncate w-100 mt-1">
          <InlineEdit text={file.name} onEdit={onRename} />
        </div>
      </Col>
    </Row>
  )
}

function DbEditor({ database, onCreate, onCancel }) {
  const queryClient = useQueryClient()
  const editRef = useRef()
  const fileRef = useRef()
  const { t } = useTranslation()
  const [showOverlay, _setShowOverlay] = useState(false)
  const setShowOverlay = useDebouncedCallback((show) => {
    _setShowOverlay(show)
  }, 500)

  const [selectedFiles, setSelectedFiles] = useState({})
  const [namedFiles, setNamedFiles] = useState({})

  const [refresh, setRefresh] = useState(0)
  const { data: files, isLoading: isLoadingFiles } = useQuery(
    ['files-warehouse'],
    async () => {
      const response = await fetch(
        `${config.WAREHOUSE_URL}/warehouse/files`,
      ).catch(async (e) => e)
      return await response.json()
    },
    { staleTime: Infinity },
  )
  const [temporaryFiles, setTeporaryFiles] = useState([])
  const uploadFiles = async (newFiles) => {
    if (temporaryFiles?.length) {
      NotificationManager.warning(
        'Please wait for the previous files to finish uploading',
      )
      return
    }
    setTeporaryFiles(
      newFiles.map((f) => ({
        name: f.name,
        placeholder: true,
      })),
    )
    const doUpload = async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      const response = await fetch(`${config.WAREHOUSE_URL}/warehouse/files`, {
        method: 'PUT',
        body: formData,
      }).catch(async (e) => e)
      if (!response?.ok)
        NotificationManager.error(`Failed to upload file ${file.name}`)
    }
    await Promise.all(newFiles.map((f) => doUpload(f)))
    queryClient.invalidateQueries(['files-warehouse'])
    setTeporaryFiles([])
  }

  const deleteFile = async (file) => {
    const index = files.findIndex((f) => f.name === file.name)
    files.splice(index, 1)
    selectedFiles[file.name] = false
    namedFiles[file.name] = null
    setRefresh(refresh + 1)
    const response = await fetch(
      `${config.WAREHOUSE_URL}/warehouse/files/${encodeURIComponent(
        file.name,
      )}`,
      {
        method: 'DELETE',
      },
    ).catch(async (e) => e)
    if (!response?.ok)
      NotificationManager.error(`Failed to delete file ${file.name}`)
    queryClient.invalidateQueries(['files-warehouse'])
  }

  const events = {
    onDragOver: (e) => {
      if (e?.dataTransfer?.items?.length) {
        _setShowOverlay(true)
      }
      e.preventDefault()
      e.stopPropagation()
    },

    onDrop: (e) => {
      e.preventDefault()
      e.stopPropagation()
      _setShowOverlay(false)
      if (e?.dataTransfer?.files?.length)
        uploadFiles(Array.from(e.dataTransfer.files))
    },
    onDragEnd: () => _setShowOverlay(false),
    onDragLeave: () => setShowOverlay(false),
  }

  const create = async () => {
    const files = Object.entries(selectedFiles)
      .filter(([_, v]) => v)
      .map(([k]) => k)
    const tableNames = Object.entries(namedFiles)
      .filter(([k]) => files.includes(k))
      .reduce((ac, [k, v]) => {
        ac[k] = v
        return ac
      }, {})
    onCreate({
      name: editRef?.current?.innerText ?? editRef?.current?.value,
      files,
      tableNames,
    })
  }

  return (
    <Row
      className="ms-2 flex-column justify-content-between h-100 position-relative"
      {...events}
    >
      <div
        className={`overlay-upload-documents d-inline-flex align-items-center ${
          showOverlay ? 'overlay-visible' : ''
        }`}
      >
        <GrDocumentText
          className="me-2 pe-none"
          size={40}
          style={{ filter: 'invert(1)' }}
        />
        {t('Drop files to add them to the database')}
      </div>
      <Col xs={12}>
        <Row className="justify-content-between">
          <Col xs={'auto'} className="h5 py-2">
            <InlineEdit
              ref={editRef}
              text={database?.displayName ?? 'New database'}
            />
            <MdEdit
              className="ms-1 icon-btn"
              onClick={() => editRef.current?.click()}
            />
          </Col>
          <Col xs="auto">
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'auto'}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  <span className="">{t('Upload file to database')}</span>
                </Tooltip>
              )}
            >
              <Button onClick={() => fileRef.current?.click()}>
                <FaUpload />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col className="py-2" xs={12}>
            <Row className="mx-3">
              {Array.isArray(files) ? (
                files.map((file, i) => (
                  <Col xl={3} md={4} xs={4} key={`${file.name}-${i}`}>
                    <DbFile
                      selected={selectedFiles[file.name]}
                      onSelect={(selected) =>
                        setSelectedFiles({
                          ...selectedFiles,
                          [file.name]: selected,
                        })
                      }
                      onRename={(newName) =>
                        setNamedFiles({ ...namedFiles, [file.name]: newName })
                      }
                      onDelete={() => deleteFile(file)}
                      file={file}
                    />
                  </Col>
                ))
              ) : (
                <Loading />
              )}
              {temporaryFiles.map((file, i) => (
                <Col xl={3} md={4} xs={4} key={`${file.name}-${i}`}>
                  <DbFile
                    onDelete={() => deleteFile(file)}
                    file={file}
                    placeholder={true}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12}>
            <input
              type="file"
              multiple
              ref={fileRef}
              className="d-none"
              onChange={(e) => uploadFiles(Array.from(e.target.files))}
            />
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-end" xs={12}>
        <Button className="original empty-secondary me-2" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            isLoadingFiles || !Object.entries(selectedFiles).some(([k, v]) => v)
          }
          onClick={create}
        >
          Save database
        </Button>
      </Col>
    </Row>
  )
}

export default function ConfigureDataWarehouse({
  id,
  actionLabel = 'Save',
  configuration,
  onFinish,
  close,
}) {
  const { t } = useTranslation()
  const { token, user } = useAuth()
  //TODO
  // eslint-disable-next-line
  const [sample, setSample] = useState(null)
  const [commands, setCommands] = useState([])
  const [currentDatabase, setCurrentDatabase] = useState(null)
  const [requestedDatabase, setRequestedDatabase] = useState(null)
  const [editDb, setEditDb] = useState(null)
  const [terminalLineData, setTerminalLineData] = useState([
    <TerminalOutput>
      <span style={{ color: 'var(--nextbrain-link-color)' }}>
        {t('Type .help for a list of commands')}
      </span>
    </TerminalOutput>,
  ])

  useEffect(() => {
    if (requestedDatabase) {
      const requested = requestedDatabase
      if (currentDatabase) {
        closeDatabase({ database: currentDatabase.displayName })
          .then(async (success) => {
            if (!success) {
              NotificationManager.error('Failed to close database')
              return null
            }
            setCurrentDatabase(null)
            await openDatabase({ database: requested.displayName }).then(
              async (success) => {
                if (!success)
                  NotificationManager.error('Failed to open database')
                else setCurrentDatabase(requested)
              },
            )
          })
          .finally(() => setRequestedDatabase(null))
      } else {
        openDatabase({ database: requested.displayName })
          .then(async (success) => {
            if (!success) NotificationManager.error('Failed to open database')
            else setCurrentDatabase(requested)
          })
          .finally(() => setRequestedDatabase(null))
      }
    }
    // eslint-disable-next-line
  }, [requestedDatabase])

  const { data: databases, isLoading: isLoadingDatabases } = useQuery(
    ['databases-warehouse'],
    async () => {
      const response = await fetch(`${config.WAREHOUSE_URL}/warehouse/dbs`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error('Failed to fetch databases')

      const databases = await response.json()
      if (Array.isArray(databases)) {
        const opened = databases.find((n) => n?.name?.endsWith('.opened'))
        if (opened) {
          const openedName = opened?.name?.replace('.opened', '')
          const openedDb = databases.find((n) => n?.name === openedName)
          setCurrentDatabase(openedDb)
        }
        return (
          databases
            ?.filter((n) => n?.name && !n?.name?.endsWith('.opened'))
            ?.map((d) => {
              d.displayName = d.name.replace(/\.db$/, '')
              return d
            }) ?? []
        )
      } else throw new Error('Failed to fetch databases')
    },
    { stale: Infinity },
  )

  useEffect(() => {
    if (commands.length) {
      setTerminalLineData((c) => [
        ...c,
        <TerminalOutput>{commands[0]}</TerminalOutput>,
      ])
      processCommand({
        command: commands[0],
        currentDatabase: currentDatabase?.displayName,
        user,
        token,
        onFinish,
      }).then(([result, options]) => {
        if (result === -1) {
          setTerminalLineData([
            <TerminalOutput>
              <span style={{ color: 'var(--nextbrain-link-color)' }}>
                {t('Type .help for a list of commands')}
              </span>
            </TerminalOutput>,
          ])
          setCommands([])
          return
        }
        if (Array.isArray(result))
          setTerminalLineData((terminalLineData) => [
            ...terminalLineData,
            ...result.map((line) => (
              <TerminalOutput>
                <span className={`${options?.error ? 'error-stderr' : ''}`}>
                  {line}
                </span>
              </TerminalOutput>
            )),
          ])
        setCommands(commands.slice(1))
      })
    }
    // eslint-disable-next-line
  }, [commands])

  const lastActive = useRef(null)
  useEffect(() => {
    lastActive.current = currentDatabase
  }, [currentDatabase])

  useEffect(() => {
    return () => {
      if (lastActive.current)
        closeDatabase({ database: lastActive.current.displayName })
    }
  }, [])

  const createDatabase = async ({ name, files, tableNames }) => {
    setEditDb(null)
    const response = await fetch(`${config.WAREHOUSE_URL}/warehouse/dbs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        files,
        table_names: tableNames,
      }),
    }).catch((e) => e)
    if (!response?.ok) {
      NotificationManager.error('Failed to create database')
    }
  }

  return (
    <Row
      className="flex-column justify-content-between mx-0"
      style={{ minHeight: '60vh' }}
    >
      <Col xs={12} className="">
        <Row>
          {!editDb && (
            <Col
              xl={2}
              md={3}
              xs={4}
              className={`px-0 ${editDb ? 'pe-none opacity-50' : ''}`}
            >
              <div className="warehouse-database-selector h-100 overflow-auto position-relative">
                <Row className="warehouse-database-list w-100 ms-0">
                  <Col
                    xs={12}
                    className="h5 mb-0 pb-3 d-flex align-items-center justify-content-start"
                  >
                    <FaDatabase className="me-2" /> Databases
                  </Col>
                  {Array.isArray(databases) && !isLoadingDatabases ? (
                    <>
                      {databases.map((d) => (
                        <Col xs={12} key={d.name} className="py-1">
                          <div
                            className={`icon-btn p-2 w-100 d-flex align-items-center justify-content-start ${
                              d.displayName === currentDatabase?.displayName
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => {
                              setRequestedDatabase(d)
                              setTerminalLineData([
                                <TerminalOutput>
                                  <span
                                    style={{
                                      color: 'var(--nextbrain-link-color)',
                                    }}
                                  >
                                    {t('Type .help for a list of commands')}
                                  </span>
                                </TerminalOutput>,
                              ])
                            }}
                          >
                            <FaTable className="me-2" />
                            {d.displayName}
                          </div>
                        </Col>
                      ))}
                      <Col className="no-border" xs={12}>
                        <div
                          className={`icon-btn py-2 w-100 dflex-center`}
                          onClick={() => setEditDb({})}
                        >
                          <FaPlusCircle size={40} />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <div className={`bouncing-loader mt-4`}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          )}
          {editDb ? (
            <Col
              className={`pt-2 pb-3 db-editor`}
              xs={12}
              style={{ minHeight: '60vh' }}
            >
              <DbEditor
                database={editDb}
                onCancel={() => setEditDb(null)}
                onCreate={createDatabase}
              />
            </Col>
          ) : (
            <Col
              className={`nb-warehouse-terminal-container px-0`}
              xl={10}
              md={9}
              xs={8}
            >
              {currentDatabase && (
                <Terminal
                  name=""
                  colorMode={ColorMode.Dark}
                  onInput={(input) => setCommands((c) => [...c, input?.trim()])}
                  prompt={
                    currentDatabase ? `$${currentDatabase?.displayName}:` : '>'
                  }
                  height="calc(60vh - 1px)"
                >
                  {terminalLineData}
                </Terminal>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}
