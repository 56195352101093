import React, { useState } from 'react'
import { Col, Row, Button, Form, Image } from 'react-bootstrap'
import { FaEnvelope } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { recoverPassword } from '../../services/login'

import './RecoverForm.css'

export default function Recover(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleSendEmail = (e) => {
    e.preventDefault()
    setIsLoading(true)
    recoverPassword(e.target.email.value).then(() => {
      setIsLoading(false)
      navigate(`/`)
      NotificationManager.info(
        t('An email has been sent to your email address'),
      )
    })
  }
  return (
    <Row id="boxlogin" style={{ minHeight: '800px' }}>
      <Col id="LoginContainer" md={6}>
        <Row className="h-100 justify-content-center flex-column">
          <Row>
            <Col className="h4 mb-2" md={12}>
              {t('Reset your password')}
            </Col>
            <p>
              {t(
                'Enter the email adress associated with your account and we will send you a link to reset your password',
              )}
            </p>
          </Row>
          <Form onSubmit={handleSendEmail}>
            <Row className="labellogin">{t('Email')}</Row>
            <Row className="posrelative ">
              <FaEnvelope className="iconinput iconinputlogin envelop" />
              <Form.Control
                id="userPassword"
                type="email"
                placeholder="example@email.com"
                bsPrefix="inputnextbrain"
                name="email"
                required
                onChange={(e) => {
                  setIsLoading(false)
                }}
              />
            </Row>
            <Row className="mb-5" align="center">
              <Button
                id="loginButton"
                className="w-100 p-2"
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                {t('Continue')}
              </Button>
              <Link
                className="mt-3 mb-5 backlink"
                style={{ fontSize: '20px', color: 'black' }}
                to={'/'}
              >
                <strong style={{ color: 'var(--nextbrain-white-font)' }}>
                  {t('Go back')}
                </strong>
              </Link>
            </Row>
          </Form>
        </Row>
      </Col>
      <Col md={6} className="p-0 imglogin">
        <div className="logologin">
          <Image
            alt="NextBrain Logo"
            height={80}
            width={80}
            src="/LogoLogin.svg"
          />
        </div>
      </Col>
    </Row>
  )
}
