import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MsalProvider } from '@azure/msal-react'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './providers/AuthProvider'
import { msalInstance } from './services/msal'
import './i18n'
// import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-notifications/lib/notifications.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-image-lightbox/style.css'
import { NavProvider } from './providers/NavProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))
const REACT_APP_GOOGLE_CLIENT_ID =
  (process.env.REACT_APP_MODE || process.env.NODE_ENV) === 'development'
    ? '61098952025-p0aenq0l0tqtvj2d6jms72nfurl1p0a8.apps.googleusercontent.com'
    : '833640934392-gpqa61e925cutiq2e35ld6ju2lipij2v.apps.googleusercontent.com'
const queryClient = new QueryClient({
  logger: {
    log: (...args) => {
      console.log(args)
    },
    warn: (...args) => {
      console.log(args)
    },
    error: (...args) => {
      console.log(args)
    },
  },
})
window.queryClient = queryClient

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NavProvider>
            {/* <div className="d-xs-block d-sm-none">
              <NoMobileDevices />
            </div> */}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <App />
          </NavProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MsalProvider>
  </GoogleOAuthProvider>,

  // </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
