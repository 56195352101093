import React, { useState, useRef } from 'react'
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
} from 'react-bootstrap'
import { ResponsiveBar } from '@nivo/bar'
import { useTranslation } from 'react-i18next'
import { BiRightArrow } from 'react-icons/bi'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import {
  getAllUsersCreatedAMonth,
  getCountedPredictionsByMonth,
  getCountedTrainsByMonth,
  getCountedErrorsByMonth,
  getActiveUsersByMonth,
  getUsersCreatedAMonthValues,
  getActiveUsersByMonthValues,
  getCountedErrorsByMonthValues,
  getCountedReportsByMonth,
  getFlag,
  setFlag,
  getCountedChatsByMonth,
} from '../../services/user'
import NextbrainSelect from '../model-content/NextbrainSelect'
import Loading from '../loading/LoadingSmall'

import './AdminDashboard.css'
import UserList from './UserList'
import ActiveUserList from './ActiveUserList'
import ErrorList from './ErrorList'
import ReportList from './ReportList'
import { deleteReport, solveReport } from '../../services/report'
import { NotificationManager } from 'react-notifications'
import { reportIssue } from '../../services/model'
import ErrorNB from '../loading/ErrorNB'
import UserCompanies from './UserCompanies'
import PruneUsers from './PruneUsers'

const colors = {
  automatic: '#ff6600',
  unsolved: 'var(--nextbrain-tables-negative-graph-bar-color)',
  inactive: 'var(--nextbrain-tables-negative-graph-bar-color)',
}

function Actions({ ...props }) {
  const [duplicateModel, setDuplicateModel] = useState(false)
  const [manageCompanies, setManageCompanies] = useState(false)
  const [result, setResult] = useState(null)
  const modelIdRef = useRef()
  const { token, signout } = useAuth()
  const [showPrune, setShowPrune] = useState(false)

  return (
    <Row {...props}>
      <Col xs={12}>
        <OverlayTrigger
          rootClose={true}
          trigger={'click'}
          placement="right"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
            <Tooltip {...props}>
              <Row>
                <Col xs={12}>
                  <Button
                    onClick={() => {
                      setDuplicateModel(true)
                    }}
                    className="action-button-admin w-100"
                  >
                    Duplicate model
                  </Button>
                </Col>
                <Col className="mt-2" xs={12}>
                  <Button
                    onClick={() => setManageCompanies(true)}
                    className="action-button-admin w-100"
                  >
                    Manage user companies
                  </Button>
                </Col>
                <Col
                  className="mt-2"
                  xs={12}
                  onClick={() => setShowPrune(true)}
                >
                  <Button className="action-button-admin w-100">
                    Prune users
                  </Button>
                </Col>
                <Col className="mt-2" xs={12}>
                  <Button disabled className="action-button-admin w-100">
                    Other actions
                  </Button>
                </Col>
              </Row>
            </Tooltip>
          )}
        >
          <Button className="action-button-admin">
            Actions <BiRightArrow size={10} />
          </Button>
        </OverlayTrigger>
      </Col>
      <Modal
        size="xl"
        backdrop="static"
        show={manageCompanies}
        onHide={() => {
          setManageCompanies(false)
        }}
      >
        <Modal.Header closeButton>Manage User Companies</Modal.Header>
        <Modal.Body>
          <UserCompanies />
        </Modal.Body>
      </Modal>
      <Modal
        show={duplicateModel}
        onHide={() => {
          setDuplicateModel(false)
          setResult(null)
        }}
      >
        <Modal.Header closeButton>Duplicate model</Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Control
                ref={modelIdRef}
                className="nb-input"
                placeholder="model id"
              />
            </Col>
            <Col xs={12}>
              <Button
                className="action-button-admin w-100 mt-2"
                onClick={() => {
                  const [id] = [modelIdRef].map((r) => r.current?.value)
                  if (!id) {
                    NotificationManager.error('Please fill all fields enabled')
                    return
                  }
                  reportIssue({
                    modelId: id,
                    msg: 'Duplicate model',
                    token,
                    signout,
                    rawResponse: true,
                  })
                    .then(async (res) => {
                      if (res.status === 200) {
                        const result = await res.json()
                        if (result?.id) setResult('Duplicated')
                        else setResult('Error')
                      } else setResult('Error')
                    })
                    .catch((err) => {
                      setResult(err?.message ?? 'unknown error')
                    })
                }}
              >
                Duplicate
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2 d-flex justify-content-center" xs={12}>
              <strong>{result}</strong>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showPrune && <PruneUsers onHide={() => setShowPrune(false)} />}
    </Row>
  )
}

function AdminGraph({
  data,
  loading,
  axisLeft,
  indexBy = 'day',
  keys = 'quantity',
  type,
  onClick = () => {},
  children,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Col xs={12} className={`created-graph ${props?.className ?? ''}`}>
      {children}
      {loading ? (
        <Loading />
      ) : !Array.isArray(data) ? (
        <ErrorNB errorMessage={t('Failed to retrieve data')} />
      ) : (
        <ResponsiveBar
          data={data}
          indexBy={indexBy}
          keys={Array.isArray(keys) ? keys : [keys]}
          margin={{ top: 10, right: 60, bottom: 45, left: 60 }}
          padding={0.3}
          colors={(s) =>
            colors[s?.id] ?? 'var(--nextbrain-tables-graph-bar-color)'
          }
          onClick={({ data }) => {
            onClick(type, data.day)
          }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          theme={{
            background: '#ffffff00',
            textColor: '#ffffff',
            fontSize: 11,
            axis: {
              domain: {
                line: {
                  stroke: '#ffffff',
                  strokeWidth: 1,
                },
              },
              legend: {
                text: {
                  fontSize: 12,
                  fill: '#ffffff',
                },
              },
              ticks: {
                line: {
                  stroke: '#ffffff',
                  strokeWidth: 1,
                },
                text: {
                  fontSize: 11,
                  fill: '#ffffff',
                },
              },
            },
            grid: {
              line: {
                stroke: '#ffffff',
                strokeWidth: 1,
              },
            },
            legends: {
              title: {
                text: {
                  fontSize: 11,
                  fill: '#ffffff',
                },
              },
              text: {
                fontSize: 11,
                fill: '#ffffff',
              },
              ticks: {
                line: {},
                text: {
                  fontSize: 10,
                  fill: '#ffffff',
                },
              },
            },
            annotations: {
              text: {
                fontSize: 13,
                fill: '#ffffff',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              link: {
                stroke: '#ffffff',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              outline: {
                stroke: '#ffffff',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              symbol: {
                fill: '#000000',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
            },
            tooltip: {
              container: {
                background: '#ffffff',
                color: '#333333',
                fontSize: 12,
              },
              basic: {},
              chip: {},
              table: {},
              tableCell: {},
              tableCellValue: {},
            },
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('Day of the Month'),
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t(axisLeft),
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="black"
          legends={[]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      )}
    </Col>
  )
}

function Maintanance() {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const queryClient = useQueryClient()

  const { data } = useQuery(
    ['app-wide-is-maintenance'],
    async () => {
      const maintenance = await getFlag('maintenance')
      return Number.parseInt(maintenance)
    },
    { staleTime: Infinity },
  )
  const maintenance = data === 1

  return (
    <>
      <Form.Check
        checked={maintenance}
        type="checkbox"
        label={t('Maintenance mode')}
        onChange={() => setShowModal(true)}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="text-center" closeButton>
          Maintenance mode
        </Modal.Header>
        <Modal.Body>
          <strong className="h4 w-100">
            {maintenance
              ? 'Enable maintenance, all non admin users will lose access to the app'
              : 'Confirm disabled maintenance'}
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-between w-100">
            <Col xs="auto">
              <Button
                className="p-3 original"
                onClick={(e) => {
                  setFlag(
                    'maintenance',
                    maintenance ? 0 : 1,
                    token,
                    signout,
                  ).then((v) => {
                    queryClient.invalidateQueries(['app-wide-is-maintenance'])
                    setShowModal(false)
                  })
                }}
              >
                Confirm
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                className="p-3 original"
                variant="danger"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function AdminDashboard() {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const queryClient = useQueryClient()
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)
  const [includeSoftpoint, setIncludeSoftpoint] = useState(true)
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ]

  const [requestInfo, setRequestInfo] = useState(null)

  const userValues = useQuery(
    ['adminUsersValues', requestInfo],
    async () => {
      if (!requestInfo || requestInfo.type !== 'users') return null
      const { day, month, year } = requestInfo

      return getUsersCreatedAMonthValues(token, day, parseInt(month) + 1, year)
    },
    { staleTime: Infinity },
  )

  const activeUsersValues = useQuery(
    ['adminActiveUsersValues', requestInfo],
    async () => {
      if (!requestInfo || requestInfo.type !== 'activeUsers') return null
      const { day, month, year } = requestInfo

      return getActiveUsersByMonthValues(token, day, parseInt(month), year)
    },
    { staleTime: Infinity },
  )

  const errorValues = useQuery(
    ['adminErrorValues', requestInfo],
    async () => {
      if (!requestInfo || requestInfo.type !== 'errors') return null
      const { day, month, year } = requestInfo

      return getCountedErrorsByMonthValues(token, day, parseInt(month), year)
    },
    { staleTime: Infinity },
  )

  const { data: usersCreated, isLoading: ucIsLoading } = useQuery(
    ['users-created-admin', selectedYear, includeSoftpoint, selectedMonth],
    async () => {
      return await getAllUsersCreatedAMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: predictionCount, isLoading: pcIsLoading } = useQuery(
    ['prediction-count-admin', selectedYear, includeSoftpoint, selectedMonth],
    () => {
      return getCountedPredictionsByMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: trainCount, isLoading: tcIsLoading } = useQuery(
    ['train-count-admin', selectedYear, includeSoftpoint, selectedMonth],
    () => {
      return getCountedTrainsByMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: errorCount, isLoading: ecIsLoading } = useQuery(
    ['error-count-admin', selectedYear, includeSoftpoint, selectedMonth],
    () => {
      return getCountedErrorsByMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: chatCount, isLoading: ccIsLoading } = useQuery(
    ['chat-count-admin', selectedYear, includeSoftpoint, selectedMonth],
    () => {
      return getCountedChatsByMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: activeUsers, isLoading: auIsLoading } = useQuery(
    ['active-user-count-admin', selectedYear, includeSoftpoint, selectedMonth],
    () => {
      return getActiveUsersByMonth(
        token,
        parseInt(selectedMonth) + 1,
        selectedYear,
        includeSoftpoint,
      )
    },
    { staleTime: Infinity },
  )

  const { data: reports, isLoading: rcIsLoading } = useQuery(
    ['report-admin', selectedYear, selectedMonth],
    async () => {
      const result = await getCountedReportsByMonth(
        token,
        parseInt(selectedMonth),
        selectedYear,
      )

      const recounts = result.map((items, index) => {
        const res = {
          day: index + 1,
          solved: 0,
          automatic: 0,
          unsolved: 0,
        }
        items.forEach((element) =>
          element?.solved
            ? res.solved++
            : element?.metadata?.source === 'app'
            ? res.automatic++
            : res.unsolved++,
        )
        return res
      })

      return { reportCount: result, reportGraph: recounts }
    },
    { staleTime: Infinity },
  )
  const { reportCount, reportGraph } = reports ?? {
    reportCount: [],
    reportGraph: [],
  }

  const [reportDay, setReportDay] = useState(null)

  return (
    <>
      <Row className="admin-dashboard mx-0">
        <Col className="admin-dashboard-select-col" xs={12}>
          <Row className="date-selector justify-content-between">
            <Col xs={10}>
              <Row>
                <Col
                  style={{ marginLeft: '-50px' }}
                  className="ps-0 d-flex align-items-center"
                  xs={'auto'}
                >
                  <Actions />
                </Col>
                <Col xs={12} style={{ maxWidth: '300px' }}>
                  <NextbrainSelect
                    value={{
                      label: months[selectedMonth],
                      value: selectedMonth,
                    }}
                    onChange={(value) => setSelectedMonth(value?.value)}
                    options={months.map((month, index) => ({
                      label: month,
                      value: index,
                    }))}
                    hideSelectedOptions={false}
                    isClearable={false}
                    placeholder={'Select month'}
                    type={'dark'}
                  />
                </Col>
                <Col xs={12} style={{ maxWidth: '300px' }}>
                  <NextbrainSelect
                    value={{ label: selectedYear, value: selectedYear }}
                    onChange={(value) => setSelectedYear(value?.value)}
                    options={Array.from(
                      { length: 2 },
                      (_, i) => currentYear - i,
                    ).map((y) => ({ label: y, value: y }))}
                    hideSelectedOptions={false}
                    isClearable={false}
                    placeholder={'Select year'}
                    type={'dark'}
                  />
                </Col>
                <Col className="mt-1" xs={'auto'}>
                  <Form.Check
                    checked={includeSoftpoint}
                    type="checkbox"
                    label={t('Softpoint accounts')}
                    onChange={(e) => setIncludeSoftpoint(e.target.checked)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2}>
              <Maintanance />
            </Col>
          </Row>
        </Col>
        <AdminGraph
          data={usersCreated}
          loading={ucIsLoading}
          axisLeft={'Number of Users'}
          type={'users'}
          keys={['active', 'inactive']}
          onClick={(type, day) => {
            setRequestInfo({
              type,
              year: selectedYear,
              month: selectedMonth,
              day,
            })
          }}
          className="active-bar-cursor"
        >
          <div className="mb-1 mt-5 title">
            <div className="graph-label h2">{t('Users created')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={activeUsers}
          loading={auIsLoading}
          axisLeft={'Active users'}
          type={'activeUsers'}
          onClick={(type, day) => {
            setRequestInfo({
              type,
              year: selectedYear,
              month: selectedMonth,
              day,
            })
          }}
          className="active-bar-cursor"
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('Active users')} </div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={trainCount}
          loading={tcIsLoading}
          axisLeft={'Trainings'}
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('Trainings')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={predictionCount}
          loading={pcIsLoading}
          axisLeft={'Predictions'}
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('Predictions')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={errorCount}
          loading={ecIsLoading}
          axisLeft={'Errors'}
          type={'errors'}
          onClick={(type, day) => {
            setRequestInfo({
              type,
              year: selectedYear,
              month: selectedMonth,
              day,
            })
          }}
          className="active-bar-cursor"
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('Errors')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={reportGraph}
          keys={['solved', 'unsolved', 'automatic']}
          loading={rcIsLoading}
          axisLeft={'User Reports'}
          type={'userReports'}
          onClick={(type, day) => {
            setReportDay(reportCount[day - 1])
          }}
          className="active-bar-cursor"
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('User reports')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
        <AdminGraph
          data={chatCount}
          loading={ccIsLoading}
          axisLeft={'User chats'}
          className="active-bar-cursor"
        >
          <div className="mb-1 mt-1 title">
            <div className="graph-label h2">{t('User chats')}</div>
            <span className="line2" />
          </div>
        </AdminGraph>
      </Row>
      <UserList
        visible={requestInfo?.type === 'users'}
        isLoading={userValues?.isLoading}
        hide={() => setRequestInfo(null)}
        users={userValues?.data}
      />
      <ActiveUserList
        visible={requestInfo?.type === 'activeUsers'}
        isLoading={activeUsersValues?.isLoading}
        hide={() => setRequestInfo(null)}
        users={activeUsersValues?.data}
      />
      <ErrorList
        visible={requestInfo?.type === 'errors'}
        isLoading={errorValues?.isLoading}
        hide={() => setRequestInfo(null)}
        errors={errorValues?.data}
      />
      <ReportList
        visible={reportDay}
        hide={() => setReportDay(null)}
        reports={reportDay}
        solveReport={async (report, message) => {
          const res = await solveReport({
            id: report.id,
            message,
            token,
            signout,
          })
          if (res.ok)
            queryClient.invalidateQueries([
              'report-admin',
              selectedYear,
              selectedMonth,
            ])
          else NotificationManager.error('Faile to solve report')
        }}
        deleteReport={async (report) => {
          await deleteReport({ id: report.id, token, signout })
          setReportDay((r) => r.filter((r) => r.id !== report.id))
          queryClient.invalidateQueries([
            'report-admin',
            selectedYear,
            selectedMonth,
          ])
        }}
      />
    </>
  )
}
