import { useRef, useState } from 'react'
import { Handle, Position, useReactFlow } from 'reactflow'
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaPlus, FaTrash, FaFlagCheckered } from 'react-icons/fa'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import { useTranslation } from 'react-i18next'
import { Template } from './DatasourceConnectors'
import { datasourceConfig, actionConfig } from './config'
import { useProps } from '../../../providers/PropsProvider'

export default function DatasourceConnectorNode({ id, data, isConnectable }) {
  const [status, setStatus] = useState({})
  const componentRef = useRef(datasourceConfig[data.type].component)
  const componentConfigRef = useRef(datasourceConfig[data.type].prepareData)
  const ComponentConfig = componentRef.current
  const { getNode } = useReactFlow()
  const { createNewNode, selectOutputNode, outputNode } = useProps()
  const close = () => {
    data.controls.onUpdate({ ...data, active: false })
  }
  const { t } = useTranslation()
  const node = getNode(id)
  const isOutput = outputNode === node?.id
  const isConfig = data.type !== 'Config'
  const isPlaceholder = node?.data?.placeholderNode

  return (
    <>
      <Template
        id={node.id}
        node={node?.data ?? {}}
        position={node?.position}
        type={data.type}
        className={`no-hover node-type ${data.valid ? 'valid' : 'invalid'}`}
      />
      {isConfig && (
        <>
          <Handle
            type="source"
            position={Position.Right}
            id={`${id}_source`}
            style={{
              right: '-25px',
              background: '#555',
              minWidth: '15px',
              minHeight: '15px',
            }}
            isConnectable={isConnectable}
          />
        </>
      )}
      {!isPlaceholder && (
        <>
          <ContextMenuTrigger
            id={`datasource-flow-transform-context-${id}`}
            mouseButton={0}
            disableIfShiftIsPressed={true}
          >
            <OverlayTrigger
              rootClose={true}
              trigger={['hover', 'focus']}
              placement={'top'}
              delay={{ show: 100, hide: 200 }}
              overlay={(props) =>
                status.visibleActions ? (
                  <></>
                ) : (
                  <Tooltip
                    {...props}
                    className={`context-menu-column-type pe-none ${
                      props?.className ?? ''
                    }`}
                  >
                    <span className="">
                      {t('Apply a transformation for your datasource')}
                    </span>
                  </Tooltip>
                )
              }
            >
              <div
                className={`transform-datasource-trigger ${
                  status.visibleActions ? 'force-visible-trigger' : ''
                } ${node?.data?.valid ? '' : 'd-none'} ${
                  isConfig ? 'config-node-item' : ''
                }`}
              >
                <FaPlus size={30} />
              </div>
            </OverlayTrigger>
          </ContextMenuTrigger>
          <ContextMenu
            id={`datasource-flow-transform-context-${id}`}
            className="menu-context-dataflow config-forced-actions-context-menu"
            onShow={() => setStatus((d) => ({ ...d, visibleActions: true }))}
            onHide={() => setStatus((d) => ({ ...d, visibleActions: false }))}
          >
            {Object.keys(actionConfig).map((key) => (
              <MenuItem key={key}>
                <div
                  className="d-inline-block text-nowrap h5 mb-0"
                  onClick={() => {
                    createNewNode({
                      type: key,
                      position: node.position,
                      forceActive: (actionConfig[key]?.inputs ?? 1) === 1,
                      input: node,
                    })
                  }}
                >
                  {actionConfig[key].iconSmall}
                  {t(key)}
                </div>
              </MenuItem>
            ))}
          </ContextMenu>
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement={'top'}
            delay={{ show: 100, hide: 200 }}
            overlay={(props) =>
              status.visibleActions ? (
                <></>
              ) : (
                <Tooltip
                  {...props}
                  className={`context-menu-column-type pe-none ${
                    props?.className ?? ''
                  }`}
                >
                  <span className="">
                    {isOutput
                      ? t('Node selected as output for the model')
                      : t('Select as output node')}
                  </span>
                </Tooltip>
              )
            }
          >
            <div
              className={`output-datasource-trigger icon-btn  ${
                node?.data?.valid ? '' : 'd-none'
              } ${isOutput ? 'selected-output-node' : ''} ${
                isConfig ? 'config-node-item' : ''
              }`}
              onClick={() => !isOutput && selectOutputNode({ node })}
            >
              {/*
            <Image
              className="d-inline-block ms-1"
              src={'/goal.svg'}
              height={30}
            />*/}
              <FaFlagCheckered color="white" size={28} />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement={'top'}
            delay={{ show: 100, hide: 200 }}
            overlay={(props) => (
              <Tooltip
                {...props}
                className={`context-menu-column-type pe-none ${
                  props?.className ?? ''
                }`}
              >
                <span className="">{t('Delete node')}</span>
              </Tooltip>
            )}
          >
            <div
              className={`delete-datasource-trigger icon-btn `}
              onClick={() => node?.data?.controls?.onDelete()}
            >
              <FaTrash color="white" size={20} />
            </div>
          </OverlayTrigger>
          <Modal
            dialogClassName={`modal-nb modal-datasource-${data.type?.replace(
              ' ',
              '-',
            )}`}
            show={data.active}
            keyboard={false}
            onHide={close}
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="ms-5">{t(data.type)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {data.active && (
                <ComponentConfig
                  actionLabel="Save"
                  configuration={data.config}
                  onFinish={(sample, ...props) => {
                    data.sample = sample
                    const newData = componentConfigRef.current(data, ...props)
                    data.controls.onUpdate({ ...newData, active: false })
                  }}
                  sampleData={data.sample ?? null}
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
