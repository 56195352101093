import React, { useState, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { getTagsByDocument, setTagsByDocument } from '../../services/document'
import { Col, Row, Modal, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { FaCheck, FaPlus } from 'react-icons/fa'
import { MdOutlineCancel } from 'react-icons/md'
import { Tag } from './TagManager'

export default function EditDocument({ document, onHide }) {
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()
  const newRef = useRef()
  const [creating, setCreating] = useState(false)
  const { t } = useTranslation()

  const { data: _tags } = useQuery(
    ['tags-document', document.id],
    async () => {
      const tags = await getTagsByDocument({
        documentId: document.id,
        token,
        signout,
      })
      if (!Array.isArray(tags)) return []
      tags.sort((a, b) => a.localeCompare(b))
      return tags
    },
    { staleTime: Infinity },
  )
  const tags = _tags || []

  const [newTags, setNewTags] = useState([])

  return (
    <Modal size="lg" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <span className="h3">{t('Edit document')}</span>
      </Modal.Header>
      <Modal.Body>
        <Row
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Col xs={12}>
            <Form.Control
              className="nb-input"
              disabled
              value={document?.name}
            />
          </Col>
          <Col className="py-4" xs={12}>
            <Row className="tag-manger-container">
              <Col className="mb-2" xs={12}>
                <strong>{t('Tags')}</strong>
              </Col>
              <Col xs={'auto'} className="d-flex align-items-center">
                {creating && (
                  <Form.Control
                    style={{ maxWidth: '150px' }}
                    className="nb-input d-inline-block py-1 me-1"
                    placeholder={t('New tag name')}
                    ref={newRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && newRef.current.value) {
                        const val = newRef.current.value.trim()
                        if (newTags.includes(val) || tags.includes(val)) {
                          NotificationManager.error(t('Tag already exists'))
                          return
                        }
                        if (val) {
                          setNewTags([val, ...newTags])
                          newRef.current.value = ''
                          setCreating(false)
                        }
                      }
                    }}
                    autoFocus
                  />
                )}
                {creating ? (
                  <>
                    <FaCheck
                      className="icon-btn"
                      size={18}
                      onClick={() => {
                        const val = newRef.current.value.trim()
                        if (newTags.includes(val) || tags.includes(val)) {
                          NotificationManager.error(t('Tag already exists'))
                          return
                        }
                        if (val) {
                          setNewTags([val, ...newTags])
                          newRef.current.value = ''
                          setCreating(false)
                        }
                      }}
                    />
                    <MdOutlineCancel
                      className="icon-btn ms-1"
                      size={23}
                      onClick={() => setCreating(false)}
                    />
                  </>
                ) : (
                  <FaPlus
                    className="icon-btn"
                    size={20}
                    onClick={() => setCreating(true)}
                  />
                )}
              </Col>
              {newTags.map((tag, index) => (
                <Tag
                  tag={tag}
                  key={tag}
                  onDelete={() =>
                    setNewTags(newTags.filter((_, i) => i !== index))
                  }
                />
              ))}
              {tags.map((tag, index) => (
                <Tag
                  tag={tag}
                  key={tag}
                  onDelete={() => {
                    tags.splice(index, 1)
                    setNewTags((t) => [...t])
                  }}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col className="d-inline-flex justify-content-end" xs={12}>
            <Button
              className="me-3 original empty-secondary"
              variant="secondary"
              onClick={() => {
                queryClient.invalidateQueries(['tags-document', document.id])
                onHide()
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                const val = newRef?.current?.value?.trim()
                if (val && !newTags.includes(val) && !tags.includes(val))
                  newTags.push(val)

                setTagsByDocument({
                  documentId: document.id,
                  tags: [...tags, ...newTags],
                  token,
                  signout,
                }).finally((r) => {
                  queryClient.invalidateQueries(['tags-document', document.id])
                  queryClient.invalidateQueries([
                    'repository-tags',
                    document.repository_id,
                  ])
                  queryClient.invalidateQueries([
                    'documents',
                    document.repository_id,
                    token,
                  ])
                })
                onHide()
              }}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
