import React, { useRef } from 'react'
import { GrDocumentCsv, GrDocumentImage } from 'react-icons/gr'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'

import { NotificationManager } from 'react-notifications'
import $ from 'jquery'

// Assumes that the children contain an element with class 'data-holder' which has:
// - data-csv attribute with a JSON-encoded CSV
// - data-filename attribute with the filename to use
// downloadOptions: https://html2canvas.hertzen.com/configuration
export function DownloadGraphOverlay({
  downloadOptions,
  buttonsStyle = { top: '3px' },
  children,
  disabled = false,
  customDownload,
}) {
  const { t } = useTranslation()
  //find svg in descendants of children using querySelector
  const svgRef = useRef(null)
  return (
    <div className="download-graph-overlay">
      <div style={{ ...buttonsStyle, ...(disabled && { display: 'none' }) }}>
        <div
          className="download-graph-button spreadsheet-color-hover"
          title={t('Download as CSV')}
          onClick={(e) => {
            if (customDownload) {
              customDownload()
              return
            }
            e.stopPropagation()

            let element, csvData
            if (
              (element = svgRef.current?.querySelector('.data-holder')) &&
              (csvData = JSON.parse(decodeURIComponent(element.dataset.csv))) &&
              csvData.length > 0
            ) {
              const csvContent =
                'data:text/csv;charset=utf-8,' +
                csvData.map((x) => x.join(',')).join('\n')
              const encodedUri = encodeURI(csvContent)
              const link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute(
                'download',
                `${element.dataset.filename ?? 'graph'}.csv`,
              )
              document.body.appendChild(link)
              link.click()
              link.remove()
            } else {
              NotificationManager.error('No data to download')
            }
          }}
        >
          <span>
            <GrDocumentCsv size={25} />
          </span>
        </div>
        <div
          className="download-graph-button"
          title={t('Download as PNG')}
          onClick={(e) => {
            e.stopPropagation()
            const element = svgRef.current
            const filename =
              svgRef.current?.querySelector('.data-holder')?.dataset
                ?.filename ?? 'graph'

            svgRef.current.classList.add('download')
            const firstColor = element.style.color
            const firstFill = element.style.fill
            const noPrint = $(element).find('.no-print')
            const noBg = $(element)
              .find('.no-bg, .insight-model-content')
              .toArray()
              .map((e) => [e, $(e).css('background-color')])
            const noBorder = $(element)
              .find('.no-border, .insight-model-content')
              .toArray()
              .map((e) => [e, $(e).css('border')])

            noPrint.each((i, e) => $(e).css('opacity', 0))
            noBg.forEach(([e]) => $(e).css('background-color', '#00000000'))
            noBg.forEach(([e]) => $(e).css('border', 'none'))
            element.style.setProperty('--graph-grid-color', 'black')
            element.style.color = 'black'
            element.style.fill = 'black'
            html2canvas(element, {
              backgroundColor: null,
              fill: 'black',
              ...downloadOptions,
            })
              .then(function (canvas) {
                // Get the data URL of the canvas
                var dataURL = canvas.toDataURL('image/png')
                // Create a link element to trigger the download
                var link = document.createElement('a')
                link.download = `${filename}.png`
                link.href = dataURL
                link.click()
              })
              .finally(() => {
                noPrint.each((i, e) => $(e).css('opacity', 1))
                noBg.forEach(([e, s]) => $(e).css('backgroundColor', s))
                noBorder.forEach(([e, s]) => $(e).css('border', s))
              })
            element.style.color = firstColor
            element.style.fill = firstFill
            element.style.setProperty('--graph-grid-color', 'unset')
            svgRef.current.classList.remove('download')
          }}
        >
          <span>
            <GrDocumentImage size={25} />
          </span>
        </div>
      </div>
      <div
        ref={svgRef}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        {children}
      </div>
    </div>
  )
}
