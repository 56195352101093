import React, { useMemo, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { BasicTooltip } from '@nivo/tooltip'
import $ from 'jquery'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import { round } from '../../utils/formating'
import { getTextWidth, getRotatedHeight, nivoProps } from '../../utils/ui'
import NextbrainSelect, { Option } from '../../model-content/NextbrainSelect'

export function ConfigPearsonMatrix({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.pearson-title').val(),
    graphFontSize: Number.parseInt($('.pearson-graph-font-size').val()) ?? 10,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="pearson-title"
            defaultValue={`${config.title ?? 'Correlation Matrix'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="pearson-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 10}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

function PMHeatmap({ model, editing, config }) {
  const [graphHeight, setGraphHeight] = React.useState(0)
  const [containerRef, selectRef] = [React.useRef(), React.useRef()]
  useEffect(() => {
    if (containerRef.current && selectRef.current) {
      const onResize = () => {
        if (!containerRef?.current?.offsetHeight) return
        setGraphHeight(
          containerRef.current.offsetHeight - selectRef.current.offsetHeight,
        )
      }
      window.addEventListener('resize', onResize)
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 500)
      return () => window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line
  }, [containerRef.current, selectRef.current])

  const [showSelect, setShowSelect] = useState(true)
  const [selectedColumns, setSelectedColumns] = useState(() =>
    Object.keys(model.pearson)
      .slice(0, 12)
      .map((v) => ({ label: v, value: v })),
  )

  const options = useMemo(() => {
    return Object.keys(model.pearson).map((v) => ({ label: v, value: v }))
  }, [model])

  const dataKey = JSON.stringify(selectedColumns)
  const [item, csvData] = useMemo(() => {
    const items = new Set(selectedColumns.map((v) => v.label))
    const keys = Object.entries(model.pearson)
      .filter(([k, v]) => items.has(k))
      .map(([k, v]) => ({
        label: k,
        v: Object.values(v).reduce((a, v) => a + Math.abs(v), 0),
      }))
      .sort((a, b) => b.v - a.v)
      .map((v) => v.label)

    const passed = new Set()

    const data = keys
      .map((k) => {
        const entries = model.pearson[k]
        const data = keys
          .map((k2) => ({
            x: k2,
            y: passed.has(k2) ? null : Math.abs(round(entries[k2])),
            r: passed.has(k2) ? null : round(entries[k2]),
          }))
          .reverse()
          .slice(0, 12)
        passed.add(k)
        return {
          id: k,
          data,
        }
      })
      .reverse()

    const maxLabelWidth =
      data.length > 0
        ? Math.max(
            ...data.map((k) =>
              getTextWidth(k.id, 'normal 11px sans-serif', false),
            ),
          )
        : 0
    const xTicksHeight = 10 + getRotatedHeight(maxLabelWidth)
    const csvData = data?.[0]?.data
      ? [
          ['column_1', 'column_1', 'correlation'],
          ...data.reduce(
            (acc, col1) => [
              ...acc,
              ...col1.data.reduce(
                (acc2, col2) =>
                  col2.r !== null && col2.x !== col1.id
                    ? [...acc2, [col1.id, col2.x, col2.r]]
                    : acc2,
                [],
              ),
            ],
            [],
          ),
        ]
      : []
    return [
      <ResponsiveHeatMap
        {...nivoProps}
        data={data}
        margin={{
          ...nivoProps.margin,
          bottom: nivoProps.margin.bottom + xTicksHeight,
          left: nivoProps.margin.left + maxLabelWidth,
        }}
        label={(props) => {
          return <>{props.data.y !== null ? props.data.r : null}</>
        }}
        tooltip={({ cell }) => {
          return cell.data.y !== null ? (
            <BasicTooltip
              id={`${cell.id}`}
              value={`${cell.data.r}`}
              color={cell.color}
              enableChip
            />
          ) : null
        }}
        labelTextColor="#333333"
        colors={{
          type: 'diverging',
          scheme: 'blues',
          minValue: 0,
          maxValue: 1.5,
          divergeAt: 0.5,
        }}
        emptyColor="#ffffff00"
        legends={[]}
      />,
      csvData,
    ]
    // eslint-disable-next-line
  }, [dataKey])

  return (
    <Col
      className="data-holder position-relative"
      data-csv={encodeURIComponent(JSON.stringify(csvData))}
      data-filename={`correlation_matrix__${model.id}`}
      style={{ minHeight: `calc(100% - 40px)`, maxHeight: `calc(100% - 40px)` }}
      xs={12}
      ref={containerRef}
    >
      <Row className="" ref={selectRef}>
        <Col xs={11}>
          {showSelect && (
            <NextbrainSelect
              className="w-100"
              isSearchable={true}
              placeholder={'Select columns to show'}
              isDisabled={false}
              isClearable={true}
              options={options}
              value={selectedColumns}
              onChange={(v) => setSelectedColumns(v)}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          )}
        </Col>
        <Col
          xs={'auto'}
          className="px-0 cursor-pointer"
          onClick={() => setShowSelect((s) => !s)}
        >
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement={'right'}
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>
                <span className="">
                  {`${showSelect ? 'Hide' : 'Show'} column selection`}
                </span>
              </Tooltip>
            )}
          >
            <span className="w-auto download-graph-button">
              {showSelect ? (
                <AiFillEye size={30} />
              ) : (
                <AiFillEyeInvisible size={30} />
              )}
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row style={{ minHeight: `calc(100% - 100px)` }}>
        <Col
          style={
            editing
              ? { maxHeight: `300px`, filter: 'blur(2px)' }
              : { maxHeight: `${graphHeight}px` }
          }
          xs={12}
        >
          {item}
        </Col>
      </Row>
    </Col>
  )
}

export function WidgetPearsonMatrix({
  model,
  config,
  id,
  requestedData = {},
  editing = false,
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  if (!model.pearson)
    return <>No Pearson matrix available, try training the model again.</>

  const graph = () => {
    return <PMHeatmap editing={editing} model={model} config={config} />
  }

  return (
    <Row
      {...props}
      id={id}
      className={`w-100 h-100 flex-column flex-nowrap ${props.className ?? ''}`}
    >
      {config.title && (
        <Col
          className=" d-inline-block text-truncate widget-title"
          style={{ height: '40px' }}
          xs={12}
        >
          {config.title}
        </Col>
      )}
      {graph()}
    </Row>
  )
}
