import React, { useState, useCallback, useRef } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { ImCross, ImPlus } from 'react-icons/im'

import './Utils.css'

// Theme for nivo charts
export const nbTheme = {
  fontSize: 13,
  textColor: 'inherit',
  axis: {
    ticks: {
      text: {
        fontSize: 11,
      },
    },
    legend: {
      text: {
        fontSize: 13,
      },
    },
    domain: {
      line: {
        stroke: 'var(--graph-grid-color)',
        strokeWidth: 1,
      },
    },
  },
  legends: {
    text: {
      fontSize: 11,
    },
  },
  grid: {
    line: {
      stroke: 'var(--graph-grid-color)',
      opacity: 0.4,
    },
  },
}

export const nivoProps = {
  margin: { top: 10, right: 10, bottom: 10, left: 10 },
  xFormat: ' >-.2f',
  yFormat: ' >-.2f',
  enableGridX: true,
  enableGridY: true,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    legendPosition: 'middle',
    tickRotation: -22,
  },
  axisLeft: {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
  },
  useMesh: false,
  theme: nbTheme,
}

export const nivoLineProps = {
  ...nivoProps,
  colors: (d) => d.color,
  xScale: { type: 'linear', min: 'auto', max: 'auto' },
  yScale: {
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: false,
    reverse: false,
  },
  curve: 'monotoneX',
  layers: [
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
  ],
}

export function BouncyLoader(props) {
  return (
    <div {...props} className={`${props.className ?? ''} bouncing-loader`}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export function SwitchButtons({ options, selected, setSelected, ...props }) {
  const [duration, setDuration] = useState(0.2)
  const handleChange = useCallback(
    (index) => {
      setSelected(index)
      setDuration(0.2 * Math.pow(Math.abs(selected - index), 0.5))
    },
    [selected, setSelected],
  )

  const boxStyle = {
    width: 100 / options.length + '%',
  }
  const selectedStyle = {
    transition: 'left ' + duration + 's',
    left: (selected / options.length) * 100 + '%',
  }

  return (
    <div className="switchbuttons">
      <div
        className="selection"
        style={{
          ...selectedStyle,
          ...boxStyle,
        }}
      >
        <div></div>
      </div>
      {options.map((label, index) => (
        <div
          key={index}
          className="box"
          onClick={() => handleChange(index)}
          style={boxStyle}
        >
          <div>{label}</div>
        </div>
      ))}
    </div>
  )
}

export function getTextWidth(text, font, useCanvas = true) {
  if (useCanvas) {
    var a = document.createElement('canvas')
    var b = a.getContext('2d')
    //b.font = fontSize + 'px ' + fontFace
    b.font = font
    return b.measureText(text).width
  } else {
    const el = document.createElement('span')
    el.textContent = text
    el.style.font = font
    el.style.position = 'absolute'
    el.style.left = -1000
    el.style.top = -1000
    document.body.appendChild(el)
    //var s = getComputedStyle(el)
    const width = el.clientWidth
    document.body.removeChild(el)
    return width
  }
}

export function getRotatedHeight(
  width,
  angle = nivoProps.axisBottom.tickRotation,
) {
  return Math.abs(width * Math.sin((angle * Math.PI) / 180))
}

export function abbrNum(num) {
  // code from:
  // https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn#answer-32638472
  try {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return '0'
    } // terminate early
    var isNegative = num < 0
    num = Math.abs(num)
    let k = 0

    let c, c1, c2
    if (num < 999.5) {
      c =
        (c1 = num.toString()).length < (c2 = num.toPrecision(4)).length
          ? c1
          : c2
    } else if (num < 9999.5) {
      c = num.toFixed(0)
    } else {
      var power = num.toPrecision(2).split('e')[1].slice(1) // get power
      k = Math.min(Math.floor(power / 3), 14)
      c = (num / Math.pow(10, k * 3)).toPrecision(3)
    }
    var d = c < 0 ? c : Math.abs(c) // enforce -0 is 0

    return (isNegative ? '-' : '') + d + ['', 'K', 'M', 'B', 'T'][k]
  } catch (e) {
    return num
  }
}

export function AddRowButton({ onClick, className = '', tooltip = '' }) {
  const button = (
    <Button
      className={`nextbrain-outline d-flex p-2 ${className ?? ''}`}
      onClick={onClick}
    >
      <ImPlus />
    </Button>
  )
  if (tooltip)
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="auto"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip
            {...props}
            className={`popover-help-tooltip ${props.className ?? ''}`}
          >
            <div>{tooltip}</div>
          </Tooltip>
        )}
      >
        {button}
      </OverlayTrigger>
    )

  return button
}

export function DeleteRowButton({
  onClick,
  className = '',
  tooltip = '',
  ...props
}) {
  const button = (
    <Button
      className={`nextbrain-outline d-flex p-2 ${className ?? ''}`}
      onClick={onClick}
    >
      <ImCross />
    </Button>
  )
  if (tooltip)
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="auto"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip
            {...props}
            className={`popover-help-tooltip ${props.className ?? ''}`}
          >
            <div>{tooltip}</div>
          </Tooltip>
        )}
      >
        {button}
      </OverlayTrigger>
    )

  return button
}

/*
Find the y value of X if it exists, try to interpolate if there's no exact match
{x:0,y:1},{x:2,y:3},{x:4,y:5}
x=3? -> {x:3,y:4} 
*/

export function interpolateXYLine(
  xscale,
  yscale,
  targetX,
  line,
  extrapolate = false,
) {
  const overIndex = line.findIndex(({ x, y }) => x >= targetX)
  if (overIndex === -1) {
    if (extrapolate && line.length > 1) {
      const p1 = line[0]
      const p2 = line[line.length - 1]
      const slope = (p2.y - p1.y) / (p2.x - p1.x)
      return { x: targetX, y: p1.y + slope * (targetX - p1.x) }
    }
    return null
  }
  if (overIndex !== 0) {
    const point = line[overIndex]
    if (point.x === targetX) return point
    const slope =
      (point.y - line[overIndex - 1].y) / (point.x - line[overIndex - 1].x)
    const y = line[overIndex - 1].y + slope * (targetX - line[overIndex - 1].x)
    return { x: targetX, y }
  }
  return line[overIndex]
}

export function useLineLegendTooltip({
  trim = Infinity,
  top = false,
  paddingX = 20,
  fontSize = 12,
}) {
  const ref = useRef(({ data, innerWidth, innerHeight }) => (
    <g
      transform={`translate(${innerWidth + paddingX}, ${
        top ? 0 : innerHeight - data.length * 20
      })`}
    >
      {data.map(({ id, color }, i) => (
        <g title={id} key={i} transform={`translate(0,${i * 20})`}>
          <circle cx={0} cy={0} r={6} fill={color} />
          <text fontSize={12} x={10} y={3}>
            {id?.length > trim + 2 ? id.slice(0, trim) + '...' : id}
          </text>
        </g>
      ))}
    </g>
  ))
  return ref.current
}

export function useBarLegend({
  trim = Infinity,
  top = false,
  paddingX = 20,
  fontSize = 12,
}) {
  const ref = useRef(({ data, innerWidth, innerHeight, margin }) => {
    return (
      <foreignObject
        x={innerWidth + paddingX}
        y={0}
        width={margin.right - paddingX}
        height={innerHeight}
      >
        <Row
          className="ms-2 container-line-legend-interactive"
          style={{
            minWidth: `${margin.right - paddingX - 10}px`,
            maxWidth: `${margin.right - paddingX - 10}px`,
            maxHeight: `${innerHeight - margin.bottom}px`,
            overflowY: 'auto',
          }}
        >
          {data.map((s, i) => (
            <Col className={i ? '' : 'mt-2'} key={s.id} xs={12}>
              <Row className={`interactive-legend-block}`}>
                <Col
                  className="px-0 d-flex align-items-center"
                  style={{
                    position: 'relative',
                    maxWidth: '100%',
                    minWidth: '100%',
                  }}
                  xs={10}
                >
                  <span
                    title={s.id}
                    className="w-100 detail-legend-interactive d-inline-block text-truncate"
                    style={{ fontSize: '11px' }}
                  >
                    <div
                      style={{
                        backgroundColor: `${s.color}`,
                        minWidth: '10px',
                        minHeight: '10px',
                        maxWidth: '10px',
                        maxHeight: '10px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '2px',
                      }}
                      className="detail-legend-interative-shape"
                    ></div>
                    {s.id}
                  </span>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </foreignObject>
    )
  })
  return ref.current
}

export function usePieLegend({ trim = Infinity, height, paddingX = 20 }) {
  const ref = useRef(({ dataWithArc, centerX, radius }) => {
    const space = dataWithArc.length * 20
    let top = 0
    if (space > height)
      dataWithArc = dataWithArc.slice(0, Math.floor(height / 20))
    else top = (height - space) / 2
    return (
      <g transform={`translate(${centerX + radius + paddingX}, ${top})`}>
        {dataWithArc.map(({ id, color }, i) => (
          <g title={id} key={i} transform={`translate(0,${i * 20})`}>
            <circle cx={0} cy={0} r={6} fill={color} />
            <text fontSize={12} x={10} y={3}>
              {id?.length > trim + 2 ? id.slice(0, trim) + '...' : id}
            </text>
          </g>
        ))}
      </g>
    )
  })

  return ref.current
}
