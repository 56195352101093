import { config } from '../Constants'

function fetchManager(endpoint, options, username, password, ...params) {
  options = options || {}
  options.headers = options.headers || {}
  options.headers['Authorization'] = 'Basic ' + btoa(username + ':' + password)
  return fetch(config.ADMIN_MANAGER_API_URL + endpoint, options, ...params)
}

export function loginToManager(username, password) {
  return fetchManager('/login', null, username, password)
}

export function getConfigurations(username, password) {
  return fetchManager('/configurations', null, username, password)
}
