import { request } from './base'

export async function getUser(token, signout) {
  return request({
    endpoint: '/user/',
    token: token,
    signout: signout,
    rawResponse: true,
  })
}

export async function updateUser(user, token, signout) {
  return request({
    endpoint: '/user/update_user',
    method: 'POST',
    token: token,
    signout: signout,
    body: user,
    contentType: 'json',
  })
}

export async function deleteUser(token, signout) {
  return request({
    endpoint: '/user/delete_user',
    method: 'DELETE',
    token: token,
    signout: signout,
    contentType: 'json',
  })
}

export async function revokeToken(token, signout) {
  return request({
    endpoint: '/user/revoke_token',
    method: 'POST',
    token: token,
    signout: signout,
  })
}

export async function resetIndustries({ override, token, signout }) {
  return request({
    endpoint: `/user/reset_industries?override=${override}`,
    method: 'POST',
    token: token,
    signout: signout,
    contentType: 'json',
  })
}
export async function getAllUsers(token) {
  return request({
    endpoint: `/admin/users`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getAllUsersLength(token) {
  return request({
    endpoint: `/admin/usersLength`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function searchUserCompanies({ searchTerm, token }) {
  return request({
    endpoint: `/admin/searchUserCompanies?searchTerm=${searchTerm}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getAllUsersPaginated(
  token,
  pagSize,
  pageNum,
  column,
  order,
  filter,
  filters = {},
) {
  filters = Object.entries(filters)
    .map(
      ([key, value]) =>
        `${key}=${encodeURIComponent(
          typeof value !== 'boolean' ? JSON.stringify(value) : value,
        )}`,
    )
    .join('&')
  const extraParams = filter ? `&filter=${encodeURIComponent(filter)}` : ``
  return request({
    endpoint: `/admin/usersPaginated?pagSize=${pagSize}&pageNum=${pageNum}&column=${encodeURIComponent(
      column,
    )}&order=${order}${extraParams}${filters ? `&${filters}` : ''}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
export async function getAllUsersPaginatedByDate(
  token,
  pagSize,
  pageNum,
  start,
  end,
  column,
  order,
  filter,
) {
  const extraParams = filter ? `&filter=${encodeURIComponent(filter)}` : ``
  return request({
    endpoint: `/admin/usersPaginatedByDate?pagSize=${pagSize}&pageNum=${pageNum}&start=${encodeURIComponent(
      start,
    )}&end=${encodeURIComponent(end)}&column=${encodeURIComponent(
      column,
    )}&order=${order}${extraParams}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
export async function getAllUsersCreatedAMonth(token, month, year, include) {
  return request({
    endpoint: `/admin/usersCreatedByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getUsersCreatedAMonthValues(token, day, month, year) {
  return request({
    endpoint: `/admin/usersCreatedByMonthValues?day=${day}&month=${month}&year=${year}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getCountedPredictionsByMonth(
  token,
  month,
  year,
  include,
) {
  return request({
    endpoint: `/admin/predictionsByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
export async function getCountedTrainsByMonth(token, month, year, include) {
  return request({
    endpoint: `/admin/trainsByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
export async function getCountedErrorsByMonth(token, month, year, include) {
  return request({
    endpoint: `/admin/errorsByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getCountedChatsByMonth(token, month, year, include) {
  return request({
    endpoint: `/admin/chatsByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getCountedErrorsByMonthValues(token, day, month, year) {
  return request({
    endpoint: `/admin/errorsByMonthValues?day=${day}&month=${month}&year=${year}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getActiveUsersByMonth(token, month, year, include) {
  return request({
    endpoint: `/admin/activeUsersByMonth?month=${month}&year=${year}&include=${include}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getActiveUsersByMonthValues(token, day, month, year) {
  return request({
    endpoint: `/admin/activeUsersByMonthValues?day=${day}&month=${month}&year=${year}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getAllActionsByID(token, start, end, id) {
  start = new Date(start)
  end = new Date(end)
  start = start.toLocaleString('en-US', { timeZone: 'UTC' })
  end = end.toLocaleString('en-US', { timeZone: 'UTC' })
  return request({
    endpoint: `/admin/actionsByDateAndID?start=${encodeURIComponent(
      start,
    )}&end=${encodeURIComponent(end)}&userId=${id}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}
export async function getAllActionsCountByID(token, start, end, id) {
  start = new Date(start)
  end = new Date(end)
  start = start.toLocaleString('en-US', { timeZone: 'UTC' })
  end = end.toLocaleString('en-US', { timeZone: 'UTC' })
  return request({
    endpoint: `/admin/actionsCountByDateAndID?start=${encodeURIComponent(
      start,
    )}&end=${encodeURIComponent(end)}&userId=${id}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getCompanyMonthlyActivity(token, company) {
  return request({
    endpoint: `/user/activityMonth?company=${encodeURIComponent(company)}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getCompanyDailyActivity(token, company, month, year) {
  return request({
    endpoint: `/user/activity?company=${encodeURIComponent(
      company,
    )}&month=${encodeURIComponent(month)}&year=${encodeURIComponent(year)}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getUserStats(token, month, year) {
  return request({
    endpoint: `/user/monthlyStats?month=${encodeURIComponent(
      month,
    )}&year=${encodeURIComponent(year)}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function getProductsInfo() {
  return request({
    endpoint: `/payment/products`,
    method: 'GET',
  })
}

export async function getActiveSubscriptions(token) {
  return request({
    endpoint: `/payment/active-subscriptions`,
    method: 'GET',
    token,
  })
}

export async function startTrial({ token, signout, companyId }) {
  return request({
    endpoint: `/user/start-trial?company_id=${companyId}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function getCompanyUsers({ companyId, token, signout }) {
  return request({
    endpoint: `/user/company-users?company_id=${companyId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function inviteUser({ invite, companyId, role, token, signout }) {
  return request({
    endpoint: `/user/invite-to-company?invite=${encodeURIComponent(
      invite,
    )}&company_id=${companyId}&role=${role}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function editRole({ userId, companyId, role, token, signout }) {
  return request({
    endpoint: `/user/edit-role?user_id=${userId}&company_id=${companyId}&role=${role}`,
    method: 'POST',
    token,
    signout,
  })
}

export async function removeUser({ userId, companyId, token, signout }) {
  return request({
    endpoint: `/user/remove-from-company?user_id=${userId}&company_id=${companyId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export const LEVEL = Object.freeze({
  INFO: 'INFO',
  DEBUG: 'DEBUG',
  ERROR: 'ERROR',
})

let previous = new Set()
export async function userEvent({
  level = LEVEL.INFO,
  message,
  metadata = null,
  token,
  signout,
}) {
  const body = {
    level,
    message,
    metadata,
  }
  const bodyString = JSON.stringify(body)
  if (previous.has(bodyString)) return
  previous.add(bodyString)

  return request({
    endpoint: `/user/event`,
    method: 'POST',
    body: {
      level,
      message,
      metadata,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function resetVerification({ token, signout = () => {} }) {
  return request({
    endpoint: `/user/resend-verification`,
    token,
    signout,
  })
}

export async function getCountedReportsByMonth(token, month, year) {
  return request({
    endpoint: `/error/reportsByMonth?month=${month}&year=${year}`,
    method: 'GET',
    token,
    isAdmin: true,
  })
}

export async function setFlag(flag, value, token, signout) {
  return request({
    endpoint: `/app/set_flag/${flag}/${value}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getFlag(flag) {
  return request({
    endpoint: `/app/get_flag/${flag}`,
    method: 'GET',
  })
}

export function createSubscription({ subscription, token, signout }) {
  return request({
    endpoint: `/internal/subscription`,
    method: 'POST',
    body: subscription,
    contentType: 'json',
    token,
    signout,
  })
}

export function updateSubscription({
  subscriptionId,
  subscription,
  token,
  signout,
}) {
  return request({
    endpoint: `/internal/subscription/${subscriptionId}`,
    method: 'POST',
    body: subscription,
    contentType: 'json',
    token,
    signout,
  })
}

export function deleteSubscription({ subscriptionId, token, signout }) {
  return request({
    endpoint: `/internal/subscription/${subscriptionId}`,
    method: 'DELETE',
    contentType: 'json',
    token,
    signout,
  })
}

export function renameCompany({ newName, token, signout }) {
  return request({
    endpoint: `/user/rename-company?new_name=${encodeURIComponent(newName)}`,
    method: 'POST',
    token,
    signout,
  })
}

export function confirmUserToCompany({ userId, companyId, token, signout }) {
  return request({
    endpoint: `/user/accept-invitation/${companyId}?user_id=${userId}&checksum=0`,
    method: 'POST',
    rawResponse: true,
    token,
    signout,
  })
}

export function moveUserToCompany({
  userId,
  companyId,
  role = 'user',
  token,
  signout,
}) {
  return request({
    endpoint: `/user/admin-move-user-company/${companyId}?user_id=${userId}&role=${role}`,
    method: 'POST',
    token,
    signout,
  })
}

export function pruneAdmin({ config, token, signout }) {
  return request({
    endpoint: `/internal/prune-users`,
    method: 'POST',
    body: config,
    contentType: 'json',
    token,
    signout,
  })
}

export function confirmPruneAdmin({ userIds, token, signout }) {
  return request({
    endpoint: `/internal/prune-users`,
    method: 'DELETE',
    body: {
      user_ids: userIds,
    },
    contentType: 'json',
    token,
    signout,
  })
}
