import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ViewDataTable from './view-data'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { editPrivacy, getModelById, setColumnType } from '../../services/model'
import { NotificationManager } from 'react-notifications'
import { useNav } from '../../providers/NavProvider'

export default function ViewDataShareView({
  getHead = () => {},
  setTitle = () => {},
  updateModel = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [model, setModel] = useState(null)
  const [isPrivatePredict, setIsPrivatePredict] = useState(true)
  const { signout, token } = useAuth()
  const { setShowNav } = useNav()
  useEffect(() => {
    setShowNav(false)
    return () => setShowNav(true)
    // eslint-disable-next-line
  }, [])

  const generateColumnToType = (model) => {
    const finalColumnStatus = model?.dataset?.final_column_status
    if (!finalColumnStatus) return {}
    return finalColumnStatus
  }

  useEffect(() => {
    if (token) {
      getModelById(id, token, signout).then((d) => {
        if (d && d.id) {
          setModel(d)
        }
      })
    }
    // eslint-disable-next-line
  }, [token])

  return (
    <Row>
      <Col xs={12}>
        <Row className="px-4 mt-3 justify-content-between">
          <Col className="h3" xs={'auto'}>
            {t('Viewing dataset of model')} #{id}
          </Col>
          <Col xs={'auto'}>
            <Form className={`form-inline ${token ? '' : 'd-none'}`}>
              <Form.Group>
                <Form.Check
                  type="switch"
                  label={t('Share web app dataset')}
                  className="form-switch-share"
                  defaultChecked={!isPrivatePredict}
                  onChange={async (e) => {
                    const newIsPrivatePredict = !e.target.checked
                    setIsPrivatePredict(newIsPrivatePredict)
                    const options = { private_report: newIsPrivatePredict }
                    // In order to predict a model the report should be also publicly accessible
                    // We can change it in the future, it is just because it call the same /model/get_model/:model-id
                    if (!newIsPrivatePredict) {
                      if (navigator?.clipboard?.writeText) {
                        options.private_report = false
                        navigator.clipboard.writeText(window.location.href)
                      } else
                        NotificationManager.error('Clipboard not supported')
                    }
                    NotificationManager.info(
                      newIsPrivatePredict
                        ? t('Dataset is now private')
                        : t('Dataset is now public'),
                    )
                    await editPrivacy(id, options, token, signout)
                  }}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col className="mt-5" xs={12}>
        {model ? (
          <ViewDataTable
            maxHeight={'70vh'}
            className="mt-2 px-4"
            model={model}
            updateModel={updateModel}
            getHead={getHead}
            rowsPerPage={100}
            dataId={model.id}
            setTitle={setTitle}
            onChange={model}
            columnToType={generateColumnToType(model)}
            onColumnTypeChanged={(column, newType, format) => {
              setColumnType(
                model.id,
                column,
                newType,
                format,
                token,
                signout,
              ).then((response) => {
                model.dataset.final_column_status[column] = newType
                model.dirty = true
                setModel({ ...model })
              })
            }}
            showStatistics={false}
          />
        ) : (
          <ViewDataTable
            maxHeight={'70vh'}
            rowsPerPage={100}
            className="mt-5 px-4"
            getHead={getHead}
            errorMessage={`This dataset is not publicly available`}
          />
        )}
      </Col>
    </Row>
  )
}
