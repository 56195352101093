import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button, Form, Image } from 'react-bootstrap'
import { useQuery } from 'react-query'
import $ from 'jquery'
import { useAuth } from '../../../providers/AuthProvider'
import { getGoalVisualizations } from '../../../services/lida'
import Loading from '../../loading/LoadingSmall'
import Visualization from '../../lida/Visualization'

export function ConfigLIDAGraph({ model, onFinish, config = {}, ...props }) {
  const { token, signout } = useAuth()
  const [selectedGoal, setSelectedGoal] = useState(config?.goal)
  const isUpdate = config.title

  const { data: visualizationStatus, refetch } = useQuery(
    ['visualize', model?.id],
    async () => {
      const data = await getGoalVisualizations({
        modelId: model?.id,
        idsToIgnore: visualizationStatus?.data
          ?.filter((a) => a?.visualizations?.[0]?.explanations)
          ?.map((a) => a?.goal?.id),
        token,
        signout,
      })
      if (data?.status === 404) throw new Error('Generating Lida data...')
      else {
        const newIds = new Set(data?.data?.map((a) => a?.goal?.id))
        data?.data?.push(
          ...(visualizationStatus?.data?.filter(
            (d) => !newIds.has(d?.goal?.id),
          ) ?? []),
        )
        data?.data?.sort((a, b) => a?.goal?.index - b?.goal?.index)
        return data
      }
    },
    { staleTime: Infinity, retry: true, retryDelay: 3000 },
  )
  useEffect(() => {
    if (visualizationStatus?.is_generating) {
      const interval = setInterval(refetch, 2000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [visualizationStatus])

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.LIDA-title').val(),
    goal: selectedGoal,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="LIDA-title"
            defaultValue={`${config.title ?? 'Custom Graph'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        {(visualizationStatus?.data ?? []).map((g, i) => (
          <Col
            className={`
                my-2 goal-lida-widget px-0
                ${
                  selectedGoal
                    ? selectedGoal === g?.goal?.id
                      ? 'active-goal-lida-widget'
                      : 'inactive-goal-lida-widget'
                    : ''
                }
                `}
            key={g?.goal?.id}
            xs={6}
          >
            <Visualization
              visualization={g}
              className="p-2 mx-2"
              showRationale={false}
              showTitle={false}
            >
              <Col xs={12}>
                <Button
                  className="w-100 py-2 mt-2"
                  onClick={() =>
                    setSelectedGoal((d) => (d ? null : g?.goal?.id))
                  }
                >
                  {selectedGoal ? 'Selected other' : 'Use graph'}
                </Button>
              </Col>
            </Visualization>
          </Col>
        ))}
        {(visualizationStatus?.is_generating ||
          !Array.isArray(visualizationStatus?.data)) && (
          <Col className="my-2 px-0" xs={6}>
            <Loading message={'New graphs are being generated'} alter={true} />
          </Col>
        )}
      </Row>
      <Row className="my-2">
        <Col xs={'auto'}>
          <Button
            onClick={() => onFinish(getConfig())}
            disabled={!selectedGoal}
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetLIDAGraph({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  const { token, signout } = useAuth()
  const [dimensions, setDimensions] = useState({})
  const imgRef = useRef()
  const { data: visualizationStatus } = useQuery(
    ['visualize', model?.id],
    async () => {
      const data = await getGoalVisualizations({
        modelId: model?.id,
        idsToIgnore: visualizationStatus?.data
          ?.filter((a) => a?.visualizations?.[0]?.explanations)
          ?.map((a) => a?.goal?.id),
        token,
        signout,
      })
      return data
    },
    { staleTime: Infinity, retry: true, retryDelay: 3000 },
  )

  const goal = visualizationStatus?.data?.find(
    (d) => d?.goal?.id === config?.goal,
  )

  if (!model || !config || !goal) return <>Loading...</>

  let wFlip = '95%',
    hflip = '100%'
  if (dimensions.w && dimensions.h) {
    wFlip = `${dimensions.w}px`
    hflip = `${dimensions.h}px`
  }

  return (
    <>
      <Col
        className="position-relative px-0"
        xs={12}
        style={{ height: '100%' }}
        onMouseEnter={() => {
          window.imageItem = imgRef.current
          setDimensions({
            w: imgRef.current?.width,
            h: imgRef.current?.height,
          })
        }}
      >
        <Row
          className="position-absolute w-100 text-center dflex-center mx-0 px-0"
          style={{ height: '100%', top: 0, left: 0 }}
        >
          <Col
            className="d-inline-block text-truncate mx-0 px-0"
            xs={12}
            style={{
              height: '30px',
              fontWeight: 'bold',
              fontSize: '15px',
              maxWidth: 'calc(100% - 100px)',
            }}
          >
            {config.title}
          </Col>
          <Col
            className="position-relative flipcard-effect  dflex-center py-2 "
            xs={'auto'}
            style={{ height: 'calc(100% - 30px)' }}
          >
            <Image
              ref={imgRef}
              src={`data:image/png;base64,${goal?.visualizations?.[0]?.raster}`}
              alt={'graph'}
              height="100%"
              style={{
                maxWidth: '100%',
                borderRadius: '10px',
                maxHeight: '100%',
              }}
              className="my-2"
            />
            <Row
              className="position-absolute  text-center dflex-center my-2"
              style={{
                height: hflip,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: wFlip,
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '10px',
                backgroundColor: 'var(--nextbrain-background)',
                overflow: 'hidden',
              }}
            >
              <Col
                className="mx-2 mt-3"
                xs={12}
                style={{ fontWeight: 'bold', fontSize: '17px' }}
              >
                {goal?.goal?.question ?? 'title'}
              </Col>
              <Col className="position-relative text-center py-2 mx-2" xs={12}>
                {goal?.goal?.rationale ?? 'Rationale'}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  )
}
