import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ImCross } from 'react-icons/im'

const reasonTranslator = {
  'Empty columns': 'All values are empty',
  id: 'Identifier column',
  const: 'Constant column',
  null: 'Column full of empty values',
  'Delete by assistant': 'Deleted by assistant',
  'Deleted manually': 'Deleted manually',
}

export default function ViewDataDeletedColumn({ column, width, reason }) {
  const { t } = useTranslation()

  return (
    <Row
      className="deleted-column-viewdata flex-column justify-content-between p-2 h-100"
      style={{ opacity: 0.6 }}
    >
      <Col xs={12}>
        <Row>
          <Col title={column} xs={12}>
            <strong className="d-inline-block text-truncate">{column}</strong>
          </Col>
          <Col className="dflex-center p-2" xs={12}>
            <span className="d-inline-block text-truncate smallp">
              {t('Deleted column')} <ImCross />
            </span>
          </Col>
        </Row>
      </Col>
      <Col className="dflex-center" xs={12}>
        <span>{t(reasonTranslator[reason] ?? reason)}</span>
      </Col>
    </Row>
  )
}
