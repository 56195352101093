import { Row, Col, Container, Image } from 'react-bootstrap'
import { GrServerCluster } from 'react-icons/gr'
import { FaCog } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

export default function UnderMaintenance() {
  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center mt-5" xs={12}>
          <Image
            style={{ objectFit: 'contain' }}
            height={200}
            src={'/Logo/3.svg'}
            alt={'Nextbrain'}
          />
        </Col>
        <Col className="mt-4" xs={12}>
          <Row>
            <Col className="position-relative" xs={6}>
              <GrServerCluster
                color={'white'}
                size={100}
                className="me-2"
                style={{ filter: 'invert(1)', float: 'right' }}
              />
              <FaCog
                size={45}
                color={'var(--nextbrain-main-color)'}
                style={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '0px',
                  animation: 'rotate 20s linear infinite',
                }}
              />
            </Col>
            <Col
              className="position-relative h5 text-center d-flex align-items-center "
              xs={6}
            >
              {t(`Under maintenance.`)}
              <br />
              {t(`We'll be back soon.`)}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
