import { useState, useRef, useEffect } from 'react'

export default function useCountdown() {
  const [_time, setTime] = useState()
  const timeout = useRef(null)

  const start = async ({ duration, step = 1000, onFinish }) => {
    setTime(duration)
    clearTimeout(timeout?.current)
    const update = () => {
      setTime((t) => {
        const nt = t - step
        if (nt > 0) {
          timeout.current = setTimeout(update, step)
          return nt
        } else {
          if (typeof onFinish === 'function') onFinish()
          return 0
        }
      })
    }
    timeout.current = setTimeout(update, step)
  }

  const stop = () => {
    clearTimeout(timeout.current)
    setTime(0)
  }

  useEffect(() => () => clearTimeout(timeout.current), [])

  return [_time, start, stop]
}
