export const DESCRIPTION_PROMPT = `I will share sections of my dataset, both a pandas DataFrame displayed with \`df.head()\` and its statistical summary using \`df.describe()\`. Your objective is to identify the dataset's context and formulate the most engaging and commonly recognized problem that can be explored with this data. Aim for precision and succinctness in your responses, avoiding any lead-in phrases such as "This dataset appears to be related to..." or "The dataset aims to solve...". Provide straightforward, impactful statements.

Head:
{df_head}

Describe:
{df_describe}

Organize your reply in this format:

{
  "context": "Direct context description",
  "problem": "Explicit problem statement, e.g., 'Determine customer churn'"
}

Your response should immediately convey the essence of the dataset's context and highlight a prominent and widely acknowledged problem, focusing on brevity and relevance.`

export const COLUMN_DESCRIPTION_PROMPT = `I want you to act as a $PROFILE_REPLACE and generate a short description of the columns in the following dataframe
which we're using to solve the following problem: $PROBLEM_REPLACE
the context of the dataset is: $CONTEXT_REPLACE

Execution of {df_head}:
{df_head}

Execution of {df_describe}:
{df_describe}

If you don't know the meaning of a column, just put an empty string do not invent things.

Your response must be only a JSON with the following format:
{
  "column_name_1": "description of the column 1",
  ...
}`

export const DATA_CLEANING_PROMPT = `Act as a $PROFILE_REPLACE, your objective is to perform data cleaning operations on the provided DataFrame. To do so follow the guidelines below:

- Do not generate considerations, checks or information retrieval, just direct actions.
- Each action should perform a transformation on the dataset to improve it.
- Do not use wildcards such as "Rename columns if necessary".
- If you detect personal or sensitive information (for example, names) suggest to remove the column.
- Each action should be a concrete change to a column/columns concisely bounded, the dataset will be used to solve the following problem: 
$PROBLEM_REPLACE.

The context of the dataset is: 
$CONTEXT_REPLACE.

The DataFrame's structure and characteristics are as follows:
When executing df.head().to_markdown():
{df_head}

Upon executing df.describe():
{df_describe}

Here is a description of the columns in the DataFrame: 
$COLUMNS_DESCRIPTION_REPLACE

$COLUMNS_TO_DELETE_REPLACE

Your response should adhere to the JSON format provided below:

[
  "A concise description of the data cleaning/transformation operation and its rationale, for example 'Remove id columns as they are not relevant to the analysis.'",
  ...
]`
