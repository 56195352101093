import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import CustomSankey from '../../model-content/CustomSankey'
import $ from 'jquery'
import { calculateMargin } from './common'

export function ConfigImportantFeaturesAffectTarget({
  model,
  onFinish,
  config = {},
  ...props
}) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.sankey-title').val(),
    graphFontSize: Number.parseInt($('.sankey-graph-font-size').val()) ?? 12,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="sankey-title"
            defaultValue={`${config.title ?? 'Combined feature influence'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Graph Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="sankey-graph-font-size"
            defaultValue={`${config.graphFontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetImportantFeaturesAffectTarget({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  if (!model?.details?.sankey_feature_importance)
    return <>Sankey data is not available</>

  const links = model.details.sankey_feature_importance.links.reduce(
    (dicts, l) => {
      dicts[0].push(l.source)
      dicts[1].push(l.target)
      return dicts
    },
    [[], []],
  )

  const fromNodes = new Set(links[0])
  const toNodes = new Set(links[1])

  const beginning = links[0]
    .filter((n) => !toNodes.has(n))
    .map((e) => `${e}`)
    .slice(0, 1)
  const end = links[1].filter((n) => !fromNodes.has(n)).map((e) => `${e}`)

  const graph = () => {
    switch (config.matrixType) {
      default:
        return (
          <CustomSankey
            height={'100%'}
            className="h-100 sankey-widget-container"
            data={model.details.sankey_feature_importance}
            target={model.target}
            sankeyProps={{
              margin: {
                top: 20,
                left: calculateMargin(beginning, config.graphFontSize) * 0.8,
                right: calculateMargin(end, config.graphFontSize) * 0.8,
                bottom: 20,
              },
              labelTextColor: 'var(--nextbrain-widget-axis-legend)',
              theme: {
                fontSize: config.graphFontSize,
                textColor: 'var(--nextbrain-widget-graph-legend)',
                axis: {
                  ticks: {
                    text: {
                      fill: 'var(--nextbrain-widget-axis-legend)',
                    },
                  },
                  legend: {
                    text: {
                      fill: 'var(--nextbrain-widget-axis-legend)',
                    },
                  },
                },
              },
            }}
          />
        )
    }
  }

  return (
    <Row className="w-100 h-100" id={id} {...props}>
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '40px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col xs={12} style={{ height: 'calc(100% - 50px)' }}>
        {graph()}
      </Col>
    </Row>
  )
}
