import { useMemo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { checkFilter } from '../../../../services/csv'
import { useAuth } from '../../../../providers/AuthProvider'
import { awaitTaskCall } from '../../../../services/base'
import Loading from '../../../loading/LoadingSmall'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import TableError from './TableError'
import ColumnPaginatedGridTable from '../../../grid-table/ColumnPaginatedGridTable'

export default function FilterTable({ input, filters, onDataLoad }) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const { isLoading, data } = useQuery(
    ['filterCheck', input, filters],
    async () => {
      if (filters.length) {
        setError(null)
        return await awaitTaskCall(checkFilter, 1000, 20000, {
          input: {
            columns: input.columns,
            data: input.sample,
          },
          filter: filters.map(({ column, operator, value }) => ({
            column: column.value,
            operator: operator.value,
            value:
              !value || typeof value === 'string'
                ? value
                : value.map((v) => v.value),
          })),
          token,
          signout,
        }).catch((e) => {
          console.error('Failed to retrieve filter data for table')
          setError(e.error)
        })
      }
      return null
    },
    { staleTime: Infinity, retry: false },
  )

  const defaultData = useMemo(() => {
    if (!input)
      return {
        columns: [],
        data: [],
      }
    return {
      columns: [input.columns],
      data: input.sample,
    }
  }, [input])

  const tableData = useMemo(() => {
    if (!data) return null
    const res = {
      columns: [data.columns],
      data: data.data,
    }
    return res
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (data)
      onDataLoad({
        columns: data.columns,
        sample: data.data,
      })
    else onDataLoad(null)
    // eslint-disable-next-line
  }, [data])

  const showData = tableData || defaultData

  return error ? (
    <TableError error="Failed to filter inputs" subtext={error} />
  ) : isLoading ? (
    <ColumnPaginatedGridTable
      key="placeholder"
      stableHeight={true}
      placeholder={true}
      emptyDatasetMessage={<Loading message={t('Loading preview')} />}
      header={[['.']]}
      rows={Array(10).fill(['.'])}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data table-placeholder h-auto"
      Pager={() => <ReactPaginate className="paginate-view-data" />}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  ) : (
    <ColumnPaginatedGridTable
      key="data"
      stableHeight={true}
      header={showData.columns}
      rows={showData.data}
      rowsPerPage={5}
      cellElement={(c) => <span className="text-truncate">{c}</span>}
      index={(i) => (
        <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
          {i ? i : ''}
        </div>
      )}
      className="w-100 table-view-data"
      Pager={({ maxPage, onChange, page }) => (
        <ReactPaginate
          className="paginate-view-data"
          breakLabel="..."
          nextLabel={t('next')}
          onPageChange={({ selected }) => onChange(selected)}
          pageRangeDisplayed={3}
          pageCount={maxPage}
          previousLabel={t('previous')}
          renderOnZeroPageCount={null}
        />
      )}
      pagerLast={true}
      style={{
        minHeight: '150px',
      }}
    />
  )
}
