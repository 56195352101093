import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function CountrySelector({
  countries,
  country,
  setCountry,
  ...props
}) {
  const { t } = useTranslation()

  const countryKeys = Object.keys(countries ?? []).sort((a, b) => {
    if (countries[a] < countries[b]) {
      return -1
    }
    if (countries[a] > countries[b]) {
      return 1
    }
    return 0
  })

  return (
    <Form.Group>
      <Form.Label>
        {t('Billing country')} <span className="text-danger">*</span>
      </Form.Label>
      <Form.Select
        onChange={(e) => setCountry(e.target.value)}
        name="country"
        autoComplete="billing country"
        value={country ?? ''}
        {...props}
      >
        <option value="">{t('Select')}</option>
        {countryKeys.map((key) => (
          <option key={key} value={key}>
            {countries[key].name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}
