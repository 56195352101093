import React, { useMemo, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import $ from 'jquery'
import { useAuth } from '../../../providers/AuthProvider'
import StatisticalSignificance from '../../model-content/statistical-significance/StatisticalSignificance'
import Loading from '../../loading/LoadingSmall'
import { statisticalSignificance } from '../../../services/model'
import NextbrainSelect, { Option } from '../../model-content/NextbrainSelect'

export function ConfigKeyFeatures({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title
  const modelId = model?.id
  const { token, signout } = useAuth()

  const { data: significance, isLoading } = useQuery(
    ['statistical-significance', modelId],
    async () => {
      const data = await statisticalSignificance({
        modelId,
        token,
        signout,
      })

      const target_to_stats = data.target_value_to_column_statistics

      function scaleTo0_100(value, min_value, max_value) {
        return ((value - min_value) / (max_value - min_value)) * 100
      }

      function scaleToLog(value) {
        return Math.log(value + 1)
      }

      let minSignificance = Infinity
      let maxSignificance = -Infinity

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          target_to_stats[key].statistics[key2].significant = scaleToLog(
            target_to_stats[key].statistics[key2].significant,
          )
        }
      }

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          const significance = target_to_stats[key].statistics[key2].significant
          minSignificance = Math.min(minSignificance, significance)
          maxSignificance = Math.max(maxSignificance, significance)
        }
      }

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          target_to_stats[key].statistics[key2].significant =
            100 -
            scaleTo0_100(
              target_to_stats[key].statistics[key2].significant,
              minSignificance,
              maxSignificance,
            )
        }
      }

      return data
    },
    { staleTime: Infinity },
  )
  const [selected, setSelected] = useState(config?.options ?? [])
  const options = useMemo(
    () =>
      Object.keys(significance?.target_value_to_column_statistics ?? {}).map(
        (k) => ({
          label: `${model?.target} ${k}`,
          value: `${model?.target} ${k}`,
          original: k,
        }),
      ),
    // eslint-disable-next-line
    [significance],
  )

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.key-features-title').val(),
    options: selected ?? [],
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col xs={12}>Title:</Col>
            <Col xs={12}>
              <Form.Control
                className="key-features-title"
                defaultValue={`${config.title ?? 'Key features'}`}
                placeholder="Title..."
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>Features:</Col>
            <Col xs={12}>
              <NextbrainSelect
                value={selected}
                onChange={(v) => setSelected(v ?? [])}
                options={options}
                className="mt-2"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={true}
                isDisabled={isLoading}
                components={{
                  Option,
                }}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs={'auto'}>
              <Button
                onClick={() => onFinish(getConfig())}
                disabled={!selected?.length}
              >
                {isUpdate ? 'Update' : 'Create'}
              </Button>
            </Col>
            <Col xs={'auto'}>
              <Button onClick={() => onFinish(null)}>Cancel</Button>
            </Col>
          </Row>
        </>
      )}
    </Row>
  )
}

export function WidgetKeyFeatures({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model?.id) return <Loading />

  return (
    <Row
      className="position-absolute w-100 mx-0 px-0"
      style={{ height: '100%', top: 0, left: 0 }}
    >
      <Col
        className=" d-inline-block text-truncate widget-title"
        style={{ height: '30px' }}
        xs={12}
      >
        {config.title}
      </Col>
      <Col
        className="position-relative   py-2  overflow-auto"
        xs={12}
        style={{ height: 'calc(100% - 30px)' }}
      >
        <StatisticalSignificance
          filter={config?.options?.map((o) => o.original)}
          showTitle={false}
          modelId={model?.id}
          span={12}
        />
      </Col>
    </Row>
  )
}
