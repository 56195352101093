import React, { useState, useEffect, useRef } from 'react'
import {
  Row,
  Col,
  Button,
  Modal,
  Tooltip,
  Form,
  OverlayTrigger,
} from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useTranslation } from 'react-i18next'
import { FiEdit2 } from 'react-icons/fi'
import { MdRefresh } from 'react-icons/md'

import {
  editModelHTMLdescription,
  regenerateDescription,
} from '../../services/model'

import './ckeditor.css'
import Loading from '../loading/LoadingSmall'
import { modelCreatedDate } from '../../util/models'
import { useModels } from '../../providers/ModelProvider'

function getInitialData(model, t) {
  if (model?.html_description === '<none>') return ''

  if (model?.html_description) {
    return model.html_description
  }

  const title = model?.dataset?.name ?? 'Title'
  return `
  <h4>${title}</h4><p></p>
  <h3>${t ? t('Context') : 'Context'}</h3>
  <p>${t ? t('Context description') : 'Context description'}</p>
  <h3>${t ? t('Content') : 'Content'}</h3>
  <p>${t ? t('Content description') : 'Content description'}</p>
  <h3>${t ? t('What to do') : 'What to do'}</h3>
  <p>${t ? t('What to do description') : 'What to do description'}</p>
  `
}

export default function CustomEditor({
  model = null,
  token,
  signout,
  enabledLLMFeatures = true,
}) {
  const { t } = useTranslation()
  const [showPrompt, setShowPrompt] = useState(false)
  const promptRef = useRef()

  const disabled =
    (enabledLLMFeatures &&
      !model?.html_description &&
      Date.now() - modelCreatedDate(model).getTime() < 1000 * 60 * 5) ||
    model?.html_description === 'generating_description'

  const { onParameterUpdate, offParameterUpdate } = useModels()

  const [config, setConfig] = useState(() => {
    return {
      data: getInitialData(model, t),
    }
  })
  const [saving, setSaving] = useState(false)

  const onReady = () => {}
  const onBlur = () => {}
  const onFocus = () => {}

  const onChange = (event, editor) => {
    config.data = editor.getData()
  }

  const onSave = (data) => {
    if (data.trim() === '') {
      data = '<none>'
    }
    setSaving(true)
    editModelHTMLdescription({
      modelId: model.id,
      html_description: data,
      token,
      signout,
    })
      .then((d) => {
        if (d.ok) {
          model.html_description = data
          NotificationManager.info(t('The problem description was saved'))
        } else {
          NotificationManager.error(t('Failed to save content.'))
        }
      })
      .catch((e) => {
        NotificationManager.error(t('Failed to save content.'))
      })
      .finally(() => {
        setSaving(false)
      })
  }

  // eslint-disable-next-line
  useEffect(() => setConfig({ data: getInitialData(model, t) }), [model])
  useEffect(() => {
    if (model) {
      const cb = () => setConfig({ data: getInitialData(model, t) })
      onParameterUpdate(model.id, 'html_description', cb)
      return () => offParameterUpdate(model.id, 'html_description', cb)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row
      className={`position-relative ${saving ? 'disabled-editor' : ''} ${
        disabled ? 'generating-desc' : ''
      }`}
    >
      {disabled && (
        <div className="generation-tooltip">
          <Loading message={'Generating description'} />
        </div>
      )}
      <Col md={12}>
        {enabledLLMFeatures && (
          <Row>
            <Modal
              centered
              show={showPrompt}
              onHide={() => setShowPrompt(false)}
              size={'xl'}
            >
              <Modal.Header className="d-flex align-items-center" closeButton>
                <FiEdit2 size={25} />
                <span className="h5 ms-2 d-flex align-items-center mb-0">
                  {t(
                    'Describe your dataset to assist the AI in crafting a tailored description.',
                  )}
                </span>
              </Modal.Header>
              <Modal.Body>
                <Form.Control
                  ref={promptRef}
                  className="nb-input"
                  as={'textarea'}
                  rows={5}
                  maxLength={2000}
                />
              </Modal.Body>
              <Modal.Footer>
                <Row className="w-100">
                  <Col
                    className="d-flex align-items-center justify-content-end"
                    xs={12}
                  >
                    <Button
                      className="p-2"
                      onClick={() => {
                        regenerateDescription({
                          modelId: model.id,
                          initialPrompt: promptRef.current?.value,
                          token,
                          signout,
                        }).finally(() => {
                          setShowPrompt(false)
                        })
                      }}
                    >
                      <span className="">{t('Confirm')}</span>
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </Modal>
            <Col className="d-flex justify-content-end py-2" xs={12}>
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement="auto"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    {t('Regenerate problem description')}
                  </Tooltip>
                )}
              >
                <Button
                  className=" mb-2"
                  onClick={() => {
                    regenerateDescription({
                      modelId: model.id,
                      token,
                      signout,
                    })
                  }}
                >
                  <MdRefresh size={20} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ minHeight: '300px' }} xs={12}>
            <CKEditor
              editor={ClassicEditor}
              disabled={false}
              data={config.data}
              config={{
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'blockQuote',
                    '|',
                    'uploadImage',
                    'insertTable',
                    '|',
                    'undo',
                    'redo',
                  ],
                },
                heading: {
                  options: [
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Heading 1',
                      class: 'ck-heading_heading1',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                    {
                      model: 'heading3',
                      view: 'h3',
                      title: 'Heading 3',
                      class: 'ck-heading_heading3',
                    },
                    {
                      model: 'heading4',
                      view: 'h4',
                      title: 'Heading 4',
                      class: 'ck-heading_heading4',
                    },
                    {
                      model: 'heading5',
                      view: 'h5',
                      title: 'Heading 5',
                      class: 'ck-heading_heading5',
                    },
                    // Add more heading options if needed
                  ],
                },
                table: {
                  contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                  ],
                },
                language: 'es',
              }}
              onReady={onReady}
              onChange={onChange}
              onBlur={(event, editor) => {
                return onBlur(event, editor)
              }}
              onFocus={onFocus}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12}>
        <Button
          onClick={() => {
            if (getInitialData(model) === config.data) return
            onSave(config.data)
          }}
          className="float-end mt-2 px-5"
        >
          {t('Save')}
        </Button>
      </Col>
    </Row>
  )
}
