import { Row, Col } from 'react-bootstrap'
import AnomaliesResults from '../../anomalies/AnomaliesResult'
import AnomaliesAccuracy from '../../anomalies/AnomaliesAccuracy'
import InsightHeading from '../InsightHeading'
import { DownloadGraphOverlay } from '../../utils/DownloadGraphOverlay'

export default function AnomalySumary({ model, ...props }) {
  return (
    <Row>
      <Col xs={12}>
        <DownloadGraphOverlay>
          <Row className="insight-model-content py-2 my-2">
            <Col xs={12}>
              <InsightHeading
                title={'Model Outliers'}
                description={`Outliers within the dataset`}
                className="px-2 my-2"
              />
            </Col>
            <Col style={{ minHeight: '500px', maxHeight: '500px' }} xs={12}>
              <AnomaliesResults model={model} />
            </Col>
          </Row>
        </DownloadGraphOverlay>
      </Col>
      <Col xs={12}>
        <DownloadGraphOverlay>
          <Row className="insight-model-content py-2 my-2">
            <Col xs={12}>
              <InsightHeading
                title={'Predictibility'}
                description={`Model accuracy, decresease with time as the horizon widens`}
                className="px-2 my-2"
              />
            </Col>
            <Col
              className="mt-1 mb-3"
              style={{ minHeight: '250px', maxHeight: '250px' }}
              xs={12}
            >
              <AnomaliesAccuracy model={model} />
            </Col>
          </Row>
        </DownloadGraphOverlay>
      </Col>
    </Row>
  )
}
