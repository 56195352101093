import { request } from './base'

export async function getSpreadsheets(google_access_token, token, signout) {
  return request({
    endpoint: `/spreadsheet/?google_token=${encodeURIComponent(
      google_access_token,
    )}`,
    token,
    signout,
    rawResponse: true,
  })
}

export async function getWorkbooks(
  spreadsheet_id,
  google_access_token,
  token,
  signout,
) {
  return request({
    endpoint: `/spreadsheet/workbooks/${spreadsheet_id}?google_token=${encodeURIComponent(
      google_access_token,
    )}`,
    token,
    signout,
    rawResponse: true,
  })
}

export async function exportSpreadsheetGsheets({
  googleToken,
  workbookName,
  spreadsheets,
  token,
  signout,
}) {
  return request({
    endpoint: `/spreadsheet/export-gsheet`,
    method: 'POST',
    contentType: 'json',
    body: {
      google_token: googleToken,
      workbook_name: workbookName,
      sheets: spreadsheets,
    },
    token,
    signout,
    rawResponse: true,
  })
}

export async function exportSpreadsheetExcel({
  workbookName,
  spreadsheets,
  token,
  signout,
}) {
  return request({
    endpoint: `/spreadsheet/export-excel`,
    method: 'POST',
    contentType: 'json',
    body: {
      workbook_name: workbookName,
      sheets: spreadsheets,
    },
    token,
    signout,
    rawResponse: true,
  })
}
