import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  formatedProblemType,
  isForecastingModel,
  isRegressionModel,
  isMulticlassModel,
  isBinaryModel,
} from '../../../util/models'
import $ from 'jquery'

function getModelDescription(model) {
  if (isForecastingModel(model))
    return (
      `Refers to the process that uses algorithms to learn from data and make predictions about` +
      ` future events and will detect trends with yearly, weekly and daily seasonality plus holiday effects.` +
      `<br/><br/>Examples include: Sales in future months, weather forecasting, web traffic & predict consumption.` +
      `<br/><br/>In your case your target is ${model.target}`
    )

  if (isRegressionModel(model))
    return (
      `Refers to the technique for investigating the relationship between independent variables` +
      ` or features and a dependent variable or outcome.<br/>` +
      `Examples include: Price estimation or any other continuous variable.` +
      `<br/><br/>In your case your target is ${model.target}.`
    )

  if (isBinaryModel(model))
    return (
      `Refers to those classifications tasks that have two class labels.
    examples include: email spam detection(is or is not); churn prediction (cancel or not), conversion prediction (buy or not).` +
      `<br/><br/>In your case your target is ${
        model.target
      }, the valid classes are: ${(
        model.dataset?.categorical_to_unique[model.target] ?? []
      )
        .map((r) => `${model.target} ${r}`)
        .join(', ')}.`
    )

  if (isMulticlassModel(model))
    return (
      `Refers to those classifications tasks that have more than two class labels.<br/>` +
      `Examples include: Differentiate user class or any sort of categorical sorting.` +
      `<br/><br/>In your case your target is ${
        model.target
      }, the valid classes are ${(
        model.dataset?.categorical_to_unique[model.target] ?? []
      )
        .map((r) => `${model.target} ${r}`)
        .join(', ')}.`
    )

  return ''
}

export function createHeader(model) {
  return `<b><h3>${model.dataset.name}</h3></b> <i>${formatedProblemType(
    model,
  )}</i>
      <br />
      <span> <br />${getModelDescription(model)}</span>
      `
}

export function ConfigStorytelling({ model, onFinish, config = {}, ...props }) {
  let state = config?.editor ?? createHeader(model)
  const stateDescription = model.html_description
  const isUpdate = config?.editor

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row className="my-2">
        <Col xs={12}>Mode</Col>
        <Col xs={12}>
          <Form.Select
            type="number"
            onChange={(v) => {
              switch (v.target.value) {
                default:
                case 'gt':
                  $('.widget-wsiwyg-edit').removeClass('d-none')
                  $('.widget-wsiwyg-description').addClass('d-none')
                  break
                case 'md':
                  $('.widget-wsiwyg-edit').addClass('d-none')
                  $('.widget-wsiwyg-description').removeClass('d-none')
                  break
              }
            }}
            className="storytelling-mode raw"
            defaultValue={`${config.mode ?? 'gt'}`}
          >
            <option disabled value="ms">
              Model summary
            </option>
            <option disabled={stateDescription === null} value="md">
              Model description
            </option>
            <option value="gt">General text</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mb-5  not-bold storytelling-editor-container">
        <Col
          className={`widget-wsiwyg-edit ${
            config.mode === 'md' ? 'd-none' : ''
          }`}
          xs={12}
        >
          <CKEditor
            editor={ClassicEditor}
            disabled={false}
            data={state}
            config={{
              toolbar: {
                items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  '|',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'blockQuote',
                  '|',
                  'uploadImage',
                  'insertTable',
                  '|',
                  'undo',
                  'redo',
                ],
              },
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
              },
              language: 'es',
            }}
            onReady={() => {}}
            onChange={(event, editor) => (state = editor.getData())}
            onBlur={(event, editor) => {}}
            onFocus={() => {}}
          />
        </Col>
        <Col
          className={`widget-wsiwyg-description ${
            config.mode === 'md' ? '' : 'd-none'
          }`}
          xs={12}
        >
          <CKEditor
            editor={ClassicEditor}
            disabled={true}
            data={stateDescription}
            config={{
              toolbar: {
                items: [],
              },
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
              },
              language: 'es',
            }}
            onReady={() => {}}
            onChange={() => {}}
            onBlur={(event, editor) => {}}
            onFocus={() => {}}
          />
        </Col>
      </Row>
      <Row className="buttons-wysiwyg">
        <Col xs={'auto'}>
          <Button
            onClick={() => {
              onFinish({
                layout: { h: 3, w: 3, x: 0, y: 0 },
                ...config,
                mode: $('.storytelling-mode').val(),
                editor: state,
              })
            }}
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetStorytelling({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  const state = config.mode === 'gt' ? config.editor : model.html_description

  return (
    <Row className="w-100 h-100" id={id} style={{ overflow: 'auto' }}>
      <Col xs={12}>
        <CKEditor
          editor={ClassicEditor}
          disabled={true}
          data={state}
          config={{
            toolbar: {
              items: [],
            },
            table: {
              contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
            },
            language: 'es',
          }}
          onReady={() => {}}
          onChange={() => {}}
          onBlur={(event, editor) => {}}
          onFocus={() => {}}
        />
      </Col>
    </Row>
  )
}
