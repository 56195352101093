import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TopFieldExploration from './TopFieldsExploration'
import FeedbackForecast from '../forecast/FeedbackForecast'
import StatusMessage from './StatusMessage'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-grid-system'
import { useAuth } from '../../providers/AuthProvider'
import { useSearchParams } from 'react-router-dom'
import { getModelById } from '../../services/model'

import { MMMModelSummaryEngagement } from './MMMModelSumaryEngagement'
import { useNav } from '../../providers/NavProvider'
import CrashFallback from '../crash-fallback/CrashFallback'
import StatisticalSignificance from './statistical-significance/StatisticalSignificance'
import SeasonalTrend from '../forecast/SeasonalTrend'
import { DownloadGraphOverlay } from '../utils/DownloadGraphOverlay'
import { ForecastPredict } from '../predict-form/predict-form'

import { testAccuracy } from '../../util/models'

export function ModelSummaryEngagement({
  model,
  hideNav,
  improvePerformance = <></>,
  children,
}) {
  const { t } = useTranslation()
  const [smodel, setSmodel] = useState(model)
  const { token, signout } = useAuth()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const useTitle = searchParams.get('title')
  const { setShowNav } = useNav()
  useEffect(() => {
    if (!hideNav) return

    setShowNav(false)
    return () => setShowNav(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!model) {
      getModelById(params.id, token, signout).then((d) => {
        if (d) {
          setSmodel(d)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  if (!model)
    return smodel ? (
      <ModelSummaryEngagement model={smodel}>{children}</ModelSummaryEngagement>
    ) : (
      <>
        <StatusMessage history={[{ message: t('Loading...') }]} />
      </>
    )

  if (model?.special_model_type === 'mmm')
    return <MMMModelSummaryEngagement model={model} children={children} />

  const validColumns =
    model &&
    model.status === 'trained' &&
    model.dataset &&
    model.dataset.columns_order
      ? model.dataset.columns_order.filter(
          (column) =>
            column in model.columns_active &&
            column in model.dataset.statistics &&
            column !== model.target,
        )
      : []

  return (
    <>
      {model.problem_type !== 'time_series_regression' && (
        <Col className="ps-md-5 ps-0 mb-3" md={8} xs={12}>
          <Row className="h-100">{improvePerformance}</Row>
        </Col>
      )}
      {useTitle ? (
        <Col className="my-4 gx-0 px-0" xs={12}>
          <strong className="h2">
            {t('Model')} {model.dataset.name}
          </strong>
        </Col>
      ) : (
        <></>
      )}
      {model.status === 'trained' &&
      validColumns.length <= 2 &&
      model.problem_type === 'time_series_regression' ? (
        <>
          <Col className="mb-3 ps-0" xl={8} md={12}>
            <Row className="ms-xl-3 ms-md-0 ms-0 insights-card py-2" style={{}}>
              <Col className="h5" xs={12}>
                <strong>{t('Model Prediction')}</strong>
              </Col>
              <Col
                style={{
                  minHeight: '450px',
                  maxHeight: '450px',
                }}
                className="py-3"
                xs={12}
              >
                <FeedbackForecast model={model} />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>{improvePerformance}</Row>
          </Col>
          <Col className="mt-2" xs={12}>
            <Row className="my-2 py-2 insights-card">
              <Col className="h5" xs={12}>
                <strong>{t('Prediction')}</strong>
              </Col>
              <Col
                style={{
                  minHeight: '550px',
                  maxHeight: '550px',
                  maxWidth: 'calc(100% - 60px)',
                }}
                className="position-relative py-2"
                xs={12}
              >
                <ForecastPredict model={model} widget={true} />
              </Col>
            </Row>
          </Col>
          <Col className="mt-2" xs={12}>
            <DownloadGraphOverlay>
              <Row className="my-2 py-2 insights-card">
                <Col className="h5" xs={12}>
                  <strong>{t('Trend and Seasonality')}</strong>
                </Col>
                <Col
                  style={{
                    minHeight: '450px',
                    maxHeight: '450px',
                  }}
                  className="py-3"
                  xs={12}
                >
                  <SeasonalTrend model={model} />
                </Col>
              </Row>
            </DownloadGraphOverlay>
          </Col>
        </>
      ) : (
        <></>
      )}
      {children}
      {model.status === 'trained' &&
      !['time_series_regression'].includes(model.problem_type) ? (
        model?.details?.individual_feature_importance &&
        testAccuracy(model) >= 10 ? (
          <Col xs={12} className="insights-card">
            <CrashFallback
              style={{ minHeight: '0px' }}
              message={
                <Row>
                  <Col className="insights-card">
                    <div className="h4 bold col-12 mt-1">
                      <strong>{t('Something went wrong')}</strong>
                    </div>
                  </Col>
                </Row>
              }
              t={t}
            >
              <TopFieldExploration model={model} />
            </CrashFallback>
          </Col>
        ) : (
          <Col className="insights-card" xs={12}>
            <div className="h4 bold col-12 mt-1 d-flex justify-content-center">
              <strong>
                {t('Column importance can not be computed for this model.')}
              </strong>
            </div>
          </Col>
        )
      ) : (
        <></>
      )}
      {model.status === 'trained' &&
      !['time_series_regression'].includes(model.problem_type) ? (
        <StatisticalSignificance
          modelId={model.id}
          wrapper={(children) => (
            <Col xs={12} className="insights-card mt-2">
              <CrashFallback
                style={{ minHeight: '0px' }}
                message={
                  <Row>
                    <Col>{t('Something went wrong')}</Col>
                  </Row>
                }
                t={t}
              >
                {children}
              </CrashFallback>
            </Col>
          )}
        />
      ) : (
        <></>
      )}
    </>
  )
}
