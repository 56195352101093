import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import $ from 'jquery'

export function ConfigHistory({ model, onFinish, config = {}, ...props }) {
  const isUpdate = config.title

  const getConfig = () => ({
    layout: { h: 9, w: 3, x: 0, y: 0 },
    ...config,
    title: $('.history-title').val(),
    fontSize: Number.parseInt($('.history-font-size').val()) ?? 12,
  })

  return (
    <Row {...props} className={`config-widget-menu ${props.className ?? ''}`}>
      <Row>
        <Col xs={12}>Title:</Col>
        <Col xs={12}>
          <Form.Control
            className="history-title"
            defaultValue={`${config.title ?? 'Model History'}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>Font Size:</Col>
        <Col xs={12}>
          <Form.Control
            type="number"
            className="history-font-size"
            defaultValue={`${config.fontSize ?? 12}`}
            placeholder="Title..."
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(getConfig())}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button onClick={() => onFinish(null)}>Cancel</Button>
        </Col>
      </Row>
    </Row>
  )
}

export function WidgetHistory({
  model,
  config,
  id,
  requestedData = {},
  ...props
}) {
  if (!model || !config) return <>Loading...</>

  return (
    <Row className="w-100 h-100 overflow-scroll px-0 mx-0" id={id}>
      {config.title ? (
        <Col
          className="header-data-distribution d-inline-block text-truncate widget-title"
          xs={12}
        >
          {config.title}
        </Col>
      ) : (
        <></>
      )}
      <Row className="mx-0">
        <ul style={{ fontSize: config.fontSize }}>
          {(model?.dataset?.logs ?? []).map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
      </Row>
    </Row>
  )
}
