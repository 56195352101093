import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Row } from 'react-grid-system'

const DEFAULT_DATA = [
  {
    id: 'Survived 0',
    label: 'Survived 0',
    value: 111,
  },
  {
    id: 'Survived 1',
    label: 'Survived 1',
    value: 92,
  },
]

export default function CustomPie({
  data,
  className = '',
  header,
  height = 204,
}) {
  return (
    <div className={className}>
      {header ? header : <></>}
      <Row className="mt-2 mx-1" style={{ height: `${height}px` }}>
        <ResponsivePie
          data={data ? data : DEFAULT_DATA}
          margin={{ top: 10, right: 10, bottom: 80, left: 10 }}
          cornerRadius={5}
          activeOuterRadiusOffset={3}
          colors={(d) => d.data.color}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 6]],
          }}
          legends={[
            {
              anchor: 'bottom',
              direction: 'column',
              justify: false,
              translateX: 50,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 14,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      </Row>
    </div>
  )
}
