import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getModelFlow } from '../../../services/model'
import { TourProvider } from '@reactour/tour'
import { useModels } from '../../../providers/ModelProvider'
import Loading from '../../loading/LoadingSmall'
import DatasourceFlow from './DatasourceFlow'
import { useAuth } from '../../../providers/AuthProvider'

export default function DatasourceFlowDisplay({ ...props }) {
  const { t } = useTranslation()
  const { workspaceId, activeModel } = useModels()
  const { signout, token } = useAuth()
  const { data, isLoading } = useQuery(
    [`model-flow-${activeModel?.id}`],
    async () => {
      if (!activeModel) return {}
      return await getModelFlow({
        modelId: activeModel.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )

  if (isLoading || !activeModel) return <Loading />

  const steps = [
    {
      selector: '.datasource-template-Config',
      content: t('Select a datasource by clicking on the configuration nodes'),
    },
    {
      selector: '.output-datasource-trigger ',
      content: t('Select a node to create a model from with this button'),
    },
    {
      selector: '.transform-datasource-trigger',
      content: t(
        'You can use this button to add transformations or merge multiple sources of data',
      ),
    },
    {
      selector: '.config-forced-actions-context-menu',
      content: t(
        'You can select all available transformation from the context menu after clicking the button',
      ),
    },
    {
      selector: '.add-new-datasource-button.tour-part',
      content: t(
        'Add additional datasources with the right click menu or with this button',
      ),
    },
  ]

  return (
    <TourProvider steps={steps}>
      <DatasourceFlow
        {...props}
        name={false}
        defaultNodes={data}
        modelId={activeModel.id}
        projectId={workspaceId}
      />
    </TourProvider>
  )
}
