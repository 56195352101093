import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import { FaCog } from 'react-icons/fa'
import { AiOutlineControl } from 'react-icons/ai'
import { MdLogout } from 'react-icons/md'
import AdminSystemConfiguration from './AdminSystemConfiguration'

const sections = {
  Configure: {
    component: AdminSystemConfiguration,
    icon: <FaCog className="mx-1" size={15} />,
  },
  Manage: {
    component: () => <></>,
    icon: <AiOutlineControl className="mx-1" size={17} />,
  },
  Exit: {
    component: () => <></>,
    icon: <MdLogout className="mx-1" size={17} />,
  },
}

export default function AdminPanel({ credentials, exit }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const [components] = useState(() => {
    return Object.entries(sections).reduce((acc, [key, value]) => {
      const Comp = value.component
      acc[key] = <Comp credentials={credentials} />
      return acc
    }, {})
  })

  const [section, setSection] = useState(
    searchParams.get('section') || 'Configure',
  )

  useEffect(() => {
    if (section === 'Exit') {
      exit()
      return
    }
    searchParams.set('section', section)
    setSearchParams(searchParams)
    // eslint-disable-next-line
  }, [section])

  return (
    <Row className="ms-0" style={{ minHeight: 'calc(100% - 100px)' }}>
      <Col
        className="admin-manager-panel-sidebar  justify-content-center"
        xs={12}
      >
        <Row className="h-100 flex-column">
          {Object.keys(sections).map((key, index) => (
            <Col
              onClick={() => {
                if (key === 'Exit') exit()
                else setSection(key)
              }}
              className={`admin-manager-panel-section d-flex align-items-center py-1 
                  ${key === 'Exit' ? 'mt-auto' : ''} ${
                key === section ? 'active' : ''
              }`}
              key={key}
              xs={12}
            >
              {sections[key].icon}
              {key}
            </Col>
          ))}
        </Row>
      </Col>
      <Col className="admin-manager-panel-contents" xs={12}>
        {components?.[section]}
      </Col>
    </Row>
  )
}
